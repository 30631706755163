import CustomerOrderRow from './CustomerOrderRow'
import { CustomerOrderType } from 'customersV2/Customers.types'

export default function CustomerOrdersList({ orders }: { orders: CustomerOrderType[] }) {
    return (
        <>
            <h3 className='text-base font-bold mb-2'>Orders</h3>
            <div className='h-full overflow-auto flex flex-col gap-2'>
                {orders.length === 0 ? (
                    <p>This customer has no orders yet.</p>
                ) : (
                    <>
                        {orders.map((order: any) => (
                            <CustomerOrderRow order={order} />
                        ))}
                    </>
                )}
            </div>
        </>
    )
}
