import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react'
import { LoadingGear, vFetch } from '../../../helpers'
import Input from '../Input'
import { ProcurementContext, ProcurementContextType } from '../../helpers'

export default function CreateCategory() {
    const modalRef = useRef(null)
    const { toggleModal, setNeedsRefresh } = useContext<ProcurementContextType>(ProcurementContext)
    const [loading, setLoading] = useState(false)
    const [newCategory, setNewCategory] = useState('')

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setNewCategory(target.value)
    }

    const handleCreate = () => {
        setLoading(true)
        vFetch('/v1/procurement/categories', {
            method: 'POST',
            body: JSON.stringify({
                name: newCategory,
            }),
            cb: (res: any) => {
                if (res.success) {
                    toggleModal()
                    setNeedsRefresh(true)
                }
            },
        }).then(() => setLoading(false))
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (e.target === modalRef.current) {
                toggleModal()
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    return (
        <div
            ref={modalRef}
            className='fixed z-[50] top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] grid place-items-center'
        >
            <div className='p-[32px] gap-[32px] bg-white dark:bg-darkaccent rounded-[4px] shadow-small relative overflow-auto max-h-[90%] '>
                {loading && (
                    <div className='absolute top-[32px] left-0 w-full h-full grid place-items-center bg-white dark:bg-darkaccent z-[5]'>
                        <LoadingGear height='100px' width='100px' />
                    </div>
                )}
                <button
                    onClick={() => {
                        toggleModal()
                    }}
                    className='leading-[1] text-[24px] text-red dark:text-lightred absolute font-bold top-0 right-[8px]'
                >
                    &times;
                </button>
                <div className='relative z-[6] flex flex-col gap-[16px] w-1/2 whitespace-nowrap'>
                    <h2 className='text-darkgrey dark:text-fire text-[24px] font-bold uppercase leading-[1] m-0 mb-[16px]'>
                        Create Category
                    </h2>
                </div>
                <Input id='name' name='name' label='Name' onChange={handleChange} type='text' value={newCategory} />
                <button
                    onClick={handleCreate}
                    className='block mx-auto mt-[16px] py-[4px] px-[8px] rounded-[4px] font-bold text-white bg-blue dark:text-darkness dark:bg-accent uppercase'
                >
                    Create Category
                </button>
            </div>
        </div>
    )
}
