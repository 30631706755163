import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { CustomerType } from 'customersV2/Customers.types'
import { moneyFormatter } from 'customersV2/helpers'
import AreYouSureModal from 'customersV2/modals/AreYouSureModal'
import EditTaxExemptionsModal from 'customersV2/modals/EditTaxExemptionsModal'
import { vFetch } from 'helpers'
import { useEffect, useRef, useState } from 'react'
import { FaCaretDown, FaCaretUp, FaMoneyBill, FaTimes } from 'react-icons/fa'
import { useSearchParams } from 'react-router-dom'

export default function CustomerHeader({ customer }: { customer: CustomerType }) {
    const modalRef = useRef<HTMLDivElement>(null)
    const [_searchParams, setSearchParams] = useSearchParams()
    const [showTaxExemptionsEditModal, setShowTaxExemptionsEditModal] = useState(false)
    const [showAreYouSure, setShowAreYouSure] = useState(false)
    const [showMoreActions, setShowMoreActions] = useState(false)
    const queryClient = useQueryClient()
    const deleteCustomerMutation = useMutation({
        mutationFn: async ({ customer_id }: { customer_id: string }) =>
            vFetch('/v2/customers', {
                method: 'DELETE',
                body: JSON.stringify({
                    customer_id,
                }),
            }),
        onSuccess: () => {
            setSearchParams((prev) => {
                prev.delete('customer_id')
                return prev
            })
            setShowAreYouSure(false)
            setShowMoreActions(false)
            return queryClient.invalidateQueries({ queryKey: ['customers'] })
        },
    })

    const countryMap = {
        US: 'United States',
        CA: 'Canada',
    }

    const location = [
        [customer?.city, customer?.province_code].filter((v) => v).join(' '),
        countryMap[customer?.country_code as keyof typeof countryMap],
    ]
        .filter((v) => v)
        .join(', ')

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!modalRef.current?.contains(e.target as HTMLElement)) {
                setShowMoreActions(false)
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <div className='flex gap-4 justify-between mb-4'>
            <div className='flex gap-4'>
                <div>
                    <h2 className='font-bold text-2xl mb-0.5'>
                        {customer.calculated_full_name || customer.email || customer.phone}
                    </h2>
                    <p className='text-xs'>
                        {location} | Customer since {new Date(customer.created_at).getFullYear()}
                    </p>
                </div>
                <div className='w-[1px] self-stretch bg-lightgrey dark:bg-darkgrey'>&nbsp;</div>

                <div className='grid grid-cols-[auto_auto] gap-x-2 gap-y-0.5'>
                    <p>Total Orders:</p>
                    <span className='font-bold'>{customer.orders_count || 0}</span>
                    <p>Total Spend:</p>
                    <span className='font-bold'>{moneyFormatter(customer.total_spent || 0)}</span>
                </div>
                <div className='w-[1px] self-stretch bg-lightgrey dark:bg-darkgrey'>&nbsp;</div>
                <div className='grid grid-cols-[auto_auto] gap-x-2 gap-y-0.5'>
                    <p>Subscribed To Email:</p>
                    <span className='font-bold'>{customer.calculated_accepts_email_marketing ? 'Yes' : 'No'}</span>
                    <p>Subscribed To SMS:</p>
                    <span className='font-bold'>{customer.calculated_accepts_sms_marketing ? 'Yes' : 'No'}</span>
                </div>
                <div className='w-[1px] self-stretch bg-lightgrey dark:bg-darkgrey'>&nbsp;</div>
                <div className='grid grid-cols-[auto_auto] gap-x-2 gap-y-0.5'>
                    <p>Tax Exempt:</p>
                    <span className='font-bold'>{customer.tax_exempt === 1 ? 'Yes' : 'No'}</span>
                    <p>Tax Exemptions:</p>
                    <span className='font-bold'>{customer.tax_exemptions.join(', ') || 'None'}</span>
                </div>
            </div>
            <div ref={modalRef} className='relative h-fit'>
                <Button
                    onClick={() => setShowMoreActions((prev) => !prev)}
                    className='py-1 px-2 leading-none flex gap-1 items-center'
                >
                    <span>More Actions</span>
                    <FaCaretUp className={`${showMoreActions ? '' : 'hidden'}`} />
                    <FaCaretDown className={`${showMoreActions ? 'hidden' : ''}`} />
                </Button>
                {showMoreActions && (
                    <div className='absolute top-full right-0 border bg-white dark:bg-darkbg1 border-lightgrey dark:border-darkgrey rounded shadow-md p-2'>
                        <button
                            onClick={() => {
                                setShowTaxExemptionsEditModal(true)
                                setShowMoreActions(false)
                            }}
                            className='whitespace-nowrap flex gap-1 items-center'
                        >
                            <FaMoneyBill /> Edit Tax Exemptions
                        </button>
                        <button
                            onClick={() => {
                                setShowAreYouSure(true)
                                setShowMoreActions(false)
                            }}
                            className='whitespace-nowrap flex gap-1 items-center'
                        >
                            <FaTimes className='text-red dark:text-lightred' /> Delete Customer
                        </button>
                    </div>
                )}
            </div>
            {showAreYouSure && (
                <AreYouSureModal
                    message='Are you sure you want to delete this customer? This action cannot be undone!'
                    onDeny={() => setShowAreYouSure(false)}
                    onConfirm={() => deleteCustomerMutation.mutate({ customer_id: customer.id.toString() })}
                />
            )}
            {showTaxExemptionsEditModal && (
                <EditTaxExemptionsModal toggleModal={() => setShowTaxExemptionsEditModal(false)} customer={customer} />
            )}
        </div>
    )
}
