import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'

export const audit = createQueryKeys('audit', {
    auditHistory: (table, record_id, parent_id, filters, limit) => ({
        queryKey: [table, record_id, parent_id, filters, limit],
        queryFn: ({ pageParam = 1 }: { pageParam: any }): any => {
            return service.getAuditHistory(table, record_id, parent_id, pageParam, filters, limit)
        },
    }),
})
export const auditQueries = mergeQueryKeys(audit)
