import { Dispatch, SetStateAction } from 'react'

export default function MessageFilters({
    filter,
    setFilter,
    search,
    setSearch,
}: {
    filter: string
    setFilter: Dispatch<SetStateAction<string>>
    search: string
    setSearch: Dispatch<SetStateAction<string>>
}) {
    return (
        <div className='flex gap-[8px] items-center text-[14px] text-darkgrey'>
            {(filter !== '' || search.length > 0) && (
                <button
                    onClick={() => {
                        setFilter('')
                        setSearch('')
                    }}
                    className={`rounded-[4px] font-bold py-[4px] px-[8px] bg-red dark:bg-lightred text-white`}
                >
                    CLEAR
                </button>
            )}
            <button
                onClick={() => setFilter('810')}
                className={`rounded-[99vw] font-bold py-[4px] px-[8px] whitespace-nowrap ${
                    filter === '810'
                        ? 'bg-blue dark:bg-accent text-white dark:text-darkness'
                        : 'bg-lightgrey dark:bg-darkness dark:text-offwhite'
                }`}
            >
                Invoices
            </button>
            <button
                onClick={() => setFilter('846')}
                className={`rounded-[99vw] font-bold py-[4px] px-[8px] whitespace-nowrap ${
                    filter === '846'
                        ? 'bg-blue dark:bg-accent text-white dark:text-darkness'
                        : 'bg-lightgrey dark:bg-darkness dark:text-offwhite'
                }`}
            >
                Inventory
            </button>
            <button
                onClick={() => setFilter('850')}
                className={`rounded-[99vw] font-bold py-[4px] px-[8px] whitespace-nowrap ${
                    filter === '850'
                        ? 'bg-blue dark:bg-accent text-white dark:text-darkness'
                        : 'bg-lightgrey dark:bg-darkness dark:text-offwhite'
                }`}
            >
                Purchase Orders
            </button>
            <button
                onClick={() => setFilter('855')}
                className={`rounded-[99vw] font-bold py-[4px] px-[8px] whitespace-nowrap ${
                    filter === '855'
                        ? 'bg-blue dark:bg-accent text-white dark:text-darkness'
                        : 'bg-lightgrey dark:bg-darkness dark:text-offwhite'
                }`}
            >
                Purchase Order Acknowledgements
            </button>
            <button
                onClick={() => setFilter('856')}
                className={`rounded-[99vw] font-bold py-[4px] px-[8px] whitespace-nowrap ${
                    filter === '856'
                        ? 'bg-blue dark:bg-accent text-white dark:text-darkness'
                        : 'bg-lightgrey dark:bg-darkness dark:text-offwhite'
                }`}
            >
                Advance Ship Notices
            </button>
        </div>
    )
}
