import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LoadingGear, defaultColorsFP, parseResObject, vFetch } from '../helpers'
import { useDispatch, useSelector } from 'react-redux'

import { StoreType, defaultStore } from './stores.types'
import { setSettings } from '../redux/settings'

import StoreStyleSetupScreen from '../StoreStyles/StoreStyleSetupScreen'

export default function UpdateStore() {
    const dispatch = useDispatch()

    const user = useSelector((state: any) => state.user)
    const [storeInfo, setStoreInfo] = useState<StoreType>(defaultStore)
    const [settingImage, setSettingImage] = useState(false)
    const [colors, setColors] = useState<any>(defaultColorsFP)

    const [storeInfoUpdates, setStoreInfoUpdates] = useState<StoreType>(defaultStore)
    const [loading, setLoading] = useState(true)
    const id = localStorage.getItem('store_id')
    let needsSaved = false
    if (storeInfo !== storeInfoUpdates) {
        needsSaved = true
    }
    useEffect(() => {
        vFetch(`/v1/stores/${id}`, {
            cb: (res: any) => {
                if (res.success) {
                    const resInfo = parseResObject(res.storeInfo)
                    setStoreInfo(resInfo)
                    setStoreInfoUpdates(resInfo)
                    setColors(resInfo.color_set)
                    setLoading(false)
                }
            },
        })
    }, [])

    function handleSubmit() {
        if (storeInfo?.name) {
        }
        vFetch(`/v1/stores/`, {
            method: 'PUT',
            body: JSON.stringify({ ...storeInfoUpdates }),
            cb: (res: any) => {
                setStoreInfo(storeInfoUpdates)
            },
            catchCb: (err: any) => console.log(err),
        })
    }

    const handleUpload = ({ target }: { target: HTMLInputElement }) => {
        setSettingImage(true)
        if (!target.files || !target.files[0]) {
            return
        }
        const data = new FormData()
        data.append('image', target.files[0], target!.files[0].name)
        vFetch(`/v1/stores/imageUpload?id=${id}`, {
            method: 'POST',
            body: data,
            contentType: 'skip',
            cb: (res: any) => {
                setSettingImage(false)
                if (res.success) {
                    setStoreInfo({ ...storeInfoUpdates, logo: res.s3_img_url as any })
                    dispatch(
                        setSettings({
                            logo: res.s3_img_url,
                        })
                    )
                }
            },
            catchCb: () => setSettingImage(false),
        })
    }

    return (
        <>
            <div className='flex flex-col gap-[8px] w-auto'>
                {loading && (
                    <div className='w-full h-[80vh] grid place-items-center col-span-full'>
                        <LoadingGear />
                    </div>
                )}
                {!loading && user.store_names && id && <h1 className='text-2xl'>Edit {storeInfo.name}</h1>}
                {storeInfoUpdates && !loading && (
                    <div className='grid grid-cols-2 gap-2'>
                        <div className='flex flex-col gap-2'>
                            <div className='w-full h-fit top-[66px] rounded-[4px] shadow-small dark:bg-darkaccent p-[16px] overflow-hidden'>
                                <p className='mt-[-16px] ml-[-16px] p-[4px] leading-[1] w-fit text-[12px] uppercase font-bold bg-blue dark:bg-accent text-white dark:text-darkness'>
                                    Logo Icon
                                </p>
                                <div className='mt-[16px]'>
                                    {storeInfo.logo && (
                                        <img
                                            className='w-full p-[16px] border border-grey dark:border-darkgrey rounded-[4px]'
                                            src={storeInfo.logo}
                                        />
                                    )}
                                </div>
                                <label className='block w-full text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase my-[4px]'>
                                    {settingImage ? 'Uploading Image...' : 'Upload new featured image'}
                                </label>
                                <input
                                    className='w-full border border-grey bg-white dark:text-offwhite dark:bg-darkness dark:border-fire p-[8px] rounded-[4px]'
                                    type='file'
                                    name='image_upload'
                                    onChange={handleUpload}
                                />
                            </div>
                            <div className='flex flex-col gap-[8px] dark:bg-darkaccent p-[16px] rounded-[4px] shadow-small w-full overflow-hidden'>
                                <p className='mt-[-16px] ml-[-16px] p-[4px] leading-[1] w-fit text-[12px] uppercase font-bold bg-blue dark:bg-accent text-white dark:text-darkness'>
                                    Store Info
                                </p>
                                <div className='flex flex-col gap-[4px]'>
                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                        Store Name
                                    </label>
                                    <input
                                        className='bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                        type='text'
                                        name='name'
                                        value={storeInfoUpdates.name}
                                        onChange={({ target }) =>
                                            setStoreInfoUpdates({ ...storeInfoUpdates, name: target.value })
                                        }
                                    />
                                </div>
                                <div className='flex gap-[8px]'>
                                    <div className='flex flex-col gap-[4px] w-full'>
                                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                            Shopify Name
                                        </label>
                                        <input
                                            className='bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                            type='text'
                                            name='shopify_name'
                                            value={storeInfoUpdates.shopify_name || ''}
                                            onChange={({ target }) =>
                                                setStoreInfoUpdates({ ...storeInfoUpdates, shopify_name: target.value })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-col gap-[4px]'>
                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                        Shopify Access Token
                                    </label>
                                    <input
                                        className='bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                        type='text'
                                        name='access_token'
                                        value={storeInfoUpdates.access_token || ''}
                                        onChange={({ target }) =>
                                            setStoreInfoUpdates({ ...storeInfoUpdates, access_token: target.value })
                                        }
                                    />
                                </div>
                                <div className='flex flex-col gap-[4px]'>
                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                        ga4 Property Id
                                    </label>
                                    <input
                                        className='bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                        type='text'
                                        name='ga4_property_id'
                                        value={storeInfoUpdates.ga4_property_id || ''}
                                        onChange={({ target }) =>
                                            setStoreInfoUpdates({ ...storeInfoUpdates, ga4_property_id: target.value })
                                        }
                                    />
                                </div>
                                <div className='flex flex-col gap-[4px]'>
                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                        ga4 Service Account Credentials
                                    </label>
                                    <input
                                        className='bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                        type='text'
                                        name='ga4_service_account_credentials'
                                        value={JSON.stringify(storeInfoUpdates.ga4_service_account_credentials) || ''}
                                        onChange={({ target }) =>
                                            setStoreInfoUpdates({
                                                ...storeInfoUpdates,
                                                ga4_service_account_credentials: target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className='flex flex-col gap-[4px]'>
                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                        Slack API Key
                                    </label>
                                    <input
                                        className='bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                        type='text'
                                        name='slack_api_key'
                                        value={storeInfoUpdates.slack_api_key || ''}
                                        onChange={({ target }) =>
                                            setStoreInfoUpdates({ ...storeInfoUpdates, slack_api_key: target.value })
                                        }
                                    />
                                </div>
                                <div className='flex flex-col gap-[4px]'>
                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                        Slack Webhook URL
                                    </label>
                                    <input
                                        className='bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                        type='text'
                                        name='slack_incoming_webhook_url'
                                        value={storeInfoUpdates.slack_incoming_webhook_url || ''}
                                        onChange={({ target }) =>
                                            setStoreInfoUpdates({
                                                ...storeInfoUpdates,
                                                slack_incoming_webhook_url: target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className='flex flex-col gap-[4px]'>
                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                        Google Shopping Name
                                    </label>
                                    <input
                                        className='bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                        type='text'
                                        name='google_shopping_name'
                                        value={storeInfoUpdates.google_shopping_name || ''}
                                        onChange={({ target }) =>
                                            setStoreInfoUpdates({
                                                ...storeInfoUpdates,
                                                google_shopping_name: target.value,
                                            })
                                        }
                                    />
                                </div>

                                <button
                                    className={`border-2 w-fit px-4 py-2 rounded self-end ${
                                        needsSaved ? '' : 'opacity-20'
                                    }`}
                                    onClick={handleSubmit}
                                >
                                    Save Store Info
                                </button>
                            </div>
                        </div>
                        <StoreStyleSetupScreen loading={loading} colors={colors} setColors={setColors} />
                    </div>
                )}
            </div>
        </>
    )
}
