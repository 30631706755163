import { Dispatch, SetStateAction, useState } from 'react'
import Input from '../../procurement/components/Input'
import { FaTimes } from 'react-icons/fa'
import Textarea from '../../procurement/components/Textarea'
import Select from '../../procurement/components/Select'
import { useAllUsersQuery } from '../../users/api/useQueries'
import { UserInit } from '../../users/users.types'
import { useCreateTask, useProjects } from '../api/useQueries'
import { useSelector } from 'react-redux'

export default function CreateTask({ setQuickModal }: { setQuickModal: Dispatch<SetStateAction<undefined | string>> }) {
    const user = useSelector((state: any) => state.user)
    const defaultTask = {
        title: '',
        description: '',
        status: 'backlog',
        assigned_to: '',
        priority: 'low',
        estimate: '0',
        project_id: '',
        created_by: user.id,
        is_ticket: 0,
    }
    const [task, setTask] = useState({ ...defaultTask })
    const allUsersQuery = useAllUsersQuery()
    const users: UserInit[] = allUsersQuery?.data?.users || []
    const projectsQuery = useProjects()
    const projects = projectsQuery?.data || []

    const createTaskMutation = useCreateTask()

    const handleCreateTask = async () => {
        createTaskMutation.mutate(
            {
                ...task,
                project_id: task.project_id || undefined,
                assigned_to: task.assigned_to || undefined,
            },
            {
                onSuccess: () => {
                    setQuickModal(undefined)
                    setTask({ ...defaultTask })
                    return
                },
            }
        )
    }

    return (
        <div className='fixed min-w-[500px] pb-4 bg-white dark:bg-darkaccent z-50 bottom-0 left-[50%] translate-x-[-50%] rounded border border-darkgrey border-b-0 rounded-b-none'>
            <div className='p-2 rounded flex justify-between items-center bg-lightgrey dark:bg-darkness'>
                <h3 className='font-bold text-[14px] leading-tight'>New Task</h3>
                <button onClick={() => setQuickModal(undefined)} className=''>
                    <FaTimes className='text-red dark:text-lightred' />
                </button>
            </div>
            <div className='flex flex-col gap-2 p-4'>
                <Input
                    label='Title'
                    id='title'
                    name='title'
                    onChange={({ target }) => setTask({ ...task, [target.name]: target.value })}
                    type='text'
                    autoFocus={true}
                    value={task.title}
                />
                <Textarea
                    label='Description'
                    id='description'
                    name='description'
                    onChange={({ target }) => setTask({ ...task, [target.name]: target.value })}
                    value={task.description}
                />
                <div className='flex gap-2'>
                    <Select
                        label='Project'
                        id='project_id'
                        name='project_id'
                        onChange={({ target }) => setTask({ ...task, [target.name]: target.value })}
                        value={task.project_id}
                    >
                        <option value=''>No project</option>
                        {projects.map((project: any) => (
                            <option value={project.id}>{project.title}</option>
                        ))}
                    </Select>
                    <Select
                        label='Assign To'
                        id='assigned_to'
                        name='assigned_to'
                        onChange={({ target }) => setTask({ ...task, [target.name]: target.value })}
                        value={task.assigned_to}
                    >
                        <option value=''>No assignee</option>
                        {users
                            .filter((user) => user.roles.includes('developer'))
                            .map((user) => (
                                <option value={user.id}>
                                    {user.first_name} {user.last_name}
                                </option>
                            ))}
                    </Select>
                </div>
                <div className='flex gap-2'>
                    <Select
                        label='Status'
                        id='status'
                        name='status'
                        onChange={({ target }) => setTask({ ...task, [target.name]: target.value })}
                        value={task.status}
                    >
                        <option className='dark:bg-darkness/90' value='backlog'>
                            Backlog
                        </option>
                        <option className='dark:bg-darkness/90' value='todo'>
                            Todo
                        </option>
                        <option className='dark:bg-darkness/90' value='in progress'>
                            In Progress
                        </option>
                        <option className='dark:bg-darkness/90' value='in review'>
                            In Review
                        </option>
                        <option className='dark:bg-darkness/90' value='blocked'>
                            Blocked
                        </option>
                        <option className='dark:bg-darkness/90' value='done'>
                            Done
                        </option>
                        <option className='dark:bg-darkness/90' value='canceled'>
                            Canceled
                        </option>
                    </Select>
                    <Select
                        label='Priority'
                        id='priority'
                        name='priority'
                        onChange={({ target }) => setTask({ ...task, [target.name]: target.value })}
                        value={task.priority}
                    >
                        <option className='dark:bg-darkness/90' value='low'>
                            Low
                        </option>
                        <option className='dark:bg-darkness/90' value='medium'>
                            Medium
                        </option>
                        <option className='dark:bg-darkness/90' value='high'>
                            High
                        </option>
                        <option className='dark:bg-darkness/90' value='urgent'>
                            Urgent
                        </option>
                    </Select>
                    <Select
                        label='Estimate'
                        id='estimate'
                        name='estimate'
                        onChange={({ target }) => setTask({ ...task, [target.name]: target.value })}
                        value={task.estimate}
                    >
                        <option className='dark:bg-darkness/90' value='0'>
                            0
                        </option>
                        <option className='dark:bg-darkness/90' value='1'>
                            1
                        </option>
                        <option className='dark:bg-darkness/90' value='2'>
                            2
                        </option>
                        <option className='dark:bg-darkness/90' value='3'>
                            3
                        </option>
                        <option className='dark:bg-darkness/90' value='5'>
                            5
                        </option>
                        <option className='dark:bg-darkness/90' value='8'>
                            8
                        </option>
                    </Select>
                </div>
            </div>
            <button
                onClick={handleCreateTask}
                className='block h-fit mx-auto bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold py-1 px-2 leading-tight rounded'
            >
                Create Task
            </button>
        </div>
    )
}
