import { useDebounce } from 'helpers'
import FilterToolBar from 'procurement/components/FilterToolBar'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export default function GroupsSearchBarContainer({ setDebouncedSearch }: { setDebouncedSearch: any }) {
    const [searchParams, setSearchParams] = useSearchParams()

    const searchParam = searchParams.get('search') || ''
    const [search, setSearch] = useState(searchParam)

    const debouncedSearch = useDebounce(search.trim(), 400)
    useEffect(() => {
        setDebouncedSearch(debouncedSearch)
    }, [debouncedSearch])

    const companyFilterBarObject = {
        params: [],
        setFunction: () => {},
        resetFunction: () =>
            setSearchParams((prev: URLSearchParams) => {
                prev.set('page', '1')
                prev.delete('after')
                prev.delete('before')
                return prev
            }),
    }

    const handleClearSearch = () => {
        setSearchParams((prev: URLSearchParams) => {
            prev.delete('after')
            prev.delete('search')
            prev.delete('before')
            prev.set('page', '1')
            return prev
        })
        setSearch('')
    }

    // const selectorButtonObject = {
    //     params: [
    //         {
    //             component: FilterBarSelectorButton,
    //             options: shopifyVendorNames?.map((vendorName: string) => {
    //                 return {
    //                     value: vendorName,
    //                     label: vendorName,
    //                     icon: undefined,
    //                 }
    //             }),
    //             title: 'Vendors',
    //             field: 'vendors',
    //             values: filters.vendors,
    //             searchToggle: true,
    //             editSearchParams: true,
    //         },
    //     ],
    //     setFunction: setFilters,
    //     resetFunction: () => {
    //         setFilters({
    //             vendors: [],
    //         })
    //     },
    // }
    return (
        <div className='flex justify-between border-b border-lightgrey dark:border-darkgrey items-center pr-[8px] rounded-t-[4px] rounded-tl-[0px] dark:bg-darkaccent'>
            <FilterToolBar
                handleClearSearch={handleClearSearch}
                search={search}
                setSearch={setSearch}
                filterBarObject={companyFilterBarObject}
                placeholder='Group name, Product title, SKU, Id'
            >
                {/* {isFetching && <FetchingSpinner isFetching={isFetching} />} */}
            </FilterToolBar>
        </div>
    )
}
