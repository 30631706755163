import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { CustomerAddressType } from 'customersV2/Customers.types'
import { vFetch } from 'helpers'
import Input from 'procurement/components/Input'
import { useEffect, useRef, useState } from 'react'

export default function CreateAddressModal({
    toggleModal,
    customerId,
}: {
    toggleModal: () => void
    customerId: string
}) {
    const modalRef = useRef<HTMLDivElement>(null)
    const [editedFields, setEditedFields] = useState<any>({})

    const queryClient = useQueryClient()
    const createAddressMutation = useMutation({
        mutationFn: async ({ address }: { address: CustomerAddressType }) =>
            vFetch('/v2/customers/addresses', {
                method: 'POST',
                body: JSON.stringify({
                    customer_id: customerId,
                    address,
                }),
            }),
        onSuccess: () => {
            setEditedFields({})
            toggleModal()
            return queryClient.invalidateQueries({ queryKey: ['customers', customerId] })
        },
    })

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement }) => {
        setEditedFields((prev: any) => ({ ...prev, [target.name]: target.value }))
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (modalRef.current === e.target) {
                toggleModal()
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <div
            ref={modalRef}
            className='fixed top-0 left-0 w-full h-full bg-black/50 z-50 flex items-center justify-center'
        >
            <div className='p-4 rounded shadow-md bg-white dark:bg-darkbg1 w-[400px] max-h-[90%] overflow-auto'>
                <div className='flex flex-col gap-2'>
                    <h2 className='font-bold text-lg'>Create New Address</h2>
                    <Input
                        id='company'
                        name='company'
                        type='text'
                        value={editedFields.company || ''}
                        onChange={handleChange}
                        label='Company'
                    />
                    <Input
                        id='first_name'
                        name='first_name'
                        type='text'
                        value={editedFields.first_name || ''}
                        onChange={handleChange}
                        label='First Name'
                    />
                    <Input
                        id='last_name'
                        name='last_name'
                        type='text'
                        value={editedFields.last_name || ''}
                        onChange={handleChange}
                        label='Last Name'
                    />
                    <Input
                        id='address1'
                        name='address1'
                        type='text'
                        value={editedFields.address1 || ''}
                        onChange={handleChange}
                        label='Address'
                    />
                    <Input
                        id='address2'
                        name='address2'
                        type='text'
                        value={editedFields.address2 || ''}
                        onChange={handleChange}
                        label='Address Line 2'
                    />
                    <Input
                        id='city'
                        name='city'
                        type='text'
                        value={editedFields.city || ''}
                        onChange={handleChange}
                        label='City'
                    />
                    <Input
                        id='province_code'
                        name='province_code'
                        type='text'
                        value={editedFields.province_code || ''}
                        onChange={handleChange}
                        label='State / Province Code'
                    />
                    <Input
                        id='zip'
                        name='zip'
                        type='text'
                        value={editedFields.zip || ''}
                        onChange={handleChange}
                        label='Zip'
                    />
                    <Input
                        id='country'
                        name='country'
                        type='text'
                        value={editedFields.country || ''}
                        onChange={handleChange}
                        label='Country'
                        required={true}
                    />
                    <div
                        className={`flex gap-2 items-center justify-center ${
                            createAddressMutation.isPending ? 'opacity-50 pointer-events-none' : ''
                        }`}
                    >
                        <Button onClick={toggleModal}>Cancel</Button>
                        <Button onClick={() => createAddressMutation.mutate({ address: editedFields })}>
                            {createAddressMutation.isPending ? 'Working...' : 'Save'}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
