import { useEffect, useState } from 'react'

export default function ReturnForm({ product, selectedProducts, setSelectedProducts }) {
    const [reason, setReason] = useState('')
    const [returnMethod, setReturnMethod] = useState('')
    const [needsReturn, setNeedsReturn] = useState(false)
    useEffect(() => {
        const newProduct = {
            ...product,
            task_details: {
                type: 'Customer Service',
                topic: 'Return',
                needsReturn,
                reason,
                returnMethod,
            },
        }
        const productIndex = selectedProducts.findIndex((p) => p.title === product.title)
        const newProducts = structuredClone(selectedProducts)
        newProducts[productIndex] = newProduct
        setSelectedProducts(newProducts)
    }, [reason, returnMethod, needsReturn])
    return (
        <div className='new-task__products__product__form'>
            <div className='new-task__form__row'>
                <div className='new-task__form__checkbox-container'>
                    <input
                        className='new-task__form__checkbox'
                        type='checkbox'
                        checked={needsReturn}
                        onChange={(e) => setNeedsReturn(e.target.checked)}
                    />
                    <label className='new-task__form__checkbox-label'>Return?</label>
                </div>
                <select value={returnMethod} onChange={(e) => setReturnMethod(e.target.value)}>
                    <option value=''>Reimbursement Method</option>
                    <option value='refund'>Refund</option>
                    <option value='credit'>Credit</option>
                    <option value='swap'>Swap</option>
                </select>
                <select value={reason} onChange={(e) => setReason(e.target.value)}>
                    <option value=''>Reason For Return</option>
                    <option value='buyers-remorse'>Buyer's Remorse</option>
                    <option value='wrong-item'>Wrong Item</option>
                    <option value='damage'>Damage</option>
                    <option value='doa'>DOA</option>
                </select>
            </div>
        </div>
    )
}
