import { useSelector } from 'react-redux'
import { Dispatch, SetStateAction } from 'react'
import { useTickets } from '../api/useQueries'
import TicketRow from './TicketRow'

export default function Tickets({
    triage,
    setQuickModal,
}: {
    triage?: boolean
    setQuickModal: Dispatch<SetStateAction<undefined | string>>
}) {
    const user = useSelector((state: any) => state.user)
    const ticketsQuery = useTickets()
    const { data: tickets, isPending } = ticketsQuery
    const triageTickets = (tickets || []).filter((task: any) =>
        triage ? task.status === 'triage' : task.status !== 'triage'
    )
    return (
        <div className='rounded shadow-lg w-full border border-lightgrey dark:border-darkaccent'>
            <div className='flex justify-between dark:bg-darkaccent rounded-t py-2 px-4'>
                <h2 className='font-bold text-[18px] leading-[25.5px]'>{triage ? 'Triage' : 'Tickets'}</h2>
                {triage && (
                    <button
                        className='h-fit bg-blue dark:bg-accent text-white text-[14px] dark:text-darkaccent font-bold py-1 px-2 leading-tight rounded'
                        onClick={() => setQuickModal('ticket')}
                    >
                        + New Ticket
                    </button>
                )}
            </div>
            <div className='w-full h-[1px] bg-blue dark:bg-slate'></div>
            {isPending ? (
                <p className='p-4'>Loading...</p>
            ) : (
                <>
                    {triageTickets.length === 0 ? (
                        <p className='p-4'>There are no {triage ? 'pending' : 'active'} user requests.</p>
                    ) : (
                        triageTickets.map((ticket: any, index: number) => (
                            <TicketRow task={ticket} last={index === triageTickets.length - 1} />
                        ))
                    )}
                </>
            )}
        </div>
    )
}
