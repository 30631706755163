import { createSlice } from '@reduxjs/toolkit'

const version = process.env.REACT_APP_VERSION

const appVersionSlice = createSlice({
    name: 'appVersion',
    initialState: {
        version,
        origin: window.location.origin,
    },
    reducers: {
        setVersion: (state, action) => {
            state = action.payload
        },
    },
})

export const getApp = (state) => state.appVersion
export const { setVersion } = appVersionSlice.actions
export default appVersionSlice.reducer
