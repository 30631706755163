import { ProcessingTimesRow } from '../../processingTimes/ProcessingTimesScreen.types'

export default function PreviewBox({ processingTime }: PreviewBoxProps) {
    const onSale = processingTime?.pdp_line_1?.includes(':') || processingTime?.cart_line_1?.includes(':')
    const saleBg = `linear-gradient(45deg,rgba(197,28,29,0) calc(100% - 120px),#c51c14 0),linear-gradient(45deg,rgba(197,28,28,0) calc(100% - 127px),rgba(197,28,0,.5) calc(100% - 127px)),linear-gradient(0deg,#18b71c,#92c50f)`
    const normalBg = `linear-gradient(0deg,#18b71c,#92c50f)`
    const copyCode = processingTime?.pdp_line_1?.split(':')[1]
    const lineOne = `${processingTime?.pdp_line_1?.split(':')[0]}${onSale ? `:` : ''}`
    const lineTwo = processingTime?.pdp_line_2

    return (
        <>
            {processingTime?.pdp_line_1 && (
                <div className='dark:text-black rounded-[4px] border border-grey dark:opacity-[0.9] w-full max-w-[600px] mx-auto'>
                    <div
                        className='flex justify-between p-[4px] px-[8px]'
                        style={onSale ? { background: saleBg } : { background: normalBg }}
                    >
                        <div className='flex gap-[8px] items-center'>
                            <CheckBox />
                            <p className='text-white font-bold text-[20px]'>IN STOCK</p>
                        </div>
                        {onSale && (
                            <div className='flex gap-[8px] items-center'>
                                <SaleTag />
                                <p className='text-white font-bold text-[20px]'>ON SALE</p>
                            </div>
                        )}
                    </div>
                    <div className='p-[16px] bg-white dark:bg-offwhite flex gap-[16px] items-center'>
                        <Truck />
                        <div>
                            <div className='flex gap-[8px] flex-wrap font-bold leading-[1.25] uppercase'>
                                <p>
                                    {lineOne}
                                    {copyCode && (
                                        <>
                                            <span className='text-red'>{copyCode}</span>
                                            <CopyButton code={copyCode} />
                                        </>
                                    )}
                                </p>
                            </div>
                            <p>{lineTwo}</p>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

function CheckBox() {
    return (
        <img
            src='https://cdn.shopify.com/s/files/1/1163/1976/files/in-stock-check.png?v=1607959434'
            className='w-[24px] h-[24px]'
        />
    )
}
function SaleTag() {
    return (
        <img
            src='https://cdn.shopify.com/s/files/1/1163/1976/files/tags.png?v=1666125305'
            className='w-[24px] h-[24px] invert'
        />
    )
}
function Truck() {
    return (
        <img
            src='https://cdn.shopify.com/s/files/1/1163/1976/files/shipping-icon.png?v=1604542161'
            className='w-[80px] h-[40px]'
        />
    )
}
function CopyButton({ code }: CopyButtonProps) {
    return (
        <>
            {code && (
                <button className='px-[2px] ml-[4px] rounded-[4px] bg-lightgrey border border-grey text-[13px]'>
                    COPY
                </button>
            )}
        </>
    )
}

type PreviewBoxProps = {
    processingTime: ProcessingTimesRow | null
}
type CopyButtonProps = {
    code: string | null
}
