import { CaretDownIcon, CaretSortIcon, CaretUpIcon } from '@radix-ui/react-icons'
import { Button } from 'custom_components/component_Basics/Button'
import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import { NoResults } from 'custom_components/NoResults'
import { cn, useDebounce } from 'helpers'
import { useCompaniesQuery, useSearchCompanies, useUnfulfilledLineItemsQuery } from 'procurement/api/useQueries'
import FilterToolBar from 'procurement/components/FilterToolBar'
import CompanyV2Skeleton from 'procurement/components/listItems/CompanyV2Skeleton'
import CompanyQuickModal from 'procurement/components/modals/CompanyQuickModal'
import { COMPANY_SORT_OPTIONS, defaultCompanyFilterParams } from 'procurement/constants'
import { useEffect, useState } from 'react'
import { AiFillInfoCircle } from 'react-icons/ai'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import { GrRefresh } from 'react-icons/gr'
import { VENDOR_TYPE_OPTIONS } from 'vendors/constants'
import VendorV2 from './VendorV2'

export default function VendorListV2({
    userSelectedCompanyFilterValues,
    setUserSelectedCompanyFilterValues,
    setTaskFilters,
}: {
    userSelectedCompanyFilterValues: any
    setUserSelectedCompanyFilterValues: Function
    setTaskFilters: any
}) {
    const [cursorList, setCursorList] = useState<any>([0])
    const [selectedLastCursor, setSelectedLastCursor] = useState<any>(undefined)
    const [sortIndex, setSortIndex] = useState(1)
    const [activeSort, setActiveSort] = useState<string | undefined>('name')
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const [showListQuickModal, setShowListQuickModal] = useState<string | undefined>()
    const [selectedLifecycle, setSelectedLifecycle] = useState(undefined)

    const [hasUnfulfilledFilter, setHasUnfulfilledFilter] = useState(false)
    const [unfulfilledSortIndex, setUnfulfilledSortIndex] = useState(0)

    const debouncedSearch = useDebounce(search, 200)
    const skeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    let sortOption
    if (activeSort) {
        sortOption = COMPANY_SORT_OPTIONS[activeSort as keyof typeof COMPANY_SORT_OPTIONS][sortIndex].value || undefined
    }

    const companyFilterBarObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: VENDOR_TYPE_OPTIONS,
                title: 'Vendor Type',
                field: 'vendor_types',
                values: userSelectedCompanyFilterValues.vendor_types,
                searchToggle: false,
            },
        ],
        setFunction: setUserSelectedCompanyFilterValues,
        resetFunction: () =>
            setUserSelectedCompanyFilterValues({
                lead_types: [],
                lifecycle_statuses: [],
                users: [],
            }),
    }

    useEffect(() => {
        setSelectedLastCursor(undefined)
        setCursorList([0])
        setPage(1)
    }, [search, userSelectedCompanyFilterValues, activeSort, sortIndex])
    const unfulfilledLineItemsQuery = useUnfulfilledLineItemsQuery(undefined, undefined)
    const { unfulfilledLineItems } = unfulfilledLineItemsQuery?.data || {}
    const unfulfilledVendorNames: string[] = Array.from(
        new Set(unfulfilledLineItems?.filter((v: any) => v.vendor)?.map((lineItem: any) => lineItem.vendor))
    )

    const filters = {
        ...defaultCompanyFilterParams,
        lastCursor: selectedLastCursor || undefined,
        assigned_to: userSelectedCompanyFilterValues.users,
        lead_type: userSelectedCompanyFilterValues.lead_types,
        lifecycle_status: userSelectedCompanyFilterValues.vendor_types,
        pageParam: page,
        limit: hasUnfulfilledFilter ? 1000 : 100,
        sortOption: activeSort ? sortOption : undefined,
        unfulfilled: hasUnfulfilledFilter,
        unfulfilledVendorNames: hasUnfulfilledFilter ? unfulfilledVendorNames : undefined,
    }
    const companiesQuery = useCompaniesQuery({ filters, search: debouncedSearch })
    const { isLoading: loading, isFetching: fetching } = companiesQuery
    const { companies: companiesNoSearch, lastCursor } = companiesQuery?.data || []

    const companiesSearchQuery = useSearchCompanies({ filters, search: debouncedSearch })
    const { isLoading: loadingSearch, isFetching: fetchingSearch } = companiesSearchQuery
    const { companies: companiesSearch, lastCursor: lastCursorSearch } = companiesSearchQuery?.data || []

    const companies = debouncedSearch ? companiesSearch : companiesNoSearch
    const currentCursor = debouncedSearch ? lastCursorSearch : lastCursor
    const isFetching = debouncedSearch ? fetchingSearch : fetching
    const isLoading = debouncedSearch ? loadingSearch : loading

    const unfulfilledSortDesc = (a: any, b: any) => {
        return b.unfulfilled_line_items_count - a.unfulfilled_line_items_count
    }
    const unfulfilledSortAsc = (a: any, b: any) => {
        return a.unfulfilled_line_items_count - b.unfulfilled_line_items_count
    }

    companies?.forEach((company: any) => {
        const relatedUnfulfilledLineItems = unfulfilledLineItems
            ? unfulfilledLineItems
                  ?.filter((v: any) => v.vendor)
                  ?.filter(
                      (lineItem: any) => lineItem?.vendor?.toLowerCase() === company?.shopify_vendor_name?.toLowerCase()
                  )
            : []
        company.unfulfilled_line_items_count = relatedUnfulfilledLineItems.length
    })

    if (!isFetching && !isLoading) {
        if (currentCursor && !(currentCursor[2] === null)) {
            if (!cursorList.includes(currentCursor)) {
                setCursorList((prev: any[]) => {
                    return [...prev, currentCursor]
                })
            }
        }
    }

    function handlePageIncrease() {
        if (!(currentCursor[2] === null)) {
            setSelectedLastCursor(currentCursor)
            setPage((prev: any) => {
                return prev + 1
            })
        }
    }
    function handlePageDecrease() {
        setSelectedLastCursor(cursorList[page - 2] || undefined)
        setPage((prev: any) => Math.max(prev - 1, 1))
    }
    function handleSort(sort: string) {
        setSelectedLastCursor(undefined)
        setCursorList([0])
        setActiveSort(sort)
        if (sort !== activeSort) {
            setSortIndex(1)
        } else if (sortIndex < 2) {
            setSortIndex((prev: any) => prev + 1)
        } else {
            setSortIndex(0)
        }
    }
    const sortedCompanies =
        unfulfilledSortIndex === 0
            ? companies
            : unfulfilledSortIndex === 1
              ? companies
                  ? [...companies].sort(unfulfilledSortDesc)
                  : []
              : companies
                ? [...companies].sort(unfulfilledSortAsc)
                : []

    return (
        <>
            <FilterToolBar search={search} setSearch={setSearch} filterBarObject={companyFilterBarObject}>
                <Button
                    disabled={companiesQuery.isLoading}
                    onClick={() =>
                        setHasUnfulfilledFilter((prev: any) => {
                            prev ? setUnfulfilledSortIndex(0) : setUnfulfilledSortIndex(1)
                            return prev ? false : true
                        })
                    }
                    className={cn(hasUnfulfilledFilter && 'border-accent2', 'max-h-[29.5px]')}
                    variant={'outline'}
                    size={'sm'}
                >
                    Unfulfilled Items
                </Button>
                {companyFilterBarObject.params.length > 0 &&
                    companyFilterBarObject.params.map((param: any) => {
                        return (
                            <param.component
                                key={param.title + param.field}
                                searchToggle={param.searchToggle}
                                title={param.title}
                                field={param.field}
                                options={param.options}
                                filterValues={param.values}
                                setFilterValues={companyFilterBarObject.setFunction}
                            />
                        )
                    })}
            </FilterToolBar>
            <div>
                <div className='sticky top-[50px] z-[10] grid grid-cols-[56px_300px_200px_74px_1fr_1fr_3fr] col-span-full border-b border-lightgrey bg-blue text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                    <div className='font-bold p-[8px] uppercase text-[12px]'></div>
                    <button
                        onClick={() => {
                            if (hasUnfulfilledFilter) {
                                setUnfulfilledSortIndex(0)
                            }
                            handleSort('name')
                        }}
                        className='font-bold p-[8px] pr-0 uppercase text-[12px] flex gap-2'
                    >
                        <span>Name</span>
                        {activeSort === 'name' &&
                            COMPANY_SORT_OPTIONS.name.map((option, index) => {
                                if (sortIndex === index)
                                    return (
                                        <option.icon
                                            key={option.label}
                                            className={`${cn(
                                                index !== 0 && 'text-accent2 dark:text-accent2'
                                            )}self-center min-h-[18px]`}
                                        />
                                    )
                            })}
                        {activeSort !== 'name' && <CaretSortIcon />}
                    </button>
                    <div className='font-bold p-[8px] uppercase text-[12px]'>Phone</div>
                    <button
                        onClick={() => {
                            if (hasUnfulfilledFilter) {
                                setUnfulfilledSortIndex(0)
                            }
                            handleSort('overall_score')
                        }}
                        className='font-bold p-[8px] pr-0 uppercase text-[12px] flex gap-2 col-start-4'
                    >
                        <span>Score</span>
                        {activeSort === 'overall_score' &&
                            COMPANY_SORT_OPTIONS.overall_score.map((option, index) => {
                                if (sortIndex === index) {
                                    return (
                                        <option.icon
                                            className={`${cn(
                                                index !== 0 && 'text-accent2 dark:text-accent2'
                                            )}self-center min-h-[18px]`}
                                        />
                                    )
                                }
                            })}
                        {activeSort !== 'overall_score' && <CaretSortIcon />}
                    </button>
                    <button
                        onClick={() => {
                            setUnfulfilledSortIndex((prev) => {
                                if (prev === 2) {
                                    return 0
                                }
                                return prev + 1
                            })
                        }}
                        className='font-bold p-[8px] pr-0 uppercase text-[12px] flex gap-2 col-start-5'
                    >
                        <div className='relative flex gap-1 peer items-center'>
                            <span>Unfulfilled Items</span>
                            <AiFillInfoCircle className='peer cursor-help mb-[2px]' />
                            <div className='dark:bg-darkaccent opacity-0 peer-hover:opacity-100 dark:border-[1px] absolute pointer-events-none top-[24px] z-[5] bg-white shadow-small p-2 min-w-[100px] rounded-md font-normal normal-case'>
                                <p className=' text-text1 dark:text-darktext1 '>Last 90 days</p>
                            </div>
                        </div>
                        {unfulfilledSortIndex === 1 && <CaretDownIcon />}
                        {unfulfilledSortIndex === 2 && <CaretUpIcon />}
                        {unfulfilledSortIndex === 0 && <CaretSortIcon />}
                    </button>
                    <div className='font-bold p-[8px] uppercase text-[12px]'>Lifecycle Stage</div>
                    <div className='font-bold p-[8px] uppercase text-[12px]'>Latest Note</div>
                    <div className='absolute right-4 top-1/4'>
                        <GrRefresh
                            className={cn(
                                isFetching && 'animate-spin opacity-100',
                                !isFetching && 'opacity-0',
                                '[&>*]:stroke-offwhite [&>*]:dark:stroke-offwhite '
                            )}
                        />
                    </div>
                </div>
                {(isLoading || fetchingSearch) &&
                    skeletons.map((skeleton) => {
                        return <CompanyV2Skeleton key={skeleton} />
                    })}
                {!isLoading &&
                    sortedCompanies?.map((company: any) => {
                        return (
                            <VendorV2
                                key={company.id}
                                company={company}
                                unfulfilledLineItems={unfulfilledLineItems}
                                showListQuickModal={showListQuickModal}
                                setShowListQuickModal={setShowListQuickModal}
                                setSelectedLifecycle={setSelectedLifecycle}
                            />
                        )
                    })}
                {!isFetching && companies?.length === 0 && (
                    <NoResults margin='mx-auto' content='No Results For Selected Filters' />
                )}
                <div className='flex fixed bottom-[8px] left-[calc(50%+216px)] translate-x-[calc(-50%-108px)] p-[8px] bg-white dark:bg-darkness border-[1px] border-darkgrey gap-[16px] justify-center items-center mt-[16px] rounded'>
                    <>
                        <button
                            disabled={page === 1}
                            className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                            onClick={() => {
                                handlePageDecrease()
                            }}
                        >
                            <BiLeftArrowAlt className='fill-darkgrey dark:fill-accent' />
                        </button>
                    </>
                    <div className='font-bold dark:text-offwhite'>Current Page: {page}</div>
                    <button
                        className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                        onClick={() => {
                            handlePageIncrease()
                        }}
                        disabled={isLoading || isFetching || companies?.length < 25}
                    >
                        <BiRightArrowAlt className='fill-darkgrey dark:fill-accent' />
                    </button>
                </div>
                {showListQuickModal && (
                    <CompanyQuickModal
                        selectedLifecycle={selectedLifecycle}
                        showQuickModal={showListQuickModal}
                        setShowQuickModal={setShowListQuickModal}
                    />
                )}
            </div>
        </>
    )
}
