import { Button } from 'custom_components/component_Basics/Button'
import { Link } from 'react-router-dom'

export default function Association({ association, id }: { association: any; id: any }) {
    let name = `${association.resource_name} ${association.resource_id}`
    let link = `/${association.resource_name}/${association.resource_id}`
    switch (association.resource_name) {
        case 'order':
            link = `/orders/${association.resource_id}`
            break
        case 'draft_order':
            link = `/draftOrders/${association.resource_id}`
            break
        case 'project':
            link = `/tasksv2/projects/${association.resource_id}`
            break
        case 'customer':
            link = `/customers?customer_id=${association.resource_id}`
            break
        case 'customer_tax_exemption':
            link = `/tax-exemptions/${association.resource_id}`
            break
        case 'roadmap':
            link = `/tasksv2/roadmaps/${association.resource_id}`
            break
    }
    return (
        <Button
            size={'sm'}
            variant={'outline'}
            className='px-1 py-0 h-6 hover:bg-bg1 hover:scale-[1.03] transition-all'
        >
            <Link onClick={(e) => e.stopPropagation()} key={`${id}` + `${association.resource_id}`} to={link}>
                {association.readable_name}
            </Link>
        </Button>
    )
}
