import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { MetafieldProps } from '../ProductSetup.types'
import { vFetch } from '../../helpers'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import Input from '../../procurement/components/Input'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { FaTimes, FaTrash } from 'react-icons/fa'
import { BiRefresh } from 'react-icons/bi'

export default function Tags({ product }: any) {
    const bulkDeleteModalRef = useRef<HTMLDivElement>(null)
    const [search, setSearch] = useState('')
    const [showMoreActions, setShowMoreActions] = useState(false)
    const [showBulkModal, setShowBulkModal] = useState(false)
    const [selectedBulkTags, setSelectedBulkTags] = useState<string[]>([])
    const tagsQuery = useQuery({
        queryKey: ['product', 'tags', product.id],
        queryFn: () =>
            vFetch(`/v2/products/tags?product_id=${product.id}&product_type=${product.product_type}`, {
                cb: (res: any) => {
                    if (!res.success) {
                        return []
                    }
                    return res.tags
                },
                catchCb: () => [],
            }),
    })
    const allTagsQuery = useQuery({
        queryKey: ['product', 'tags', 'all', product.id],
        queryFn: () =>
            vFetch(`/v2/products/tags?product_id=${product.id}`, {
                cb: (res: any) => {
                    if (!res.success) {
                        return []
                    }
                    return res.tags
                },
                catchCb: () => [],
            }),
    })
    const queryClient = useQueryClient()
    const addTagsMutation = useMutation({
        mutationFn: (tag: string) =>
            vFetch('/v2/products/tags', {
                method: 'POST',
                body: JSON.stringify({
                    product_id: product.id,
                    tags: [tag],
                }),
            }),
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: ['product', 'tags', product.id] })
        },
    })
    const removeTagsMutation = useMutation({
        mutationFn: (tag: string) =>
            vFetch('/v2/products/tags', {
                method: 'DELETE',
                body: JSON.stringify({
                    product_id: product.id,
                    tags: [tag],
                }),
            }),
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: ['product', 'tags', product.id] })
        },
    })
    const bulkRemoveTagsMutation = useMutation({
        mutationFn: (tags: string[]) =>
            vFetch('/v2/products/tags/bulk', {
                method: 'DELETE',
                body: JSON.stringify({
                    tags,
                }),
            }),
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: ['product', 'tags'] })
        },
    })
    const tags = tagsQuery.data || []
    const allTags = allTagsQuery.data || []
    const selectedTags: { tag: string; is_selected: 1 }[] = tags.filter(
        (tag: { tag: string; is_selected: 0 | 1 }) =>
            tag.is_selected && tag.tag.toLowerCase().includes(search.toLowerCase())
    )
    const nonSelectedTags: { tag: string; is_selected: 0 }[] = (search.length ? allTags : tags).filter(
        (tag: { tag: string; is_selected: 0 | 1 }) =>
            !tag.is_selected && tag.tag.toLowerCase().includes(search.toLowerCase())
    )
    const handleShowBulkModal = () => {
        setShowBulkModal(true)
        setShowMoreActions(false)
    }
    const handleToggleTag = ({ target }: ChangeEvent<HTMLInputElement>) => {
        if (target.checked) {
            setSelectedBulkTags((previousState) => {
                const newState = structuredClone(previousState)
                newState.push(target.value)
                return newState
            })
        } else {
            setSelectedBulkTags((previousState) => previousState.filter((tag) => tag !== target.value))
        }
    }
    const handleRefreshTags = () => {
        queryClient.invalidateQueries({ queryKey: ['product', 'tags'] })
        setShowMoreActions(false)
    }
    const handleBulkDelete = () => {
        bulkRemoveTagsMutation.mutate(selectedBulkTags)
        setSelectedBulkTags([])
        setShowBulkModal(false)
    }
    useEffect(() => {
        const listenToWindow = (e: any) => {
            if (e.target === bulkDeleteModalRef.current) {
                setShowBulkModal(false)
            }
        }
        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    return (
        <div className='w-full dark:bg-darkaccent p-[16px] rounded-[4px] flex flex-col shadow-small overflow-hidden'>
            <p className='mt-[-16px] ml-[-16px] mb-[16px] p-[4px] leading-[1] w-fit p-[2px] text-[12px] uppercase font-bold bg-blue dark:bg-accent text-white dark:text-darkness'>
                Tags
            </p>
            {tagsQuery.isPending ? (
                <p>Loading...</p>
            ) : (
                <div className='flex flex-col gap-2 justify-center'>
                    <div className='flex gap-2'>
                        <Input
                            id='search'
                            name='search'
                            type='text'
                            placeholder='Search or add new tags...'
                            value={search}
                            onChange={({ target }) => setSearch(target.value)}
                        />
                        <div className='relative flex items-center'>
                            <button
                                className='text-2xl'
                                onClick={() => setShowMoreActions((previousState) => !previousState)}
                            >
                                {showMoreActions ? <FaTimes /> : <BsThreeDotsVertical />}
                            </button>
                            {showMoreActions && (
                                <div className='absolute top-full right-0 p-2 border border-lightgrey dark:border-darkgrey bg-white dark:bg-darkbg1 rounded shadow-md'>
                                    <button
                                        onClick={handleRefreshTags}
                                        className='whitespace-nowrap flex items-center gap-1 mb-1'
                                    >
                                        <BiRefresh className='w-4' /> Refresh Tag List
                                    </button>
                                    <button
                                        onClick={handleShowBulkModal}
                                        className='whitespace-nowrap flex items-center gap-1'
                                    >
                                        <FaTrash className='text-xs w-4' /> Bulk Remove Tags
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='flex gap-4'>
                        <div className='flex flex-col gap-2 w-full'>
                            <h2 className='font-bold'>
                                {!search.length
                                    ? `Common Tags For ${product.product_type}`
                                    : 'Searching all unselected tags'}
                            </h2>
                            <div className='flex gap-2 flex-wrap'>
                                {search.length > 0 &&
                                    !tags.find((tag: any) => tag.tag.toLowerCase() === search.toLowerCase()) && (
                                        <button
                                            onClick={() => addTagsMutation.mutate(search)}
                                            disabled={addTagsMutation.variables === search && addTagsMutation.isPending}
                                            className='disabled:opacity-50 rounded-[99vw] py-1 px-2 bg-lightgrey dark:bg-darkgrey leading-none text-md'
                                        >
                                            <span className='font-bold'>+ New Tag</span> {search}
                                        </button>
                                    )}
                                {nonSelectedTags.map((tag) => (
                                    <button
                                        onClick={() => addTagsMutation.mutate(tag.tag)}
                                        disabled={addTagsMutation.variables === tag.tag && addTagsMutation.isPending}
                                        className='disabled:opacity-50 flex gap-1 items-center rounded-[99vw] py-1 px-2 font-bold bg-lightgrey dark:bg-darkgrey leading-none text-md'
                                    >
                                        {tag.tag}
                                        <span className='font-normal'>+</span>
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div className='flex flex-col gap-2 w-full'>
                            <h2 className='font-bold'>Selected Tags</h2>
                            <div className='flex gap-2 flex-wrap'>
                                {selectedTags.map((tag) => (
                                    <button
                                        onClick={() => removeTagsMutation.mutate(tag.tag)}
                                        disabled={
                                            removeTagsMutation.variables === tag.tag && removeTagsMutation.isPending
                                        }
                                        className='disabled:opacity-50 flex gap-1 items-center rounded-[99vw] py-1 px-2 font-bold bg-blue dark:bg-fire text-white dark:text-darkaccent leading-none text-md'
                                    >
                                        {tag.tag}
                                        <span className='font-normal'>&times;</span>
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                    {showBulkModal && (
                        <div
                            ref={bulkDeleteModalRef}
                            className='fixed z-50 w-full h-full top-0 left-0 bg-black/50 flex items-center justify-center'
                        >
                            <div className='bg-white dark:bg-darkbg1 rounded shadow-md p-4 relative flex flex-col min-w-[400px] max-h-[600px]'>
                                <button
                                    onClick={() => setShowBulkModal(false)}
                                    className='text-xl text-red dark:text-lightred absolute top-1 right-1'
                                >
                                    <FaTimes />
                                </button>
                                <h2 className='mb-1 pb-1 border-b border-lightgrey dark:border-darkgrey font-bold'>
                                    Select tags to delete
                                </h2>
                                <div className='flex flex-col gap-2 overflow-auto'>
                                    {allTags.map((tag: any) => (
                                        <div className='flex gap-2 items-center'>
                                            <input
                                                id={`tag-${tag.tag}`}
                                                type='checkbox'
                                                checked={!!selectedBulkTags.find((t) => t === tag.tag)}
                                                value={tag.tag}
                                                onChange={handleToggleTag}
                                                className='cursor-pointer appearance-none w-4 h-4 border border-darkgrey checked:bg-darkgrey dark:checked:bg-offwhite rounded cursor-pointer'
                                            />
                                            <label className='cursor-pointer' htmlFor={`tag-${tag.tag}`}>
                                                {tag.tag}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                <div className='flex items-center justify-center p-2 pt-4'>
                                    <button
                                        onClick={handleBulkDelete}
                                        className='py-1 px-2 rounded bg-blue text-white dark:bg-accent dark:text-darkbg1 font-bold text-sm'
                                    >
                                        Remove Tags From All Products
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
