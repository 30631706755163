import { RowSelectionState } from '@tanstack/react-table'
import { TaskFilterValues } from 'procurement/Procurements.types'
import {
    useCompanyContactsQuery,
    useCompanyDetailTasksQuery,
    useCompanyNotesQuery,
    useDuplicateCompany,
} from 'procurement/api/useQueries'
import ResourceViewButton from 'procurement/components/ResourceViewButton'
import ActivityListV2 from 'procurement/components/lists/ActivityListV2'
import ContactListV2 from 'procurement/components/lists/ContactListV2'
import NoteListV2 from 'procurement/components/lists/NoteListV2'
import TaskListV2 from 'procurement/components/lists/TaskListV2'
import UnfulfilledOrdersList from 'procurement/components/lists/UnfulfilledOrdersList'
import VendorFileListV2 from 'procurement/components/lists/VendorFileListV2'
import CompanyQuickModal from 'procurement/components/modals/CompanyQuickModal'
import { CompanyContext, CompanyContextType, ProcurementContext, ProcurementContextType } from 'procurement/helpers'
import { useContext, useState } from 'react'
import { CgExport } from 'react-icons/cg'
import { FaSyncAlt } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { VENDOR_RESOURCE_VIEW_TABS } from 'vendors/constants/Vendors.types'
import { cn, useDebounce } from '../../helpers'
import PrivateEvent from '../../tasks/components/PrivateEvent'
import { PrivateEventType } from '../../tasks/tasks.types'
import { UserInit } from '../../users/users.types'
import VendorAccountingInfo from './VendorAccountingInfo'
import VendorInfoV2 from './VendorInfoV2'
import VendorQuickActions from './VendorQuickActions'

export default function VendorResourcesV2({
    company,
    companyEventsPrivate,
    setShowEventsPrivate,
    users,
    selectedLifecycle,
}: {
    company: any
    companyEventsPrivate: PrivateEventType[]
    setShowEventsPrivate: Function
    users: UserInit[]
    selectedLifecycle: string | undefined
}) {
    const { selectedCompany } = useContext<ProcurementContextType>(ProcurementContext)
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
    const [selectedLegacyContact, setSelectedLegacyContact] = useState()

    const { resourceView, setResourceView, showQuickModal, setShowQuickModal } =
        useContext<CompanyContextType>(CompanyContext)
    const { toggleModal } = useContext<ProcurementContextType>(ProcurementContext)

    const user = useSelector<any, any>((state) => state.user)
    const [search, setSearch] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()
    const urlParamTaskId = searchParams.get('task_id')
    const debouncedSearch = useDebounce(search, 300)

    const duplicateCompany = useDuplicateCompany()

    function handleDuplicateCompany() {
        duplicateCompany.mutate(
            { editableCompany: company },
            {
                onSuccess: () => {
                    toggleModal()
                },
            }
        )
    }

    const options = [
        {
            displayText: `Duplicate Company`,
            icon: FaSyncAlt,
            action: handleDuplicateCompany,
        },
        {
            displayText: `Export Product CSV`,
            icon: CgExport,
            action: () => setShowQuickModal('csv_export'),
        },
    ]

    const [userSelectedTaskFilterValues, setUserSelectedTaskFilterValues] = useState<TaskFilterValues>({
        statuses: urlParamTaskId ? [] : ['OPEN'],
        users: [],
        due_at_options: [],
    })

    const companyContactsQuery = useCompanyContactsQuery({ companyId: company.id })

    const companyEventsQuery = useCompanyNotesQuery({ companyId: company.id, users })

    const companyTasksQuery = useCompanyDetailTasksQuery({ companyId: company.id })

    return (
        <div className='flex w-full overflow-auto  '>
            <div
                className={cn(
                    'w-full',
                    (resourceView === 'contacts' || resourceView === 'unfulfilled orders') && 'overflow-auto'
                )}
            >
                <div className={cn('flex gap-4 sticky top-0 z-40 bg-bg1 dark:bg-darkbg1 ')}>
                    {(resourceView === 'contacts' || resourceView === 'unfulfilled orders') && (
                        <div
                            className={cn(
                                'flex flex-col gap-[8px] overflow-clip pr-[16px] w-1/4 shrink-0',
                                (resourceView === 'contacts' || resourceView === 'unfulfilled orders') && 'pr-0'
                            )}
                        >
                            <div className=' flex justify-between items-start'>
                                <div className='flex flex-col gap-2'>
                                    <h2 className='text-darkgrey dark:text-offwhite text-[18px] font-bold uppercase leading-[1]'>
                                        {company.name}
                                    </h2>
                                    {selectedCompany.parent_company && (
                                        <div className='border-lightgrey dark:border-darkgrey'>
                                            <button
                                                className='font-semibold uppercase text-[14px] leading-[1] py-[4px] px-[8px] w-full rounded-[4px] border border-blue text-blue dark:border-fire dark:text-fire'
                                                onClick={() => toggleModal('parent', selectedCompany)}
                                            >
                                                View Parent Company
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <VendorQuickActions resourceView={resourceView} />
                            </div>
                        </div>
                    )}
                    <div className='flex w-full justify-between'>
                        <div className='flex'>
                            {VENDOR_RESOURCE_VIEW_TABS.map((tab: string) => (
                                <ResourceViewButton
                                    key={tab}
                                    setResourceView={setResourceView}
                                    resourceView={resourceView}
                                    viewName={tab}
                                />
                            ))}
                        </div>

                        <div className='flex gap-2 mr-2'>
                            {user.type === 'Admin' ||
                                (user.type === 'SuperAdmin' && companyEventsPrivate.length > 0 && (
                                    <div
                                        className={cn(
                                            'fixed right-[4.5%] top-[3%]',
                                            'self-center',
                                            (resourceView === 'contacts' || resourceView === 'unfulfilled orders') &&
                                                'self-start'
                                        )}
                                    >
                                        <PrivateEvent
                                            events={companyEventsPrivate}
                                            setViewModal={setShowEventsPrivate}
                                            userList={users}
                                        />
                                    </div>
                                ))}
                            {/* <MoreActions options={options} displayContent={{ icon: FaBars }} /> */}
                        </div>
                    </div>
                </div>
                <>
                    {resourceView === 'activity' && <ActivityListV2 company={company} />}
                    {resourceView === 'unfulfilled orders' && (
                        <UnfulfilledOrdersList
                            company={company}
                            rowSelection={rowSelection}
                            setRowSelection={setRowSelection}
                        />
                    )}
                    {/* {resourceView === 'emails' && <EmailListV2 company={company} />} */}
                    {resourceView === 'notes' && <NoteListV2 />}
                    {resourceView === 'info' && <VendorInfoV2 />}
                    {resourceView === 'files' && <VendorFileListV2 />}
                    {resourceView === 'accounting' && <VendorAccountingInfo />}
                    {resourceView === 'tasks' && (
                        <div className=''>
                            <TaskListV2
                                company={company}
                                userSelectedTaskFilterValues={userSelectedTaskFilterValues}
                                setUserSelectedTaskFilterValues={setUserSelectedTaskFilterValues}
                                inModal={true}
                            />
                        </div>
                    )}
                    {resourceView === 'contacts' && (
                        <ContactListV2 setSelectedLegacyContact={setSelectedLegacyContact} />
                    )}
                    {/* {resourceView === 'files' && <FileListV2 />} */}
                </>
            </div>

            {showQuickModal && (
                <>
                    <CompanyQuickModal
                        selectedLegacyContact={selectedLegacyContact}
                        showQuickModal={showQuickModal}
                        setShowQuickModal={setShowQuickModal}
                        selectedLifecycle={selectedLifecycle}
                    />
                </>
            )}
        </div>
    )
}
