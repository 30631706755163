import { useSelector } from 'react-redux'
import { taskVariables } from '../TaskVariables'
import { PrivateEventType, TaskCreateInit, TaskEditInit, TaskEventType, topicMap } from '../tasks.types'
import { UserlistDropdown } from '../../custom_components/UserlistDropdown'
import { UserInit } from '../../users/users.types'
import { useEffect, useState } from 'react'
import { closePopout, formatDateToLocale, sendToast, taskIdRegex, vFetch } from '../../helpers'
import TaskDueAt from './dueAtField'
import { NavLink } from 'react-router-dom'
import Modal from '../../custom_components/Modal'
import { OrderInit } from '../../orders/orders.types'
import PrivateEventModal from './PrivateEventModal'
import PrivateEvent from './PrivateEvent'
import { startOfDay } from 'date-fns'

type TaskEditCreateModalProps = {
    task: TaskEditInit | TaskCreateInit
    setTask: Function
    possibleTypes?: string[]
    order: OrderInit
    setOrder: Function
    refreshProp?: Function
    userList: UserInit[]
    setLoading: Function
}

export default function TaskEditCreateModal({
    refreshProp = () => {},
    task,
    possibleTypes,
    order,
    setOrder,
    userList,
    setLoading,
}: TaskEditCreateModalProps) {
    const user = useSelector<any, any>((state) => state.user)
    const { taskTypeColors }: any = taskVariables
    const { priorityLevels } = taskVariables
    const [originalTask, setOriginalTask] = useState(task)
    const [newTask, setNewTask] = useState(task)
    const [edited, setEdited] = useState<boolean>(false)
    const [taskEvents, setTaskEvents] = useState<PrivateEventType[]>([])
    const [latestTaskEvent, setLatestTaskEvent] = useState<PrivateEventType | null>(null)
    const [showTaskEvents, setShowTaskEvents] = useState(false)
    const [showTaskActions, setShowTaskActions] = useState(false)
    const [showAreYouSureDelete, setShowAreYouSureDelete] = useState(false)
    const { id } = newTask as TaskEditInit

    const taskActions: any = {
        'Delete Task': () => setShowAreYouSureDelete(true),
    }

    function refreshTaskEvents() {
        if (id)
            vFetch(`/v1/taskEvents?task_id=${id}`, {
                cb: (res: any) => {
                    if (res.taskEvents) {
                        setTaskEvents(res.taskEvents)
                        setLatestTaskEvent(res.taskEvents.find((te: TaskEventType) => te.event_type === 'viewed'))
                    }
                },
            })
    }
    function refresh() {
        refreshProp()
        refreshTaskEvents()
    }
    useEffect(() => {
        refreshTaskEvents()
    }, [])
    useEffect(() => {
        setNewTask(task)
        setOriginalTask(task)
    }, [task])
    useEffect(() => {
        setOriginalTask(newTask)
    }, [(newTask as TaskEditInit)?.due_at_revisions])
    useEffect(() => {
        setEdited(JSON.stringify(originalTask) !== JSON.stringify(newTask))
    }, [newTask, originalTask])

    function handleSubmit() {
        const required: any = {
            task_type: (value: any) => !!value === true && value !== 'Select Type',
            title: (value: any) => !!value === true,
            topic: (value: any) => !!value === true,
            assigned_to: (value: string[]) => value.length > 0,
        }
        const failingValues = Object.keys(required).filter((key) => !required[key]((newTask as any)[key]))
        if (!!failingValues.length)
            return sendToast({ message: `Please fill enter a value for: ${failingValues.join(', ')}` })
        setLoading(true)
        if (id) {
            if (newTask.status !== task.status && newTask.status === 'OPEN') {
                newTask.assigned_to = [user.user_id]
            }
            return vFetch(`/v1/tasks/${id}`, {
                method: 'PUT',
                body: JSON.stringify({ ...newTask, due_at: newTask.due_at.toISOString() }),
                cb: (res: any) => {
                    setLoading(false)
                    if (res.success) refresh()
                },
                catchCb: (err: any) => setLoading(false),
            })
        } else {
            vFetch('/v1/tasks', {
                method: 'POST',
                body: JSON.stringify({ ...newTask, due_at: newTask.due_at.toISOString() }),
                cb: (res: any) => {
                    if (res.success) {
                        const oldNotes = (order.note || '').slice().trim()
                        const relatedTask = { ...newTask, id: res.task_id } as TaskEditInit
                        const abbreviatedType = newTask.task_type
                            .split(' ')
                            .filter((v) => v)
                            .map((w: string) => w[0].toUpperCase())
                            .join('')
                        const noteTasks =
                            oldNotes
                                .match(taskIdRegex)
                                ?.map((v) => v.split('\n'))
                                .flat()
                                .filter((v) => v)
                                .map((v) => v.trim()) || []
                        if (
                            !noteTasks?.find((noteTask) => noteTask === `${abbreviatedType}:${relatedTask?.id}`) &&
                            !abbreviatedType.match(/^[0-9]/)
                        )
                            noteTasks.push(`${abbreviatedType}:${relatedTask?.id}`)
                        const oldNotesWithoutTasks = oldNotes.replaceAll(taskIdRegex, '').trim()
                        const orderNotes = [noteTasks.join('\n'), oldNotesWithoutTasks].join('\n\n').trim()

                        vFetch(`/v1/orders/${order.id}`, {
                            method: 'POST',
                            body: JSON.stringify({
                                note: orderNotes,
                                task_id: relatedTask?.id,
                                task_type: relatedTask?.task_type,
                            }),
                            cb: (res: any) => {
                                setLoading(false)
                                if (res.success) setOrder({ ...order, note: orderNotes })
                            },
                            catchCb: (err: any) => setLoading(false),
                        })
                        refresh()
                    }
                },
                catchCb: (err: any) => setLoading(false),
            })
        }
    }
    function handleDelete() {
        setLoading(true)

        const abbreviatedType = `${[
            newTask.task_type
                .split(' ')
                .map((w) => w[0].toUpperCase())
                .join(''),
            (newTask as TaskEditInit).id,
        ].join(':')}`
        const oldNotes = order.note || ''

        const noteTasks =
            oldNotes
                .match(taskIdRegex)
                ?.map((v) => v.split('\n'))
                .flat()
                .filter((v) => v && v !== abbreviatedType) || []
        const oldNotesWithoutTasks = oldNotes.replace(taskIdRegex, '').trim()
        const orderNotes = [noteTasks.join('\n'), oldNotesWithoutTasks].join('\n\n').trim()

        vFetch(`/v1/tasks`, {
            method: 'DELETE',
            body: JSON.stringify({ order_id: order.id, id, note: orderNotes, task_type: newTask.task_type }),
            cb: (res: any) => {
                setLoading(false)
                if (res.success) {
                    setOrder({ ...order, note: orderNotes })
                    setShowAreYouSureDelete(false)
                    refresh()
                }
            },
            catchCb: (err: any) => setLoading(false),
        })
    }

    useEffect(() => {
        const listenToWindow = (e: any) => closePopout(e, [`js-newTask-actions__${id}`], setShowTaskActions)
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [id])

    const buttonStyle =
        'w-[80px] p-[8px] bg-blue hover:bg-blue/90 text-white font-semibold dark:bg-accent dark:hover:bg-accent/90 dark:text-black rounded shadow-small'
    const labelStyle = 'block dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
    const inputStyle =
        'bg-lightgrey dark:bg-darkness p-[4px] border-[1px] border-transparent focus:border-darkgrey focus:dark:border-accent outline-0 rounded'
    const taskTypeOptions = possibleTypes
        ? possibleTypes.filter((v) => v.toLowerCase() !== 'select type')
        : Object.keys(topicMap)

    return (
        <div>
            {taskEvents && showTaskEvents && (
                <PrivateEventModal
                    origin='task'
                    userList={userList}
                    setViewModal={setShowTaskEvents}
                    events={taskEvents}
                />
            )}
            {showAreYouSureDelete && (
                <Modal setShow={() => setShowAreYouSureDelete(false)}>
                    <div className='flex flex-col gap-[16px] p-[16px] bg-white dark:bg-darkaccent shadow-small rounded'>
                        <p>Are you sure you want to DELETE this newTask?</p>
                        <div className='p-[16px] bg-offwhite shadow-small dark:bg-darkness rounded'>
                            <div className='flex justify-between'>
                                <div className='flex gap-[8px]'>
                                    <span className={`font-bold ${taskTypeColors[newTask.task_type]}`}>
                                        {newTask.task_type}
                                    </span>
                                    <span className=''>#{id}</span>
                                </div>
                                <div className='flex flex-col '>
                                    {' '}
                                    <label className={labelStyle + ' pt-[8px]'}>Status</label>
                                    <span>{(newTask as TaskEditInit).status}</span>
                                </div>
                            </div>
                            <div className='flex flex-col'>
                                <label className={labelStyle + ' pt-[8px]'}>Latest Activity</label>
                                <p>{newTask.title}</p>
                            </div>
                            <div className='flex gap-[16px]'>
                                <div className='flex flex-col '>
                                    <label className={labelStyle + ' pt-[8px]'}>Due at</label>
                                    <span>{formatDateToLocale(newTask.due_at)}</span>
                                </div>
                                <div className='flex flex-col'>
                                    <label className={labelStyle + ' pt-[8px]'}>Assigned To</label>
                                    <span>{newTask.assigned_to.join(', ')}</span>
                                </div>
                                <div className='flex flex-col'>
                                    <label className={labelStyle + ' pt-[8px]'}>Priority</label>
                                    <span>{newTask.priority}</span>
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-between'>
                            <button
                                className={buttonStyle + ' !bg-red !text-white  hover:!bg-red/80'}
                                onClick={handleDelete}
                            >
                                Delete
                            </button>
                            <button className={buttonStyle} onClick={() => setShowAreYouSureDelete(false)}>
                                No
                            </button>
                        </div>
                    </div>
                </Modal>
            )}
            <div className='relative flex flex-col gap-[16px] p-[8px] bg-white dark:bg-darkaccent shadow-small rounded'>
                <div>
                    {(user.type === 'Admin' || user.type === 'SuperAdmin') && taskEvents.length > 0 && (
                        <div>
                            <PrivateEvent events={taskEvents} setViewModal={setShowTaskEvents} userList={userList} />
                        </div>
                    )}
                    <div className='flex flex-wrap-reverse justify-between'>
                        <div className={`flex flex-col gap-[8px]`}>
                            <div className='flex gap-[8px]'>
                                {id ? (
                                    <span className={`font-semibold ${taskTypeColors[newTask.task_type]}`}>
                                        {newTask.task_type}
                                    </span>
                                ) : (
                                    <select
                                        className={inputStyle}
                                        onChange={({ target }) => setNewTask({ ...newTask, task_type: target.value })}
                                        value={newTask.task_type}
                                    >
                                        <option className='hidden' value=''>
                                            Select Type
                                        </option>
                                        {taskTypeOptions.map((taskType: any) => (
                                            <option value={taskType}>{taskType}</option>
                                        ))}
                                    </select>
                                )}
                                {id && <div className='text-blue dark:text-accent hover:underline'>#{id}</div>}
                                {(newTask as TaskEditInit).status === 'COMPLETED' && (
                                    <div className='w-[24px] h-[24px]'>
                                        <svg
                                            className='w-[24px] h-[auto] fill-[#16a679] dark:fill-[rgb(150,255,175)] border-[3px] border-[#a1edd0] dark:border-[rgba(100,225,150,0.3)] rounded-full'
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 17 17'
                                            role='img'
                                            focusable='false'
                                            aria-hidden='true'
                                        >
                                            <path d='M8.5 17a8.36 8.36 0 1 1 8.35-8.36A8.35 8.35 0 0 1 8.5 17zm0-15a6.69 6.69 0 1 0 6.68 6.68A6.68 6.68 0 0 0 8.5 2z' />
                                            <path d='M7.66 11.09a.82.82 0 0 1-.59-.24L5.4 9.18A.84.84 0 0 1 5.45 8a.82.82 0 0 1 1.13 0l1.08 1.08 2.75-2.75a.83.83 0 0 1 1.18 1.18l-3.34 3.34a.82.82 0 0 1-.59.24z' />
                                        </svg>
                                    </div>
                                )}
                            </div>

                            <div className='flex flex-col gap-[4px]'>
                                <label className={labelStyle}>Topic</label>
                                <select
                                    className={inputStyle + ' !p-0'}
                                    onChange={({ target }) => setNewTask({ ...newTask, topic: target.value })}
                                    value={newTask.topic}
                                >
                                    <option className='hidden' value=''>
                                        Select Topic
                                    </option>
                                    {topicMap[newTask.task_type].map((topic: any) => (
                                        <option key={topic} value={topic}>
                                            {topic}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className='flex flex-col gap-[8px] items-end'>
                            {!!id && user.type.match(/admin/i) && (
                                <div className='relative'>
                                    <button
                                        className={`js-newTask-actions__${id} flex justify-center items-center relative w-[35px] h-[15px] text-grey hover:text-darkgrey dark:hover:text-offwhite`}
                                        onClick={() => setShowTaskActions(!showTaskActions)}
                                    >
                                        <span className='js-order__dot-button text-[20px] font-black tracking-wider leading-[0px] bottom-[4px] relative'>
                                            ...
                                        </span>
                                    </button>

                                    {showTaskActions && (
                                        <div className='absolute top-full right-0 flex flex-col p-[8px] bg-white dark:bg-darkaccent shadow-small gap-[8px] border-[1px] border-darkgrey'>
                                            <h3 className='text-[14px] font-semibold'>Admin Actions</h3>
                                            <div className='flex flex-col border-grey border-[1px]'>
                                                {Object.keys(taskActions).map((action) => (
                                                    <div
                                                        className='hover:bg-black/20 px-[8px] py-[2px] cursor-pointer whitespace-nowrap'
                                                        onClick={() => taskActions[action]()}
                                                    >
                                                        {action}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            <div className='flex gap-[8px] items-center'>
                                {(newTask as TaskEditInit).id && (
                                    <div className='flex flex-col gap-[4px]'>
                                        <label className={labelStyle + ' pt-[8px]'}>Status</label>
                                        <select
                                            className={inputStyle + ' !p-0'}
                                            onChange={({ target }) => setNewTask({ ...newTask, status: target.value })}
                                            value={(newTask as TaskEditInit).status}
                                        >
                                            <option className='hidden' value=''>
                                                Select Status
                                            </option>
                                            {['OPEN', 'COMPLETED'].map((status: any) => (
                                                <option key={status} value={status}>
                                                    {status}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex gap-[16px]'>
                    <div className='flex flex-col gap-[4px] grow'>
                        <label className={labelStyle}>Latest Activity</label>
                        <textarea
                            value={newTask.title}
                            className={`${inputStyle} max-h-20`}
                            onChange={({ target }) => setNewTask({ ...newTask, title: target.value })}
                        />
                    </div>
                </div>
                <div className='flex gap-[16px]'>
                    <div className='flex flex-col gap-[4px] relative w-[49%]'>
                        <label className={labelStyle} htmlFor='task_due_at'>
                            DUE AT
                        </label>
                        <TaskDueAt
                            dueAt={newTask.due_at}
                            setDueAt={(newDueAt: any) => setNewTask({ ...newTask, due_at: newDueAt })}
                            style={'border-transparent dark:border-transparent'}
                        />
                    </div>
                    <div className='flex'>
                        <div className='relative'>
                            <label className={labelStyle + ' mb-[4px]'}>Assigned To</label>
                            {user.type === 'Admin' || user.type === 'SuperAdmin' ? (
                                <UserlistDropdown
                                    users={userList}
                                    selectedUsers={newTask.assigned_to}
                                    setSelectedUsers={(assigned_to: string[]) =>
                                        setNewTask({ ...newTask, assigned_to })
                                    }
                                    disabled={!['admin', 'superadmin'].includes(user?.type.toLowerCase())}
                                    style={'border-transparent dark:border-transparent bg-lightgrey'}
                                />
                            ) : (
                                <input
                                    className={inputStyle}
                                    type='text'
                                    id='assigned_to'
                                    name='assigned_to'
                                    value={`${newTask.assigned_to}`}
                                    onChange={() => {}}
                                    disabled
                                />
                            )}
                        </div>
                    </div>
                    <div className='flex flex-col gap-[4px] grow'>
                        <label className={labelStyle} htmlFor='task_priority'>
                            Priority
                        </label>
                        <select
                            className={inputStyle + ' w-[100%]'}
                            id='priority'
                            name='priority'
                            value={newTask.priority}
                            onChange={({ target }: any) => setNewTask({ ...newTask, priority: target.value })}
                        >
                            <option className='hidden' value=''></option>
                            {priorityLevels.map((l) => (
                                <option key={l} value={l}>
                                    {l}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            {(edited || !id) && (
                <div className='flex justify-end'>
                    <button
                        className='bg-blue dark:bg-accent px-[8px] py-[2px] rounded-b text-white dark:text-black font-semibold'
                        onClick={handleSubmit}
                    >
                        {id ? 'Submit Changes' : 'Create New Task'}
                    </button>
                </div>
            )}
        </div>
    )
}
