import { useEffect } from 'react'
import './component_styles/FiltersMenu.css'
import Calendar from './Calendar'
import { BsFillTrash3Fill } from 'react-icons/bs'
import { closePopout } from '../helpers'

export class Filter {
    constructor(
        name: string,
        filter: string[] = [],
        setFilter: Function,
        options: string[],
        showOptions: boolean,
        setShowOptions: Function,
        ref: any,
        zIndex?: number,
        quantities?: number[]
    ) {
        this.filter = filter
        this.name = name
        this.options = options
        this.ref = ref
        this.setFilter = setFilter
        this.setShow = setShowOptions
        this.show = showOptions
        this.zIndex = zIndex
        this.quantities = quantities
    }
    filter: string[]
    name: string
    options: string[]
    ref: any
    setFilter: any
    setShow: any
    show: boolean
    zIndex: number | undefined
    quantities?: number[]
}
export class FilterSearch {
    constructor(name: string, search: string, setSearch: Function) {
        this.name = name
        this.search = search
        this.setSearch = setSearch
    }
    name: string
    search: string
    setSearch: Function
}

type FiltersMenuProps = {
    filters: Filter[]
    ex?: {
        startDate?: Date
        setStartDate?: Function
        endDate?: Date
        setEndDate?: Function
        items?: any[]
        hide?: string[]
        removemb?: boolean
        namePrepend?: string
    }
    searches?: FilterSearch[]
    style?: string
}
type FilterByButtonProps = {
    filterClassName: string
    propFilter: Filter
    option: string
    quantity?: number
}

export default function FiltersMenu({ filters, ex, searches, style }: FiltersMenuProps) {
    const FilterByButton = ({ filterClassName, propFilter, option, quantity }: FilterByButtonProps) => {
        const { filter, setFilter } = propFilter

        const handleChangeFilters = ({ target }: React.MouseEvent) => {
            ;((target as HTMLElement).childNodes[0].childNodes[0] as HTMLElement).classList.toggle(
                'js-dropdown__checkbox--checked'
            )
            const checkedBoxes = document.getElementsByClassName('js-dropdown__checkbox--checked') as any // as HTMLCollectionOf<HTMLInputElement>;
            const checkedOptions = []
            for (let checkedBox of checkedBoxes) {
                if (checkedBox.nextElementSibling!.innerHTML !== 'All Issues') {
                    checkedOptions.push(checkedBox.nextElementSibling!.innerHTML)
                }
            }
            if (checkedOptions.length === 0) return setFilter([])
            setFilter(checkedOptions)
        }
        return (
            <div
                onClick={handleChangeFilters}
                className={`${filterClassName} js-dropdown__option relative flex gap-[8px] py-[6px] items-center justify-between overflow-y-hidden max-w-[500px] overflow-x-hidden cursor-pointer hover:bg-[rgb(240,240,240)] dark:hover:bg-darkaccent`}
            >
                <div className={`flex grow gap-[8px] items-center select-none pointer-events-none px-[8px]`}>
                    <div
                        className={`w-[16px] h-[16px] border-[1px] border-[#cdcdcd] dark:border-[blueviolet] rounded js-dropdown__checkbox${
                            filter.includes(option) ? ' js-dropdown__checkbox--checked' : ''
                        } pointer-events-none`}
                    >
                        &nbsp;
                    </div>
                    <span
                        className='pointer-events-none whitespace-nowrap select-none grow'
                        key={option}
                        id={`js-filter-option-${option}`}
                    >
                        {option}
                    </span>
                </div>
                {quantity !== undefined && <span className='pointer-events-none pr-[8px]'>({quantity})</span>}
            </div>
        )
    }
    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            filters.forEach((filter) => {
                closePopout(
                    e,
                    [
                        `filter__${
                            ex?.namePrepend
                                ? `${ex.namePrepend}__${filter.name.toLowerCase().split(' ').join('-')}`
                                : filter.name.toLowerCase().split(' ').join('-')
                        }`,
                    ],
                    () => filter.setShow(false)
                )
                // swapped out for closePopout. Trying this out unless someone complains that the menu doesn't close itself.
                // if (filter.ref.current && !filter.ref.current.contains(e.target) && !(e.target as HTMLElement).classList.contains("js-dropdown") && !(e.target as HTMLElement).classList.contains("js-dropdown__option")) {
                //     filter.setShow(false);
                // }
            })
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <div
            className={`flex flex-wrap bg-[#f5f5f5] dark:bg-darkaccent border-[1px] [&>*]:py-[8px] [&>*]:px-[16px] dark:border-darkgrey shadow-md sticky z-index-5 ${
                ex?.removemb && '!mb-0'
            } mb-[20px] rounded ${style || ''}`}
        >
            <div className='flex gap-x-[8px] justify-between w-[100%]'>
                <div>
                    <div className='flex flex-wrap gap-y-[8px]'>
                        {filters.map((filter) => (
                            <div
                                key={filter.name}
                                className={`filter__${
                                    ex?.namePrepend
                                        ? `${ex.namePrepend}__${filter.name.toLowerCase().split(' ').join('-')}`
                                        : filter.name.toLowerCase().split(' ').join('-')
                                } ${ex?.hide?.includes(filter.name) ? 'hidden' : ''} z-index-${
                                    filters.length - filters.indexOf(filter)
                                }`}
                            >
                                <label className='flex items-center gap-x-[8px] uppercase text-[10px] font-bai font-bold text-[#4a4a4a] dark:text-grey'>
                                    {filter.name}
                                    {filter.filter.length > 0 && (
                                        <BsFillTrash3Fill
                                            className='dark:fill-accent relative top-[-2px] cursor-pointer h-[13px] w-[13px]'
                                            onClick={() => filter.setFilter([])}
                                        />
                                    )}
                                </label>
                                <div className='relative select-none'>
                                    <input
                                        className={`w-[calc(100%-8px)] px-[4px] outline outline-[1px] outline-[#cdcdcd] dark:outline-darkgrey focus:outline focus:border-0 dark:bg-darkness dark:text-white  dark:focus:outline-darkgrey caret-transparent cursor-default select-none`}
                                        type='text'
                                        value={filter.filter}
                                        onFocus={() => {
                                            filter.setShow(true)
                                        }}
                                        onChange={() => {}}
                                        ref={filter.ref}
                                    />
                                    {filter.show && (
                                        <div
                                            className={`filter__${
                                                ex?.namePrepend
                                                    ? `${ex.namePrepend}__${filter.name
                                                          .toLowerCase()
                                                          .split(' ')
                                                          .join('-')}`
                                                    : filter.name.toLowerCase().split(' ').join('-')
                                            } absolute top-[calc(100%)] left-[-1px] min-w-[calc(100%-6px)] h-[250px] overflow-x-hidden overflow-auto select-none`}
                                        >
                                            <div
                                                className={`js-dropdown ${filter.name
                                                    .toLowerCase()
                                                    .split(' ')
                                                    .join(
                                                        '-'
                                                    )} relative flex flex-col w-full border-[1px] border-[#cdcdcd] dark:border-darkgrey bg-[white] dark:text-offwhite dark:bg-darkness pointer-events-auto select-none`}
                                                onClick={() => {
                                                    filter.setShow(true)
                                                }}
                                            >
                                                {filter.options.map((option) => (
                                                    <FilterByButton
                                                        key={option}
                                                        option={option}
                                                        quantity={
                                                            filter.quantities
                                                                ? filter.quantities[filter.options.indexOf(option)]
                                                                : undefined
                                                        }
                                                        propFilter={filter}
                                                        filterClassName={`filter__${
                                                            ex?.namePrepend
                                                                ? `${ex.namePrepend}__${filter.name
                                                                      .toLowerCase()
                                                                      .split(' ')
                                                                      .join('-')}`
                                                                : filter.name.toLowerCase().split(' ').join('-')
                                                        }`}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {ex && ex.startDate && ex.endDate && !(ex.hide && ex.hide.includes('Calendar')) && (
                    <div className='flex items-center gap-[8px] h-[24px]'>
                        <button
                            className='bg-blue dark:bg-accent text-white dark:text-black font-semibold px-[8px] text-[12px] h-[18px] w-[50px] rounded'
                            onClick={() => {
                                ex.setStartDate &&
                                    ex.setStartDate(
                                        new Date(
                                            ex.startDate!.getFullYear(),
                                            ex.startDate!.getMonth(),
                                            ex.startDate!.getDate() - 1
                                        )
                                    )
                                ex.setEndDate &&
                                    ex.setEndDate(
                                        new Date(
                                            ex.endDate!.getFullYear(),
                                            ex.endDate!.getMonth(),
                                            ex.endDate!.getDate() - 1,
                                            23,
                                            59,
                                            59,
                                            999
                                        )
                                    )
                            }}
                        >
                            &lt; Day
                        </button>
                        <Calendar
                            startDate={ex.startDate}
                            setStartDate={ex.setStartDate!}
                            endDate={ex.endDate}
                            setEndDate={ex.setEndDate}
                            items={ex.items}
                        />
                        <button
                            className='bg-blue dark:bg-accent text-white dark:text-black font-semibold px-[8px] text-[12px] h-[18px] w-[50px] rounded'
                            onClick={() => {
                                ex.setStartDate &&
                                    ex.setStartDate(
                                        new Date(
                                            ex.startDate!.getFullYear(),
                                            ex.startDate!.getMonth(),
                                            ex.startDate!.getDate() + 1
                                        )
                                    )
                                ex.setEndDate &&
                                    ex.setEndDate(
                                        new Date(
                                            ex.endDate!.getFullYear(),
                                            ex.endDate!.getMonth(),
                                            ex.endDate!.getDate() + 1,
                                            23,
                                            59,
                                            59,
                                            999
                                        )
                                    )
                            }}
                        >
                            Day &gt;
                        </button>
                    </div>
                )}
            </div>
            {searches && (
                <div className='grow flex gap-[16px] justify-center items-end text-white dark:text-offwhite bg-white dark:!bg-darkaccent border-t-[1px] dark:border-darkgrey'>
                    {searches?.map((searchObject) => {
                        const { name, search, setSearch } = searchObject
                        return (
                            <input
                                className={
                                    'grow max-w-[250px] text-black dark:text-offwhite bg-lightgrey dark:bg-darkness p-[4px] border-[1px] dark:border-blue focus:dark:border-accent outline-0 rounded'
                                }
                                value={search}
                                onChange={({ target }) => setSearch(target.value)}
                                placeholder={name || 'search'}
                            />
                        )
                    })}
                </div>
            )}
        </div>
    )
}
