import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { vFetch } from '../../helpers'
import { useSearchParams } from 'react-router-dom'
import EditArticleView from './EditArticleView'
import QuestionWithAnswersView from './QuestionWithAnswersView'
import MyContributionsArticleView from './MyContributionsArticleView'
import YourArticleCard from '../components/YourArticleCard'
import YourQuestionCard from '../components/YourQuestionCard'
import MyContributionsEditArticleView from './MyContributionsEditArticleView'
import { FilterBarSelectorButton } from '../../custom_components/component_Basics/FilterBarSelectorButton'
import { getFilterBarUsers } from '../../procurement/helpers'
import { FaLightbulb, FaRegLightbulb, FaTimes } from 'react-icons/fa'
import { BsLightbulb, BsLightbulbFill, BsThreeDotsVertical } from 'react-icons/bs'
import ExampleArticleCards from '../components/ExampleArticleCard'
import { Button } from '../../custom_components/component_Basics/Button'
import { GrInfo } from 'react-icons/gr'
import { HiLightBulb, HiOutlineLightBulb, HiOutlineQuestionMarkCircle, HiQuestionMarkCircle } from 'react-icons/hi2'
import { LuInfo } from 'react-icons/lu'

export default function MyContributions({
    setView,
    selectedDepartment,
    selectedArticle,
    setSelectedArticle,
    // id,
}: {
    setView: any
    selectedDepartment: any
    selectedArticle: any
    setSelectedArticle: any
    // id: any
}) {
    const [isLoading, setIsLoading] = useState(false) //
    const [isError, setIsError] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const myArticleID = searchParams.get('myArticleID')
    const questionID = searchParams.get('questionID')
    const currentUser = useSelector((state: any) => state.user)
    const [articleList, setArticleList] = useState([]) //
    const [answersList, setAnswersList] = useState([]) //
    const [questionList, setQuestionList] = useState([])
    const articleMap = articleList.filter((article: any) => article.user_id === currentUser.id)
    const questionMap = questionList.filter((question: any) => question.user_id === currentUser.id)
    // const [selectedArticle, setSelectedArticle] = useState<undefined | any>()
    const [selectedArticleView, setSelectedArticleView] = useState<boolean>(false)
    const [selectedEditView, setSelectedEditView] = useState<boolean>(false)
    const [selectedQuestion, setSelectedQuestion] = useState<undefined | any>()
    const [toggleTabSelection, setToggleTabSelection] = useState(true)
    const [showArticles, setshowArticles] = useState(true)
    const [showQuestions, setshowQuestions] = useState(false)
    const [showInfoModal, setShowInfoModal] = useState(false)

    const getArticles = async () => {
        vFetch(
            `/v1/knowledge/articles?user_id=${currentUser.id}&fields=title,excerpt,featured_image,pinned,id,created_at,user_id,department_id,status,hidden`,
            {
                cb: (res: any) => {
                    setIsLoading(false)
                    if (!res.success) {
                        setIsError(true)
                    }
                    if (res.success) {
                        const foundArticle = res.articles.find((article: any) => article.id == myArticleID)
                        if (foundArticle) {
                            setSelectedArticle(foundArticle)
                            setSelectedArticleView(true)
                        }
                        setArticleList(res.articles)
                    }
                },
            }
        )
    }

    const getQuestions = async () => {
        vFetch(`/v1/knowledge/questions`, {
            // vFetch(`/v1/knowledge/questions?user_id=${currentUser.id}`, {
            cb: (res: any) => {
                setIsLoading(false)
                if (!res.success) {
                    setIsError(true)
                }
                if (res.success) {
                    const foundQuestion = res.questions.find((question: any) => question.id == questionID)
                    if (foundQuestion) {
                        setSelectedQuestion(foundQuestion)
                    }
                    setQuestionList(res.questions)
                }
            },
        })
    }
    const handleArticlesTabSelection = () => {
        setToggleTabSelection((prev) => !prev)
        setshowArticles(true)
        setshowQuestions(false)
        setShowInfoModal(false)
    }
    const handleQuestionsTabSelection = () => {
        setToggleTabSelection((prev) => !prev)
        setshowArticles(false)
        setshowQuestions(true)
    }
    const handleInfoButtonClick = () => {
        setShowInfoModal(true)
        setshowArticles(false)
    }
    const handleInfoClose = () => {
        setShowInfoModal(false)
        setshowArticles(true)
    }
    // STATUS FILTER //
    const [filterValues, setFilterValues] = useState<{ statuses: string[] }>({
        statuses: ['Pending', 'Rejected', 'Published'],
    })
    const statusOptions = ['Pending', 'Rejected', 'Published'].map((option) => {
        return {
            label: option,
            value: option,
            icon: undefined,
        }
    })
    const selectorButtonObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                title: 'Statuses',
                options: statusOptions,
                field: 'statuses',
                values: filterValues?.statuses || [],
                searchToggle: false,
            },
        ],
        setFunction: setFilterValues,
        resetFunction: () => {
            setFilterValues({
                statuses: [],
            })
        },
    }
    const filteredArticleMap = articleMap.filter((article: any) => {
        if (!filterValues.statuses.includes(article.status)) {
            return
        }
        return article
    })
    useEffect(() => {
        getArticles()
        getQuestions()
    }, [])

    return (
        <div>
            {myArticleID && selectedArticleView && selectedArticle && (
                <MyContributionsArticleView
                    selectedArticle={selectedArticle}
                    setSelectedArticle={setSelectedArticle}
                    setView={setView}
                />
            )}
            {/* {selectedEditView && selectedArticle && (
                <MyContributionsEditArticleView
                    setView={setView}
                    selectedArticle={selectedArticle}
                    setSelectedArticle={setSelectedArticle}
                />
            )} */}
            {selectedQuestion && (
                <>
                    <QuestionWithAnswersView setView={setView} selectedQuestion={selectedQuestion} />
                </>
            )}
            {isLoading && (
                <div className='text-lg p-2 rounded-b w-full bg-white dark:bg-darkbg1'>
                    <p>Loading...</p>
                </div>
            )}
            {isError && (
                <div className='text-lg p-2 rounded-b w-full bg-white dark:bg-darkbg1'>
                    <p>Unable to load. Please refresh and try again</p>
                </div>
            )}
            {!selectedArticle && !selectedEditView && !selectedQuestion && !isLoading && !isError && (
                // 'h-full grid overflow-auto pr-2'
                <div className='pr-8 overflow-auto'>
                    <div className='flex justify-between'>
                        <div className='relative flex mt-4'>
                            <button
                                onClick={handleArticlesTabSelection}
                                className={`font-bold text-[20px] border dark:border-darkgrey rounded-l border-r-0 px-2 ${
                                    toggleTabSelection ? 'text-white bg-blue dark:bg-accent dark:text-darkbg2' : ' '
                                }`}
                            >
                                Your Articles
                            </button>
                            <button
                                onClick={handleQuestionsTabSelection}
                                className={`font-bold text-[20px] border dark:border-darkgrey rounded-r px-2 ${
                                    toggleTabSelection
                                        ? ''
                                        : 'text-white bg-blue dark:bg-accent dark:text-darkbg2 py-0.5'
                                }`}
                            >
                                Your Questions
                            </button>
                        </div>
                        <div className='flex mt-6 gap-1'>
                            {showArticles &&
                                !isLoading &&
                                !isError &&
                                selectorButtonObject.params.map((param: any) => {
                                    return (
                                        <param.component
                                            key={param.field}
                                            searchToggle={param.searchToggle}
                                            title={param.title}
                                            field={param.field}
                                            options={param.options}
                                            filterValues={param.values}
                                            setFilterValues={selectorButtonObject.setFunction}
                                            align={'start'}
                                            preview={false}
                                        />
                                    )
                                })}

                            <div>
                                {!showQuestions && (
                                    <>
                                        {showInfoModal === true ? (
                                            <div className='flex gap-2'>
                                                <div className='mt-1'>Article tips</div>
                                                <Button
                                                    variant={'outline'}
                                                    className='border-none mt-[-4px]'
                                                    onClick={handleInfoClose}
                                                >
                                                    {/* <FaLightbulb className='text-xl text-darkgrey' /> */}
                                                    <HiQuestionMarkCircle className='text-2xl text-blue dark:text-accent' />
                                                </Button>
                                            </div>
                                        ) : (
                                            <Button
                                                variant={'outline'}
                                                className='border-none mt-[-4px]'
                                                onClick={handleInfoButtonClick}
                                            >
                                                {/* <FaRegLightbulb className='text-xl' /> */}
                                                <HiOutlineQuestionMarkCircle className='text-2xl text-blue dark:text-accent' />
                                            </Button>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    {showArticles && filteredArticleMap.length === 0 && !showInfoModal && !isLoading && (
                        <div className='text-2xl mt-6 ml-4 dark:text-offwhite'>
                            <p>Any articles you create will appear here</p>
                        </div>
                    )}
                    {!showQuestions && showInfoModal && <ExampleArticleCards />}

                    {showQuestions && questionMap.length === 0 && (
                        <div className='text-2xl mt-6 ml-4 dark:text-offwhite'>
                            <p>Any questions you ask will appear here</p>
                        </div>
                    )}

                    {showArticles && (
                        <div className='my-4 ml-[2px] grid grid-cols-2 gap-2'>
                            {filteredArticleMap?.map((article: any, index: number) => (
                                <YourArticleCard
                                    article={article}
                                    setView={setView}
                                    selectedArticle={selectedArticle}
                                    setSelectedArticle={setSelectedArticle}
                                    setSelectedArticleView={setSelectedArticleView}
                                    currentUser={currentUser}
                                    articleList={articleList}
                                    setArticleList={setArticleList}
                                    getArticles={getArticles}
                                />
                            ))}
                        </div>
                    )}
                    <>
                        {showQuestions && (
                            <div className='mt-4 ml-[2px]'>
                                <div className='border-s-darkscroll '>
                                    {questionMap?.map((question: any) => (
                                        <YourQuestionCard
                                            question={question}
                                            setView={setView}
                                            setSelectedQuestion={setSelectedQuestion}
                                            questionID={questionID}
                                        />
                                    ))}
                                </div>
                            </div>
                        )}
                    </>
                </div>
            )}
        </div>
    )
}
