import { formatMoney, locationMap } from '../../../helpers'
import { FulfillmentOrderInit, OrderInit } from '../../orders.types'

type RemovedItemsProps = {
    fulfillmentOrders: FulfillmentOrderInit[]
    order: OrderInit
}

export default function RemovedItems({ order, fulfillmentOrders }: RemovedItemsProps) {
    return (
        <>
            {/* check to see if any items were cancelled, if these numbers don't match, then something was removed. */}
            {fulfillmentOrders &&
                fulfillmentOrders.length > 0 &&
                fulfillmentOrders.map((order) => order.line_items.length).reduce((acc, cur) => acc + cur, 0) !==
                    order.fulfillments.map((f) => f.line_items.length).reduce((acc, cur) => acc + cur, 0) &&
                fulfillmentOrders.map(
                    (f: FulfillmentOrderInit) =>
                        f.line_items.some((item) => item.quantity === 0) && (
                            <div className='grid gap-[24px] bg-white dark:bg-darkaccent p-[16px] shadow-small rounded dark:text-offwhite opacity-[0.7]'>
                                <div className='flex justify-between'>
                                    <div className='flex items-center gap-[8px] text-[14px]'>
                                        <h2 className='font-bold text-[20px] whitespace-nowrap'>
                                            Removed ({f.line_items.filter((item) => item.quantity === 0).length})
                                        </h2>
                                    </div>
                                </div>
                                <div className='grid [&>div]:border-b-[1px] dark:[&>div]:border-darkgrey [&>:last-child]:border-0'>
                                    {f.line_items
                                        .filter((item) => item.quantity === 0)
                                        .map((item) => {
                                            const oItemIndex: any = order.line_items.findIndex(
                                                (orderItem) => orderItem.id === item.line_item_id
                                            )
                                            if (oItemIndex < 0) return

                                            return (
                                                <div
                                                    key={item.id}
                                                    className='flex gap-[16px] pt-[24px] w-[100%] justify-between col-span-2 text-[14px]'
                                                >
                                                    <div className='flex gap-[16px]'>
                                                        <div className='grid w-[100px] items-center relative'>
                                                            <img
                                                                className='object-contain min-w-[100px] min-h-[100px]'
                                                                src={
                                                                    window.IMAGE_MAP[
                                                                        `gid://shopify/Product/${order.line_items[oItemIndex].product_id}`
                                                                    ]
                                                                }
                                                                alt={order.line_items[oItemIndex].name}
                                                            />
                                                            <span className='absolute flex top-[-10px] right-[-10px] min-w-[20px] min-h-[20px] text-[12px] leading-[1px] items-center pb-[2px] border-[5px] border-[darkgrey] bg-[darkgrey] text-white font-bold rounded-full justify-center'>
                                                                {item.quantity}
                                                            </span>
                                                        </div>
                                                        <div className='my-[16px]'>
                                                            <a
                                                                href={`https://factorypure.com/products/${order.line_items[oItemIndex].handle}`}
                                                                target='_blank'
                                                                className='font-semibold hover:underline hover:text-blue dark:hover:text-accent pointer-events-auto'
                                                            >
                                                                {order.line_items[oItemIndex].title}
                                                            </a>
                                                            {item.variant_title && (
                                                                <p className='pt-[4px]'>
                                                                    <strong className='text-darkgrey dark:text-grey'>
                                                                        Variant:
                                                                    </strong>{' '}
                                                                    {order.line_items[oItemIndex].variant_title}
                                                                </p>
                                                            )}
                                                            <p className='pt-[4px]'>
                                                                <strong className='text-darkgrey dark:text-grey'>
                                                                    SKU:
                                                                </strong>{' '}
                                                                {order.line_items[oItemIndex].sku}
                                                            </p>
                                                            {order.refunds.some((refund) =>
                                                                refund.refund_line_items.some(
                                                                    (rItem) => rItem.line_item_id === item.line_item_id
                                                                )
                                                            ) && (
                                                                <ul className='list-disc ml-[16px] mt-[8px]'>
                                                                    {order.refunds.find((refund) =>
                                                                        refund.refund_line_items.find(
                                                                            (rItem) =>
                                                                                rItem.line_item_id === item.line_item_id
                                                                        )
                                                                    )?.restock && (
                                                                        <li>
                                                                            Restocked at{' '}
                                                                            {
                                                                                locationMap[
                                                                                    order.refunds
                                                                                        .find((refund) =>
                                                                                            refund.refund_line_items.find(
                                                                                                (rItem) =>
                                                                                                    rItem.line_item_id ===
                                                                                                    item.line_item_id
                                                                                            )
                                                                                        )
                                                                                        ?.refund_line_items.find(
                                                                                            (rItem) =>
                                                                                                rItem.line_item_id ===
                                                                                                item.line_item_id
                                                                                        )?.location_id!
                                                                                ]
                                                                            }
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='flex gap-[32px]'>
                                                            <span className='my-[16px] flex gap-[4px]'>
                                                                {formatMoney(
                                                                    Number(order.line_items[oItemIndex].price)
                                                                )}
                                                                <span>&times;</span>
                                                                {order.line_items[oItemIndex].quantity}
                                                            </span>
                                                            <span className='my-[16px]'>
                                                                {formatMoney(
                                                                    Number(order.line_items[oItemIndex].price) *
                                                                        Number(order.line_items[oItemIndex].quantity)
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div className='flex justify-end'>
                                                            <a
                                                                className='p-[8px] font-semibold text-white dark:text-black bg-[#007a5c] hover:bg-[#125443] dark:bg-fire rounded dark:hover:bg-[darkorange]'
                                                                href={`https://factorypure.myshopify.com/admin/products/${order.line_items[oItemIndex].product_id}`}
                                                            >
                                                                View On Shopify
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            </div>
                        )
                )}
        </>
    )
}
