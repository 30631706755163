import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '../../../custom_components/component_Basics/Button'
import { cn, sendToast } from '../../../helpers'
import { useContactTypesQuery } from '../../api/useQueries'
import Input from '../Input'
import Select from '../Select'
import ContactDetailsSideBarInfo from './ContactDetailsSideBarInfo'

export default function CompanySideBarContact({ company }: { company: any }) {
    const [showDetails, setShowDetails] = useState(false)
    const user = useSelector((state: any) => state.user)

    const contactTypesQuery = useContactTypesQuery()
    const { types: contactTypes } = contactTypesQuery?.data || {}

    return (
        <div
            className={cn(
                'text-[14px] grid grid-cols-[250px_repeat(4,auto)_50px] w-full border-b border-lightgrey dark:border-darkgrey hover:bg-lightgrey dark:hover:bg-darkness'
            )}
        >
            <Select
                className='w-full h-full p-1 text-[14px] bg-transparent max-w-[250px]'
                id='contact_type'
                name='contact_type'
                onChange={() => {}}
                value={'Sidebar Info'}
                disabled={true}
            >
                <option value={'General'}>General</option>
                <option hidden={true} value={'Sidebar Info'}>
                    Company Sidebar Info
                </option>
            </Select>
            <div
                className=' flex justify-center'
                onClick={() => {
                    if (company.name) {
                        navigator.clipboard.writeText(company.name)
                        sendToast({ message: `Copied: ${company.name}` })
                    }
                }}
            >
                <Input
                    id='name'
                    type='text'
                    className='w-full h-full p-1 text-[14px] bg-transparent cursor-pointer'
                    value={company.name}
                    name='name'
                    placeholder='Name'
                    onChange={() => {}}
                    readOnly={true}
                />
            </div>
            <div
                onClick={() => {
                    if (company.email) {
                        navigator.clipboard.writeText(company.email)
                        sendToast({ message: `Copied: ${company.email}` })
                    }
                }}
                className=' flex justify-center'
            >
                <Input
                    id='email'
                    type='text'
                    className='w-full h-full p-1 text-[14px] bg-transparent cursor-pointer'
                    value={company.email}
                    name='email'
                    placeholder='Email'
                    onChange={() => {}}
                    readOnly={true}
                />
            </div>
            <div
                onClick={() => {
                    if (company.phone) {
                        navigator.clipboard.writeText(company.phone)
                        sendToast({ message: `Copied: ${company.phone}` })
                    }
                }}
                className=' flex justify-center '
            >
                <Input
                    id='phone'
                    type='text'
                    className='w-full h-full p-1 text-[14px] bg-transparent cursor-pointer '
                    value={company.phone}
                    name='phone'
                    placeholder='Phone'
                    onChange={() => {}}
                    readOnly={true}
                />
            </div>
            <textarea
                onClick={() => {
                    if (company.domain) {
                        navigator.clipboard.writeText(company.domain)
                        sendToast({ message: `Copied: ${company.domain}` })
                    }
                }}
                className='w-full h-full rounded-none border-0 p-1 bg-transparent outline-none text-[14px] cursor-pointer'
                value={company.domain}
                name='alternate'
                placeholder='Domain'
                onChange={() => {}}
                readOnly={true}
            ></textarea>
            {showDetails && (
                <ContactDetailsSideBarInfo
                    setShowDetails={setShowDetails}
                    company={company}
                    contactTypes={contactTypes}
                />
            )}

            {user.roles.includes('procurement') && (
                <Button
                    size={'sm'}
                    variant={'outline'}
                    onClick={() => setShowDetails(true)}
                    className='cursor-pointer justify-self-end place-self-center mr-3'
                >
                    <p>Edit</p>
                </Button>
            )}
        </div>
    )
}
