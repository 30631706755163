import { useCreateChangelog } from 'changelog/api/useQueries'
import { Button } from 'custom_components/component_Basics/Button'
import Input from 'procurement/components/Input'
import { useEffect, useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'

export default function CreateChangeLogModal({ closeModal }: { closeModal: () => void }) {
    const modalRef = useRef<HTMLDivElement>(null)
    const [appName, setAppName] = useState('')
    const createChangelogMutation = useCreateChangelog()

    const handleCreate = () => {
        createChangelogMutation.mutate({
            app_name: appName,
        })
    }

    useEffect(() => {
        const listenToWindow = (event: MouseEvent) => {
            if (event.target === modalRef.current) {
                closeModal()
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    return (
        <div
            ref={modalRef}
            className='fixed top-0 left-0 w-full h-full bg-black/50 z-50 flex items-center justify-center'
        >
            <div className='bg-bg1 dark:bg-darkbg1 p-6 rounded shadow-md relative max-w-[90%] max-h-[90%] min-w-[400px]'>
                <button onClick={closeModal} className='text-lg text-red dark:text-lightred absolute top-1 right-1'>
                    <FaTimes />
                </button>
                <h2 className='text-lg font-bold mb-4'>Create New Changelog</h2>
                <Input
                    id='app_name'
                    name='app_name'
                    type='text'
                    value={appName}
                    onChange={({ target }) => setAppName(target.value)}
                    label='Application Name'
                />
                <Button className='block mx-auto mt-4' onClick={handleCreate} disabled={appName.length === 0}>
                    Create Changelog
                </Button>
            </div>
        </div>
    )
}
