import { useState } from 'react'
import ReactDatalist from '../../custom_components/ReactDatalist'
import { validateAndHandlePhoneNumber } from '../vendors.helpers'
import { VendorContactCreateInit, VendorContactEditInit, vendorContactTypes } from '../vendors.types'

type VendorContactFieldsProps = {
    contactTypes: string[]
    contactsArray: [VendorContactEditInit] | VendorContactCreateInit[]
    setContactsArray: Function
    contact: VendorContactEditInit | VendorContactCreateInit
}

export default function VendorContactFields({
    contactTypes,
    contactsArray,
    setContactsArray,
    contact,
}: VendorContactFieldsProps) {
    const { id } = contact as VendorContactEditInit

    function formatPhoneNumber(num: string) {
        const number = String(num || '')
        let n = number.replaceAll(/\D/g, '')
        if (n.length > 3 && n.length <= 6) return [n.substr(0, 3), n.substr(3, 3)].join('-')
        if (n.length > 6) return [n.substr(0, 3), n.substr(3, 3), n.substr(6, 4)].join('-')
        return n
    }
    function handleChangeContactsArray(property: string, { value }: any, i: number) {
        const newContactsArray = [...contactsArray]
        newContactsArray.splice(i, 1, { ...newContactsArray[i], [property]: value })
        setContactsArray(newContactsArray)
    }

    const labelStyle = 'font-bai text-[12px] font-bold text-[#4a4a4a] uppercase dark:text-offwhite'
    const inputStyle =
        'block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'

    return (
        <div className='grid gap-[16px] [&>*]:border-t-[1px] [&>*]:border-darkgrey [&>*]:dark:border-fire [&>*:first-child]:border-[0px] [&>*:last-child]:border-[0px]'>
            {contactsArray.map((contact, i) => (
                <div className='grid gap-[8px]'>
                    <div className='flex gap-[8px]'>
                        <div className='grow'>
                            <label className={labelStyle}>Contact Type</label>
                            <ReactDatalist
                                value={contact.contact_type}
                                options={contactTypes}
                                setSelected={(clicked: any) =>
                                    handleChangeContactsArray('contact_type', { value: clicked }, i)
                                }
                            />
                        </div>
                    </div>
                    <div className='flex gap-[8px]'>
                        <div className='grow'>
                            <label className={labelStyle}>Name</label>
                            <input
                                className={inputStyle}
                                value={contact.name}
                                onChange={({ target }) => handleChangeContactsArray('name', target, i)}
                            />
                        </div>
                        <div className='grow'>
                            <label className={labelStyle}>Email</label>
                            <input
                                className={inputStyle}
                                value={contact.email}
                                onChange={({ target }) => handleChangeContactsArray('email', target, i)}
                            />
                        </div>
                    </div>
                    <div className='grid grid-cols-[minMax(20px,auto)_minmax(100px,auto)_minmax(20px,auto)] gap-[8px]'>
                        <div className='grow'>
                            <label className={labelStyle}>Phone Prefix</label>
                            <input
                                type='text'
                                className={inputStyle}
                                value={contact.phone_prefix}
                                onChange={({ target }) =>
                                    validateAndHandlePhoneNumber(
                                        target,
                                        'phone_prefix',
                                        () => handleChangeContactsArray('phone_prefix', target, i),
                                        3
                                    )
                                }
                            />
                        </div>
                        <div className='grow'>
                            <label className={labelStyle}>Phone Number</label>
                            <div className='flex relative'>
                                <input
                                    id={`vendor__phone_number-${i}`}
                                    type='text'
                                    tabIndex={-1}
                                    className={inputStyle}
                                    value={formatPhoneNumber(contact.phone)}
                                />
                                <input
                                    type='text'
                                    className='absolute w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] text-transparent bg-transparent focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                    value={contact.phone}
                                    onChange={({ target }) => {
                                        ;(
                                            document.getElementById(`vendor__phone_number-${i}`) as HTMLInputElement
                                        ).value = formatPhoneNumber(target.value)
                                        validateAndHandlePhoneNumber(
                                            target,
                                            'phone',
                                            () => handleChangeContactsArray('phone', target, i),
                                            10
                                        )
                                    }}
                                />
                            </div>
                        </div>
                        <div className='grow'>
                            <label className={labelStyle}>Phone Extension</label>
                            <input
                                type='text'
                                className={inputStyle}
                                value={contact.phone_extension}
                                onChange={({ target }) =>
                                    validateAndHandlePhoneNumber(target, 'phone_prefix', () =>
                                        handleChangeContactsArray('phone_extension', target, i)
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className='flex gap-[8px]'>
                        <div className='grow'>
                            <label className={labelStyle}>Cell Phone</label>
                            <div className='flex relative'>
                                <input
                                    id={`vendor__cell_phone-${i}`}
                                    type='text'
                                    tabIndex={-1}
                                    className={inputStyle}
                                    value={formatPhoneNumber(contact.cell_phone)}
                                />
                                <input
                                    type='text'
                                    className='absolute w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] text-transparent bg-transparent focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                    value={contact.cell_phone}
                                    onChange={({ target }) => {
                                        ;(
                                            document.getElementById(`vendor__cell_phone-${i}`) as HTMLInputElement
                                        ).value = formatPhoneNumber(target.value)
                                        validateAndHandlePhoneNumber(
                                            target,
                                            'cell_phone',
                                            () => handleChangeContactsArray('cell_phone', target, i),
                                            10
                                        )
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-[8px]'>
                        <div className='grow'>
                            <label className={labelStyle}>Alternate</label>
                            <textarea
                                className={`${inputStyle} h-[120px]`}
                                value={contact.alternate}
                                onChange={({ target }) => handleChangeContactsArray('alternate', target, i)}
                            />
                        </div>
                    </div>
                    {i > 0 && !id && (
                        <div className='relative flex justify-center h-[40px]'>
                            <button
                                className={`p-[8px] bg-blue text-white font-semibold dark:bg-accent dark:text-black rounded shadow-small`}
                                onClick={() => setContactsArray(contactsArray.filter((c, cI) => i !== cI))}
                            >
                                - Contact
                            </button>
                        </div>
                    )}
                </div>
            ))}
            {!id && (
                <div className='flex justify-center items-center mb-[32px] mt-[16px]'>
                    <button
                        className={`p-[8px] bg-blue text-white font-semibold dark:bg-accent dark:text-black rounded shadow-small`}
                        onClick={() => setContactsArray([...contactsArray, contact])}
                    >
                        + Contact
                    </button>
                </div>
            )}
        </div>
    )
}
