type ReactCheckboxProps = {
    name?: string
    option: any
    selected: any
    setSelected: Function
    style?: string
    styleFromList?: string
}

export function ReactCheckbox({ name, option, selected, setSelected, style, styleFromList }: ReactCheckboxProps) {
    const handleChangeOptions = ({ target }: any) => {
        ;((target as HTMLElement).childNodes[0] as HTMLElement).classList.toggle(
            `js-checkbox-select__checkbox--checked`
        )
        const checkedBoxes = document.getElementsByClassName(`js-checkbox-select__checkbox--checked`)
        const checkedOptions = []
        for (let checkedBox of checkedBoxes as any)
            if (checkedBox.nextElementSibling.innerHTML !== 'All Issues')
                checkedOptions.push(checkedBox.nextElementSibling.innerHTML)
        if (checkedOptions.length === 0) return setSelected([])
        setSelected(checkedOptions)
    }

    return (
        <div
            onClick={handleChangeOptions}
            className={`js-checkbox-select__${name}__option relative flex px-[8px] py-[2px] gap-[8px] items-center cursor-pointer ${styleFromList}`}
        >
            <div
                className={`w-[16px] h-[16px] border-[1px] border-[#cdcdcd] dark:border-[blueviolet] rounded js-checkbox-select__checkbox ${
                    selected.find((selected: string | number) => selected == option)
                        ? `js-checkbox-select__checkbox--checked`
                        : ''
                } pointer-events-none`}
            >
                &nbsp;
            </div>
            <span
                className={`pointer-events-none whitespace-nowrap ${style}`}
                key={option}
                id={`js-filter-option-${option}`}
            >
                {option}
            </span>
        </div>
    )
}
