import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { createColumnHelper } from '@tanstack/react-table'
import { cn } from 'helpers'
import React, { useEffect, useState } from 'react'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { Link, useLocation } from 'react-router-dom'
import { useGetProductsQuery } from './api/useQueries'
import ProductsTable from './ProductsTable'
import { SkeletonBasic } from 'custom_components/component_Basics/SkeletonBasic'

export default function RecentlyVisitedProductsTableContainer({
    loading,
    recentlyViewedProducts,
}: {
    loading: boolean
    recentlyViewedProducts: { id: number | string; title: string }[]
}) {
    const [initRender, setInitRender] = useState(true)
    const location = useLocation()

    const recentlyViewedProductIds = recentlyViewedProducts.map(
        (product: { id: number | string; title: string }) => product.id
    )
    const productsQuery = useGetProductsQuery(
        { id: recentlyViewedProductIds, vendors: [], statuses: [], missing_info: false },
        '',
        recentlyViewedProductIds.length > 0 ? true : false
    )
    const { products } = productsQuery?.data || {}

    function recentlyVisitedSort(a: any, b: any) {
        const aIndex = recentlyViewedProductIds.findIndex((item) => item == a.id)
        const bIndex = recentlyViewedProductIds.findIndex((item) => b.id == item)
        return aIndex - bIndex
    }
    products?.sort(recentlyVisitedSort)

    useEffect(() => {
        if (initRender) {
            setInitRender(false)
        }
    }, [])

    type ProductInfo = {
        id: string | number
        company_id?: number | string
        vendor?: string
        title: string
        status?: string
        sku?: string
        product_type?: string
        has_identifying_info?: boolean
        featured_image?: string
    }

    const tableData = products?.length > 0 ? products : []
    const tableDataMemo = React.useMemo(() => tableData ?? [], [tableData])
    const columnHelper = createColumnHelper<ProductInfo>()

    const productsColumns = [
        columnHelper.accessor('title', {
            minSize: 600,
            header: 'Title',
            cell: (info) => {
                return (
                    <div className='flex flex-col gap-2 '>
                        <Link
                            to={`/products/${info.row.original.id}`}
                            className={cn('underline flex gap-1 items-center')}
                            state={{ previousLocationPathname: location.pathname }}
                        >
                            {typeof info.getValue() === 'number' ? info.getValue() : info.getValue()}
                        </Link>
                    </div>
                )
            },
        }),

        columnHelper.accessor('has_identifying_info', {
            header: 'Info',
            cell: (info) => {
                return (
                    <div className='flex flex-col gap-2 '>
                        {!info.getValue() && (
                            <div className='group relative '>
                                <ExclamationTriangleIcon className='text-danger z-[4] ' />
                                <div className='hidden group-hover:flex border absolute top-[100%] right-0 w-fit whitespace-nowrap bg-white shadow-small text-sm dark:bg-darkbg1 p-2 rounded-[4px] z-[5] '>
                                    Product has no UPC, model number or supplier sku
                                </div>
                            </div>
                        )}
                    </div>
                )
            },
            size: 30,
        }),
        columnHelper.accessor('sku', {
            header: 'Sku',
            cell: (info) => {
                return (
                    <div className='flex flex-col gap-2 '>
                        {typeof info.getValue() === 'number' ? info.getValue() : info.getValue()}
                    </div>
                )
            },
        }),
        columnHelper.accessor('status', {
            cell: (info) => {
                return (
                    <div className='flex flex-col gap-2'>
                        {typeof info.getValue() === 'number' ? info.getValue() : info.getValue()}
                    </div>
                )
            },
            maxSize: 60,
        }),
        columnHelper.accessor('product_type', {
            header: 'Product Type',
            cell: (info) => {
                return (
                    <div className='flex flex-col gap-2'>
                        {typeof info.getValue() === 'number' ? info.getValue() : info.getValue()}
                    </div>
                )
            },
        }),
        columnHelper.accessor('vendor', {
            cell: (info) => {
                return (
                    <div className='flex justify-between items-center gap-2 '>
                        <span className='font-semibold'>{info.getValue()}</span>
                        <Link
                            to={`https://admin.shopify.com/store/factorypure/products/${info.row.original.id}`}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <FaExternalLinkAlt />
                        </Link>
                    </div>
                )
            },
        }),
    ]

    const columnCount = productsColumns?.length.toString() || 10

    const skeletonColumns = React.useMemo(
        () =>
            productsColumns.map((column) => {
                return {
                    ...column,
                    cell: () => (
                        <div
                            className={cn(
                                (column.accessorKey === 'title' ||
                                    column.accessorKey === 'sku' ||
                                    column.accessorKey === 'vendor') &&
                                    ' h-[24px]',
                                ' w-[60%] rounded-sm flex items-center '
                            )}
                        >
                            <SkeletonBasic
                                className={cn(
                                    column.accessorKey !== 'has_identifying_info' && 'p-2',
                                    ' w-[60%] rounded-sm  '
                                )}
                            />
                        </div>
                    ),
                }
            }),
        [productsQuery.isLoading]
    )

    return (
        <>
            <div>
                <>
                    <div className='w-full'>
                        {(productsQuery.isLoading || loading) && tableDataMemo.length < 1 && (
                            <div className='w-full  text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                                <ProductsTable
                                    columns={skeletonColumns}
                                    tableData={Array(5).fill({})}
                                    columnCount={columnCount}
                                />
                            </div>
                        )}
                        {tableDataMemo.length > 1 && (!productsQuery.isLoading || !loading) && (
                            <div className='w-full  text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                                <ProductsTable
                                    columns={productsColumns}
                                    tableData={tableDataMemo}
                                    columnCount={columnCount}
                                />
                            </div>
                        )}
                    </div>
                </>
            </div>
        </>
    )
}
