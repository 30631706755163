import { useEffect, useRef, useState } from 'react'
import { FaGraduationCap, FaTimes } from 'react-icons/fa'
import { FaGear } from 'react-icons/fa6'
import { HiMiniQuestionMarkCircle } from 'react-icons/hi2'
import { IoPerson } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import { setSettings } from '../redux/settings'
import CreateSubmitAQuestionModal from './components/SubmitAQuestionModal'
import AllArticlesView from './views/AllArticlesView'
import DepartmentsMain from './components/DepartmentsMain'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CreateArticleView from './views/CreateArticleView'
import KnowledgeSearch from './components/KnowledgeSearch'
import selected, { setSelected } from '../redux/selected'
import DepartmentView from './views/DepartmentView'
import { vFetch } from '../helpers'
import EditArticleView from './views/EditArticleView'
import MyContributions from './views/MyContributions'
import ArticleView from './views/ArticleView'
import QuestionWithAnswersView from './views/QuestionWithAnswersView'
import { MdOutlineArticle } from 'react-icons/md'
import AdminPanel from './views/AdminPanel'
import AdminArticleView from './views/AdminArticleView'
import { useQuery } from '@tanstack/react-query'
import { TbHexagon } from 'react-icons/tb'
import MyContributionsEditArticleView from './views/MyContributionsEditArticleView'
import QuestionsAndAnswersView from './views/QuestionsAndAnswersView'

export default function KnowledgeScreen() {
    const navigate = useNavigate()
    const modalRef = useRef<HTMLDivElement>(null)
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams()
    // const section = searchParams.get('section') as 'qna' | 'myContribution' | 'departmentView'
    const [view, setView] = useState<
        | 'qna'
        | 'qwa'
        | 'myContributions'
        | 'departmentView'
        | 'createArticleView'
        | 'articleView'
        | 'editArticleView'
        | 'allArticlesView'
        | 'adminPanel'
        | 'adminArticleView'
        | 'myEditArticleView'
    >((searchParams.get('section') as ViewOptionsType) || 'allArticlesView') // || 'allArticlesView'
    const viewOptions = [
        'qna',
        'qwa',
        'myContributions',
        'departmentView',
        'createArticleView',
        'articleView',
        'editArticleView',
        'allArticlesView',
        'adminPanel',
        'adminArticleView',
        'myEditArticleView',
    ] as const
    type ViewOptionsType = (typeof viewOptions)[number]
    const { showKnowledgeScreen } = useSelector((state: any) => state.settings)
    const [openSubmitQuestionModal, setOpenSubmitQuestionModal] = useState(false)
    const [selectedDepartment, setSelectedDepartment] = useState<any>()
    const [selectedPage, setSelectedPage] = useState<any>()
    const [selectedArticle, setSelectedArticle] = useState<undefined | any>()
    const viewParam = searchParams.get('section')
    const currentUser = useSelector((state: any) => state.user)
    // const isAdmin = currentUser.type === 'client'
    const isAdmin = currentUser.type === 'Admin' || currentUser.type === 'SuperAdmin'

    // FETCHES AND QUERIES //
    const globalArticlesQuery = useQuery({
        queryKey: ['knowledge', 'articles', 'global'],
        queryFn: async () => {
            return await vFetch('/v1/knowledge/articles?department_id=0', {
                cb: (res: any) => {
                    if (!res.success) {
                        return []
                    }
                    return res.articles
                },
            })
        },
    })
    const globalArticles = globalArticlesQuery?.data || []
    const globalArticlesFiltered = globalArticles.filter(
        (article: any) => article.status === 'Published' && article.hidden === 0
    )
    const [articleDepartment, setArticleDepartment] = useState()
    const [departmentList, setDeparmentList] = useState([])
    // const articleDepartmentID = articles.find((article: any) => article.department_id)

    const departmentsQuery = useQuery({
        queryKey: ['knowledge', 'departments', 'allArticles'],
        queryFn: async () => {
            return await vFetch('/v1/knowledge/departments')
        },
    })
    const departments = departmentsQuery?.data?.departments || []
    const articlesQuery = useQuery({
        queryKey: ['knowledge', 'articles', 'pending'],
        queryFn: async () => {
            return await vFetch('/v1/knowledge/articles?status=Pending&fields=id', {
                cb: (res: any) => {
                    if (!res.success) {
                        return []
                    }
                    return res.articles
                },
            })
        },
    })
    const pendingArticles = articlesQuery?.data || []

    // HANDLERS //
    const handleSetGlobalArticle = (global: any) => {
        setView('departmentView')
        setSelectedDepartment({ name: 'global' })
        setSelectedArticle(global)
        setSearchParams((prev: any) => {
            prev.set('articleID', global.id)
            prev.set('section', 'departmentView')
            prev.delete('department', global.department_id)
            prev.delete('editArticleView')
            prev.delete('adminArticleView')
            prev.delete('adminPanel')
            return prev
        })
    }

    // USE EFFECTS //
    useEffect(() => {
        if (showKnowledgeScreen) {
            document.body.classList.add('overflow-y-hidden')
        }
        return () => document.body.classList.remove('overflow-y-hidden')
    }, [showKnowledgeScreen])

    // if user clicks on margin around page, it will close
    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (e.target === modalRef.current) {
                navigate(`${window.location.pathname}`)
                dispatch(setSettings({ showKnowledgeScreen: false }))
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    useEffect(() => {
        const foundView = viewOptions.find((option) => option === viewParam)
        if (foundView) {
            setView(foundView)
        }
    }, [viewParam])

    return (
        <div
            ref={modalRef}
            className='dark:text-offwhite fixed top-0 left-0 w-full h-[100vh] bg-[rgb(0,0,0,0.5)] flex items-center justify-center z-[50]'
        >
            <div className='flex relative rounded bg-white dark:bg-darkaccent w-[95%] h-[95%]'>
                <button
                    className='absolute top-0 right-0'
                    onClick={() => {
                        navigate(`${window.location.pathname}`)
                        return dispatch(setSettings({ showKnowledgeScreen: false }))
                    }}
                >
                    <FaTimes className='text-red w-7 h-7' />
                </button>
                <div className='flex flex-col w-[270px] shrink-0 h-full'>
                    <div className='text-2xl border-b  border-lightgrey dark:border-darkgrey p rounded-tl p-8 shrink-0'>
                        <div className='flex items-center gap-2'>
                            <FaGraduationCap className='w-8 text-blue dark:text-accent' />{' '}
                            <div className='font-bold hover:cursor-default'>FPKnowledge</div>
                        </div>
                        <div className='w-[70px] h-[2px] bg-white'></div>
                        <div className='text-sm font-semibold'>
                            <div
                                // className='flex items-center gap-1 pt-2 mb-1'
                                className={`flex items-center gap-1 mt-2 mb-1 pl-2
                                ${view === 'qna' ? 'bg-lightgrey dark:bg-darkbg2 pr-2 rounded' : ''}`}
                                onClick={() => {
                                    setSearchParams((prev) => {
                                        prev.set('section', 'qna')
                                        prev.delete('questionID')
                                        prev.delete('departmentID')
                                        prev.delete('articleID')
                                        prev.delete('myArticleID')
                                        prev.delete('myEditArticle')
                                        prev.delete('createArticleView')
                                        prev.delete('adminArticleView')
                                        prev.delete('adminPanel')
                                        return prev
                                    })
                                    setSelectedDepartment(undefined)
                                    return setView('qna')
                                }}
                            >
                                <HiMiniQuestionMarkCircle className='w-4 h-4 text-blue dark:text-accent' />{' '}
                                <button>Questions & Answers</button>
                            </div>
                            {/* <div className='flex gap-1 items-center pl-2'> */}
                            <div
                                className={`flex gap-1 items-center pl-2
                                ${view === 'allArticlesView' ? 'bg-lightgrey dark:bg-darkbg2 pr-2 rounded' : ''}`}
                            >
                                <MdOutlineArticle className='w-4 h-4 text-blue dark:text-accent' />
                                <button
                                    onClick={() => {
                                        setSearchParams((prev) => {
                                            prev.set('section', 'allArticlesView')
                                            prev.delete('questionID')
                                            prev.delete('articleView')
                                            prev.delete('departmentView')
                                            prev.delete('departmentID')
                                            prev.delete('articleID')
                                            prev.delete('myArticleID')
                                            prev.delete('adminArticleView')
                                            prev.delete('adminPanel')
                                            prev.delete('myEditArticle')
                                            prev.delete('editArticleView')
                                            return prev
                                        })
                                        setSelectedArticle(undefined)
                                        setSelectedDepartment(undefined)
                                        setSelectedPage('allArticlesView')

                                        return setView('allArticlesView')
                                    }}
                                >
                                    All Articles
                                </button>
                            </div>

                            {/* <div className='flex items-center gap-1 mb-1'>
                                <IoDocumentTextSharp className='w-6  text-accent' /> <button>Resources</button>
                            </div>
                            <div className='flex items-center gap-1'>
                                <BiSolidBook className='w-6  text-accent' /> <button>Team Directory</button>
                            </div> */}
                        </div>
                    </div>
                    <div className='border-b  border-lightgrey dark:border-darkgrey py-8 pl-8 pr-2 h-full'>
                        <DepartmentsMain
                            setView={setView}
                            selectedDepartment={selectedDepartment}
                            setSelectedDepartment={setSelectedDepartment}
                            setSelectedArticle={setSelectedArticle}
                            selectedArticle={selectedArticle}
                        />
                    </div>
                    <div className='relative text-sm font-semibold rounded-bl p-8 shrink-0'>
                        {/* <div className=' flex items-center gap-1 mb-1'> */}
                        <div
                            className={`flex items-center gap-1 mb-3
                                ${view === 'myContributions' ? 'bg-lightgrey dark:bg-darkbg2 pr-2 rounded' : ''}`}
                        >
                            <IoPerson className='w-6 text-blue  dark:text-accent' />{' '}
                            <button
                                onClick={() => {
                                    setSearchParams((prev) => {
                                        prev.set('section', 'myContributions')
                                        prev.delete('articleID')
                                        prev.delete('questionID')
                                        prev.delete('departmentID')
                                        prev.delete('articleID')
                                        prev.delete('createArticleView')
                                        prev.delete('editArticleView')
                                        prev.delete('adminArticleView')
                                        prev.delete('adminPanel')
                                        prev.delete('myEditArticle')
                                        return prev
                                    })
                                    setSelectedArticle(undefined)
                                    setSelectedDepartment(undefined)
                                    return setView('myContributions')
                                }}
                                className=''
                            >
                                My Contributions
                            </button>
                        </div>
                        {isAdmin && (
                            // <div className='flex items-center gap-1 mb-1'>
                            <div
                                className={`flex items-center gap-1 mb-1
                            ${view === 'adminPanel' ? 'bg-lightgrey dark:bg-darkbg2 pr-2 rounded' : ''}`}
                            >
                                <FaGear className='w-6 text-blue dark:text-accent' />
                                <button
                                    onClick={() => {
                                        setSearchParams((prev) => {
                                            prev.set('section', 'adminPanel')
                                            prev.delete('articleID')
                                            prev.delete('questionID')
                                            prev.delete('departmentID')
                                            prev.delete('articleID')
                                            prev.delete('myArticleID')
                                            prev.delete('createArticleView')
                                            prev.delete('editArticleView')
                                            prev.delete('myEditArticle')
                                            prev.delete('adminArticleView')
                                            return prev
                                        })
                                        setSelectedArticle(undefined)
                                        setSelectedDepartment(undefined)
                                    }}
                                >
                                    Admin Panel
                                </button>
                                {pendingArticles?.length > 0 && pendingArticles?.length <= 9 && (
                                    <div className='relative top-0 left-[4px] text-blue dark:text-accent'>
                                        <TbHexagon className='text-xl' />
                                        <p className='absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] text-xs font-bold'>
                                            {pendingArticles.length}
                                        </p>
                                    </div>
                                )}
                                {pendingArticles?.length > 9 && (
                                    <div className='relative top-[-6px] left-[-1px] text-blue dark:text-accent'>
                                        <TbHexagon className='text-xl' />
                                        <p className='absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] text-xs font-bold'>
                                            !
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                {/* MIDDLE VIEW SECTION  */}
                <div className='h-full w-full flex flex-col border-l border-r border-lightgrey dark:border-darkgrey pw-full py-8 px-4'>
                    <div className='z-10 bg-white dark:bg-darkness'>
                        {departments.length > 0 && (
                            <KnowledgeSearch
                                setView={setView}
                                departments={departments}
                                setSelectedArticle={setSelectedArticle}
                            />
                        )}
                    </div>
                    <div className='fixed z-50 min-w-[400px] bottom-[2.5%] left-1/2 translate-x-[-50%] w-[600px]'>
                        {openSubmitQuestionModal && (
                            <CreateSubmitAQuestionModal openModal={setOpenSubmitQuestionModal} />
                        )}
                    </div>
                    <div className='h-full overflow-auto'>
                        {view === 'qna' && <QuestionsAndAnswersView setView={setView} />}
                        {view === 'qwa' && (
                            <QuestionWithAnswersView
                                setView={setView}
                                selectedQuestion={''}
                                handleDeleteQuestion={''}
                                getQuestions={''}
                            />
                        )}
                        {view === 'allArticlesView' && departments.length > 0 && (
                            <AllArticlesView
                                setView={setView}
                                setSelectedArticle={setSelectedArticle}
                                selectedArticle={selectedArticle}
                                selectedDepartment={selectedDepartment}
                                departments={departments}
                            />
                        )}
                        {view === 'createArticleView' && <CreateArticleView setView={setView} />}
                        {view === 'editArticleView' && (
                            <EditArticleView
                                setView={setView}
                                selectedArticle={selectedArticle}
                                setSelectedArticle={setSelectedArticle}
                            />
                        )}
                        {view === 'myEditArticleView' && selectedArticle && (
                            <>
                                <MyContributionsEditArticleView
                                    setView={setView}
                                    selectedArticle={selectedArticle}
                                    setSelectedArticle={setSelectedArticle}
                                />
                            </>
                        )}
                        {view === 'departmentView' && selectedDepartment && (
                            <DepartmentView
                                setView={setView}
                                selectedDepartment={selectedDepartment}
                                selectedArticle={selectedArticle}
                                setSelectedArticle={setSelectedArticle}
                            />
                        )}
                        {view === 'articleView' && (
                            <ArticleView
                                setView={setView}
                                selectedArticle={selectedArticle}
                                setSelectedArticle={setSelectedArticle}
                                // departmentID={selectedArticle.department_id}
                            />
                        )}
                        {view === 'myContributions' && (
                            <MyContributions
                                setView={setView}
                                selectedDepartment={selectedDepartment}
                                selectedArticle={selectedArticle}
                                setSelectedArticle={setSelectedArticle}
                            />
                        )}
                        {view === 'adminPanel' && (
                            <AdminPanel setView={setView} setSelectedArticle={setSelectedArticle} />
                        )}
                        {view === 'adminArticleView' && selectedArticle && (
                            <AdminArticleView
                                setView={setView}
                                selectedArticle={selectedArticle}
                                setSelectedArticle={setSelectedArticle}
                            />
                        )}
                    </div>
                </div>
                {view !== 'createArticleView' && view !== 'editArticleView' && view !== 'myEditArticleView' && (
                    <div className='text-sm font-semibold flex flex-col w-[270px] shrink-0 h-full'>
                        <div className=' border-b  border-lightgrey dark:border-darkgrey p rounded-tr p-8 shrink-0'>
                            <button
                                onClick={() => {
                                    setOpenSubmitQuestionModal(true)
                                }}
                                className=' mb-1'
                            >
                                Submit A New Question
                            </button>
                            <button
                                className=''
                                onClick={() => {
                                    setSearchParams((prev) => {
                                        prev.set('createArticleView', 'createArticleView') //
                                        prev.delete('questionID')
                                        prev.delete('editArticleView')
                                        prev.delete('adminArticleView')
                                        prev.delete('adminPanel')
                                        return prev
                                    })
                                    setSelectedArticle(undefined)
                                    setSelectedDepartment(undefined)
                                    return setView('createArticleView')
                                }}
                            >
                                Create A New Article
                            </button>
                            {/* <button className=' mb-1'>Open A Ticket</button> */}
                        </div>
                        {globalArticlesFiltered.length > 0 && (
                            <div className='rounded-br h-full'>
                                {globalArticlesFiltered.length === 1 ? (
                                    <h2 className='font-bold text-lg hover:cursor-default pt-8 pl-8 mb-2'>
                                        Global Article
                                    </h2>
                                ) : (
                                    <h2 className='font-bold text-lg hover:cursor-default pt-8 pl-8 mb-2'>
                                        Global Articles
                                    </h2>
                                )}
                                <div>
                                    {globalArticlesFiltered?.map((global: any) => (
                                        <button
                                            key={`global-article-${global.id}`}
                                            // className='flex flex-cols leading-tight mb-2 text-left px-8'
                                            className={`flex flex-cols leading-tight mb-1 text-left py-1 pl-2 mx-6 w-[220px]
                                            ${
                                                selectedArticle?.id === global.id
                                                    ? 'bg-lightgrey dark:bg-darkbg2 pr-2 rounded'
                                                    : ''
                                            }`}
                                            onClick={() => handleSetGlobalArticle(global)}
                                        >
                                            {global.title}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
