import { Key, MouseEventHandler, useEffect, useState } from 'react'
import { ResourceType, ResourceListProps } from '../ProcessingTimesScreen.types'
import Resource from './Resource'
import { useDebounce, vFetch } from '../../helpers'
import { LoadingGear } from '../../helpers'

export default function ResourceList({
    affectedResourcesLoading,
    affectedResources = [],
    pushList,
    setPushList,
    setPopList,
}: ResourceListProps) {
    const [addType, setAddType] = useState<AddType>('Product')
    const [addSearch, setAddSearch] = useState<string>('')
    const debouncedSearch = useDebounce(addSearch, 300)
    const [resourceList, setResourceList] = useState<ResourceType[]>([])
    const [resourceListLoading, setResourceListLoading] = useState<boolean>(false)
    const [affectedResourcesSearch, setAffectedResourcesSearch] = useState<string>('')
    const [affectedResourcesFilterType, setAffectedResourcesFilterType] = useState<ResourceFilterType>('All')
    const [filteredPushList, setFilteredPushList] = useState<ResourceType[]>([])

    /* ----- EFFECTS ----- */

    // Fetch resourceList
    useEffect(() => {
        if (addType === 'Product') {
            setResourceListLoading(true)
            getProductList(addSearch)
                .then(setResourceList)
                .then(() => setResourceListLoading(false))
        }
        if (addType === 'Brand') {
            setResourceListLoading(true)
            getBrandList(addSearch)
                .then(setResourceList)
                .then(() => setResourceListLoading(false))
        }
        if (addType === 'Collection') {
            setResourceListLoading(true)
            getCollectionList(addSearch)
                .then(setResourceList)
                .then(() => setResourceListLoading(false))
        }
        if (addType === 'ProductVariant') {
            setResourceListLoading(true)
            getVariantList(addSearch)
                .then(setResourceList)
                .then(() => setResourceListLoading(false))
        }
    }, [debouncedSearch, addType])

    // Search and filter affectedResources
    useEffect(() => {
        setResourceListLoading(false)
        let newList = [...pushList]
        if (affectedResourcesFilterType !== 'All') {
            if (affectedResourcesFilterType === 'Brand') {
                newList = newList.filter((resouce) => resouce.is_vendor)
            } else {
                newList = newList.filter((resouce) => resouce.gid.includes(`/${affectedResourcesFilterType}/`))
            }
        }
        newList = newList.filter((resource) => {
            return (
                resource?.gid?.includes(affectedResourcesSearch.toLowerCase()) ||
                resource?.title?.toLowerCase().includes(affectedResourcesSearch.toLowerCase()) ||
                resource?.sku?.toLowerCase().includes(affectedResourcesSearch.toLowerCase()) ||
                resource?.product_title?.toLowerCase().includes(affectedResourcesSearch.toLowerCase())
            )
        })
        setFilteredPushList(newList)
    }, [affectedResourcesSearch, affectedResourcesFilterType, pushList])

    // Reset resource list
    useEffect(() => {
        setFilteredPushList([...affectedResources])
        setResourceListLoading(true)
        setAddSearch('')
        setAffectedResourcesSearch('')
        setAddType('Product')
        setAffectedResourcesFilterType('All')
    }, [affectedResources])

    /* ----- GETTERS ----- */

    async function getProductList(search: string): Promise<ResourceType[]> {
        const result = await vFetch(`/v1/products/search?fields=gid,title&search=${search}`, { catchCb: () => null })

        if (result?.success) {
            return result.products
        }
        return []
    }
    async function getBrandList(search: string): Promise<ResourceType[]> {
        const result = await vFetch(
            `/v2/collections?brands_only=true&fields=admin_graphql_api_id AS gid,title&search=${search}`,
            {
                catchCb: () => null,
            }
        )
        if (result?.success) {
            return result.collections
        }
        return []
    }
    async function getCollectionList(search: string): Promise<ResourceType[]> {
        const result = await vFetch(`/v2/collections?fields=admin_graphql_api_id AS gid,title&search=${search}`, {
            catchCb: () => null,
        })

        if (result?.success) {
            return result.collections
        }
        return []
    }
    async function getVariantList(search: string): Promise<ResourceType[]> {
        const result = await vFetch(`/v1/variants/search?fields=gid,title&search=${search}`, { catchCb: () => null })

        if (result?.success) {
            return result.variants
        }
        return []
    }

    /* ----- SETTERS ----- */

    const handleRemoveResource = (resource: ResourceType) => {
        setAffectedResourcesFilterType('All')
        setPushList((previousState) => {
            const index = previousState.findIndex((r) => r.gid === resource.gid)
            if (index !== -1) {
                previousState.splice(index, 1)
                return [...previousState]
            }
            return previousState
        })
        setPopList((previousState) => {
            if (affectedResources.find((r) => r.gid === resource.gid)) {
                return [...previousState, resource]
            }
            return previousState
        })
    }

    const handleAddResource = (resource: ResourceType) => {
        setAffectedResourcesFilterType('All')
        setPushList((previousState) => [resource, ...previousState])
        setPopList((previousState) => previousState.filter((r) => r.gid !== resource.gid))
    }
    return (
        <div className='w-1/2 flex flex-col gap-[16px]'>
            <div className='bg-lightgrey dark:bg-darkness shadow-small rounded-[4px] flex flex-col gap-[8px] h-1/2 relative'>
                <div className='flex'>
                    <h3 className='w-fit shrink-0 p-[4px] text-[12px] uppercase font-bold text-white bg-blue dark:bg-accent dark:text-darkness rounded-tl-[4px]'>
                        Affected Resources
                    </h3>
                    <select
                        onChange={({ target }) => setAffectedResourcesFilterType(target.value as ResourceFilterType)}
                        value={affectedResourcesFilterType}
                        className='bg-darkgrey text-offwhite font-bold text-[12px] uppercase focus:outline-none'
                    >
                        <option value='All'>All</option>
                        <option value='Product'>Product</option>
                        <option value='Brand'>Brand</option>
                        <option value='Collection'>Collection</option>
                        <option value='ProductVariant'>Variant</option>
                    </select>
                    <input
                        value={affectedResourcesSearch}
                        onChange={({ target }) => setAffectedResourcesSearch(target.value)}
                        className='w-full px-[4px] focus:outline-none dark:bg-faintplus dark:text-white rounded-tr-[4px]'
                        type='text'
                        placeholder='Search'
                    />
                </div>
                {affectedResourcesLoading}
                <div className='p-[8px] flex flex-col gap-[8px] overflow-y-auto h-full shadow-[inset_0_0_5px_rgb(0,0,0,0.15)] m-[4px] mt-0 bg-[rgb(0,0,0,0.05)]'>
                    {affectedResourcesLoading ? (
                        <div className='w-full h-full flex items-center justify-center p-[64px]'>
                            <LoadingGear width={'200px'} height={'200px'} />
                        </div>
                    ) : (
                        <>
                            {filteredPushList.map((resource) => (
                                <Resource
                                    key={`PUSH-${resource.gid}` as Key}
                                    resource={resource}
                                    deletable={true}
                                    handleRemove={handleRemoveResource}
                                />
                            ))}
                        </>
                    )}
                </div>
            </div>
            <div className='bg-lightgrey dark:bg-darkness shadow-small rounded-[4px] flex flex-col gap-[8px] h-1/2 relative overflow-auto'>
                <div className='flex'>
                    <h3 className='w-fit shrink-0 p-[4px] text-[12px] uppercase font-bold text-white dark:text-darkness bg-fire rounded-tl-[4px]'>
                        Add Resources
                    </h3>
                    <select
                        onChange={({ target }) => setAddType(target.value as AddType)}
                        value={addType}
                        className='bg-darkgrey text-offwhite font-bold text-[12px] uppercase focus:outline-none'
                    >
                        <option value='Product'>Product</option>
                        <option value='Brand'>Brand</option>
                        <option value='Collection'>Collection</option>
                        <option value='ProductVariant'>Variant</option>
                    </select>
                    <input
                        onChange={({ target }) => setAddSearch(target.value)}
                        className='w-full px-[4px] focus:outline-none rounded-tr-[4px] dark:bg-faintplus dark:text-white'
                        type='text'
                        placeholder='Search'
                    />
                </div>
                <div className='p-[8px] flex flex-col gap-[8px] overflow-y-auto h-full shadow-[inset_0_0_5px_rgb(0,0,0,0.15)] m-[4px] mt-0 bg-[rgb(0,0,0,0.05)]'>
                    {resourceListLoading ? (
                        <div className='w-full h-full flex items-center justify-center p-[64px]'>
                            <LoadingGear width={'200px'} height={'200px'} />
                        </div>
                    ) : (
                        <>
                            {resourceList
                                .filter((resource) => !pushList.find((r) => r.gid === resource.gid))
                                .map((resource) => (
                                    <Resource
                                        key={`ADD-${resource.gid}` as Key}
                                        resource={resource}
                                        deletable={false}
                                        handleAdd={handleAddResource}
                                    />
                                ))}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

type AddType = 'Product' | 'Brand' | 'Collection' | 'ProductVariant'
type ResourceFilterType = 'All' | 'Product' | 'Brand' | 'Collection' | 'ProductVariant'
