import { ChangeEvent } from 'react'
import { BsFileBarGraph, BsGraphUp } from 'react-icons/bs'
import { TbReportSearch } from 'react-icons/tb'
import { VscGraph, VscGraphLine } from 'react-icons/vsc'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button } from '../../custom_components/component_Basics/Button'
import { cn } from '../../helpers'
import { TIME_PERIOD_OPTIONS } from '../helpers'

export function GraphContainer({
    timePeriodIndex,
    setTimePeriodIndex,
    setOptionsIndex,
    options,
    setChartType,
    chartType,
    intervalEnabled,
    reportName,
    children,
}: {
    timePeriodIndex?: any
    setTimePeriodIndex?: any
    setOptionsIndex?: any
    options?: {
        tag: string
        value: string[]
        title: string
    }[]
    setChartType?: any
    chartType?: any
    intervalEnabled?: boolean
    reportName?: string
    children: any
}) {
    let [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const interval = searchParams.get('interval')
    const chartActions = [
        {
            displayText: 'Line Graph',
            icon: VscGraphLine,
            action: () => setChartType('line'),
        },
        {
            displayText: 'Bar Graph',
            icon: BsFileBarGraph,
            action: () => setChartType('bar'),
        },
    ]

    const handleChange = ({ target }: ChangeEvent<HTMLSelectElement>) => {
        const index = target.value
        setOptionsIndex(index)
    }
    return (
        <div className='relative flex flex-col justify-start gap-1 max-w-fit '>
            <div className='flex gap-2  absolute top-1 left-1 z-[5]'>
                <Button
                    className={cn(intervalEnabled === false ? 'pointer-events-none' : 'pointer-events-auto', 'w-fit')}
                    variant={'outline'}
                    size={'sm'}
                    onClick={() =>
                        setTimePeriodIndex((prev: number) => {
                            if (prev + 1 > TIME_PERIOD_OPTIONS.length - 1) {
                                return 0
                            } else {
                                return prev + 1
                            }
                        })
                    }
                >
                    {TIME_PERIOD_OPTIONS[timePeriodIndex]}
                </Button>
                <div className='self-center'>
                    <button
                        onClick={() => setChartType(chartType === 'bar' ? 'line' : 'bar')}
                        className='text-[14px] font-medium rounded-[4px] bg-lightgrey dark:bg-darkaccent dark:text-offwhite p-[6px] leading-[1]'
                    >
                        {chartType === 'line' && <BsGraphUp />}
                        {chartType === 'bar' && <VscGraph />}
                    </button>
                </div>

                {reportName && (
                    <div className='self-center'>
                        <button
                            onClick={() => navigate(`/analytics/${reportName}?${searchParams.toString()}`)}
                            className='text-[14px] font-medium rounded-[4px] bg-lightgrey dark:bg-darkaccent dark:text-offwhite p-[6px] leading-[1]'
                        >
                            <TbReportSearch />
                        </button>
                    </div>
                )}
            </div>
            {options && (
                <div
                    className={`inline-flex items-center rounded-md justify-center whitespace-nowrap text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 absolute top-1 right-1 flex-col gap-[4px] w-fit z-[5] cursor-pointer border border-input bg-bg1 dark:bg-darkbg1 shadow-sm hover:bg-lightgrey hover:dark:bg-darkbg2 hover:text-accent2`}
                >
                    <select
                        autoComplete='off'
                        className=' dark:bg-darkbg1 focus:outline-none dark:text-white p-[4px] rounded-md'
                        onChange={handleChange}
                    >
                        {options?.map((option: any, index) => (
                            <option className='dark:bg-darkness/90' value={index}>
                                {option.title}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            {children}
        </div>
    )
}
