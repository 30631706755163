import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { leadTimes, productGroups, productSetupQueries } from './productSetupQueryKeys'
import * as service from './service'
import { useLocation } from 'react-router-dom'
import { LeadTime } from 'productSetupV2/inventoryLeadTimes/InventoryLeadTimesList'

export function useGetInventoryEmailsQuery({
    emailIdParam,
    filters = { vendors: undefined, statuses: undefined, after: undefined, before: undefined },
    search,
}: {
    emailIdParam?: string | undefined
    filters?: any | undefined
    search?: string | undefined
}): any {
    const query = useQuery({
        ...productSetupQueries.productSetup.inventory({
            emailIdParam,
            filters,
            search,
        }),
        placeholderData: keepPreviousData,
        meta: {
            clientErrMessage: 'Failed to load inventory emails',
            pathname: useGetPathname(),
            functionName: 'useGetInventoryEmailsQuery',
        },
    })

    return query
}
export function useFilesQuery(files: any): any {
    const query = useQuery({
        ...productSetupQueries.productSetup.files(files),
        enabled: Boolean(files),
        meta: {
            clientErrMessage: 'Failed to load inventory file details',
            pathname: useGetPathname(),
            functionName: 'useFIlesQuery',
        },
    })

    return query
}
export function useManualInventorySheetUpload() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({
            keyMap,
            headingString,
            src,
            vendors,
            emailId,
        }: {
            keyMap: any
            headingString?: string
            src: string
            vendors: string[]
            emailId: string | number
        }) => {
            return service.manualInventorySheetUpload({ keyMap, headingString, src, vendors, emailId })
        },
        meta: {
            clientErrMessage: 'Failed to upload inventory sheet',
            pathname: useGetPathname(),
            functionName: 'useManualInventorySheetUpload',
        },
        onSuccess: (data, variables, context) => {
            return queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.inventory._def })
        },
    })
}
export function useSaveInventoryMap() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({
            keyMap,
            companyIds,
            headingString,
        }: {
            keyMap: any
            companyIds: string[] | number[]
            headingString: string
        }) => {
            return service.saveInventoryMap(keyMap, companyIds, headingString)
        },
        meta: {
            clientErrMessage: 'Failed to save inventory map',
            pathname: useGetPathname(),
            functionName: 'useSaveInventoryMap',
        },
        onSuccess: (data, variables, context) => {
            return queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.inventory._def })
        },
    })
}
export function useDeleteInventoryEmailMap() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ id }: { id: string | number }) => {
            return service.deleteInventoryEmailMap(id)
        },
        meta: {
            clientErrMessage: 'Failed to delete inventory map',
            pathname: useGetPathname(),
            functionName: 'useDeleteInventoryEmailMap',
        },
        onSuccess: (data, variables, context) => {
            return queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.inventory._def })
        },
    })
}
export function useCreateCompanySenderAddress() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ from, companyId }: { from: string; companyId: string | number }) => {
            return service.addCompanySenderAddress(from, companyId)
        },
        meta: {
            clientErrMessage: 'Failed to link sender address',
            pathname: useGetPathname(),
            functionName: 'useCreateCompanySenderAddress',
        },
        onSuccess: (data, variables, context) => {
            return queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.inventory._def })
        },
    })
}
export function useDeleteCompanySenderAddress() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ from, companyId }: { from: string; companyId: string | number }) => {
            return service.deleteCompanySenderAddress(from, companyId)
        },
        meta: {
            clientErrMessage: 'Failed to delete sender address',
            pathname: useGetPathname(),
            functionName: 'useDeleteCompanySenderAddress',
        },
        onSuccess: (data, variables, context) => {
            return queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.inventory._def })
        },
    })
}
export function useUpdateEmailStatus() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ emailId, status }: { emailId: string | number; status: string }) => {
            return service.updateEmailStatus(emailId, status)
        },
        meta: {
            clientErrMessage: 'Failed to update email status',
            pathname: useGetPathname(),
            functionName: 'useUpdateEmailStatus',
        },
        onSuccess: (data, variables, context) => {
            return queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.inventory._def })
        },
    })
}
export function useGetProductsQuery(filters: any, search: string, enabled?: boolean, placeholder?: boolean): any {
    const query = useQuery({
        ...productSetupQueries.productSetup.products(filters, search),
        enabled: !enabled ? enabled : true,
        placeholderData: placeholder ? keepPreviousData : false,
        meta: {
            clientErrMessage: 'Failed to load products',
            pathname: useGetPathname(),
            functionName: 'useGetProductsQuery',
        },
    })

    return query
}
export function useGetProductSetupEvents(productId: string | undefined): any {
    const query = useQuery({
        ...productSetupQueries.productSetup.productSetupEvents(productId),
        enabled: Boolean(productId),
        meta: {
            clientErrMessage: 'Failed to load product history',
            pathname: useGetPathname(),
            functionName: 'useGetProductSetupEvents',
        },
    })

    return query
}
export function useGetProductVariantsQuery(product_id: any): any {
    const query = useQuery({
        ...productSetupQueries.productSetup.variants(product_id),
        enabled: Boolean(product_id),
        meta: {
            clientErrMessage: 'Failed to load product variants',
            pathname: useGetPathname(),
            functionName: 'useGetProductVariantsQuery',
        },
    })

    return query
}
export function useGetProductSetupPrivateEvents(): any {
    const query = useQuery({
        ...productSetupQueries.productSetup.privateEvents(),
        meta: {
            clientErrMessage: 'Failed to load',
            pathname: useGetPathname(),
            functionName: 'useGetProductSetupPrivateEvents',
        },
    })

    return query
}
export function useUpdateVariantFields() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ variant }: { variant: any }) => {
            return service.updateVariantFields(variant)
        },
        meta: {
            clientErrMessage: 'Failed to update variant fields',
            pathname: useGetPathname(),
            functionName: 'useUpdateVariantFields',
        },

        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.products._def })
            return queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.variants._def })
        },
    })
}

// product groups

export function useGetProductGroupsQuery({
    filters = { after: undefined, before: undefined },
}: {
    groupId?: string | undefined
    filters?: any | undefined
}): any {
    const query = useQuery({
        ...productSetupQueries.productGroups.list(filters),
        placeholderData: keepPreviousData,
        meta: {
            clientErrMessage: 'Failed to load groups',
            pathname: useGetPathname(),
            functionName: 'useGetProductGroupsQuery',
        },
    })

    return query
}
export function useGetGroupedProductIdsQuery(): any {
    const query = useQuery({
        ...productSetupQueries.productGroups.groupedProductIdsQuery(),
        meta: {
            clientErrMessage: 'Failed to load product Ids',
            pathname: useGetPathname(),
            functionName: 'useGetGroupedProductIdsQuery',
        },
    })

    return query
}
export function useGetProductGroupDetailQuery({
    groupId,
    productId,
}: {
    groupId?: string | undefined
    productId?: number | undefined
}): any {
    const query = useQuery({
        ...productSetupQueries.productGroups.detail({ groupId, productId }),
        placeholderData: keepPreviousData,
        enabled: Boolean(groupId || productId),
        meta: {
            clientErrMessage: 'Failed to load product group',
            pathname: useGetPathname(),
            functionName: 'useGetProductGroupDetailQuery',
        },
    })

    return query
}
export function useGetGroupOptionsQuery({ groupId }: { groupId?: string | undefined }): any {
    const query = useQuery({
        ...productSetupQueries.productGroups.detail({ groupId })._ctx.options,
        placeholderData: keepPreviousData,
        meta: {
            clientErrMessage: 'Failed to load group options',
            pathname: useGetPathname(),
            functionName: 'useGetGroupOptionsQuery',
        },
    })

    return query
}

// product group mutations

export function useUpdateGroup() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ groupId, name }: { groupId: string | number; name: string }) => {
            return service.updateGroup(groupId, name)
        },
        meta: {
            clientErrMessage: 'Failed to update group',
            pathname: useGetPathname(),
            functionName: 'useUpdateGroup',
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.productSetupEvents._def })
            return queryClient.invalidateQueries({
                queryKey: productSetupQueries.productGroups._def,
            })
        },
    })
}
export function useDeleteGroup() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ groupId }: { groupId: string | number }) => {
            return service.deleteGroup(groupId)
        },
        meta: {
            clientErrMessage: 'Failed to delete group',
            pathname: useGetPathname(),
            functionName: 'useDeleteGroup',
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.productSetupEvents._def })
            return queryClient.invalidateQueries({})
        },
    })
}
export function useCreateGroup() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ group_name }: { group_name: string }) => {
            return service.createGroup(group_name)
        },
        meta: {
            clientErrMessage: 'Failed to create group',
            pathname: useGetPathname(),
            functionName: 'useCreateGroup',
        },
        onSuccess: (data, variables, context) => {
            return queryClient.invalidateQueries({})
        },
    })
}

export function useUpdateOptions() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({
            groupId,
            updates,
            deletions,
            additions,
        }: {
            groupId: string | number
            updates: { option_id: string | number; option_name: string }[] | []
            deletions: string[]
            additions: ({ option_name: string; position: number } | undefined)[]
        }) => {
            return service.updateOptions(groupId, updates, deletions, additions)
        },
        meta: {
            clientErrMessage: 'Failed to update options',
            pathname: useGetPathname(),
            functionName: 'useUpdateOptions',
        },

        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.productSetupEvents._def })
            return queryClient.invalidateQueries({
                queryKey: productSetupQueries.productGroups._def,
            })
        },
    })
}
export function useUpdateOptionValues() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({
            groupId,
            updates,
            deletions,
        }: {
            groupId: string | number
            updates: { option_id: string | number; product_link_id: number; option_value: string }[] | []
            deletions: string[]
        }) => {
            return service.updateOptionValues(groupId, updates, deletions)
        },
        meta: {
            clientErrMessage: 'Failed to update option values',
            pathname: useGetPathname(),
            functionName: 'useUpdateOptionValues',
        },

        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.productSetupEvents._def })
            return queryClient.invalidateQueries({
                queryKey: productSetupQueries.productGroups._def,
            })
        },
    })
}
export function useAddGroupProductLink() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ groupId, productId }: { groupId: string | number; productId: string | number }) => {
            return service.addGroupProductLink(groupId, productId)
        },
        meta: {
            clientErrMessage: 'Failed to add product link',
            pathname: useGetPathname(),
            functionName: 'useAddGroupProductLink',
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.productSetupEvents._def })
            return queryClient.invalidateQueries({
                queryKey: productSetupQueries.productGroups._def,
            })
        },
    })
}
export function useDeleteGroupProductLinks() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ groupId, productIds }: { groupId: string | number; productIds: (string | number)[] }) => {
            return service.deleteGroupProductLinks(groupId, productIds)
        },
        meta: {
            clientErrMessage: 'Failed to delete product link',
            pathname: useGetPathname(),
            functionName: 'useDeleteGroupProductLinks',
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.productSetupEvents._def })
            return queryClient.invalidateQueries({
                queryKey: productSetupQueries.productGroups._def,
            })
        },
    })
}

export function useGetPathname() {
    const location = useLocation()
    return location.pathname
}

// LEAD TIMES

export function useGetLeadTimes({
    leadTimeId,
    filters = { vendors: undefined, statuses: undefined, after: undefined, before: undefined },
    search,
}: {
    leadTimeId?: string | undefined
    filters?: any | undefined
    search?: string | undefined
}) {
    const query = useQuery({
        ...leadTimes.list({
            leadTimeId,
            filters,
            search,
        }),
        placeholderData: keepPreviousData,
        meta: {
            clientErrMessage: 'Failed to load lead times',
            pathname: useGetPathname(),
            functionName: 'useGetLeadTimes',
        },
    })

    return query
}
export function useGetLeadTimeDetail(leadTimeId: string | undefined, filters: any) {
    const queryClient = useQueryClient()

    const query = useQuery({
        ...leadTimes.detail(leadTimeId),
        enabled: Boolean(leadTimeId),
        initialData: () => {
            return {
                leadTime: queryClient
                    .getQueryData<{ success: boolean; leadTimes: LeadTime[] }>([
                        ...productSetupQueries.leadTimes.list(filters).queryKey,
                    ])
                    ?.leadTimes?.find((leadTime) => leadTime.id.toString() === leadTimeId),
            }
        },
        initialDataUpdatedAt: () => Date.now() - 300000,
        meta: {
            clientErrMessage: 'Failed to load lead time details',
            pathname: useGetPathname(),
            functionName: 'useGetLeadTimeDetail',
        },
    })

    return query
}

export function useCreateLeadTime() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({
            leadTime,
        }: {
            leadTime: {
                product_id: number
                variant_id: number
                quantity: number
                preorder_days: number
                inventory_policy: 'deny' | 'continue'
                date: Date
                user_alias: string
                options?: any
            }
        }) => {
            return service.createLeadTime({ leadTime })
        },
        meta: {
            clientErrMessage: 'Failed to create lead time',
            pathname: useGetPathname(),
            functionName: 'useCreateLeadTime',
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.variants._def })
            return queryClient.invalidateQueries({
                queryKey: productSetupQueries.leadTimes._def,
            })
        },
    })
}
export function useDeleteLeadTime() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ leadTimeId, variantId }: { leadTimeId: number | string; variantId: number | string }) => {
            return service.deleteLeadTime(leadTimeId, variantId)
        },
        meta: {
            clientErrMessage: 'Failed to delete lead time',
            pathname: useGetPathname(),
            functionName: 'useDeleteLeadTime',
        },
        onSuccess: (data, variables, context) => {
            return queryClient.invalidateQueries({
                queryKey: productSetupQueries.leadTimes._def,
            })
        },
    })
}
export function useUpdateLeadTime() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ leadTimeUpdates, original }: { leadTimeUpdates: any; original: any }) => {
            return service.updateLeadTime(leadTimeUpdates, original)
        },
        meta: {
            clientErrMessage: 'Failed to update lead time',
            pathname: useGetPathname(),
            functionName: 'useUpdateLeadTime',
        },
        onSuccess: (data, variables, context) => {
            queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.variants._def })
            return queryClient.invalidateQueries({
                queryKey: productSetupQueries.leadTimes._def,
            })
        },
    })
}
