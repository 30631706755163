import { Button } from 'custom_components/component_Basics/Button'
import { useGetChangelogs } from '../api/useQueries'
import { Link, useSearchParams } from 'react-router-dom'
import CreateChangeLogModal from './modals/CreateChangelogModal'
import { ChangelogType } from './Changelog.types'
import { useSelector } from 'react-redux'

const dateFormatter = (dateString: string) => {
    if (!dateString) {
        return 'Never'
    }
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
    }).format(new Date(dateString))
}

export default function ChangelogListScreen() {
    const user = useSelector((state: any) => state.user)
    const isDeveloper = user.roles.includes('developer')
    const [searchParams, setSearchParams] = useSearchParams()
    const showCreateModal = searchParams.get('showCreateModal') === 'true'
    const changelogsQuery = useGetChangelogs({})
    const changelogs: ChangelogType[] = changelogsQuery.data?.changelogs || []
    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showCreateModal')
            return setSearchParams(searchParams)
        }
        searchParams.set('showCreateModal', 'true')
        return setSearchParams(searchParams)
    }
    return (
        <div className='text-sm max-w-[1000px] mx-auto'>
            <div className='flex justify-between mb-4'>
                <h1 className='text-2xl font-semibold'>Changelogs</h1>
                {isDeveloper && (
                    <Button variant='outline' onClick={toggleCreateModal}>
                        + New Changelog
                    </Button>
                )}
            </div>
            <div className='grid grid-cols-3 gap-4'>
                {changelogs.map((changelog) => (
                    <Link
                        to={`/changelogs/${changelog.id}`}
                        className='shadow-md rounded border border-lighrgrey dark:border-darkgrey p-4 hover:shadow-lg'
                    >
                        <h2 className='font-bold'>{changelog.app_name}</h2>
                        <p>
                            Version: {changelog.major_version}.{changelog.minor_version}.{changelog.patch_version}
                        </p>
                        <p>Last updated: {dateFormatter(changelog.last_updated)}</p>
                    </Link>
                ))}
            </div>
            {showCreateModal && isDeveloper && <CreateChangeLogModal closeModal={toggleCreateModal} />}
        </div>
    )
}
