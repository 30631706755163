import { sendToast } from '../helpers'
import { VendorContactCreateInit, VendorContactEditInit } from './vendors.types'

export function validateAndHandlePhoneNumber(
    target: any,
    property: string,
    handler: Function,
    maxLength: number = Infinity
) {
    let { value } = target
    let valueName = property
        .split('_')
        .filter((v) => v)
        .map((w, i) => (i === 0 ? [w[0].toUpperCase(), w.slice(1)].join('') : w))
        .join(' ')
    if (value.match(/\D/)) return sendToast({ message: `${valueName} must be a number.` })
    while (value.match(/\D/)) value = value.replace(/\D/, '')
    if (value.length > maxLength) return sendToast({ message: `${valueName} has a max length of ${maxLength}` })
    handler()
}
