import { useQuery } from '@tanstack/react-query'
import { vFetch } from '../../helpers'
import { Dispatch, SetStateAction } from 'react'
import RoadmapRow from './RoadmapRow'
import { useRoadmaps } from '../api/useQueries'

export default function PinnedRoadmaps({
    setQuickModal,
}: {
    setQuickModal: Dispatch<SetStateAction<undefined | string>>
}) {
    const roadmapsQuery = useRoadmaps()
    const { data: roadmaps, isPending } = roadmapsQuery
    const pinnedRoadmaps = (roadmaps || []).filter((roadmap: any) => roadmap.pinned)
    return (
        <div className='rounded shadow-lg w-full border border-lightgrey dark:border-darkaccent'>
            <div className='flex justify-between dark:bg-darkaccent rounded-t py-2 px-4'>
                <h2 className='font-bold text-[18px] leading-tight'>Pinned Roadmaps</h2>
                <button
                    className='h-fit bg-blue dark:bg-accent text-white text-[14px] dark:text-darkaccent font-bold py-1 px-2 leading-tight rounded'
                    onClick={() => setQuickModal('roadmap')}
                >
                    + New Roadmap
                </button>
            </div>
            <div className='w-full h-[1px] bg-blue dark:bg-slate'></div>
            {isPending ? (
                <p className='p-4'>Loading...</p>
            ) : (
                <>
                    {pinnedRoadmaps.length === 0 ? (
                        <p className='p-4'>There are no pinned roadmaps at this time.</p>
                    ) : (
                        pinnedRoadmaps.map((roadmap: any, index: number) => (
                            <RoadmapRow roadmap={roadmap} last={index === pinnedRoadmaps.length - 1} />
                        ))
                    )}
                </>
            )}
        </div>
    )
}
