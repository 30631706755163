import { addBusinessDays, getDay } from 'date-fns'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { dayMapArray } from '../../helpers'

export default function DueDateChanger({
    inNote,
    setTask,
    setDisplayedDate,
    handleCancelDateChange,
    setShowDueAtEditor,
    disabled,
}: {
    inNote: boolean
    setDisplayedDate: Dispatch<SetStateAction<any>>
    handleCancelDateChange: Dispatch<SetStateAction<any>>
    setTask: Dispatch<SetStateAction<any>>
    setShowDueAtEditor: Dispatch<SetStateAction<any>>
    disabled: boolean
}) {
    const settings = useSelector<any, any>((state) => state.settings)
    const [dueDate, setDueDate] = useState('in_1_day')
    const [slackAlert, setSlackAlert] = useState(1)
    const [customDueDate, setCustomDueDate] = useState(
        [
            new Date().getFullYear(),
            `0${new Date().getMonth() + 1}`.slice(-2),
            `0${new Date().getDate()}`.slice(-2),
        ].join('-')
    )
    const [dueMinutes, setDueMinutes] = useState('08:00')
    const [alertOption, setAlertOption] = useState('at_time')

    const getAlertTime = (dateObject: Date, alertTime: string) => {
        const alertMap: { [key: string]: string } = {
            at_time: dateObject.toISOString(),
            '15_till': new Date(dateObject.getTime() - 15000 * 60).toISOString(),
            '30_till': new Date(dateObject.getTime() - 30000 * 60).toISOString(),
            '60_till': new Date(dateObject.getTime() - 60000 * 60).toISOString(),
            '24hrs_till': new Date(dateObject.getTime() - 1000 * 60 * 60 * 24).toISOString(),
        }
        return alertMap[alertTime]
    }

    const dueMap: { [key: string]: Date } = {
        today: new Date(),
        in_1_day: addBusinessDays(new Date(), 1),
        in_2_days: addBusinessDays(new Date(), 2),
        in_3_days: addBusinessDays(new Date(), 3),
        in_1_week: addBusinessDays(new Date(), 5),
        in_2_weeks: addBusinessDays(new Date(), 10),
        in_1_month: addBusinessDays(new Date(), 22),
        in_3_months: addBusinessDays(new Date(), 66),
        in_6_months: addBusinessDays(new Date(), 132),
    }

    const getISOString = (dateObject: Date, time: string) => {
        const year = dateObject.getFullYear()
        const month = dateObject.getMonth()
        const date = dateObject.getDate()
        const timeSplit = time ? time.split(':') : ['00', '00']
        let result

        const [hours, minutes] = timeSplit
        result = new Date(year, month, date, parseInt(hours), parseInt(minutes)).toISOString()

        return result
    }
    if (dueDate === 'custom') {
        const newDueAtString =
            getISOString(new Date(customDueDate.replaceAll('-', '/')), dueMinutes) || new Date().toISOString()
        setDisplayedDate(newDueAtString)
    } else {
        const newDueAtString = getISOString(dueMap[dueDate], dueMinutes) || new Date().toISOString()
        setDisplayedDate(newDueAtString)
    }
    useEffect(() => {
        if (inNote) {
            if (dueDate === 'custom') {
                const newDueAtString =
                    getISOString(new Date(customDueDate.replaceAll('-', '/')), dueMinutes) || new Date().toISOString()
                setTask((previousState: any) => ({
                    ...previousState,
                    send_slack_alert: slackAlert,
                    due_at: newDueAtString,
                    alert_at: getAlertTime(new Date(newDueAtString), alertOption),
                }))
            } else {
                const newDueAtString = getISOString(dueMap[dueDate], dueMinutes) || new Date().toISOString()
                setTask((previousState: any) => ({
                    ...previousState,
                    send_slack_alert: slackAlert,
                    due_at: newDueAtString,
                    alert_at: getAlertTime(new Date(newDueAtString), alertOption),
                }))
            }
        }
    }, [dueDate, dueMinutes, customDueDate])

    function handleUpdate() {
        if (dueDate === 'custom') {
            const newDueAtString =
                getISOString(new Date(customDueDate.replaceAll('-', '/')), dueMinutes) || new Date().toISOString()
            setTask((previousState: any) => ({
                ...previousState,
                send_slack_alert: slackAlert,
                due_at: newDueAtString,
                alert_at: getAlertTime(new Date(newDueAtString), alertOption),
            }))
        } else {
            const newDueAtString = getISOString(dueMap[dueDate], dueMinutes) || new Date().toISOString()
            setTask((previousState: any) => ({
                ...previousState,
                send_slack_alert: slackAlert,
                due_at: newDueAtString,
                alert_at: getAlertTime(new Date(newDueAtString), alertOption),
            }))
        }
        setShowDueAtEditor(false)
    }

    return (
        <>
            <div className='flex gap-[8px]'>
                <div className='flex flex-col gap-[4px] w-full'>
                    <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                        Due Date <br />
                        (business days)
                    </label>
                    <select
                        className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                        value={dueDate}
                        onChange={({ target }) => setDueDate(target.value)}
                        disabled={disabled}
                    >
                        <option className='dark:bg-darkness/90' value='today'>
                            Today {`(${dayMapArray[getDay(new Date())]})`}
                        </option>
                        <option className='dark:bg-darkness/90' value='in_1_day'>
                            In 1 day {`(${dayMapArray[getDay(addBusinessDays(new Date(), 1))]})`}
                        </option>
                        <option className='dark:bg-darkness/90' value='in_2_days'>
                            In 2 days {`(${dayMapArray[getDay(addBusinessDays(new Date(), 2))]})`}
                        </option>
                        <option className='dark:bg-darkness/90' value='in_3_days'>
                            In 3 days {`(${dayMapArray[getDay(addBusinessDays(new Date(), 3))]})`}
                        </option>
                        <option className='dark:bg-darkness/90' value='in_1_week'>
                            In 1 week {`(${dayMapArray[getDay(addBusinessDays(new Date(), 5))]})`}
                        </option>
                        <option className='dark:bg-darkness/90' value='in_2_weeks'>
                            In 2 weeks {`(${dayMapArray[getDay(addBusinessDays(new Date(), 10))]})`}
                        </option>
                        <option className='dark:bg-darkness/90' value='in_1_month'>
                            In 1 month
                        </option>
                        <option className='dark:bg-darkness/90' value='in_3_months'>
                            In 3 months
                        </option>
                        <option className='dark:bg-darkness/90' value='in_6_months'>
                            In 6 months
                        </option>
                        <option className='dark:bg-darkness/90' value='custom'>
                            Custom
                        </option>
                    </select>
                    {dueDate === 'custom' && (
                        <>
                            {/* <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                Custom Due Date
                            </label> */}
                            <input
                                className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                                type='date'
                                value={customDueDate}
                                onChange={({ target }) => setCustomDueDate(target.value)}
                            />
                        </>
                    )}
                </div>
                <div className='flex flex-col gap-[4px] w-full justify-between'>
                    <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                        Time
                    </label>
                    <input
                        className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] py-[2px] rounded-[4px]'
                        style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                        type='time'
                        value={dueMinutes}
                        onChange={({ target }) => setDueMinutes(target.value)}
                    />
                </div>
            </div>
            <div className='flex items-center gap-[16px]'>
                <div className='flex flex-col gap-[4px] w-full'>
                    <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                        Set Reminder
                    </label>
                    <select
                        className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                        value={alertOption}
                        onChange={({ target }) => setAlertOption(target.value)}
                        disabled={disabled}
                    >
                        <option className='dark:bg-darkness/90' value=''>
                            Do not remind
                        </option>
                        <option className='dark:bg-darkness/90' value='at_time'>
                            When due
                        </option>
                        <option className='dark:bg-darkness/90' value='15_till'>
                            15 minutes before
                        </option>
                        <option className='dark:bg-darkness/90' value='30_till'>
                            30 minutes before
                        </option>
                        <option className='dark:bg-darkness/90' value='60_till'>
                            1 hour before
                        </option>
                        <option className='dark:bg-darkness/90' value='24hrs_till'>
                            1 day before
                        </option>
                    </select>
                </div>
                <div className='flex items-center gap-[4px]'>
                    <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                        Slack
                    </label>
                    <input
                        name='send_slack_alert'
                        id='send_slack_alert'
                        type='checkbox'
                        checked={slackAlert ? true : false}
                        onChange={({ target }) => setSlackAlert(target.checked ? 1 : 0)}
                        disabled={disabled}
                    />
                </div>
            </div>
            {!inNote && (
                <div className='flex gap-[8px] items-center mt-[8px] justify-center'>
                    <button
                        className='bg-lightgrey dark:bg-darkgrey py-[4px] px-[8px] rounded-[4px] font-bold text-[14px]'
                        onClick={handleCancelDateChange}
                    >
                        Cancel
                    </button>
                    <button
                        className='bg-blue text-white dark:bg-accent dark:text-darkaccent py-[4px] px-[8px] rounded-[4px] font-bold text-[14px]'
                        onClick={handleUpdate}
                    >
                        Set New Date
                    </button>
                </div>
            )}
        </>
    )
}
