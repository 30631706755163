import { useMutation, useQueryClient } from '@tanstack/react-query'
import { cn, vFetch } from 'helpers'
import Input from 'procurement/components/Input'
import { ChangeEvent, useState } from 'react'
import { FaHistory } from 'react-icons/fa'
import { useSearchParams } from 'react-router-dom'

export default function ProductRow({ product, editingProduct, setEditingProduct }: any) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [updates, setUpdates] = useState<any>({})
    const updated = Object.keys(updates).length > 0
    const variants = product.variants.map((v: any) => {
        if (updates[v.id]) {
            return {
                ...v,
                ...updates[v.id],
            }
        }
        return v
    })

    const handleFocus = (event: any) => event.target.select()

    const queryClient = useQueryClient()
    const createAdjustmentMutation = useMutation({
        mutationFn: ({ updates }: { updates: any }) =>
            vFetch('/v2/costAndPrice', {
                method: 'POST',
                body: JSON.stringify({
                    adjustments: Object.entries(updates).map(([variant_id, update]: any) => ({
                        variant_id,
                        cost: update.cost === '' ? null : update.cost,
                        price: update.price === '' ? 0 : update.price,
                        compare_at_price: update.compare_at_price === '' ? null : update.compare_at_price,
                    })),
                }),
            }),
        onSuccess: () => {
            setUpdates({})
            setEditingProduct(undefined)
            return queryClient.invalidateQueries({ queryKey: ['costAndPrice'] })
        },
    })
    const handleSave = () => {
        createAdjustmentMutation.mutate({ updates })
    }
    const handleChange = (variantId: number, event: ChangeEvent<HTMLInputElement>) => {
        setUpdates((prev: any) => {
            const newState = structuredClone(prev)
            if (!newState[variantId]) {
                newState[variantId] = {}
            }
            newState[variantId][event.target.name] = event.target.value
            return newState
        })
        setEditingProduct(product.id)
    }
    const handleCancelEdit = () => {
        setUpdates({})
        setEditingProduct(undefined)
    }

    return (
        <div
            className={`${
                editingProduct && editingProduct !== product.id ? 'pointer-events-none opacity-50' : ''
            } p-2 ${
                editingProduct === product.id
                    ? 'rounded shadow-[inset_0_0_5px_orange]'
                    : 'border-b border-lightgrey  dark:border-darkgrey'
            }`}
        >
            <div className='flex justify-between'>
                <div className='flex gap-2 items-center'>
                    <p className='font-semibold'>{product.title}</p>
                    <button
                        title='Adjustment History'
                        onClick={() => {
                            searchParams.append('product_id', product.id)
                            setSearchParams(searchParams)
                        }}
                    >
                        <FaHistory />
                    </button>
                </div>
                {updated && (
                    <div className='flex gap-2'>
                        <button
                            onClick={handleCancelEdit}
                            className={cn(
                                createAdjustmentMutation.isPending && 'opacity-[0.6] pointer-events-none',
                                'text-xs font-bold py-1 px-2 rounded bg-lightgrey dark:bg-darkgrey leading-none'
                            )}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleSave}
                            className={cn(
                                createAdjustmentMutation.isPending && 'opacity-[0.6] pointer-events-none',
                                'text-xs font-bold py-1 px-2 rounded bg-accent1 dark:bg-darkaccent1 dark:text-darkbg2 leading-none text-white dark:text-offwhite min-w-[100px]'
                            )}
                        >
                            {createAdjustmentMutation.isPending ? 'Saving...' : 'Save Changes'}
                        </button>
                    </div>
                )}
            </div>
            {variants.map((v: any, index: number) => {
                const margin = ((((v.price || 0) - v.cost) / (v.price || 1)) * 100).toFixed(2)
                const symbol = margin[0] === '-' ? '' : '+'
                return (
                    <div
                        className={`grid grid-cols-6 p-2 items-center gap-2 ${
                            index !== variants.length - 1 ? 'border-b border-lightgrey dark:border-darkgrey' : ''
                        }`}
                    >
                        <p className=''>{v.sku}</p>
                        <p className=''>{v.title}</p>
                        <div className={cn('')}>
                            <Input
                                className=' rounded bg-transparent leading-none'
                                leadingClassName='rounded bg-transparent border border-lightgrey dark:border-darkgrey leading-none'
                                value={
                                    createAdjustmentMutation.isPending
                                        ? createAdjustmentMutation.variables.updates[product.id]?.price
                                        : v.price || ''
                                }
                                id='price'
                                name='price'
                                onFocus={handleFocus}
                                onChange={(e: any) => handleChange(v.id, e)}
                                type='number'
                                leading={'$'}
                            />
                        </div>
                        <div className={cn('')}>
                            <Input
                                className=' rounded bg-transparent leading-none'
                                leadingClassName='rounded bg-transparent border border-lightgrey dark:border-darkgrey leading-none'
                                value={
                                    createAdjustmentMutation.isPending
                                        ? createAdjustmentMutation.variables.updates[product.id]?.compare_at_price
                                        : v.compare_at_price || ''
                                }
                                id='compare_at_price'
                                name='compare_at_price'
                                onFocus={handleFocus}
                                onChange={(e: any) => handleChange(v.id, e)}
                                type='number'
                                leading={'$'}
                            />
                        </div>
                        <div className={cn('')}>
                            <Input
                                value={
                                    createAdjustmentMutation.isPending
                                        ? createAdjustmentMutation.variables.updates[product.id]?.cost
                                        : v.cost || ''
                                }
                                id='cost'
                                name='cost'
                                onFocus={handleFocus}
                                onChange={(e: any) => handleChange(v.id, e)}
                                type='number'
                                leading={'$'}
                                className=' rounded bg-transparent leading-none'
                                leadingClassName='rounded bg-transparent border border-lightgrey dark:border-darkgrey leading-none'
                            />
                        </div>

                        <span className=''>
                            {symbol}
                            {margin} %
                        </span>
                    </div>
                )
            })}
        </div>
    )
}
