import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'

export const timesheets = createQueryKeys('timesheets', {
    list: (filters: any) => ({
        queryKey: [filters],
        queryFn: () => service.getTimesheets(filters),
    }),
})

export const timesheetEntries = createQueryKeys('timesheetEntries', {
    list: (filters: any) => ({
        queryKey: [filters],
        queryFn: () => service.getTimesheetEntries(filters),
    }),
})

export default mergeQueryKeys(timesheets, timesheetEntries)
