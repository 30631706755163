import { FaRegCircle, FaTrash, FaUserCircle } from 'react-icons/fa'
import { useAllUsersQuery } from '../../users/api/useQueries'
import { UserInit } from '../../users/users.types'
import { BiSolidPyramid } from 'react-icons/bi'
import { priorityMap, taskStatusDisplayMap } from '../constants'
import FetchOptionSelector from './FetchOptionSelector'
import { useEffect, useRef, useState } from 'react'
import { useDeleteTask, useProjects, useUpdateTask } from '../api/useQueries'
import { monthDateShortFormatter } from '../helpers'
import { Link, useLocation } from 'react-router-dom'

export default function TaskRow({ task, last }: { task: any; last?: boolean }) {
    const location = useLocation()

    /* REFS */
    const statusRef = useRef<HTMLDivElement>(null)
    const priorityRef = useRef<HTMLDivElement>(null)
    const estimateRef = useRef<HTMLDivElement>(null)
    const assignedToRef = useRef<HTMLDivElement>(null)
    const projectRef = useRef<HTMLDivElement>(null)

    /* STATE | QUERIES */
    const [popover, setPopover] = useState<undefined | string>()
    const [dismountingPopover, setDismountingPopover] = useState(false)
    const usersQuery = useAllUsersQuery()
    const projectsQuery = useProjects()
    const updateTaskMutation = useUpdateTask()
    const deleteTaskMutation = useDeleteTask()

    /* DERRIVED VALUES */
    const users: UserInit[] = usersQuery?.data?.users || []
    const projects = projectsQuery?.data || []
    const foundUser = users.find((user) => user.id === task.assigned_to)
    const foundProject = projects.find((project: any) => project.id === task.project_id)
    const createdAt = monthDateShortFormatter.format(new Date(task.created_at))
    const updatedAt = monthDateShortFormatter.format(new Date(task.updated_at))
    const { icon: Priority, className: priorityClassName } = priorityMap[task.priority as keyof typeof priorityMap]
    const { icon: Status, className: statusClassName } = taskStatusDisplayMap[task.status] || {
        icon: FaRegCircle,
        class: '',
    }

    /* FUNCTIONS */
    const handleUpdateTask = (taskUpdate: any) => {
        if (JSON.stringify(task) === JSON.stringify({ ...task, ...taskUpdate })) {
            return
        }
        updateTaskMutation.mutate(taskUpdate, {
            onSuccess: () => {
                setPopover(undefined)
            },
        })
    }
    const handleDeleteTask = () => {
        deleteTaskMutation.mutate(task.id)
    }

    const handleTogglePopover = (target: string) => {
        if (target !== popover) {
            return setPopover(target)
        }
        setDismountingPopover(true)
        setTimeout(() => {
            setPopover(undefined)
            setDismountingPopover(false)
        }, 200)
    }

    /* USEEFFECTS */
    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (
                !statusRef.current?.contains(e.target as HTMLElement) &&
                !priorityRef.current?.contains(e.target as HTMLElement) &&
                !estimateRef.current?.contains(e.target as HTMLElement) &&
                !assignedToRef.current?.contains(e.target as HTMLElement) &&
                !projectRef.current?.contains(e.target as HTMLElement)
            ) {
                setDismountingPopover(true)
                setTimeout(() => {
                    setPopover(undefined)
                    setDismountingPopover(false)
                }, 200)
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <div
            className={`select-none ${
                deleteTaskMutation.isPending ? 'opacity-50 pointer-events-none' : ''
            }hover:bg-lightgrey/50 dark:hover:bg-darkaccent/50 flex gap-4 items-center text-[14px] py-2 px-4 ${
                !last ? 'border-b border-lightgrey dark:border-darkaccent' : ''
            }`}
        >
            <p className='shrink-0 text-darkgrey w-4 text-[12px] font-medium font-robotomono'>{task.id}</p>
            <div ref={statusRef} className='relative cursor-pointer' title='Change the task status'>
                <Status
                    onClick={() => handleTogglePopover('status')}
                    className={`${statusClassName} shrink-0 hover:scale-110 transition-all`}
                />
                {popover === 'status' && (
                    <FetchOptionSelector
                        options={Object.keys(taskStatusDisplayMap)}
                        selectedOption={task.status}
                        onClick={(option: string) => handleUpdateTask({ id: task.id, status: option })}
                        isPending={updateTaskMutation.isPending}
                        className='left-0 translate-x-0'
                        searchText='Update status...'
                        dismountingPopover={dismountingPopover}
                    />
                )}
            </div>
            <Link
                to={`/development/tasks/${task.id}?return_url=${encodeURIComponent(location.pathname)}`}
                className='font-semibold w-full'
            >
                {task.title}
            </Link>
            <div ref={projectRef} className='relative shrink-0 cursor-pointer' title='Add task to a project'>
                <div
                    onClick={() => handleTogglePopover('project')}
                    className='font-bold text-[12px] py-1 px-2 leading-tight rounded-[99vw] border border-darkgrey hover:bg-lightgrey/50 dark:hover:bg-darkaccent/50 transition-all'
                >
                    {foundProject ? foundProject.title : 'No Project'}
                </div>
                {popover === 'project' && (
                    <FetchOptionSelector
                        options={[
                            { label: 'No Project', value: null },
                            ...projects.map((project: any) => ({ label: project.title, value: project.id.toString() })),
                        ]}
                        selectedOption={task.project_id?.toString() || null}
                        onClick={(option: string) => handleUpdateTask({ id: task.id, project_id: option })}
                        isPending={updateTaskMutation.isPending}
                        searchText='Add to project...'
                        dismountingPopover={dismountingPopover}
                    />
                )}
            </div>
            <div ref={priorityRef} className='relative cursor-pointer' title='Adjust the priority'>
                <Priority
                    onClick={() => handleTogglePopover('priority')}
                    className={`text-[20px] ${priorityClassName} dark:opacity-75 dark:hover:opacity-100 transition-all`}
                />
                {popover === 'priority' && (
                    <FetchOptionSelector
                        options={['low', 'medium', 'high', 'urgent']}
                        selectedOption={task.priority}
                        onClick={(option: string) => handleUpdateTask({ id: task.id, priority: option })}
                        isPending={updateTaskMutation.isPending}
                        searchText='Set priority...'
                        dismountingPopover={dismountingPopover}
                    />
                )}
            </div>
            <div
                ref={estimateRef}
                className='relative group w-6 shrink-0 text-[12px] font-bold font-robotomono cursor-pointer'
                title='Adjust the time estimate'
            >
                <button className='flex gap-1 items-center' onClick={() => handleTogglePopover('estimate')}>
                    <BiSolidPyramid className='w-1/2 dark:opacity-75 dark:group-hover:opacity-100 transition-all' />
                    <p className='w-1/2 text-center opacity-75'>{task.estimate}</p>
                </button>
                {popover === 'estimate' && (
                    <FetchOptionSelector
                        options={['0', '1', '2', '3', '5', '8']}
                        selectedOption={task.estimate.toString()}
                        onClick={(option: string) => handleUpdateTask({ id: task.id, estimate: option })}
                        isPending={updateTaskMutation.isPending}
                        searchText='Change estimate...'
                        dismountingPopover={dismountingPopover}
                    />
                )}
            </div>
            <p className='shrink-0 text-[12px] select-none' title={`Created ${createdAt}`}>
                {createdAt}
            </p>
            <p className='shrink-0 text-[12px] select-none' title={`Last updated ${updatedAt}`}>
                {updatedAt}
            </p>
            <div ref={assignedToRef} className='relative w-4 shrink-0 cursor-pointer' title='Assign to a developer'>
                <button className='flex items-center justify-center' onClick={() => handleTogglePopover('assigned_to')}>
                    {foundUser ? (
                        <div className='w-4 h-4 text-[9px] flex justify-center items-center bg-blue text-white dark:bg-accent rounded-full dark:text-darkaccent font-bold'>
                            {foundUser.first_name[0]}
                            {foundUser.last_name[0]}
                        </div>
                    ) : (
                        <FaUserCircle className='w-4 h-4 opacity-50' />
                    )}
                </button>
                {popover === 'assigned_to' && (
                    <FetchOptionSelector
                        options={[
                            { label: 'Unassigned', value: null },
                            ...users
                                .filter((user) => user.roles.includes('developer'))
                                .map((user) => ({
                                    label: `${user.first_name} ${user.last_name}`,
                                    value: user.id.toString(),
                                })),
                        ]}
                        selectedOption={task.assigned_to?.toString() || null}
                        onClick={(option: string) => handleUpdateTask({ id: task.id, assigned_to: option })}
                        isPending={updateTaskMutation.isPending}
                        className='left-[unset] right-0 translate-x-0'
                        searchText='Assign to...'
                        dismountingPopover={dismountingPopover}
                    />
                )}
            </div>
            <button
                className={`opacity-25 hover:opacity-100 transition-all hover:text-red hover:dark:text-lightred`}
                onClick={handleDeleteTask}
            >
                <FaTrash />
            </button>
        </div>
    )
}
