import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import { cn, sendToast, sendToastWarning } from 'helpers'
import { useCompanyNamesQuery, useShopifyVendorsQuery } from 'procurement/api/useQueries'
import Select from 'procurement/components/Select'
import { productSetupQueries } from 'productSetupV2/api/productSetupQueryKeys'
import { useManualInventorySheetUpload } from 'productSetupV2/api/useQueries'
import { ChangeEvent, useState } from 'react'
import { useParams } from 'react-router-dom'

export default function InternalEmailMapSetupV2({ companyInfo, file }: { companyInfo: any; file: any }) {
    const queryClient = useQueryClient()
    const { emailId } = useParams()

    const rows = file.content
    const headingRow: any = []
    const lengths = rows.map((row: any) => row.length)
    const longestRowIndex = lengths.indexOf(Math.max(...lengths))

    const headings = file?.heading_string?.split(',')

    const [headingsMap, setHeadingsMap] = useState<{}>({})
    const selectedFields = new Set(Object.values(headingsMap))
    const [headerRowIndex, setHeaderRowIndex] = useState(0)

    const [selectedVendor, setSelectedVendor] = useState<string | undefined>()
    const [selectedVendors, setSelectedVendors] = useState<{ vendors: string[] }>({
        vendors: [],
    })

    const manualInventorySheetUpload = useManualInventorySheetUpload()

    const inventorySheetFieldOptions = [
        {
            value: 'description',
            label: 'Description',
        },
        {
            value: 'supplier_sku',
            label: 'supplier sku',
        },
        {
            value: 'upc',
            label: 'UPC',
        },
        {
            value: 'model_number',
            label: 'model number',
        },
        {
            value: 'new_cost',
            label: 'cost',
        },
        {
            value: 'quantity1',
            label: 'Quantity 1',
        },
        {
            value: 'quantity2',
            label: 'Quantity 2',
        },
        {
            value: 'quantity3',
            label: 'Quantity 3',
        },
        {
            value: 'quantity4',
            label: 'Quantity 4',
        },
        {
            value: 'quantity5',
            label: 'Quantity 5',
        },
        {
            value: 'restock_date_day_month_year',
            label: 'Inc. Available At Date (DD-MM-YY)',
        },
        {
            value: 'restock_date_month_day_year',
            label: 'Inc. Available At Date (MM-DD-YY)',
        },
        {
            value: 'restock_amount',
            label: 'Incoming Amount',
        },
    ]

    const shopifyVendorsQuery = useShopifyVendorsQuery()
    const { vendors: shopifyVendorNames }: { vendors: string[] } = shopifyVendorsQuery?.data || {}
    const companyNamesQuery = useCompanyNamesQuery()
    const {
        companies: companyNames,
    }: { companies: { name: string; id: string | number; shopify_vendor_name: string }[] } =
        companyNamesQuery?.data || {}

    const handleRemoveVendor = (vendorName: string) => {
        const vendorNameSet = new Set(selectedVendors.vendors)
        vendorNameSet.delete(vendorName)
        setSelectedVendors({
            vendors: Array.from(vendorNameSet),
        })
    }

    const handleSelectVendor = (vendorName: string) => {
        const foundCompany = companyNames.find((company) => company.shopify_vendor_name === vendorName)
        if (!foundCompany) {
            sendToastWarning({
                message: `${vendorName} has no linked company. Please Link shopify vendor to company on dashboard`,
            })
            return
        }
        setSelectedVendor(vendorName)
    }
    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setHeadingsMap({
            ...headingsMap,
            [target.name]: target.value,
        })
    }

    const unlinkedVendors = shopifyVendorNames?.filter((vendorName) => {
        const companyShopifyVendorNames = companyNames?.map((companyName) => companyName.shopify_vendor_name)
        if (!companyShopifyVendorNames?.includes(vendorName)) {
            return vendorName
        }
    })
    const selectorButtonObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: shopifyVendorNames?.map((vendorName: string) => {
                    const isUnlinked = unlinkedVendors?.includes(vendorName)
                    return {
                        value: vendorName,
                        label: (
                            <div
                                onClick={() => {
                                    if (isUnlinked) {
                                        sendToastWarning({
                                            message: `${vendorName} has no linked company. Please Link shopify vendor to company on dashboard`,
                                        })
                                    }
                                }}
                                className='flex items-center gap-2'
                            >
                                <p>{vendorName}</p>
                                {isUnlinked && <ExclamationTriangleIcon className='text-danger' />}
                            </div>
                        ),
                        icon: undefined,
                    }
                }),
                title: 'Vendors',
                field: 'vendors',
                values: selectedVendors.vendors,
                searchToggle: true,
                editSearchParams: true,
            },
        ],
        setFunction: setSelectedVendors,
        resetFunction: () => {
            setSelectedVendors({
                vendors: [],
            })
        },
    }

    const handleManualMap = (src: string) => {
        const vendorNames = selectedVendors.vendors
        if (vendorNames.length < 1) {
            return sendToastWarning({ message: 'Upload must include vendor' })
        }
        const selectedFields = Object.values(headingsMap)
        if (
            selectedFields.includes('restock_date_month_day_year') &&
            selectedFields.includes('restock_date_day_month_year')
        ) {
            return sendToastWarning({ message: 'Only one incoming date selection allowed' })
        }
        if (!selectedFields.includes('quantity1')) {
            return sendToastWarning({ message: 'Upload must include selection for quantity' })
        }
        if (
            !selectedFields.includes('supplier_sku') &&
            !selectedFields.includes('upc') &&
            !selectedFields.includes('model_number')
        ) {
            return sendToastWarning({
                message: 'Upload must include selection for at least one upc, model number or supplier sku',
            })
        }
        if (emailId) {
            sendToast({
                message: 'Inventory Update started.',
            })
            manualInventorySheetUpload.mutate(
                {
                    keyMap: headingsMap,
                    headingString: rows[headerRowIndex].join(','),
                    src,
                    vendors: vendorNames,
                    emailId,
                },
                {
                    onSettled: () => {
                        return queryClient.invalidateQueries({
                            queryKey: productSetupQueries.productSetup.inventory._def,
                        })
                    },
                }
            )
        }
    }

    return (
        <>
            <div className='w-2/3 grid grid-cols-[2fr_1fr] relative p-[16px] pt-[20px] rounded-[4px] border border-blue/25 dark:border-darkgrey dark:bg-darkness shadow-md'>
                <p className='absolute top-0 left-0 bg-blue dark:bg-accent rounded-tl-[4px] text-white dark:text-darkness leading-[1] text-[10px] uppercase font-bold p-[2px] px-1'>
                    Select Vendor
                </p>
                <div className='flex flex-col gap-1 rounded-[4px] dark:text-offwhite'>
                    <div className='flex flex-col'>
                        {selectedVendors.vendors.length > 0 &&
                            selectedVendors.vendors.map((vendor) => {
                                return (
                                    <div className='flex justify-between'>
                                        <p>{vendor}</p>
                                        <div className='flex gap-2 items-center'>
                                            <Button
                                                onClick={() => handleRemoveVendor(vendor)}
                                                size={'icon'}
                                                variant={'ghost'}
                                                className='text-danger text-xl'
                                            >
                                                &times;
                                            </Button>
                                        </div>
                                    </div>
                                )
                            })}
                        {selectedVendor && (
                            <div className='flex justify-between'>
                                <p>{selectedVendor}</p>
                                <div className='flex gap-2 items-center'>
                                    <Button
                                        onClick={() => {
                                            setSelectedVendor(undefined)
                                        }}
                                        size={'icon'}
                                        variant={'ghost'}
                                        className='text-danger text-xl'
                                    >
                                        &times;
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='w-full flex justify-center'>
                    {selectorButtonObject.params.length &&
                        selectorButtonObject.params.map((param: any) => {
                            return (
                                <param.component
                                    align={'start'}
                                    key={param.field}
                                    searchToggle={param.searchToggle}
                                    title={param.title}
                                    field={param.field}
                                    options={param.options}
                                    filterValues={param.values}
                                    setFilterValues={selectorButtonObject.setFunction}
                                    selectType={param.selectType}
                                    preview={false}
                                />
                            )
                        })}
                </div>
            </div>
            <div className='w-3/3  relative p-[16px] pt-[20px] rounded-[4px] border border-blue/25 dark:border-darkgrey dark:bg-darkness shadow-md overflow-scroll'>
                <p className='absolute top-0 left-0 bg-blue dark:bg-accent rounded-tl-[4px] text-white dark:text-darkness leading-[1] text-[10px] uppercase font-bold p-[2px] px-1'>
                    Map Headings
                </p>
                <div className='rounded-[4px] dark:text-offwhite min-w-[1258px] '>
                    <div
                        style={{ gridTemplateColumns: `repeat(${rows[longestRowIndex]?.length}, minmax(160px, 1fr))` }}
                        className='grid  w-full gap-2 overflow-scroll '
                    >
                        <div className={`grid grid-cols-subgrid col-span-full pr-2`}>
                            {new Array(rows[longestRowIndex]?.length).fill('')?.map((heading: string, index) => {
                                return (
                                    <Select
                                        label={`Col ${index + 1} ${headingRow.length ? `(${headingRow[index]})` : ''}`}
                                        id={index.toString()}
                                        name={index.toString()}
                                        onChange={handleChange}
                                        value={headingsMap[index.toString().trim() as keyof typeof headingsMap] ?? ''}
                                        className='capitalize dark:bg-darkbg1 min-w-[160px]'
                                    >
                                        <option value={''}></option>
                                        {inventorySheetFieldOptions.map((option: { value: string; label: string }) => {
                                            if (option.value === 'quantity2' && !selectedFields.has('quantity1')) {
                                                return
                                            }
                                            if (option.value === 'quantity3' && !selectedFields.has('quantity2')) {
                                                return
                                            }
                                            if (option.value === 'quantity4' && !selectedFields.has('quantity3')) {
                                                return
                                            }
                                            if (option.value === 'quantity5' && !selectedFields.has('quantity4')) {
                                                return
                                            }
                                            if (
                                                (option.value === 'sku' || option.value === 'supplier_sku') &&
                                                selectedFields.has('sku, supplier_sku')
                                            ) {
                                                return (
                                                    <option disabled={true} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                )
                                            }
                                            if (
                                                option.value === 'sku, supplier_sku' &&
                                                (selectedFields.has('sku') || selectedFields.has('supplier_sku'))
                                            ) {
                                                return (
                                                    <option disabled={true} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                )
                                            }
                                            if (!selectedFields.has(option.value)) {
                                                return <option value={option.value}>{option.label}</option>
                                            } else {
                                                return (
                                                    <option disabled={true} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                )
                                            }
                                        })}
                                    </Select>
                                )
                            })}
                        </div>
                        <div className={`grid grid-cols-subgrid col-span-full gap-4  max-h-[500px] py-2 px-0`}>
                            {rows.map((row: any, index: number) => {
                                const isHeaderRow = index === headerRowIndex
                                return (
                                    <div
                                        onClick={() => setHeaderRowIndex(index)}
                                        className={cn(
                                            `grid grid-cols-subgrid col-span-full gap-4 cursor-pointer w-full justify-between`,
                                            isHeaderRow &&
                                                'bg-lightgrey p-1 rounded-md dark:bg-darkbg1 dark:text-darktext1'
                                        )}
                                    >
                                        {row.map((cell: any) => {
                                            if (cell && cell !== '') {
                                                return (
                                                    <div className=' w-fit'>
                                                        <p className='min-w-[160px]'>{cell}</p>
                                                    </div>
                                                )
                                            }
                                            return (
                                                <div className='pl-1 w-[160px]'>
                                                    <p className='min-w-[160px]'></p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className=''>
                <Button
                    disabled={manualInventorySheetUpload.isPending}
                    variant={'outline'}
                    onClick={() => {
                        const src = file?.src
                        if (src) {
                            handleManualMap(src)
                        } else {
                            sendToastWarning({ message: 'No valid files to submit' })
                        }
                    }}
                >
                    Import Inventory Sheet
                </Button>
            </div>
        </>
    )
}
