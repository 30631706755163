import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'

// Procurement Companies

export const users = createQueryKeys('users', {
    all: () => ({
        queryKey: ['all'],
        queryFn: (context) => service.getAllUsers(),
    }),
})

export const usersQueries = mergeQueryKeys(users)
