import { Dispatch, SetStateAction } from 'react'
import { StationType } from '../EDIScreens.types'

export default function AS2Stations({
    stations,
    setSelectedStation,
    setShowModal,
}: {
    stations: StationType[]
    setSelectedStation: Dispatch<SetStateAction<StationType>>
    setShowModal: Dispatch<SetStateAction<string | undefined>>
}) {
    return (
        <div>
            <div className='sticky top-[50px] grid grid-cols-[1fr_1fr_200px] col-span-full border-b border-lightgrey bg-blue text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Name</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>EDI ID</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Last Updated</div>
            </div>
            {stations.map((station) => {
                return (
                    <div
                        key={station.name}
                        onClick={() => {
                            setSelectedStation(station)
                            setShowModal('station')
                        }}
                        className='grid grid-cols-[1fr_1fr_200px] border-b border-lightgrey cursor-pointer dark:border-darkgrey hover:bg-lightgrey dark:hover:bg-darkness'
                    >
                        <div className='p-[8px] font-bold dark:text-offwhite'>{station.name}</div>
                        <div className='p-[8px] font-bold dark:text-offwhite'>{station.edi_id}</div>
                        <div className='p-[8px] font-bold dark:text-offwhite'>
                            {new Date(station.updated_at!).toDateString()}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
