export const parseIfJson = (object: any) => {
    return Object.fromEntries(Object.entries(object).map(([key, val]) => {
        if (typeof val === "string" && (val[0] === '[' || val[0] === '{')) {
            try {
                return [key, JSON.parse(val)]
            } catch {
                return [key, val]
            }
        }
        return [key, val]
    }))
}
export const amalgamateObject = (object: any, defaultValue: any) => {
    return Object.fromEntries(Object.entries(object).map(([key, val]) => {
        return [key, val || defaultValue[key]]
    }))
}