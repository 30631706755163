import { useEffect, useState } from 'react'
import { BiChevronDown } from 'react-icons/bi'
import ReactOption from './ReactOption'
import { closePopout, sortRelevantSearch } from '../helpers'

type ReactDatalistProps = {
    value: string
    options: string[]
    setSelected: Function
    disabled?: boolean
    inputStyle?: string
    onBlurHandler?: Function
}

export default function ReactDatalist({
    value = '',
    options = [],
    setSelected = () => {},
    disabled = false,
    inputStyle = 'block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent',
    onBlurHandler = () => {},
}: ReactDatalistProps) {
    const [show, setShow] = useState(false)
    const [filteredOptions, setFilteredOptions] = useState(options)
    const [hovered, setHovered] = useState<string>('')

    useEffect(() => {
        const listenToWindow = (e: any) => closePopout(e, ['react-datalist'], setShow)
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    })

    useEffect(() => {
        let newFilteredOptions = options
        const search = value.toLowerCase()
        newFilteredOptions = newFilteredOptions
            .filter((o) => o.toLowerCase().includes(search))
            .sort((a, b) => sortRelevantSearch(value, a, b))
        setFilteredOptions(newFilteredOptions)
        setShow(true)
    }, [value])

    return (
        <div className={`relative z-index-1 react-datalist ${disabled ? 'opacity-75' : ''}`}>
            <input
                className={inputStyle}
                disabled={disabled}
                value={value}
                onClick={() => {
                    setShow(true)
                }}
                onFocus={() => setShow(true)}
                onBlur={(e) => {
                    if (e.relatedTarget) setShow(false)
                    onBlurHandler(e)
                }}
                onChange={(e) => setSelected(e.target.value, e)}
            />
            <BiChevronDown className='absolute fill-black dark:fill-offwhite h-[20px] w-[20px] top-[6px] right-0 pointer-events-none' />
            {show && !disabled && (
                <div
                    className={`${
                        show && filteredOptions.length > 0 && 'border-[1px] border-t-0 border-darkgrey dark:border-0'
                    } absolute top-[100%] flex flex-col w-full bg-white dark:bg-darkness overflow-x-hidden max-h-[500px] rounded-b shadow-small`}
                >
                    {filteredOptions.map((o, i) => (
                        <ReactOption
                            option={o}
                            setSelected={(clicked) => {
                                setShow(false)
                                setSelected(clicked)
                            }}
                            hovered={hovered}
                            setHovered={setHovered}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}
