import { ComponentProps } from 'react'

export default function InputToggleSwitch(props: ComponentProps<'input'>) {
    // bg-accent1/50 bg-darkaccent1/50
    return (
        <div className='relative w-8 h-5 border border-grey dark:border-darkgrey rounded-[99vw] overflow-hidden'>
            <input
                id={props.id}
                className='peer appearance-none absolute top-0 transition-all left-0 w-full h-full checked:bg-accent1 dark:checked:bg-darkaccent1 dark:opacity-75'
                type='checkbox'
                name={props.name}
                value={props.value}
                checked={props.checked}
                onChange={props.onChange}
            />
            <label
                className='bg-gradient-to-t from-grey to-lightgrey dark:from-darkgrey dark:to-grey peer-checked:right-0.5 pointer-events-none transition-all block w-4 h-4 rounded-full absolute top-1/2 translate-y-[-50%] right-3'
                htmlFor={props.id}
            >
                &nbsp;
            </label>
        </div>
    )
}
