import { useEffect, useRef } from 'react'

export default function useModal({ toggleModal }: { toggleModal: () => void }) {
    const modalRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (modalRef.current === e.target) {
                toggleModal()
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    return modalRef
}
