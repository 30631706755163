import { ChangeEvent, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { sendToastWarning } from '../../../helpers'
import { useCreateOptionValue, useDeleteOptionValue, useUpdateOptionValue } from '../../api/useQueries'
import Input from '../Input'

export function VariantOptionValue({
    canDelete,
    isNew,
    optionValue,
    productId,
    optionId,
    setShowNewOptionValue,
}: {
    canDelete: boolean
    isNew: boolean
    optionValue: any
    productId: string | number
    optionId: string | number
    setShowNewOptionValue?: any
}) {
    const [showDelete, setShowDelete] = useState(false)
    const [showEdit, setShowEdit] = useState<boolean>(isNew ? true : false)
    const [editableFields, setEditableFields] = useState<any>(isNew ? { label: undefined } : {})

    const isEdited = Boolean(Object.keys(editableFields).length)

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setEditableFields((previousState: any) => ({
            ...structuredClone(previousState),
            [target.name]: target.type === 'checkbox' ? (target as HTMLInputElement).checked : target.value,
        }))
    }

    const createOptionValue = useCreateOptionValue()
    const updateOptionValue = useUpdateOptionValue()
    const deleteOptionValue = useDeleteOptionValue()

    const handleDeleteOption = () => {
        deleteOptionValue.mutate(optionValue, {
            onSuccess: () => {
                setShowDelete(false)
            },
        })
    }
    function handleUpdate() {
        if (isNew) {
            if (!editableFields.label) {
                return sendToastWarning({ message: 'must include option value name' })
            }
            if (editableFields.label && !editableFields.label.trim()) {
                return sendToastWarning({ message: 'must include option value name' })
            }
            createOptionValue.mutate(
                {
                    optionValue: {
                        product_id: productId,
                        option_id: optionId,
                        ...editableFields,
                        label: editableFields.label.trim(),
                    },
                },
                {
                    onSuccess: () => {
                        setEditableFields({})
                        setShowEdit(false)
                        setShowNewOptionValue(false)
                    },
                }
            )
        }
        if (!isNew) {
            if (!editableFields.label) {
                return sendToastWarning({ message: 'must include option value name' })
            }
            if (editableFields.label && !editableFields.label.trim()) {
                return sendToastWarning({ message: 'must include option value name' })
            }
            updateOptionValue.mutate(
                {
                    optionValues: [
                        {
                            id: optionValue.id,
                            product_id: productId,
                            ...editableFields,
                            label: editableFields.label.trim(),
                        },
                    ],
                    product_id: productId,
                },
                {
                    onSuccess: () => {
                        setShowEdit(false)
                        setEditableFields({})
                    },
                }
            )
        }
    }

    const optionIsPending = createOptionValue.isPending || updateOptionValue.isPending || deleteOptionValue.isPending
    const optionValueLabel = updateOptionValue?.variables?.optionValues[0].label ?? optionValue.label
    function SaveButtons() {
        return (
            <div className='flex gap-3 items-center '>
                <button
                    onClick={handleUpdate}
                    className={`${
                        optionIsPending ? 'opacity-[0.9] pointer-events-none' : 'pointer-events-auto'
                    } shrink-0 text-[14px] leading-[1] py-1 px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase`}
                >
                    {optionIsPending ? 'SAVING...' : 'SAVE'}
                </button>
                <button
                    onClick={() => {
                        setEditableFields({})
                        setShowDelete(false)
                        if (isNew) {
                            setShowNewOptionValue(false)
                        }
                    }}
                    className={`${
                        optionIsPending ? 'opacity-[0.9] pointer-events-none' : 'pointer-events-auto'
                    } self-center shrink-0 text-[14px] leading-[1] py-1 px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkgrey text-black dark:text-offwhite font-bold uppercase`}
                >
                    Cancel
                </button>
            </div>
        )
    }
    return (
        <div className='grid grid-cols-[3fr_2fr] gap-3'>
            <Input
                id={'label'}
                name={'label'}
                type='text'
                placeholder={isNew ? 'New Option Value' : ''}
                onChange={handleChange}
                value={editableFields.label ?? optionValueLabel ?? ''}
                className={` py-[4px] text-[14px] rounded-[4px] font-semibold `}
                autoFocus={isNew ? true : false}
            ></Input>

            {isEdited && <SaveButtons />}
            {showDelete && !isEdited && (
                <div className='flex gap-[8px] self-center items-center'>
                    <button
                        onClick={() => setShowDelete(false)}
                        className={`${
                            deleteOptionValue.isPending ? 'opacity-[0.5] pointer-events-none' : 'pointer-events-auto'
                        } text-[14px] leading-[1] py-[3px] w-fit px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkgrey font-bold uppercase`}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleDeleteOption}
                        className={`${
                            deleteOptionValue.isPending ? 'opacity-[0.5] pointer-events-none' : 'pointer-events-auto'
                        } shrink-0 text-[14px] leading-[1] py-[3px] w-fit px-[8px] rounded-[4px] bg-red dark:bg-lightred text-white font-bold uppercase`}
                    >
                        Delete
                    </button>
                </div>
            )}
            {canDelete && !showDelete && !showEdit && !isEdited && (
                <div className='flex gap-1 mr-[2px]'>
                    {!showDelete && (
                        <button onClick={() => setShowDelete(true)}>
                            <FaTimes />
                        </button>
                    )}
                </div>
            )}
        </div>
    )
}
