import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'

// Procurement Companies Private Events

export const procurementPrivate = createQueryKeys('privateEvents', {
    list: ({ privateEventUrl, element, id }) => ({
        queryKey: [privateEventUrl, element, id],
        queryFn: (context) => service.getPrivateEvents({ privateEventUrl, element, id }),
    }),
})

export const procurementPrivateEventQueries = mergeQueryKeys(procurementPrivate)
