import { InfoCircledIcon } from '@radix-ui/react-icons'

export const BLOCK_INFO_OPTIONS = [
    'Order Submission URL',
    'Order Submission Info',
    'Payment Info',
    'Return Instructions',
    'Technical Issues / Customer Service Questions',
    'Buyers Remorse',
    'Damaged On Arrival',
    'Warranty Repair',
    'Additional Info',
    'Custom Header',
]

export const VENDOR_TYPE_OPTIONS = [
    {
        tag: 'listed',
        value: 'listed',
        label: 'Listed',
        icon: InfoCircledIcon,
    },
    {
        tag: 'freight',
        value: 'freight',
        label: 'Freight Company',
        icon: InfoCircledIcon,
    },
]
