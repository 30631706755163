import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useDebounce, vFetch } from '../../helpers'
import { FaPlus } from 'react-icons/fa'
import { Button } from '../../custom_components/component_Basics/Button'
import MetafieldPreview from './MetafieldPreview'

export default function ProductMetafieldImporter({
    namespace,
    metafieldKey,
    handleSetField,
    hidePreview,
}: {
    namespace: string
    metafieldKey: string
    handleSetField: (metafield: string) => void
    hidePreview?: boolean
}) {
    const [activeMetafield, setActiveMetafield] = useState<string | null>(null)
    const [showImporter, setShowImporter] = useState(false)
    const [search, setSearch] = useState('')
    const debouncedSearch = useDebounce(search, 300)
    const productSearchQuery = useQuery({
        queryKey: ['productMetafields', namespace, metafieldKey, debouncedSearch],
        queryFn: () =>
            vFetch(`/v2/products?fields=id,title,${namespace}_${metafieldKey}&search=${debouncedSearch}`, {
                cb: (res: any) => {
                    if (!res.success) {
                        return []
                    }
                    return res.products.filter((p: any) => p[`${namespace}_${metafieldKey}`])
                },
                catchCb: () => [],
            }),
    })

    return (
        <div className='mb-2'>
            {!showImporter ? (
                <Button
                    className='w-fit gap-1 font-bold text-sm'
                    variant={'outline'}
                    onClick={() => setShowImporter(true)}
                >
                    <FaPlus /> Import Values
                </Button>
            ) : (
                <div className='flex gap-2 items-center'>
                    <Button
                        onClick={() => setShowImporter(false)}
                        className='py-1 px-2 border border-lightgrey dark:border-darkgrey rounded flex gap-1 items-center whitespace-nowrap shrink-0 text-red dark:text-lightred'
                        variant={'outline'}
                    >
                        Cancel
                    </Button>
                    <div className='relative w-[300px] border border-lightgrey dark:border-darkgrey rounded'>
                        <input
                            className='bg-transparent focus:outline-none w-full py-1 px-2'
                            value={search}
                            onChange={({ target }) => setSearch(target.value)}
                            placeholder='Search for a product...'
                        />
                        <div
                            onMouseLeave={() => setActiveMetafield(null)}
                            className='absolute top-full left-0 bg-white dark:bg-darkbg1 rounded-b max-h-[200px] overflow-auto'
                        >
                            {productSearchQuery.isLoading ? (
                                <p className='p-2'>Loading...</p>
                            ) : (
                                <>
                                    {productSearchQuery.data.map((product: any) => (
                                        <button
                                            onMouseEnter={() =>
                                                setActiveMetafield(product[`${namespace}_${metafieldKey}`])
                                            }
                                            onClick={() => {
                                                handleSetField(product[`${namespace}_${metafieldKey}`])
                                                setShowImporter(false)
                                                setSearch('')
                                            }}
                                            className='flex gap-2 items-center text-sm font-bold text-left p-2 border-b border-lightgrey dark:border-darkgrey w-full relative hover:bg-lightgrey dark:hover:bg-darkbg2'
                                        >
                                            <FaPlus />
                                            {product.title}
                                        </button>
                                    ))}
                                </>
                            )}
                        </div>
                        {!hidePreview && activeMetafield && <MetafieldPreview metafield={activeMetafield} />}
                    </div>
                </div>
            )}
        </div>
    )
}
