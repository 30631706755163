import { useContext, useState } from 'react'
import { useContactTypesQuery, useCreateContact } from '../../../api/useQueries'
import { CompanyContext, CompanyContextType } from '../../../helpers'
import { cn } from '../../../../helpers'
import Select from '../../Select'

export default function CreateContactV2({ setShowQuickModal }: { setShowQuickModal: any }) {
    const { editableCompany } = useContext<CompanyContextType>(CompanyContext)
    const [contact, setContact] = useState({
        name: '',
        phone: '',
        email: '',
        alternate: '',
        company_id: editableCompany.id,
        contact_type: 'General',
    })

    const contactTypesQuery = useContactTypesQuery()
    const { types: contactTypes } = contactTypesQuery?.data || {}

    const createContact = useCreateContact(setShowQuickModal)
    const { isPending } = createContact

    const handleSubmit = () => {
        createContact.mutate(
            { contact },
            {
                onSettled: () => {
                    return setShowQuickModal(undefined)
                },
            }
        )
    }
    const handleChange = ({ target }: { target: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement }) => {
        setContact((previousState) => ({ ...previousState, [target.name]: target.value }))
    }
    return (
        <>
            <div className={cn(createContact.isPending && 'opacity-50')}>
                <div className='p-[16px] w-full bg-lightgrey dark:bg-darkness'>
                    <h3 className='leading-[1] font-bold'>New Contact</h3>
                </div>
                <div className='p-[16px] flex flex-col gap-[4px]'>
                    <Select
                        label='Contact Type'
                        id='contact_type'
                        name='contact_type'
                        onChange={handleChange}
                        value={contact.contact_type}
                    >
                        <option value={'General'}>General</option>
                        {contactTypes?.map((type: string) => {
                            return <option value={type}>{type}</option>
                        })}
                    </Select>
                    <div className='flex flex-col gap-[4px] w-full'>
                        <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                            Name
                        </label>
                        <input
                            className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                            id='name'
                            name='name'
                            onChange={handleChange}
                            value={contact.name}
                            disabled={isPending}
                        />
                    </div>
                    <div className='flex gap-[8px] w-full'>
                        <div className='flex flex-col gap-[4px] w-full'>
                            <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                Email
                            </label>
                            <input
                                className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                id='email'
                                name='email'
                                onChange={handleChange}
                                value={contact.email}
                                disabled={isPending}
                            />
                        </div>
                        <div className='flex flex-col gap-[4px] w-full'>
                            <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                Phone
                            </label>
                            <input
                                className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                id='phone'
                                name='phone'
                                onChange={handleChange}
                                value={contact.phone}
                                disabled={isPending}
                            />
                        </div>
                    </div>
                    <div className='flex flex-col gap-[4px] w-full'>
                        <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                            Alternate
                        </label>
                        <textarea
                            className='h-[100px] border-none w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                            id='alternate'
                            name='alternate'
                            onChange={handleChange}
                            value={contact.alternate}
                            disabled={isPending}
                        ></textarea>
                    </div>
                </div>
                <div className='p-[16px] w-full bg-lightgrey dark:bg-darkness flex justify-center'>
                    <button
                        onClick={handleSubmit}
                        className='py-[4px] px-[8px] rounded-[4px] text-[14px] bg-blue dark:bg-accent font-bold uppercase text-white dark:text-darkaccent'
                        disabled={isPending}
                    >
                        {createContact.isPending ? 'Saving Contact...' : 'Save Contact'}
                    </button>
                </div>
            </div>
        </>
    )
}
