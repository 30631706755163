import { ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import { cn } from '../../helpers'

export default function Input({
    id,
    label,
    name,
    type,
    value,
    list,
    onChange,
    datasetName,
    datasetValue,
    required,
    disabled,
    placeholder,
    readOnly,
    className,
    leadingClassName,
    onclick,
    autoFocus,
    onFocus,
    onBlur,
    leading,
    outerClassName,
}: {
    id: string
    label?: string
    list?: string
    name: string
    type: string
    value: string | number
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
    datasetName?: string
    datasetValue?: string
    required?: boolean
    disabled?: boolean
    placeholder?: string
    readOnly?: boolean
    className?: string
    leadingClassName?: string
    onclick?: any
    autoFocus?: boolean
    onFocus?: any
    onBlur?: any
    leading?: string
    outerClassName?: string
}) {
    const settings = useSelector((state: any) => state.settings)
    const dataSetProps: any = {}
    if (datasetName && datasetValue) {
        dataSetProps[datasetName] = datasetValue
    }
    return (
        <div className={cn('flex flex-col gap-[4px] w-full relative', outerClassName)}>
            {label && (
                <label
                    htmlFor={id}
                    className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1] relative w-fit min-h-[12px]'
                >
                    {label}
                    {required && (
                        <span className='absolute top-0 right-[-10px] text-[14px] text-red dark:text-lightred'>*</span>
                    )}
                </label>
            )}
            <div
                className={cn(
                    'flex items-center',
                    leading && 'bg-lightgrey dark:text-white dark:bg-darkness rounded-[4px] px-1',
                    leadingClassName && leadingClassName
                )}
            >
                {leading && <p>{leading}</p>}
                <input
                    style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                    className={cn(
                        `disabled:opacity-75 w-full text-[16px] leading-none min-h-[29.5px] bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]`,
                        className
                    )}
                    list={list}
                    autoFocus={autoFocus}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    id={id}
                    autoComplete='off'
                    name={name}
                    type={type}
                    onChange={onChange}
                    value={value}
                    placeholder={placeholder}
                    required={required ? true : false}
                    disabled={disabled ? true : false}
                    readOnly={readOnly}
                    onClick={onclick}
                    {...dataSetProps}
                />
            </div>
            {list && (
                <div className='absolute bottom-3 right-[5px] w-[6.5px] h-[6.5px] rotate-45 border-b-2 border-r-2 border-black/75' />
            )}
        </div>
    )
}
