import { cn } from '../helpers'

export function NoResults({ margin, content }: { margin: string; content: string }) {
    return (
        <div className='w-full h-[calc(100vh-250px)] mt-16'>
            <div
                className={cn(
                    margin,
                    'text-center p-[16px] rounded-[4px] bg-lightgrey dark:bg-darkaccent shadow-small w-fit border border-darkgrey'
                )}
            >
                <h1 className='dark:text-white font-bold text-2xl uppercase'>{content}</h1>
            </div>
        </div>
    )
}
