import { useEffect, useRef, useState } from 'react'
import { FaObjectGroup } from 'react-icons/fa6'
import { toast } from 'react-toastify'
import { sendToast } from '../helpers'
import { useGetScoreSetupGroups, useGetScoreSetupOptions, useUpdatePositionMap } from './api/useQueries'
import ScoreGroup from './components/ScoreGroup'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

// position map needs to be added manually to db for each store. Will error until row exists. Can be created as empty array.

export default function ProcurementScores() {
    const navigate = useNavigate()
    const user = useSelector<any, any>((state) => state.user)
    const [showNew, setShowNew] = useState(false)
    const [editablePositionMap, setEditablePositionMap] = useState(undefined)
    const toastId = useRef<any>(null)

    const getScoreSetupGroupsQuery = useGetScoreSetupGroups()
    const getScoreSetupOptionsQuery = useGetScoreSetupOptions()
    const updatePositionMap = useUpdatePositionMap()

    const { groups } = getScoreSetupGroupsQuery?.data || {}
    const { options } = getScoreSetupOptionsQuery?.data || {}

    let positionMap = undefined
    if (groups) {
        positionMap = groups[0]?.position_map
    }
    const currentPositionMap = editablePositionMap ?? positionMap
    const isEdited = Boolean(editablePositionMap)

    const placeholderGroup = {
        name: '',
    }
    useEffect(() => {
        if (!(user.type === 'Admin' || user.type === 'SuperAdmin')) {
            navigate(`/`)
        }
    }, [])

    function handleAddNewGroup() {
        if (!showNew) {
            setShowNew(true)
        } else {
            toast.dismiss(toastId.current)
            return (toastId.current = sendToast({ message: 'Complete Pending Group Creation' }))
        }
    }
    function handleSave(): void {
        updatePositionMap.mutate(
            { positionMap: editablePositionMap },
            {
                onSuccess: () => {
                    setEditablePositionMap(undefined)
                },
            }
        )
    }

    return (
        <>
            <div className='flex justify-between mb-[8px] relative'>
                <div className='flex w-full gap-4 justify-between'>
                    <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>
                        Procurement Score Settings
                    </h1>
                    {isEdited && (
                        <div className='flex gap-[8px] justify-end'>
                            <button
                                onClick={handleSave}
                                className={`${
                                    updatePositionMap.isPending
                                        ? 'opacity-[0.5] pointer-events-none'
                                        : 'pointer-events-auto'
                                } self-center shrink-0 text-[14px] leading-[1] py-1 px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase`}
                            >
                                {updatePositionMap.isPending ? 'SAVING...' : 'SAVE POSITIONS'}
                            </button>
                            <button
                                onClick={() => setEditablePositionMap(undefined)}
                                className={`${
                                    updatePositionMap.isPending
                                        ? 'opacity-[0.5] pointer-events-none'
                                        : 'pointer-events-auto'
                                } self-center shrink-0 text-[14px] leading-[1] py-1 px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkgrey text-black dark:text-offwhite font-bold uppercase`}
                            >
                                Cancel
                            </button>
                        </div>
                    )}
                    <button
                        onClick={handleAddNewGroup}
                        className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                    >
                        <FaObjectGroup className='text-darkgrey dark:text-offwhite p-[6px] w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite' />
                        <p className='font-medium leading-[1] uppercase text-[12px]'>Group</p>
                    </button>
                </div>
            </div>
            <div>
                {showNew && (
                    <ScoreGroup
                        isNew={true}
                        group={{ ...placeholderGroup, positionMap }}
                        index={0}
                        options={undefined}
                        setShowNewGroup={setShowNew}
                    />
                )}
                {currentPositionMap?.map((id: any, index: number) => {
                    const relatedGroup = groups?.filter((group: any) => group.id === id)
                    const relatedOptions = options?.filter(
                        (option: any) => option.procurement_score_group_id === relatedGroup[0].id
                    )
                    return (
                        <ScoreGroup
                            editablePositionMap={editablePositionMap}
                            setEditablePositionMap={setEditablePositionMap}
                            isNew={false}
                            group={relatedGroup[0]}
                            index={index}
                            options={relatedOptions}
                        />
                    )
                })}
            </div>
        </>
    )
}
