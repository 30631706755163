import { Dispatch, SetStateAction } from 'react'

export default function PriceCutsList({
    priceCuts = [],
    setSelectedPriceCut,
    search,
    filter = '',
    page = 1,
}: {
    priceCuts: any[]
    setSelectedPriceCut: Dispatch<SetStateAction<any>>
    search: string
    filter: string
    page: number
}) {
    const searchlower = search.toLowerCase()
    const filteredPriceCuts = filterPriceCuts(priceCuts, searchlower, page)
    /**
     * Returns a filtered list of priceCuts based on the search, filteres, and page
     */
    function filterPriceCuts(priceCuts: any[], search: string, page: number) {
        const startIndex = (page - 1) * 50
        const endIndex = page * 50
        return priceCuts
            .filter((priceCut) => {
                if (filter.length) {
                    if (filter === 'STARTING' && !priceCut.starts_on) {
                        return false
                    }
                    if (filter === 'ENDING' && !priceCut.ends_on) {
                        return false
                    }
                    if (
                        filter === 'RECENT' &&
                        new Date(priceCut.updated_at).getTime() < Date.now() - 1000 * 60 * 60 * 24 * 7
                    ) {
                        return false
                    }
                }
                return (
                    priceCut.name?.toLowerCase().includes(search) ||
                    priceCut.pdp_line_1?.toLowerCase().includes(search) ||
                    priceCut.pdp_line_2?.toLowerCase().includes(search) ||
                    priceCut.cart_line_1?.toLowerCase().includes(search) ||
                    priceCut.type?.toLowerCase().includes(search)
                )
            })
            .sort((a, b) => (new Date(a.created_at) > new Date(b.created_at) ? -1 : 1))
    }
    const dateFormatter = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: 'America/Chicago',
    })
    return (
        <>
            <div className='sticky top-[50px] grid grid-cols-[1fr_100px_2fr_2fr_150px_150px_100px] col-span-full border-b border-lightgrey bg-blue text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Name</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Amount</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>PDP Processing Time</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Cart Processing Time</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Starts On</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Ends On</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Affects</div>
            </div>
            {filteredPriceCuts.map((priceCut) => {
                const amount = priceCut.amount
                const amountHeading = amount
                    ? `${priceCut.amount_unit === 'FIXED' ? '$' : ''}${priceCut.amount}${
                          priceCut.amount_unit === 'PERCENT' ? '%' : ''
                      }`
                    : 'VARIES'
                return (
                    <div
                        key={priceCut.name}
                        onClick={() => setSelectedPriceCut(priceCut)}
                        className='grid grid-cols-[1fr_100px_2fr_2fr_150px_150px_100px] border-b border-lightgrey cursor-pointer dark:border-darkgrey hover:bg-lightgrey dark:hover:bg-darkness'
                    >
                        <div className='p-[8px] font-bold dark:text-offwhite'>{priceCut.name}</div>
                        <div className='p-[8px] text-fire font-bold'>{amountHeading}</div>
                        <div className='p-[8px]'>
                            <p className='flex font-bold text-darkgrey dark:text-offwhite uppercase gap-[6px]'>
                                {priceCut.pdp_line_1}
                            </p>
                            <p className='flex gap-[6px] dark:text-offwhite'>{priceCut.pdp_line_2}</p>
                        </div>
                        <div className='p-[8px]'>
                            <p className='font-bold text-darkgrey dark:text-offwhite uppercase'>
                                {priceCut.cart_line_1}
                            </p>
                        </div>
                        <div className='p-[8px] dark:text-offwhite'>
                            {priceCut.starts_on ? dateFormatter.format(new Date(priceCut.starts_on)) : ''}
                        </div>
                        <div className='p-[8px] dark:text-offwhite'>
                            {priceCut.ends_on ? dateFormatter.format(new Date(priceCut.ends_on)) : ''}
                        </div>
                        <div className='p-[8px] dark:text-offwhite'>
                            {priceCut.affected_resources?.split(',').length}
                        </div>
                    </div>
                )
            })}
        </>
    )
}
