import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { DateRange } from 'react-day-picker'
import { analyticsQueries } from './analyticsQueryKeys'

export function useCompanyStatusAnalyticsQuery({
    user_ids,
    dateRange,
    dateRangeCompare,
    timePeriod,
    fields,
}: {
    user_ids: string | number[]
    dateRange: DateRange | undefined
    dateRangeCompare: DateRange | undefined
    timePeriod: string
    fields: {
        tag: string
        value: string[]
        title: string
    }
}): any {
    const query = useQuery({
        ...analyticsQueries.analytics.companyStatusAnalytics(user_ids, dateRange, dateRangeCompare, timePeriod, fields),
        staleTime: 50000,
        enabled: Boolean(dateRange && user_ids.length),
        placeholderData: keepPreviousData,
    })

    return query
}
export function useCompanyStatusAnalyticsQueryByUserTotal({
    user_ids,
    dateRange,
    dateRangeCompare,
    fields,
}: {
    user_ids: string | number[]
    dateRange: DateRange | undefined
    dateRangeCompare: DateRange | undefined
    fields: {
        tag: string
        value: string[]
        title: string
    }[]
}): any {
    const query = useQuery({
        ...analyticsQueries.analytics.companyStatusAnalyticsByUserTotal(user_ids, dateRange, dateRangeCompare, fields),
        staleTime: 50000,
        enabled: Boolean(dateRange && user_ids.length && fields),
        placeholderData: keepPreviousData,
    })

    return query
}
export function useLeadTypesQuery({
    user_ids,
    dateRange,
    dateRangeCompare,
    timePeriod,
    leadTypes,
}: {
    user_ids: string | number[]
    dateRange: DateRange | undefined
    dateRangeCompare: DateRange | undefined
    timePeriod: string
    leadTypes: string[]
}): any {
    const query = useQuery({
        ...analyticsQueries.analytics.leadTypes(user_ids, dateRange, dateRangeCompare, timePeriod, leadTypes),
        staleTime: 50000,
        enabled: Boolean(dateRange && user_ids.length),
        placeholderData: keepPreviousData,
    })

    return query
}

export function useCompanyInteractionsQuery({
    user_ids,
    dateRange,
    dateRangeCompare,
    timePeriod,
    fields,
}: {
    user_ids: string | number[]
    dateRange: DateRange | undefined
    dateRangeCompare: DateRange | undefined
    timePeriod: string
    fields: {
        tag: string
        value: string[]
        title: string
    }
}): any {
    const query = useQuery({
        ...analyticsQueries.analytics.companyInteractions(user_ids, dateRange, dateRangeCompare, timePeriod, fields),
        staleTime: 50000,
        enabled: Boolean(dateRange && user_ids.length),
        placeholderData: keepPreviousData,
    })

    return query
}
export function useProductInteractionsQuery({
    user_ids,
    dateRange,
    dateRangeCompare,
    timePeriod,
    fields,
}: {
    user_ids: string | number[]
    dateRange: DateRange | undefined
    dateRangeCompare: DateRange | undefined
    timePeriod: string
    fields: {
        tag: string
        value: string[]
        title: string
    }
}): any {
    const query = useQuery({
        ...analyticsQueries.analytics.productInteractions(user_ids, dateRange, dateRangeCompare, timePeriod, fields),
        staleTime: 50000,
        enabled: Boolean(dateRange && user_ids.length),
        placeholderData: keepPreviousData,
    })

    return query
}
