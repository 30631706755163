import { useEffect, useState } from 'react'
import { closePopout, sendToast, vFetch } from '../../helpers'

function isInParentButNotInnerContainer(e, className, otherClassName) {
    let element = e.target
    let inParent = false
    let inInner = false
    while (element.parentNode) {
        if (typeof className === 'string') {
            if (element.classList.contains(className)) inParent = true
            if (element.classList.contains(otherClassName)) inInner = true
        } else {
            for (let name of className) {
                if (element.classList.contains(name)) inParent = true
                if (element.classList.contains(otherClassName)) inInner = true
            }
        }
        element = element.parentNode
    }
    return inParent && !inInner
}

export default function Searchbar({ search, handleSearch, filters, setSelectedCustomerId }) {
    const [showPresetOptions, setShowPresetOptions] = useState(false)
    const [presetOptions, setPresetOptions] = useState([])
    const [showFilters, setShowFilters] = useState(false)
    const [showFilterOptions, setShowFilterOptions] = useState(null)
    const [filterOptions, setFilterOptions] = useState(['Tagged with', 'Not tagged with'])
    const [tags, setTags] = useState()
    const [preset, setPreset] = useState(null)
    const [newPreset, setNewPreset] = useState(null)
    const [presetEdited, setPresetEdited] = useState(false)
    const [presetName, setPresetName] = useState('')

    function newFilter(f) {
        return { name: f, value: null, edit: true, id: Math.floor(Math.random() * 1000000) }
    }

    const handleSavePreset = () => {
        if (!presetName.trim()) {
            return sendToast({ message: 'Please add a name to this preset before saving.' })
        }

        if (!presetOptions.some((p) => p.name.trim().toLowerCase() === presetName.trim().toLowerCase())) {
            vFetch(`/v1/presets`, {
                method: 'POST',
                body: JSON.stringify({
                    name: presetName.trim(),
                    belongs_to: 'customers',
                    value: filters,
                }),
                cb: (res) => {
                    if (res.success) {
                        setPreset({ name: presetName, belongs_to: 'customers', value: filters })
                        setNewPreset({ name: presetName, belongs_to: 'customers', value: filters })
                        setPresetName('')
                        setPresetOptions([
                            ...presetOptions,
                            {
                                name: presetName.trim(),
                                belongs_to: 'customers',
                                value: filters,
                            },
                        ])
                    }
                },
            })
        } else {
            sendToast({ message: 'That preset name is already in use.' })
        }
    }

    const handleDeletePreset = () => {
        vFetch(`/v1/presets`, {
            method: 'DELETE',
            body: JSON.stringify({ id: preset.id }),
            cb: (res) => {
                if (res.success) {
                    setPresetOptions(presetOptions.filter((p) => JSON.stringify(p) !== JSON.stringify(preset)))
                    setPreset(null)
                }
            },
        })
    }

    useEffect(() => {
        function listenToFilterOptionsList(e) {
            closePopout(e, ['js-presets-filter-options-list'], setShowFilterOptions)
        }
        window.addEventListener('click', listenToFilterOptionsList)

        vFetch(`/v1/presets?belongs_to=customers`, {
            cb: (res) => {
                if (res.success)
                    setPresetOptions(
                        res.presets
                            .map((p) => {
                                return { ...p, value: JSON.parse(p.value) }
                            })
                            .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
                    )
            },
        })

        return () => {
            window.removeEventListener('click', listenToFilterOptionsList)
        }
    }, [])

    return (
        <div className={`bg-white dark:bg-darkaccent shadow-small duration-300 rounded`}>
            <div
                className={`relative flex justify-between gap-[8px] p-[8px] ${
                    showFilters && 'border-b-[1px] border-offwhite dark:border-darkgrey'
                } `}
            >
                <input
                    className='js-searchbar-input px-[4px] dark:text-offwhite rounded bg-[whitesmoke] dark:bg-darkness'
                    type='text'
                    onChange={handleSearch}
                    value={search}
                    placeholder='Customer Search'
                />
                <button
                    className='justify-self-end p-[8px] font-semibold text-white dark:text-black bg-blue dark:bg-accent rounded'
                    onClick={() => {
                        setSelectedCustomerId('New Customer')
                    }}
                >
                    + New Customer
                </button>
            </div>
        </div>
    )
}
