import { TaxLine } from '../orders/orders.types'
import { ReturnEditInit, ReturnCreateInit, DamageClaimCreateInit } from './returns.types'
import { returnVariables } from './returnsVariables'

export function getTaxTotal(taxLinesArray: TaxLine[]) {
    let total = 0
    for (let tax of taxLinesArray) {
        total += Number(tax.price)
    }
    return total
}

export function canSubmitReturn(
    r: ReturnCreateInit | DamageClaimCreateInit | ReturnEditInit,
    skipItemChecklist: boolean = returnVariables.skipItemChecklistReasons.includes(r.reason)
) {
    function areFilesOkay() {
        if (r.reason === 'damaged in transit' && r.status !== 'initialized') {
            if (r.shipping_type === 'UPS') return r.files.length >= 7
            else if (r.shipping_type === 'freight') return r.files.length >= 8
            else return true
        } else return true
    }

    // anything that is marked as "true" is a required field in this state.
    // any functions will run and the returned boolean will tell if the field is acceptable/submittable.
    //  () => true      means the value is always acceptable to submit.
    //  () => r.status === "closed" ? !!r.refund_to_customer : false    means if r.status === "closed", r.refund_to_customer must have a value. Otherwise if r.status !== "closed", the value is of r.refund_to_customer is always acceptable/submittable.
    const returnInitializedTruthyState: any = {
        customer: true,
        created_at: () => true,
        damage_checklist: () => true,
        destination: r.shipping_type === 'N/A' ? () => true : true,
        files: areFilesOkay(),
        fees: () =>
            ['partial'].includes(r.refund_type) ? r.fees.length > 0 && r.fees.every((fee) => !!fee.amount) : true,
        id: () => true,
        item_checklist: () =>
            skipItemChecklist ||
            Object.values((r as ReturnCreateInit).item_checklist).every((v) => v === true) ||
            (r as ReturnCreateInit).item_checklist, //Object.values(r.item_checklist).every(v => v === true),
        manufacturer: true,
        model: true,
        notes: () => true,
        origin: true,
        original_shipping_paid_by: true,
        original_tracking_number: true,
        original_shipping_type: true,
        po_number: true,
        quantity: true,
        reason: true,
        refund_from_manufacturer_actual: () => true,
        refund_from_manufacturer_expected: () => true,
        refund_to_customer: () => (r.status === 'closed' ? !!r.refund_to_customer : true),
        rep: true,
        resolution: true,
        rma_number: () => true,
        shipping_type: true,
        status: true,
        task_id: false,
        tracking_number: () => true,
        type: true,
        refund_type: () => true,
        updated_at: () => true,
    }
    const damageInitializedTruthyState: any = {
        ...returnInitializedTruthyState,
        damage_checklist: () =>
            Object.values((r as DamageClaimCreateInit).damage_checklist).every((v) => !!v) ||
            (r as DamageClaimCreateInit).damage_checklist,
        item_checklist: () => true,
    }
    const returnFactoryPureTruthyState: any = {
        ...returnInitializedTruthyState,
        refund_from_manufacturer_expected: false,
        rma_number: false,
        tracking_number: () => (r.shipping_type !== 'N/A' ? !!r.tracking_number : true),
        refund_type: true,
    }
    const returnManufacturerTruthyState: any = {
        ...returnFactoryPureTruthyState,
        refund_from_manufacturer_actual: () => {
            if (!['ready for processing', 'closed'].includes(r.status)) return true
            else return r.refund_from_manufacturer_actual !== undefined && r.refund_from_manufacturer_actual !== null
        },
        refund_from_manufacturer_expected: true,
        rma_number: () => (['initialized', 'requested RMA'].includes(r.status) ? true : !!r.rma_number),
    }

    function checkState(comparisonState: any) {
        const passedValues: any = {}
        Object.keys(comparisonState).forEach((k: any) => {
            passedValues[k] = comparisonState[k] === !!(r as ReturnEditInit)[k as keyof ReturnEditInit]
            if (typeof comparisonState[k] === 'function')
                passedValues[k] = comparisonState[k]((r as ReturnEditInit)[k as keyof ReturnEditInit])
        })
        return passedValues
    }

    if (r.type === 'return' || r.type === 'cancellation') {
        if (r.status === 'initialized') return checkState(returnInitializedTruthyState)
        if (r.destination === 'Manufacturer') return checkState(returnManufacturerTruthyState)
        if (r.destination === 'FactoryPure') return checkState(returnFactoryPureTruthyState)
        return checkState(returnInitializedTruthyState)
    }
    if (r.type === 'damage') {
        if (r.status === 'initialized') return checkState(damageInitializedTruthyState)
        return checkState(damageInitializedTruthyState)
    }
}
