import { useSearchParams } from 'react-router-dom'
import AdjustmentsModal from './modals/AdjustmentsModal'
import ProductList from './lists/ProductList'

export default function CostAndPriceScreen() {
    const [searchParams, setSearchParams] = useSearchParams()
    const product_id = searchParams.get('product_id')

    return (
        <div className='text-sm pb-16'>
            <h1 className='text-2xl font-semibold mb-4 leading-none'>Cost and price</h1>
            <ProductList />
            {product_id && (
                <AdjustmentsModal
                    productId={product_id}
                    toggleModal={() => {
                        searchParams.delete('product_id')
                        setSearchParams(searchParams)
                    }}
                />
            )}
        </div>
    )
}
