import { useSearchParams } from 'react-router-dom'
import CreateTemplateModal from './modals/CreateTemplateModal'
import EditTemplateModal from './modals/EditTemplateModal'
import AnalyticsHeader from './components/AnalyticsHeader'
import { Button } from 'custom_components/component_Basics/Button'
import TemplateList from './lists/TemplateList'
import Blacklist from './lists/Blacklist'

export default function FollowUpEmailScreen() {
    const [searchParams, setSearchParams] = useSearchParams()
    const view = searchParams.get('view')
    const templateId = searchParams.get('template_id')

    return (
        <div>
            <div className='flex mb-2 justify-between mb-4'>
                <h1 className='text-2xl font-semibold'>Follow Up Emails</h1>
                <Button
                    variant={'outline'}
                    onClick={() =>
                        setSearchParams((prev) => {
                            prev.set('view', 'create-template')
                            return prev
                        })
                    }
                >
                    Create Template
                </Button>
            </div>
            <AnalyticsHeader />
            <TemplateList />
            <Blacklist />
            {view === 'create-template' && <CreateTemplateModal />}
            {view === 'edit-template' && templateId && <EditTemplateModal templateId={templateId} />}
        </div>
    )
}
