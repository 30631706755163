import { useEffect, useState } from 'react'
import RedirectComponent from './RedirectComponent'
import './RedirectsScreen.css'
import { vFetch } from '../../helpers'

export default function RedirectsScreen() {
    const [redirects, setRedirects] = useState([])
    const [needsRefresh, setNeedsRefresh] = useState(true)
    const addRedirect = () => {
        setRedirects([
            ...redirects,
            {
                redirect_id: parseInt(redirects[redirects.length - 1].redirect_id) + 1,
                from_path: '',
                to_path: '',
                is_new: true,
            },
        ])
    }
    function refresh() {
        vFetch(`/v1/redirects`, {
            cb: (res) => {
                if (res.success) {
                    setRedirects(res.redirects)
                }
            },
        })
    }
    useEffect(() => {
        refresh()
    }, [])
    return (
        <div className='redirects'>
            <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Redirects</h1>
            <div className='redirects__heading-row'>
                <div className='redirects__heading-row__label'>FROM</div>
                <div className='redirects__heading-row__label'>TO</div>
            </div>
            {redirects.map((r) => (
                <RedirectComponent
                    redirects={redirects}
                    setRedirects={setRedirects}
                    redirect_id={r.redirect_id}
                    from_path={r.from_path}
                    to_path={r.to_path}
                    is_new={r.is_new}
                    refresh={refresh}
                />
            ))}
            <button className='redirects__new-button' onClick={addRedirect}>
                +
            </button>
        </div>
    )
}
