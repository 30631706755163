import { createSlice } from '@reduxjs/toolkit'

export const appFunctionsSlice = createSlice({
    name: 'data',
    initialState: {
        modalDiv: {
            func: '',
            args: '',
            name: '',
            purpose: '',
        },
    },
    reducers: {
        setAreYouSure: (state, action) => {
            const { func, args, name, purpose } = action.payload
            state.modalDiv = {
                func: func,
                args: args,
                name: name,
                purpose: purpose,
            }
        },
        setTextInput: (state, action) => {
            const { heading, subheading, purpose } = action.payload
            state.modalDiv = {
                heading: heading,
                subheading: subheading,
                purpose: purpose,
            }
        },
    },
})

export const { setAreYouSure, setTextInput } = appFunctionsSlice.actions

export const selectModalDiv = (state) => state.appFunctions.modalDiv

export default appFunctionsSlice.reducer
