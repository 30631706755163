import { useState } from 'react'
import { OrderInit } from '../../orders/orders.types'
import EmailModal from '../../emailModal/EmailModal'

type CustomerProps = {
    order: OrderInit
    eventSource: {
        foreign_id: number | null | undefined
        foreign_table: string
    }
    onEmailSubmit: Function
    refetchAttachments: boolean
    setRefetchAttachments: any
}

export default function DraftCustomer({
    order,
    eventSource,
    onEmailSubmit,
    refetchAttachments,
    setRefetchAttachments,
}: CustomerProps) {
    const [emailMode, setEmailMode] = useState<string | null>(null)

    return (
        <div className='grid bg-white dark:bg-darkaccent shadow-small rounded dark:text-offwhite [&>*]:p-[16px] [&>*]:border-b-[1px] [&>*]:dark:border-darkgrey [&>:last-child]:border-0'>
            <div>
                <div className='flex justify-between'>
                    <h2 className='text-[20px] font-bold mb-[4px]'>Customer</h2>
                    <button className='relative w-[35px] h-[35px] text-grey hover:text-darkgrey dark:hover:text-offwhite'></button>
                </div>
                <p
                    onClick={() => {
                        window.open(
                            `https://factorypure.myshopify.com/admin/customers/${order?.customer?.id}`,
                            '_blank'
                        )
                    }}
                    className='text-blue dark:text-accent hover:underline cursor-pointer my-[8px]'
                >
                    {order?.customer?.first_name} {order?.customer?.last_name}
                </p>
                <p
                    onClick={() => {
                        window.open(
                            `https://factorypure.myshopify.com/admin/orders?customer_id=${order?.customer?.id}&inContextTimeframe=today`,
                            '_blank'
                        )
                    }}
                    className='text-blue dark:text-accent hover:underline cursor-pointer my-[8px]'
                >
                    See Other Orders
                </p>
            </div>
            {order?.customer?.email && (
                <div>
                    <h2 className='text-[20px] font-bold mb-[4px]'>Contact Information</h2>
                    <div className='grid gap-[16px]'>
                        <a
                            href={`mailto:${order.customer.email}`}
                            className='text-blue dark:text-accent hover:underline cursor-pointer my-[8px]'
                        >
                            {order.customer.email}
                        </a>
                        <button
                            type='button'
                            onClick={() => setEmailMode('customer')}
                            className='py-[4px] w-full uppercase px-[16px] border-2 border-blue dark:border-fire text-blue dark:text-fire rounded-[4px] font-bold'
                        >
                            Email Customer
                        </button>
                        {order.name && !order.name.includes('D') && (
                            <button
                                type='button'
                                onClick={() => setEmailMode('manufacturer')}
                                className='py-[4px] w-full uppercase px-[16px] border-2 border-blue dark:border-fire text-blue dark:text-fire rounded-[4px] font-bold'
                            >
                                Email Manu
                            </button>
                        )}
                        <EmailModal
                            eventSource={eventSource}
                            mode={emailMode}
                            setMode={setEmailMode}
                            handleSubmitEmailEvent={onEmailSubmit}
                            customer={order.customer}
                            order={order}
                            refetchAttachments={refetchAttachments}
                            setRefetchAttachments={setRefetchAttachments}
                        />
                    </div>
                </div>
            )}
            {order?.shipping_address && (
                <div>
                    <h2 className='text-[20px] font-bold mb-[4px]'>Shipping Address</h2>
                    {[
                        order.shipping_address.name && <p>{order.shipping_address.name}</p>,
                        order.shipping_address.address1 && <p>{order.shipping_address.address1}</p>,
                        (order.shipping_address.city ||
                            order.shipping_address.province_code ||
                            order.shipping_address.zip) && (
                            <p>
                                {order.shipping_address.city && `${order.shipping_address.city}, `}
                                {order.shipping_address.province_code && `${order.shipping_address.province_code} `}
                                {order.shipping_address.zip && `${order.shipping_address.zip}`}
                            </p>
                        ),
                        order.shipping_address.country && <p>{order.shipping_address.country}</p>,
                        order.shipping_address.phone && <p>{order.shipping_address.phone}</p>,
                    ].filter((v) => v)}
                </div>
            )}
            <div>
                <h2 className='text-[20px] font-bold mb-[4px]'>Billing Address</h2>
                {order.billing_address && (
                    <>
                        {order.shipping_address?.name === order.billing_address.name &&
                        order.shipping_address?.address1 === order.billing_address.address1 &&
                        order.shipping_address?.city === order.billing_address.city &&
                        order.shipping_address?.province_code === order.billing_address.province_code &&
                        order.shipping_address?.zip === order.billing_address.zip &&
                        order.shipping_address?.country_code === order.billing_address.country_code ? (
                            <p>Same as shipping address</p>
                        ) : (
                            <>
                                <p>{order.billing_address.name}</p>
                                <p>{order.billing_address.address1}</p>
                                <p>
                                    {order.billing_address.city}, {order.billing_address.province_code}{' '}
                                    {order.billing_address.zip}
                                </p>
                                <p>{order.billing_address.country_code}</p>
                                <p>{order.billing_address.phone}</p>
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}
