import Topbar from './Topbar'
import './LayoutLogin.css'

export default function LayoutLogin({ children }) {
    return (
        <>
            <main className='content'>{children}</main>
        </>
    )
}
