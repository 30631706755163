import { useState } from 'react'
import RoadmapList from './lists/RoadmapList'
import { useQuery } from '@tanstack/react-query'
import { vFetch } from '../helpers'
import CreateRoadmap from './quickModals/CreateRoadmap'
import { useRoadmaps } from './api/useQueries'

export default function DevelopmentRoadmapsScreen() {
    const [quickModal, setQuickModal] = useState<undefined | string>()

    const roadmapsQuery = useRoadmaps()
    const { data: roadmaps, isPending } = roadmapsQuery

    return (
        <div>
            <div className='flex justify-between mb-4'>
                <h1 className='text-[24px] leading-tight font-semibold dark:text-offwhite capitalize'>
                    Development Roadmaps
                </h1>
                <button
                    className='h-fit bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold py-1 px-2 leading-tight rounded'
                    onClick={() => setQuickModal('roadmap')}
                >
                    + New Roadmap
                </button>
            </div>
            {isPending ? <p className='p-4'>Loading...</p> : <RoadmapList roadmaps={roadmaps} />}
            {quickModal === 'roadmap' && <CreateRoadmap setQuickModal={setQuickModal} />}
        </div>
    )
}
