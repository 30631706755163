export default function MetafieldPreview({ metafield }: { metafield: string }) {
    const parseMetafield = (metafield: string) => {
        try {
            return JSON.parse(metafield)
        } catch {
            return metafield
        }
    }
    const parsedMetafield = parseMetafield(metafield)
    const typeofMetafield = Array.isArray(parsedMetafield)
        ? 'array'
        : typeof parsedMetafield === 'object'
          ? 'object'
          : 'string'

    if (typeofMetafield === 'string') {
        return (
            <div className='w-[300px] absolute left-[calc(100%+8px)] shadow-lg rounded border border-lightgrey dark:border-darkgrey top-0 p-2 bg-white dark:bg-darkbg1 text-sm'>
                <p className='font-bold'>Preview:</p>
                <p>{metafield}</p>
            </div>
        )
    }

    if (typeofMetafield === 'array') {
        return (
            <div className='w-[300px] absolute left-[calc(100%+8px)] shadow-lg rounded border border-lightgrey dark:border-darkgrey top-0 p-2 bg-white dark:bg-darkbg1 text-sm'>
                <p className='font-bold'>Preview:</p>
                {parsedMetafield.map((value: string) => (
                    <p>{value}</p>
                ))}
            </div>
        )
    }

    return (
        <div className='w-[300px] absolute left-[calc(100%+8px)] shadow-lg border border-lightgrey dark:border-darkgrey rounded top-0 p-2 bg-white dark:bg-darkbg1 text-sm'>
            <p className='font-bold'>Preview:</p>
            {Object.entries(parsedMetafield).map(([key, value]: any) => (
                <p>
                    <span className='font-bold'>{key}:</span> {value}
                </p>
            ))}
        </div>
    )
}
