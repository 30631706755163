import { useSearchParams } from 'react-router-dom'
import { useGetProjects } from 'tasksV2/api/useQueries'
import ProjectListV2 from './lists/ProjectListV2'
import CreateProjectModal from './modals/CreateProjectModal'

export default function ProjectsScreen() {
    const [searchParams, setSearchParams] = useSearchParams()
    const showCreateModal = searchParams.get('showCreateModal') === 'true'
    const projectsQuery = useGetProjects({})
    const projects = projectsQuery.data?.projects || []
    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showCreateModal')
        } else {
            searchParams.set('showCreateModal', 'true')
        }
        setSearchParams(searchParams)
    }
    return (
        <div className='text-sm'>
            <ProjectListV2 />
            {showCreateModal && <CreateProjectModal closeModal={toggleCreateModal} />}
        </div>
    )
}
