import { useSearchParams } from 'react-router-dom'

export default function SupplierRow({ supplier }: { supplier: any }) {
    const [searchParams, setSearchParams] = useSearchParams()

    const toggleEditModal = (supplierId?: number) => {
        if (!supplierId) {
            searchParams.delete('supplier_id')
            return setSearchParams(searchParams)
        }
        searchParams.set('supplier_id', supplierId.toString())
        return setSearchParams(searchParams)
    }

    return (
        <div
            className='cursor-pointer hover:bg-lightgrey dark:hover:bg-darkbg2 border-b border-lightgrey dark:border-darkgrey p-2 grid grid-cols-4 gap-2'
            onClick={() => toggleEditModal(supplier.id)}
        >
            <p>{supplier.name}</p>
            <p>
                {supplier.po_type === 'edi'
                    ? 'EDI'
                    : `${supplier.po_type[0].toUpperCase()}${supplier.po_type.slice(1)}`}
            </p>
            <p>{supplier.contact_name}</p>
            <p>{supplier.test_mode === 1 ? 'Testing' : 'Live'}</p>
        </div>
    )
}
