import { vFetch } from 'helpers'

// departments
export const getCustomers = async (filters: any) => {
    const params = Object.entries(filters)
        .map(([key, value]) => (value ? `${key}=${value}` : null))
        .filter((v) => v)
        .join('&')
    return await vFetch(`/v2/customers${params ? `?${params}` : ''}`)
}
