import { GrRefresh } from 'react-icons/gr'
import { cn } from '../helpers'

export function FetchingSpinner({ isFetching, className }: { isFetching: any; className?: string }) {
    return (
        <>
            <GrRefresh
                className={cn(
                    isFetching && 'animate-spin opacity-100',
                    !isFetching && 'opacity-0',
                    '[&>*]:stroke-darkgrey [&>*]:dark:stroke-offwhite ',
                    className
                )}
            />
        </>
    )
}
