import { useQueryClient } from '@tanstack/react-query'
import { cn } from 'helpers'
import { GrRefresh } from 'react-icons/gr'

export default function RefreshButton({ queryKeys, isFetching }: { queryKeys: string[]; isFetching?: any }) {
    const queryClient = useQueryClient()

    return (
        <button
            onClick={() => {
                queryKeys.map((key) => {
                    queryClient.resetQueries({ queryKey: key.split(',') })
                })
            }}
            className='p-[5px] h-fit bg-lightgrey dark:bg-darkaccent rounded-[4px] hover:bg-fire transition'
        >
            <GrRefresh
                className={cn(
                    isFetching && 'animate-spin opacity-100',
                    '[&>*]:stroke-darkgrey [&>*]:dark:stroke-offwhite'
                )}
            />
        </button>
    )
}
