import Select from 'procurement/components/Select'
import { useSelector } from 'react-redux'

export default function TaskAlertStateEditor({
    alertOffset,
    sendEmailAlert,
    sendSlackAlert,
    handleChange,
}: {
    alertOffset?: string
    sendEmailAlert?: 1 | 0
    sendSlackAlert?: 1 | 0
    handleChange: (...args: any) => void
}) {
    const settings = useSelector((state: any) => state.settings)
    return (
        <div className='flex flex-col gap-1'>
            <label className='text-xs font-bold uppercase text-darkgrey dark:text-offwhite leading-none'>
                Reminders
            </label>
            <div className='flex gap-4'>
                <div className='flex flex-col gap-1 items-center'>
                    <label htmlFor='send_slack_alert' className='text-xs font-bold leading-none cursor-pointer'>
                        Slack
                    </label>
                    <input
                        style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                        className='cursor-pointer mt-[5px]'
                        type='checkbox'
                        id='send_slack_alert'
                        name='send_slack_alert'
                        checked={sendSlackAlert === 1}
                        onChange={handleChange}
                    />
                </div>
                <div className='flex flex-col gap-1 items-center'>
                    <label htmlFor='send_email_alert' className='text-xs font-bold leading-none cursor-pointer'>
                        Email
                    </label>
                    <input
                        style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                        className='cursor-pointer mt-[5px]'
                        type='checkbox'
                        id='send_email_alert'
                        name='send_email_alert'
                        checked={sendEmailAlert === 1}
                        onChange={handleChange}
                    />
                </div>
                <div className='flex flex-col gap-1 w-full'>
                    <label htmlFor='alert_offset' className='text-xs font-bold leading-none cursor-pointer'>
                        When
                    </label>
                    <Select
                        className='p-1 rounded leading-none text-sm'
                        id='alert_offset'
                        name='alert_offset'
                        value={alertOffset}
                        onChange={handleChange}
                    >
                        <option value=''>Do not remind</option>
                        <option value='0'>When Due</option>
                        <option value='15'>15 Minutes Before</option>
                        <option value='30'>30 Minutes Before</option>
                        <option value='60'>1 Hour Before</option>
                        <option value='1440'>1 Day Before</option>
                    </Select>
                </div>
            </div>
        </div>
    )
}
