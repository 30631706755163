import { FaTrash } from 'react-icons/fa'
import { RxDragHandleDots2 } from 'react-icons/rx'
import { useDeleteDepartmentOption } from 'tasksV2/api/useQueries'

export default function DepartmentOptionRow({
    optionName,
    option,
    onDragStart,
    onDragEnter,
    onDragOver,
    onDrop,
}: {
    optionName: 'estimates' | 'types'
    option: any
    onDragStart: any
    onDragEnter: any
    onDragOver: any
    onDrop: any
}) {
    const deleteOptionMutation = useDeleteDepartmentOption(optionName)

    const handleDelete = () => {
        deleteOptionMutation.mutate({
            id: option.id,
        })
    }

    return (
        <div
            onDragStart={onDragStart}
            onDragEnter={onDragEnter}
            onDragOver={onDragOver}
            onDrop={onDrop}
            draggable
            className='p-1 border rounded border-lightgrey dark:border-darkgrey flex gap-2'
        >
            <RxDragHandleDots2 className='text-xl self-center shrink-0 cursor-grab' />
            <p className='w-full p-2 focus:outline-none'>{option.value}</p>
            <button onClick={handleDelete}>
                <FaTrash className='text-red dark:text-lightred' />
            </button>
        </div>
    )
}
