import { Dispatch, SetStateAction } from 'react'
import ParentCreateContact from './parentQuickActions/ParentCreateContact'
import ParentCreateNote from './parentQuickActions/ParentCreateNote'

export default function ParentQuickModal({
    company_ids,
    showQuickModal,
    setShowQuickModal,
}: {
    company_ids: string[]
    showQuickModal: string | undefined
    setShowQuickModal: Dispatch<SetStateAction<string | undefined>>
}) {
    return (
        <div className='fixed w-[500px] h-fit bottom-0 left-[50%] translate-x-[-50%] z-[100] bg-white shadow-small rounded-t-[4px] overflow-hidden dark:bg-darkaccent flex flex-col border border-darkgrey dark:border-grey border-b-0'>
            <button
                onClick={() => {
                    setShowQuickModal(undefined)
                }}
                className='leading-[1] text-[24px] text-red dark:text-lightred absolute font-bold top-0 right-[8px]'
            >
                &times;
            </button>
            {showQuickModal === 'note' && (
                <ParentCreateNote company_ids={company_ids} setShowQuickModal={setShowQuickModal} />
            )}
            {showQuickModal === 'contact' && (
                <ParentCreateContact company_ids={company_ids} setShowQuickModal={setShowQuickModal} />
            )}
        </div>
    )
}
