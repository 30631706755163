import { useContext, useState } from 'react'
import { useArchiveVendorContact, useContactTypesQuery, useCreateContact } from '../../../api/useQueries'
import { CompanyContext, CompanyContextType } from '../../../helpers'
import { cn, sendToast } from '../../../../helpers'
import Select from '../../Select'
import Input from 'procurement/components/Input'

export default function ImportContactV2({
    setShowQuickModal,
    selectedLegacyContact,
}: {
    setShowQuickModal: any
    selectedLegacyContact: any
}) {
    const { editableCompany } = useContext<CompanyContextType>(CompanyContext)
    const importPhone = [
        selectedLegacyContact.phone_prefix ? '+' + selectedLegacyContact.phone_prefix + '-' : null,
        selectedLegacyContact.phone
            ? [
                  selectedLegacyContact.phone.slice(0, 3),
                  selectedLegacyContact.phone.slice(3, 6),
                  selectedLegacyContact.phone.slice(6),
              ].join('-')
            : null,
        selectedLegacyContact.phone_extension ? ', EXT' + selectedLegacyContact.phone_extension : null,
    ]
        .filter((p) => p)
        .join('')
    const [contact, setContact] = useState({
        name: selectedLegacyContact.name || '',
        phone: importPhone || '',
        email: selectedLegacyContact.email || '',
        alternate: '',
        company_id: editableCompany.id,
        contact_type: selectedLegacyContact.contact_type || 'General',
    })

    const archiveVendorContact = useArchiveVendorContact()

    const contactTypesQuery = useContactTypesQuery()
    const { types: contactTypes } = contactTypesQuery?.data || {}

    const createContact = useCreateContact(setShowQuickModal)
    const { isPending } = createContact
    const handleSubmit = () => {
        createContact.mutate(
            { contact },
            {
                onSuccess: () => {
                    archiveVendorContact.mutate({ contact: selectedLegacyContact })
                },
                onSettled: () => {
                    return setShowQuickModal(undefined)
                },
            }
        )
    }
    const handleChange = ({ target }: { target: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement }) => {
        setContact((previousState) => ({ ...previousState, [target.name]: target.value }))
    }
    const vendorValues = [
        selectedLegacyContact.contact_type,
        selectedLegacyContact.name,
        importPhone,
        selectedLegacyContact.email,
        selectedLegacyContact.cell_phone
            ? `Cell: ${selectedLegacyContact.cell_phone.slice(0, 3)}-${selectedLegacyContact.cell_phone.slice(
                  3,
                  6
              )}-${selectedLegacyContact.cell_phone.slice(6)}`
            : '',
    ].filter((v) => v)
    const details = selectedLegacyContact ? vendorValues.slice(1).filter((v: any) => v) : []
    return (
        <>
            <div className={cn(createContact.isPending && 'opacity-50')}>
                <div className='flex gap-2'>
                    <div className='w-1/2'>
                        <div className='p-[16px] w-full bg-lightgrey dark:bg-darkness'>
                            <h3 className='leading-[1] font-bold'>Import Info</h3>
                        </div>
                        <div className='p-[16px] flex flex-col gap-[4px]'>
                            <Input
                                className='mb-2'
                                type='text'
                                label='Contact Type'
                                id='contact_type'
                                name='contact_type'
                                onChange={handleChange}
                                value={selectedLegacyContact.contact_type}
                                readOnly={true}
                            />
                            {details.length > 0 &&
                                details.map((detail: any) => {
                                    return (
                                        <div
                                            onClick={() => {
                                                navigator.clipboard.writeText(detail)
                                                sendToast({ message: `Copied: ${detail}` })
                                            }}
                                            className='flex flex-col gap-[4px] w-full'
                                        >
                                            <Input
                                                type='text'
                                                readOnly={true}
                                                className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                                id='name'
                                                name='name'
                                                onChange={handleChange}
                                                value={detail}
                                                disabled={isPending}
                                            />
                                        </div>
                                    )
                                })}
                            <div
                                onClick={() => {
                                    navigator.clipboard.writeText(selectedLegacyContact.alternate)
                                    sendToast({ message: `Copied: ${selectedLegacyContact.alternate}` })
                                }}
                                className='flex flex-col gap-[4px] w-full h-1/2'
                            >
                                <textarea
                                    className='h-[200px] border-none w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                    id='alternate'
                                    name='alternate'
                                    value={selectedLegacyContact.alternate}
                                    readOnly={true}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/2'>
                        <div className='p-[16px] w-full bg-lightgrey dark:bg-darkness'>
                            <h3 className='leading-[1] font-bold'>New Contact</h3>
                        </div>
                        <div className='p-[16px] flex flex-col gap-[4px] w-full'>
                            <Select
                                label='Contact Type'
                                id='contact_type'
                                name='contact_type'
                                onChange={handleChange}
                                value={selectedLegacyContact[0] || contact.contact_type}
                            >
                                <option value={'General'}>General</option>
                                {contactTypes?.map((type: string) => {
                                    return <option value={type}>{type}</option>
                                })}
                            </Select>
                            <div className='flex flex-col gap-[4px] w-full'>
                                <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    Name
                                </label>
                                <input
                                    className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                    id='name'
                                    name='name'
                                    onChange={handleChange}
                                    value={contact.name}
                                    disabled={isPending}
                                />
                            </div>
                            <div className='flex flex-col gap-[4px] w-full'>
                                <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    Email
                                </label>
                                <input
                                    className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                    id='email'
                                    name='email'
                                    onChange={handleChange}
                                    value={contact.email}
                                    disabled={isPending}
                                />
                            </div>
                            <div className='flex flex-col gap-[4px] w-full'>
                                <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    Phone
                                </label>
                                <input
                                    className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                    id='phone'
                                    name='phone'
                                    onChange={handleChange}
                                    value={contact.phone}
                                    disabled={isPending}
                                />
                            </div>
                            <div className='flex flex-col gap-[4px] w-full'>
                                <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    Alternate
                                </label>
                                <textarea
                                    className='h-[100px] border-none w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                    id='alternate'
                                    name='alternate'
                                    onChange={handleChange}
                                    value={contact.alternate}
                                    disabled={isPending}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='p-[16px] w-full bg-lightgrey dark:bg-darkness flex justify-center'>
                    <button
                        onClick={handleSubmit}
                        className='py-[4px] px-[8px] rounded-[4px] text-[14px] bg-blue dark:bg-accent font-bold uppercase text-white dark:text-darkaccent'
                        disabled={isPending}
                    >
                        {createContact.isPending ? 'Saving Contact...' : 'Save Contact'}
                    </button>
                </div>
            </div>
        </>
    )
}
