import '../docs.css'

export default function AWSDocs() {
    return (
        <div className='docs'>
            <h1 className='docs__heading'>AWS</h1>
            <div className='docs__body'>
                <h2 className='docs__body__heading'>Active Services</h2>
                <div className='docs__body__service'>
                    <h3 className='docs__body__services__heading'>EC2 Instances</h3>
                    <ul>
                        <li>
                            <div>
                                <h4>FPDash</h4>
                                <ul className='docs__body__services__table'>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>Public Address:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>ec2-user@ec2-44-194-40-167.compute-1.amazonaws.com</code>
                                        </div>
                                    </li>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>GP2 Storage:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>8GB</code>
                                        </div>
                                    </li>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>Database:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>MYSQL - Amazon RDS - fp-new + fp-new-read-rep</code>
                                        </div>
                                    </li>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>Instance Type:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>t4g.nano (2 vCPU, 0.5GB RAM)</code>
                                        </div>
                                    </li>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>Cost:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>($3.15) On Demand $0.0042/hr - 750 Hours</code>
                                        </div>
                                    </li>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>PEM Keys:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>fp-dash-dev</code>
                                        </div>
                                    </li>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>Associated Domains:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>server.fpdash.com</code>
                                        </div>
                                    </li>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>Apps Running:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>
                                                fpdash-server (
                                                <a href='https://github.com/FactoryPure/fpdash-server'>GitHub</a>)
                                            </code>
                                        </div>
                                    </li>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>Process Manager:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>PM2 - Fork Mode</code>
                                        </div>
                                    </li>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>Notes:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>
                                                This server is facilitates all of the Shopify Admin API requests coming
                                                from the FPDashboard.
                                                <br />
                                                The associated Shopify App has access to sensitive information and thus
                                                all requests require authorization via JWT.
                                                <br />
                                                Server routes are organized in 'Modules', with each containing a
                                                controller, serivce, router, helper, and validator.
                                                <br />
                                                This server handles order and draft order webhooks from Shopify.
                                                <br />
                                                SSH requires PEM key.
                                            </code>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <div>
                                <h4>FP OnSite</h4>
                                <ul className='docs__body__services__table'>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>Public Address:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>ec2-user@ec2-44-208-10-68.compute-1.amazonaws.com</code>
                                        </div>
                                    </li>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>GP2 Storage:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>8GB</code>
                                        </div>
                                    </li>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>Database:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>MYSQL - Amazon RDS - fp-new + fp-new-read-rep</code>
                                        </div>
                                    </li>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>Instance Type:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>t4g.nano (2 vCPU, 0.5GB RAM)</code>
                                        </div>
                                    </li>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>Cost:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>($3.15) On Demand $0.0042/hr - 750 Hours</code>
                                        </div>
                                    </li>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>PEM Keys:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>fp-dash-dev</code>
                                        </div>
                                    </li>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>Associated Domains:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>api.fp-development.com</code>
                                        </div>
                                    </li>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>Apps Running:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>
                                                fpapps-server (
                                                <a href='https://github.com/FactoryPure/fpapps-server'>GitHub</a>)
                                            </code>
                                        </div>
                                    </li>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>Process Manager:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>PM2 - Fork Mode</code>
                                        </div>
                                    </li>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>Notes:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>
                                                This server powers the apps used on the FactoryPure site (Recently
                                                Viewed, On Sale, Compare Similar, Collection Pages).
                                                <br />
                                                The products / collections data and related webhooks are managed here.
                                                We use a clone of our shopify database to facilitate better searching,
                                                sorting, and filtering.
                                                <br />
                                                This data is publicly accessible.
                                                <br />
                                                <br />
                                                Read streams must be used to parse large updates as available memmory is
                                                limited
                                            </code>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                    <h3 className='docs__body__services__heading'>RDS Instances</h3>
                    <ul>
                        <li>
                            <div>
                                <h4>Main FP DB</h4>
                                <ul className='docs__body__services__table'>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>Endpoint:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>fp-new.covyvnlc0a3z.us-east-1.rds.amazonaws.com</code>
                                        </div>
                                    </li>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>Size:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>20GB</code>
                                        </div>
                                    </li>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>Port:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>3306</code>
                                        </div>
                                    </li>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>Instance Type:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>db.t3.micro (2 vCPU, 1GB RAM)</code>
                                        </div>
                                    </li>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>Cost:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>
                                                ($29.50) On Demand $0.017/hr - 750 hours + $0.10/GB-Month - 20 GB-Months
                                                + Read Replica
                                            </code>
                                        </div>
                                    </li>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>Associated Read Replicas:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>fp-new-read-rep</code>
                                        </div>
                                    </li>
                                    <li className='docs__body__services__table-row'>
                                        <div className='docs__body__services__table-cell'>
                                            <p>Notes:</p>
                                        </div>
                                        <div className='docs__body__services__table-cell'>
                                            <code>
                                                This is our everything database. We have some complex inserts which
                                                cause a considerable blocking time thus we have a read replica to ensure
                                                performance meets the demands of our traffic.
                                            </code>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
