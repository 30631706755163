import { useState } from 'react'
import { vFetch } from '../../helpers'
import { FaTimes } from 'react-icons/fa'
import Textarea from '../../procurement/components/Textarea'

export default function CreateSubmitAQuestionModal({ openModal }: any) {
    const [question, setQuestion] = useState('')

    const handleSubmit = () => {
        vFetch('/v1/knowledge/questions', {
            method: 'POST',
            body: JSON.stringify({ question }),
            cb: () => {
                setQuestion('')
                openModal(false)
            },
        })
    }

    const handleClose = () => {
        openModal(false)
    }

    return (
        <>
            <div className='flex flex-col p-4 w-[100%] h-full relative bg-white  dark:bg-darkaccent rounded rounded-b-none border border-b-0 border-gray-600'>
                <p className='text-lg font-bold mb-4'>Create A New Question</p>
                <Textarea
                    id='question'
                    name='question'
                    autoFocus={true}
                    value={question}
                    onChange={({ target }) => setQuestion(target.value)}
                    placeholder='Type your question here'
                    className='min-h-full max-h-[90px] w-[100%] p-[8px] max-w-none border-[1px] border-[#cdcdcd] 
                rounded border-none outline-none dark:focus:outline-[1px] dark:focus:outline dark:focus:outline-accent dark:bg-darkness
                 dark:text-offwhite dark:border-gray-600 overscroll-contain pb-0'
                ></Textarea>
                <div></div>
                <button
                    onClick={handleSubmit}
                    className='mx-auto mt-2 p-2 shrink-0 whitespace-nowrap text-white bg-blue dark:bg-accent dark:text-darkaccent font-bold text-base rounded'
                >
                    Submit Question
                </button>
                <button className='absolute top-0 right-0' onClick={handleClose}>
                    <FaTimes className='text-red w-7 h-7' />
                </button>
            </div>
        </>
    )
}
