import {
    CollectionListProps,
    CollectionProcessingTimesRow,
    ProcessingTimesRow,
} from '../../ProcessingTimesScreen.types'

export default function CollectionList({
    collectionProcessingTimes = [],
    setSelectedCollection,
    search,
    page = 1,
}: CollectionListProps) {
    const searchlower = search.toLowerCase()
    const filteredCollections = filterMessages(collectionProcessingTimes, searchlower, page)
    /**
     * Returns a filtered list of messages based on the search, filteres, and page
     */
    function filterMessages(collectionProccessingTimes: CollectionProcessingTimesRow[], search: string, page: number) {
        const startIndex = (page - 1) * 50
        const endIndex = page * 50
        return collectionProccessingTimes.slice(startIndex, endIndex)
    }
    return (
        <>
            <div className='sticky top-[50px] grid grid-cols-[200px_1fr_1fr] col-span-full border-b border-lightgrey bg-blue text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Title</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>PDP</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Cart</div>
            </div>
            {filteredCollections.map((collection) => {
                const activeCollectionPDPMessage = collection.active_pdp_processing_time || ({} as ProcessingTimesRow)
                const activeCollectionCartMessage = collection.active_cart_processing_time || ({} as ProcessingTimesRow)
                return (
                    <div
                        key={collection.gid}
                        onClick={() => setSelectedCollection(collection)}
                        className='grid grid-cols-[200px_1fr_1fr] items-center border-b border-lightgrey dark:border-darkgrey hover:bg-lightgrey dark:hover:bg-darkness cursor-pointer'
                    >
                        <div className='p-[8px] font-bold dark:text-offwhite'>{collection.title}</div>
                        <div className='p-[8px]'>
                            <p className='flex font-bold text-darkgrey dark:text-offwhite uppercase gap-[6px]'>
                                {activeCollectionPDPMessage.pdp_line_1}
                            </p>
                            <p className='flex gap-[6px] dark:text-offwhite'>{activeCollectionPDPMessage.pdp_line_2}</p>
                        </div>
                        <div className='p-[8px]'>
                            <p className='flex font-bold text-darkgrey dark:text-offwhite uppercase gap-[6px]'>
                                {activeCollectionCartMessage.cart_line_1}
                            </p>
                        </div>
                    </div>
                )
            })}
        </>
    )
}
