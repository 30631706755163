import { useState } from 'react'
import { sendToast } from '../../helpers'
import {
    VendorContactEditInit,
    VendorContactsMap,
    VendorInit,
    defaultVendor,
    defaultVendorContact,
} from '../vendors.types'
import VendorContactsList from './VendorContactsList'

type VendorsListProps = {
    vendorsInfo: VendorContactsMap
    expanded: string[]
    setVendorsInfo: Function
    setExpanded: Function
    setVendorToEdit: Function
    setVendorContactToEdit: Function
    refreshVendorsList: Function
}

export default function VendorsList({
    expanded,
    vendorsInfo,
    setVendorsInfo,
    setExpanded,
    setVendorToEdit,
    setVendorContactToEdit,
    refreshVendorsList,
}: VendorsListProps) {
    const [expandedReturnPolicy, setExpandedReturnPolicy] = useState<string[]>([])
    const preStyle = 'whitespace-pre-wrap font-normal font-bai text-[16px]'

    function clickHereToSetUpVendor(property: string, vendor: VendorInit, paddingLeft?: boolean) {
        return (
            <div className='p-[8px] border-b-[1px] border-darkgrey'>
                <span className={paddingLeft ? 'pl-[16px]' : ''}>
                    <strong>{property}</strong> has not been set up yet.
                </span>{' '}
                <span
                    className='text-blue dark:text-accent cursor-pointer hover:underline'
                    // onClick={() => setVendorToEdit(vendor)}
                ></span>
            </div>
        )
    }
    return (
        <div className='relative border-b-[1px] border-darkgrey'>
            {!Object.keys(vendorsInfo).length ? (
                <div className='flex dark:text-offwhite justify-center border-x-[1px] border-darkgrey border-t-0 p-[8px]'>
                    <h2 className='text-[20px] font-semibold'>No vendors found</h2>
                </div>
            ) : (
                Object.keys(vendorsInfo).map((v) => {
                    const vendor = vendorsInfo[v]
                    //come back and add real accordion style later
                    return (
                        <div
                            className={`grid duration-300 items-center text-[16px] border-darkgrey ${
                                expanded.includes(v)
                                    ? `h-full border-[2px] my-[10px] bg-[rgb(224,228,235)]/10 dark:bg-darkaccent/20`
                                    : `h-[40px] border-x-[1px] ${
                                          vendor.contacts.length > 0
                                              ? 'hover:bg-[rgb(199,202,209)]/75 [&:nth-child(even)]:bg-[rgb(224,228,235)]/75 [&:nth-child(even)]:hover:bg-[rgb(199,202,209)]/75 dark:[&:nth-child(even)]:bg-darkaccent/75 dark:[&:nth-child(even)]:hover:bg-blue/75 dark:hover:bg-blue/75'
                                              : 'text-white dark:text-offwhite bg-red [&:nth-child(even)]:bg-red/80 hover:bg-red/60 [&:nth-child(even)]:hover:bg-red/60'
                                      }`
                            }`}
                        >
                            <div
                                className={`relative flex flex-wrap items-center font-bold cursor-pointer ${
                                    expanded.includes(v)
                                        ? vendor.contacts.length > 0
                                            ? 'bg-[rgb(199,202,209)]/75 dark:bg-blue/50'
                                            : 'bg-red dark:bg-red/60'
                                        : ''
                                }`}
                            >
                                <div
                                    className='flex p-[8px] grow'
                                    onClick={() => {
                                        setExpanded(
                                            expanded.includes(v) ? expanded.filter((e) => e !== v) : [...expanded, v]
                                        )
                                    }}
                                >
                                    <div className='flex w-[16px]'>{expanded.includes(v) ? '-' : '+'}</div>
                                    {v} ({vendor.contacts.length})
                                </div>
                                {expanded.includes(v) && (
                                    <div className='flex gap-[8px] px-[8px] items-center flex-wrap'>
                                        {vendor ? <div></div> : <div></div>}
                                        {/* {vendor ? (
                                            <button
                                                className='px-[8px] uppercase font-bold rounded text-white dark:text-black bg-blue hover:bg-blue/80 dark:bg-accent dark:hover:bg-accent/80'
                                                onClick={() => setVendorToEdit(vendor)}
                                            >
                                                Edit Vendor
                                            </button>
                                        ) : (
                                            <button
                                                className='px-[8px] uppercase font-bold rounded text-white dark:text-black bg-blue hover:bg-blue/80 dark:bg-accent dark:hover:bg-accent/80'
                                                onClick={() => setVendorToEdit({ ...defaultVendor, name: v })}
                                            >
                                                Create Vendor
                                            </button>
                                        )} */}
                                    </div>
                                )}
                            </div>

                            {expanded.includes(v) && (
                                <div>
                                    {vendor.order_submission_url &&
                                    vendor.order_submission_url.toLowerCase() !== 'n/a' ? (
                                        <div className={`flex flex-col p-[0px] border-b-[1px] border-darkgrey`}>
                                            <div className='flex justify-between items-center border-b-[1px] border-grey dark:border-darkgrey w-[100%] p-[8px]'>
                                                <h3 className='font-bold text-[16px]'>Order Submission URL</h3>
                                            </div>
                                            <pre className={preStyle + ' p-[8px]'}>{vendor.order_submission_url}</pre>
                                        </div>
                                    ) : (
                                        clickHereToSetUpVendor('Order submission URL', vendor)
                                    )}
                                    {vendor.order_submission_info &&
                                    vendor.order_submission_info.toLowerCase() !== 'n/a' ? (
                                        <div className={`flex flex-col p-[0px] border-b-[1px] border-darkgrey`}>
                                            <div className='flex justify-between items-center border-b-[1px] border-grey dark:border-darkgrey w-[100%] p-[8px]'>
                                                <h3 className='font-bold text-[16px]'>Order Submission Info</h3>
                                            </div>
                                            <pre className={preStyle + ' p-[8px]'}>{vendor.order_submission_info}</pre>
                                        </div>
                                    ) : (
                                        clickHereToSetUpVendor('Order submission info', vendor)
                                    )}
                                    {vendor.payment_info ? (
                                        <div className={`flex flex-col p-[0px] border-b-[1px] border-darkgrey`}>
                                            <div className='flex justify-between items-center border-b-[1px] border-grey dark:border-darkgrey w-[100%] p-[8px]'>
                                                <h3 className='font-bold text-[16px]'>Payment Info</h3>
                                            </div>
                                            <pre className={preStyle + ' p-[8px]'}>{vendor.payment_info}</pre>
                                        </div>
                                    ) : (
                                        clickHereToSetUpVendor('Payment info', vendor)
                                    )}
                                    <div className={`flex flex-col p-[0px] border-darkgrey`}>
                                        <div
                                            className='flex justify-between items-center border-b-[1px] border-grey dark:border-darkgrey w-[100%] p-[8px] cursor-pointer'
                                            onClick={() =>
                                                setExpandedReturnPolicy(
                                                    expandedReturnPolicy.includes(v)
                                                        ? expandedReturnPolicy.filter((exV) => exV !== v)
                                                        : [...expandedReturnPolicy, v]
                                                )
                                            }
                                        >
                                            <h3 className='flex font-bold text-[16px]'>
                                                <span className='w-[16px]'>
                                                    {expandedReturnPolicy.includes(v) ? '-' : '+'}
                                                </span>
                                                Return Policy
                                            </h3>
                                        </div>
                                        {expandedReturnPolicy.includes(v) && (
                                            <div>
                                                {vendor.return_instructions ? (
                                                    <div
                                                        className={`flex flex-col p-[0px] border-darkgrey border-b-[1px]`}
                                                    >
                                                        <div className='flex items-center border-b-[1px] border-grey dark:border-darkgrey w-[100%] p-[8px]'>
                                                            <h3 className='font-bold pl-[16px] text-[16px]'>
                                                                Return Instructions
                                                            </h3>
                                                        </div>
                                                        <pre className={preStyle + ' p-[8px] pl-[24px]'}>
                                                            {vendor.return_instructions}
                                                        </pre>
                                                    </div>
                                                ) : (
                                                    clickHereToSetUpVendor('Return instructions', vendor, true)
                                                )}
                                                {vendor.return_policy_buyers_remorse ? (
                                                    <div
                                                        className={`flex flex-col p-[0px] border-b-[1px] border-darkgrey`}
                                                    >
                                                        <div className='flex items-center border-b-[1px] border-grey dark:border-darkgrey w-[100%] p-[8px]'>
                                                            <h3 className='font-bold pl-[16px] text-[16px]'>
                                                                Buyer's Remorse
                                                            </h3>
                                                        </div>
                                                        <pre className={preStyle + ' p-[8px] pl-[24px]'}>
                                                            {vendor.return_policy_buyers_remorse}
                                                        </pre>
                                                    </div>
                                                ) : (
                                                    <div>{clickHereToSetUpVendor("Buyer's Remorse", vendor, true)}</div>
                                                )}
                                                {vendor.return_policy_doa ? (
                                                    <div
                                                        className={`flex flex-col p-[0px] border-b-[1px] border-darkgrey`}
                                                    >
                                                        <div className='items-center border-b-[1px] border-grey dark:border-darkgrey w-[100%] p-[8px]'>
                                                            <h3 className='font-bold pl-[16px] text-[16px]'>DOA</h3>
                                                        </div>
                                                        <pre className={preStyle + ' p-[8px] pl-[24px]'}>
                                                            {vendor.return_policy_doa}
                                                        </pre>
                                                    </div>
                                                ) : (
                                                    <div>{clickHereToSetUpVendor('DOA', vendor, true)}</div>
                                                )}
                                                {vendor.return_policy_warranty_repair ? (
                                                    <div
                                                        className={`flex flex-col p-[0px] border-b-[1px] border-darkgrey`}
                                                    >
                                                        <div className='flex items-center border-b-[1px] border-grey dark:border-darkgrey w-[100%] p-[8px]'>
                                                            <h3 className='font-bold pl-[16px] text-[16px]'>
                                                                Warranty
                                                            </h3>
                                                        </div>
                                                        <pre className={preStyle + ' p-[8px] pl-[24px]'}>
                                                            {vendor.return_policy_warranty_repair}
                                                        </pre>
                                                    </div>
                                                ) : (
                                                    <div>{clickHereToSetUpVendor('Warranty', vendor, true)}</div>
                                                )}
                                                {vendor.additional_info && (
                                                    <div
                                                        className={`flex flex-col p-[0px] border-b-[1px] border-darkgrey`}
                                                    >
                                                        <div className='flex justify-between items-center border-b-[1px] border-grey dark:border-darkgrey w-[100%] p-[8px]'>
                                                            <h3 className='font-bold text-[16px] pl-[16px]'>
                                                                Additional Info
                                                            </h3>
                                                        </div>
                                                        <pre className={preStyle + ' p-[8px] pl-[24px]'}>
                                                            {vendor.additional_info}
                                                        </pre>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    {vendor.contacts.length > 0 ? (
                                        <>
                                            <div className='flex justify-between items-center border-b-[1px] border-darkgrey w-[100%] p-[8px]'>
                                                <h3 className='font-bold text-[16px]'>Contacts</h3>
                                                <div></div>
                                                {/* <button
                                                    className='flex gap-[3px] items-center px-[8px] uppercase font-bold rounded text-white dark:text-black bg-blue hover:bg-blue/80 dark:bg-accent dark:hover:bg-accent/80'
                                                    onClick={() =>
                                                        setVendorContactToEdit({
                                                            ...defaultVendorContact,
                                                            vendor: v,
                                                        })
                                                    }
                                                >
                                                    <div></div>+
                                                    <span className='inline-block truncate max-w-[150px]'>{v}</span>
                                                    contact
                                                </button> */}
                                            </div>
                                            <VendorContactsList
                                                vendorsInfo={vendorsInfo}
                                                setVendorsInfo={setVendorsInfo}
                                                contacts={vendor.contacts}
                                                setVendorContactToEdit={setVendorContactToEdit}
                                                refreshVendorsList={refreshVendorsList}
                                            />
                                        </>
                                    ) : (
                                        <div className='flex justify-between items-center border-b-[1px] border-darkgrey w-[100%] p-[8px]'>
                                            <h3 className='font-bold text-[16px]'>Contacts</h3>
                                            <div></div>
                                            {/* <button
                                                className='flex gap-[3px] items-center px-[8px] uppercase font-bold rounded text-white dark:text-black bg-blue hover:bg-blue/80 dark:bg-accent dark:hover:bg-accent/80'
                                                onClick={() =>
                                                    setVendorContactToEdit({
                                                        ...defaultVendorContact,
                                                        vendor: v,
                                                    })
                                                }
                                            >
                                                <div></div>+
                                                <span className='inline-block truncate max-w-[150px]'>{v}</span>
                                                contact
                                            </button> */}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )
                })
            )}
        </div>
    )
}
