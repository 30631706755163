import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { NotificationSubscriptionType, StationType } from '../EDIScreens.types'
import { LoadingGear, vFetch } from '../../helpers'
import { FaTrash } from 'react-icons/fa'
import NotificationToggler from './NotificationToggler'

export default function StationModal({
    station,
    setSelectedStation,
    setNeedsRefresh,
    setShowModal,
}: {
    station: StationType
    setSelectedStation: Dispatch<SetStateAction<StationType | undefined>>
    setNeedsRefresh: Dispatch<SetStateAction<boolean>>
    setShowModal: Dispatch<SetStateAction<string | undefined>>
}) {
    const modalRef = useRef(null)
    const [loading, setLoading] = useState(true)
    const [emails, setEmails] = useState<string[]>([])
    const [notificationSubscriptions, setNotificationSubscriptions] = useState<NotificationSubscriptionType[]>([])
    const [editableStation, setEditableStation] = useState(station)
    const [showDelete, setShowDelete] = useState(false)

    const DEFAULT_EMAIL_LIST = ['sales@factorypure.com', 'accounting@factorypure.com', 'orders@factorypure.com']

    const handleChange = ({ target }: { target: HTMLInputElement }) => {
        setEditableStation((previousState) => ({
            ...structuredClone(previousState),
            [target.name]: target.type === 'checkbox' ? target.checked : target.value,
        }))
    }

    const handleCreate = () => {
        setLoading(true)
        vFetch('/v2/edi/stations', {
            method: 'POST',
            body: JSON.stringify({
                station: editableStation,
                notification_subscriptions: notificationSubscriptions,
            }),
            cb: (res: any) => {
                if (res.success) {
                    setSelectedStation(undefined)
                    setShowModal(undefined)
                    setNeedsRefresh(true)
                }
            },
        }).then(() => setLoading(false))
    }

    const handleUpdate = () => {
        setLoading(true)
        vFetch('/v2/edi/stations', {
            method: 'PUT',
            body: JSON.stringify({
                station: editableStation,
                notification_subscriptions: notificationSubscriptions,
            }),
            cb: (res: any) => {
                if (res.success) {
                    setSelectedStation(undefined)
                    setShowModal(undefined)
                    setNeedsRefresh(true)
                }
            },
        }).then(() => setLoading(false))
    }

    const handleDelete = () => {
        setLoading(true)
        vFetch('/v2/edi/stations', {
            method: 'DELETE',
            body: JSON.stringify(editableStation),
            cb: (res: any) => {
                if (res.success) {
                    setSelectedStation(undefined)
                    setShowModal(undefined)
                    setNeedsRefresh(true)
                }
            },
        }).then(() => setLoading(false))
    }

    useEffect(() => {
        Promise.all([
            vFetch(`/v1/users?filter=${encodeURI("roles not like '%suspended%'")}`, {
                cb: (res: any) => {
                    setEmails(Array.from(new Set([...DEFAULT_EMAIL_LIST, ...res.users.map((u: any) => u.email)])))
                },
            }),
            station.id
                ? vFetch(`/v2/edi/notifications?station_id=${station.id}`, {
                      cb: (res: any) => {
                          setNotificationSubscriptions(res.subscriptions)
                      },
                  })
                : null,
        ]).then(() => setLoading(false))
        const listenToWindow = (e: MouseEvent) => {
            if (e.target === modalRef.current) {
                setShowModal(undefined)
                setShowDelete(false)
                setSelectedStation(undefined)
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    useEffect(() => {
        setEditableStation(station)
    }, [station])

    return (
        <div
            ref={modalRef}
            className='fixed z-[50] top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] grid place-items-center'
        >
            <div className='p-[32px] gap-[32px] bg-white dark:bg-darkaccent rounded-[4px] shadow-small relative'>
                {loading && (
                    <div className='absolute top-0 left-0 w-full h-full grid place-items-center bg-white dark:bg-darkaccent z-[5]'>
                        <LoadingGear />
                    </div>
                )}
                <button
                    onClick={() => {
                        setShowModal(undefined)
                        setSelectedStation(undefined)
                        setShowDelete(false)
                    }}
                    className='leading-[1] text-[24px] text-red dark:text-lightred absolute font-bold top-0 right-[8px]'
                >
                    &times;
                </button>
                <div className='relative z-[6] flex flex-col gap-[16px] w-1/2 whitespace-nowrap'>
                    {editableStation.id ? (
                        <h2 className='text-darkgrey dark:text-fire text-[24px] font-bold uppercase leading-[1] m-0 mb-[16px]'>
                            Edit Station
                        </h2>
                    ) : (
                        <h2 className='text-darkgrey dark:text-fire text-[24px] font-bold uppercase leading-[1] m-0 mb-[16px]'>
                            Create Station
                        </h2>
                    )}
                </div>
                <div className='flex gap-[16px]'>
                    <div className='min-w-[400px]'>
                        <div className='flex flex-col gap-[4px] w-full'>
                            <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                Name
                            </label>
                            <input
                                className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                id='name'
                                name='name'
                                onChange={handleChange}
                                value={editableStation.name}
                            />
                        </div>
                        <div className='flex flex-col gap-[4px] w-full mt-[8px]'>
                            <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                EDI ID
                            </label>
                            <input
                                className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                id='edi_id'
                                name='edi_id'
                                onChange={handleChange}
                                value={editableStation.edi_id}
                            />
                        </div>
                        <div className='flex flex-col gap-[4px] w-full mt-[8px]'>
                            <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                Global Notifications
                            </label>
                            <NotificationToggler
                                station_id={station.id}
                                event_type={'GLOBAL_RECEIVED_ACCEPTED'}
                                setNotificationSubscriptions={setNotificationSubscriptions}
                                label='Message Received & Accepted'
                                notifications={notificationSubscriptions.filter(
                                    (ns) => ns.event_type === 'GLOBAL_RECEIVED_ACCEPTED'
                                )}
                                emails={emails}
                            />
                            <NotificationToggler
                                station_id={station.id}
                                event_type={'GLOBAL_RECEIVED_REJECTED'}
                                setNotificationSubscriptions={setNotificationSubscriptions}
                                label='Message Received & Rejected'
                                notifications={notificationSubscriptions.filter(
                                    (ns) => ns.event_type === 'GLOBAL_RECEIVED_REJECTED'
                                )}
                                emails={emails}
                            />
                            <NotificationToggler
                                station_id={station.id}
                                event_type={'GLOBAL_SENT_ACCEPTED'}
                                setNotificationSubscriptions={setNotificationSubscriptions}
                                label='Message Sent & Accepted'
                                notifications={notificationSubscriptions.filter(
                                    (ns) => ns.event_type === 'GLOBAL_SENT_ACCEPTED'
                                )}
                                emails={emails}
                            />
                            <NotificationToggler
                                station_id={station.id}
                                event_type={'GLOBAL_SENT_REJECTED'}
                                setNotificationSubscriptions={setNotificationSubscriptions}
                                label='Message Sent & Rejected'
                                notifications={notificationSubscriptions.filter(
                                    (ns) => ns.event_type === 'GLOBAL_SENT_REJECTED'
                                )}
                                emails={emails}
                            />
                        </div>
                    </div>
                    <div className='min-w-[350px]'>
                        <div className='flex flex-col gap-[4px] w-full'>
                            <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                Document Received Notifications
                            </label>
                            <NotificationToggler
                                station_id={station.id}
                                event_type={'810_INVOICE_RECEIVED'}
                                setNotificationSubscriptions={setNotificationSubscriptions}
                                label='810 Invoice Received'
                                notifications={notificationSubscriptions.filter(
                                    (ns) => ns.event_type === '810_INVOICE_RECEIVED'
                                )}
                                emails={emails}
                            />

                            <NotificationToggler
                                station_id={station.id}
                                event_type={'846_INVENTORY_RECEIVED'}
                                setNotificationSubscriptions={setNotificationSubscriptions}
                                label='846 Inventory Received'
                                notifications={notificationSubscriptions.filter(
                                    (ns) => ns.event_type === '846_INVENTORY_RECEIVED'
                                )}
                                emails={emails}
                            />

                            <NotificationToggler
                                station_id={station.id}
                                event_type={'850_PURCHASE_ORDER_RECEIVED'}
                                setNotificationSubscriptions={setNotificationSubscriptions}
                                label='850 Purchase Order Received'
                                notifications={notificationSubscriptions.filter(
                                    (ns) => ns.event_type === '850_PURCHASE_ORDER_RECEIVED'
                                )}
                                emails={emails}
                            />

                            <NotificationToggler
                                station_id={station.id}
                                event_type={'855_RECEIVED_PURCHASE_ORDER_ACKNOWLEDGED'}
                                setNotificationSubscriptions={setNotificationSubscriptions}
                                label='855 Purchase Order Acknowledged'
                                notifications={notificationSubscriptions.filter(
                                    (ns) => ns.event_type === '855_RECEIVED_PURCHASE_ORDER_ACKNOWLEDGED'
                                )}
                                emails={emails}
                            />
                            <NotificationToggler
                                station_id={station.id}
                                event_type={'855_RECEIVED_PURCHASE_ORDER_REJECTED'}
                                setNotificationSubscriptions={setNotificationSubscriptions}
                                label='855 Purchase Order Rejected'
                                notifications={notificationSubscriptions.filter(
                                    (ns) => ns.event_type === '855_RECEIVED_PURCHASE_ORDER_REJECTED'
                                )}
                                emails={emails}
                            />
                            <NotificationToggler
                                station_id={station.id}
                                event_type={'856_RECEIVED_ADVANCED_SHIP_NOTICE_CREATED'}
                                setNotificationSubscriptions={setNotificationSubscriptions}
                                label='856 Advance Ship Notice Created'
                                notifications={notificationSubscriptions.filter(
                                    (ns) => ns.event_type === '856_RECEIVED_ADVANCED_SHIP_NOTICE_CREATED'
                                )}
                                emails={emails}
                            />
                            <NotificationToggler
                                station_id={station.id}
                                event_type={'856_RECEIVED_ADVANCED_SHIP_NOTICE_REJECTED'}
                                setNotificationSubscriptions={setNotificationSubscriptions}
                                label='856 Advance Ship Notice Failed'
                                notifications={notificationSubscriptions.filter(
                                    (ns) => ns.event_type === '856_RECEIVED_ADVANCED_SHIP_NOTICE_REJECTED'
                                )}
                                emails={emails}
                            />
                        </div>
                    </div>
                    <div className='min-w-[350px]'>
                        <div className='flex flex-col gap-[4px] w-full'>
                            <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                Document Sent Notifications
                            </label>

                            <NotificationToggler
                                station_id={station.id}
                                event_type={'810_INVOICE_SENT'}
                                setNotificationSubscriptions={setNotificationSubscriptions}
                                label='810 Invoice Sent'
                                notifications={notificationSubscriptions.filter(
                                    (ns) => ns.event_type === '810_INVOICE_SENT'
                                )}
                                emails={emails}
                            />

                            <NotificationToggler
                                station_id={station.id}
                                event_type={'846_INVENTORY_SENT'}
                                setNotificationSubscriptions={setNotificationSubscriptions}
                                label='846 Inventory Sent'
                                notifications={notificationSubscriptions.filter(
                                    (ns) => ns.event_type === '846_INVENTORY_SENT'
                                )}
                                emails={emails}
                            />

                            <NotificationToggler
                                station_id={station.id}
                                event_type={'850_PURCHASE_ORDER_SENT'}
                                setNotificationSubscriptions={setNotificationSubscriptions}
                                label='850 Purchase Order Sent'
                                notifications={notificationSubscriptions.filter(
                                    (ns) => ns.event_type === '850_PURCHASE_ORDER_SENT'
                                )}
                                emails={emails}
                            />
                            <NotificationToggler
                                station_id={station.id}
                                event_type={'855_SENT_PURCHASE_ORDER_ACKNOWLEDGED'}
                                setNotificationSubscriptions={setNotificationSubscriptions}
                                label='855 Purchase Order Acknowledged'
                                notifications={notificationSubscriptions.filter(
                                    (ns) => ns.event_type === '855_SENT_PURCHASE_ORDER_ACKNOWLEDGED'
                                )}
                                emails={emails}
                            />
                            <NotificationToggler
                                station_id={station.id}
                                event_type={'855_SENT_PURCHASE_ORDER_REJECTED'}
                                setNotificationSubscriptions={setNotificationSubscriptions}
                                label='855 Purchase Order Rejected'
                                notifications={notificationSubscriptions.filter(
                                    (ns) => ns.event_type === '855_SENT_PURCHASE_ORDER_REJECTED'
                                )}
                                emails={emails}
                            />

                            <NotificationToggler
                                station_id={station.id}
                                event_type={'856_SENT_ADVANCED_SHIP_NOTICE_CREATED'}
                                setNotificationSubscriptions={setNotificationSubscriptions}
                                label='856 Advance Ship Notice Created'
                                notifications={notificationSubscriptions.filter(
                                    (ns) => ns.event_type === '856_SENT_ADVANCED_SHIP_NOTICE_CREATED'
                                )}
                                emails={emails}
                            />
                            <NotificationToggler
                                station_id={station.id}
                                event_type={'856_SENT_ADVANCED_SHIP_NOTICE_REJECTED'}
                                setNotificationSubscriptions={setNotificationSubscriptions}
                                label='856 Advance Ship Notice Failed'
                                notifications={notificationSubscriptions.filter(
                                    (ns) => ns.event_type === '856_SENT_ADVANCED_SHIP_NOTICE_REJECTED'
                                )}
                                emails={emails}
                            />
                        </div>
                    </div>
                </div>
                <div className='flex gap-[16px] justify-center items-center mt-[16px]'>
                    {editableStation.id && (
                        <button className='absolute bottom-[8px] right-[8px] ' onClick={() => setShowDelete(true)}>
                            <FaTrash className='text-red dark:text-lightred' />
                        </button>
                    )}
                    <button
                        onClick={editableStation.id ? handleUpdate : handleCreate}
                        className='block py-[8px] px-[24px] rounded-[4px] font-bold text-white bg-blue dark:text-darkness dark:bg-accent uppercase'
                    >
                        Submit
                    </button>
                </div>
                {showDelete && (
                    <div className='absolute top-0 left-0 w-full h-full z-[5] bg-white dark:bg-darkaccent flex flex-col gap-[16px] items-center justify-center'>
                        <p className='font-bold dark:text-offwhite'>Are you sure? This action cannot be undone.</p>
                        <div className='flex gap-[16px] justify-center items-center'>
                            <button
                                onClick={() => setShowDelete(false)}
                                className='block py-[8px] px-[24px] rounded-[4px] font-bold bg-grey dark:text-offwhite dark:bg-darkgrey uppercase'
                            >
                                Go Back
                            </button>
                            <button
                                onClick={handleDelete}
                                className='block py-[8px] px-[24px] rounded-[4px] font-bold bg-red text-white dark:text-white dark:bg-lightred uppercase'
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

// potentially useful for partners screen
{
    /* <div className='flex flex-col gap-[4px] w-full mt-[8px]'>
    <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
        Accepted Documents (Incoming)
    </label>
    <div className='flex flex-col gap-[4px] justify-between mt-[4px]'>
        <div className='flex gap-[4px] items-center'>
            <input
                className='cursor-pointer'
                id='enabled_810'
                type='checkbox'
                name={'enabled_810'}
                onChange={handleChange}
                value={editableStation.enabled_810}
            />
            <label
                className='cursor-pointer text-darkgrey dark:text-offwhite font-bold text-[14px] uppercase leading-[1]'
                htmlFor='enabled_810'
            >
                810 (Invoice)
            </label>
        </div>
        <div className='flex gap-[4px] items-center'>
            <input
                className='cursor-pointer'
                id='enabled_846'
                type='checkbox'
                name={'enabled_846'}
                onChange={handleChange}
                value={editableStation.enabled_846}
            />
            <label
                className='cursor-pointer text-darkgrey dark:text-offwhite font-bold text-[14px] uppercase leading-[1]'
                htmlFor='enabled_846'
            >
                846 (Inventory)
            </label>
        </div>
        <div className='flex gap-[4px] items-center'>
            <input
                className='cursor-pointer'
                id='enabled_850'
                type='checkbox'
                name={'enabled_850'}
                onChange={handleChange}
                value={editableStation.enabled_850}
            />
            <label
                className='cursor-pointer text-darkgrey dark:text-offwhite font-bold text-[14px] uppercase leading-[1]'
                htmlFor='enabled_850'
            >
                850 (Purchase Order)
            </label>
        </div>
        <div className='flex gap-[4px] items-center'>
            <input
                className='cursor-pointer'
                id='enabled_855'
                type='checkbox'
                name={'enabled_855'}
                onChange={handleChange}
                value={editableStation.enabled_855}
            />
            <label
                className='cursor-pointer text-darkgrey dark:text-offwhite font-bold text-[14px] uppercase leading-[1]'
                htmlFor='enabled_855'
            >
                855 (Purchase Order Acknowledgement)
            </label>
        </div>
        <div className='flex gap-[4px] items-center'>
            <input
                className='cursor-pointer'
                id='enabled_856'
                type='checkbox'
                name={'enabled_856'}
                onChange={handleChange}
                value={editableStation.enabled_856}
            />
            <label
                className='cursor-pointer text-darkgrey dark:text-offwhite font-bold text-[14px] uppercase leading-[1]'
                htmlFor='enabled_856'
            >
                856 (Advanced Ship Notice)
            </label>
        </div>
    </div>
</div> */
}
