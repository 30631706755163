import { colorSettingsReadable } from '../helpers'

export default function ColorPicker({
    tempColors,
    colorSettings,
    handleColorUpdate,
    theme,
}: {
    tempColors: any
    colorSettings: any[]
    handleColorUpdate: Function
    theme: string
}) {
    return (
        <div className='divide-y divide-lightgrey-900'>
            {tempColors &&
                colorSettings.map((setting: string) => {
                    return (
                        <div key={setting} className='grid grid-cols-2 p-1 items-center'>
                            <p>{colorSettingsReadable[setting]}</p>
                            <div className='grid grid-cols-2 items-center'>
                                <label htmlFor={setting}>{tempColors[setting]}</label>
                                <div
                                    className={`border ${
                                        theme === '' ? 'border-black' : 'border-white'
                                    } w-fit h-[34px]`}
                                >
                                    <input
                                        onChange={(e) => handleColorUpdate(setting, e)}
                                        type='color'
                                        id={setting}
                                        value={tempColors[setting]}
                                    ></input>
                                </div>
                            </div>
                        </div>
                    )
                })}
        </div>
    )
}
