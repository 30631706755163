import { NavLink, useNavigate } from 'react-router-dom'
import { TaskEditInit } from '../tasks.types'
import { taskVariables } from '../TaskVariables'
import { orderOrDraftOrderId } from '../tasks.helpers'

type TaskPreviewNavBlockProps = {
    task: TaskEditInit
    handleClick?: Function
}

export default function TaskPreviewNavBlock({ task, handleClick }: TaskPreviewNavBlockProps) {
    const navigate = useNavigate()
    const { taskTypeColors }: any = taskVariables
    const labelStyle = 'block font-bold text-[12px] uppercase'

    if (!handleClick) handleClick = navigate

    function handleTaskClick(e: any, t: TaskEditInit) {
        e.preventDefault()
        if (handleClick) return handleClick(e, t)
        navigate(orderOrDraftOrderId(t))
    }

    return (
        <NavLink
            className='flex flex-col js-topbar__order__task  hover:bg-darkness/20 dark:hover:bg-darkness/40 cursor-pointer p-[4px] rounded truncate max-w-[500px] font-normal'
            onClick={(e) => handleTaskClick(e, task)}
            to={`/tasks/${task.id}`}
        >
            <div className='flex gap-[4px]'>
                <label className={`${taskTypeColors[task.task_type]} ${labelStyle} cursor-pointer`}>
                    {task.task_type
                        .split(' ')
                        .map((word: string) => word[0])
                        .join('')}
                    :
                </label>
                <p className='text-[12px]'>{task.status}</p>
            </div>
            <p className='text-[12px] truncate'>{task.title}</p>
            <a className='text-[12px] font-semibold text-blue dark:text-accent'>{task.id}</a>
        </NavLink>
    )
}
