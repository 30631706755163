import { Dispatch, SetStateAction } from 'react'

export default function SupplierProductRow({
    product,
    selectedProducts,
    setSelectedProducts,
}: {
    product: any
    selectedProducts: any[]
    setSelectedProducts: Dispatch<SetStateAction<any[]>>
}) {
    const isSelected = !!selectedProducts.find((p) => p.id === product.id)
    const toggleSelected = () => {
        setSelectedProducts((prev: any) => {
            const newState = structuredClone(prev)
            if (newState.find((p: any) => p.id === product.id)) {
                return newState.filter((p: any) => p.id !== product.id)
            }
            newState.push(product)
            return newState
        })
    }
    return (
        <button
            onClick={toggleSelected}
            className='text-left flex gap-2 items-center p-2 border-b border-lightgrey dark:border-darkgrey'
        >
            <span
                className={`w-4 h-4 rounded border border-lighgrey dark:border-darkgrey ${
                    isSelected ? 'bg-accent1 dark:bg-darkaccent1' : ''
                }`}
            ></span>
            <span>{product.title}</span>
        </button>
    )
}
