import { useEffect, useState } from 'react'
import { closePopout } from '../../helpers'
import { useNavigate, useParams } from 'react-router'
import { OrderInit } from '../orders.types'
import { AiFillCaretDown } from 'react-icons/ai'

type OrderActionsProps = {
    actions: any[]
    addNewTask: Function
    order: OrderInit
}

export default function OrderActions({ actions, addNewTask, order }: OrderActionsProps) {
    const navigate = useNavigate()
    const { orderId } = useParams()
    const [showActions, setShowActions] = useState<boolean>(false)

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => closePopout(e, ['js-order__actions'], setShowActions)
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <div className='flex font-semibold [&>button]:rounded gap-[8px]'>
            <button
                className='px-2 py-1 text-sm h-fit border-[1px] border-black dark:border-darkgrey hover:bg-offwhite dark:hover:bg-darkaccent'
                onContextMenu={() => {
                    window.open(`/tasks/new?order_id=${orderId}`)
                }}
                onClick={() => addNewTask()}
            >
                Create Task
            </button>
            <button
                className='px-2 py-1 text-sm h-fit border-[1px] border-black dark:border-darkgrey hover:bg-offwhite dark:hover:bg-darkaccent'
                onContextMenu={() => {
                    window.open(`/orders/purchase-orders/new?orders=${orderId}`)
                }}
                onClick={() => {
                    ;(window.event as MouseEvent).ctrlKey
                        ? window.open(`/orders/purchase-orders/new?orders=${orderId}`)
                        : navigate(`/orders/purchase-orders/new?orders=${orderId}`)
                }}
            >
                Create Purchase Order
            </button>
            <button
                className='px-2 py-1 text-sm h-fit border-[1px] border-black dark:border-darkgrey hover:bg-offwhite dark:hover:bg-darkaccent'
                onClick={() => {
                    window.open(`https://factorypure.myshopify.com/admin/orders/${orderId}/refund`, '_blank')
                }}
            >
                {order.financial_status === 'paid' ? 'Refund' : 'Restock'}
            </button>
            <button
                className='px-2 py-1 text-sm h-fit border-[1px] border-black dark:border-darkgrey hover:bg-offwhite dark:hover:bg-darkaccent'
                onClick={() => {
                    window.open(`https://factorypure.myshopify.com/admin/orders/${orderId}/edit`, '_blank')
                }}
            >
                Edit
            </button>
            {actions.length > 0 && (
                <div className='js-order__actions relative'>
                    <button
                        className='js-order__actions flex gap-[8px] items-center px-[16px] py-[8px] hover:bg-offwhite dark:hover:bg-darkaccent rounded'
                        onClick={() => {
                            !showActions ? setShowActions(true) : setShowActions(false)
                        }}
                    >
                        <span className='js-order__actions'>. . .</span>
                        <AiFillCaretDown className='pointer-events-none' />
                    </button>
                    {showActions && (
                        <div className='js-order__actions absolute flex flex-col top-[calc(100%+4px)] right-0 min-w-[200px] p-[8px] bg-bg1 dark:bg-darkbg1 border-[1px] dark:border-darkgrey rounded shadow-small'>
                            {actions.map((action: any) => (
                                <p
                                    onClick={action.action}
                                    className='p-[8px] w-[100%] cursor-pointer hover:bg-bg1 dark:hover:bg-darkbg2 rounded'
                                >
                                    {action.name}
                                </p>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
