import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'
import * as auditService from '../../audit/api/service'

export const taxExemptions = createQueryKeys('taxExemptions', {
    list: (filters: any, search?: string) => ({
        queryKey: [filters, search],
        queryFn: () => service.getTaxExemptions(filters, search),
    }),
    detail: (taxExemptionId: any) => ({
        queryKey: [taxExemptionId],
        queryFn: () => service.getTaxExemptionDetail(taxExemptionId),
    }),
    forms: (taxExemptionId: any) => ({
        queryKey: [taxExemptionId],
        queryFn: () => service.getTaxExemptionForms(taxExemptionId),
    }),
    audit: (table: string, taxExemptionId: any, parent_id: number) => ({
        queryKey: [table, taxExemptionId, parent_id],
        queryFn: () => auditService.getAuditHistory(table, taxExemptionId, parent_id),
    }),
})

export const taxExemptionQueries = mergeQueryKeys(taxExemptions)
