import { useQuery } from '@tanstack/react-query'
import { topMoversQueries } from './MoversQueryKeys'

//company detail

export function useGetTopMoversQuery(period: any): any {
    const query = useQuery({
        ...topMoversQueries.top_movers.topMovers(period),
        staleTime: 45000,
        enabled: Boolean(period),
    })

    return query
}
