import { CrossCircledIcon, StopwatchIcon } from '@radix-ui/react-icons'
import { format } from 'date-fns'
import { useContext, useEffect, useState } from 'react'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import { GrRefresh } from 'react-icons/gr'
import { useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { CalendarProcurementTasks } from '../../../custom_components/CalendarProcurementTasks'
import { NoResults } from '../../../custom_components/NoResults'
import { FilterBarSelectorButton } from '../../../custom_components/component_Basics/FilterBarSelectorButton'
import { cn, useDebounce } from '../../../helpers'
import { useTasksQuery, useTasksQueryCalendar, useTasksSearchQuery } from '../../api/useQueries'
import { DATE_TODAY, TASK_STATUS_OPTIONS, defaultTaskFilterParams } from '../../constants'
import { ProcurementContext, ProcurementContextType, getFilterBarUsersActiveFirst } from '../../helpers'
import FilterToolBar from '../FilterToolBar'
import TaskListItem from '../listItems/TaskListItem'
import TaskListItemSkeleton from '../listItems/TaskListItemSkeleton'

export default function TaskListV2({
    company,
    userSelectedTaskFilterValues,
    setUserSelectedTaskFilterValues,
    inModal,
}: {
    company: any
    userSelectedTaskFilterValues: any
    setUserSelectedTaskFilterValues: Function
    inModal?: boolean
}) {
    const user = useSelector<any, any>((state) => state.user)
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const urlParamTaskId = searchParams.get('task_id')
    const { users } = useContext<ProcurementContextType>(ProcurementContext)
    const [page, setPage] = useState(1)
    const [cursorList, setCursorList] = useState<any>([0])
    const [selectedLastCursor, setSelectedLastCursor] = useState<any>(undefined)
    const [search, setSearch] = useState(urlParamTaskId || '')
    const debouncedSearch = useDebounce(search, 300)
    const skeletons = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]

    const [taskDueDate, setTaskDueDate] = useState<Date | undefined>(DATE_TODAY)

    useEffect(() => {
        if (search === '' && urlParamTaskId) {
            navigate('/procurement')
        }
    }, [search])

    useEffect(() => {
        const selectedValues = new Set(userSelectedTaskFilterValues.due_at_options)
        if (taskDueDate === undefined) {
            setUserSelectedTaskFilterValues((prev: any) => {
                if (selectedValues.has('custom')) {
                    selectedValues.delete('custom')
                }
                const newValues = Array.from(selectedValues)
                return { ...prev, due_at_options: newValues }
            })
        } else if (taskDueDate !== DATE_TODAY) {
            setUserSelectedTaskFilterValues((prev: any) => {
                if (!selectedValues.has('custom')) {
                    selectedValues.add('custom')
                }
                const newValues = Array.from(selectedValues)
                return { ...prev, due_at_options: newValues }
            })
        }
    }, [taskDueDate])

    const taskFilterBarObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: getFilterBarUsersActiveFirst(user, users),
                title: 'Users',
                field: 'users',
                values: userSelectedTaskFilterValues.users,
                searchToggle: true,
            },
            {
                component: FilterBarSelectorButton,
                options: TASK_STATUS_OPTIONS,
                title: 'Status',
                field: 'statuses',
                values: userSelectedTaskFilterValues.statuses,
                searchToggle: false,
            },
            {
                component: FilterBarSelectorButton,
                options: [
                    {
                        value: 'overdue',
                        label: 'Overdue',
                        icon: CrossCircledIcon,
                    },
                    {
                        value: 'custom',
                        label: taskDueDate ? `Due ${format(taskDueDate, 'P')}` : 'Select Date',
                        icon: StopwatchIcon,
                    },
                ],
                title: 'Due At',
                field: 'due_at_options',
                values: userSelectedTaskFilterValues.due_at_options,
                searchToggle: false,
            },
        ],
        setFunction: setUserSelectedTaskFilterValues,
        resetFunction: () => {
            setTaskDueDate(DATE_TODAY)
            setUserSelectedTaskFilterValues({
                statuses: [],
                users: [],
                due_at_options: [],
            })
        },
    }

    useEffect(() => {
        setPage(1)
        setCursorList([0])
    }, [search, userSelectedTaskFilterValues, taskDueDate])

    let params: any = {
        filters: {
            ...defaultTaskFilterParams,
            company_id: company?.id || undefined,
            id: undefined,
            search: urlParamTaskId || debouncedSearch,
            lastCursor: selectedLastCursor || undefined,
            statuses: userSelectedTaskFilterValues.statuses,
            user_ids: userSelectedTaskFilterValues.users,
            due_at_options: userSelectedTaskFilterValues.due_at_options,
            selectedTaskDueDate: userSelectedTaskFilterValues.due_at_options.includes('custom')
                ? taskDueDate?.toISOString()
                : DATE_TODAY.toISOString(),
            pageParam: page,
        },
    }

    const preFetchCalendarquery = useTasksQueryCalendar({ userId: user.id, day: null })

    const tasksQuery = useTasksQuery({ ...params, search: search })
    const { isLoading: loading, isFetching: fetching } = tasksQuery
    const { tasks: tasksNoSearch, lastCursor } = tasksQuery?.data || {}

    const tasksSearchQuery = useTasksSearchQuery({ ...params, search: search })
    const { isLoading: searchLoading, isFetching: searchFetching } = tasksSearchQuery
    const { tasks: tasksSearch, lastCursor: lastCursorSearch } = tasksSearchQuery?.data || {}

    const tasks = debouncedSearch ? tasksSearch : tasksNoSearch
    const isFetching = debouncedSearch ? searchFetching : fetching
    const isLoading = debouncedSearch ? searchLoading : loading
    const currentCursor = debouncedSearch ? lastCursorSearch : lastCursor

    if (currentCursor && !(currentCursor[0] === null)) {
        if (!cursorList.includes(currentCursor)) {
            setCursorList((prev: any[]) => {
                return [...prev, currentCursor]
            })
        }
    }

    function handlePageIncrease() {
        if (!(currentCursor[0] === null)) {
            setSelectedLastCursor(currentCursor)
            setPage((prev: any) => {
                return prev + 1
            })
        }
    }
    function handlePageDecrease() {
        setSelectedLastCursor(cursorList[page - 2] || undefined)
        setPage((prev: any) => Math.max(prev - 1, 1))
    }

    return (
        <>
            <div className={cn('sticky z-40 px-2 top-[50px] bg-bg1 dark:bg-darkbg1', inModal && 'top-[33px]')}>
                <FilterToolBar search={search} setSearch={setSearch} filterBarObject={taskFilterBarObject}>
                    {' '}
                    {taskFilterBarObject.params.length &&
                        taskFilterBarObject.params.map((param: any) => {
                            return (
                                <param.component
                                    key={param.field}
                                    searchToggle={param.searchToggle}
                                    title={param.title}
                                    field={param.field}
                                    options={param.options}
                                    filterValues={param.values}
                                    setFilterValues={taskFilterBarObject.setFunction}
                                />
                            )
                        })}
                    <CalendarProcurementTasks date={taskDueDate} setDate={setTaskDueDate} />
                </FilterToolBar>
            </div>
            <div
                className={cn(
                    'sticky z-[25] px-2 top-[90px] grid grid-cols-[36px_24px_1.5fr_1fr_.5fr_28px_1fr_1fr] col-span-full border-b border-lightgrey bg-blue text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness',
                    inModal && 'top-[74px]'
                )}
            >
                <div className='font-bold dark:text-offwhite py-2 uppercase text-[12px]'>Status</div>
                <div></div>
                <div className='font-bold dark:text-offwhite py-2 uppercase text-[12px]'>Name</div>
                <div className='font-bold dark:text-offwhite py-2 uppercase text-[12px]'>Assigned</div>
                <div className='font-bold dark:text-offwhite py-2 uppercase text-[12px]'>Type</div>
                <div className='font-bold dark:text-offwhite py-2 uppercase text-[12px]'></div>
                <div className='font-bold dark:text-offwhite py-2 uppercase text-[12px]'>Due</div>
                <div className='font-bold dark:text-offwhite py-2 uppercase text-[12px]'></div>
                <div className='absolute right-4 top-1/4'>
                    <GrRefresh
                        className={cn(
                            isFetching && 'animate-spin opacity-100',
                            !isFetching && 'opacity-0',
                            '[&>*]:stroke-offwhite [&>*]:dark:stroke-offwhite '
                        )}
                    />
                </div>
            </div>
            <div className='p-0 pl-0 overflow-auto min-h-[50vh]'>
                <div className='mb-[8px] w-full flex gap-[16px]'></div>
                {(isLoading || searchFetching) && (
                    <div className='flex flex-col gap-[8px]'>
                        {skeletons.map((skeleton) => {
                            return <TaskListItemSkeleton key={skeleton} />
                        })}
                    </div>
                )}
                <div className='flex flex-col gap-[8px] overflow-visible'>
                    {!isLoading &&
                        search &&
                        tasksSearch?.map((task: any) => (
                            <TaskListItem
                                key={task.id + search}
                                task={task}
                                urlParamTaskId={urlParamTaskId || undefined}
                            />
                        ))}
                    {!isLoading &&
                        !search &&
                        tasksNoSearch?.map((task: any) => (
                            <TaskListItem key={task.id + 'no search'} task={task} urlParamTaskId={undefined} />
                        ))}
                </div>
                {!isFetching && tasks?.length === 0 && (
                    <NoResults margin='mx-auto' content={`No Results For Selected Filters`} />
                )}
                <div className='flex fixed bottom-[8px] left-[calc(50%+216px)] translate-x-[calc(-50%-108px)] p-[8px] bg-white dark:bg-darkness border-[1px] border-darkgrey gap-[16px] justify-center items-center mt-[16px] rounded'>
                    <>
                        <button
                            disabled={page === 1}
                            className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                            onClick={() => {
                                handlePageDecrease()
                            }}
                        >
                            <BiLeftArrowAlt className='fill-darkgrey dark:fill-accent' />
                        </button>
                    </>
                    <div className='font-bold dark:text-offwhite'>Current Page: {page}</div>
                    <button
                        className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                        onClick={() => {
                            handlePageIncrease()
                        }}
                        disabled={isFetching || tasks?.length < 20}
                    >
                        <BiRightArrowAlt className='fill-darkgrey dark:fill-accent' />
                    </button>
                </div>
            </div>
        </>
    )
}
