import { useEffect, useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import CreateTicket from '../development/quickModals/CreateTicket'
import { setSettings } from '../redux/settings'
import { useNavigate } from 'react-router-dom'

export default function KnowledgeScreenFallback() {
    const modalRef = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { showKnowledgeScreen } = useSelector((state: any) => state.settings)

    useEffect(() => {
        if (showKnowledgeScreen) {
            document.body.classList.add('overflow-y-hidden')
        }
        return () => document.body.classList.remove('overflow-y-hidden')
    }, [showKnowledgeScreen])

    // if user clicks on margin around page, it will close
    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (e.target === modalRef.current) {
                navigate(`${window.location.pathname}`)
                dispatch(setSettings({ showKnowledgeScreen: false }))
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    const Sorry = () => {
        const [colors, setColors] = useState({
            s: { r: 100, g: 10, b: 10, dir: 'up' },
            o: { r: 100, g: 30, b: 30, dir: 'up' },
            r: { r: 100, g: 50, b: 50, dir: 'up' },
            // r: { r: 100, g: 70, b: 70, dir: 'up' },
            y: { r: 100, g: 90, b: 90, dir: 'up' },
        })
        useEffect(() => {
            const colorChanger = setInterval(() => {
                setColors((previousState) => {
                    const newState = { ...previousState }
                    Object.entries(newState).forEach(([key, val]) => {
                        if (val.g > 100 && val.dir === 'up') {
                            val.dir = 'down'
                        } else if (val.g < 10 && val.dir === 'down') {
                            val.dir = 'up'
                        }
                        val.g = val.dir === 'up' ? val.g + 10 : val.g - 10
                    })
                    return newState
                })
            }, 100)

            return () => clearInterval(colorChanger)
        }, [])

        return (
            <p>
                <span
                    className='transition-all duration-[500ms] font-bold text-[48px]'
                    style={{ color: Math.random() > 0.5 ? 'orange' : 'darkseagreen' }}
                >
                    S
                </span>
                <span
                    className='transition-all duration-[500ms] font-bold text-[48px]'
                    style={{ color: Math.random() > 0.5 ? 'orange' : 'darkseagreen' }}
                >
                    O
                </span>
                <span
                    className='transition-all duration-[500ms] font-bold text-[48px]'
                    style={{ color: Math.random() > 0.5 ? 'orange' : 'darkseagreen' }}
                >
                    R
                </span>
                <span
                    className='transition-all duration-[500ms] font-bold text-[48px]'
                    style={{ color: Math.random() > 0.5 ? 'orange' : 'darkseagreen' }}
                >
                    R
                </span>
                <span
                    className='transition-all duration-[500ms] font-bold text-[48px]'
                    style={{ color: Math.random() > 0.5 ? 'orange' : 'darkseagreen' }}
                >
                    Y
                </span>
            </p>
        )
    }
    const Fallback = () => {
        const [quickModal, setQuickModal] = useState<any>()
        return (
            <div className='w-full h-[calc(100vh-250px)] grid place-items-center'>
                <div className='text-center p-[16px] rounded-[4px] w-fit mx-auto'>
                    <Sorry />
                    <h1 className='dark:text-white font-bold text-[32px] mb-4'>Something went wrong!</h1>
                    <button className='font-semibold underline' onClick={() => setQuickModal('ticket')}>
                        Report this bug?
                    </button>
                </div>
                {quickModal === 'ticket' && <CreateTicket setQuickModal={setQuickModal} />}
            </div>
        )
    }

    return (
        <div
            ref={modalRef}
            className='dark:text-offwhite fixed top-0 left-0 w-full h-[100vh] bg-[rgb(0,0,0,0.5)] flex items-center justify-center z-[50]'
        >
            <div className='flex relative rounded bg-white dark:bg-darkaccent w-[95%] h-[95%]'>
                <button
                    className='absolute top-0 right-0'
                    onClick={() => {
                        navigate(`${window.location.pathname}`)
                        return dispatch(setSettings({ showKnowledgeScreen: false }))
                    }}
                >
                    <FaTimes className='text-red w-7 h-7' />
                </button>
                <Fallback />
            </div>
        </div>
    )
}
