import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'

export const suppliers = createQueryKeys('suppliers', {
    list: (filters: any) => ({
        queryKey: [filters],
        queryFn: () => service.getSuppliers(filters),
    }),
    products: ({ supplier_id, search }: { supplier_id: number; search?: string }) => ({
        queryKey: [{ supplier_id, search }],
        queryFn: () => service.getSupplierProducts({ supplier_id, search }),
    }),
    allProducts: (filters: { vendor?: string; search?: string }) => ({
        queryKey: [filters],
        queryFn: () => service.getAllProducts(filters),
    }),
})

export const queryKeys = mergeQueryKeys(suppliers)
