import { createSlice } from '@reduxjs/toolkit'
const productPinSlice = createSlice({
    name: 'productPin',
    initialState: {
        id: undefined,
        sku: undefined,
        open: false,
    },
    reducers: {
        setProductPin: (state, action) => {
            const payload = action.payload
            const newState = { ...state, ...payload }
            return newState
        },
    },
})

export const getProductPin = (state) => state.productPin
export const { setProductPin } = productPinSlice.actions
export default productPinSlice.reducer
