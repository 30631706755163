import { Dispatch, SetStateAction } from 'react'
import { AiFillInfoCircle } from 'react-icons/ai'
import { isPrimObject } from '../helpers'

type ReactOptionProps = {
    option: any
    setSelected: Dispatch<SetStateAction<any>>
    iconSide?: 'left' | 'right'
    hovered: any
    setHovered: Dispatch<SetStateAction<any>>
}

export default function ReactOption({
    option,
    iconSide = 'right',
    setSelected = () => {},
    hovered,
    setHovered = () => {},
}: ReactOptionProps) {
    const optionText = isPrimObject(option) ? option.option : option
    const value = isPrimObject(option) ? option.value || option.option : option
    const symbol = isPrimObject(option) ? option.symbol : null

    return (
        <div
            onClick={() => setSelected(value)}
            onMouseOver={() => setHovered(option)}
            className={`${
                hovered === option ? 'js-select__option--checked' : ''
            } relative w-full font-bai flex px-[8px] gap-[4px] items-center justify-between cursor-pointer hover:bg-[#1967d2] hover:text-white`}
        >
            <div className='flex items-center pointer-events-none gap-[4px]'>
                {iconSide === 'left' && symbol}
                <span className='pointer-events-none whitespace-nowrap h-[24px]' id={`js-filter-option-${option.id}`}>
                    {optionText}
                </span>
            </div>
            {iconSide === 'right' && symbol}
        </div>
    )
}
