import { parseResObject, signatureHTMLString, vFetch } from '../../helpers'
import { UserInit } from '../../users/users.types'

// queries/companies.ts
const COMPANIES_BASE_URL = '/v1/procurement/companies'

export const createPrivateEvent = async ({
    privateEventUrl,
    id,
    user_id,
}: {
    privateEventUrl: string
    id: number | string
    user_id: number
}) => {
    vFetch(`${privateEventUrl}`, {
        reThrowErr: true,
        skipToast: true,
        method: 'POST',
        body: JSON.stringify({
            id,
            user_id,
            event_type: 'viewed',
        }),
    })
}

export const getPrivateEvents = async ({
    privateEventUrl,
    element,
    id,
}: {
    privateEventUrl: string
    element: string
    id: number
}) => {
    return await vFetch(`${privateEventUrl}?${element}=${id}`, {
        reThrowErr: true,
    })
}
