import {
    ChangelogRelease,
    ChangelogEntry,
    ChangelogType,
    CreateChangelogOptions,
    CreateChangelogEntryOptions,
    CreateChangelogReleaseOptions,
    UpdateChangelogReleaseOptions,
    UpdateChangelogEntryOptions,
} from 'changelog/changelogs/Changelog.types'
import { vFetch } from 'helpers'

export const createChangelog = async (changelog: CreateChangelogOptions) => {
    return vFetch(`/v2/changelogs`, {
        method: 'POST',
        body: JSON.stringify(changelog),
        reThrowErr: true,
    })
}

export const getChangelogs = async (
    filters: any
): Promise<{
    success: boolean
    changelogs: ChangelogType[]
}> => {
    const params = Object.entries(filters)
        .filter(([_key, val]) => val === 0 || val)
        .map(([key, val]) => `${key}=${val}`)
        .join('&')
    return vFetch(`/v2/changelogs${params ? `?${params}` : ''}`)
}

export const createRelease = async (release: CreateChangelogReleaseOptions) => {
    return vFetch(`/v2/changelogs/releases`, {
        method: 'POST',
        body: JSON.stringify(release),
        reThrowErr: true,
    })
}

export const getReleases = async (
    filters: any
): Promise<{
    success: boolean
    releases: ChangelogRelease[]
}> => {
    const params = Object.entries(filters)
        .filter(([_key, val]) => val === 0 || val)
        .map(([key, val]) => `${key}=${val}`)
        .join('&')
    return vFetch(`/v2/changelogs/releases${params ? `?${params}` : ''}`)
}

export const updateRelease = async (release: UpdateChangelogReleaseOptions) => {
    return vFetch(`/v2/changelogs/releases`, {
        method: 'PUT',
        body: JSON.stringify(release),
        reThrowErr: true,
    })
}

export const createEntry = async (entry: CreateChangelogEntryOptions) => {
    return vFetch(`/v2/changelogs/entries`, {
        method: 'POST',
        body: JSON.stringify(entry),
        reThrowErr: true,
    })
}

export const getEntries = async (
    filters: any
): Promise<{
    success: boolean
    entries: ChangelogEntry[]
}> => {
    const params = Object.entries(filters)
        .filter(([_key, val]) => val === 0 || val)
        .map(([key, val]) => `${key}=${val}`)
        .join('&')
    return vFetch(`/v2/changelogs/entries${params ? `?${params}` : ''}`)
}

export const updateEntry = async (entry: UpdateChangelogEntryOptions) => {
    return vFetch(`/v2/changelogs/entries`, {
        method: 'PUT',
        body: JSON.stringify(entry),
        reThrowErr: true,
    })
}

export const deleteEntry = async (entryId: number) => {
    return vFetch(`/v2/changelogs/entries`, {
        method: 'DELETE',
        body: JSON.stringify({ id: entryId }),
        reThrowErr: true,
    })
}
