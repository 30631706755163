import { vFetch } from "../../helpers"
import { CompetitorProductsFiltersType } from "../Competitor.types"

export const getCompetitorProducts = async (filters: CompetitorProductsFiltersType = {}) => {
    const queryParams = Object.entries(filters).map(([key, val]) => `${key}=${val}`).join("&")
    return await vFetch(`/v1/competitors/products${queryParams.length ? `?${queryParams}`: ''}`, {
        cb: (res: any) => {
            if (!res.success) {
                return []
            }
            return res.products
        }
    })
}