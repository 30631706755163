import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { createColumnHelper } from '@tanstack/react-table'
import Table from 'analytics/reports/Table'
import RefreshButton from 'custom_components/RefreshButton'
import { formatDate } from 'procurement/helpers'
import { productSetupQueries } from 'productSetupV2/api/productSetupQueryKeys'
import { useGetInventoryEmailsQuery } from 'productSetupV2/api/useQueries'
import React, { useEffect, useState } from 'react'
import { BsClockHistory } from 'react-icons/bs'
import { IoMdCheckmarkCircle } from 'react-icons/io'
import { MdOutlineCancel } from 'react-icons/md'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { cn } from '../helpers'
import { InventoryEmailType } from './EDIScreens.types'
import InventoryEmailsSearchBarContainer from './InventoryEmailsSearchBarContainer'
import { LuArrowLeftToLine } from 'react-icons/lu'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'

export default function InventoryEmails() {
    const [searchParams, setSearchParams] = useSearchParams()
    const [search, setSearch] = useState('')
    const location = useLocation()
    const [initRender, setInitRender] = useState(true)

    const after = searchParams.get('after')
    const before = searchParams.get('before')
    const searchParam = searchParams.get('search') || ''
    const page = searchParams.get('page') || 1
    const vendorsString = searchParams.get('vendors') || ''
    const statusesString = searchParams.get('status') || ''
    const [cursorList, setCursorList] = useState<any>([0])

    const vendors = decodeURIComponent(vendorsString)
        .split(',')
        .filter((v) => v)

    const statuses = decodeURIComponent(statusesString)
        .split(',')
        .filter((v) => v)

    const inventoryEmailsQuery = useGetInventoryEmailsQuery({
        filters: { vendors, statuses, after, before, page },
        search,
    })
    const { inventoryEmails }: { inventoryEmails: InventoryEmailType[] } = inventoryEmailsQuery?.data || {}

    function sortNewestFirst(a: any, b: any) {
        return new Date(b.delivered_at).getTime() - new Date(a.delivered_at).getTime()
    }

    inventoryEmails?.sort(sortNewestFirst)

    useEffect(() => {
        if (initRender) {
            setInitRender(false)
        }
    }, [])

    useEffect(() => {
        if (!initRender) {
            search ? searchParams.set('search', search) : searchParams.delete('search')
            setSearchParams(searchParams, { replace: true })
        }
    }, [search])
    useEffect(() => {
        if (!initRender) {
            setSearch(searchParam)
        }
    }, [searchParam])

    if (!inventoryEmailsQuery.isFetching && !inventoryEmailsQuery.isLoading) {
        if (after && !(after[2] === null)) {
            if (!cursorList.includes(after)) {
                setCursorList((prev: any[]) => {
                    return [...prev, after]
                })
            }
        }
    }

    function handlePageIncrease() {
        if (inventoryEmails?.length === 50) {
            const newPage = (typeof page === 'string' ? parseInt(page) : page) + 1
            setSearchParams((prev: any) => {
                prev.delete('before')
                prev.set('after', inventoryEmails[49].id)
                prev.set('page', newPage.toString())
                return prev
            })
        }
    }
    function handlePageDecrease() {
        if (typeof page === 'string' ? parseInt(page) : page > 1) {
            const newPage = (typeof page === 'string' ? parseInt(page) : page) - 1
            setSearchParams((prev: any) => {
                prev.delete('after')
                prev.set('before', inventoryEmails[0].id)
                prev.set('page', newPage.toString())
                return prev
            })
        }
    }
    function handleFirstPage() {
        if (typeof page === 'string' ? parseInt(page) : page > 1) {
            setSearchParams((prev: URLSearchParams) => {
                prev.delete('after')
                prev.delete('before')
                prev.set('page', '1')
                return prev
            })
        }
    }

    const tableData = inventoryEmails?.length > 0 ? inventoryEmails : []
    const tableDataMemo = React.useMemo(() => tableData ?? [], [tableData])
    const columnHelper = createColumnHelper<InventoryEmailType>()

    const productsColumns = [
        {
            header: ' ',

            cell: ({ row }: { row: any }) => {
                let status = row.original.status
                const created_at = row.original.delivered_at
                if (status === 'In Progress' && Date.now() - Number(new Date(created_at)) > 3600000) {
                    status = 'Failed'
                }
                return (
                    <div className='flex justify-center'>
                        {status === 'Cancelled' || status === 'Failed' ? (
                            <MdOutlineCancel className=' text-lightred w-[24px] h-[24px]' />
                        ) : status == 'Success' ? (
                            <IoMdCheckmarkCircle className=' text-lime w-[24px] h-[24px]' />
                        ) : (
                            <BsClockHistory className=' text-fire w-[24px] h-[24px]' />
                        )}
                    </div>
                )
            },
            size: 10,
        },
        // {
        //     enableGlobalFilter: false,
        //     id: 'select-col',
        //     header: ({ table }: { table: any }) => (
        //         <div className='flex items-center justify-center mx-auto'>
        //             <input
        //                 className='h-[15px] w-[15px]'
        //                 type='checkbox'
        //                 checked={table.getIsAllRowsSelected()}
        //                 onChange={table.getToggleAllRowsSelectedHandler()}
        //             />
        //         </div>
        //     ),
        //     cell: ({ row }: { row: any }) => (
        //         <div
        //             onClick={row.getToggleSelectedHandler()}
        //             className='h-full w-fit flex items-center justify-center mx-auto'
        //         >
        //             <input
        //                 className='h-[15px] w-[15px] '
        //                 type='checkbox'
        //                 checked={row.getIsSelected()}
        //                 disabled={!row.getCanSelect()}
        //                 onChange={row.getToggleSelectedHandler()}
        //             />
        //         </div>
        //     ),
        //     size: 20,
        // },

        columnHelper.accessor('status', {
            header: 'Status',
            cell: (info) => {
                let status = info.row.original.status
                const updateFailures = info?.row?.original?.update_failures
                let delivered_at = info.row.original.delivered_at
                if (status === 'In Progress' && Date.now() - Number(new Date(delivered_at)) > 3600000) {
                    status = 'Failed'
                }
                return (
                    <Link
                        draggable={false}
                        to={`/products/inventory-emails/${info.row.original.id}`}
                        state={{ previousLocationPathname: location.pathname }}
                        className={cn('flex gap-1 items-center')}
                    >
                        {status}
                        {updateFailures && updateFailures.length > 0 && (
                            <div className='group relative'>
                                <ExclamationTriangleIcon className='text-danger z-[4]' />
                                <div className='hidden group-hover:flex border absolute top-[100%] left-0 w-fit whitespace-nowrap bg-white shadow-small text-sm dark:bg-darkbg1 p-2 rounded-[4px] z-[5] '>
                                    Some items may not have been updated in shopify
                                </div>
                            </div>
                        )}
                    </Link>
                )
            },
        }),
        columnHelper.accessor('id', {
            header: 'Id',
            cell: (info) => {
                return (
                    <Link
                        draggable={false}
                        to={`/products/inventory-emails/${info.row.original.id}`}
                        state={{ previousLocationPathname: location.pathname }}
                        className={cn(' flex gap-1 items-center')}
                    >
                        {info.getValue()}
                    </Link>
                )
            },
            size: 100,
        }),
        columnHelper.accessor('subject', {
            header: 'Subject',
            cell: (info) => {
                return (
                    <Link
                        draggable={false}
                        to={`/products/inventory-emails/${info.row.original.id}`}
                        state={{ previousLocationPathname: location.pathname }}
                        className={cn('flex gap-1 items-center')}
                    >
                        {info.getValue()}
                    </Link>
                )
            },
            size: 350,
        }),
        columnHelper.accessor('sent_from', {
            header: 'Author',
            cell: (info) => {
                return (
                    <Link
                        draggable={false}
                        to={`/products/inventory-emails/${info.row.original.id}`}
                        state={{ previousLocationPathname: location.pathname }}
                        className={cn('flex gap-1 items-center')}
                    >
                        {info.getValue()}
                    </Link>
                )
            },
        }),
        columnHelper.accessor('delivered_at', {
            header: 'Delivered At',
            cell: (info) => {
                return (
                    <Link
                        draggable={false}
                        to={`/products/inventory-emails/${info.row.original.id}`}
                        state={{ previousLocationPathname: location.pathname }}
                        className={cn(' flex gap-1 items-center')}
                    >
                        {formatDate(new Date(info.getValue()))}
                    </Link>
                )
            },
            size: 160,
        }),
    ]
    const columnCount = productsColumns?.length.toString() || 10

    return (
        <div>
            <div className='flex justify-between mb-[8px]'>
                <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Inventory Emails</h1>
                <div className='flex gap-[8px]'>
                    <RefreshButton
                        queryKeys={[productSetupQueries.productSetup.inventory._def.toString()]}
                        isFetching={inventoryEmailsQuery.isFetching}
                    />
                </div>
            </div>
            <div className='rounded-[4px] shadow-small dark:bg-darkaccent min-h-[85vh]'>
                <InventoryEmailsSearchBarContainer setDebouncedSearch={setSearch} />

                <>
                    <div className='w-full'>
                        {tableDataMemo && (
                            <div className='w-full  text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                                <Table columns={productsColumns} tableData={tableDataMemo} columnCount={columnCount} />
                            </div>
                        )}
                    </div>
                    <div className='flex fixed bottom-[8px] left-[calc(50%+216px)] translate-x-[calc(-50%-108px)] p-[8px] bg-white dark:bg-darkness border-[1px] border-darkgrey gap-[16px] justify-center items-center mt-[16px] rounded'>
                        <>
                            <button
                                disabled={
                                    inventoryEmailsQuery.isLoading || inventoryEmailsQuery.isFetching || page == 1
                                }
                                className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                                onClick={() => {
                                    handleFirstPage()
                                }}
                            >
                                <LuArrowLeftToLine className='fill-darkgrey dark:fill-accent' />
                            </button>
                            <button
                                disabled={
                                    inventoryEmailsQuery.isLoading || inventoryEmailsQuery.isFetching || page == 1
                                }
                                className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                                onClick={() => {
                                    handlePageDecrease()
                                }}
                            >
                                <BiLeftArrowAlt className='fill-darkgrey dark:fill-accent' />
                            </button>
                        </>
                        <div className='font-bold dark:text-offwhite'>Current Page: {page}</div>
                        <button
                            className='disabled:opacity-20 grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                            onClick={() => {
                                handlePageIncrease()
                            }}
                            disabled={
                                inventoryEmailsQuery.isLoading ||
                                inventoryEmailsQuery.isFetching ||
                                inventoryEmails?.length < 50
                            }
                        >
                            <BiRightArrowAlt className='fill-darkgrey dark:fill-accent' />
                        </button>
                    </div>
                </>
            </div>
        </div>
    )
}
