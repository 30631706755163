import { vFetch } from '../../../helpers'
import { formatDate } from '../../helpers'
import { FaTrash } from 'react-icons/fa'

export default function ParentFile({ file }: { file: any }) {
    const handleDeleteFile = () => {
        vFetch('/v1/procurement/files', {
            method: 'DELETE',
            body: JSON.stringify({ id: file.id, key: file.src.replace('https://fpdash-bucket.s3.amazonaws.com', '') }),
        })
    }

    return (
        <div className='text-[14px] grid grid-cols-[1fr_1fr_1fr_1fr_1fr_50px] border-b border-lightgrey dark:border-darkgrey hover:bg-lightgrey dark:hover:bg-darkness'>
            <div className='p-[8px]'>{file.company_name}</div>
            <a
                key={file.name}
                href={file.src}
                style={{ wordBreak: 'break-word' }}
                className='p-[8px] font-bold dark:text-offwhite'
            >
                {file.name}
            </a>
            <div style={{ wordBreak: 'break-word' }} className='p-[8px] font-bold dark:text-offwhite'>
                {file.type}
            </div>
            <div style={{ wordBreak: 'break-word' }} className='p-[8px] font-bold dark:text-offwhite'>
                {file.file_type}
            </div>
            <div style={{ wordBreak: 'break-word' }} className='p-[8px] font-bold dark:text-offwhite'>
                {formatDate(new Date(file.created_at))}
            </div>
            <button onClick={handleDeleteFile}>
                <FaTrash className='text-red dark:text-lightred' />
            </button>
        </div>
    )
}
