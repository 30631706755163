import { CalendarIcon } from '@radix-ui/react-icons'
import { Button } from '../../custom_components/component_Basics/Button'
import { Popover, PopoverContent, PopoverTrigger } from '../../custom_components/component_Basics/Popover'
import { cn } from '../../helpers'

export function RangeSelectorPopover({
    CalendarProp,
    ButtonContent,
    children,
}: {
    CalendarProp: any
    ButtonContent: any
    children: any
}) {
    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    variant={'outline'}
                    size={'sm'}
                    className={cn('min-w-[180px] w-fit justify-start text-left font-normal')}
                >
                    <CalendarIcon className='mr-2 h-4 w-4' />
                    <ButtonContent />
                </Button>
            </PopoverTrigger>
            <PopoverContent
                align='start'
                className='flex w-auto h-[322px] flex-col space-y-2 p-2 text-text dark:text-darktext1 bg-bg1 dark:bg-darkbg1 border-darkgrey'
            >
                <div className='relative flex h-full text-sm divide-x-2 divide-darkgrey'>
                    <div className='flex flex-col gap-2 items-start pr-1 min-w-[200px] h-full overflow-auto'>
                        {children}
                    </div>
                    <div className=''>
                        <CalendarProp />
                    </div>
                </div>
            </PopoverContent>
        </Popover>
    )
}
