import { useQueryClient } from '@tanstack/react-query'
import { parseResObject } from 'helpers'
import { useSelector } from 'react-redux'
import queryKeys from 'tasksV2/api/queryKeys'
import { useGetAllDepartmentConfigs } from 'tasksV2/api/useQueries'

export default function TaskEstimate({
    taskId,
    departmentId,
    estimateId,
    mutation,
}: {
    taskId: number
    departmentId: number
    estimateId: number
    mutation: any
}) {
    const user = useSelector<any, any>((state) => state.user)
    const departmentConfigs = useGetAllDepartmentConfigs(user.id)
    const configs = departmentConfigs.data?.departments || []
    const foundConfig = parseResObject(configs.find((config: any) => config.id === departmentId) || {})
    const foundTypeOption = foundConfig.estimates?.find((estimate: any) => estimate.id === estimateId)
    const queryClient = useQueryClient()
    const handleChange = ({ target }: { target: HTMLSelectElement }) => {
        queryClient.setQueryData(queryKeys.tasks.detail(taskId.toString()).queryKey, (oldData: any) => {
            return {
                ...oldData,
                task: { ...oldData.task, estimate_id: parseInt(target.value) },
            }
        })
        mutation.mutate({
            id: taskId,
            estimate_id: parseInt(target.value),
        })
    }
    return (
        <select
            className='border border-lightgrey dark:border-darkgrey rounded p-1 w-full dark:bg-darkbg1'
            id='task_estimate_id'
            name='task_estimate_id'
            value={foundTypeOption?.id}
            onChange={handleChange}
        >
            <option value=''>{!foundConfig.estimates ? 'No Estimates Available' : 'No Estimate Selected'}</option>
            {foundConfig?.estimates?.map((estimate: any) => {
                return <option value={estimate.task_estimate_id}>{estimate.value}</option>
            })}
        </select>
    )
}
