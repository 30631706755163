import { CategoryScale } from 'chart.js'
import Chart from 'chart.js/auto'
import { useState } from 'react'
import { DateRange } from 'react-day-picker'
import { useSearchParams } from 'react-router-dom'
import { BarChart } from '../components/BarChart'
import { GraphContainer } from '../components/GraphContainer'
import LineChart from '../components/LineChart'
import {
    DATE_PICKER_OPTIONS,
    PLACEHOLDER_DATA_INSERT,
    TIME_PERIOD_OPTIONS,
    formatChartDataByAnalyticsSet,
} from '../helpers'

Chart.register(CategoryScale)

export default function GenericAnalyticsIntervalMultiDataset({
    analyticsObjects,
    user_ids,
    dateRange,
    dateRangeCompare,
    size,
    chartTitle,
}: {
    analyticsGetFunction1: Function
    analyticsGetFunction2: Function
    user_ids: any
    dateRange: DateRange | undefined
    analyticsObjects: any
    dateRangeCompare: DateRange | undefined
    size: 'small' | 'medium' | 'large'
    queryOptions: {
        tag: string
        value: string[]
        title: string
    }[]
    queryOptions2: {
        tag: string
        value: string[]
        title: string
    }[]
    chartTitle: string
}) {
    let [searchParams, setSearchParams] = useSearchParams()
    const interval = searchParams.get('interval') || '0'
    const [timePeriodIndex, setTimePeriodIndex] = useState(parseInt(interval))
    const optionIndexParam: string = searchParams.get('option') || '2'
    const [queryOptionsIndex, setQueryOptionsIndex] = useState(0)
    const [chartType, setChartType] = useState<'bar' | 'line'>('bar')

    chartTitle = chartTitle + `- ${DATE_PICKER_OPTIONS[parseInt(optionIndexParam)].label}`
    const analyticsSets: any = []

    analyticsObjects.forEach((object: any) => {
        const analyticsQuery = object.analyticsGetFunction({
            user_ids,
            dateRange,
            dateRangeCompare,
            timePeriod: TIME_PERIOD_OPTIONS[timePeriodIndex],
            fields: object.queryOptions[0],
        })
        const { analytics: analyticsData }: { analytics: any } = analyticsQuery?.data || {}
        analyticsSets.push({
            data: analyticsData,
            label: object.label,
        })
    })

    const chartData =
        formatChartDataByAnalyticsSet(analyticsSets, dateRangeCompare, TIME_PERIOD_OPTIONS[timePeriodIndex]) ??
        PLACEHOLDER_DATA_INSERT

    return (
        <GraphContainer
            timePeriodIndex={timePeriodIndex}
            setTimePeriodIndex={setTimePeriodIndex}
            setOptionsIndex={setQueryOptionsIndex}
            setChartType={setChartType}
            chartType={chartType}
        >
            {chartType === 'bar' && <BarChart chartTitle={chartTitle} chartData={chartData} size={size} />}
            {chartType === 'line' && <LineChart chartTitle={chartTitle} chartData={chartData} size={size} />}
        </GraphContainer>
    )
}
