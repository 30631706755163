import { CaretDownIcon, CaretUpIcon } from '@radix-ui/react-icons'
import { SortingState, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import React from 'react'
import { cn } from '../../helpers'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { LeadTime } from './InventoryLeadTimesList'

export default function LeadTimeTable({ tableData, columns, columnCount, width = 'w-[85vw]' }: any) {
    const [sorting, setSorting] = React.useState<SortingState>([])
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const table = useReactTable({
        columns,
        data: tableData,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        state: { sorting },
    })

    const columnInsert: string[] = []
    for (let index = 0; index < columnCount; index++) {
        columnInsert.push('1fr')
    }

    return (
        <table className={cn(' bg-blue shadow-md p-2 ', width)}>
            <thead className='sticky top-[50px]'>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id} className={cn(' bg-blue px-2 dark:bg-darkbg2')}>
                        {headerGroup.headers.map((header) => (
                            <th key={header.id} colSpan={header.colSpan} className='text-start text-sm px-2 capitalize'>
                                {header.isPlaceholder ? null : (
                                    <div
                                        className={
                                            header.column.getCanSort()
                                                ? 'cursor-pointer select-none flex gap-1'
                                                : 'flex gap-1'
                                        }
                                        onClick={header.column.getToggleSortingHandler()}
                                        title={
                                            header.column.getCanSort()
                                                ? header.column.getNextSortingOrder() === 'asc'
                                                    ? 'Sort ascending'
                                                    : header.column.getNextSortingOrder() === 'desc'
                                                      ? 'Sort descending'
                                                      : 'Clear sort'
                                                : undefined
                                        }
                                    >
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        {{
                                            asc: (
                                                <div className='self-center min-h-[18px]'>
                                                    <CaretUpIcon />
                                                </div>
                                            ),
                                            desc: (
                                                <div className='self-center min-h-[18px]'>
                                                    <CaretDownIcon />
                                                </div>
                                            ),
                                        }[header.column.getIsSorted() as string] ?? null}
                                    </div>
                                )}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody className='w-full'>
                {table.getRowModel().rows.map((row) => {
                    const leadTime: any = table.getRow(row.id).original
                    return (
                        <tr
                            key={row.id}
                            className={cn(
                                `w-full bg-bg1 dark:bg-darkbg1 text-black dark:text-offwhite border-b-[2px] px-2 border-lightgrey dark:border-gray-500 hover:bg-lightgrey cursor-pointer`
                            )}
                            onClick={() =>
                                navigate(`/products/inventory-lead-times/${leadTime.id}?${searchParams.toString()}`)
                            }
                        >
                            {row.getVisibleCells().map((cell) => {
                                return (
                                    <td
                                        style={{ width: cell.column.getSize() }}
                                        key={cell.id}
                                        className='px-2 h-[52px]'
                                    >
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}
