import { taskStatusDisplayMap } from "../constants"

export const monthDateShortFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric',
})
export const sortTasksByType = (a: any, b: any) => {
    const aSortValue = taskStatusDisplayMap[a.status].sortValue
    const bSortValue = taskStatusDisplayMap[b.status].sortValue
    return bSortValue - aSortValue
}