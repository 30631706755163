import { useEffect, useState } from 'react'
import { MetafieldProps } from '../ProductSetup.types'
import ProductMetafieldImporter from '../components/ProductMetafieldImporter'

export default function Checkmarks({ product, setProduct, handleUpdateMetafield, edited }: MetafieldProps) {
    const [checkText, setCheckText] = useState('')
    const handleChange = ({ target }: { target: HTMLTextAreaElement }) => {
        setCheckText(target.value)
        const values = target.value.split('\n')
        const currentCheckmarks = values.map((v) => v.trim()).filter((v) => v.length)
        setProduct((previousState) => ({
            ...previousState,
            checkmarks: currentCheckmarks,
        }))
    }
    const handleSwap = (metafieldString: string) => {
        const parsedMetafield = JSON.parse(metafieldString)
        setCheckText(parsedMetafield.join('\n'))
        const currentCheckmarks = parsedMetafield.map((v: string) => v.trim()).filter((v: string) => v.length)
        setProduct((previousState) => ({
            ...previousState,
            checkmarks: currentCheckmarks,
        }))
    }
    useEffect(() => {
        if (product.checkmarks) {
            setCheckText(product.checkmarks.join('\n'))
        }
    }, [])
    return (
        <>
            <div className='w-full dark:bg-darkaccent p-[16px] rounded-[4px] flex flex-col shadow-small overflow-hidden'>
                <p className='mt-[-16px] ml-[-16px] mb-[16px] p-[4px] leading-[1] w-fit p-[2px] text-[12px] uppercase font-bold bg-blue dark:bg-accent text-white dark:text-darkness'>
                    Checkmarks
                </p>
                <ProductMetafieldImporter namespace='custom' metafieldKey='checkmarks' handleSetField={handleSwap} />
                <textarea
                    className='w-full block max-w-none border-none p-[8px] rounded-[4px] dark:text-white bg-lightgrey dark:bg-faintplus focus:outline-none'
                    spellCheck='true'
                    onChange={handleChange}
                    value={checkText}
                ></textarea>
                <p className='dark:text-offwhite dark:font-normal font-bold text-[14px] mt-[4px]'>
                    * Each checkmark item must be separated by a newline
                </p>
                {edited && (
                    <button
                        className='block py-[4px] px-[8px] bg-blue dark:bg-accent text-white dark:text-darkaccent rounded-[4px] mx-auto mt-[16px] font-bold uppercase'
                        onClick={() =>
                            handleUpdateMetafield([
                                {
                                    ownerId: `gid://shopify/Product/${product.id}`,
                                    namespace: 'custom',
                                    key: 'checkmarks',
                                    type: 'list.single_line_text_field',
                                    value: JSON.stringify(product.checkmarks),
                                },
                            ])
                        }
                    >
                        Save Changes
                    </button>
                )}
            </div>
            {product.checkmarks && product.checkmarks.length > 0 && (
                <>
                    <h2 className='dark:text-offwhite font-light uppercase text-[24px] my-[16px]'>
                        Checkmarks Preview
                    </h2>
                    <div className='w-fit !m-[0] bg-white description-preview dark:opacity-[0.9] dark:text-black'>
                        <div className='pdp__description__checkmarks'>
                            {product.checkmarks.map((c) => (
                                <div className='pdp__description__checkmarks__row'>
                                    <div className='pdp__description__checkmarks__row__image-container'>
                                        <img
                                            className='pdp__description__checkmarks__row__image-container__img'
                                            src='https://cdn.shopify.com/s/files/1/1163/1976/files/checkmark.svg?v=1604691291'
                                            alt='checkmark'
                                            width='auto'
                                            height='auto'
                                            loading='lazy'
                                        />
                                    </div>
                                    <p className='pdp__description__checkmarks__row__text'>{c}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
