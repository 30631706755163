import { useEffect, useState } from 'react'
import { DayMap, MonthMap, closePopout, dayMap, formatMoney, monthMap, sendToast } from '../../helpers'
import {
    FinancialStatusMap,
    FulfillmentLineItemInit,
    FulfillmentOrderInit,
    LineItemInit,
    OrderInit,
    defaultOrder,
    financialStatusMap,
} from '../orders.types'
import { filterOutRefundedItems, findDiscountApplications, paidByCustomer, totalOrderPrice } from '../orders.helpers'
import { TbCircle } from 'react-icons/tb'
import CloseButton from '../../custom_components/CloseButton'

type PaymentStatusProps = {
    order: OrderInit
    fulfillmentOrders: FulfillmentOrderInit[]
}

export default function PaymentStatus({ order, fulfillmentOrders }: PaymentStatusProps) {
    const [showTaxRates, setShowTaxRates] = useState<boolean>(false)
    const financialStatus = order.financial_status as keyof FinancialStatusMap

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => closePopout(e, ['js-order__tax-rates'], setShowTaxRates)
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <div className='grid gap-[8px] bg-white dark:bg-darkaccent p-[16px] pb-0 shadow-small rounded dark:text-offwhite [&>*:last-child]:pb-[16px]'>
            <div className='flex items-center gap-[8px] text-[14px]'>
                <div
                    className={`${
                        financialStatus && financialStatusMap[financialStatus]
                            ? financialStatusMap[financialStatus].bg
                            : 'bg-fire'
                    } w-[auto] p-[2px] px-[8px] pr-[10px] flex gap-[4px] items-center text-darkgrey  rounded-full`}
                >
                    {financialStatus &&
                    financialStatusMap[financialStatus] &&
                    financialStatusMap[financialStatus].symbol ? (
                        financialStatusMap[financialStatus].symbol
                    ) : (
                        <TbCircle />
                    )}
                    <h2 className='inline-block font-bold text-[14px] text-center'>
                        {financialStatus && financialStatusMap[financialStatus]
                            ? financialStatusMap[financialStatus].status
                            : 'U'}
                    </h2>
                </div>
                {financialStatus === 'authorized' && (
                    <span className='text-[13px] text-darkgrey dark:text-grey'>
                        Expires on{' '}
                        {new Date(
                            order.processed_at.getFullYear(),
                            order.processed_at.getMonth(),
                            order.processed_at.getDate() + 7,
                            order.processed_at.getHours(),
                            0,
                            0,
                            0
                        )
                            .toDateString()
                            .split(' ')
                            .map((dateItem: string) =>
                                dayMap[dateItem as keyof DayMap]
                                    ? `${dayMap[dateItem as keyof DayMap]}, `
                                    : monthMap[dateItem as keyof MonthMap]
                                      ? `${monthMap[dateItem as keyof MonthMap]}`
                                      : Number(dateItem) < 32
                                        ? `${dateItem},`
                                        : dateItem
                            )
                            .join(' ')}
                    </span>
                )}
            </div>
            <div className='grid gap-[16px]'>
                <div className='flex flex-col gap-[8px]'>
                    <div className='flex justify-between'>
                        <div className='flex gap-[16px] text-darkgrey dark:text-offwhite'>
                            <span className='w-[140px]'>Subtotal</span>
                            <span>
                                {filterOutRefundedItems(order, fulfillmentOrders)!
                                    .map((item: any) => item?.quantity)
                                    .reduce((acc, cur) => acc + cur, 0)}{' '}
                                items
                            </span>
                        </div>
                        <span>
                            {formatMoney(
                                filterOutRefundedItems(order, fulfillmentOrders)!
                                    .map((item) => item!.quantity * Number(item!.price))
                                    .reduce((acc, cur) => acc + cur, 0)
                            )}
                        </span>
                    </div>
                    {findDiscountApplications(order).map((discount, index) => {
                        const discountTitleFormat: string[] = []
                        if (discount.discount.title)
                            discountTitleFormat.push(
                                `${discount.discount.title} × ${discount.item.quantity} [${discount.item.sku}]`
                            )
                        else if (discount.discount.code)
                            discountTitleFormat.push(`${discount.discount.code} [${discount.item.sku}]`)
                        else {
                            discountTitleFormat.push(`[${discount.item.sku}`)
                            if (discount.discount.value_type === 'percentage')
                                discountTitleFormat.push(`(-${Number(discount.discount.value)}%)]`)
                            else
                                discountTitleFormat.push(`(${discount.per_item_discount} x ${discount.item.quantity})]`)
                        }

                        return (
                            <div key={discount.total_discount_value} className='flex justify-between'>
                                <div className='flex gap-[16px] text-darkgrey dark:text-offwhite'>
                                    <span className='w-[140px]'>Discount</span>
                                    <span key={discount.discount.title + index}>{discountTitleFormat}</span>
                                </div>
                                <span>-{discount.total_discount_value}</span>
                            </div>
                        )
                    })}
                    {order.shipping_lines.length > 0 && (
                        <div className='flex justify-between'>
                            <div className='flex gap-[16px] text-darkgrey dark:text-offwhite'>
                                <span className='w-[140px]'>Shipping</span>
                                <span>
                                    {order.shipping_lines[0].code} ({(Number(order.total_weight) / 453.6).toFixed(1)}{' '}
                                    lb)
                                </span>
                            </div>
                            <span>{formatMoney(Number(order.shipping_lines[0].price))}</span>
                        </div>
                    )}
                    {order.tax_lines.length > 0 && (
                        <div className='flex justify-between'>
                            <div className='flex gap-[16px] text-darkgrey dark:text-offwhite'>
                                <span className='w-[140px]'>Tax</span>
                                {order.tax_lines.length === 1 ? (
                                    <span>
                                        {order.tax_lines[0].title} {(Number(order.tax_lines[0].rate) * 100).toFixed(4)}%
                                    </span>
                                ) : (
                                    <>
                                        <span
                                            className='js-order__tax-rates font-semibold hover:underline hover:text-blue dark:hover:text-accent cursor-pointer'
                                            onClick={() => {
                                                setShowTaxRates(true)
                                            }}
                                        >
                                            Show Tax Rates
                                        </span>
                                        {showTaxRates && (
                                            <div className='fixed flex p-[32px] top-[51px] left-[216px] bg-[rgba(0,0,0,0.3)] w-[calc(100%-216px)] h-[calc(100%-50px)] z-50'>
                                                <div className='js-order__tax-rates relative grid self-center ml-[64px] w-[calc(65%-128px)] min-w-[250px] bg-white dark:bg-darkaccent rounded-lg'>
                                                    <h2 className='p-[16px] text-[20px] font-bold border-b-[1px] dark:border-b-darkgrey'>
                                                        Tax Rates
                                                    </h2>
                                                    <CloseButton onClick={() => setShowTaxRates(false)} />
                                                    <div className='p-[16px] flex flex-col gap-[16px] border-b-[1px] dark:border-b-darkgrey'>
                                                        {order.tax_lines.map((line) => (
                                                            <div key={line.title} className='flex justify-between'>
                                                                <span>
                                                                    {line.title} ({(Number(line.rate) * 100).toFixed(4)}
                                                                    %)
                                                                </span>{' '}
                                                                <span>{formatMoney(Number(line?.price))}</span>
                                                            </div>
                                                        ))}
                                                        <div className='flex justify-between'>
                                                            <span className='font-semibold'>Total Tax</span>
                                                            <span>{formatMoney(Number(order.total_tax))}</span>
                                                        </div>
                                                    </div>
                                                    <button
                                                        className='ml-[auto] mr-[16px] my-[16px] py-[4px] px-[16px] border-[1px] rounded hover:bg-offwhite dark:hover:bg-darkness'
                                                        onClick={() => {
                                                            setShowTaxRates(false)
                                                        }}
                                                    >
                                                        Close
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                            <span>{formatMoney(Number(order.current_total_tax))}</span>
                        </div>
                    )}

                    <div className='flex justify-between'>
                        <span className='w-[140px] font-semibold'>Total</span>
                        <span>{formatMoney(Number(order.current_total_price))}</span>
                    </div>
                </div>
                <div className='flex flex-col gap-[16px] pt-[16px] border-t-[1px] dark:border-darkgrey'>
                    <div className='flex justify-between'>
                        <span className='text-darkgrey dark:text-offwhite'>Paid by customer</span>
                        <span>
                            {/* {financialStatus === 'authorized'
                                ? formatMoney(0)
                                : financialStatus === 'refunded'
                                  ? formatMoney(order.total_price)
                                  : formatMoney(totalOrderPrice(order) - Number(order.total_outstanding))} */}
                            {paidByCustomer(order)}
                        </span>
                    </div>
                    {order.refunds.some((refund) => refund.transactions.length > 0) && [
                        <div className='flex dark:border-darkgrey gap-[16px] justify-between'>
                            <span className='w-[128px]'>Refund</span>
                            <div className='grow flex flex-col gap-[16px]'>
                                {order.refunds
                                    .filter((refund) => refund.transactions.length)
                                    .map((refund) => [
                                        <div className='flex grow gap-[16px] justify-between'>
                                            <span>Reason: "{refund.note}"</span>
                                            <div className='flex flex-col gap-[8px]'>
                                                {formatMoney(
                                                    refund.refund_line_items.reduce(
                                                        (acc, cur) => acc - cur.subtotal! - cur.total_tax!,
                                                        0
                                                    ) +
                                                        refund.order_adjustments.reduce(
                                                            (acc, cur) => acc + Number(cur.amount),
                                                            0
                                                        )
                                                )}
                                            </div>
                                        </div>,
                                    ])}
                            </div>
                        </div>,
                        <div className='flex border-t-[1px] pt-[16px] dark:border-darkgrey gap-[32px] justify-between'>
                            <span className='w-[128px] font-bold'>Net Payment</span>
                            <span>{formatMoney(totalOrderPrice(order) - Number(order.total_outstanding))}</span>
                        </div>,
                    ]}
                </div>
            </div>
            {financialStatus !== 'authorized' && Number(order.total_outstanding) !== 0 && (
                <div className='relative flex justify-between left-[-16px] p-[16px] w-[calc(100%+32px)] bg-fire/20 rounded-b'>
                    <span className='text-darkgrey dark:text-offwhite'>Balance</span>
                    <span>{formatMoney(Number(order.total_outstanding))}</span>
                </div>
            )}
            {financialStatus === 'authorized' && (
                <div className='flex p-[16px] w-[calc(100%+32px)] relative left-[-16px] border-t-[1px] dark:border-darkgrey'>
                    <button
                        onClick={() => {
                            window.open(`https://factorypure.myshopify.com/admin/orders/${order.id}`, '_blank')
                        }}
                        className='ml-auto bg-[#007a5c] hover:bg-[#125443] dark:bg-fire dark:hover:bg-[darkorange] rounded py-[8px] px-[16px] items-center justify-center text-[14px] font-semibold text-white dark:text-black !border-[1px] border-darkgrey'
                    >
                        Capture Payment
                    </button>
                </div>
            )}
        </div>
    )
}
