import { formatDate } from '../../helpers'
import { EventType } from '../lists/ActivityList'

export default function BasicEvent({ event }: { event: EventType }) {
    const notTask = !event.message.split(' ').includes('task')
    return (
        <div className='w-full relative p-[16px] pt-[20px] rounded-[4px] border border-blue/25 dark:border-darkgrey dark:bg-darkness shadow-md'>
            <p className='absolute top-0 left-0 bg-blue dark:bg-accent rounded-tl-[4px] text-white dark:text-darkness leading-[1] text-[10px] uppercase font-bold p-[2px]'>
                Event
            </p>
            <div className='max-w-full grid grid-cols-[minmax(200px,_1fr)_100px]'>
                <div className='flex gap-2 text-[14px]'>
                    <p className='break-words max-w-full font-bold'>{event.message}</p>
                    {(event.previous_value || event.value) && notTask && (
                        <p>
                            <span className='line-through'>{event.previous_value}</span> {'=>'} {event.value}
                        </p>
                    )}
                </div>
                <p className='font-bold w-full text-[12px]'>{formatDate(new Date(event.created_at))}</p>
            </div>
        </div>
    )
}
