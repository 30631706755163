import { useSearchParams } from 'react-router-dom'
import { useGetDepartments } from 'tasksV2/api/useQueries'
import { useAllUsersQuery } from 'users/api/useQueries'
import TaskDepartmentListV2 from './lists/TaskDepartmentListV2'
import CreateTaskDepartmentModal from './modals/CreateTaskDepartmentModal'
import EditTaskDepartmentModal from './modals/EditTaskDepartmentModal'

export default function TaskDepartmentsScreen() {
    const [searchParams, setSearchParams] = useSearchParams()
    const showCreateModal = searchParams.get('showCreateModal') === 'true'
    const selectedDepartmentId = searchParams.get('departmentId')
    const departmentsQuery: any = useGetDepartments({})
    const departments = departmentsQuery.data?.departments || []

    // cache seed queries
    const usersQuery = useAllUsersQuery()

    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showCreateModal')
        } else {
            searchParams.set('showCreateModal', 'true')
        }
        setSearchParams(searchParams)
    }

    const toggleEditModal = (departmentId?: string) => {
        if (!departmentId) {
            searchParams.delete('departmentId')
        } else {
            searchParams.set('departmentId', departmentId)
        }
        setSearchParams(searchParams)
    }

    return (
        <div className='text-sm'>
            <TaskDepartmentListV2 />

            {showCreateModal && <CreateTaskDepartmentModal closeModal={toggleCreateModal} />}
            {selectedDepartmentId && (
                <EditTaskDepartmentModal departmentId={selectedDepartmentId} closeModal={toggleEditModal} />
            )}
        </div>
    )
}
