import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'
import { getDate } from 'date-fns'

export const analytics = createQueryKeys('analytics', {
    companyStatusAnalytics: (user_ids, dateRange, dateRangeCompare, timePeriod, fields) => ({
        queryKey: [
            {
                user_ids,
                dateRange: { from: getDate(new Date(dateRange?.from)), to: getDate(new Date(dateRange?.to)) },
                dateRangeCompare: {
                    from: getDate(new Date(dateRangeCompare?.from)),
                    to: getDate(new Date(dateRangeCompare?.to)),
                },
                timePeriod,
                fields,
            },
        ],
        queryFn: (context) =>
            service.getCompanyStatusAnalytics(user_ids, dateRange, dateRangeCompare, timePeriod, fields),
    }),
    companyStatusAnalyticsByUserTotal: (user_ids, dateRange, dateRangeCompare, fields) => ({
        queryKey: [
            {
                user_ids,
                dateRange: { from: getDate(new Date(dateRange?.from)), to: getDate(new Date(dateRange?.to)) },
                dateRangeCompare: {
                    from: getDate(new Date(dateRangeCompare?.from)),
                    to: getDate(new Date(dateRangeCompare?.to)),
                },
                fields,
            },
        ],
        queryFn: (context) =>
            service.getCompanyStatusAnalyticsByUserTotal(user_ids, dateRange, dateRangeCompare, fields),
    }),
    leadTypes: (user_ids, dateRange, dateRangeCompare, timePeriod, leadTypes) => ({
        queryKey: [{ user_ids, dateRange, dateRangeCompare, timePeriod, leadTypes }],
        queryFn: (context) =>
            service.getCompanyLeadTypeAnalytics(user_ids, dateRange, dateRangeCompare, timePeriod, leadTypes),
    }),
    companyInteractions: (user_ids, dateRange, dateRangeCompare, timePeriod, fields) => ({
        queryKey: [
            {
                user_ids,
                dateRange: { from: getDate(new Date(dateRange?.from)), to: getDate(new Date(dateRange?.to)) },
                dateRangeCompare: {
                    from: getDate(new Date(dateRangeCompare?.from)),
                    to: getDate(new Date(dateRangeCompare?.to)),
                },
                timePeriod,
                fields,
            },
        ],
        queryFn: (context) =>
            service.getCompanyInteractionAnalytics(user_ids, dateRange, dateRangeCompare, timePeriod, fields),
    }),
    productInteractions: (user_ids, dateRange, dateRangeCompare, timePeriod, fields) => ({
        queryKey: [
            {
                user_ids,
                dateRange: { from: getDate(new Date(dateRange?.from)), to: getDate(new Date(dateRange?.to)) },
                dateRangeCompare: {
                    from: getDate(new Date(dateRangeCompare?.from)),
                    to: getDate(new Date(dateRangeCompare?.to)),
                },
                timePeriod,
                fields,
            },
        ],
        queryFn: (context) =>
            service.getProductInteractionAnalytics(user_ids, dateRange, dateRangeCompare, timePeriod, fields),
    }),
})

export const analyticsQueries = mergeQueryKeys(analytics)
