import { useEffect, useRef, useState } from 'react'
import { IoTimeSharp } from 'react-icons/io5'
import { useDebounce } from '../../helpers'
import { FetchingSpinner } from '../../custom_components/FetchingSpinner'

export default function Period({ period, setPeriod }) {
    const [show, setShow] = useState(false)
    const periodRef = useRef()
    const [customPeriod, setCustomPeriod] = useState(period)
    const [spinning, setSpinning] = useState(false)
    const debouncedPeriod = useDebounce(customPeriod, 1500)
    useEffect(() => {
        const listenToWindow = ({ target }) => {
            if (!periodRef.current.contains(target)) {
                setShow(false)
            }
        }
        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    useEffect(() => {
        if (debouncedPeriod) {
            setPeriod(debouncedPeriod)
        }
        setSpinning(false)
    }, [debouncedPeriod])
    return (
        <div ref={periodRef} className='relative'>
            <IoTimeSharp
                onClick={() => setShow((prev) => !prev)}
                className='w-[24px] h-[24px] cursor-pointer text-blue dark:text-accent'
            />
            {show && (
                <div className='w-fit p-[8px] absolute top-[100%] right-0 bg-white dark:bg-darkaccent z-[500] shadow-small rounded-[4px]'>
                    <FetchingSpinner className='absolute top-[-20px] right-10' isFetching={spinning} />
                    <button
                        className={`text-center w-full my-[2px] py-[2px] rounded-[4px] border border-lightgrey dark:border-none font-bold ${
                            period === 7
                                ? 'bg-blue dark:bg-accent text-white dark:text-darkness font-bold'
                                : 'bg-lightgrey dark:bg-darkness'
                        }`}
                        onClick={() => setPeriod(7)}
                    >
                        Last 7 Days
                    </button>
                    <button
                        className={`text-center w-full my-[2px] py-[2px] rounded-[4px] border border-lightgrey dark:border-none font-bold ${
                            period === 14
                                ? 'bg-blue dark:bg-accent text-white dark:text-darkness font-bold'
                                : 'bg-lightgrey dark:bg-darkness'
                        }`}
                        onClick={() => setPeriod(14)}
                    >
                        Last 14 Days
                    </button>
                    <button
                        className={`text-center w-full my-[2px] py-[2px] rounded-[4px] border border-lightgrey dark:border-none font-bold ${
                            period === 30
                                ? 'bg-blue dark:bg-accent text-white dark:text-darkness font-bold'
                                : 'bg-lightgrey dark:bg-darkness'
                        }`}
                        onClick={() => setPeriod(30)}
                    >
                        Last 30 Days
                    </button>
                    <button
                        className={`text-center w-full my-[2px] py-[2px] rounded-[4px] border border-lightgrey dark:border-none font-bold ${
                            period === 90
                                ? 'bg-blue dark:bg-accent text-white dark:text-darkness font-bold'
                                : 'bg-lightgrey dark:bg-darkness'
                        }`}
                        onClick={() => setPeriod(90)}
                    >
                        Last 90 Days
                    </button>
                    <input
                        className=' w-[120px] px-[4px] border border-lightgrey dark:border-none dark:bg-faintplus dark:text-white rounded-[4px]'
                        type='number'
                        placeholder='Custom (days)'
                        value={customPeriod}
                        max={365}
                        onChange={({ target }) => {
                            if (target.value === '') {
                                setSpinning(true)
                                setCustomPeriod(target.value)
                                return
                            }
                            const newPeriod = parseInt(target.value)
                            if (newPeriod <= 365 && newPeriod >= 0) {
                                setSpinning(true)
                                setCustomPeriod(newPeriod)
                                return
                            }
                            return
                        }}
                    />
                </div>
            )}
        </div>
    )
}
