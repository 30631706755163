import { useContext, useRef, useState } from 'react'
import { MdCancel, MdDoubleArrow } from 'react-icons/md'
import {
    useAlertAuditHistoryQuery,
    useCompanyDetailQuery,
    useCreateAlert,
    useProductAlertsQuery,
} from '../api/useQueries'
import { ProcurementContext, ProcurementContextType, formatDateSimple, getFilterBarUsers } from '../helpers'
import Input from './Input'
import { ProductDetail } from './ProductDetail'
import { ProductAlert } from './lists/ProductAlert'
import { GearIcon } from '@radix-ui/react-icons'
import { companyAlertsTable } from '../constants'
import { EditedFieldsHover, auditHistoryType } from './CompanyProductEditHistory'
import { Button } from '../../custom_components/component_Basics/Button'
import { useAuditHistoryQuery } from '../../audit/api/useQueries'
import { FetchingSpinner } from '../../custom_components/FetchingSpinner'
import { FilterBarSelectorButton } from '../../custom_components/component_Basics/FilterBarSelectorButton'
import { cn } from '../../helpers'

export function CompanyProductHeader({ showNewAlert, setShowNewAlert, users }: any) {
    const alertInputContainerRef = useRef<HTMLInputElement>(null)
    const [newAlert, setNewAlert] = useState('')
    const [showAlertsAudit, setShowAlertsAudit] = useState(false)
    const { selectedCompany, showAlerts, setShowAlerts, showDetails, setShowDetails } =
        useContext<ProcurementContextType>(ProcurementContext)
    const { mutate: createAlertMutation, isPending: createAlertPending } = useCreateAlert(selectedCompany.id)
    const [showEdit, setShowEdit] = useState<string>('')

    const [filterValues, setFilterValues] = useState<{
        user_ids: number[]
        fields: string[]
        record_id: string | number | undefined
    }>({
        fields: [],
        user_ids: [],
        record_id: undefined,
    })

    const handleCreateAlert = () => {
        createAlertMutation(
            {
                affects: 'products',
                message: newAlert,
                company_id: selectedCompany.id,
            },
            {
                onSuccess: () => {
                    setNewAlert('')
                    setShowNewAlert(false)
                },
            }
        )
    }
    const { data: alertsData } = useProductAlertsQuery(selectedCompany.id)
    const companyQuery = useCompanyDetailQuery(selectedCompany.id, selectedCompany)
    const company = companyQuery.data?.company || {}

    const alertAuditHistoryQuery = useAuditHistoryQuery({
        table: companyAlertsTable,
        record_id: filterValues.record_id || undefined,
        parent_id: selectedCompany.id,
        filters: { fields: filterValues.fields, user_ids: filterValues.user_ids },
    })
    const { pages: auditHistoryEventPages }: { pages: any } = alertAuditHistoryQuery?.data || {}

    const uniqueAlertIds: any = new Set([])
    auditHistoryEventPages?.map((page: any) => {
        return page?.auditHistory?.map((event: auditHistoryType) => {
            if (!uniqueAlertIds.has(event.record_id)) {
                uniqueAlertIds.add(event.record_id)
            }
        })
    })

    const selectorButtonObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: getFilterBarUsers(users),
                title: 'Users',
                field: 'user_ids',
                values: filterValues?.user_ids || [],
                searchToggle: true,
            },
            {
                component: FilterBarSelectorButton,
                options:
                    uniqueAlertIds.size > 0
                        ? Array.from(uniqueAlertIds).map((id) => {
                              return {
                                  value: id,
                                  label: id,
                                  icon: undefined,
                              }
                          })
                        : [],
                title: 'Alert ID',
                field: 'record_id',
                values: filterValues?.record_id || undefined,
                searchToggle: true,
            },
        ],
        setFunction: setFilterValues,
        resetFunction: () => {
            setFilterValues({
                fields: [],
                user_ids: [],
                record_id: undefined,
            })
        },
    }

    const companyDetails = [
        {
            label: 'Shipping Details',
            field: 'shipping_details',
        },
        {
            label: 'Shipping Terms',
            field: 'shipping_terms',
        },
        {
            label: 'US Ships From',
            field: 'us_ships_from',
        },
        selectedCompany.sells_to_canada != 'No'
            ? {
                  label: 'Canada Ships From',
                  field: 'canada_ships_from',
              }
            : null,
        {
            label: 'Processing Time',
            field: 'processing_time',
        },
        {
            label: 'Warranty',
            field: 'warranty',
        },
    ].filter((v) => v)

    return (
        <div className=' flex flex-col gap-[3px] mt-[-4px] w-full '>
            {showNewAlert && (
                <div
                    ref={alertInputContainerRef}
                    className={`flex items-start gap-[8px] my-[8px] ${
                        createAlertPending ? 'opacity-[0.5] pointer-events-none' : ''
                    }`}
                >
                    <textarea
                        id={'message'}
                        className='w-full font-semibold max-h-[100px] !text-[14px] !leading-snug bg-lightgrey border-0 focus:outline-none dark:text-white dark:bg-darkness p-1 rounded-[4px]'
                        value={newAlert}
                        name={'message'}
                        onChange={({ target }) => setNewAlert(target.value)}
                        placeholder='Type your alert message'
                    ></textarea>

                    <div className='flex gap-3 justify-end items-start'>
                        <button
                            onClick={handleCreateAlert}
                            className={`${
                                createAlertPending ? 'opacity-[0.9] pointer-events-none' : 'pointer-events-auto'
                            } shrink-0 text-[14px] leading-[1] py-[3px] px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase`}
                        >
                            {createAlertPending ? 'SAVING...' : 'SAVE'}
                        </button>
                        <button
                            onClick={() => {
                                setShowNewAlert(false)
                            }}
                            className={`${
                                createAlertPending ? 'opacity-[0.9] pointer-events-none' : 'pointer-events-auto'
                            } shrink-0 text-[14px] leading-[1] py-[3px] px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkgrey text-black dark:text-offwhite font-bold uppercase`}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
            <div className='flex flex-col gap-[8px]'>
                <div className='flex flex-col gap-[3px]'>
                    <div
                        onClick={() => setShowDetails(showDetails ? false : true)}
                        className={`cursor-pointer p-[4px] px-[8px] flex justify-between rounded-[4px] border border-darkgrey bg-lightgrey/10 font-bold text-[14px]`}
                    >
                        <div className='flex justify-between w-full items-center'>
                            <p>Listing Details</p>
                            <MdDoubleArrow
                                className={`w-[16px]  h-[16px] ${showDetails ? '-rotate-90' : 'rotate-90'}`}
                            />
                        </div>
                    </div>
                    {showDetails && (
                        <div className='flex flex-col gap-[3px]'>
                            {companyDetails?.map((detail: any) => (
                                <ProductDetail
                                    company={company}
                                    detail={detail}
                                    setShowEdit={setShowEdit}
                                    showEdit={showEdit}
                                />
                            ))}
                        </div>
                    )}
                    <div
                        className={`cursor-pointer p-[4px] px-[8px] flex justify-between rounded-[4px] border border-fire bg-fire/10 font-bold text-[14px]`}
                    >
                        <div className='flex flex-col gap-2 w-full'>
                            <div className='flex justify-between w-full items-center'>
                                <p onClick={() => setShowAlerts(showAlerts ? false : true)} className='w-full'>
                                    Alerts
                                </p>
                                <div className='flex gap-[10px]'>
                                    <div
                                        onClick={() => setShowAlertsAudit(showAlertsAudit ? false : true)}
                                        className={cn(showAlertsAudit && 'underline', 'flex gap-2')}
                                    >
                                        {!showAlertsAudit && <GearIcon className={`w-[16px]  h-[16px] `} />}
                                        {showAlertsAudit && (
                                            <>
                                                <p className='font-normal text-sm'>close</p>
                                                <MdCancel className='self-center' />
                                            </>
                                        )}
                                    </div>
                                    <MdDoubleArrow
                                        onClick={() => setShowAlerts(showAlerts ? false : true)}
                                        className={`w-[16px]  h-[16px] ${showAlerts ? '-rotate-90' : 'rotate-90'}`}
                                    />
                                </div>
                            </div>
                            {showAlertsAudit && (
                                <div className='bg-1 dark:bg-darkbg-1'>
                                    <div className='col-span-3 p-2 pt-0 min-h-[200px] max-h-[200px] overflow-auto max-w-full bg-bg1 dark:bg-darkbg1'>
                                        <div className='grid grid-cols-[.4fr_.6fr_2.5fr]'>
                                            <div className='sticky bg-lightgrey dark:bg-darkbg2 rounded-sm px-1 z-[4] top-0 grid mb-2 col-span-3 grid-cols-[.4fr_.4fr_.3fr_2.5fr]'>
                                                <div>User</div>
                                                <div>Date</div>
                                                <div>Alert Id</div>
                                                <div>Edits</div>
                                            </div>
                                            {auditHistoryEventPages?.map((page: any) => {
                                                return page?.auditHistory?.map((event: auditHistoryType) => {
                                                    return (
                                                        <div className='px-2 border-b border-lightgrey dark:border-darkgrey grid col-span-3 grid-cols-[.4fr_.4fr_.3fr_2.5fr]'>
                                                            <div>{event.user_alias}</div>
                                                            <div>{formatDateSimple(event.created_at)}</div>
                                                            <div>{event.record_id}</div>
                                                            <EditedFieldsHover event={event} />
                                                        </div>
                                                    )
                                                })
                                            })}
                                            <div className='flex justify-center col-span-3 mt-2'>
                                                <Button
                                                    className='flex gap-1'
                                                    size={'default'}
                                                    variant={'default'}
                                                    onClick={() => alertAuditHistoryQuery.fetchNextPage()}
                                                    disabled={!alertAuditHistoryQuery.hasNextPage}
                                                >
                                                    load more
                                                    {alertAuditHistoryQuery.isFetching && (
                                                        <FetchingSpinner
                                                            isFetching={alertAuditHistoryQuery.isFetching}
                                                        />
                                                    )}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='p-2 flex gap-2 items-start bg-1 dark:bg-darkbg1'>
                                        {selectorButtonObject.params.map((param: any) => {
                                            return (
                                                <param.component
                                                    key={param.field}
                                                    searchToggle={param.searchToggle}
                                                    title={param.title}
                                                    field={param.field}
                                                    options={param.options}
                                                    filterValues={param.values}
                                                    setFilterValues={selectorButtonObject.setFunction}
                                                    align={'start'}
                                                    preview={false}
                                                />
                                            )
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {showAlerts && (
                        <div className='flex flex-col gap-[3px]'>
                            {alertsData?.alerts?.map((alert: any) => <ProductAlert key={alert.id} alert={alert} />)}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
