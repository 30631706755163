import CustomerList from './lists/CustomerList'
import { useSearchParams } from 'react-router-dom'
import CustomerModal from './modals/CustomerModal'
import { Button } from 'custom_components/component_Basics/Button'
import { useState } from 'react'
import CreateCustomerModal from './modals/CreateCustomerModal'

export default function CustomersScreen() {
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const selectedCustomerId = searchParams.get('customer_id')

    return (
        <div>
            <div className='flex justify-between items-start mb-4'>
                <h1 className='text-2xl font-semibold'>Customers</h1>
                <Button
                    onClick={() => setShowCreateModal(true)}
                    className='font-bold text-white dark:text-offwhite leading-none bg-blue dark:bg-darkbg1 h-fit py-2 px-4'
                >
                    + New Customer
                </Button>
            </div>
            <div>
                <CustomerList />
            </div>
            {selectedCustomerId && (
                <CustomerModal
                    toggleModal={() => {
                        setSearchParams((prev) => {
                            prev.delete('customer_id')
                            return prev
                        })
                    }}
                    customer_id={selectedCustomerId}
                />
            )}
            {showCreateModal && <CreateCustomerModal toggleModal={() => setShowCreateModal(false)} />}
        </div>
    )
}
