import { useEffect, useState } from 'react'
import { FilterGroupType, FilterType, ShopifyProduct } from '../ProductSetup.types'
import { LoadingGear, vFetch } from '../../helpers'
import NewFilterModal from '../components/NewFilterModal'
import FilterValueModal from '../components/FilterValueModal'
import { FaGear } from 'react-icons/fa6'
import FilterGroupEditModal from '../components/FilterGroupEditModal'
import { useSelector } from 'react-redux'
import { useQueryClient } from '@tanstack/react-query'
import { productSetupQueries } from 'productSetupV2/api/productSetupQueryKeys'

export default function Filters({ product }: { product: ShopifyProduct }) {
    const queryClient = useQueryClient()
    const user = useSelector<any, any>((state) => state.user)
    const [allFilterMap, setAllFilterMap] = useState<{ [group_name: string]: FilterType[] }>({})
    const [productFilters, setProductFilters] = useState<{ group_name: string; values: FilterType[]; id?: number }[]>(
        []
    )
    const [editableProductFilters, setEditableProductFilters] = useState<
        { group_name: string; values: FilterType[] }[]
    >([])
    const [showModal, setShowModal] = useState(false)
    const [selectedFilterEditObject, setSelectedFilterEditObject] = useState<{
        groupName: string
        filterValues: (FilterType & { selected: boolean })[]
    } | null>(null)
    const [loading, setLoading] = useState(true)
    const [loadingModal, setLoadingModal] = useState(false)
    const [showEditGroup, setShowEditGroup] = useState(false)
    const [selectedFilterGroup, setSelectedFilterGroup] = useState<FilterGroupType | null>(null)

    const handleCreateFilterValue = async (
        product_id: string | number,
        filter_group: FilterType[],
        value: string,
        skipToast: boolean,
        skipRefresh: boolean
    ) => {
        setLoadingModal(true)
        await vFetch('/v2/products/groups', {
            method: 'POST',
            body: JSON.stringify({
                product_id,
                ...filter_group,
                value,
            }),
            cb: (res: any) => {
                setLoadingModal(false)
                if (res.success) {
                    setShowModal(false)
                    if (!skipRefresh) {
                        fetchFilters()
                    }
                }
            },
            catchCb: (err: any) => {
                setLoadingModal(false)
            },
            skipToast,
        })
    }

    const handleEditFilterGroup = async (filterGroup: FilterGroupType, oldfilterGroup: FilterGroupType) => {
        vFetch('/v2/products/groups', {
            method: 'PUT',
            body: JSON.stringify(filterGroup),
            cb: (res: any) => {
                setLoadingModal(false)
                if (res.success) {
                    setShowEditGroup(false)
                    fetchFilters()
                }
            },
        })
    }

    const handleFilterChange = ({ target }: { target: HTMLInputElement }, outerIndex: number, innerIndex: number) => {
        setEditableProductFilters((previousState) => {
            const newState = structuredClone(previousState)
            newState[outerIndex].values[innerIndex].value = target.value
            return newState
        })
    }

    const fetchFilters = async () => {
        setLoading(true)
        const allFilterValues: FilterType[] = await vFetch(
            `/v2/products/filters?product_type=${product.product_type}&includes_product_id=${product.id}`,
            {
                cb: (res: any) => {
                    return res.filterValues
                },
            }
        )
        const filterMap: {
            [key: string]: {
                group_name: string
                values: FilterType[]
                id?: number
            }
        } = {}
        const tempAllFilterMap: { [group_name: string]: FilterType[] } = {}
        for (let filterValue of allFilterValues) {
            if (!tempAllFilterMap[filterValue.group_name]) {
                tempAllFilterMap[filterValue.group_name] = []
            }
            const foundFilterValues = allFilterValues.filter(
                (cfv) => cfv.applied_to_current_product && cfv.group_name === filterValue.group_name
            )
            if (!tempAllFilterMap[filterValue.group_name].find((cur) => cur.value == filterValue.value)) {
                tempAllFilterMap[filterValue.group_name].push({
                    ...filterValue,
                    disjunctive: !!filterValue.disjunctive,
                    is_feature: !!filterValue.is_feature,
                })
            }

            if (!filterMap[filterValue.group_name]) {
                filterMap[filterValue.group_name] = {
                    group_name: filterValue.group_name,
                    values: [],
                    id: filterValue.id,
                }
            }
            foundFilterValues.length > 0
                ? (filterMap[filterValue.group_name].values = foundFilterValues.map(
                      (ffv) =>
                          ({
                              ...ffv,
                              disjunctive: !!ffv?.disjunctive,
                              is_feature: !!ffv?.is_feature,
                          }) as FilterType
                  ))
                : (filterMap[filterValue.group_name].values = [
                      {
                          ...filterValue,
                          disjunctive: !!filterValue.disjunctive,
                          is_feature: !!filterValue.is_feature,
                          value: '',
                      },
                  ])
        }
        setLoading(false)
        setAllFilterMap(tempAllFilterMap)
        setProductFilters(Object.values(filterMap))
        setEditableProductFilters(Object.values(filterMap))
    }

    const handleSaveFilter = (product_id: string | number, filter_group_id: string | number, values: string[]) => {
        const updatedFilterGroup = productFilters.filter((group) => {
            if (group.id === filter_group_id) {
                return group
            }
        })
        let previousValue: string[] = []
        updatedFilterGroup[0].values.forEach((value) => {
            previousValue.push(value.value)
        })
        let events: object[] = []
        events.push({
            step: 'filters',
            field: 'filter values',
            filter_group_name: updatedFilterGroup[0].group_name,
            updated_value: values.join(','),
            previous_value: previousValue.join(','),
            product_id,
            user_id: user.id,
        })
        vFetch(`/v1/productSetupEvents`, {
            method: 'POST',
            body: JSON.stringify({ events }),
        })
        vFetch('/v2/products/productFilterValues', {
            method: 'POST',
            body: JSON.stringify({
                product_id,
                filter_group_id,
                values,
            }),
            cb: () => {
                queryClient.invalidateQueries({ queryKey: productSetupQueries.productSetup.productSetupEvents._def })

                setSelectedFilterEditObject(null)
                fetchFilters()
            },
        })
    }

    useEffect(() => {
        fetchFilters()
    }, [])

    return (
        <>
            <div className='w-full dark:bg-darkaccent p-[16px] rounded-[4px] flex flex-col shadow-small overflow-hidden'>
                <p className='mt-[-16px] ml-[-16px] mb-[16px] p-[4px] leading-[1] w-fit p-[2px] text-[12px] uppercase font-bold bg-blue dark:bg-accent text-white dark:text-darkness'>
                    Filters
                </p>
                {!loading ? (
                    <div className='dark:text-offwhite grid grid-cols-[auto_1fr] gap-[16px]'>
                        {editableProductFilters.map((pf, outerIndex) => (
                            <>
                                <div className='py-[4px] px-[8px] h-fit border border-grey dark:border-darkgrey rounded-[4px] font-bold upercase text-blue dark:text-offwhite text-[18px] flex justify-between items-center gap-[16px]'>
                                    <p>
                                        {pf.group_name}
                                        {pf.values[0].unit && (
                                            <span className='font-light text-[16px]'>&nbsp;({pf.values[0].unit})</span>
                                        )}
                                    </p>
                                    <button
                                        onClick={() => {
                                            setShowEditGroup(true)
                                            setSelectedFilterGroup({
                                                name: pf.group_name,
                                                disjunctive: pf.values[0].disjunctive,
                                                feature_template: pf.values[0].feature_template || '',
                                                is_feature: pf.values[0].is_feature,
                                                match_score: pf.values[0].match_score,
                                                type: pf.values[0].type,
                                                id: pf.values[0].id,
                                                unit: pf.values[0].unit,
                                            })
                                        }}
                                    >
                                        <FaGear className='w-[16px] h-[16px] dark:text-grey' />
                                    </button>
                                </div>
                                <div>
                                    {pf.values.map((pfv, innerIndex) => (
                                        <div
                                            key={pfv.group_name + innerIndex}
                                            className='flex gap-[4px] mb-[4px] last:mb-0'
                                        >
                                            {pfv.type === 'range' ? (
                                                <input
                                                    className='w-full focus:outline-none dark:bg-faintplus rounded-[4px] py-[4px] px-[8px] border border-grey dark:border-darkgrey rounded-[4px]'
                                                    type='number'
                                                    value={pfv.value}
                                                    placeholder='Amount'
                                                    onChange={(e) => handleFilterChange(e, outerIndex, innerIndex)}
                                                />
                                            ) : (
                                                <p className='w-full dark:bg-faintplus rounded-[4px] py-[4px] px-[8px] border border-grey dark:border-darkgrey rounded-[4px]'>
                                                    {pfv.value}
                                                </p>
                                            )}
                                            {(!productFilters[outerIndex].values[innerIndex] ||
                                                pfv.value !== productFilters[outerIndex].values[innerIndex].value) && (
                                                <button
                                                    className='block py-[4px] px-[8px] bg-blue dark:bg-accent text-white dark:text-darkaccent rounded-[4px] font-bold uppercase'
                                                    onClick={() => handleSaveFilter(product.id!, pfv.id!, [pfv.value])}
                                                >
                                                    Save
                                                </button>
                                            )}
                                            {pfv.type !== 'range' && innerIndex === 0 && (
                                                <button
                                                    className='block py-[4px] px-[8px] whitespace-nowrap bg-blue dark:bg-accent text-white dark:text-darkaccent rounded-[4px] font-bold uppercase'
                                                    onClick={() =>
                                                        setSelectedFilterEditObject({
                                                            groupName: pf.group_name,
                                                            filterValues: Array.from(allFilterMap[pf.group_name]).map(
                                                                (val) => ({
                                                                    ...val,
                                                                    selected: !!pf.values.find(
                                                                        (foundFilter) => foundFilter.value === val.value
                                                                    ),
                                                                })
                                                            ),
                                                        })
                                                    }
                                                >
                                                    Edit {pf.group_name}
                                                </button>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </>
                        ))}
                        <button
                            className='w-fit text-blue border-blue uppercase font-bold dark:text-fire border-2 dark:border-fire py-[4px] px-[8px] rounded-[4px]'
                            onClick={() => setShowModal(true)}
                        >
                            New Filter Value
                        </button>
                    </div>
                ) : (
                    <div className='grid place-items-center p-[32px]'>
                        <LoadingGear />
                    </div>
                )}
            </div>
            {showModal && (
                <NewFilterModal
                    product={product}
                    loadingModal={loadingModal}
                    setShowModal={setShowModal}
                    handleCreateFilterValue={handleCreateFilterValue}
                />
            )}
            {showEditGroup && (
                <FilterGroupEditModal
                    loadingModal={loadingModal}
                    setShowModal={setShowEditGroup}
                    selectedFilterGroup={selectedFilterGroup}
                    setSelectedFilterGroup={setSelectedFilterGroup}
                    handleEditFilterValue={handleEditFilterGroup}
                />
            )}
            {selectedFilterEditObject && product.id && (
                <FilterValueModal
                    productId={product.id}
                    groupName={selectedFilterEditObject.groupName}
                    filterValues={selectedFilterEditObject.filterValues}
                    handleSave={handleSaveFilter}
                    setSelectedFilterEditObject={setSelectedFilterEditObject}
                />
            )}
        </>
    )
}
