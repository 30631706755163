import { cn } from 'helpers'

export default function FakeInput({
    value,
    label,
    required,
    className,
}: {
    value: string
    label?: string
    required?: boolean
    className?: string
}) {
    return (
        <div className='flex flex-col gap-[4px] w-full'>
            {label && (
                <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1] relative w-fit min-h-[12px]'>
                    {label}
                    {required && (
                        <span className='absolute top-0 right-[-10px] text-[14px] text-red dark:text-lightred'>*</span>
                    )}
                </label>
            )}
            <div
                className={cn(
                    'flex p-1 items-center h-[29.5px]  rounded-[4px] bg-white w-full dark:bg-darkbg1 border dark:border-darkgrey',
                    className
                )}
            >
                <p className='ml-[-1px]  leading-none h-fit text-base'>{value}</p>
            </div>
        </div>
    )
}
