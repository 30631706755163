import { FaPen, FaTimes } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { useDeleteAlert, useUpdateAlert } from '../../api/useQueries'
import { ChangeEvent, useContext, useState } from 'react'
import { ProcurementContext, ProcurementContextType } from '../../helpers'
import { useAllUsersQuery } from '../../../users/api/useQueries'
import { UserInit } from '../../../users/users.types'

export function ProductAlert({ alert }: { alert: any }) {
    const user = useSelector((state: any) => state.user)
    const { selectedCompany } = useContext<ProcurementContextType>(ProcurementContext)
    const [showDelete, setShowDelete] = useState(false)
    const [showEdit, setShowEdit] = useState<boolean>()
    const [editableFields, setEditableFields] = useState<any>({})

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setEditableFields((previousState: any) => ({
            ...structuredClone(previousState),
            [target.name]: target.type === 'checkbox' ? (target as HTMLInputElement).checked : target.value,
        }))
    }

    const updateAlert = useUpdateAlert(selectedCompany.id)
    const deleteAlert = useDeleteAlert(selectedCompany.id)

    const handleDeleteAlert = () => {
        deleteAlert.mutate(alert, {
            onSuccess: () => {
                setShowDelete(false)
            },
        })
    }

    function handleUpdate() {
        updateAlert.mutate(
            { id: alert.id, company_id: alert.company_id, ...editableFields },
            {
                onSuccess: () => {
                    setShowEdit(false)
                    setEditableFields({})
                },
            }
        )
    }

    return (
        <div
            className={`relative p-[4px] px-[8px] flex rounded-[4px] gap-3 border border-fire bg-fire/10 font-bold text-[14px] ${
                deleteAlert.isPending ? 'opacity-[0.5] pointer-events-none' : ''
            }`}
        >
            <div className='absolute top-[2px] left-[4px] rounded-full w-[26px] h-[26px] border-[1px] border-blue dark:border-grey flex font-semibold uppercase items-center justify-center'>
                {alert?.user_alias?.slice(0, 2)}
            </div>

            {!showEdit && <p className='ml-[34px]'>{alert.message}</p>}
            <div className='flex gap-3 ml-auto mr-[2px]'>
                {!showEdit && !showDelete && (
                    <button onClick={() => setShowEdit(true)} className='ml-auto'>
                        <FaPen className='text-blue dark:text-offwhite' />
                    </button>
                )}
                {!showDelete && !showEdit && (
                    <button onClick={() => setShowDelete(true)}>
                        <FaTimes />
                    </button>
                )}
            </div>
            {showEdit && (
                <div className='flex flex-col gap-[4px] w-full ml-[22px]'>
                    <textarea
                        id={'message'}
                        className='w-full font-semibold max-h-[100px] !text-[14px] !leading-snug bg-lightgrey border-0 focus:outline-none dark:text-white dark:bg-darkness p-1 rounded-[4px]'
                        value={editableFields.message ?? alert.message ?? ''}
                        name={'message'}
                        onChange={handleChange}
                    ></textarea>
                </div>
            )}
            {showDelete && (
                <div className='flex gap-[8px] self-center items-center justify-self-end'>
                    <button
                        onClick={handleDeleteAlert}
                        className={`${
                            deleteAlert.isPending ? 'opacity-[0.5] pointer-events-none' : 'pointer-events-auto'
                        } shrink-0 text-[14px] leading-[1] py-[3px] w-fit px-[8px] rounded-[4px] bg-red dark:bg-lightred text-white font-bold uppercase`}
                    >
                        Delete
                    </button>
                    <button
                        onClick={() => setShowDelete(false)}
                        className={`${
                            deleteAlert.isPending ? 'opacity-[0.5] pointer-events-none' : 'pointer-events-auto'
                        } text-[14px] leading-[1] py-[3px] w-fit px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkgrey font-bold uppercase`}
                    >
                        Cancel
                    </button>
                </div>
            )}
            {showEdit && (
                <div className='flex flex-col justify-between items-start'>
                    <div className='flex gap-3 justify-end items-start'>
                        <button
                            onClick={handleUpdate}
                            className={`${
                                updateAlert.isPending ? 'opacity-[0.9] pointer-events-none' : 'pointer-events-auto'
                            } shrink-0 text-[14px] leading-[1] py-[3px] px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase`}
                        >
                            {updateAlert.isPending ? 'SAVING...' : 'SAVE'}
                        </button>
                        <button
                            onClick={() => {
                                setShowEdit(false)
                                setEditableFields({})
                            }}
                            className={`${
                                updateAlert.isPending ? 'opacity-[0.9] pointer-events-none' : 'pointer-events-auto'
                            } shrink-0 text-[14px] leading-[1] py-[3px] px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkgrey text-black dark:text-offwhite font-bold uppercase`}
                        >
                            Cancel
                        </button>
                    </div>
                    <div className='text-xs flex flex-col items-start font-normal'>
                        <p>Author - {alert?.user_alias}</p>
                        <p>Alert Id - {alert?.id}</p>
                    </div>
                </div>
            )}
        </div>
    )
}
