import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { FilterGroupType } from '../ProductSetup.types'
import LabelWithInfo from './LabelWithInfo'

export default function FilterGroupEditModal({
    loadingModal,
    setShowModal,
    selectedFilterGroup,
    setSelectedFilterGroup,
    handleEditFilterValue,
}: {
    loadingModal: boolean
    setShowModal: Dispatch<SetStateAction<boolean>>
    selectedFilterGroup: FilterGroupType | null
    setSelectedFilterGroup: Dispatch<SetStateAction<FilterGroupType | null>>
    handleEditFilterValue: Function
}) {
    const modalRef = useRef(null)
    const [editableFilter, setEditableFilter] = useState<FilterGroupType>({
        name: '',
        feature_template: '{{ value }}',
        type: 'checkbox',
        disjunctive: true,
        is_feature: false,
        match_score: 0,
    })
    const [isValid, setIsValid] = useState(false)

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement }) => {
        setEditableFilter((previousState) => ({
            ...previousState,
            [target.name]: target.value,
        }))
    }
    const handleChangeCheckbox = ({ target }: { target: HTMLInputElement }) => {
        setEditableFilter((previousState) => ({
            ...previousState,
            [target.name]: target.checked,
        }))
    }
    const handleChangeNumber = ({ target }: { target: HTMLInputElement }) => {
        setEditableFilter((previousState) => ({
            ...previousState,
            [target.name]: parseInt(target.value),
        }))
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (modalRef.current === e.target) {
                setShowModal(false)
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    useEffect(() => {
        if (editableFilter.name.length && editableFilter.feature_template.includes('{{ value }}')) {
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    }, [editableFilter])

    useEffect(() => {
        setEditableFilter(
            selectedFilterGroup || {
                name: '',
                feature_template: '{{ value }}',
                type: 'checkbox',
                disjunctive: true,
                is_feature: false,
                match_score: 0,
            }
        )
    }, [selectedFilterGroup])

    return (
        <div
            ref={modalRef}
            className='fixed top-0 left-0 z-[50] w-full h-full bg-[rgb(0,0,0,0.5)] grid place-items-center'
        >
            <div className='max-w-[90%] max-h-[90%] overflow-auto bg-white dark:bg-darkaccent p-[32px] rounded-[4px] shadow-small relative'>
                <button
                    onClick={() => setShowModal(false)}
                    className='absolute top-[0px] right-[8px] text-red dark:text-lightred font-bold text-[32px] leading-[1]'
                >
                    &times;
                </button>
                <h2 className='text-[24px] font-light tracking-[2px] dark:text-offwhite uppercase leading-[1] mb-[16px]'>
                    Edit Filter Value
                </h2>
                <div className='flex flex-col gap-[8px] w-[300px]'>
                    <div className='flex flex-col gap-[4px] w-full'>
                        <LabelWithInfo
                            label='Group Name'
                            htmlFor='group_name'
                            info='This name will appear on the collection page as a filter option.'
                        />
                        <div className='relative w-full'>
                            <input
                                id='name'
                                type='text'
                                value={editableFilter.name}
                                name='name'
                                autoComplete='off'
                                className='py-[2px] px-[8px] w-full focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='flex gap-[8px]'>
                        <div className='flex flex-col gap-[4px] w-full'>
                            <LabelWithInfo
                                label='Type'
                                htmlFor='type'
                                info='This is the type of selector within the filter group. Checkboxes allow multiple selections, radios allow one, and ranges allow a high and low value.'
                            />
                            <select
                                id='type'
                                value={editableFilter.type}
                                name='type'
                                className='py-[2px] px-[8px] focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                onChange={handleChange}
                            >
                                <option value='checkbox'>Checkbox</option>
                                <option value='radio'>Radio</option>
                                <option value='range'>Range</option>
                            </select>
                        </div>
                        {editableFilter.type === 'range' && (
                            <div className='flex flex-col gap-[4px] w-full'>
                                <label
                                    className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                                    htmlFor='type'
                                >
                                    Unit
                                </label>
                                <input
                                    id='unit'
                                    type='text'
                                    value={editableFilter.unit}
                                    name='unit'
                                    className='py-[2px] px-[8px] focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                    onChange={handleChange}
                                />
                            </div>
                        )}
                    </div>
                    <div className='flex flex-col gap-[4px] w-full'>
                        <LabelWithInfo
                            label='Feature Template'
                            htmlFor='feature_template'
                            info='This is the formatting for the features section on each product card. The value is added dynamically in place of {{ value }}. This field must include {{ value }}.'
                        />
                        <input
                            id='feature_template'
                            type='text'
                            value={editableFilter.feature_template}
                            name='feature_template'
                            className='py-[2px] px-[8px] focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                            onChange={handleChange}
                        />
                    </div>
                    <div className='flex flex-col gap-[4px] w-full'>
                        <LabelWithInfo
                            label='Match Score'
                            htmlFor='match_score'
                            info='This field indicates how closely products with the same filter value are related for the compare similiar tool. Higher scores means more products are more closely related.'
                        />
                        <input
                            id='match_score'
                            type='number'
                            value={editableFilter.match_score}
                            name='match_score'
                            className='py-[2px] px-[8px] focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                            onChange={handleChangeNumber}
                        />
                    </div>
                    <div className='flex gap-[4px] mt-[4px]'>
                        <div className='relative rounded-[4px] bg-lightgrey dark:bg-darkness flex items-center justify-between gap-[4px] w-full'>
                            <LabelWithInfo
                                label='Disjunctive'
                                htmlFor='disjunctive'
                                info='Indicates whether a product should match every selection or just one of the selected values. Disjunctive means that only one match is required.'
                                labelClassName='cursor-pointer p-[8px] w-full h-full leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                            />
                            <input
                                id='disjunctive'
                                type='checkbox'
                                checked={editableFilter.disjunctive}
                                name='disjunctive'
                                className='absolute top-[50%] translate-y-[-50%] right-[8px] cursor-pointer focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                onChange={handleChangeCheckbox}
                            />
                        </div>
                        <div className='relative rounded-[4px] bg-lightgrey dark:bg-darkness flex items-center justify-between gap-[4px] w-full'>
                            <LabelWithInfo
                                label='Is Feature'
                                htmlFor='is_feature'
                                info='Indicates that the value should appear in the features section of each product card.'
                                labelClassName='cursor-pointer p-[8px] w-full h-full leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                            />
                            <input
                                id='is_feature'
                                type='checkbox'
                                checked={editableFilter.is_feature}
                                name='is_feature'
                                className='absolute top-[50%] translate-y-[-50%] right-[8px] cursor-pointer focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                onChange={handleChangeCheckbox}
                            />
                        </div>
                    </div>
                    {isValid && (
                        <button
                            disabled={loadingModal}
                            onClick={() => handleEditFilterValue(editableFilter, selectedFilterGroup)}
                            className='block mx-auto py-[4px] px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase mt-[16px]'
                        >
                            {loadingModal ? 'Working...' : 'Submit'}
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}
