import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { closePopout, isPrimObject } from '../helpers/index'
import { BsChevronDown, BsFillTrash3Fill } from 'react-icons/bs'
import { BiChevronDown } from 'react-icons/bi'
import { AiFillInfoCircle } from 'react-icons/ai'
import ReactOption from './ReactOption'

type optionWithHoverText = {
    option: any
    value?: any
    symbol?: any
    hoverText?: string
}
type ReactSelectProps = {
    name?: string
    options: string[] | optionWithHoverText[]
    selected: any
    icon?: any
    iconSide?: 'left' | 'right'
    setSelected: Dispatch<SetStateAction<any>>
    style?: string
    containerStyle?: string
    listStyle?: string
}
export default function ReactSelect({
    name,
    options,
    icon = (
        <BiChevronDown className='fill-black dark:fill-offwhite h-[20px] w-[20px] top-[6px] right-0 pointer-events-none' />
    ),
    iconSide = 'right',
    selected,
    setSelected,
    style,
    containerStyle,
    listStyle,
}: ReactSelectProps) {
    const [hovered, setHovered] = useState(selected)
    const [show, setShow] = useState(false)
    const [nameOrNum, setNameOrNum] = useState(name || `${Math.floor(Math.random() * Math.pow(10, 18))}`)

    useEffect(() => {
        const listenToWindow = (e: any) =>
            closePopout(e, [`js-select__${nameOrNum}`, `js-select__${nameOrNum}__option`], setShow, undefined, () => {
                setHovered(selected)
            })
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    function handleClick(clicked: any) {
        setShow(false)
        setSelected(clicked)
    }

    return (
        <div key={name} className={containerStyle}>
            {name && (
                <label className='flex items-center gap-x-[8px] uppercase text-[10px] font-bai font-bold text-blue dark:text-grey'>
                    {name.replaceAll('_', ' ')}
                </label>
            )}
            <div
                className={`relative flex gap-[4px] justify-between items-center js-select__${nameOrNum} w-full py-[3px] px-[6px] outline-0 border-[1px] border-grey dark:border-blue focus:border-[1px] dark:bg-darkness dark:text-white dark:focus:border-blue z-2 rounded caret-transparent ${
                    show ? 'rounded-t rounded-b-none' : ''
                } ${style}`}
                onClick={() => setShow(!show)}
                tabIndex={0}
            >
                {iconSide === 'left' && (
                    <div className='flex relative justify-center items-center w-[20px] h-[20px]'>{icon}</div>
                )}
                <input
                    className={`bg-transparent pointer-events-none capitalize`}
                    type='text'
                    value={typeof selected === 'object' ? selected.name : selected}
                    tabIndex={-1}
                />
                {iconSide === 'right' && (
                    <div className='flex relative justify-center items-center w-[20px] h-[20px]'>{icon}</div>
                )}
                <div
                    className={`js-dropdown absolute top-[100%] left-[-1px] min-w-[calc(100%+2px)] ${
                        show ? '' : 'hidden'
                    } border-[1px] border-grey dark:border-blue bg-[white] dark:text-offwhite dark:bg-darkness flex flex-col max-h-[500px] overflow-auto z-0 ${listStyle}`}
                >
                    {show &&
                        options.map((option, index) => (
                            <ReactOption
                                key={nameOrNum + index}
                                option={option}
                                iconSide={iconSide}
                                setSelected={handleClick}
                                hovered={hovered}
                                setHovered={setHovered}
                            />
                        ))}
                </div>
                {show && isPrimObject(hovered) && hovered.hoverText && (
                    <div
                        className={`absolute pointer-events-none top-[0px] left-[calc(100%+8px)] w-full z-[5] bg-white dark:text-offwhite dark:bg-darkaccent border-[1px] shadow-small p-[8px] rounded-[4px] font-normal normal-case ${listStyle}`}
                    >
                        <pre className='mb-[8px] font-bai break-words whitespace-pre-wrap'>{hovered.hoverText}</pre>
                    </div>
                )}
            </div>
        </div>
    )
}
