import { ProcessingTimesRow, ProductListProps, ProductProcessingTimesRow } from '../../ProcessingTimesScreen.types'

export default function ProductList({
    productProcessingTimes = [],
    setSelectedProduct,
    search,
    filter = '',
    page = 1,
}: ProductListProps) {
    const searchlower = search.toLowerCase()
    const filteredProducts = filterMessages(productProcessingTimes, searchlower, page)
    /**
     * Returns a filtered list of messages based on the search, filteres, and page
     */
    function filterMessages(productProcessingTimes: ProductProcessingTimesRow[], search: string, page: number) {
        const startIndex = (page - 1) * 50
        const endIndex = page * 50
        return productProcessingTimes.slice(startIndex, endIndex)
    }
    return (
        <>
            <div className='sticky top-[50px] grid grid-cols-[72px_200px_1fr_1fr_1fr_1fr] col-span-full border-b border-lightgrey bg-blue text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                <div>&nbsp;</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Title</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>PDP</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Cart</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Vendor PDP</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Vendor Cart</div>
            </div>
            {filteredProducts.map((product) => {
                const activeProductPDPMessage = product.active_pdp_processing_time || ({} as ProcessingTimesRow)
                const activeProductCartMessage = product.active_cart_processing_time || ({} as ProcessingTimesRow)
                const activeVendorPDPMessage = product.active_vendor_pdp_processing_time || ({} as ProcessingTimesRow)
                const activeVendorCartMessage = product.active_vendor_cart_processing_time || ({} as ProcessingTimesRow)
                return (
                    <div
                        key={product.product_gid}
                        onClick={() => setSelectedProduct(product)}
                        className='grid grid-cols-[72px_200px_1fr_1fr_1fr_1fr] items-center border-b border-lightgrey dark:border-darkgrey hover:bg-lightgrey dark:hover:bg-darkness cursor-pointer'
                    >
                        <div className='p-[8px] w-[72px] h-[72px] shrink-0'>
                            <img
                                className='border border-grey dark:border-darkgrey w-full h-full object-contain rounded-[4px]'
                                src={product.img_src || '/default-product-img.png'}
                            />
                        </div>
                        <div className='p-[8px] font-bold dark:text-offwhite'>{product.product_title}</div>
                        <div className='p-[8px]'>
                            <p className='flex font-bold text-darkgrey dark:text-offwhite uppercase gap-[6px]'>
                                {activeProductPDPMessage.pdp_line_1}
                            </p>
                            <p className='flex gap-[6px] dark:text-offwhite'>{activeProductPDPMessage.pdp_line_2}</p>
                        </div>
                        <div className='p-[8px]'>
                            <p className='flex font-bold text-darkgrey dark:text-offwhite uppercase gap-[6px]'>
                                {activeProductCartMessage.cart_line_1}
                            </p>
                        </div>
                        <div className='p-[8px]'>
                            <p className='flex font-bold text-darkgrey dark:text-offwhite uppercase gap-[6px]'>
                                {activeVendorPDPMessage.pdp_line_1}
                            </p>
                            <p className='flex gap-[6px] dark:text-offwhite'>{activeVendorPDPMessage.pdp_line_2}</p>
                        </div>
                        <div className='p-[8px]'>
                            <p className='flex font-bold text-darkgrey dark:text-offwhite uppercase gap-[6px]'>
                                {activeVendorCartMessage.cart_line_1}
                            </p>
                        </div>
                    </div>
                )
            })}
        </>
    )
}
