import { useQueryClient } from '@tanstack/react-query'
import queryKeys from 'tasksV2/api/queryKeys'
import { TASK_PRIORITIES } from 'tasksV2/constants/tasks'

export default function TaskPriority({
    taskId,
    priority,
    mutation,
}: {
    taskId: number
    priority?: string
    mutation: any
}) {
    const queryClient = useQueryClient()
    const handleChange = ({ target }: { target: HTMLSelectElement }) => {
        queryClient.setQueryData(queryKeys.tasks.detail(taskId.toString()).queryKey, (oldData: any) => {
            return {
                ...oldData,
                task: { ...oldData.task, priority: target.value },
            }
        })
        mutation.mutate({
            id: taskId,
            priority: target.value,
        })
    }
    return (
        <select
            className='border border-lightgrey dark:border-darkgrey rounded p-1 w-full dark:bg-darkbg1'
            id='task_priority'
            name='task_priority'
            value={priority}
            onChange={handleChange}
        >
            <option value=''>No Priority Selected</option>
            {TASK_PRIORITIES.map((priority: any) => (
                <option value={priority}>{priority}</option>
            ))}
        </select>
    )
}
