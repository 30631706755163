import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { closePopout, parseResObject, sendToast, vFetch } from '../helpers'
import { FulfillmentOrderInit, LineItemInit, OrderInit, OrderTimelineEvent, defaultOrder } from './orders.types'
import { LEGACY_CODE_renderTimelineEvent, refreshPurchaseOrders, refreshTasks } from './orders.helpers'
import FulfilledItems from './components/lineItems/FulfilledItems'
import UnfulfilledItems from './components/lineItems/UnfulfilledItems'
import RemovedItems from './components/lineItems/RemovedItems'
import PaymentStatus from './components/PaymentStatus'
import Customer from './components/Customer'
import FraudAnalysis from './components/FraudAnalysis'
import Tags from './components/Tags'
import OrderHeader from './components/OrderHeader'
import { TaskEditInit } from '../tasks/tasks.types'
import { PurchaseOrderEditInit } from '../purchaseOrders/purchaseOrders.types'

export default function OrderScreen() {
    const { orderId } = useParams()
    const [skinnyMode, setSkinnyMode] = useState<boolean>(
        localStorage.getItem('skinny_mode') === 'true' ? true : false || false
    )
    const navigate = useNavigate()
    const [initRender, setInitRender] = useState(true)
    const [events, setEvents] = useState<OrderTimelineEvent[]>([])
    const [fulfillmentOrders, setFulfillmentOrders] = useState<FulfillmentOrderInit[]>([])
    const [order, setOrder] = useState<OrderInit>(defaultOrder)
    const [tags, setTags] = useState<string[]>([])

    const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrderEditInit[]>([])
    const [tasks, setTasks] = useState<TaskEditInit[]>([])

    const orderActions = [
        {
            name: `${skinnyMode ? 'Wide mode' : 'Skinny Mode'}`,
            action: () => {
                setSkinnyMode(!skinnyMode)
            },
        },
    ]

    const eventSource: {
        foreign_id: number | null | undefined
        foreign_table: string
    } = {
        foreign_id: order.id,
        foreign_table: 'orders',
    }

    const [showDiscount, setShowDiscount] = useState<string>('')

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => closePopout(e, ['js-order__discount'], setShowDiscount)

        window.addEventListener('click', listenToWindow)

        vFetch(`/v1/orders/${orderId}`, {
            cb: (res: any) => {
                if (res.success) {
                    const resOrder: any = parseResObject(res.order)

                    // refreshTasks(resOrder, setTasks, () => { },)
                    refreshPurchaseOrders(resOrder, setPurchaseOrders)
                    vFetch(
                        `/v1/products/limited/handles?gid=${resOrder.line_items.map(
                            (i: LineItemInit) => `gid://shopify/Product/${i.product_id}`
                        )}`,
                        {
                            cb: (pRes: any) => {
                                if (pRes.success) {
                                    pRes.products.forEach(
                                        (p: any) =>
                                            (resOrder.line_items.find(
                                                (i: LineItemInit) =>
                                                    i.product_id == p.gid.replace('gid://shopify/Product/', '')
                                            ).handle = p.handle)
                                    )
                                    setOrder(resOrder)
                                    setTags(resOrder.tags.split(', '))

                                    let unparsedNote = resOrder.note
                                    let stringArray = []
                                    if (unparsedNote) {
                                        while (unparsedNote.includes('\n')) {
                                            const index = unparsedNote.indexOf('\n')
                                            unparsedNote = unparsedNote.replace('\n', '')
                                            const section = unparsedNote.slice(0, index)
                                            stringArray.push(section)
                                            unparsedNote = unparsedNote.slice(index)
                                        }
                                    }
                                }
                            },
                        }
                    )
                }
            },
        })
        vFetch(`/v1/orders/${orderId}/fulfillment-orders`, {
            cb: (res: any) => setFulfillmentOrders(res.fulfillment_orders),
        })
        vFetch(`/v1/orders/${orderId}/events`, {
            cb: (res: any) => setEvents(res.events.map((event: any) => event.node)),
        })

        setInitRender(false)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    useEffect(() => {
        localStorage.setItem('skinny_mode', skinnyMode.toString())
    }, [skinnyMode])

    useEffect(() => {
        if (!initRender && JSON.stringify(order.tags.split(', ')) !== JSON.stringify(tags)) {
            vFetch(`/v1/orders/${orderId}`, {
                method: 'PUT',
                body: JSON.stringify({ id: order.id, fields: { tags } }),
            })
        }
    }, [tags])

    return (
        <div className={`flex w-[100%] ${skinnyMode && 'max-w-[1100px]'} gap-[8px] flex-wrap m-[auto]`}>
            {/* <OrderHeader
                order={order}
                tasks={tasks.filter((t) => !!(t as TaskEditInit).id) as TaskEditInit[]}
                actions={orderActions}
                addNewTask={() =>
                    sendToast({ message: 'This button performs a legacy operation and no longer works. Sorry!' })
                }
            /> */}

            <div className='flex flex-col basis-[calc(65%)] grow-[1] min-w-[450px] gap-[8px]'>
                <UnfulfilledItems order={order} fulfillmentOrders={fulfillmentOrders} />
                <FulfilledItems order={order} />
                <RemovedItems order={order} fulfillmentOrders={fulfillmentOrders} />
                <PaymentStatus order={order} fulfillmentOrders={fulfillmentOrders} />
                <div className='bg-white dark:bg-darkaccent shadow-small rounded p-[16px] py-[32px]'>
                    {events
                        .slice()
                        .reverse()
                        .map((event) => LEGACY_CODE_renderTimelineEvent(event))}
                </div>
            </div>

            {/* right flex */}
            <div className='flex flex-col basis-[calc(35%-32px)] min-w-[250px] grow-[1] gap-[8px]'>
                {!!purchaseOrders.length && (
                    <div className='grid bg-white dark:bg-darkaccent shadow-small rounded dark:text-offwhite [&>*]:p-[16px] [&>*]:border-b-[1px] [&>*]:dark:border-darkgrey [&>:last-child]:border-0'>
                        <div>
                            <h2 className='text-[20px] font-bold mb-[4px]'>Purchase Orders</h2>
                            <div className='flex gap-[8px]'>
                                {purchaseOrders.map((PO) => (
                                    <span
                                        className='js-dont-navigate text-blue dark:text-accent hover:underline cursor-pointer font-semibold mr-[4px]'
                                        onContextMenu={() => {
                                            window.open(`/orders/purchase-orders/${PO}`)
                                        }}
                                        onClick={() => {
                                            ;(window.event as MouseEvent).ctrlKey
                                                ? window.open(`/orders/purchase-orders/${PO}`)
                                                : navigate(`/orders/purchase-orders/${PO}`)
                                        }}
                                    >
                                        {PO.id}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                {!!tasks.length && (
                    <div className='grid bg-white dark:bg-darkaccent shadow-small rounded dark:text-offwhite [&>*]:p-[16px] [&>*]:border-b-[1px] [&>*]:dark:border-darkgrey [&>:last-child]:border-0'>
                        <div>
                            <h2 className='text-[20px] font-bold mb-[4px]'>Tasks</h2>
                            <div className='flex gap-[8px]'>
                                {tasks.map((task) => (
                                    <span
                                        className='js-dont-navigate text-blue dark:text-accent hover:underline cursor-pointer font-semibold mr-[4px]'
                                        onContextMenu={() => {
                                            window.open(`/tasks/${task.id}`)
                                        }}
                                        onClick={() => {
                                            ;(window.event as MouseEvent).ctrlKey
                                                ? window.open(`/tasks/${task.id}`)
                                                : navigate(`/tasks/${task.id}`)
                                        }}
                                    >
                                        {task.id}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                <div className='grid bg-white dark:bg-darkaccent shadow-small rounded dark:text-offwhite [&>*]:p-[16px] [&>*]:border-b-[1px] [&>*]:dark:border-darkgrey [&>:last-child]:border-0'>
                    <div>
                        <h2 className='text-[20px] font-bold mb-[4px]'>Notes</h2>
                        <div className='grid gap-[8px]'>{order.note}</div>
                    </div>
                    {order.note_attributes.length > 0 && (
                        <div>
                            <h2 className='text-[20px] font-bold mb-[4px]'>Additional Details</h2>
                            {order.note_attributes.map((attr) => {
                                if (attr.name === 'shipping_estimate') {
                                    return (
                                        <div className='mb-[8px]'>
                                            <h3 className='capitalize font-semibold text-[16px] mb-[4px]'>
                                                {attr.name.replace('_', ' ')}
                                            </h3>
                                            {attr.value.split('\n').map((line) => (
                                                <p className='mb-[8px] text-[14px] break-all'>
                                                    {line.split(':').join(': ')}
                                                </p>
                                            ))}
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className='mb-[8px]'>
                                            <h3 className='capitalize font-semibold text-[16px] mb-[4px]'>
                                                {attr.name.replace('_', ' ')}
                                            </h3>
                                            {attr.value.split('\n').map((line) => (
                                                <p className='mb-[8px] text-[14px] break-all'>{line}</p>
                                            ))}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    )}
                </div>
                {/* <Customer order={order} eventSource={eventSource} onEmailSubmit={onEmailSubmit} /> */}
                <FraudAnalysis order={order} />
                <Tags tags={tags} setTags={setTags} />
            </div>
        </div>
    )
}
