import { useQuery } from '@tanstack/react-query'
import { vFetch } from 'helpers'
import { Link } from 'react-router-dom'

export default function Blacklist() {
    const blacklistQuery = useQuery({
        queryKey: ['marketing', 'email', 'blacklist'],
        queryFn: () => vFetch('/v1/marketing/followUpEmail/blacklist'),
    })
    const blacklist = blacklistQuery.data?.blacklist || []
    return (
        <>
            <h2 className='font-semibold leading-none text-lg mb-2'>Blacklisted Customers</h2>
            <div className='bg-bg1 dark:bg-darkbg1 shadow-sm rounded overflow-hidden border border-lightgrey dark:border-darkgrey text-sm'>
                <div className='grid grid-cols-3 bg-blue text-white dark:text-offwhite dark:bg-darkbg2 font-bold'>
                    <p className='p-2'>ID</p>
                    <p className='p-2'>Name</p>
                    <p className='p-2'>Date Unsubscribed</p>
                </div>
                {blacklist.map((customer: any) => (
                    <Link
                        to={`/customers?customer_id=${customer.customer_id}`}
                        className='w-full text-left grid grid-cols-3 border-b border-lightgrey dark:border-darkgrey hover:bg-lightgrey dark:hover:bg-darkbg2'
                    >
                        <p className='p-2 capitalize'>{customer.customer_id}</p>
                        <p className='p-2 capitalize'>{customer.calculated_full_name}</p>
                        <p className='p-2 capitalize'>{new Date(customer.created_at).toDateString()}</p>
                    </Link>
                ))}
            </div>
        </>
    )
}
