import { useContext } from 'react'
import { UserInit } from '../../../users/users.types'
import { ProcurementContext, ProcurementContextType, formatDate } from '../../helpers'

export default function CompanyEmail({ email }: { email: any }) {
    const { users } = useContext<ProcurementContextType>(ProcurementContext)
    const user = users.find((u) => u.id === email.user_id) || ({} as UserInit)

    return (
        <div className='w-full relative p-[16px] pt-[20px] rounded-[4px] border border-blue/25 dark:border-darkgrey dark:bg-darkness shadow-md'>
            <p className='absolute top-0 left-0 bg-blue dark:bg-accent rounded-tl-[4px] text-white dark:text-darkness leading-[1] text-[10px] uppercase font-bold p-[2px]'>
                Email
            </p>
            <div className='flex justify-between mb-[8px]'>
                <p className='text-[14px]'>
                    <span className='font-bold'>{email.subject}</span>
                </p>
                <p className='font-bold text-[12px]'>{formatDate(new Date(email.created_at || email.delivered_at))}</p>
            </div>
            <div className='rounded-[4px] dark:text-offwhite'>
                <div className='flex flex-col mb-[16px]'>
                    <p className='text-[14px]'>
                        from{' '}
                        <span className='font-bold'>
                            {user?.first_name} {user?.last_name}
                        </span>
                    </p>
                    <p className='text-[14px]'>
                        to <span className='font-bold'>{email.recipient.join(', ')}</span>
                    </p>
                </div>
                <div dangerouslySetInnerHTML={{ __html: email.body }}></div>
            </div>
        </div>
    )
}
