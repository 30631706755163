import { createSlice } from '@reduxjs/toolkit'
const procurementTasksSlice = createSlice({
    name: 'procurementTaskFilterParams',
    initialState: {
        params: undefined,
    },
    reducers: {
        setProcurementTaskFilterParams: (state, action) => {
            return action.payload
        },
    },
})

export const getProcurementTaskFilterParams = (state) => state.procurementTaskFilterParams
export const { setProcurementTaskFilterParams } = procurementTasksSlice.actions
export default procurementTasksSlice.reducer
