import Category from '../listItems/Category'
import { CategoryType } from '../../helpers'

export default function CategoryList({ categories }: { categories: CategoryType[] }) {
    return (
        <div className='p-2'>
            {categories.map((category) => {
                return <Category key={category.id} category={category} />
            })}
        </div>
    )
}
