import React from 'react'
import { useSelector } from 'react-redux'
import { cn } from '../../helpers'

// V2 for uncontrolled inputs with react hook form

export default function InputV2({
    field,
    register,
    id,
    label,
    type,
    required = false,
    disabled = false,
    placeholder,
    readOnly = false,
    className,
    onclick,
    autoFocus,
    errors,
}: {
    field: string
    register: any
    id: string
    label?: string
    type: string
    required?: boolean
    disabled?: boolean
    placeholder?: string
    readOnly?: boolean
    className?: string
    onclick?: any
    autoFocus?: boolean
    errors?: any
}) {
    const settings = useSelector((state: any) => state.settings)
    const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    return (
        <div className='flex flex-col gap-[4px] w-full'>
            {label && (
                <label
                    htmlFor={id}
                    className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1] relative w-fit min-h-[12px]'
                >
                    {label}
                    {required && (
                        <span className='absolute top-0 right-[-10px] text-[14px] text-red dark:text-lightred'>*</span>
                    )}
                </label>
            )}
            <input
                {...register(field, { required: required, pattern: field === 'email' ? validEmailRegex : undefined })}
                style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                className={cn(
                    `w-full text-[16px] leading-none min-h-[29.5px] bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]`,
                    className
                )}
                autoFocus={autoFocus}
                id={id}
                autoComplete='off'
                type={type}
                placeholder={placeholder}
                disabled={disabled ? true : false}
                readOnly={readOnly}
                onClick={onclick}
            />
            {errors && errors[field] && errors[field].type === 'required' && (
                <span className='text-danger dark:text-lightred'>This is required</span>
            )}
            {errors && field === 'email' && errors[field] && errors[field].type === 'pattern' && (
                <span className='text-danger dark:text-lightred'>Invalid Email</span>
            )}
        </div>
    )
}
