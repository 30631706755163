import MainSessionChart from './components/MainSessionChart'
import TopIPAddresses from './components/TopIPAddresses'
import TopISPs from './components/TopISPs'

export default function SessionAnalyticsScreen() {
    return (
        <div>
            <MainSessionChart />
            <div className='my-4'>
                <TopIPAddresses />
            </div>
            <TopISPs />
        </div>
    )
}
