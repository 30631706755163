import { CgFileDocument } from "react-icons/cg";
import { FaBookOpen, FaBriefcase, FaCode } from "react-icons/fa";
import { FaCircleUser, FaMoneyBill1Wave, FaPeopleGroup } from "react-icons/fa6";
import { FiPackage } from "react-icons/fi";
import { GrMoney } from "react-icons/gr";
import { IoPerson } from "react-icons/io5";
import { MdAdminPanelSettings } from "react-icons/md";
import { RiCustomerService2Fill } from "react-icons/ri";

export const iconMap = {
    person: IoPerson,
    box: FiPackage,
    book: FaBookOpen,
    devIcon: FaCode,
    paper: CgFileDocument,
    briefcase: FaBriefcase,
    Admin: MdAdminPanelSettings,
    person2: FaCircleUser,
    people: FaPeopleGroup,
    money: GrMoney,
    headset: RiCustomerService2Fill,
    money2: FaMoneyBill1Wave,
}

export const toolbarOptions = [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    [{ 'align': [] }],
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    // ['blockquote', 'code-block'],
    ['blockquote'],
    ['link', 'image', 'video'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    ['clean']                                         // remove formatting button
  ];

  export const modules = {toolbar: toolbarOptions}