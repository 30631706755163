import { TbAntennaBars1, TbAntennaBars3, TbAntennaBars4, TbAntennaBars5 } from 'react-icons/tb'

export const TASK_STATUSES = ['To Do', 'In Progress', 'In Review', 'Blocked', 'Cancelled', 'Completed']
export const TASK_PRIORITIES = ['Low', 'Medium', 'High', 'Urgent']
export const ACTIVE_BRIDGE_TABLE_RESOURCE_NAMES = [
    'order',
    'draft_order',
    'project',
    'company',
    'customer',
    'customer_tax_exemption',
].sort()
export const ACTIVE_PROJECT_BRIDGE_TABLE_RESOURCE_NAMES = ['roadmap']
export const SHOW_EXISTING_TASK_ALERT_RESOURCE_NAMES = ['order']

export const priorityMap: { [key: string]: any } = {
    Low: {
        icon: TbAntennaBars1,
        className: '',
    },
    Medium: {
        icon: TbAntennaBars3,
        className: '',
    },
    High: {
        icon: TbAntennaBars4,
        className: '',
    },
    Urgent: {
        icon: TbAntennaBars5,
        className: 'text-red dark:text-lightred',
    },
}

export interface TaskV2 {
    id: number
    associations: any
    // associations: {
    //     resource_name: string
    //     resource_id: number
    //     readable_name: string
    // }
    alert_offset: number
    department_id: number
    description: string
    due_at: Date
    estimate_id: number
    is_escalated: number
    parent_task_id: number
    pinned: number
    priority: string
    recurring_task_id: number
    send_email_alert: number
    send_slack_alert: number
    status: string
    title: string
    type_id: number
    created_by: number
    created_at: Date
    updated_at: Date
    string_due_at: string
    store_id: number
}
export interface RecurringTask {
    id: number
    active: 0 | 1
    created_at: Date
    failure_count: number
    interval_type: string
    interval_value: number
    starting_at: any
    task_data: TaskV2
    store_id: number
    updated_at: Date
}

export interface Project {
    id: number
    title: string
    description: string
    status: string
    start_date: Date
    target_date: Date
    project_lead: number
    pinned: number
    store_id: number
    created_at: Date
    updated_at: Date
    associations: any
    // associations: {
    //     resource_name: string
    //     resource_id: number
    //     readable_name: string
    // }
}

export interface RoadMap {
    associations: any
    created_at: Date
    description: string
    id: number
    pinned: 0 | 1
    private: 0 | 1
    start_date: Date
    status: string
    store_id: number
    target_date: Date
    title: string
    updated_at: Date
}

export interface TaskDepartment {
    created_at: Date
    id: number
    private: 0 | 1
    allow_descriptions: 1 | 0
    store_id: number
    title: string
    updated_at: Date
    users: any
}
