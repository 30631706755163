import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setUser } from '../redux/user'
import { setSettings } from '../redux/settings'
import './LoginScreen.css'
import ForgotPasswordModal from './ForgotPasswordModal'
import { colorSettingsDark, colorSettingsLight, handleLocalStorage, vFetch } from '../helpers'

export default function LoginScreen() {
    const user = useSelector<any, any>((state) => state.user)
    const app = useSelector<any, any>((state) => state.app)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState<boolean>(false)
    const handleLogin = (e: any) => {
        e.preventDefault()
        const email = e.target.email.value
        const password = e.target.password.value
        vFetch(`/v1/users/login`, {
            Authorization: null,
            method: 'POST',
            body: JSON.stringify({
                email,
                password,
                app,
            }),
            cb: (res: any) => {
                if (res.success) {
                    handleLocalStorage.setItem('session_token', res.token)
                    dispatch(
                        setUser({
                            firstName: res.first_name,
                            lastName: res.last_name,
                            email: res.email,
                            user_id: res.user_id,
                            id: res.id,
                            type: res.type,
                            roles: res.roles,
                            store_ids: res.store_ids,
                            access: JSON.parse(res.access),
                        })
                    )
                    if (!res.store_ids.includes(localStorage.getItem('store_id'))) {
                        localStorage.setItem('store_id', res.store_ids.split(',')[0])
                    }
                    vFetch(`/v1/stores/${localStorage.getItem('store_id')}`, {
                        cb: (storeRes: any) => {
                            if (storeRes.success) {
                                dispatch(
                                    setSettings({
                                        logo: storeRes.storeInfo.logo,
                                        color_set: storeRes.storeInfo.color_set,
                                    })
                                )
                                const color_set = storeRes.storeInfo.color_set
                                localStorage.setItem('color_set', color_set)
                                colorSettingsLight.forEach((setting) => {
                                    const colors = JSON.parse(color_set)
                                    document.documentElement.style.setProperty(`--${setting}`, `${colors[setting]}`)
                                })
                                colorSettingsDark.forEach((setting) => {
                                    const colors = JSON.parse(color_set)
                                    if (setting === 'darkBackgroundPrimary') {
                                    }
                                    document.documentElement.style.setProperty(`--${setting}`, `${colors[setting]}`)
                                })
                            }
                        },
                    })
                    if (sessionStorage.getItem('stored_path')) {
                        const storedPath = sessionStorage.getItem('stored_path')
                        sessionStorage.removeItem('stored_path')
                        navigate(storedPath!)
                    } else navigate('/home')
                }
            },
        })
    }
    return (
        <div className='login relative '>
            {showForgotPasswordModal && <ForgotPasswordModal setShowForgotPasswordModal={setShowForgotPasswordModal} />}
            <h1 className='login__heading'>Welcome</h1>
            <form onSubmit={handleLogin} className='login__form'>
                <input className='login__input' type='email' name='email' id='email' placeholder='email' />
                <input className='login__input' type='password' name='password' id='password' placeholder='password' />
                <button type='submit' className='login__submit'>
                    SUBMIT
                </button>
            </form>
            <p className='login__forgot' onClick={() => setShowForgotPasswordModal(true)}>
                Forgot your password?
            </p>
            <style>
                {`
                    body {
                        padding: 0;
                        margin: 0;
                        box-shadow: none;
                        background: linear-gradient(
                        270deg,
                        #92b3d1 0%,
                        rgba(137, 208, 5, 0.3) 60%,
                        rgba(255, 255, 255, 0.7) 100%
                        ) !important;
                    }
                    body::after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        background-size: 50px 50px;
                        background-image: linear-gradient(to right, #eeeeee 2px, transparent 2px),
                        linear-gradient(to bottom, #eeeeee 2px, transparent 2px);
                        z-index: -1;
                        mix-blend-mode: color-burn;
                        pointer-events: none;
                    }
                    .topbar {
                        background: transparent !important;
                    }
                `}
            </style>
        </div>
    )
}
