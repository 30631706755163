import { createSlice } from '@reduxjs/toolkit'
import { defaultColorsFP } from '../helpers'
const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        theme: localStorage.getItem('theme'),
        color_set: `${defaultColorsFP}`,
        logo: 'https://fpdash-bucket.s3.amazonaws.com/logo-1708628621015.png',
        hidebar: false,
        showKnowledgeScreen: false,
    },
    reducers: {
        setSettings: (state, action) => {
            const payload = action.payload
            const newState = { ...state, ...payload }
            return newState
        },
    },
})

export const getSettings = (state) => state.settings
export const { setSettings } = settingsSlice.actions
export default settingsSlice.reducer
