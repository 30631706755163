import { TaskFilterValues } from 'procurement/Procurements.types'
import { procurementQueries } from 'procurement/api/procurementQueryKeys'
import { useCompanyDetailQuery } from 'procurement/api/useQueries'
import CategoryList from 'procurement/components/lists/CategoryList'
import CreateCompanyModalV2 from 'procurement/components/modals/CreateCompanyModalV2'
import ParentCompanyModal from 'procurement/components/modals/ParentCompanyModal'
import { CompanyProvider, ProcurementContext, ProcurementContextType } from 'procurement/helpers'
import { useContext, useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import RefreshButton from '../custom_components/RefreshButton'
import { Button } from '../custom_components/component_Basics/Button'
import { cn } from '../helpers'
import VendorListV2 from './components/VendorListV2'
import VendorModalV2 from './components/VendorModalV2'
import { UserSelectedVendorFilterValues } from './constants/Vendors.types'

export default function VendorScreen() {
    const { view, activeModal, toggleModal, handleChangeView, categories, selectedCompany } =
        useContext<ProcurementContextType>(ProcurementContext)
    const [searchParams, setSearchParams] = useSearchParams()
    const urlParamTaskId = searchParams.get('task_id')
    const urlParamCompanyId = searchParams.get('company_id')
    const [search, setSearch] = useState('')
    const lastViewedCompany = localStorage.getItem('lastViewedCompany') || undefined

    const [userSelectedCompanyFilterValues, setUserSelectedCompanyFilterValues] =
        useState<UserSelectedVendorFilterValues>({
            lifecycle_statuses: [],
            vendor_types: [],
            lead_types: [],
            users: [],
        })

    const [userSelectedTaskFilterValues, setUserSelectedTaskFilterValues] = useState<TaskFilterValues>({
        statuses: ['OPEN'],
        users: [],
        due_at_options: ['custom', 'overdue'],
    })

    const companyDetailQuery = useCompanyDetailQuery(urlParamCompanyId ? parseInt(urlParamCompanyId) : undefined)
    const { companies: company } = companyDetailQuery?.data || {}
    useEffect(() => {
        if (company) {
            toggleModal('company', company[0])
        }
    }, [company])

    useEffect(() => {
        if (urlParamTaskId) {
            setSearch(urlParamTaskId)
            handleChangeView('tasks')
            setUserSelectedTaskFilterValues({
                statuses: [],
                users: [],
                due_at_options: [],
            })
        }
    }, [urlParamTaskId])

    useEffect(() => {
        if (activeModal) {
            document.body.classList.add('overflow-y-hidden')
        } else {
            document.body.classList.remove('overflow-y-hidden')
        }
        return () => document.body.classList.remove('overflow-y-hidden')
    }, [activeModal])

    return (
        <div className=''>
            <div className='flex justify-between mb-[8px] bg-bg2 relative dark:bg-darkbg2'>
                <div className='flex gap-4'>
                    <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Vendor Info</h1>
                </div>
                <div className='flex gap-[8px] absolute top-[-16px] right-2'>
                    {/* <MoreActions options={options} displayContent={{ icon: FaBars }} /> */}
                    <RefreshButton
                        queryKeys={[
                            procurementQueries.companies._def.toString(),
                            procurementQueries.tasks._def.toString(),
                        ]}
                    />
                </div>
            </div>
            <div className='flex justify-between relative bg-bg1 dark:bg-darkbg2 mb-2'>
                <div className='flex gap-1 mb-2 mt-2 bg-2'>
                    <button
                        onClick={() => {
                            handleChangeView('companies')
                        }}
                        className={`mb-[-16px] py-1 px-[16px] rounded-t-[4px] font-bold  max-h-10 ${
                            view === 'companies'
                                ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                                : 'bg-lightgrey dark:bg-darkness dark:text-lightgrey dark:border dark:border-darkgrey dark:border-b-0'
                        }`}
                    >
                        Vendors
                    </button>
                    {/* <button
                        onClick={() => {
                            handleChangeView('tasks')
                        }}
                        className={`mb-[-16px] py-1 px-[16px] rounded-t-[4px] font-bold  max-h-10 ${
                            view === 'tasks'
                                ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                                : 'bg-lightgrey dark:bg-darkness dark:text-lightgrey dark:border dark:border-darkgrey dark:border-b-0'
                        }`}
                    >
                        Tasks
                    </button> */}
                </div>
                <div className='absolute right-52 top-[-8px] bg-bg1 dark:bg-darkbg2 flex items-center gap-8'>
                    <div className='flex gap-3'>
                        <Link
                            draggable={false}
                            className={cn(!lastViewedCompany && 'pointer-events-none opacity-50', '')}
                            to={`/vendorsInfo${lastViewedCompany}`}
                        >
                            <Button variant={'outline'}>Return to Last Visited</Button>
                        </Link>
                        <Link
                            draggable={false}
                            className={cn(!lastViewedCompany && 'pointer-events-none opacity-50', '')}
                            to={`/vendors`}
                        >
                            <Button variant={'outline'}>Legacy Vendor Screen</Button>
                        </Link>
                    </div>
                </div>
                <div className='absolute right-6 top-[-10px] bg-bg1 dark:bg-darkbg2 flex items-center gap-8'>
                    {/* <button
                        className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                        onClick={() => {
                            toggleModal('create-company')
                        }}
                    >
                        <div className=' flex justify-center items-center text-darkgrey dark:text-offwhite p-0 w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite'>
                            <FaBuilding />
                        </div>
                        <p className='font-medium leading-[1] uppercase text-[12px]'>Vendor</p>
                    </button> */}
                    {/* <button
                        className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                        onClick={() => {
                            toggleModal('create-category')
                        }}
                    >
                        <div className=' flex justify-center items-center text-darkgrey dark:text-offwhite p-0 w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite'>
                            <FaClipboardList />
                        </div>
                        <p className='font-medium leading-[1] uppercase text-[12px]'>Category</p>
                    </button> */}
                </div>
            </div>
            <div className='rounded-[4px] shadow-small dark:bg-darkaccent min-h-[85vh] bg-bg1'>
                <>
                    {(view === 'companies' || view === 'my-companies') && (
                        <VendorListV2
                            userSelectedCompanyFilterValues={userSelectedCompanyFilterValues}
                            setUserSelectedCompanyFilterValues={setUserSelectedCompanyFilterValues}
                            setTaskFilters={{
                                setSearch: setSearch,
                                userSelectedFiltersSetFunction: setUserSelectedTaskFilterValues,
                            }}
                        />
                    )}
                    {view === 'categories' && <CategoryList categories={categories} />}
                    {/* {view === 'tasks' && (
                        <TaskListV2
                            company={undefined}
                            userSelectedTaskFilterValues={userSelectedTaskFilterValues}
                            setUserSelectedTaskFilterValues={setUserSelectedTaskFilterValues}
                        />
                    )} */}
                </>
            </div>
            {activeModal === 'create-company' && <CreateCompanyModalV2 />}
            {/* {activeModal === 'create-category' && <CreateCategory />} */}
            {(activeModal === 'listing' || activeModal === 'company') && (
                <CompanyProvider>{activeModal === 'company' && <VendorModalV2 />}</CompanyProvider>
            )}
            {activeModal === 'parent' && <ParentCompanyModal parentCompany={selectedCompany.parent_company} />}
        </div>
    )
}
