import { Button } from 'custom_components/component_Basics/Button'
import { RiArrowGoBackLine } from 'react-icons/ri'
import { Link, useLocation, useNavigate } from 'react-router-dom'

export default function ProductGroupFallback({}: {}) {
    const navigate = useNavigate()
    const previousLocationPathname: string | undefined = useLocation().state?.previousLocationPathname
    return (
        <div>
            <div className='flex justify-between mb-[8px]'>
                <div className='flex gap-2'>
                    <span
                        onClick={() => {
                            if (previousLocationPathname?.includes('product-groups')) {
                                navigate(-1)
                                return
                            }
                            navigate('/products/product-groups')
                        }}
                        className='self-center cursor-pointer'
                    >
                        <RiArrowGoBackLine />
                    </span>
                    <div className=' flex gap-4 items-center'>
                        <h1 className='font-semibold text-[24px]'>Group: </h1>
                    </div>
                </div>
                <div className='flex gap-8 items-center'></div>
            </div>

            <div className='rounded-[4px] shadow-small dark:bg-darkaccent'>
                <div className='w-full h-fit flex flex-col justify-center items-center min-h-[50vh]'>
                    <div className='w-fit  relative p-[16px] rounded-[4px] border border-blue/25 dark:border-darkgrey dark:bg-darkness shadow-md'>
                        <p>There was an issue</p>
                        <div className='flex gap-2'>
                            <Button variant={'outline'}>
                                <Link to={'/products/product-groups'} draggable={false} className=''>
                                    Go Back
                                </Link>
                            </Button>
                            <Button onClick={() => window.location.reload()} variant={'outline'}>
                                Retry
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
