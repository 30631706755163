import { Button } from 'custom_components/component_Basics/Button'
import { useDeleteGroup } from 'productSetupV2/api/useQueries'
import { useNavigate, useParams } from 'react-router-dom'

export default function DeleteGroupAreYouSure({ setShowAreYouSure }: { setShowAreYouSure: any }) {
    const { groupId } = useParams()
    const navigate = useNavigate()
    const deleteGroup = useDeleteGroup()
    const handleDelete = () => {
        if (groupId) {
            navigate('/products/product-groups')
            deleteGroup.mutate(
                { groupId },
                {
                    onSuccess: () => {},
                }
            )
        }
    }
    return (
        <div className='w-full h-full fixed top-0 left-0 z-[50] bg-[rgb(0,0,0,0.5)] grid place-items-center'>
            <div className='p-[32px] rounded-[4px] shadow-small bg-white dark:bg-darkaccent'>
                <p className='text-[20px] font-bold dark:text-offwhite leading-[1] mb-[16px]'>Are you sure?</p>
                <p className='dark:text-offwhite leading-[1] mb-[24px]'>
                    This cannot be undone. All linked products will be removed from this group.
                </p>
                <div className='flex gap-8 justify-center'>
                    <Button
                        variant={'outline'}
                        onClick={() => setShowAreYouSure(false)}
                        className='font-bold uppercase '
                    >
                        Cancel
                    </Button>
                    <Button
                        variant={'outline'}
                        onClick={handleDelete}
                        className='border border-danger text-danger font-bold uppercase'
                    >
                        DELETE
                    </Button>
                </div>
            </div>
        </div>
    )
}
