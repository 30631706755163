import { formatMoney, parseResObject, vFetch } from '../../helpers'
import { DraftLineItemInit, DraftOrderInit, OrderInit } from '../../orders/orders.types'
import { TaskEditInit } from '../../tasks/tasks.types'

export function draftOrderRenderDiscount(order: any, sku: string, item: any, showDiscount: string) {
    if (showDiscount === sku)
        return (
            <>
                {draftOrderFindDiscounts(order, item).map((discount: any) => {
                    const discountTitleFormat: string[] = []
                    if (discount) {
                        if (discount.title) {
                            discountTitleFormat.push(`${discount.title}`)
                        } else if (discount.description) {
                            discountTitleFormat.push(`${discount.description}`)
                        }
                        if (discount.value_type === 'percentage') {
                            discountTitleFormat.push(`(-${Number(discount.value)}%)`)
                        } else {
                            if (discount.quantity) {
                                discountTitleFormat.push(` $${discount.value} x ${discount.quantity}  `)
                            }

                            discountTitleFormat.push(`(-$${discount.amount})`)
                        }

                        return (
                            <div className='js-order-discounts absolute grid gap-[16px] top-[24px] shadow-small p-[16px] left-[-20px] rounded bg-white dark:bg-darkness z-index-4'>
                                <h3 className='js-order-discounts font-semibold text-[16px]'>
                                    {draftOrderFindDiscounts(order).length > 1 ? 'Discounts' : 'Discount'}
                                </h3>
                                <div className='js-order-discounts'>
                                    <h4 className='js-order-discounts font-semibold'>Each</h4>
                                    <p className='js-order-discounts text-[16px]'>
                                        {formatMoney(discount.amount / Number(item.quantity))}
                                    </p>
                                    <h4 className='js-order-discounts font-semibold mt-[16px]'>Total</h4>
                                    <p className='js-order-discounts text-[16px]'>{formatMoney(discount.amount)}</p>
                                    <h4 className='js-order-discounts font-semibold mt-[16px]'>Reason</h4>
                                    <p className='js-order-discounts text-[16px]'>
                                        {discount.title}
                                        {discount.description}
                                    </p>
                                </div>
                            </div>
                        )
                    }
                })}
            </>
        )
}
export function getSubtotal(item: any) {
    return Number(
        item.line_items.reduce((acc: any, cur: any) => Number(acc) + Number(cur.price) * Number(cur.quantity), 0)
    )
}

export function draftOrderItemPriceAfterDiscounts(item: any) {
    if (!item.applied_discount) {
        return Number(item.price)
    }
    let discountArray = []
    discountArray.push(item.applied_discount)
    return (
        Number(item.price) -
        discountArray.reduce((acc: any, cur: any) => Number(acc) + Number(cur.amount), 0) / item.quantity
    )
}

export function draftOrderFindDiscounts(order: DraftOrderInit, thisItemOnly?: DraftLineItemInit) {
    const discounts: any[] = []

    if (thisItemOnly?.applied_discount) {
        if (thisItemOnly.quantity > 1) {
            thisItemOnly.applied_discount.quantuty = thisItemOnly.quantity
        }
        discounts.push(thisItemOnly.applied_discount)
    } else if (order) {
        if (order.applied_discount) {
            discounts.push(order.applied_discount)
        }
        order.line_items.forEach((lineItem: any) => {
            if (lineItem.applied_discount) {
                if (lineItem.quantity > 1) {
                    lineItem.applied_discount.quantity = lineItem.quantity
                }
                discounts.push(lineItem.applied_discount)
            }
        })
    }
    return discounts
}
export function draftOrderRefreshTasks(draft_order: DraftOrderInit, setTasks: Function, setTaskEvents: Function) {
    vFetch(`/v1/tasks/search?draft_order_name=${draft_order.name.replaceAll('#', '')}`, {
        cb: (res: any) => {
            if (!res.tasks.length) {
                setTasks(res.tasks)
                setTaskEvents([])
                return
            }
            res.tasks.map((task: TaskEditInit) => {
                parseResObject(task)
                task.due_at = new Date(task.due_at.toISOString().slice(0, -1))
            })
            setTasks(res.tasks)
            vFetch(`/v1/taskEventsPublic?id=${res.tasks[0].id}`, {
                cb: (res: any) => {
                    if (res.success) {
                        setTaskEvents(
                            res.events.map((e: any) => {
                                return { ...parseResObject(e), normalized_event_type: 'Task Events Public' }
                            })
                        )
                    }
                },
            })
        },
    })
}

export function totalDiscountsAndAdjustments(order: DraftOrderInit) {
    return order.refunds.reduce(
        (refundAcc, refundCur) => refundAcc + refundCur.transactions.reduce((acc, cur) => acc + Number(cur.amount), 0),
        0
    )
    //return order.refunds.reduce((refundAcc, refundCur) => refundAcc + refundCur.refund_line_items.reduce((acc, cur) => acc - cur.subtotal! - cur.total_tax!, 0) + refundCur.order_adjustments.reduce((acc, cur) => acc + Number(cur.amount), 0), 0);
}
