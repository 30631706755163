import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { FcHighPriority, FcMediumPriority, FcLowPriority } from 'react-icons/fc'

import FiltersMenu from '../../custom_components/FiltersMenu'
import {
    dayMap,
    DayMap,
    monthMap,
    MonthMap,
    parseResObject,
    sendToast,
    sortByAlphanumeric,
    useDebounce,
    vFetch,
} from '../../helpers'
import { Filter } from '../../custom_components/FiltersMenu'
import { TaskEditInit } from '../tasks.types'
import { orderOrDraftOrderId } from '../tasks.helpers'

function renderPriority(priority: string, status?: string) {
    if (priority == 'High' || priority == 'Urgent') {
        return (
            <div className='flex gap-x-[8px] items-center'>
                <FcHighPriority className='h-[19px] w-auto' />
                {priority && <span>{priority}</span>}
            </div>
        )
    } else if (priority == 'Medium') {
        return (
            <div className='flex gap-x-[8px] items-center'>
                <FcMediumPriority className='h-[19px] w-auto' />
                {priority && <span>{priority}</span>}
            </div>
        )
    } else {
        return (
            <div className='flex gap-x-[8px] items-center'>
                <FcLowPriority className='h-[19px] w-auto' />
                {priority && <span>{priority}</span>}
            </div>
        )
    }
}

function renderIconMessage(iconMessage: string) {
    let unparsedMessage = iconMessage
    let parsedMessage = []

    while (unparsedMessage.match('\n')) {
        parsedMessage.push(unparsedMessage.slice(0, unparsedMessage.indexOf('\n')))
        parsedMessage.push(<br></br>)
        unparsedMessage = unparsedMessage.replace(unparsedMessage.slice(0, unparsedMessage.indexOf('\n') + 1), '')
    }
    parsedMessage.push(unparsedMessage)

    return parsedMessage
}

type PriorityMap = {
    Low: number
    Medium: number
    High: number
    Urgent: number
}

export default function TasksListsScreen() {
    const options: Intl.DateTimeFormatOptions = {
        month: 'numeric',
        day: 'numeric',
        year: '2-digit',
        timeZone: 'America/Chicago',
    }
    const utcOptions: Intl.DateTimeFormatOptions = {
        month: 'numeric',
        day: 'numeric',
        year: '2-digit',
        timeZone: 'UTC',
    }
    const taskEventTime = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour12: true,
        timeZone: 'America/Chicago',
    })

    const formatter = new Intl.DateTimeFormat('en-US', options)
    const utcFormatter = new Intl.DateTimeFormat('en-US', utcOptions)

    const [tasks, setTasks] = useState<TaskEditInit[]>([])
    const navigate = useNavigate()
    const savedDateIsOkay = () => {
        if (!localStorage.getItem('tasks_time_set_at')) return false
        const timeSet = new Date(localStorage.getItem('tasks_time_set_at') as string)
        const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())

        if (timeSet.toISOString().split('T')[0] !== today.toISOString().split('T')[0]) return false
        return true
    }
    const [startDate, setStartDate] = useState(
        savedDateIsOkay()
            ? new Date(localStorage.getItem('tasks_start_date') as string)
            : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
    )
    const [endDate, setEndDate] = useState(
        savedDateIsOkay()
            ? new Date(localStorage.getItem('tasks_end_date') as string)
            : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
    )
    const [allTasks, setAllTasks] = useState<TaskEditInit[]>([])
    const [dateIsToday, setDateIsToday] = useState(
        startDate.getTime() ===
            new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime() &&
            startDate.getTime() === endDate.getTime()
            ? true
            : false
    )
    const [sortMode, setSortMode] = useState('due_at')
    const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
    const user = useSelector<any, any>((state) => state.user)
    const [iconMessage, setIconMessage] = useState<[number, string]>([-1, ''])

    // filters
    const [filterByAssignedTo, setFilterByAssignedTo] = useState<string[]>(
        localStorage
            ?.getItem('tasks_filter_by_assigned_to')
            ?.split(',')
            .filter((s) => s) || []
    )
    const [filterByAssignedToOptions, setFilterByAssignedToOptions] = useState<string[]>([])
    const [showFilterByAssignedToOptions, setShowFilterByAssignedToOptions] = useState(false)

    const [filterByPriority, setFilterByPriority] = useState<string[]>(
        localStorage
            .getItem('tasks_filter_by_priority')
            ?.split(',')
            .filter((s) => s) || []
    )
    const [filterByPriorityOptions, setFilterByPriorityOptions] = useState<string[]>([])
    const [showFilterByPriorityOptions, setShowFilterByPriorityOptions] = useState(false)

    const [filterByTopic, setFilterByTopic] = useState<string[]>(
        localStorage
            .getItem('tasks_filter_by_topic')
            ?.split(',')
            .filter((s) => s) || []
    )
    const [filterByTopicOptions, setFilterByTopicOptions] = useState<string[]>([])
    const [showFilterByTopicOptions, setShowFilterByTopicOptions] = useState(false)

    const [filterByType, setFilterByType] = useState<string[]>(
        localStorage
            .getItem('tasks_filter_by_type')
            ?.split(',')
            .filter((s) => s) || []
    )
    const [filterByTypeOptions, setFilterByTypeOptions] = useState<string[]>([])
    const [showFilterByTypeOptions, setShowFilterByTypeOptions] = useState(false)

    const [filterByStatus, setFilterByStatus] = useState<string[]>(
        localStorage
            .getItem('tasks_filter_by_status')
            ?.split(',')
            .filter((s) => s) || ['OPEN']
    )
    const filterByStatusOptions = ['OPEN', 'COMPLETED']
    const [showFilterByStatusOptions, setShowFilterByStatusOptions] = useState(false)

    const statusFilter = new Filter(
        'Filter by Status',
        filterByStatus,
        setFilterByStatus,
        filterByStatusOptions,
        showFilterByStatusOptions,
        setShowFilterByStatusOptions,
        useRef(null)
    )
    const topicFilter = new Filter(
        'Filter by Topic',
        filterByTopic,
        setFilterByTopic,
        filterByTopicOptions,
        showFilterByTopicOptions,
        setShowFilterByTopicOptions,
        useRef(null)
    )
    const typeFilter = new Filter(
        'Filter by Type',
        filterByType,
        setFilterByType,
        filterByTypeOptions,
        showFilterByTypeOptions,
        setShowFilterByTypeOptions,
        useRef(null)
    )
    const assignedToFilter = new Filter(
        'Filter by Assignee',
        filterByAssignedTo,
        setFilterByAssignedTo,
        filterByAssignedToOptions,
        showFilterByAssignedToOptions,
        setShowFilterByAssignedToOptions,
        useRef(null)
    )
    const priorityFilter = new Filter(
        'Filter by Priority',
        filterByPriority,
        setFilterByPriority,
        filterByPriorityOptions,
        showFilterByPriorityOptions,
        setShowFilterByPriorityOptions,
        useRef(null)
    )

    const filters = [assignedToFilter, typeFilter, topicFilter, priorityFilter, statusFilter]
    filters.forEach((filter: Filter, index: number) => (filter.zIndex = filters.length - index))

    const timeZoneMap: TimeZoneMapType = {
        WA: 'PST',
        OR: 'PST',
        NV: 'PST',
        CA: 'PST',
        MT: 'MST',
        ID: 'MST',
        WY: 'MST',
        UT: 'MST',
        CO: 'MST',
        AZ: 'MST',
        NM: 'MST',
        ND: 'CST',
        SD: 'CST',
        MN: 'CST',
        WI: 'CST',
        NE: 'CST',
        IA: 'CST',
        IL: 'CST',
        MO: 'CST',
        KS: 'CST',
        OK: 'CST',
        AR: 'CST',
        TN: 'CST',
        MS: 'CST',
        AL: 'CST',
        LA: 'CST',
        TX: 'CST',
        MI: 'EST',
        IN: 'EST',
        OH: 'EST',
        KY: 'EST',
        WV: 'EST',
        VA: 'EST',
        NC: 'EST',
        SC: 'EST',
        GA: 'EST',
        FL: 'EST',
        DC: 'EST',
        MD: 'EST',
        DE: 'EST',
        NJ: 'EST',
        PA: 'EST',
        NY: 'EST',
        CT: 'EST',
        RI: 'EST',
        MA: 'EST',
        NH: 'EST',
        VT: 'EST',
        ME: 'EST',
        AK: 'HAST',
        HI: 'HST',
    }

    type TimeZoneMapType = {
        [key: string]: string
    }

    useEffect(() => {
        const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toDateString()
        const isToday = startDate.toDateString() === today && endDate.toDateString() === today

        vFetch(`/v1/tasks/v2/allListScreen?${filterByStatus.length ? `status=${filterByStatus}` : ''}`, {
            cb: (res: any) => {
                if (res.success && res.tasks) {
                    const prioritiesMap = ['Low', 'Medium', 'High', 'Urgent']
                    const priorities: any = {}
                    const taskUsers: any = []
                    const types: any = {}
                    const topics: any = {}

                    const tasksRes: any[] = res.tasks
                        .map((t: any) => {
                            parseResObject(t)
                            t.due_at = new Date(t.due_at?.toISOString().slice(0, -1) || new Date().toISOString())
                            return t
                        })
                        .sort((a: any, b: any) => sortByAlphanumeric(a.due_at.getTime(), b.due_at.getTime()))

                    tasksRes.forEach((t) => {
                        if (!priorities[t.priority]) priorities[t.priority] = t.priority
                        const assignedTo = t.assigned_to
                        assignedTo.forEach((user: string) => {
                            if (!taskUsers.includes(user)) taskUsers.push(user)
                        })
                        if (!types[t.task_type]) types[t.task_type] = t.task_type
                        if (!topics[t.topic]) topics[t.topic] = t.topic
                        t.timezone = timeZoneMap[t?.customer_province_code as keyof TimeZoneMapType]
                    })
                    setAllTasks(tasksRes)

                    const dateFilteredTasks = filteredTasksForSort(tasksRes).filter((task) => {
                        const { due_at } = task

                        if (
                            isToday &&
                            startDate.toDateString() === endDate.toDateString() &&
                            due_at.valueOf() <= endDate.valueOf()
                        ) {
                            return true
                        } else if (due_at.valueOf() >= startDate.valueOf() && due_at.valueOf() <= endDate.valueOf()) {
                            return true
                        }
                    })
                    setTasks(dateFilteredTasks)
                    setFilterByPriorityOptions(
                        prioritiesMap.filter((option) => Object.keys(priorities).includes(option))
                    )
                    setFilterByAssignedToOptions(
                        taskUsers.includes(user.user_id) ? taskUsers.sort() : taskUsers.concat(user.user_id).sort()
                    )
                    setFilterByTypeOptions(Object.keys(types).sort())
                    setFilterByTopicOptions(Object.keys(topics).sort())
                }
            },
            catchCb: (err: any) => console.error(err),
        })
    }, [startDate, endDate, filterByStatus])

    const filteredTasksForSort = (tasksToFilter: TaskEditInit[]) => {
        const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toDateString()
        const isToday = startDate.toDateString() === today && endDate.toDateString() === today
        let filteredTasks = tasksToFilter.slice(0)

        // filters by date
        filteredTasks = filteredTasks.filter((task) => {
            const { due_at } = task

            if (
                isToday &&
                startDate.toDateString() === endDate.toDateString() &&
                due_at.valueOf() <= endDate.valueOf()
            ) {
                return true
            } else if (due_at.valueOf() >= startDate.valueOf() && due_at.valueOf() <= endDate.valueOf()) {
                return true
            }
        })

        // filters by priority
        if (filterByPriority.length > 0) {
            filteredTasks = filteredTasks.filter((task) => filterByPriority.includes(task.priority))
        }

        // filters by task_type
        if (filterByType.length > 0) {
            filteredTasks = filteredTasks.filter((task) => filterByType.includes(task.task_type))
        }

        // filters by topic
        if (filterByTopic.length > 0) {
            filteredTasks = filteredTasks.filter((task) => filterByTopic.includes(task.topic))
        }

        //filters by assignee
        if (filterByAssignedTo.length > 0) {
            filteredTasks = filteredTasks.filter((task) => {
                const assignedTo = task.assigned_to
                const usersInList = assignedTo.filter((user: string) => {
                    return filterByAssignedTo.includes(user)
                })
                return usersInList.length > 0 ? true : false
            })
        }

        // filters by topic
        if (filterByTopic.length === 0) {
            return filteredTasks
        } else {
            filteredTasks = filteredTasks.filter((task) => filterByTopic.includes(task.topic))
        }
        if (JSON.stringify(tasks) !== JSON.stringify(filteredTasks)) {
            setTasks(filteredTasks)
        }
        return filteredTasks
    }

    useEffect(() => {
        const reversed = sortMode.includes('reverse')

        // Sort Draft #
        if (sortMode.includes('order_name')) {
            const sortedTasks = filteredTasksForSort(allTasks).sort((a, b) =>
                sortByAlphanumeric(a.due_at.getTime(), b.due_at.getTime())
            )
            sortedTasks.sort((a, b) => {
                let aStart = 0
                let bStart = 0

                const aName = a.order_name ? a.order_name : a.draft_order_name
                const bName = b.order_name ? b.order_name : b.draft_order_name

                if (!aName) return 1
                if (!bName) return -1

                while (isNaN(Number(aName[aStart]))) {
                    aStart++
                }
                while (isNaN(Number(bName[bStart]))) {
                    bStart++
                }
                let aNum = parseInt(aName.slice(aStart))
                let bNum = parseInt(bName.slice(bStart))

                return reversed ? (aNum > bNum ? 1 : -1) : aNum > bNum ? -1 : 1
            })
            setTasks(sortedTasks)
        }
        if (sortMode.includes('priority')) {
            const sortedTasks = filteredTasksForSort(allTasks).sort((a, b) => {
                const dueA = new Date(a.due_at).getTime()
                const dueB = new Date(b.due_at).getTime()
                return reversed ? dueB - dueA : dueA - dueB
            })
            const priorityMap: PriorityMap = {
                Low: 1,
                Medium: 2,
                High: 3,
                Urgent: 4,
            }
            sortedTasks.sort((a, b) => {
                return priorityMap[a.priority as keyof PriorityMap] > priorityMap[b.priority as keyof PriorityMap]
                    ? reversed
                        ? 1
                        : -1
                    : reversed
                      ? -1
                      : 1
            })

            setTasks(sortedTasks)
        }
        if (sortMode.includes('due_at')) {
            const sortedTasks = filteredTasksForSort(allTasks).sort((a, b) => {
                const dueA = new Date(a.due_at).getTime()
                const dueB = new Date(b.due_at).getTime()
                return reversed ? dueB - dueA : dueA - dueB
            })
            setTasks(sortedTasks)
        }
        if (sortMode.includes('created_at')) {
            const sortedTasks = filteredTasksForSort(allTasks).sort((a, b) => {
                const createdA = new Date(a.created_at).getTime()
                const createdB = new Date(b.created_at).getTime()
                return reversed ? createdB - createdA : createdA - createdB
            })
            setTasks(sortedTasks)
        }
        if (sortMode.includes('task_type')) {
            const sortedTasks = filteredTasksForSort(allTasks).sort((a, b) => {
                return reversed ? (a.task_type > b.task_type ? -1 : 1) : a.task_type > b.task_type ? 1 : -1
            })
            setTasks(sortedTasks)
        }

        localStorage.setItem('tasks_filter_by_assigned_to', filterByAssignedTo.join(','))
        localStorage.setItem('tasks_filter_by_priority', filterByPriority.join(','))
        localStorage.setItem('tasks_filter_by_status', filterByStatus.join(','))
        localStorage.setItem('tasks_filter_by_topic', filterByTopic.join(','))
        localStorage.setItem('tasks_filter_by_type', filterByType.join(','))
    }, [
        sortMode,
        filterByAssignedTo,
        filterByPriority,
        filterByStatus,
        filterByTopic,
        filterByType,
        startDate,
        endDate,
    ])

    useEffect(() => {
        if (startDate.toDateString() === endDate.toDateString()) {
            if (startDate.toDateString() === today.toDateString()) {
                setDateIsToday(true)
            } else {
                setDateIsToday(false)
            }
        } else {
            setDateIsToday(false)
        }
        localStorage.setItem('tasks_start_date', startDate.toISOString())
        localStorage.setItem('tasks_end_date', endDate.toISOString())
        localStorage.setItem('tasks_time_set_at', new Date(Date.now()).toISOString())
    }, [startDate, endDate])

    const filterProps = {
        startDate: startDate,
        setStartDate: setStartDate,
        endDate: endDate,
        setEndDate: setEndDate,
        items:
            allTasks.filter((t) => t.assigned_to.some((a: string) => filterByAssignedTo.includes(a))) ||
            filterByAssignedTo.length === 0,
    }

    function handleNavigate(e: MouseEvent, location: string, taskId?: number) {
        if (e.ctrlKey) return
        e.preventDefault()
        let element = e.target
        let inDontNavigate = false
        while ((element as HTMLElement).parentNode) {
            if ((element as HTMLElement).classList.contains('js-dont-navigate')) inDontNavigate = true
            element = (element as HTMLElement).parentNode
        }
        if (!inDontNavigate) navigate(location)
    }

    const [pageInfo, setPageInfo] = useState({})
    const [search, setSearch] = useState('')
    const debouncedSearch = useDebounce(search, 100)
    const [searchResults, setSearchResults] = useState<TaskEditInit[]>([])
    const [showSearch, setShowSearch] = useState(false)

    function handleSearch({ target }: ChangeEvent) {
        setPageInfo({
            before: null,
            after: null,
            page: 1,
        })
        setSearch((target as HTMLInputElement).value)
    }
    useEffect(() => {
        // this whole thing can be improved. It kind of sucks at the moment, but that's my fault. Idea is half-baked and needs work.
        if (!showSearch) setShowSearch(true)

        const sResults = allTasks
            .filter((t) => t.id.toString().includes(search))
            .sort((a, b) => (a.id < b.id ? 1 : -1))
            .sort((a, b) => {
                if (a.id.toString().slice(0, search.length) == search) {
                    return -1
                } else {
                    return 1
                }
            })
        const first15 = debouncedSearch.length > 0 ? sResults.slice(0, 15) : []

        setSearchResults(first15)
    }, [debouncedSearch])

    return (
        <div className='mb-32px'>
            <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Task Manager</h1>
            <div className='flex items-center gap-x-[8px] h-[50px]'>
                <h2 className='font-bold text-[24px] font-bai dark:text-offwhite'>
                    {startDate
                        .toDateString()
                        .split(' ')
                        .map((dateItem) =>
                            dayMap[dateItem as keyof DayMap]
                                ? `${dayMap[dateItem as keyof DayMap]}, `
                                : monthMap[dateItem as keyof MonthMap]
                                  ? `${monthMap[dateItem as keyof MonthMap]}`
                                  : Number(dateItem) < 32
                                    ? `${Number(dateItem)},`
                                    : dateItem
                        )
                        .join(' ')}
                    {startDate.toDateString() !== endDate.toDateString()
                        ? endDate
                              .toDateString()
                              .split(' ')
                              .map((dateItem) =>
                                  dayMap[dateItem as keyof DayMap]
                                      ? ` - ${dayMap[dateItem as keyof DayMap]}, `
                                      : monthMap[dateItem as keyof MonthMap]
                                        ? `${monthMap[dateItem as keyof MonthMap]}`
                                        : Number(dateItem) < 32
                                          ? `${Number(dateItem)},`
                                          : dateItem
                              )
                              .join(' ')
                        : ''}
                </h2>
                {(startDate.toISOString() !==
                    new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).toISOString() ||
                    endDate.toISOString() !==
                        new Date(
                            new Date().getFullYear(),
                            new Date().getMonth(),
                            new Date().getDate()
                        ).toISOString()) && (
                    <button
                        className='dark:text-accent font-semibold py-[8px] px-[16px] rounded hover:bg-offwhite dark:hover:bg-darkaccent'
                        onClick={() => {
                            setDateIsToday(true)
                            setStartDate(
                                new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
                            )
                            setEndDate(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()))
                        }}
                    >
                        Reset Date
                    </button>
                )}
            </div>
            <FiltersMenu filters={filters} ex={filterProps} />
            <form
                className='relative flex justify-center'
                onSubmit={(event: any) => {
                    event.preventDefault()
                    searchResults.length > 0 && handleNavigate(event, orderOrDraftOrderId(searchResults[0]))
                }}
            >
                <input
                    className='js-tasks-search px-[4px] w-[30%] dark:text-offwhite rounded bg-[whitesmoke] dark:bg-darkaccent'
                    type='text'
                    onChange={handleSearch}
                    value={search}
                    placeholder='Task # Search'
                    onClick={() => {
                        setShowSearch(true)
                    }}
                />
                {showSearch && (
                    <div className='absolute top-[calc(100%)] left-0 w-[100%] grid justify-center pointer-events-none p-[8px]'>
                        {searchResults.length > 0 ? (
                            <div className='shadow-small dark:border-[1px] dark:border-offwhite/60 rounded-sm pointer-events-auto'>
                                <div className='grid grid-cols-[50px_70px_50px_110px_320px_100px] dark:border-darkgrey gap-[8px] p-[4px] text-[white] bg-blue dark:bg-darkaccent font-extrabold font-bai'>
                                    <div
                                        className='flex items-center justify-between'
                                        onClick={() =>
                                            setSortMode(sortMode === 'order_name' ? 'order_name_reverse' : 'order_name')
                                        }
                                    >
                                        T #
                                    </div>
                                    <div
                                        className='flex items-center justify-between'
                                        onClick={() =>
                                            setSortMode(sortMode === 'order_name' ? 'order_name_reverse' : 'order_name')
                                        }
                                    >
                                        PO #
                                    </div>
                                    <div>STS.</div>
                                    {/* commenting out until I can fix the elements after this element being rendered over top this element. Issue has something to do with an absolute positioned div trying to lay over top an absolute positioned-div.
                            grid col width should be 20px

                            <div className="flex items-center justify-between">
                            </div> */}
                                    <div
                                        className='flex items-center justify-between'
                                        onClick={() =>
                                            setSortMode(sortMode === 'task_type' ? 'task_type_reverse' : 'task_type')
                                        }
                                    >
                                        TYPE
                                    </div>
                                    <div className='flex items-center justify-between'>ACTIVITY</div>
                                    <div className='flex items-center justify-between'>ASSIGNED</div>
                                </div>
                                {searchResults.map((t) => (
                                    <div
                                        className='bg-white/90 hover:bg-[lightgrey]/90 dark:bg-darkness/90 dark:hover:bg-darkaccent/90  p-[4px] pointer-events-auto backdrop-blur-[2px] [&:last-child]:rounded-b'
                                        onClick={(event: any) => handleNavigate(event, orderOrDraftOrderId(t))}
                                    >
                                        <div className='grid grid-cols-[50px_70px_50px_110px_320px_100px] gap-[8px] col-start-1 col-end-6 text-[14px] cursor-pointer dark:text-offwhite'>
                                            <p className='font-semibold'>#{t.id}</p>
                                            <div className='text-[14px]'>{t.order_name || t.draft_order_name}</div>
                                            <p className={`${t.status === 'OPEN' && 'font-bold'}`}>
                                                {t.status.slice(0, 4)}
                                            </p>
                                            {/* <div className="js-orders-icon-list relative flex gap-[8px] h-[100%] w-[100%]">
                                    <div className="flex gap-[8px]">
                                        {t.order_notes && (!t.order_notes.match(/https:\/\/fpdash.com\/tasks\/\d+\n/) || t.order_notes !== t.order_notes.match(/https:\/\/fpdash.com\/tasks\/\d+\n/)[0]) && 
                                        <div className="js-orders-icon pt-[4px]" onMouseOver={() => {setIconMessage([t.id, `${t.order_notes}`])}}>
                                            <svg className="stroke-black dark:stroke-accent pointer-events-none h-[14px] w-[14px]" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                <path className="pointer-events-none" fill="none" strokeWidth="2" d="M3,1 L3,23 L16,23 L21,18 L21,1 L3,1 Z M6,17 L11,17 M6,13 L18,13 M6,9 L16,9 M3,5 L21,5 M21,17 L15,17 L15,23">
                                                </path>
                                            </svg>
                                        </div>
                                        }
                                    </div>
                                    <div className="flex grow h-[100%] shrink-0" onMouseOver={() => {setIconMessage([-1, ""])}}/>
                                    {iconMessage[0] === t.id &&
                                    <div className="hidden js-orders-icon-message js-dont-navigate absolute hover:flex p-[16px] min-w-[350px] top-[calc(95%-16px)] left-[-36px] cursor-auto js-dont-navigate text-[14px]">
                                        <div className="left-[0px] top-[0px] js-orders-icon p-[8px] min-w-[300px] bg-white dark:bg-darkaccent border-darkgrey border-[1px] text-black dark:text-offwhite z-index-4 shadow-small js-dont-navigate">
                                            {renderIconMessage(iconMessage[1])}
                                        </div>
                                    </div>}
                                </div> */}
                                            <div
                                                className={`${
                                                    t.task_type === 'Customer Service'
                                                        ? 'text-[rgb(0,136,204)] font-bold'
                                                        : t.task_type === 'Sales'
                                                          ? 'text-[#41a112] font-bold'
                                                          : t.task_type === 'Accounting'
                                                            ? 'text-purple-300 font-bold'
                                                            : t.task_type === 'Order Fulfillment'
                                                              ? 'text-fire font-bold'
                                                              : ''
                                                }`}
                                            >
                                                {t.topic} (
                                                {t.task_type
                                                    .split(' ')
                                                    .map((s) => s[0])
                                                    .join('')}
                                                )
                                            </div>
                                            <div>
                                                {t.title.slice(0, 45).trim()}
                                                {t.title.slice(0, 45).length >= 45 && '...'}
                                            </div>
                                            <div>{t.assigned_to.join(', ')}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            debouncedSearch.length > 0 && (
                                <div className='bg-white dark:bg-darkness p-[16px] shadow-small w-[300px]'>
                                    <h2 className='text-[20px] font-semibold'>No Results</h2>
                                </div>
                            )
                        )}
                    </div>
                )}
            </form>

            {(filterByStatus.includes('OPEN') || filterByStatus.length === 0) && (
                <div className='mb-[32px]'>
                    <h2 className='text-[20px] mt-[16px] font-bai dark:text-offwhite'>
                        Open Tasks ({tasks.filter((task) => task.status === 'OPEN').length})
                    </h2>
                    <div
                        className={`${
                            user.type === 'Admin' || user.type === 'SuperAdmin'
                                ? 'grid mt-[16px] grid-cols-[78px_93px_20px_120px_3fr_85px_75px_75px_200px_minmax(75px,125px)]'
                                : 'grid mt-[16px] grid-cols-[78px_93px_20px_120px_3fr_85px_75px_75px_minmax(75px,125px)]'
                        } border-[1px] border-[#cdcdcd] dark:border-darkgrey`}
                    >
                        <div
                            className='flex items-center justify-between p-[8px] text-[12px] text-[white] bg-[rgb(0,60,90)] dark:bg-darkaccent font-extrabold cursor-pointer font-bai border-b-[1px] border-darkgrey'
                            onClick={() => setSortMode(sortMode === 'order_name' ? 'order_name_reverse' : 'order_name')}
                        >
                            PO / DRAFT #
                            {sortMode.includes('order_name') && (
                                <span style={{ fontSize: '18px', lineHeight: 1 }}>*</span>
                            )}
                        </div>
                        <div
                            className='flex items-center justify-between p-[8px] text-[12px] text-[white] bg-[rgb(0,60,90)] dark:bg-darkaccent font-extrabold cursor-pointer font-bai border-b-[1px] border-darkgrey'
                            onClick={() => setSortMode(sortMode === 'priority' ? 'priority_reverse' : 'priority')}
                        >
                            PRIORITY
                            {sortMode.includes('priority') && (
                                <span style={{ fontSize: '18px', lineHeight: 1 }}>*</span>
                            )}
                        </div>
                        <div className='flex items-center justify-between p-[8px] text-[12px] text-[white] bg-[rgb(0,60,90)] dark:bg-darkaccent font-extrabold cursor-pointer font-bai border-b-[1px] border-darkgrey'></div>
                        <div
                            className='flex items-center justify-between p-[8px] text-[12px] text-[white] bg-[rgb(0,60,90)] dark:bg-darkaccent font-extrabold cursor-pointer font-bai border-b-[1px] border-darkgrey'
                            onClick={() => setSortMode(sortMode === 'task_type' ? 'task_type_reverse' : 'task_type')}
                        >
                            TYPE
                            {sortMode === 'task_type' && <span style={{ fontSize: '18px', lineHeight: 1 }}>*</span>}
                        </div>
                        <div className='flex items-center justify-between p-[8px] text-[12px] text-[white] bg-[rgb(0,60,90)] dark:bg-darkaccent font-extrabold cursor-pointer font-bai border-b-[1px] border-darkgrey'>
                            LATEST ACTIVITY
                            {sortMode === 'title' && <span style={{ fontSize: '18px', lineHeight: 1 }}>*</span>}
                        </div>
                        <div className='flex items-center justify-between p-[8px] text-[12px] text-[white] bg-[rgb(0,60,90)] dark:bg-darkaccent font-extrabold cursor-pointer font-bai border-b-[1px] border-darkgrey'>
                            CUST. TIMEZONE
                        </div>
                        <div
                            className='flex items-center justify-between p-[8px] text-[12px] text-[white] bg-[rgb(0,60,90)] dark:bg-darkaccent font-extrabold cursor-pointer font-bai border-b-[1px] border-darkgrey'
                            onClick={() => setSortMode(sortMode === 'due_at' ? 'due_at_reverse' : 'due_at')}
                        >
                            DUE
                            {sortMode.includes('due_at') && <span style={{ fontSize: '18px', lineHeight: 1 }}>*</span>}
                        </div>
                        <div
                            className='flex items-center justify-between p-[8px] text-[12px] text-[white] bg-[rgb(0,60,90)] dark:bg-darkaccent font-extrabold cursor-pointer font-bai border-b-[1px] border-darkgrey'
                            onClick={() => setSortMode(sortMode === 'created_at' ? 'created_at_reverse' : 'created_at')}
                        >
                            CREATED
                            {sortMode.includes('created_at') && (
                                <span style={{ fontSize: '18px', lineHeight: 1 }}>*</span>
                            )}
                        </div>
                        {(user.type === 'Admin' || user.type === 'SuperAdmin') && (
                            <div className='flex items-center justify-between p-[8px] text-[12px] text-[white] bg-[rgb(0,60,90)] dark:bg-darkaccent font-extrabold cursor-pointer font-bai border-b-[1px] border-darkgrey'>
                                LAST VIEWED
                            </div>
                        )}
                        <div className='flex items-center justify-between p-[8px] text-[12px] text-[white] bg-[rgb(0,60,90)] dark:bg-darkaccent font-extrabold cursor-pointer font-bai border-b-[1px] border-darkgrey'>
                            ASSIGNED TO
                        </div>
                        {tasks
                            .filter((task) => task.status === 'OPEN')
                            .map((t) => (
                                <NavLink
                                    key={t.id}
                                    className={`grid ${
                                        user.type === 'Admin' || user.type === 'SuperAdmin'
                                            ? 'grid-cols-[78px_93px_20px_120px_3fr_85px_75px_75px_200px_minmax(75px,125px)]'
                                            : 'grid-cols-[78px_93px_20px_120px_3fr_85px_75px_75px_minmax(75px,125px)]'
                                    } col-start-1 col-end-11 cursor-pointer ${
                                        t.due_at.getTime() < Date.now() - 93600000
                                            ? 'bg-[#8a0f0f] text-[white] hover:bg-[#CC0000]'
                                            : 'hover:bg-[rgb(199,202,209)] [&:nth-child(even)]:bg-[rgb(224,228,235)] [&:nth-child(even)]:hover:bg-[rgb(199,202,209)] dark:[&:nth-child(even)]:bg-darkaccent dark:[&:nth-child(even)]:hover:bg-blue dark:bg-darkness dark:text-offwhite dark:hover:bg-blue'
                                    }`}
                                    onClick={(event: any) => handleNavigate(event, orderOrDraftOrderId(t))}
                                    to={orderOrDraftOrderId(t)}
                                >
                                    <div className='p-[8px] text-[14px]'>{t.order_name || t.draft_order_name}</div>
                                    <div className='p-[8px] text-[14px]'>{renderPriority(t.priority)}</div>
                                    <div className='js-orders-icon-list relative flex gap-[8px] h-[100%] w-[100%]'>
                                        <div className='flex gap-[8px]'>
                                            {t.order_notes &&
                                                ((t.order_notes &&
                                                    !t?.order_notes?.match(/https:\/\/fpdash.com\/tasks\/\d+\n/)) ||
                                                    t.order_notes !==
                                                        (t?.order_notes?.match(
                                                            /https:\/\/fpdash.com\/tasks\/\d+\n/
                                                        ) || [''])[0]) && (
                                                    <div
                                                        className='js-orders-icon pt-[12px]'
                                                        onMouseOver={() => {
                                                            setIconMessage([t.id, `${t.order_notes || ''}`])
                                                        }}
                                                    >
                                                        <svg
                                                            className='stroke-black dark:stroke-accent pointer-events-none h-[14px] w-[14px]'
                                                            stroke='currentColor'
                                                            fill='currentColor'
                                                            strokeWidth='0'
                                                            viewBox='0 0 24 24'
                                                            height='1em'
                                                            width='1em'
                                                            xmlns='http://www.w3.org/2000/svg'
                                                        >
                                                            <path
                                                                className='pointer-events-none'
                                                                fill='none'
                                                                strokeWidth='2'
                                                                d='M3,1 L3,23 L16,23 L21,18 L21,1 L3,1 Z M6,17 L11,17 M6,13 L18,13 M6,9 L16,9 M3,5 L21,5 M21,17 L15,17 L15,23'
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                )}
                                        </div>
                                        <div
                                            className='flex grow h-[100%] shrink-0'
                                            onMouseOver={() => {
                                                setIconMessage([-1, ''])
                                            }}
                                        />
                                        {iconMessage[0] === t.id && (
                                            <div className='hidden js-orders-icon-message js-dont-navigate absolute hover:flex p-[16px] min-w-[350px] top-[calc(95%-16px)] left-[-36px] cursor-auto js-dont-navigate text-[14px]'>
                                                <div className='left-[0px] top-[0px] js-orders-icon p-[8px] min-w-[300px] bg-white dark:bg-darkaccent border-darkgrey border-[1px] text-black dark:text-offwhite z-index-4 shadow-small js-dont-navigate'>
                                                    {renderIconMessage(iconMessage[1])}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className={`p-[8px] text-[14px] ${
                                            t.task_type === 'Customer Service'
                                                ? 'text-[rgb(0,136,204)] font-bold'
                                                : t.task_type === 'Sales'
                                                  ? 'text-[#41a112] font-bold'
                                                  : t.task_type === 'Accounting'
                                                    ? 'text-purple-300 font-bold'
                                                    : t.task_type === 'Order Fulfillment'
                                                      ? 'text-fire font-bold'
                                                      : ''
                                        }`}
                                    >
                                        {t.topic} (
                                        {t.task_type
                                            .split(' ')
                                            .map((s) => s[0])
                                            .join('')}
                                        )
                                    </div>
                                    <div className='p-[8px] text-[14px]'>{t.title}</div>
                                    {(t as any).timezone ? (
                                        <div className='p-[8px] text-[14px]'>
                                            {t.customer_province_code} - {(t as any).timezone}
                                        </div>
                                    ) : (
                                        <div className='p-[8px] text-[14px]'></div>
                                    )}
                                    <div className='p-[8px] text-[14px]'>{formatter.format(t.due_at)}</div>
                                    <div className='p-[8px] text-[14px]'>{formatter.format(t.created_at)}</div>
                                    {(user.type === 'Admin' || user.type === 'SuperAdmin') && (
                                        <div className='p-[8px] text-[14px] '>
                                            {(t as any).last_task_event}{' '}
                                            {(t as any).last_task_event_created_at
                                                ? taskEventTime.format(new Date((t as any).last_task_event_created_at))
                                                : ''}
                                        </div>
                                    )}
                                    <div className='p-[8px] text-[14px]'>{(t as any).assigned_to.join(', ')}</div>
                                </NavLink>
                            ))}
                    </div>
                </div>
            )}

            {(filterByStatus.includes('COMPLETED') || filterByStatus.length === 0) && (
                <div className='mb-[32px]'>
                    <h2 className='text-[20px] mt-[16px] font-bai dark:text-offwhite'>
                        Completed Tasks ({tasks.filter((task) => task.status === 'COMPLETED').length})
                    </h2>
                    <div
                        className={`${
                            user.type === 'Admin' || user.type === 'SuperAdmin'
                                ? 'grid mt-[16px] grid-cols-[78px_93px_20px_120px_3fr_75px_75px_200px_minmax(75px,125px)]'
                                : 'grid mt-[16px] grid-cols-[78px_93px_20px_120px_3fr_75px_75px_minmax(75px,125px)]'
                        } border-[1px] border-[#cdcdcd] dark:border-darkgrey`}
                    >
                        <div
                            className='flex items-center justify-between p-[8px] text-[12px] text-[white] bg-[rgb(0,60,90)] dark:bg-darkaccent font-extrabold cursor-pointer font-bai border-b-[1px] border-darkgrey'
                            onClick={() => setSortMode(sortMode === 'order_name' ? 'order_name_reverse' : 'order_name')}
                        >
                            PO / DRAFT #
                            {sortMode.includes('order_name') && (
                                <span style={{ fontSize: '18px', lineHeight: 1 }}>*</span>
                            )}
                        </div>
                        <div
                            className='flex items-center justify-between p-[8px] text-[12px] text-[white] bg-[rgb(0,60,90)] dark:bg-darkaccent font-extrabold cursor-pointer font-bai border-b-[1px] border-darkgrey'
                            onClick={() => setSortMode(sortMode === 'priority' ? 'priority_reverse' : 'priority')}
                        >
                            PRIORITY
                            {sortMode.includes('priority') && (
                                <span style={{ fontSize: '18px', lineHeight: 1 }}>*</span>
                            )}
                        </div>
                        <div className='flex items-center justify-between p-[8px] text-[12px] text-[white] bg-[rgb(0,60,90)] dark:bg-darkaccent font-extrabold cursor-pointer font-bai border-b-[1px] border-darkgrey'></div>
                        <div className='flex items-center justify-between p-[8px] text-[12px] text-[white] bg-[rgb(0,60,90)] dark:bg-darkaccent font-extrabold cursor-pointer font-bai border-b-[1px] border-darkgrey'>
                            TYPE
                            {sortMode === 'type' && <span style={{ fontSize: '18px', lineHeight: 1 }}>*</span>}
                        </div>

                        <div className='flex items-center justify-between p-[8px] text-[12px] text-[white] bg-[rgb(0,60,90)] dark:bg-darkaccent font-extrabold cursor-pointer font-bai border-b-[1px] border-darkgrey'>
                            LATEST ACTIVITY
                            {sortMode === 'title' && <span style={{ fontSize: '18px', lineHeight: 1 }}>*</span>}
                        </div>
                        <div
                            className='flex items-center justify-between p-[8px] text-[12px] text-[white] bg-[rgb(0,60,90)] dark:bg-darkaccent font-extrabold cursor-pointer font-bai border-b-[1px] border-darkgrey'
                            onClick={() => setSortMode(sortMode === 'due_at' ? 'due_at_reverse' : 'due_at')}
                        >
                            DUE
                            {sortMode.includes('due_at') && <span style={{ fontSize: '18px', lineHeight: 1 }}>*</span>}
                        </div>
                        <div
                            className='flex items-center justify-between p-[8px] text-[12px] text-[white] bg-[rgb(0,60,90)] dark:bg-darkaccent font-extrabold cursor-pointer font-bai border-b-[1px] border-darkgrey'
                            onClick={() => setSortMode(sortMode === 'created_at' ? 'created_at_reverse' : 'created_at')}
                        >
                            CREATED
                            {sortMode.includes('created_at') && (
                                <span style={{ fontSize: '18px', lineHeight: 1 }}>*</span>
                            )}
                        </div>
                        {(user.type === 'Admin' || user.type === 'SuperAdmin') && (
                            <div className='flex items-center justify-between p-[8px] text-[12px] text-[white] bg-[rgb(0,60,90)] dark:bg-darkaccent font-extrabold cursor-pointer font-bai border-b-[1px] border-darkgrey'>
                                LAST VIEWED
                            </div>
                        )}
                        <div className='flex items-center justify-between p-[8px] text-[12px] text-[white] bg-[rgb(0,60,90)] dark:bg-darkaccent font-extrabold cursor-pointer font-bai border-b-[1px] border-darkgrey'>
                            ASSIGNED TO
                        </div>
                        {tasks
                            .filter((task) => task.status === 'COMPLETED')
                            .map((t) => (
                                <NavLink
                                    key={t.id}
                                    className={`grid ${
                                        user.type === 'Admin' || user.type === 'SuperAdmin'
                                            ? 'grid-cols-[78px_93px_20px_120px_3fr_75px_75px_200px_minmax(75px,125px)]'
                                            : 'grid-cols-[78px_93px_20px_120px_3fr_75px_75px_minmax(75px,125px)]'
                                    } col-start-1 col-end-10 cursor-pointer ${
                                        t.due_at.getTime() < Date.now() - 93600000
                                            ? 'bg-[#8a0f0f] text-[white] hover:bg-[#CC0000]'
                                            : 'hover:bg-[rgb(199,202,209)] [&:nth-child(even)]:bg-[rgb(224,228,235)] [&:nth-child(even)]:hover:bg-[rgb(199,202,209)] dark:[&:nth-child(even)]:bg-darkaccent dark:[&:nth-child(even)]:hover:bg-blue dark:bg-darkness dark:text-offwhite dark:hover:bg-blue'
                                    }`}
                                    onClick={(event: any) => handleNavigate(event, orderOrDraftOrderId(t))}
                                    to={orderOrDraftOrderId(t)}
                                >
                                    <div className='p-[8px] text-[14px]'>{t.order_name || t.draft_order_name}</div>
                                    <div className='p-[8px] text-[14px]'>{renderPriority(t.priority)}</div>
                                    <div className='js-orders-icon-list relative flex gap-[8px] h-[100%] w-[100%]'>
                                        <div className='flex gap-[8px]'>
                                            {(!t?.order_notes?.match(/https:\/\/fpdash.com\/tasks\/\d+\n/) ||
                                                t.order_notes !==
                                                    t?.order_notes?.match(
                                                        /https:\/\/fpdash.com\/tasks\/\d+\n/
                                                    )![0]) && (
                                                <div
                                                    className='js-orders-icon pt-[12px]'
                                                    onMouseOver={() => {
                                                        setIconMessage([t.id, `${t.order_notes}`])
                                                    }}
                                                >
                                                    <svg
                                                        className='stroke-black dark:stroke-accent pointer-events-none h-[14px] w-[14px]'
                                                        stroke='currentColor'
                                                        fill='currentColor'
                                                        strokeWidth='0'
                                                        viewBox='0 0 24 24'
                                                        height='1em'
                                                        width='1em'
                                                        xmlns='http://www.w3.org/2000/svg'
                                                    >
                                                        <path
                                                            className='pointer-events-none'
                                                            fill='none'
                                                            strokeWidth='2'
                                                            d='M3,1 L3,23 L16,23 L21,18 L21,1 L3,1 Z M6,17 L11,17 M6,13 L18,13 M6,9 L16,9 M3,5 L21,5 M21,17 L15,17 L15,23'
                                                        ></path>
                                                    </svg>
                                                </div>
                                            )}
                                        </div>
                                        <div
                                            className='flex grow h-[100%] shrink-0'
                                            onMouseOver={() => {
                                                setIconMessage([-1, ''])
                                            }}
                                        />
                                        {iconMessage[0] === t.id && (
                                            <div className='hidden js-orders-icon-message js-dont-navigate absolute hover:flex p-[16px] min-w-[350px] top-[calc(95%-16px)] left-[-36px] cursor-auto js-dont-navigate text-[14px]'>
                                                <div className='left-[0px] top-[0px] js-orders-icon p-[8px] min-w-[300px] bg-white dark:bg-darkaccent border-darkgrey border-[1px] text-black dark:text-offwhite z-index-4 shadow-small js-dont-navigate'>
                                                    {renderIconMessage(iconMessage[1])}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div
                                        className={`p-[8px] text-[14px] ${
                                            t.task_type === 'Customer Service'
                                                ? 'text-[rgb(0,136,204)] font-bold'
                                                : t.task_type === 'Sales'
                                                  ? 'text-[#41a112] font-bold'
                                                  : t.task_type === 'Accounting'
                                                    ? 'text-purple-300 font-bold'
                                                    : t.task_type === 'Order Fulfillment'
                                                      ? 'text-fire font-bold'
                                                      : ''
                                        }`}
                                    >
                                        {t.topic} (
                                        {t.task_type
                                            .split(' ')
                                            .map((s) => s[0])
                                            .join('')}
                                        )
                                    </div>
                                    <div className='p-[8px] text-[14px]'>{t.title}</div>
                                    <div className='p-[8px] text-[14px]'>{formatter.format(t.due_at)}</div>
                                    <div className='p-[8px] text-[14px]'>
                                        {formatter.format(new Date(t.created_at))}
                                    </div>
                                    {(user.type === 'Admin' || user.type === 'SuperAdmin') && (
                                        <div className='p-[8px] text-[14px] '>
                                            {(t as any).last_task_event}{' '}
                                            {(t as any).last_task_event_created_at
                                                ? taskEventTime.format(new Date((t as any).last_task_event_created_at))
                                                : ''}
                                        </div>
                                    )}
                                    <div className='p-[8px] text-[14px]'>{t.assigned_to.join(', ')}</div>
                                </NavLink>
                            ))}
                    </div>
                </div>
            )}
        </div>
    )
}
