import { cn } from 'helpers'
import { useDeleteInfoBlock, useUpdateInfoBlock } from 'procurement/api/useQueries'
import Input from 'procurement/components/Input'
import { ChangeEvent, useState } from 'react'
import { FaTrash } from 'react-icons/fa'

export default function VendorInfoBlock({ infoBlock, index }: { infoBlock: any; index: number }) {
    const [editableFields, setEditableFields] = useState<{ header?: string; body?: string }>({})
    const [showDelete, setShowDelete] = useState(false)
    const [expand, setExpand] = useState(false)

    const isEdited = Boolean(Object.keys(editableFields).length > 0)

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setEditableFields((previousState: any) => ({
            ...structuredClone(previousState),
            [target.name]: target.type === 'checkbox' ? (target as HTMLInputElement).checked : target.value,
        }))
    }

    const deleteInfoBlock = useDeleteInfoBlock()
    const updateInfoBlock = useUpdateInfoBlock()

    const handleDelete = () => {
        deleteInfoBlock.mutate(infoBlock, {
            onSuccess: () => {
                setShowDelete(false)
            },
        })
    }

    const handleUpdate = () => {
        updateInfoBlock.mutate(
            { infoBlock: { ...infoBlock, ...editableFields } },
            {
                onSuccess: () => {
                    setEditableFields({})
                    setShowDelete(false)
                },
            }
        )
    }

    function SaveButtons() {
        return (
            <div className='flex gap-3 justify-end items-center '>
                <button
                    onClick={handleUpdate}
                    className={`${
                        updateInfoBlock.isPending ? 'opacity-[0.9] pointer-events-none' : 'pointer-events-auto'
                    } shrink-0 text-[14px] leading-[1] py-1 px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase`}
                >
                    {updateInfoBlock.isPending ? 'SAVING...' : 'SAVE'}
                </button>
                <button
                    onClick={() => {
                        setEditableFields({})
                        setShowDelete(false)
                    }}
                    className={`${
                        updateInfoBlock.isPending ? 'opacity-[0.9] pointer-events-none' : 'pointer-events-auto'
                    } self-center shrink-0 text-[14px] leading-[1] py-1 px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkgrey text-black dark:text-offwhite font-bold uppercase`}
                >
                    Cancel
                </button>
            </div>
        )
    }
    return (
        <div
            className={cn(
                index % 2 == 0 && 'bg-lightgrey dark:bg-darkbg1',
                `flex flex-col p-[0px] rounded-md border-[1px] border-darkgrey`
            )}
        >
            <div className='flex justify-between items-center w-[100%] p-1'>
                <Input
                    type='text'
                    id='header'
                    name='header'
                    onChange={handleChange}
                    value={editableFields.header ?? infoBlock.header}
                    className={cn(
                        'w-3/4 font-bold bg-bg1 focus:bg-lightgrey',
                        index % 2 == 0 && 'bg-lightgrey focus:bg-bg1'
                    )}
                ></Input>
                {isEdited && <SaveButtons />}
                {!showDelete && !isEdited && (
                    <button className='' onClick={() => setShowDelete(true)}>
                        <FaTrash className='text-red dark:text-lightred' />
                    </button>
                )}
                {showDelete && !isEdited && (
                    <div className='flex gap-[8px] self-center items-center justify-self-end'>
                        <button
                            onClick={handleDelete}
                            className={`${
                                deleteInfoBlock.isPending ? 'opacity-[0.5] pointer-events-none' : 'pointer-events-auto'
                            } shrink-0 text-[14px] leading-[1] py-[3px] w-fit px-[8px] rounded-[4px] bg-red dark:bg-lightred text-white font-bold uppercase`}
                        >
                            Delete
                        </button>
                        <button
                            onClick={() => setShowDelete(false)}
                            className={`${
                                deleteInfoBlock.isPending ? 'opacity-[0.5] pointer-events-none' : 'pointer-events-auto'
                            } text-[14px] leading-[1] py-[3px] w-fit px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkgrey font-bold uppercase`}
                        >
                            Cancel
                        </button>
                    </div>
                )}
            </div>
            <div className=' p-[8px]'>
                <div className='grow'>
                    <textarea
                        onMouseEnter={() => setExpand(true)}
                        onMouseLeave={() => setExpand(false)}
                        id='body'
                        name='body'
                        onChange={handleChange}
                        value={editableFields.body ?? infoBlock.body}
                        className={cn(
                            `block w-[100%] text-[14px] rounded border-darkgrey p-[4px] dark:text-offwhite dark:bg-darkness outline-none  transition-all duration-200 delay-75  h-[45px] focus:h-[200px]`,
                            expand && 'h-[200px]'
                        )}
                    />
                </div>
                {/* <Input
                    type='text'
                    onChange={handleChange}
                    id='body'
                    name='body'
                    value={editableFields.body ?? infoBlock.body}
                ></Input> */}
            </div>
        </div>
    )
}
