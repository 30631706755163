import { useState } from 'react'
import { closePopout, sendToast, sortByAlphanumeric, vFetch } from '../../helpers'
import { VendorContactEditInit, VendorContactsMap } from '../vendors.types'
import { MdContentCopy } from 'react-icons/md'

type VendorContactsListProps = {
    vendorsInfo: VendorContactsMap
    setVendorsInfo: Function
    contacts: VendorContactEditInit[]
    setVendorContactToEdit: Function
    refreshVendorsList: Function
}

export default function VendorContactsList({
    vendorsInfo,
    setVendorsInfo,
    contacts,
    setVendorContactToEdit,
    refreshVendorsList,
}: VendorContactsListProps) {
    const [dragged, setDragged] = useState<VendorContactEditInit | null>(null)
    const [reorderedContacts, setReorderedContacts] = useState<VendorContactEditInit[]>([])

    const vendorContactClassName = (contact: VendorContactEditInit) =>
        `js-vendors-list__${contact.vendor.trim()}-contact`
    function handleChangeContactOrder(e: any, contact: VendorContactEditInit, i: number) {
        if (JSON.stringify(dragged) !== JSON.stringify(contact)) setDragged(contact)
        const vendor = vendorsInfo[contact.vendor]
        const { contacts } = vendor
        if (!contacts)
            return sendToast({
                message: 'Something went wrong. Cannot reorder contacts. Please contact the developers.',
            })

        const elements = document.getElementsByClassName(vendorContactClassName(contact))
        const elLength = Number(
            [...(elements[elements.length - 1].classList as any)]
                .find((name) => name.includes('grid-row'))
                .split('-')[2]
        )
        const numColumns = elements.length / elLength
        const firstElementPerRow = [...(elements as any)].filter((el, i) => i % numColumns === 0)

        if (dragged) {
            let index = 0
            let newSortId = 0
            const contactsReordered = [...contacts.map((c) => (c.id !== contact.id ? c : undefined))]
            while (index < firstElementPerRow.length) {
                const { height, y } = firstElementPerRow[index].getBoundingClientRect()
                if (e.clientY < y + height / 2) index = Infinity
                else {
                    index++
                    newSortId = index
                }
            }
            contactsReordered.splice(newSortId, 0, contact)

            if (e.clientX === 0 && e.clientY === 0) {
                setVendorsInfo({
                    ...vendorsInfo,
                    [contact.vendor]: { ...vendorsInfo[contact.vendor], contacts: reorderedContacts },
                })
                vFetch(`/v1/vendors/contacts/v2`, {
                    method: 'PUT',
                    body: JSON.stringify({ contacts: reorderedContacts }),
                    cb: (res: any) => {
                        if (res.success) refreshVendorsList()
                    },
                })
            } else
                setReorderedContacts(
                    contactsReordered
                        .filter((v) => v)
                        .map((c, i) => {
                            return { ...c, sort_id: i + 1 }
                        }) as VendorContactEditInit[]
                )
        }
    }

    return (
        <div className={`relative grid grid-cols-[repeat(6,auto)_1fr] w-full pointer-events-auto`}>
            {contacts.map((c, index) => {
                const vendorValues = [
                    c.contact_type,
                    c.name,
                    [
                        c.phone_prefix ? '+' + c.phone_prefix + '-' : null,
                        c.phone ? [c.phone.slice(0, 3), c.phone.slice(3, 6), c.phone.slice(6)].join('-') : null,
                        c.phone_extension ? ', EXT' + c.phone_extension : null,
                    ]
                        .filter((p) => p)
                        .join(''),
                    c.email,
                    c.cell_phone
                        ? `Cell: ${c.cell_phone.slice(0, 3)}-${c.cell_phone.slice(3, 6)}-${c.cell_phone.slice(6)}`
                        : '',
                    c.alternate,
                ].filter((v) => v)
                // this fills the last row so that the bg color goes all the way to the end and makes it look like a normal grid.
                while (vendorValues.length < 7) vendorValues.push('')

                return (
                    <div
                        className={`contents cursor-pointer [&>*]:hover:!bg-[rgb(199,202,209)] [&>*]:dark:hover:!bg-blue [&>*:first-child]:pl-[8px] [&>*:last-child]:pr-[8px] ${
                            index % 2
                                ? '[&>*]:bg-[rgb(224,228,235)] [&>*]:dark:bg-darkness'
                                : '[&>*]:dark:bg-darkaccent'
                        }`}
                        // onClick={(e) => {
                        //     closePopout(e, ['js-contact__copy-button'], () => setVendorContactToEdit(c))
                        // }}
                        onDrag={(e) => handleChangeContactOrder(e, c, index)}
                    >
                        {vendorValues.map((v, i) => (
                            <div className='flex justify-between items-center'>
                                <p
                                    style={{ gridRowStart: index + 1 }}
                                    className={`${vendorContactClassName(c)} px-[8px] grid-row-${index + 1}`}
                                    draggable={true}
                                >
                                    {v}
                                </p>
                                {!!v && !!i && (
                                    <MdContentCopy
                                        title={`Copy "${v}"`}
                                        className='js-contact__copy-button cursor-pointer opacity-50 hover:opacity-100 duration-300'
                                        onClick={() => {
                                            navigator.clipboard.writeText(v)
                                            sendToast({ message: `Copied: ${v}` })
                                        }}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                )
            })}
        </div>
    )
}
