import { formatDateToLocale } from '../helpers'
import { TaskEditInit, TaskEventType } from './tasks.types'

export const taskDateFormatterOptions: any = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
}
export const taskDateFormatterTLEOptions = {
    ...taskDateFormatterOptions,
    hour12: false,
    seconds: 'numeric',
    timeZone: 'UTC',
}

export const taskDateFormatter = new Intl.DateTimeFormat('en-US', taskDateFormatterOptions).format

export function renderTaskTimelineEvent(event: any, initialTask: any, eventIndex?: any) {
    let editedFields: any[] = []
    try {
        editedFields =
            event.event_type !== 'comment' && event.event_type !== 'email'
                ? event.message.split('(')[2].split(')')[0].split(',')
                : []
    } catch {}
    // not rendered on hub screen, beause eventIndex is not passed.
    const nextState =
        eventIndex !== undefined
            ? eventIndex === 0
                ? initialTask
                : initialTask.timeline_events[eventIndex - 1].previous_state
            : undefined
    const messageWithFields = () => {
        let newHTMLString = eventMessage
        editedFields.forEach((field: any) => {
            try {
                newHTMLString = newHTMLString.replace(
                    field,
                    `<span class="task-screen__field">${field}</span><pre class="task-screen__field-body whitespace-pre-wrap font-bai absolute text-[14px] top-[100%] left-0 w-[100%] max-h-[300px] overflow-y-scroll py-[8px] px-[16px] rounded border-[1px] border-[#a1a1a1] shadow-small bg-white text-black dark:text-grey dark:bg-darkness dark:shadow-hot dark:border-darkaccent z-index-5">${
                        field.trim() === 'order_notes'
                            ? `${nextState[field.trim()]}`
                            : field.trim() === 'due_at'
                              ? `${
                                    new Date(event.previous_state[field.trim()]).toUTCString().split(' 00:')[0]
                                } <span class="inline-block text-[18px] text-lime font-black leading-[18px]  border-[1px] border-lime pt-[2px] rounded">→</span> ${
                                    new Date(nextState[field.trim()]).toUTCString().split(' 00:')[0]
                                }`
                              : `${
                                    event.previous_state[field.trim()]
                                } <span class="inline-block text-[18px] text-lime font-black leading-[18px]  border-[1px] border-lime pt-[2px] rounded">→</span> ${
                                    nextState[field.trim()]
                                }`
                    }</pre>`
                )
            } catch {}
        })

        return newHTMLString
    }
    const niceDate = new Date(event.created_at)
    let date = [niceDate.getMonth() + 1, niceDate.getDate(), niceDate.getFullYear()].join('/')
    let time = formatDateToLocale(niceDate).split(' ').slice(3).join(' ')

    const eventMessage =
        eventIndex !== undefined
            ? event.message
            : event.message.replaceAll(
                  'this task',
                  `${initialTask.task_type
                      .split(' ')
                      .map((w: string) => w[0])
                      .join('')} task ${initialTask.id}`
              )

    return event.event_type === 'comment' && event.user ? (
        <li className='flex mb-[16px] gap-[8px] bg-white dark:bg-darkaccent basis-[100%] items-center'>
            {eventIndex !== undefined && (
                <div className='relative top-0 bg-[#809ead] dark:bg-accent border-[5px] border-white dark:border-darkaccent rounded-full shrink-0 h-[16px] ml-[calc(var(--p-space-025)+0.59375rem+var(--p-space-4))] mr-[var(--p-space-4)] w-[16px] z-index-2 self-center'></div>
            )}

            <div className='grid w-[100%] max-w-[100%] p-[16px] gap-[8px] dark:bg-darkness shadow-small dark:shadow-cool rounded'>
                <p className='text-[rgb(74, 74, 74)] dark:text-grey'>
                    <strong>
                        {event.user.first_name} {event.user.last_name} {`(${event.user.user_id})`}
                    </strong>
                </p>
                <pre className='dark:text-offwhite font-bai break-words whitespace-pre-wrap'>"{eventMessage}"</pre>
            </div>
            <div className='flex flex-wrap justify-end content-center text-[12px] h-[100%]'>
                <p className='dark:text-offwhite'>{date === 'Today' ? 'Today,' : date}</p>
                <p className='text-darkgrey dark:text-grey'>{time}</p>
            </div>
        </li>
    ) : event.event_type === 'email' && event.user ? (
        <li className='flex mb-[16px] gap-[8px] bg-white dark:bg-darkaccent basis-[100%] items-center'>
            {eventIndex !== undefined && (
                <div className='relative top-0 bg-[#809ead] dark:bg-accent border-[5px] border-white dark:border-darkaccent rounded-full shrink-0 h-[16px] ml-[calc(var(--p-space-025)+0.59375rem+var(--p-space-4))] mr-[var(--p-space-4)] w-[16px] z-index-2 self-center'></div>
            )}

            <div className='grid w-[100%] max-w-[100%] p-[16px] gap-[8px] dark:bg-darkness shadow-small dark:shadow-cool rounded'>
                <p className='text-[rgb(74, 74, 74)] dark:text-grey'>
                    <strong>
                        {event.user.first_name} {event.user.last_name} {`(${event.user.user_id})`} sent an email.
                    </strong>
                </p>
                <div className='flex flex-col gap-[8px] p-[8px] border-[1px] border-darkgrey rounded'>
                    {(event.cc || event.from || event.send_to || event.subject) && (
                        <div className='border-b-[1px] border-darkgrey'>
                            {event.send_to && (
                                <div className='flex flex-wrap gap-x-[8px]'>
                                    <p>
                                        To:{' '}
                                        {(Array.isArray(event.send_to) && event.send_to.join(', ')) || event.send_to}
                                    </p>
                                    {event.cc && <p>CC: {event.cc}</p>}
                                </div>
                            )}
                            {event.respond_to && <p>From: {event.respond_to}</p>}
                            {event.subject && <p className='font-bold text-[18px]'>{event.subject}</p>}
                        </div>
                    )}
                    <pre className='dark:text-offwhite font-bai break-words whitespace-pre-wrap'>{eventMessage}</pre>
                </div>
            </div>
            <div className='flex flex-wrap justify-end content-center text-[12px] h-[100%]'>
                <p className='dark:text-offwhite'>{date === 'Today' ? 'Today,' : date}</p>
                <p className='text-darkgrey dark:text-grey'>{time}</p>
            </div>
        </li>
    ) : (
        <li
            className={`flex  gap-[8px] bg-white dark:bg-darkaccent basis-[100%] items-center ${
                eventIndex === undefined ? 'pl-[8px]' : 'mb-[16px]'
            }`}
        >
            {eventIndex !== undefined && (
                <div className='relative top-0 bg-[#809ead] dark:bg-accent border-[5px] border-white dark:border-darkaccent rounded-full shrink-0 h-[16px] ml-[calc(var(--p-space-025)+0.59375rem+var(--p-space-4))] mr-[var(--p-space-4)] w-[16px] z-index-2 self-center'></div>
            )}
            <div className='basis-[100%]'>
                <p
                    className='relative dark:text-offwhite'
                    dangerouslySetInnerHTML={{
                        __html:
                            editedFields.length > 0
                                ? eventIndex !== undefined
                                    ? messageWithFields()
                                    : eventMessage
                                : eventMessage,
                    }}
                ></p>
            </div>
            <div className='flex flex-wrap justify-end content-center text-[12px] h-[100%]'>
                <p className='dark:text-offwhite'>{date}</p>
                <p className='text-darkgrey dark:text-grey'>{time}</p>
            </div>
        </li>
    )
}
export function getTaskEventDate(taskEvent: TaskEventType, setTaskEventDate: Function) {
    if (taskEvent) {
        const theTime = new Intl.DateTimeFormat('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour12: true,
            timeZone: 'America/Chicago',
        }).format(new Date(taskEvent.created_at))
        setTaskEventDate(theTime)
    }
}
export function orderOrDraftOrderId(task: TaskEditInit) {
    return task.order_id || task.draft_order_id
        ? `/${task.draft_order_id ? 'draft' : ''}orders/${task.order_id || task.draft_order_id}?navigated_from=task=${
              task.id
          }`
        : `/tasks/${task.id}`
}
