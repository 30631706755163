'use client'

import { Popover, PopoverContent, PopoverTrigger } from '@radix-ui/react-popover'
import { addDays } from 'date-fns'
import { getDateToday } from 'procurement/constants'
import { formatDateSimple } from 'procurement/helpers'
import { CalendarBaseLeadTimes } from './CalendarBaseLeadTimes'
import { cn } from 'helpers'

export function CalendarSelectorLeadTimes<TData, TValue>({
    date,
    setDate,
    openFlag,
    className,
    label,
    readOnly,
}: {
    date: Date | undefined
    setDate: any
    className?: string
    openFlag?: boolean
    label?: string
    readOnly?: boolean
}) {
    return (
        <Popover open={openFlag || undefined}>
            <PopoverTrigger asChild>
                <div className={cn('flex flex-col gap-1', readOnly && 'pointer-events-none')}>
                    {label && (
                        <label
                            className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                            htmlFor='available'
                        >
                            {label}
                        </label>
                    )}
                    <div
                        className={cn(
                            'flex cursor-pointer items-center text-[16px] w-full leading-none h-[29.5px] bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]',
                            className
                        )}
                        id='available_at'
                        onChange={() => {}}
                    >
                        <p>{date ? formatDateSimple(date) : ''}</p>
                    </div>
                </div>
            </PopoverTrigger>
            <PopoverContent className='w-auto p-0 z-50 ' align='start'>
                <CalendarBaseLeadTimes
                    className='border border-primary rounded bg-bg1 dark:bg-darkbg1'
                    mode='single'
                    selected={date}
                    onSelect={(e: any) => {
                        if (e === undefined) {
                            setDate(addDays(getDateToday(), 1))
                        } else {
                            setDate(e)
                        }
                    }}
                    showTasks={false}
                    initialFocus
                />
            </PopoverContent>
        </Popover>
    )
}
