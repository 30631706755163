import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { vFetch } from 'helpers'
import Input from 'procurement/components/Input'
import { useState } from 'react'

export default function InvoicePaidDate({
    custom_invoice_paid,
    order_id,
}: {
    custom_invoice_paid?: string
    order_id: string
}) {
    const queryClient = useQueryClient()
    const saveInvoicePaidDateMutation = useMutation({
        mutationFn: ({ invoice_paid_date }: { invoice_paid_date: string }) =>
            vFetch(`/v2/orders/invoicePaidDate`, {
                method: 'PUT',
                body: JSON.stringify({
                    order_id,
                    invoice_paid_date: invoice_paid_date || null,
                }),
            }),
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: ['orders'] })
        },
    })
    const [editableInvoicePaidDate, setEditableInvoicePaidDate] = useState<any>(custom_invoice_paid)
    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
        setEditableInvoicePaidDate(target.value)
        saveInvoicePaidDateMutation.mutate({ invoice_paid_date: target.value })
    }
    return (
        <div className='grid gap-[8px] bg-white dark:bg-darkaccent p-[16px] shadow-small rounded dark:text-offwhite'>
            <h2 className='text-[20px] font-bold mb-[4px]'>Invoice Paid Date</h2>
            <Input
                type='date'
                id='invoice-paid-date'
                name='invoice-paid-date'
                value={editableInvoicePaidDate}
                onChange={handleChange}
            />
        </div>
    )
}
