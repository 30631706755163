import { useEffect, useState } from 'react'
import { vFetch } from '../helpers'

export default function DailyFreightScreen() {
    const [dailyFreight, setDailyFreight] = useState([])
    const [initialDailyFreight, setInitialDailyFreight] = useState([])
    const [filteredDailyFreight, setFilteredDailyFreight] = useState([])
    const [view, setView] = useState('ACTIVE')
    const [needsRefresh, setNeedsRefresh] = useState(true)
    const [edited, setEdited] = useState(false)
    const [showBulk, setShowBulk] = useState(false)
    const [bulk, setBulk] = useState('')
    const [search, setSearch] = useState('')
    useEffect(() => {
        if (needsRefresh) {
            setNeedsRefresh(false)
            vFetch(`/v1/orders/dailyFreight`, {
                cb: (res) => {
                    if (res.success) {
                        setDailyFreight((prev) => res.dailyFreight)
                        setInitialDailyFreight((prev) => res.dailyFreight)
                        setFilteredDailyFreight((prev) => res.dailyFreight)
                    }
                },
            })
        }
    }, [needsRefresh])
    useEffect(() => {
        if (JSON.stringify(dailyFreight) !== JSON.stringify(initialDailyFreight)) {
            setEdited(true)
        } else {
            setEdited(false)
        }
        const indexedFreight = dailyFreight.map((df, idx) => ({ ...df, index: idx }))
        setFilteredDailyFreight(
            indexedFreight.filter((df) => {
                if (view === 'ACTIVE' && df.status === 'MONITOR') {
                    return true
                }
                if (df.status !== view) {
                    return false
                }
                if (df.order_name.toLowerCase().includes(search.toLowerCase())) {
                    return true
                }
                if (df.note.toLowerCase().includes(search.toLowerCase())) {
                    return true
                }
                return false
            })
        )
    }, [dailyFreight, initialDailyFreight, search, view])

    const handleSave = () => {
        const differences = []
        const newDf = []
        for (let i = 0; i < dailyFreight.length; i++) {
            const current = dailyFreight[i]
            const compare = initialDailyFreight[i - dailyFreight.filter((f) => f.is_new).length] || {}
            if (!current.is_new && (compare.note !== current.note || compare.status !== current.status)) {
                differences.push(current)
            } else if (current.is_new) {
                newDf.push(current)
            }
        }
        const promises = []
        if (differences.length > 0) {
            promises.push(
                promises.push(
                    vFetch(`/v1/orders/dailyFreight`, {
                        method: 'PUT',
                        body: JSON.stringify({ daily_freight: differences }),
                    })
                )
            )
        }
        if (newDf.length > 0) {
            promises.push(
                vFetch(`/v1/orders/dailyFreight`, {
                    method: 'POST',
                    body: JSON.stringify({ daily_freight: newDf }),
                })
            )
        }
        Promise.allSettled(promises).then((res) => {
            setNeedsRefresh(true)
        })
    }
    const handleChangeDf = (e, index) => {
        setDailyFreight((prev) => {
            const editedDf = prev[index]
            const newDf = {
                ...editedDf,
                [e.target.name]: e.target.value,
            }
            const clone = [...prev]
            clone.splice(index, 1, newDf)
            return clone
        })
    }
    const handleAddNewDf = () => {
        setDailyFreight((prev) => [
            {
                order_name: '',
                note: '',
                status: 'ACTIVE',
                is_new: true,
            },
            ...prev,
        ])
    }
    const handleDeleteDf = (df, index) => {
        if (df.is_new) {
            setDailyFreight((prev) => {
                const clone = [...prev]
                clone.splice(index, 1)
                return clone
            })
        } else {
            vFetch(`/v1/orders/dailyFreight`, {
                method: 'DELETE',
                body: JSON.stringify({
                    order_name: df.order_name,
                }),
                cb: (res) => {
                    if (res.success) {
                        setNeedsRefresh(true)
                    }
                },
            })
        }
    }
    const handleBulkAdd = () => {
        setDailyFreight((prev) => [
            ...bulk
                .split(/[ ,\n]+/)
                .filter((v) => v && !prev.find((df) => df.order_name === v.trim()))
                .map((v) => ({ order_name: v.trim(), note: '', status: 'ACTIVE', is_new: true })),
            ...prev,
        ])
        setShowBulk(false)
    }
    return (
        <>
            <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize mb-2'>Daily Freight</h1>
            {showBulk && (
                <div className='fixed z-[50] top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] grid place-items-center'>
                    <div className='relative p-[16px] rounded-[4px] shadow-small bg-white dark:bg-darkaccent'>
                        <button
                            onClick={() => setShowBulk(false)}
                            className='absolute top-0 right-[4px] font-bold text-[32px] text-red dark:text-lightred leading-[1]'
                        >
                            &times;
                        </button>
                        <p className='dark:text-offwhite uppercase text-[12px] font-bold py-[4px] px-[8px]'>
                            PO Numbers
                        </p>
                        <textarea
                            className='w-[400px] rounded-[4px]  bg-lightgrey dark:bg-faintplus dark:text-white focus:outline-none border-0'
                            value={bulk}
                            onChange={({ target }) => setBulk(target.value)}
                        ></textarea>
                        <button
                            onClick={handleBulkAdd}
                            className='py-[4px] px-[16px] bg-blue dark:bg-accent font-bold text-white dark:text-darkness uppercase block mx-auto rounded-[4px]'
                        >
                            Add
                        </button>
                    </div>
                </div>
            )}
            {edited && (
                <button
                    onClick={handleSave}
                    className='fixed top-[50%] right-0 translate-y-[-50%] py-[4px] px-[16px] bg-blue font-bold text-white dark:bg-accent dark:text-darkness uppercase shadow-small dark:shadow-cool'
                >
                    Save
                </button>
            )}
            <div className='flex gap-[4px] mb-[8px]'>
                <button
                    onClick={() => setView('ACTIVE')}
                    className={`mb-[-8px] py-[4px] px-[16px] rounded-t-[4px] font-bold ${
                        view === 'ACTIVE'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'bg-lightgrey dark:bg-darkness dark:text-lightgrey dark:border dark:border-darkgrey dark:border-b-0'
                    }`}
                >
                    Active
                </button>
                <button
                    onClick={() => setView('COMPLETED')}
                    className={`mb-[-8px] py-[4px] px-[16px] rounded-t-[4px] font-bold ${
                        view === 'COMPLETED'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'bg-lightgrey dark:bg-darkness dark:text-offwhite dark:border dark:border-darkgrey dark:border-b-0'
                    }`}
                >
                    Completed
                </button>
                <button
                    onClick={handleAddNewDf}
                    className='ml-auto py-[4px] px-[16px] bg-blue text-white rounded-[4px] dark:bg-accent dark:text-darkness font-bold uppercase'
                >
                    + New Daily Freight
                </button>
                <button
                    onClick={() => setShowBulk(true)}
                    className='py-[4px] px-[16px] bg-blue text-white rounded-[4px] dark:bg-accent dark:text-darkness font-bold uppercase'
                >
                    + Bulk
                </button>
            </div>
            <div className='shadow-small dark:bg-darkaccent rounded-[4px]'>
                <div className='flex border-b border-lightgrey dark:border-darkgrey'>
                    <input
                        className='py-[4px] px-[8px] dark:text-offwhite bg-transparent focus:outline-none w-full'
                        type='text'
                        placeholder='Search'
                        value={search}
                        onChange={({ target }) => setSearch(target.value)}
                    />
                </div>
                <div className='grid grid-cols-[100px_1fr_1fr]'>
                    <div className='sticky top-[50px] col-span-full grid grid-cols-[100px_1fr_150px] bg-blue dark:bg-darkness border-b border-lightgrey dark:border-darkgrey'>
                        <p className='text-white dark:text-offwhite uppercase text-[12px] font-bold p-[8px]'>PO</p>
                        <p className='text-white dark:text-offwhite uppercase text-[12px] font-bold p-[8px]'>Note</p>
                        <p className='text-white dark:text-offwhite uppercase text-[12px] font-bold p-[8px]'>Status</p>
                    </div>
                    {filteredDailyFreight.map((df, index) => (
                        <div
                            key={df.is_new + String(index)}
                            className={`col-span-full grid grid-cols-[100px_1fr_150px] border-b border-lightgrey dark:border-darkgrey ${
                                df.status === 'MONITOR' ? 'bg-red' : ''
                            }`}
                        >
                            {df.is_new ? (
                                <div className='p-[8px] w-full'>
                                    <input
                                        className='rounded-[4px] py-[4px] px-[8px] rounded-4px w-full bg-lightgrey dark:bg-faintplus dark:text-white focus:outline-none'
                                        type='text'
                                        name='order_name'
                                        value={df.order_name}
                                        onChange={(e) => handleChangeDf(e, df.index)}
                                    />
                                </div>
                            ) : (
                                <p
                                    className={`font-bold text-[20px] p-[8px] ${
                                        df.status === 'MONITOR' ? 'text-white' : 'text-darkgrey dark:text-fire'
                                    }`}
                                >
                                    {df.order_name}
                                </p>
                            )}
                            <div className='p-[8px] w-full'>
                                <input
                                    className='rounded-[4px] py-[4px] px-[8px] rounded-4px w-full bg-lightgrey dark:bg-faintplus dark:text-white focus:outline-none'
                                    type='text'
                                    name='note'
                                    value={df.note}
                                    onChange={(e) => handleChangeDf(e, df.index)}
                                />
                            </div>
                            <div className='flex gap-[8px] p-[8px]'>
                                <select
                                    className='rounded-[4px] py-[4px] px-[8px] rounded-4px  bg-lightgrey dark:bg-faintplus dark:text-white focus:outline-none w-full'
                                    name='status'
                                    value={df.status}
                                    onChange={(e) => handleChangeDf(e, df.index)}
                                >
                                    <option value='ACTIVE'>ACTIVE</option>
                                    <option value='MONITOR'>MONITOR</option>
                                    <option value='COMPLETED'>COMPLETED</option>
                                </select>
                                <button
                                    className='text-red dark:text-lightred font-bold text-[32px] leading-[1] w-fit'
                                    onClick={() => handleDeleteDf(df, df.index)}
                                >
                                    &times;
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
