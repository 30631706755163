import { Dispatch, SetStateAction, useState } from 'react'

export default function CustomItemModal({
    setShowCustomItemModal,
    handleAddLineItem,
}: {
    setShowCustomItemModal: Dispatch<SetStateAction<boolean>>
    handleAddLineItem: Function
}) {
    const [name, setName] = useState('')
    return (
        <div className='fixed z-[10] w-full h-full bg-[rgb(0,0,0,0.5)] top-0 left-0 grid place-items-center'>
            <div className='p-[16px] rounded-[4px] dark:bg-darkaccent w-full max-w-[600px] flex flex-col gap-[8px]'>
                <input
                    type='text'
                    className='focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                    placeholder='Item Name'
                    value={name}
                    onChange={({ target }) => setName(target.value)}
                />
                <div className='flex gap-[8px] ml-auto'>
                    <button
                        className='py-[4px] px-[16px] bg-red text-white font-bold uppercase rounded-[4px]'
                        onClick={() => setShowCustomItemModal(false)}
                    >
                        Cancel
                    </button>
                    <button
                        className='py-[4px] px-[16px] dark:bg-accent dark:text-darkaccent font-bold uppercase rounded-[4px]'
                        onClick={() => {
                            handleAddLineItem({
                                title: name,
                                quantity: 1,
                                item_cost: 0,
                                product_title: name,
                                sku: '',
                                supplier_sku: '',
                            })
                            setShowCustomItemModal(false)
                        }}
                    >
                        Add Item
                    </button>
                </div>
            </div>
        </div>
    )
}
