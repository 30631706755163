import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import queryKeys from './queryKeys'
import { CreateTimesheetEntryType, UpdateTimesheetEntryType, UpdateTimesheetType } from 'timesheets/Timesheets.types'
import * as service from './service'

export const useGetTimesheets = (filters: any) => {
    return useQuery({
        ...queryKeys.timesheets.list(filters),
    })
}

export const useUpdateTimesheet = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (timesheet: UpdateTimesheetType) => service.updateTimesheet(timesheet),
        onSuccess: () => {
            return Promise.all([
                queryClient.invalidateQueries({ queryKey: queryKeys.timesheets._def }),
                queryClient.invalidateQueries({ queryKey: queryKeys.timesheetEntries._def }),
            ])
        },
    })
}

export const useCreateTimesheetEntry = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (entry: CreateTimesheetEntryType) => service.createEntry(entry),
        onSuccess: () => {
            return Promise.all([
                queryClient.invalidateQueries({ queryKey: queryKeys.timesheets._def }),
                queryClient.invalidateQueries({ queryKey: queryKeys.timesheetEntries._def }),
            ])
        },
    })
}

export const useGetTimesheetEntries = (filters: any) => {
    return useQuery({
        ...queryKeys.timesheetEntries.list(filters),
    })
}

export const useUpdateTimesheetEntry = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (entry: UpdateTimesheetEntryType) => service.updateEntry(entry),
        onSuccess: () => {
            return Promise.all([
                queryClient.invalidateQueries({ queryKey: queryKeys.timesheets._def }),
                queryClient.invalidateQueries({ queryKey: queryKeys.timesheetEntries._def }),
            ])
        },
    })
}

export const useDeleteTimesheetEntry = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (entryId: number) => service.deleteEntry(entryId),
        onSuccess: () => {
            return Promise.all([
                queryClient.invalidateQueries({ queryKey: queryKeys.timesheets._def }),
                queryClient.invalidateQueries({ queryKey: queryKeys.timesheetEntries._def }),
            ])
        },
    })
}
