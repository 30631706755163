import { useGetRoadmaps } from 'tasksV2/api/useQueries'

export default function RoadmapAssociationBuilder({
    associations,
    toggleAssociation,
}: {
    associations: { resource_name: string; readable_name: string; resource_id: number }[]
    toggleAssociation: (association: { resource_name: string; readable_name: string; resource_id: number }) => void
}) {
    const roadmapsQuery = useGetRoadmaps({})
    const roadmaps = roadmapsQuery.data?.roadmaps || []

    return (
        <div>
            <h2 className='font-bold mb-2'>Choose Roadmaps To Associate</h2>
            <div className='flex flex-col gap-1 h-[200px] overflow-auto'>
                {roadmapsQuery.isLoading ? (
                    <p className='p-2'>Loading...</p>
                ) : (
                    <>
                        {roadmaps.map((roadmap: any) => (
                            <div
                                onClick={() =>
                                    toggleAssociation({
                                        resource_name: 'roadmap',
                                        resource_id: roadmap.id,
                                        readable_name: roadmap.title,
                                    })
                                }
                                className='p-1 border border-lightgrey dark:border-darkgrey rounded flex items-center gap-2 cursor-pointer hover:bg-lightgrey dark:hover:bg-darkbg2'
                            >
                                <div
                                    className={`w-4 h-4 rounded border border-lightgrey dark:border-darkgrey ${
                                        associations.find(
                                            (association: any) =>
                                                association.resource_name === 'roadmap' &&
                                                association.resource_id === roadmap.id
                                        )
                                            ? 'bg-accent1 dark:bg-darkaccent1'
                                            : ''
                                    }`}
                                ></div>
                                <span>{roadmap.title}</span>
                            </div>
                        ))}
                    </>
                )}
            </div>
        </div>
    )
}
