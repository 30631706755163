import { FaInfo } from 'react-icons/fa'

export default function LabelWithInfo({
    label,
    htmlFor,
    info,
    labelClassName,
}: {
    label: string
    htmlFor: string
    info: string
    labelClassName?: string
}) {
    return (
        <div className='flex items-center gap-[4px]'>
            <label
                className={
                    labelClassName || 'leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                }
                htmlFor={htmlFor}
            >
                {label}
            </label>
            <div className='relative'>
                <FaInfo className='peer w-[12px] h-[12px] p-[2px] rounded-full bg-darkgrey dark:bg-offwhite text-white dark:text-darkaccent' />
                <div className='peer-hover:w-[200px] delay-100 w-0 overflow-hidden absolute top-[50%] translate-y-[-50%] left-[150%] z-[5] transition-all'>
                    <div
                        className={`w-[200px] p-[8px] dark:bg-darkness dark:text-offwhite bg-white rounded-[4px] shadow-small`}
                    >
                        {info}
                    </div>
                </div>
            </div>
        </div>
    )
}
