import PDPDoc from './PDPDoc'
import CollectionDoc from './CollectionDoc'
import HomeDoc from './HomeDoc'

export default function FPSiteDocs() {
    return (
        <div className='docs'>
            <h1>FactoryPure.com</h1>
            <div className='docs__fpsite__section'>
                <PDPDoc />
            </div>
            <div className='docs__fpsite__section'>
                <CollectionDoc />
            </div>
            <div className='docs__fpsite__section'>
                <HomeDoc />
            </div>
        </div>
    )
}
