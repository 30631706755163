import { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { cn } from '../../../../helpers'
import { useCreateNote } from '../../../api/useQueries'
import { CompanyContext, CompanyContextType, formatDateWithYear } from '../../../helpers'
import DueDateChanger from '../../DueDateChanger'
import { CompanyTaskType } from './CreateTask'

export default function CreateNoteV2({ setShowQuickModal }: { setShowQuickModal: any }) {
    const { editableCompany } = useContext<CompanyContextType>(CompanyContext)
    const user = useSelector<any, any>((state) => state.user)
    const [note, setNote] = useState('')
    const [createTask, setCreateTask] = useState(false)
    const [showDueAtEditor, setShowDueAtEditor] = useState(false)

    const [taskEditableFields, setTaskEditableFields] = useState<CompanyTaskType>({
        title: '',
        status: 'OPEN',
        due_at: new Date(Date.now() + 1000 * 60 * 60 * 24).toISOString(),
        alert_at: 'at_time',
        send_slack_alert: 1,
        send_email_alert: 0,
        company_id: editableCompany.id,
        user_id: user.id,
    })
    const [displayedDate, setDisplayedDate] = useState(taskEditableFields.due_at)

    const createNote = useCreateNote(setShowQuickModal)

    const handleCancelDateChange = () => {
        setTaskEditableFields((prev: any) => ({
            ...prev,
            due_at: undefined,
            alert_at: undefined,
            send_email_alert: undefined,
            send_slack_alert: undefined,
        }))
    }

    const handleSubmit = () => {
        createNote.mutate(
            { user, note, companyId: editableCompany.id, task: taskEditableFields, createTask },
            {
                onSettled: () => {
                    return setShowQuickModal(undefined)
                },
            }
        )
    }
    return (
        <>
            <div className={cn(createNote.isPending && 'opacity-50')}>
                <div className='p-[16px] w-full bg-lightgrey dark:bg-darkness'>
                    <h3 className='leading-[1] font-bold'>New Note</h3>
                </div>
                <textarea
                    className='w-full p-[16px] dark:bg-darkaccent border-none focus:outline-none h-[150px]'
                    placeholder='Type your note here...'
                    value={note}
                    onChange={({ target }) => setNote(target.value)}
                    disabled={createNote.isPending}
                ></textarea>
                <div className='p-[16px] w-full bg-lightgrey dark:bg-darkness flex flex-col gap-[8px]'>
                    <div className='flex gap-[8px]'>
                        <input
                            type='checkbox'
                            checked={createTask}
                            onChange={({ target }) => {
                                return setCreateTask(target.checked)
                            }}
                            disabled={createNote.isPending}
                        />
                        <p>Create a task from this note?</p>
                    </div>
                    {createTask && (
                        <div className='bg-white dark:bg-darkaccent p-[8px] rounded-[4px] shadow-md'>
                            <p>Due {formatDateWithYear(new Date(displayedDate ?? taskEditableFields.due_at))}</p>

                            <DueDateChanger
                                inNote={true}
                                setDisplayedDate={setDisplayedDate}
                                handleCancelDateChange={handleCancelDateChange}
                                setTask={setTaskEditableFields}
                                setShowDueAtEditor={setShowDueAtEditor}
                                disabled={createNote.isPending}
                            />
                        </div>
                    )}
                    <button
                        disabled={createNote.isPending ? true : false}
                        onClick={handleSubmit}
                        className='py-[4px] px-[8px] disabled:opacity-50 self-center rounded-[4px] text-[14px] bg-blue dark:bg-accent font-bold uppercase text-white dark:text-darkaccent'
                    >
                        {createNote.isPending ? 'Saving Note...' : 'Save Note'}
                    </button>
                </div>
            </div>
        </>
    )
}
