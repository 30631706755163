export const HTMLDateInputFormatter = (date: Date) => {
    const [month, day, year] = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'UTC',
    })
        .format(date)
        .split('/')
    return `${year}-${month}-${day}`
}
