export function getDelayInEnglish(delayInMs: number) {
    const weeks = Math.floor((delayInMs|| 0) / (7 * 24 * 60 * 60 * 1000))
    const days = Math.floor(((delayInMs || 0) % (7 * 24 * 60 * 60 * 1000)) / (24 * 60 * 60 * 1000))
    const hours = Math.floor(((delayInMs || 0) % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000))
    const minutes = Math.floor(((delayInMs || 0) % (60 * 60 * 1000)) / (60 * 1000))
    const delay = [
        weeks ? `${weeks} week${weeks > 1 ? 's': ''}` : null,
        days? `${days} day${days > 1 ? 's': ''}` : null,
        hours ? `${hours} hour${hours > 1 ? 's': ''}` : null,
        minutes? `${minutes} minute${minutes > 1 ? 's': ''}` : null
    ].filter( v=> v).join(", ")
    return delay
}