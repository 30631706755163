import { formatMoney, formatNumberInput, isPrimObject, sendToast, sortByAlphanumeric, vFetch } from '../../helpers'
import { ReturnFee, S3File, ReturnCreateInit, ReturnEditInit, DamageClaimCreateInit } from '../returns.types'
import { returnVariables } from '../returnsVariables'
import { ChangeEvent, useRef, useState } from 'react'
import { LineItemInit, OrderInit } from '../../orders/orders.types'
import { canSubmitReturn, getTaxTotal } from '../returns.helpers'
import { MdContentCopy } from 'react-icons/md'
import { AiFillInfoCircle } from 'react-icons/ai'
import { findDiscountApplications } from '../../orders/orders.helpers'
import { FaCaretLeft, FaCaretRight, FaRegEye, FaTimes, FaTrash } from 'react-icons/fa'

type ReturnInfoSectionProps = {
    buttonStyle: string
    inputStyle: string
    fieldContainerStyle: string
    labelStyle: string
    order: OrderInit | null
    r: ReturnCreateInit | DamageClaimCreateInit | ReturnEditInit
    setR: Function
    handleChangeReturn: Function
}

export default function ReturnInfoSection({
    buttonStyle,
    inputStyle,
    fieldContainerStyle,
    labelStyle,
    order,
    r,
    setR,
    handleChangeReturn,
}: ReturnInfoSectionProps) {
    const { destinations, reasons, resolutions, shippingTypes, statuses, refundTypes, skipItemChecklistReasons } =
        returnVariables
    const [showDamageClaimInfo, setShowDamageClaimInfo] = useState(false)
    const itemChecklistSkipped = skipItemChecklistReasons.includes(r.reason)
    const [downloading, setDownloading] = useState(false)
    function itemsTotal(item: LineItemInit) {
        return (
            (Number(item.price) -
                findDiscountApplications(order!, item).reduce(
                    (acc: number, cur: any) => acc + Number(cur.per_item_discount.replace('$', '')),
                    0
                ) +
                getTaxTotal(r.model.tax_lines) / r.model.quantity) *
            r.quantity
        )
    }

    // refund handlers
    function renderQuantities(item: any) {
        let q: number = 0
        const quantities: any[] = []
        while (q <= item.quantity) {
            quantities.push(<option value={q}>{q}</option>)
            q++
        }
        return quantities
    }
    function handleChangeRefundType(target: HTMLInputElement, pricePlusTax: number) {
        const newReturn = { ...r, type: target.value }
        // newReturn.refund = target.value === "full" ? Number(pricePlusTax.toFixed(2)) : 0;
        setR(newReturn)
    }
    function handleChangeRefundQuantity(target: HTMLInputElement, pricePlusTax: number) {
        const newReturn = { ...r, quantity: Number(target.value) }
        // newReturn.refund = Number((newReturn.quantity * pricePlusTax).toFixed(2));
        setR(newReturn)
    }
    function handleChangeRefundAmount(value: string) {
        const newReturn = { ...r, refund: Number(value) }
        if (newReturn.refund_type === 'full') newReturn.refund_type = 'partial'
        setR(newReturn)
    }

    // image handlers
    async function handleImage(e: ChangeEvent) {
        const tempFiles: any[] = []
        const uploadArray = (e.target as HTMLInputElement).files

        async function readFileAsDataURL(file: File) {
            return await new Promise((resolve) => {
                const fileReader = new FileReader()
                fileReader.onload = (e) => resolve(fileReader.result)
                fileReader.readAsDataURL(file)
            }).then((res) => res)
        }
        async function readFileAsUint8Array(file: File) {
            return await file.arrayBuffer().then((buff) => {
                return new Uint8Array(buff)
            })
        }
        async function prepFile(file: File) {
            return {
                name: file.name,
                type: file.type,
                base64: await readFileAsDataURL(file),
                data: file,
            }
        }
        if (uploadArray) {
            for (let i = 0; i < uploadArray?.length; i++) {
                const file = uploadArray.item(i)!
                tempFiles.push(prepFile(file))
            }
        }
        Promise.all(tempFiles).then((res) => {
            setR({ ...r, files: [...r.files, ...res] })
        })
    }
    const handleDrop = (e: any) => {
        e.preventDefault()
        const tempFiles: any[] = []
        const uploadArray = e.dataTransfer.files

        async function readFileAsDataURL(file: File) {
            return await new Promise((resolve) => {
                const fileReader = new FileReader()
                fileReader.onload = (e) => resolve(fileReader.result)
                fileReader.readAsDataURL(file)
            }).then((res) => res)
        }
        async function prepFile(file: File) {
            return {
                name: file.name,
                type: file.type,
                base64: await readFileAsDataURL(file),
                data: file,
            }
        }
        if (uploadArray) {
            for (let i = 0; i < uploadArray?.length; i++) {
                const file = uploadArray.item(i)!
                tempFiles.push(prepFile(file))
            }
        }
        Promise.all(tempFiles).then((res) => {
            setR({ ...r, files: [...r.files, ...res] })
        })
    }
    const handleRemoveFile = (index: number) => {
        setR({ ...r, files: r.files.filter((f: S3File, i: number) => i !== index) })
    }
    async function downloadFile(urls: string[], fileNames: string[], fileTypes: string[]) {
        if (urls.length !== fileNames.length)
            return sendToast({ message: 'Error: File URL array length does not match file names array length.' })
        if (urls.length !== fileTypes.length)
            return sendToast({ message: 'Error: File URL array length does not match file type array length.' })
        setDownloading(true)
        vFetch(`/v1/s3?urls=${urls}`, {
            cb: (res: any) => {
                if (res.success) {
                    res.files.forEach((f: any, i: number) => {
                        let rawData: any[] = []
                        f.forEach((arr: any) => (rawData = rawData.concat(arr.data)))
                        const file = new File(
                            [new Blob([new Uint8Array(rawData)], { type: fileTypes[i] })],
                            fileNames[i]
                        )
                        if ((window.navigator as any).msSaveOrOpenBlob)
                            (window.navigator as any).msSaveOrOpenBlob(file, file.name)
                        else {
                            var a = document.createElement('a'),
                                url = URL.createObjectURL(file)
                            a.href = url
                            a.download = file.name
                            document.body.appendChild(a)
                            a.click()
                            setTimeout(function () {
                                document.body.removeChild(a)
                                window.URL.revokeObjectURL(url)
                            }, 0)
                        }
                    })
                }
                setDownloading(false)
            },
        })
    }

    // mini components
    function isMissingValue(missingValuesObject: any, key: string) {
        const objectsArray: string[] = []
        Object.keys(missingValuesObject)
            .filter((k: any) => isPrimObject(missingValuesObject[k]))
            .map((v: any) =>
                Object.keys(missingValuesObject[v])
                    .filter((k) => !missingValuesObject[v][k])
                    .forEach((k) => objectsArray.push(k))
            )
        const missingValuesArray = objectsArray.concat(
            Object.keys(missingValuesObject).filter((k) => !missingValuesObject[k])
        )
        if (missingValuesArray.includes(key)) return ' *'
    }
    function itemChecklist(r: ReturnCreateInit | ReturnEditInit) {
        return (
            <div className='flex flex-col grow rounded gap-[8px]'>
                <h3 className='font-semibold text-[16px] w-[100%] border-b-[1px] border-black dark:border-grey'>
                    Item Checklist
                </h3>
                <div className='flex flex-col gap-[16px]'>
                    <div className='flex flex-col'>
                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                            Item is eligible to be returned
                            {isMissingValue(canSubmitReturn(r, itemChecklistSkipped), 'returnable')}
                        </label>
                        <div className='flex gap-[32px] justify-self-center'>
                            <div
                                className='flex gap-[8px] p-[8px]'
                                onClick={() =>
                                    setR({ ...r, item_checklist: { ...r.item_checklist, returnable: true } })
                                }
                            >
                                <label
                                    className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                                    htmlFor='returnable'
                                >
                                    Yes
                                </label>
                                <input
                                    className='p-[4px] focus:ring-blue focus:ring-2 focus:border-[10px] active:border-[10px] border-[green] rounded'
                                    type='radio'
                                    name='returnable'
                                    checked={r.item_checklist.returnable}
                                    onClick={() =>
                                        setR({ ...r, item_checklist: { ...r.item_checklist, returnable: true } })
                                    }
                                />
                            </div>
                            <div
                                className='flex gap-[8px] p-[8px]'
                                onClick={() =>
                                    setR({ ...r, item_checklist: { ...r.item_checklist, returnable: false } })
                                }
                            >
                                <label
                                    className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                                    htmlFor='returnable'
                                >
                                    No
                                </label>
                                <input
                                    className='p-[4px] focus:ring-blue focus:ring-2 focus:border-[10px] active:border-[10px] border-[green] rounded'
                                    type='radio'
                                    name='returnable'
                                    checked={!r.item_checklist.returnable}
                                    onClick={() =>
                                        setR({ ...r, item_checklist: { ...r.item_checklist, returnable: false } })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                            Delivered Within The Last 30 Days
                            {isMissingValue(canSubmitReturn(r, itemChecklistSkipped), 'recently_delivered')}
                        </label>
                        <div className='flex gap-[32px] justify-self-center'>
                            <div
                                className='flex gap-[8px] p-[8px]'
                                onClick={() =>
                                    setR({ ...r, item_checklist: { ...r.item_checklist, recently_delivered: true } })
                                }
                            >
                                <label
                                    className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                                    htmlFor='recently_delivered'
                                >
                                    Yes
                                </label>
                                <input
                                    className='p-[4px] focus:ring-blue focus:ring-2 focus:border-[10px] active:border-[10px] border-[green] rounded'
                                    type='radio'
                                    name='recently_delivered'
                                    checked={r.item_checklist.recently_delivered}
                                    onClick={() =>
                                        setR({
                                            ...r,
                                            item_checklist: { ...r.item_checklist, recently_delivered: true },
                                        })
                                    }
                                />
                            </div>
                            <div
                                className='flex gap-[8px] p-[8px]'
                                onClick={() =>
                                    setR({ ...r, item_checklist: { ...r.item_checklist, recently_delivered: false } })
                                }
                            >
                                <label
                                    className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                                    htmlFor='recently_delivered'
                                >
                                    No
                                </label>
                                <input
                                    className='p-[4px] focus:ring-blue focus:ring-2 focus:border-[10px] active:border-[10px] border-[green] rounded'
                                    type='radio'
                                    name='recently_delivered'
                                    checked={!r.item_checklist.recently_delivered}
                                    onClick={() =>
                                        setR({
                                            ...r,
                                            item_checklist: { ...r.item_checklist, recently_delivered: false },
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                            New Condition (unused)
                            {isMissingValue(canSubmitReturn(r, itemChecklistSkipped), 'new_condition')}
                        </label>
                        <div className='flex gap-[32px] justify-self-center'>
                            <div
                                className='flex gap-[8px] p-[8px]'
                                onClick={() =>
                                    setR({ ...r, item_checklist: { ...r.item_checklist, new_condition: true } })
                                }
                            >
                                <label
                                    className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                                    htmlFor='new_condition'
                                >
                                    Yes
                                </label>
                                <input
                                    className='p-[4px] focus:ring-blue focus:ring-2 focus:border-[10px] active:border-[10px] border-[green] rounded'
                                    type='radio'
                                    name='new_condition'
                                    checked={r.item_checklist.new_condition}
                                    onClick={() =>
                                        setR({ ...r, item_checklist: { ...r.item_checklist, new_condition: true } })
                                    }
                                />
                            </div>
                            <div
                                className='flex gap-[8px] p-[8px]'
                                onClick={() =>
                                    setR({ ...r, item_checklist: { ...r.item_checklist, new_condition: false } })
                                }
                            >
                                <label
                                    className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                                    htmlFor='new_condition'
                                >
                                    No
                                </label>
                                <input
                                    className='p-[4px] focus:ring-blue focus:ring-2 focus:border-[10px] active:border-[10px] border-[green] rounded'
                                    type='radio'
                                    name='new_condition'
                                    checked={!r.item_checklist.new_condition}
                                    onClick={() =>
                                        setR({ ...r, item_checklist: { ...r.item_checklist, new_condition: false } })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                            In Original Packaging
                            {isMissingValue(canSubmitReturn(r, itemChecklistSkipped), 'original_packaging')}
                        </label>
                        <div className='flex gap-[32px] justify-self-center'>
                            <div
                                className='flex gap-[8px] p-[8px]'
                                onClick={() =>
                                    setR({ ...r, item_checklist: { ...r.item_checklist, original_packaging: true } })
                                }
                            >
                                <label
                                    className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                                    htmlFor='original_packaging'
                                >
                                    Yes
                                </label>
                                <input
                                    className='p-[4px] focus:ring-blue focus:ring-2 focus:border-[10px] active:border-[10px] border-[green] rounded'
                                    type='radio'
                                    name='original_packaging'
                                    checked={r.item_checklist.original_packaging}
                                    onClick={() =>
                                        setR({
                                            ...r,
                                            item_checklist: { ...r.item_checklist, original_packaging: true },
                                        })
                                    }
                                />
                            </div>
                            <div
                                className='flex gap-[8px] p-[8px]'
                                onClick={() =>
                                    setR({ ...r, item_checklist: { ...r.item_checklist, original_packaging: false } })
                                }
                            >
                                <label
                                    className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                                    htmlFor='original_packaging'
                                >
                                    No
                                </label>
                                <input
                                    className='p-[4px] focus:ring-blue focus:ring-2 focus:border-[10px] active:border-[10px] border-[green] rounded'
                                    type='radio'
                                    name='original_packaging'
                                    checked={!r.item_checklist.original_packaging}
                                    onClick={() =>
                                        setR({
                                            ...r,
                                            item_checklist: { ...r.item_checklist, original_packaging: false },
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    function damageChecklist(r: DamageClaimCreateInit) {
        function damageClaimInfo() {
            return (
                <div className='absolute top-[100%] left-0 flex flex-col gap-[8px] bg-white dark:bg-darkness border-[1px] border-grey p-[16px]'>
                    <p className='whitespace-nowrap'>
                        <strong>Once a claim is filed, the following cannot be edited:</strong>
                    </p>
                    <p>Additional damage cannot be notated.</p>
                    <p>The damage checklist cannot be modified.</p>
                </div>
            )
        }

        return (
            <div className='flex flex-col grow rounded gap-[8px]'>
                <div className='flex gap-[8px] items-center w-[100%] border-b-[1px] border-black dark:border-grey'>
                    <h3 className='font-semibold text-[16px]'>Damage Checklist</h3>
                    <div
                        className='relative'
                        onMouseOver={() => setShowDamageClaimInfo(true)}
                        onMouseLeave={() => setShowDamageClaimInfo(false)}
                    >
                        <AiFillInfoCircle className='fill-grey dark:fill-white cursor-help' />
                        {showDamageClaimInfo && damageClaimInfo()}
                    </div>
                </div>
                <div className='flex flex-col gap-[16px]'>
                    <div className='flex flex-col'>
                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                            Customer Inspected The Item
                            {isMissingValue(canSubmitReturn(r, itemChecklistSkipped), 'customer_inspected')}
                        </label>
                        <div className='flex gap-[32px] justify-self-center'>
                            <div
                                className='flex gap-[8px] p-[8px]'
                                onClick={() =>
                                    setR({
                                        ...r,
                                        damage_checklist: { ...r.damage_checklist, customer_inspected: true },
                                    })
                                }
                            >
                                <label
                                    className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                                    htmlFor='customer_inspected'
                                >
                                    Yes
                                </label>
                                <input
                                    className='p-[4px] focus:ring-blue focus:ring-2 focus:border-[10px] active:border-[10px] border-[green] rounded'
                                    type='radio'
                                    name='customer_inspected'
                                    checked={r.damage_checklist.customer_inspected}
                                    onClick={() =>
                                        setR({
                                            ...r,
                                            damage_checklist: { ...r.damage_checklist, customer_inspected: true },
                                        })
                                    }
                                />
                            </div>
                            <div
                                className='flex gap-[8px] p-[8px]'
                                onClick={() =>
                                    setR({
                                        ...r,
                                        damage_checklist: { ...r.damage_checklist, customer_inspected: false },
                                    })
                                }
                            >
                                <label
                                    className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                                    htmlFor='customer_inspected'
                                >
                                    No
                                </label>
                                <input
                                    className='p-[4px] focus:ring-blue focus:ring-2 focus:border-[10px] active:border-[10px] border-[green] rounded'
                                    type='radio'
                                    name='customer_inspected'
                                    checked={!r.damage_checklist.customer_inspected}
                                    onClick={() =>
                                        setR({
                                            ...r,
                                            damage_checklist: { ...r.damage_checklist, customer_inspected: false },
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                            Customer Refused Delivery
                            {isMissingValue(canSubmitReturn(r, itemChecklistSkipped), 'refused_shipment')}
                        </label>
                        <div className='flex gap-[32px] justify-self-center'>
                            <div
                                className='flex gap-[8px] p-[8px]'
                                onClick={() =>
                                    setR({ ...r, damage_checklist: { ...r.damage_checklist, refused_shipment: true } })
                                }
                            >
                                <label
                                    className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                                    htmlFor='refused_shipment'
                                >
                                    Yes
                                </label>
                                <input
                                    className='p-[4px] focus:ring-blue focus:ring-2 focus:border-[10px] active:border-[10px] border-[green] rounded'
                                    type='radio'
                                    name='refused_shipment'
                                    checked={r.damage_checklist.refused_shipment}
                                    onClick={() =>
                                        setR({
                                            ...r,
                                            damage_checklist: { ...r.damage_checklist, refused_shipment: true },
                                        })
                                    }
                                />
                            </div>
                            <div
                                className='flex gap-[8px] p-[8px]'
                                onClick={() =>
                                    setR({ ...r, damage_checklist: { ...r.damage_checklist, refused_shipment: false } })
                                }
                            >
                                <label
                                    className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                                    htmlFor='refused_shipment'
                                >
                                    No
                                </label>
                                <input
                                    className='p-[4px] focus:ring-blue focus:ring-2 focus:border-[10px] active:border-[10px] border-[green] rounded'
                                    type='radio'
                                    name='refused_shipment'
                                    checked={!r.damage_checklist.refused_shipment}
                                    onClick={() =>
                                        setR({
                                            ...r,
                                            damage_checklist: { ...r.damage_checklist, refused_shipment: false },
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col'>
                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                            Customer Notated Damage
                            {isMissingValue(canSubmitReturn(r, itemChecklistSkipped), 'notated_damage')}
                        </label>
                        <div className='flex gap-[32px] justify-self-center'>
                            <div
                                className='flex gap-[8px] p-[8px]'
                                onClick={() =>
                                    setR({ ...r, damage_checklist: { ...r.damage_checklist, notated_damage: true } })
                                }
                            >
                                <label
                                    className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                                    htmlFor='notated_damage'
                                >
                                    Yes
                                </label>
                                <input
                                    className='p-[4px] focus:ring-blue focus:ring-2 focus:border-[10px] active:border-[10px] border-[green] rounded'
                                    type='radio'
                                    name='notated_damage'
                                    checked={r.damage_checklist.notated_damage}
                                    onClick={() =>
                                        setR({
                                            ...r,
                                            damage_checklist: { ...r.damage_checklist, notated_damage: true },
                                        })
                                    }
                                />
                            </div>
                            <div
                                className='flex gap-[8px] p-[8px]'
                                onClick={() =>
                                    setR({ ...r, damage_checklist: { ...r.damage_checklist, notated_damage: false } })
                                }
                            >
                                <label
                                    className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                                    htmlFor='notated_damage'
                                >
                                    No
                                </label>
                                <input
                                    className='p-[4px] focus:ring-blue focus:ring-2 focus:border-[10px] active:border-[10px] border-[green] rounded'
                                    type='radio'
                                    name='notated_damage'
                                    checked={!r.damage_checklist.notated_damage}
                                    onClick={() =>
                                        setR({
                                            ...r,
                                            damage_checklist: { ...r.damage_checklist, notated_damage: false },
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    function trackingInformation() {
        function changeTrackingNumber(target: any, trackingNumber: string) {
            if (!target.classList.contains('js-returns-ignore-select'))
                setR({
                    ...r,
                    original_tracking_number: r.original_tracking_number === trackingNumber ? '' : trackingNumber,
                })
        }

        const fulfillmentsWithSelectedItem =
            order?.fulfillments.filter(
                (f) => f.tracking_number && f.line_items.find((item: LineItemInit) => item.sku === r.model.sku)
            ) || []
        const trackingNumbers = fulfillmentsWithSelectedItem.map((f) => f.tracking_number)

        if (order && r.model.sku)
            return (
                <div className='flex flex-col gap-[4px]'>
                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                        Original Tracking Number{isMissingValue(r, 'original_tracking_number')}
                    </label>
                    {!trackingNumbers.includes(r.original_tracking_number) && (
                        <input
                            className={inputStyle}
                            value={r.original_tracking_number || ''}
                            onChange={({ target }) => setR({ ...r, original_tracking_number: target.value })}
                        />
                    )}
                    {order.fulfillments
                        .filter(
                            (f) =>
                                f.tracking_number && f.line_items.find((item: LineItemInit) => item.sku === r.model.sku)
                        )
                        .map((f) => {
                            return (
                                f.tracking_url && (
                                    <div
                                        className='flex gap-[8px] items-center p-[8px] hover:bg-offwhite dark:hover:bg-darkaccent rounded'
                                        onClick={({ target }) => changeTrackingNumber(target, f.tracking_number)}
                                    >
                                        <input
                                            className='p-[4px] focus:ring-blue focus:ring-2 focus:border-[10px] active:border-[10px] border-[green] rounded'
                                            type='checkbox'
                                            name='customer_inspected'
                                            checked={r.original_tracking_number === f.tracking_number}
                                            onChange={({ target }) => changeTrackingNumber(target, f.tracking_number)}
                                        />
                                        <span
                                            className='js-returns-ignore-select hover:underline cursor-pointer font-semibold text-[16px] text-blue dark:text-accent'
                                            onClick={() => {
                                                if (
                                                    !f.tracking_url.includes('ups.com') &&
                                                    !f.tracking_url.includes(f.tracking_number)
                                                ) {
                                                    navigator.clipboard.writeText(f.tracking_number)
                                                    setTimeout(() => {
                                                        alert(
                                                            'Tracking number copied: ' +
                                                                f.tracking_number +
                                                                '\n\nYou can right-click and paste the tracking number into the tracking website.\n\nClick OK to continue to the tracking website.'
                                                        )
                                                        window.open(f.tracking_url, '_blank')
                                                    }, 1)
                                                } else {
                                                    window.open(f.tracking_url, '_blank')
                                                }
                                            }}
                                        >
                                            {f.tracking_company === 'Other' &&
                                            f.tracking_url.match(/www.([a-zA-Z0-9]+)./)
                                                ? f.tracking_url
                                                      .match(/www.([a-zA-Z0-9]+)./)![1]
                                                      .slice(0, 1)
                                                      .toUpperCase() +
                                                  f.tracking_url.match(/www.([a-zA-Z0-9]+)./)![1].slice(1)
                                                : f.tracking_url.match(/([a-zA-Z0-9]+).com\//)
                                                  ? f.tracking_url.match(/([a-zA-Z0-9]+).com\//)![1].toUpperCase()
                                                  : f.tracking_company}{' '}
                                            - {f.tracking_number}
                                        </span>
                                        <MdContentCopy
                                            className='js-returns-ignore-select cursor-pointer'
                                            onClick={() => {
                                                navigator.clipboard.writeText(f.tracking_number)
                                                setTimeout(() => {
                                                    window.alert('Tracking number copied!')
                                                }, 1)
                                            }}
                                        />
                                    </div>
                                )
                            )
                        })}
                </div>
            )
    }
    function ImageManager() {
        const modalRef = useRef<HTMLDivElement>(null)
        const [selectedImageIndex, setSelectedImageIndex] = useState(-1)
        const selectedImage = r.files[selectedImageIndex]

        const handleDecrement = () => {
            setSelectedImageIndex((prev) => {
                if (prev === 0) {
                    return r.files.length - 1
                }
                return prev - 1
            })
        }

        const handleIncrement = () => {
            setSelectedImageIndex((prev) => {
                if (prev === r.files.length - 1) {
                    return 0
                }
                return prev + 1
            })
        }

        return (
            <>
                {selectedImage && (
                    <div
                        ref={modalRef}
                        className='fixed top-0 left-0 w-full h-full z-50 bg-black/50 flex items-center justify-center'
                    >
                        <div className='p-6 relative w-5/6 h-5/6 bg-bg1 dark:bg-darkbg1 rounded shadow-md flex flex-col gap-2'>
                            <button
                                onClick={() => setSelectedImageIndex(-1)}
                                className='absolute top-1 right-1 text-red dark:text-lightred'
                            >
                                <FaTimes />
                            </button>
                            <img
                                src={selectedImage?.location}
                                className='w-full h-[calc(100%-72px)] object-contain block'
                            />
                            <div className='flex gap-2 justify-center flex-wrap shrink-0'>
                                {r.files.map(({ location }: any, index) => (
                                    <button onClick={() => setSelectedImageIndex(index)}>
                                        <img
                                            src={location}
                                            className={`w-16 h-16 object-contain border-2 rounded ${
                                                selectedImageIndex === index
                                                    ? 'border-fire dark:border-fire'
                                                    : 'border-lightgrey dark:border-darkgrey'
                                            }`}
                                        />
                                    </button>
                                ))}
                            </div>
                            <button
                                onClick={handleDecrement}
                                className='text-xl p-2 rounded-full bg-accent1 dark:bg-darkaccent2 text-white absolute top-1/2 translate-y-[-50%] left-4 z-50'
                            >
                                <FaCaretLeft className='ml-[-2px] mr-[2px]' />
                            </button>
                            <button
                                onClick={handleIncrement}
                                className='text-xl p-2 rounded-full bg-accent1 dark:bg-darkaccent2 text-white absolute top-1/2 translate-y-[-50%] right-4 z-50'
                            >
                                <FaCaretRight className='mr-[-2px] ml-[2px]' />
                            </button>
                        </div>
                    </div>
                )}
                <div className='grid gap-[8px]'>
                    <label
                        htmlFor='file-upload'
                        className='relative flex flex-col w-[100%] p-[16px] gap-[16px] border-[1px] text-[#4a4a4a] border-[#cdcdcd] dark:bg-darkness dark:text-offwhite dark:border-blue rounded appearance-none cursor-pointer'
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={handleDrop}
                    >
                        <label className='absolute flex items-center justify-between font-extrabold text-[12px] top-0 left-0 uppercase bg-[rgba(0,60,90,0.5)] rounded-tl text-white dark:text-offwhite py-[2px] px-[4px] mb-[8px] width-fit cursor-pointer'>
                            Manage Images
                        </label>
                        <div className='grid justify-center'>
                            <div className='mx-auto'>
                                <span className='mx-auto py-[4px] px-[8px] bg-blue text-white dark:bg-fire dark:text-black font-bold rounded leading-[1]'>
                                    Browse
                                </span>
                            </div>
                            <span>or drag files here</span>
                        </div>
                        <div className='flex gap-[64px] justify-center'>
                            {(r.shipping_type === 'freight' || r.shipping_type === 'other / unspecified') && (
                                <div className='flex justify-center basis-[50%]'>
                                    <div>
                                        <h3>For Freight, Must Include (8 total):</h3>
                                        <ul className='list-disc pl-[16px]'>
                                            <li>
                                                1 picture for each side of the box
                                                <br />
                                                (4 Total)
                                            </li>
                                            <li>
                                                1 for each side of the item
                                                <br />
                                                (4 Total)
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                            {(r.shipping_type === 'UPS' || r.shipping_type === 'other / unspecified') && (
                                <div className='grid basis-[50%] justify-center'>
                                    <h3>For UPS Shipments, Must Include (7 total):</h3>
                                    <ul className='list-disc pl-[16px]'>
                                        <li>
                                            1 picture showing the damaged item and how it was packaged inside the box
                                        </li>
                                        <li>1 picture of the damaged item</li>
                                        <li>1 picture of the packaging material used</li>
                                        <li>
                                            1 close-up picture of the shipping label with tracking number
                                            <br />
                                            (generally starts with 1Z)
                                        </li>
                                        <li>
                                            1 close-up picture of the box manufacturer's certificate
                                            <br />
                                            (round stamp on the outside of the box)
                                        </li>
                                        <li>
                                            2 photos showing all six sides of the package
                                            <br />
                                            (1 showing top and two sides, 1 showing bottom and opposite sides)
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                        <input
                            id='file-upload'
                            className='hidden'
                            accept='image/*'
                            type='file'
                            onChange={handleImage}
                            multiple
                        />
                    </label>
                    <div className='flex gap-[16px] flex-wrap justify-center'>
                        {r.files.map((f, i: number) => (
                            <div className='flex flex-col justify-center relative !rounded border-[1px] border-[#cdcdcd] dark:border-blue items-center max-w-[200px] max-h-[160px] place-items-center'>
                                <div className='relative flex flex-col justify-center items-center w-[180px] h-[120px]'>
                                    <img
                                        className='max-w-[180px] max-h-[120px] object-contain'
                                        src={f.location || f.base64 || (f.data as string)}
                                    />
                                    <div className='absolute top-0 flex grow w-full h-[24px]'>
                                        {f.location ? (
                                            <button
                                                className={
                                                    buttonStyle +
                                                    ' grow dark:!bg-fire !rounded-b-none !rounded-r-none text-center py-[0] opacity-30 hover:opacity-100 duration-300'
                                                }
                                                onClick={() => downloadFile([f.location!], [f.name], [f.type])}
                                            >
                                                Download
                                            </button>
                                        ) : (
                                            <div className='w-full' />
                                        )}
                                        <button
                                            className='bg-fire text-white p-1'
                                            onClick={() => setSelectedImageIndex(i)}
                                        >
                                            <FaRegEye />
                                        </button>
                                    </div>
                                    <button
                                        className={
                                            'absolute bottom-1 right-1 text-sm text-red dark:text-lightred z-[2]'
                                        }
                                        onClick={() => handleRemoveFile(i)}
                                    >
                                        <FaTrash />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                    {r.files.length > 0 && (
                        <button
                            className='bg-accent1 dark:bg-darkaccent1 text-bg1 dark:text-darkbg1 rounded py-2 px-4 font-bold leading-none w-fit mx-auto'
                            onClick={() =>
                                downloadFile(
                                    r.files.map((file) => file.location!),
                                    r.files.map((file) => file.name),
                                    r.files.map((file) => file.type)
                                )
                            }
                        >
                            {!downloading ? 'Download All' : 'Downloading, this may take some time...'}
                        </button>
                    )}
                </div>
            </>
        )
    }
    function feeField(name: string) {
        const fee = r.fees.find((fee: ReturnFee) => fee.name === name)
        const tabIndex = fee ? 0 : -1
        function handleFeeAdditionOrRemoval({ target }: any) {
            if (Number(target.value))
                setR({
                    ...r,
                    fees: [...r.fees, { name, type: name === 'shipping' ? '$' : '%', amount: 0 }].sort((a, b) =>
                        sortByAlphanumeric(a.name, b.name)
                    ),
                })
            else if (fee) setR({ ...r, fees: r.fees.filter((f) => f.name !== fee.name) })
        }
        function handleChangeFeeType(type: string) {
            setR({
                ...r,
                fees: [...r.fees.filter((f) => f.name !== fee!.name), { ...fee, type, amount: 0 }].sort((a, b) =>
                    sortByAlphanumeric(a.name, b.name)
                ),
            })
        }
        function handleChangeFeeAmount(input: string | number) {
            setR({
                ...r,
                fees: [
                    ...r.fees.filter((f) => f.name !== fee!.name),
                    { ...fee, amount: formatNumberInput(input.toString()) },
                ].sort((a, b) => sortByAlphanumeric(a.name, b.name)),
            })
        }
        function handleChangeFeeNameAlt(input: string) {
            setR({
                ...r,
                fees: [...r.fees.filter((f) => f.name !== fee!.name), { ...fee, name_alt: input }].sort((a, b) =>
                    sortByAlphanumeric(a.name, b.name)
                ),
            })
        }
        return (
            <div className={fieldContainerStyle}>
                <div className='flex justify-between'>
                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                        {name} fee{isMissingValue(canSubmitReturn(r, itemChecklistSkipped), 'shipping_type')}
                    </label>
                    {/* This can be added back when discounts are properly calculated */}
                    {fee && (
                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                            {fee.name_alt || fee.name} Fee
                        </label>
                    )}
                </div>
                <div className='flex gap-[8px]'>
                    <select
                        className={inputStyle + ' capitalize w-[70px]'}
                        onChange={handleFeeAdditionOrRemoval}
                        value={fee?.name ? 1 : 0}
                    >
                        <option className='hidden' value=''></option>
                        <option className='capitalize' value={1}>
                            Yes
                        </option>
                        <option className='capitalize' value={0}>
                            No
                        </option>
                    </select>
                    {name === 'other' && fee && (
                        <input
                            className={inputStyle}
                            placeholder='Fee name'
                            value={fee.name_alt}
                            onChange={({ target }) => handleChangeFeeNameAlt(target.value)}
                        />
                    )}
                    <div
                        className={`flex gap-[8px] grow ${
                            fee ? 'opacity-100' : 'opacity-50 pointer-events-none tab-index'
                        }`}
                    >
                        <div
                            className={`${
                                name === 'shipping' ? 'mr-[40px]' : ''
                            } flex border-[1px] border-darkgrey rounded`}
                        >
                            <button
                                tabIndex={tabIndex}
                                className={`${
                                    name === 'shipping' ? 'rounded border-none' : ''
                                } w-[40px] text-center border-r-[1px] border-darkgrey ${
                                    fee?.type === '$'
                                        ? 'bg-blue text-white dark:bg-accent dark:text-black'
                                        : 'dark:text-offwhite'
                                } rounded-l`}
                                onClick={() => {
                                    if (fee!.type !== '$') handleChangeFeeType('$')
                                }}
                            >
                                $
                            </button>
                            <button
                                tabIndex={tabIndex}
                                className={`${name === 'shipping' ? 'hidden' : ''} w-[40px] text-center ${
                                    fee?.type === '%'
                                        ? 'bg-blue text-white dark:bg-accent dark:text-black'
                                        : 'dark:text-offwhite'
                                } rounded-r`}
                                onClick={() => {
                                    if (fee!.type !== '%') handleChangeFeeType('%')
                                }}
                            >
                                %
                            </button>
                        </div>
                        <input
                            tabIndex={tabIndex}
                            className={inputStyle + ' w-[100px]'}
                            onChange={({ target }) => handleChangeFeeAmount(target.value)}
                            value={fee?.amount ?? 0}
                            onBlur={() => handleChangeFeeAmount(Number(fee?.amount || 0))}
                        ></input>
                        {/* This is currently incorrect because it does not properly account for discounts. */}
                        {fee && (
                            <div className='grow text-right flex gap-[8px] justify-end items-center'>
                                {r.quantity
                                    ? formatMoney(
                                          fee.type === '$' ? fee.amount : (itemsTotal(r.model) * fee.amount) / 100
                                      )
                                    : `Please select a quantity to ${r.type}.`}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
    function refundBuilder() {
        return (
            <div className='flex flex-col gap-[8px]'>
                <h3 className='font-semibold text-[16px] w-[100%] border-b-[1px] border-black dark:border-grey'>
                    Refund
                </h3>
                <div className='flex flex-col gap-[4px]'>
                    <div className='flex gap-[16px]'>
                        <div className='flex flex-col gap-[4px] grow max-w-[306px]'>
                            <label className={labelStyle}>Type</label>
                            <select
                                className={inputStyle + ' capitalize'}
                                value={r.refund_type}
                                onChange={({ target }) => setR({ ...r, refund_type: target.value, fees: [] })}
                            >
                                <option className='hidden' value=''></option>
                                {refundTypes.map((d: string) => (
                                    <option className='capitalize' value={d}>
                                        {d}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                {['partial'].includes(r.refund_type) && [
                    feeField('shipping'),
                    feeField('restocking'),
                    feeField('chargeback'),
                    feeField('administrative'),
                    feeField('other'),
                ]}
                <div className='flex justify-end'>
                    <div className='flex flex-col gap-[4px]'>
                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1] text-right'>
                            Total Fees
                        </label>
                        <span className='text-right'>
                            {r.quantity
                                ? formatMoney(
                                      r.fees.reduce(
                                          (acc, cur) =>
                                              acc +
                                              (cur.type === '$'
                                                  ? Number(cur.amount)
                                                  : (cur.amount / 100) * itemsTotal(r.model)),
                                          0
                                      )
                                  )
                                : `Please select a quantity to ${r.type}.`}
                        </span>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className='flex flex-col grow dark:bg-darkness rounded p-[16px] shadow-small gap-[16px]'>
            <h3 className='font-semibold text-[16px] w-[100%] border-b-[1px] border-black dark:border-grey capitalize'>
                {r.type} Info
            </h3>
            {r.type === 'return' ? (
                <div className={fieldContainerStyle}>
                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                        Reason{isMissingValue(canSubmitReturn(r, itemChecklistSkipped), 'reason')}
                    </label>
                    <select
                        className={inputStyle + ' capitalize'}
                        value={r.reason}
                        onChange={({ target }) => {
                            setR({ ...r, reason: target.value, files: [] })
                        }}
                    >
                        <option className='hidden' value=''></option>
                        {reasons.map((r: string) => (
                            <option className='capitalize' value={r}>
                                {r}
                            </option>
                        ))}
                    </select>
                </div>
            ) : (
                <div className={fieldContainerStyle}>
                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                        Reason{isMissingValue(canSubmitReturn(r, itemChecklistSkipped), 'reason')}
                    </label>
                    <input className={inputStyle + ' capitalize'} value={r.reason} disabled />
                </div>
            )}
            {!skipItemChecklistReasons.includes(r.reason) && itemChecklist(r as ReturnCreateInit)}
            {r.type === 'damage' && damageChecklist(r as DamageClaimCreateInit)}
            <div className='flex gap-[16px]'>
                <div className={fieldContainerStyle}>
                    <label className='grow w-[306px] dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                        Status
                    </label>
                    <select
                        className={inputStyle + ' capitalize'}
                        value={r.status}
                        onChange={({ target }) => handleChangeReturn('status', target.value)}
                    >
                        <option className='hidden' value=''></option>
                        {statuses
                            .filter((v) => v !== 'closed')
                            .map((s: string) => (
                                <option className='capitalize' value={s}>
                                    {s}
                                </option>
                            ))}
                        <option className='hidden' value='closed'>
                            closed
                        </option>
                    </select>
                </div>
                <div className={fieldContainerStyle}>
                    <label className={labelStyle}>
                        Quantity{isMissingValue(canSubmitReturn(r, itemChecklistSkipped), 'quantity')}
                    </label>
                    <div className='grow'>
                        <select
                            className={inputStyle + ' w-[100%]'}
                            value={r.quantity}
                            onChange={({ target }: any) => {
                                handleChangeRefundQuantity(
                                    target,
                                    Number(r.model.pre_tax_price) +
                                        (Number(order?.total_price) / Number(order?.total_price! - order?.total_tax!) -
                                            1) *
                                            Number(r.model.pre_tax_price)
                                )
                            }}
                        >
                            {renderQuantities(r.model)}
                        </select>
                    </div>
                </div>
                <div className={'flex flex-col gap-[4px] grow'}>
                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                        Resolution{isMissingValue(canSubmitReturn(r, itemChecklistSkipped), 'resolution')}
                    </label>
                    <select
                        className={inputStyle + ' capitalize w-[100%]'}
                        onChange={({ target }) => handleChangeReturn('resolution', target.value)}
                        value={r.resolution}
                    >
                        <option className='hidden' value={''} />
                        {resolutions.map((o) => (
                            <option className='capitalize' value={o}>
                                {o}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {trackingInformation()}
            <div className={'flex flex-col gap-[4px] grow max-w-[306px]'}>
                <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                    Original Shipping Paid By
                    {isMissingValue(canSubmitReturn(r, itemChecklistSkipped), 'original_shipping_paid_by')}
                </label>
                <select
                    className={inputStyle + ' capitalize w-[100%]'}
                    onChange={({ target }) => handleChangeReturn('original_shipping_paid_by', target.value)}
                    value={r.original_shipping_paid_by}
                >
                    <option className='hidden' value={''} />
                    {['FactoryPure', 'Manufacturer'].map((o) => (
                        <option className='capitalize' value={o}>
                            {o}
                        </option>
                    ))}
                </select>
            </div>
            <div className='flex gap-[16px]'>
                <div className={'flex flex-col gap-[4px] grow max-w-[306px]'}>
                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                        Original Shipping
                        {isMissingValue(canSubmitReturn(r, itemChecklistSkipped), 'original_shipping_type')}
                    </label>
                    <select
                        className={inputStyle + ' capitalize w-[100%]'}
                        onChange={({ target }) => handleChangeReturn('original_shipping_type', target.value)}
                        value={r.original_shipping_type}
                    >
                        <option className='hidden' value={''} />
                        {shippingTypes
                            .filter((v) => v !== 'N/A')
                            .map((o) => (
                                <option className='capitalize' value={o}>
                                    {o}
                                </option>
                            ))}
                    </select>
                </div>
                <div className='flex flex-col gap-[4px] grow'>
                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                        Origin{isMissingValue(canSubmitReturn(r, itemChecklistSkipped), 'origin')}
                    </label>
                    <select
                        className={inputStyle + ' capitalize'}
                        value={r.origin || ''}
                        onChange={({ target }) =>
                            target.value === 'FactoryPure'
                                ? setR({
                                      ...r,
                                      origin: target.value,
                                      refund_from_manufacturer_expected: 0,
                                      rma_number: null,
                                  })
                                : handleChangeReturn('origin', target.value)
                        }
                    >
                        <option className='hidden' value=''></option>
                        {destinations.map((d: string) => (
                            <option className='capitalize' value={d}>
                                {d}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className='flex gap-[16px]'>
                <div className={'flex flex-col gap-[4px] grow max-w-[306px]'}>
                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                        Return Shipping{isMissingValue(canSubmitReturn(r, itemChecklistSkipped), 'shipping_type')}
                    </label>
                    <select
                        className={inputStyle + ' capitalize w-[100%]'}
                        onChange={({ target }) => handleChangeReturn('shipping_type', target.value)}
                        value={r.shipping_type}
                    >
                        <option className='hidden' value=''></option>
                        {shippingTypes.map((o) => (
                            <option className='capitalize' value={o}>
                                {o}
                            </option>
                        ))}
                    </select>
                </div>
                {r.shipping_type !== 'N/A' && (
                    <div className='flex flex-col gap-[4px] grow'>
                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                            Destination{isMissingValue(canSubmitReturn(r, itemChecklistSkipped), 'destination')}
                        </label>
                        <select
                            className={inputStyle + ' capitalize'}
                            value={r.destination || ''}
                            onChange={({ target }) =>
                                target.value === 'FactoryPure'
                                    ? setR({
                                          ...r,
                                          destination: target.value,
                                          refund_from_manufacturer_expected: 0,
                                          rma_number: null,
                                      })
                                    : handleChangeReturn('destination', target.value)
                            }
                        >
                            <option className='hidden' value=''></option>
                            {destinations.map((d: string) => (
                                <option className='capitalize' value={d}>
                                    {d}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
            </div>
            {r.shipping_type !== 'N/A' && (
                <div className='flex flex-col gap-[4px] grow'>
                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                        Return Tracking Number{isMissingValue(canSubmitReturn(r), 'tracking_number')}
                    </label>
                    <input
                        className={inputStyle}
                        value={r.tracking_number || ''}
                        onChange={({ target }) => handleChangeReturn('tracking_number', target.value)}
                    />
                </div>
            )}
            {r.destination === 'Manufacturer' && r.shipping_type !== 'N/A' && (
                <div className='flex flex-col gap-[4px] grow'>
                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                        RMA Number{isMissingValue(canSubmitReturn(r, itemChecklistSkipped), 'rma_number')}
                    </label>
                    <input
                        className={inputStyle}
                        value={r.rma_number || ''}
                        onChange={({ target }) => handleChangeReturn('rma_number', target.value)}
                    />
                </div>
            )}
            {r.destination === 'Manufacturer' && (
                <div className='flex gap-[16px]'>
                    <div className={fieldContainerStyle + ' basis-[50%]'}>
                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                            Expected Refund From Manufacturer
                            {isMissingValue(
                                canSubmitReturn(r, itemChecklistSkipped),
                                'refund_from_manufacturer_expected'
                            )}
                        </label>
                        <input
                            className={inputStyle}
                            value={r.refund_from_manufacturer_expected}
                            onChange={({ target }) =>
                                handleChangeReturn('refund_from_manufacturer_expected', formatNumberInput(target.value))
                            }
                        />
                    </div>
                    <div className={fieldContainerStyle + ' basis-[50%]'}>
                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                            Received Refund From Manufacturer
                            {isMissingValue(
                                canSubmitReturn(r, itemChecklistSkipped),
                                'refund_from_manufacturer_actual'
                            )}
                        </label>
                        <input
                            className={inputStyle}
                            value={r.refund_from_manufacturer_actual}
                            onChange={({ target }) =>
                                handleChangeReturn('refund_from_manufacturer_actual', formatNumberInput(target.value))
                            }
                        />
                    </div>
                </div>
            )}
            <ImageManager />
            {refundBuilder()}
            {['ready for processing', 'closed'].includes(r.status) && (
                <div className='flex flex-col gap-[4px] grow hidden'>
                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                        Total Refund To Customer
                        {isMissingValue(canSubmitReturn(r, itemChecklistSkipped), 'refund_to_customer')}
                    </label>
                    <input
                        className={inputStyle}
                        value={r.refund_to_customer || 0}
                        onChange={({ target }) =>
                            handleChangeReturn('refund_to_customer', formatNumberInput(target.value))
                        }
                    />
                </div>
            )}
            <div className='flex flex-col gap-[4px] w-full h-full'>
                <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>Notes</label>
                <textarea
                    className={inputStyle + ' h-[200px] mx-0 max-w-none'}
                    value={r.notes || ''}
                    onChange={({ target }) => handleChangeReturn('notes', target.value)}
                />
            </div>
        </div>
    )
}
