import { PlusCircledIcon } from '@radix-ui/react-icons'
import React, { useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import { FaArrowDown, FaArrowUp } from 'react-icons/fa6'
import { toast } from 'react-toastify'
import { cn, sendToast } from '../../helpers'
import {
    useCreateScoreSetupGroup,
    useCreateScoreSetupOption,
    useDeleteScoreSetupGroup,
    useGetScoreSetupGroups,
    useUpdateScoreSetupGroup,
} from '../api/useQueries'
import Input from './Input'
import ScoreOption from './ScoreOption'

export default function ScoreGroup({
    editablePositionMap,
    setEditablePositionMap,
    isNew,
    group,
    index,
    options,
    setShowNewGroup,
}: {
    editablePositionMap?: any[]
    setEditablePositionMap?: any
    isNew: boolean
    group: any
    index: number
    options: any
    setShowNewGroup?: any
}) {
    const [showDelete, setShowDelete] = useState(false)
    const [showNew, setShowNew] = useState(false)
    const [editableFields, setEditableFields] = useState<any>({})

    const isEdited = Boolean(Object.values(editableFields).length)
    const positionMap = group.position_map
    const currentPositionMap = editablePositionMap ?? positionMap

    const getScoreSetupGroups = useGetScoreSetupGroups()
    const { groups } = getScoreSetupGroups?.data || {}
    const groupNames = groups?.map((group: any) => group.name)

    const createScoreGroup = useCreateScoreSetupGroup()
    const updateScoreGroup = useUpdateScoreSetupGroup()
    const deleteScoreGroup = useDeleteScoreSetupGroup()

    const createScoreSetupOption = useCreateScoreSetupOption()

    const toastId = React.useRef<any>(null)

    const placeholderOption = {
        name: '',
        value: '',
        procurement_score_group_id: group.id,
    }

    function arrayMove(array: any[], oldIndex: number, newIndex: number) {
        let newArray = [...array]
        newArray.splice(newIndex, 0, newArray.splice(oldIndex, 1)[0])
        return newArray
    }

    function handleDelete(): void {
        if (editablePositionMap) {
            return (toastId.current = sendToast({ message: 'Please Save Pending Position Changes' }))
        }
        deleteScoreGroup.mutate(
            { group },
            {
                onSuccess: () => {
                    setEditablePositionMap(undefined)
                    setEditableFields({})
                    setShowDelete(false)
                },
            }
        )
    }

    function handleSave(): void {
        updateScoreGroup.mutate(
            { group: { ...group, ...editableFields } },
            {
                onSuccess: () => {
                    setEditableFields({})
                },
            }
        )
    }
    function handleAddNewOption() {
        if (!showNew) {
            setShowNew(true)
        } else {
            toast.dismiss(toastId.current)
            return (toastId.current = sendToast({ message: 'Complete Pending Option Creation' }))
        }
    }
    function handleCreateGroup() {
        const editedGroup = { ...group, ...editableFields }
        if (!editedGroup.name) {
            toast.dismiss(toastId.current)
            return (toastId.current = sendToast({ message: 'Please Complete All Fields' }))
        }
        if (groupNames?.includes(editedGroup.name)) {
            toast.dismiss(toastId.current)
            return (toastId.current = sendToast({ message: 'No duplicate group names' }))
        }
        createScoreGroup.mutate(
            {
                ...editedGroup,
            },
            {
                onSuccess: () => {
                    setShowNewGroup(false)
                    setEditableFields({})
                },
            }
        )
    }
    function handleMove(direction: string) {
        if (isNew) {
            toast.dismiss(toastId.current)
            return (toastId.current = sendToast({ message: 'Complete Pending Group Creation' }))
        }
        const newIndex = direction === 'up' ? index - 1 : index + 1
        if (newIndex === -1 || newIndex === currentPositionMap.length) {
            return
        }
        setEditablePositionMap(arrayMove(currentPositionMap, index, newIndex))
    }

    return (
        <div className='p-2 border border-lightgrey dark:border-darkgrey mb-2 shadow-md rounded-md bg-white dark:bg-darkaccent'>
            <div className='grid grid-cols-[1fr_1fr] gap-2 items-center'>
                <div className='flex gap-2 items-center'>
                    <Input
                        label={isNew ? 'Create New Group' : ''}
                        className={cn(
                            isNew && 'mt-2',
                            'w-full text-[18px]  dark:bg-darkbg2 leading-1 focus:outline-none dark:text-white font-bold'
                        )}
                        id={group.id}
                        name='name'
                        value={editableFields.name ?? group.name}
                        placeholder='Name'
                        type='text'
                        onChange={({ target }) => {
                            setShowDelete(false)
                            setEditableFields((previousState: any) => ({
                                ...previousState,
                                [target.name]: target.value,
                            }))
                        }}
                    />
                    {!isNew && (
                        <div className='flex gap-4'>
                            <div className='flex gap-2'>
                                <button onClick={() => handleMove('up')}>
                                    <FaArrowUp />
                                </button>
                                <button onClick={() => handleMove('down')}>
                                    <FaArrowDown />
                                </button>
                            </div>
                            <div className='flex items-center gap-[4px]'>
                                <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    Required
                                </label>
                                <input
                                    name='is_required'
                                    id='is_required'
                                    type='checkbox'
                                    checked={editableFields.is_required ?? group.is_required}
                                    onChange={({ target }) =>
                                        setEditableFields((prev: any) => {
                                            return {
                                                ...prev,
                                                is_required: target.checked ? 1 : 0,
                                            }
                                        })
                                    }
                                />
                            </div>
                        </div>
                    )}
                </div>
                <>
                    {isNew && (
                        <div className='flex gap-[8px] justify-end'>
                            <button
                                onClick={handleCreateGroup}
                                className={`${
                                    createScoreGroup.isPending
                                        ? 'opacity-[0.5] pointer-events-none'
                                        : 'pointer-events-auto'
                                }  shrink-0 text-[14px] leading-[1] py-1 px-2 rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase`}
                            >
                                {createScoreGroup.isPending ? 'CREATING...' : 'CREATE'}
                            </button>
                            <button
                                onClick={() => {
                                    setShowNewGroup(false)
                                    setEditableFields({})
                                }}
                                className={`${
                                    createScoreGroup.isPending
                                        ? 'opacity-[0.5] pointer-events-none'
                                        : 'pointer-events-auto'
                                } shrink-0 text-[14px] leading-[1] py-1 px-2 rounded-[4px] bg-lightgrey dark:bg-darkgrey text-black dark:text-offwhite font-bold uppercase`}
                            >
                                Cancel
                            </button>
                        </div>
                    )}
                    {!isNew && isEdited && (
                        <div className='flex gap-[8px] justify-end'>
                            <button
                                onClick={handleSave}
                                className={`${
                                    updateScoreGroup.isPending
                                        ? 'opacity-[0.5] pointer-events-none'
                                        : 'pointer-events-auto'
                                } shrink-0 text-[14px] leading-[1] py-1 px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase`}
                            >
                                {updateScoreGroup.isPending ? 'SAVING...' : 'SAVE'}
                            </button>
                            <button
                                onClick={() => setEditableFields({})}
                                className={`${
                                    updateScoreGroup.isPending
                                        ? 'opacity-[0.5] pointer-events-none'
                                        : 'pointer-events-auto'
                                } self-center shrink-0 text-[14px] leading-[1] py-1 px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkgrey text-black dark:text-offwhite font-bold uppercase`}
                            >
                                Cancel
                            </button>
                        </div>
                    )}
                    {!isNew && showDelete && (
                        <div className='flex gap-[8px] self-center justify-self-end'>
                            <button
                                onClick={handleDelete}
                                className={`${
                                    deleteScoreGroup.isPending
                                        ? 'opacity-[0.5] pointer-events-none'
                                        : 'pointer-events-auto'
                                } shrink-0 text-[14px] leading-[1] py-[4px] w-fit px-[8px] rounded-[4px] bg-red dark:bg-lightred text-white font-bold uppercase`}
                            >
                                Delete
                            </button>
                            <button
                                onClick={() => setShowDelete(false)}
                                className={`${
                                    deleteScoreGroup.isPending
                                        ? 'opacity-[0.5] pointer-events-none'
                                        : 'pointer-events-auto'
                                } text-[14px] leading-[1] py-[4px] w-fit px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkgrey font-bold uppercase`}
                            >
                                Cancel
                            </button>
                        </div>
                    )}
                    {!isNew && !isEdited && !showDelete && (
                        <button onClick={() => setShowDelete(true)} className='mb-[9px] self-end justify-self-end'>
                            <FaTrash className='text-red dark:text-lightred w-4 h-4' />
                        </button>
                    )}
                </>
            </div>

            {!isNew && (
                <div className={`flex flex-col gap-2 pb-2 mt-2`}>
                    <div
                        className={`grid grid-cols-[2fr_2fr_1fr] border-b border-lightgrey dark:border-darkgrey mb-2 pb-2`}
                    >
                        <span>Option Name</span>
                        <span>Score Value</span>
                        <button
                            onClick={handleAddNewOption}
                            className={`${
                                createScoreSetupOption.isPending
                                    ? 'opacity-[0.5] pointer-events-none'
                                    : 'pointer-events-auto'
                            } self-center justify-self-start shrink-0 text-[14px] leading-[1] py-[2px] px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase`}
                        >
                            <span className='flex'>
                                New Option <PlusCircledIcon className='ml-1 font-bold self-center' />
                            </span>
                        </button>
                    </div>
                    {options?.map((option: any, index: number) => (
                        <ScoreOption isNew={false} option={option} last={!showNew && index === options.length - 1} />
                    ))}
                    {showNew && (
                        <ScoreOption isNew={true} option={placeholderOption} last={true} setShowNew={setShowNew} />
                    )}
                </div>
            )}
        </div>
    )
}
