import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { CustomerAddressType } from 'customersV2/Customers.types'
import { vFetch } from 'helpers'
import Input from 'procurement/components/Input'
import { useEffect, useRef, useState } from 'react'

export default function EditAddressModal({
    toggleModal,
    customerId,
    addressId,
}: {
    toggleModal: () => void
    customerId: string
    addressId: string
}) {
    const modalRef = useRef<HTMLDivElement>(null)
    const addressQuery = useQuery({
        queryKey: ['customers', customerId, 'addresses', addressId],
        queryFn: () => vFetch(`/v2/customers/addresses?id=${addressId}`),
    })
    const address: CustomerAddressType = (addressQuery?.data?.addresses || [])[0] || {}
    const [editedFields, setEditedFields] = useState<any>({})

    const queryClient = useQueryClient()
    const editAddressMutation = useMutation({
        mutationFn: async ({ address }: { address: CustomerAddressType }) =>
            vFetch('/v2/customers/addresses', {
                method: 'PUT',
                body: JSON.stringify({
                    customer_id: customerId,
                    address_id: addressId,
                    address,
                }),
            }),
        onSuccess: () => {
            setEditedFields({})
            toggleModal()
            return queryClient.invalidateQueries({ queryKey: ['customers', customerId] })
        },
    })

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement }) => {
        setEditedFields((prev: any) => ({ ...prev, [target.name]: target.value }))
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (modalRef.current === e.target) {
                toggleModal()
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <div
            ref={modalRef}
            className='fixed top-0 left-0 w-full h-full bg-black/50 z-50 flex items-center justify-center'
        >
            <div className='p-4 rounded shadow-md bg-white dark:bg-darkbg1 w-[400px] max-h-[90%] overflow-auto'>
                {addressQuery.isLoading ? (
                    <p>Loading...</p>
                ) : (
                    <div className='flex flex-col gap-2'>
                        <h2 className='font-bold text-lg'>Edit Address</h2>
                        <Input
                            id='company'
                            name='company'
                            type='text'
                            value={editedFields.company ?? (address.company || '')}
                            onChange={handleChange}
                            label='Company'
                        />
                        <Input
                            id='first_name'
                            name='first_name'
                            type='text'
                            value={editedFields.first_name ?? (address.first_name || '')}
                            onChange={handleChange}
                            label='First Name'
                        />
                        <Input
                            id='last_name'
                            name='last_name'
                            type='text'
                            value={editedFields.last_name ?? (address.last_name || '')}
                            onChange={handleChange}
                            label='Last Name'
                        />
                        <Input
                            id='phone'
                            name='phone'
                            type='text'
                            value={editedFields.phone ?? (address.phone || '')}
                            onChange={handleChange}
                            label='Phone number'
                        />
                        <Input
                            id='address1'
                            name='address1'
                            type='text'
                            value={editedFields.address1 ?? (address.address1 || '')}
                            onChange={handleChange}
                            label='Address'
                        />
                        <Input
                            id='address2'
                            name='address2'
                            type='text'
                            value={editedFields.address2 ?? (address.address2 || '')}
                            onChange={handleChange}
                            label='Address Line 2'
                        />
                        <Input
                            id='city'
                            name='city'
                            type='text'
                            value={editedFields.city ?? (address.city || '')}
                            onChange={handleChange}
                            label='City'
                        />
                        <Input
                            id='province_code'
                            name='province_code'
                            type='text'
                            value={editedFields.province_code ?? (address.province_code || '')}
                            onChange={handleChange}
                            label='State / Province Code'
                        />
                        <Input
                            id='zip'
                            name='zip'
                            type='text'
                            value={editedFields.zip ?? (address.zip || '')}
                            onChange={handleChange}
                            label='Zip'
                        />
                        <Input
                            id='country'
                            name='country'
                            type='text'
                            value={editedFields.country ?? (address.country || '')}
                            onChange={handleChange}
                            label='Country'
                        />
                        <div
                            className={`flex gap-2 items-center justify-center ${
                                editAddressMutation.isPending ? 'opacity-50 pointer-events-none' : ''
                            }`}
                        >
                            <Button onClick={toggleModal}>Cancel</Button>
                            <Button onClick={() => editAddressMutation.mutate({ address: editedFields })}>
                                {editAddressMutation.isPending ? 'Working...' : 'Save'}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
