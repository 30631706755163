import Select from 'procurement/components/Select'
import { useEffect, useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { ACTIVE_PROJECT_BRIDGE_TABLE_RESOURCE_NAMES } from 'tasksV2/constants/tasks'
import { Button } from 'custom_components/component_Basics/Button'
import RoadmapAssociationBuilder from '../components/RoadmapAssociationBuilder'

export default function ProjectAssociationsModal({
    closeModal,
    associations,
    toggleAssociation,
}: {
    closeModal: () => void
    associations: { resource_name: string; readable_name: string; resource_id: number }[]
    toggleAssociation: (association: { resource_name: string; readable_name: string; resource_id: number }) => void
}) {
    const modalRef = useRef<HTMLDivElement>(null)
    const [selectedAssociationType, setSelectedAssociationType] = useState<null | string>(null)

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement }) => {
        setSelectedAssociationType(target.value)
    }

    useEffect(() => {
        const listenToWindow = (event: MouseEvent) => {
            if (event.target === modalRef.current) {
                closeModal()
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    return (
        <div
            ref={modalRef}
            className='fixed z-50 top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center'
        >
            <div className='relative rounded p-6 bg-bg1 dark:bg-darkbg1 min-w-[400px]'>
                <button onClick={closeModal} className='absolute top-1 right-1'>
                    <FaTimes className='text-red dark:text-lightred text-lg' />
                </button>
                <h2 className='font-bold mb-2'>Associate Project To A Resource</h2>
                <div className='flex flex-col gap-1'>
                    <Select
                        label='Resource Type'
                        id='association_type'
                        name='association_type'
                        onChange={handleChange}
                        value={selectedAssociationType}
                    >
                        <option value=''>Select a resource type</option>
                        {ACTIVE_PROJECT_BRIDGE_TABLE_RESOURCE_NAMES.map((resourceName: string) => (
                            <option className='capitalize' value={resourceName}>
                                {resourceName[0].toUpperCase()}
                                {resourceName.slice(1).replaceAll('_', ' ')}
                            </option>
                        ))}
                    </Select>
                    {selectedAssociationType === 'roadmap' ? (
                        <RoadmapAssociationBuilder associations={associations} toggleAssociation={toggleAssociation} />
                    ) : (
                        <></>
                    )}
                </div>
                <Button variant={'outline'} className='block mx-auto mt-2' onClick={closeModal}>
                    Return To Project Editor
                </Button>
            </div>
        </div>
    )
}
