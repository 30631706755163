import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import store from './redux/store'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AnalyticsProvider } from 'use-analytics'
import analytics from './analytics/analytics'
import { formatErrorBlocks, formatSlackMessage, sendToastErr, sendToastWarning, vFetch } from './helpers'
import { AUDIT_TABLE_QUERY_KEY } from './audit/api/useQueries'

const queryClient = new QueryClient({
    queryCache: new QueryCache({
        onError: (error, query) => {
            const MODE = process.env.REACT_APP_MODE
            if (MODE !== 'production') {
                console.log(error)
            }
            if (error.status < 500) {
                if (!error.skipToast) {
                    return sendToastWarning(error)
                }
            }
            const { queryKey, meta } = query
            let formattedErrorFields = {
                error,
                queryKey,
                ...meta,
            }
            if (typeof error?.success === 'boolean') {
                formattedErrorFields = { ...formattedErrorFields, ...formattedErrorFields.error }
            } else {
                formattedErrorFields.message = error.message
            }

            const errorBlocks = formatErrorBlocks({ formattedErrorFields })
            const slackMessage = formatSlackMessage({ formattedErrorFields })

            vFetch(`/v1/slack`, {
                skipToast: true,
                method: 'POST',
                body: JSON.stringify({
                    message: slackMessage,
                    errorBlocks,
                }),
            })
            if (!error.skipToast) {
                sendToastErr({
                    message: `${formattedErrorFields.clientErrMessage}${
                        formattedErrorFields.serverErrMessage ? ':' : ''
                    } ${formattedErrorFields.serverErrMessage ? formattedErrorFields.serverErrMessage : ''}`,
                })
            }
        },
    }),
    mutationCache: new MutationCache({
        onError: (error, query, context, mutation) => {
            const MODE = process.env.REACT_APP_MODE
            if (MODE !== 'production') {
                console.log(error)
            }
            if (error.status < 500) {
                if (!error.skipToast) {
                    return sendToastWarning(error)
                }
            }
            const {
                state: { variables },
                meta,
            } = mutation
            let formattedErrorFields = {
                error,
                variables,
                ...meta,
            }
            if (typeof error?.success === 'boolean') {
                formattedErrorFields = { ...formattedErrorFields, ...formattedErrorFields.error }
            } else {
                formattedErrorFields.message = error.message
            }

            const errorBlocks = formatErrorBlocks({ formattedErrorFields })
            const slackMessage = formatSlackMessage({ formattedErrorFields })

            vFetch(`/v1/slack`, {
                skipToast: true,
                method: 'POST',
                body: JSON.stringify({
                    message: slackMessage,
                    errorBlocks,
                }),
            })
            if (!error.skipToast) {
                sendToastErr({
                    message: `${formattedErrorFields.clientErrMessage}: ${formattedErrorFields.serverErrMessage}`,
                })
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: AUDIT_TABLE_QUERY_KEY })
            queryClient.invalidateQueries({ queryKey: ['development'] })
            queryClient.invalidateQueries({ queryKey: ['companies', 'options'] })
        },
    }),
    defaultOptions: {
        queries: {
            staleTime: 30000,
            // throwOnError: true,
        },
        mutations: {
            meta: { clientErrMessage: 'Something went wrong' },
        },
    },
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <QueryClientProvider client={queryClient}>
        <AnalyticsProvider instance={analytics}>
            <Provider store={store}>
                <Router>
                    <App />
                </Router>
            </Provider>
            <ReactQueryDevtools initialIsOpen={false} />
        </AnalyticsProvider>
    </QueryClientProvider>
)
