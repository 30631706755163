import { Button } from 'custom_components/component_Basics/Button'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { cn } from 'helpers'
import { useUpdateCompany } from 'procurement/api/useQueries'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import Input from '../Input'

export default function ContactDetailsSideBarInfo({ company, setShowDetails }: any) {
    const modalRef = useRef(null)
    const [editableFields, setEditableFields] = useState<any>({})

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setEditableFields((previousState: any) => ({
            ...structuredClone(previousState),
            [target.name]: target.type === 'checkbox' ? (target as HTMLInputElement).checked : target.value,
        }))
    }

    const updateCompany = useUpdateCompany()

    function handleUpdate() {
        updateCompany.mutate(
            { editableFields, id: company.id },
            {
                onSuccess: () => {
                    setEditableFields({})
                },
                onSettled: () => {},
            }
        )
    }

    const handleReset = () => {
        setEditableFields({})
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (e.target === modalRef.current) {
                setShowDetails(false)
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    return (
        <div className='fixed top-0 left-0 w-[100%] h-[100%] justify-center bg-[rgba(0,0,0,0.5)] z-50'>
            <div ref={modalRef} className='relative flex justify-center items-center h-[calc(100%-51px)] w-[100%]'>
                <div className='relative min-w-[600px] bg-white dark:bg-darkaccent dark:text-offwhite p-[16px] rounded'>
                    <button
                        onClick={() => {
                            setShowDetails(false)
                        }}
                        className='leading-[1] text-[24px] text-red dark:text-lightred absolute font-bold top-0 right-[8px]'
                    >
                        &times;
                    </button>
                    <h2 className='text-[24px] font-semibold dark:text-offwhite capitalize mb-[8px]'>
                        Edit Company Info
                    </h2>

                    <div className='grid gap-[8px] overflow-auto max-h-[calc(70vh-51px)]'>
                        <Input
                            id='phone'
                            name='phone'
                            label='Phone'
                            value={editableFields.phone ?? company.phone ?? ''}
                            onChange={handleChange}
                            type='text'
                        />

                        <Input
                            id='email'
                            name='email'
                            label='Email'
                            value={editableFields.email ?? company.email ?? ''}
                            onChange={handleChange}
                            type='text'
                        />
                        <Input
                            id='domain'
                            name='domain'
                            label='Domain Name'
                            value={editableFields.domain ?? company.domain ?? ''}
                            onChange={handleChange}
                            type='text'
                        />
                    </div>
                    <div className='relative flex justify-center mt-[16px] gap-4 h-[40px]'>
                        <Button variant={'outline'} className={cn('col relative')} onClick={() => handleUpdate()}>
                            <p className={cn(updateCompany.isPending && 'opacity-0')}>Submit</p>
                            {updateCompany.isPending && (
                                <div className='absolute '>
                                    <FetchingSpinner isFetching={updateCompany.isPending} />
                                </div>
                            )}
                        </Button>

                        <Button
                            disabled={updateCompany.isPending}
                            variant={'outline'}
                            className={`text-danger`}
                            onClick={handleReset}
                        >
                            Reset
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
