import { useState } from 'react'
import { AiFillCaretDown } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { DayMap, MonthMap, dayMap, monthMap } from '../../helpers'
import { OrderTimelineEvent, DraftOrderInit } from '../../orders/orders.types'
import DraftOrderPaymentStatus from '../components/DraftOrderPaymentStatus'
import DraftOrderUnfulfilledItems from '../components/DraftOrderUnfulfilledItems'

function renderTimelineEvent(event: OrderTimelineEvent) {
    const date = new Date(Date.parse(event.createdAt))
    const time = [
        [date.toLocaleTimeString().split(':')[0], date.toLocaleTimeString().split(':')[1]].join(':'),
        date.toLocaleTimeString().split(':')[2].split(' ')[1],
    ].join(' ')
    const linkRegex = /\<a href="(.+)">(.+)<\/a>/

    let { message } = event
    let strings = []

    message = message.replaceAll('&lt;', '<').replaceAll('&gt;', '>')
    if (message.match(linkRegex)) {
        while (message.match(linkRegex)) {
            const link: string[] = message.match(linkRegex) || []
            const linkIndex = message.indexOf(link[0])
            const string = message.slice(0, linkIndex)
            strings.push(<span className='inline'>{string}</span>)
            message = message.replace(string + link[0], '')
            strings.push(
                <a className='inline hover:underline text-blue dark:text-accent' href={link[1]}>
                    {link[2]}
                </a>
            )
        }
        strings.push(message.slice(0))
    } else {
        strings = [<p>{message}</p>]
    }

    return event.__typename === 'BasicEvent' ? (
        <li key={event.id} className='flex mb-[16px] gap-[8px] basis-[100%] items-center'>
            <div className='relative top-0 bg-[#809ead] dark:bg-accent border-[5px] border-white dark:border-darkaccent rounded-full shrink-0 h-[16px] ml-[calc(var(--p-space-025)+0.59375rem+var(--p-space-4))] mr-[var(--p-space-4)] w-[16px] z-index-2 self-center'></div>

            <div className='w-[100%] max-w-[100%] p-[16px] gap-[8px] rounded dark:text-offwhite'>
                {strings.map((string) => string)}
            </div>
            <div className='flex flex-wrap justify-end content-center text-[12px] h-[100%]'>
                <p className='dark:text-offwhite'>{date.toLocaleDateString()}</p>
                <p className='text-darkgrey dark:text-grey'>{time}</p>
            </div>
        </li>
    ) : (
        event.__typename === 'CommentEvent' && (
            <li
                key={event.id}
                className='flex mb-[16px] gap-[8px] bg-white dark:bg-darkaccent basis-[100%] items-center'
            >
                <div className='relative top-0 bg-[#809ead] dark:bg-accent border-[5px] border-white dark:border-darkaccent rounded-full shrink-0 h-[16px] ml-[calc(var(--p-space-025)+0.59375rem+var(--p-space-4))] mr-[var(--p-space-4)] w-[16px] z-index-2 self-center'></div>

                <div className='w-[100%] max-w-[100%] p-[16px] gap-[8px] dark:bg-darkness shadow-small dark:shadow-cool rounded dark:text-offwhite'>
                    {strings.map((string) => string)}
                </div>
                <div className='flex flex-wrap justify-end content-center text-[12px] h-[100%]'>
                    <p className='dark:text-offwhite'>{date.toLocaleDateString()}</p>
                    <p className='text-darkgrey dark:text-grey'>{time}</p>
                </div>
            </li>
        )
    )

}

type OverviewProps = {
    events: any
    order: DraftOrderInit
}
export default function DraftOrderOverView({ order, events }: OverviewProps) {


    function draftOrderCreatedOn(order: any) {
        return new Date(Date.parse(order))
            .toDateString()
            .split(' ')
            .map((dateItem: string) =>
                dayMap[dateItem as keyof DayMap]
                    ? `${dayMap[dateItem as keyof DayMap]}, `
                    : monthMap[dateItem as keyof MonthMap]
                        ? `${monthMap[dateItem as keyof MonthMap]}`
                        : Number(dateItem) < 32
                            ? `${dateItem},`
                            : dateItem
            )
            .join(' ')
    }

    function draftOrderCreatedTime(order: any) {
        return `${new Date(order).getHours() > 12
            ? new Date(order).getHours() % 12
            : new Date(order).getHours() === 0
                ? 12
                : new Date(order).getHours()
            }:${new Date(order).getMinutes() < 10 ? `0${new Date(order).getMinutes()}` : new Date(order).getMinutes()} ${new Date(order).getHours() >= 12 ? 'PM' : 'AM'
            }`
    }

    return (
        <>


            <div className='flex flex-col basis-[calc(65%)] grow-[1] min-w-[450px] gap-2'>
                <DraftOrderUnfulfilledItems order={order} />
                <DraftOrderPaymentStatus order={order} />


            </div>
        </>
    )
}
