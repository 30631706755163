import { ChangeEvent, useEffect, useState } from 'react'
import { PaginationProps } from '../ProcessingTimesScreen.types'

export default function Pagination({ page, setPage, list = [], resetPage, setResetPage }: PaginationProps) {
    useEffect(() => {
        setPage(1)
        setVisiblePage('1')
    }, [list])
    useEffect(() => {
        if (resetPage) {
            setResetPage(false)
            setPage(1)
            setVisiblePage('1')
        }
    }, [resetPage])
    const [visiblePage, setVisiblePage] = useState(String(page))
    const handleSetPage = (event: ChangeEvent<HTMLInputElement>, list: any[]) => {
        const target = event.target
        setVisiblePage(target.value)
        if (!target.value) {
            setPage(1)
            return
        }
        const newPage =
            parseInt(target.value) * 50 < list.length ? parseInt(target.value) : parseInt(String(list.length / 50 + 1))
        setPage(newPage)
    }
    const handlePrevious = () => {
        if (page > 1) {
            setVisiblePage(String(page - 1))
            setPage((previousPage) => previousPage - 1)
        }
    }
    const handleNext = (list: any[]) => {
        if (page * 50 < list.length) {
            setVisiblePage(String(page + 1))
            setPage((previousPage) => previousPage + 1)
        }
    }
    return (
        <div className='flex justify-center mx-auto items-center gap-[8px] mt-[16px]'>
            <button
                disabled={page === 1}
                className='py-[4px] px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkness font-bold'
                onClick={handlePrevious}
            >
                PREV
            </button>
            <input
                className='p-[4px] rounded-[4px] border border-lightgrey dark:border-darkgrey dark:text-offwhite bg-transparent w-[40px] text-center'
                value={visiblePage}
                type='number'
                onChange={(e) => handleSetPage(e, list)}
                onBlur={() => setVisiblePage(String(page))}
            />
            <button
                disabled={page * 50 > list.length}
                className='py-[4px] px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkness font-bold'
                onClick={() => handleNext(list)}
            >
                NEXT
            </button>
        </div>
    )
}
