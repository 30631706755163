// components/LineChart.js
import React from 'react'
import { Line } from 'react-chartjs-2'
import { useSelector } from 'react-redux'
import { cn } from '../../helpers'
function LineChart({
    chartTitle,
    chartData,
    size,
}: {
    chartTitle: string
    chartData: any
    size: 'small' | 'medium' | 'large'
}) {
    const settings = useSelector<any, any>((state: any) => state.settings)

    const darkGraphOptions = {
        scales: {
            x: {
                grid: {
                    display: true,
                    color: 'grey',
                },
                ticks: {
                    color: '#eeefea',
                },
            },
            y: {
                suggestedMax: 30,
                suggestedMin: 0,
                grid: {
                    display: true,
                    color: 'darkgrey',
                },
                ticks: {
                    stepSize: 1,
                    color: '#eeefea',
                },
            },
        },
        plugins: {
            title: {
                display: true,
                text: chartTitle,
                color: 'white',
            },
            legend: {
                display: true,
                labels: {
                    color: 'white',
                },
            },
        },
    }
    const lightGraphOptions = {
        scales: {
            x: {
                grid: {
                    display: true,
                    color: 'grey',
                },
                ticks: {
                    color: 'black',
                },
            },
            y: {
                suggestedMax: 30,
                suggestedMin: 0,
                grid: {
                    display: true,
                    color: 'darkgrey',
                },
                ticks: {
                    stepSize: 1,
                    color: 'black',
                },
            },
        },
        plugins: {
            title: {
                display: true,
                text: chartTitle,
                color: 'black',
            },
            legend: {
                display: true,
                labels: {
                    color: 'black',
                },
            },
        },
    }
    return (
        <div
            className={cn(
                size === 'small' && 'h-[50vh] w-[28vw]',
                size === 'medium' && 'h-[50vh] w-[56vw]',
                size === 'large' && 'h-[50vh] w-[85vw]',
                'relative dark:bg-darkbg2'
            )}
        >
            <Line
                data={chartData}
                options={{
                    maintainAspectRatio: false,
                    plugins: settings.theme == 'dark' ? darkGraphOptions.plugins : lightGraphOptions.plugins,
                    scales: settings.theme == 'dark' ? darkGraphOptions.scales : lightGraphOptions.scales,
                }}
            />
        </div>
    )
}
export default LineChart
