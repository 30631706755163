import { useRef } from 'react'
import { sendToast } from '../../helpers'
import Calendar from '../../custom_components/Calendar'
import { useSelector } from 'react-redux'

type DueAtProps = {
    dueAt: Date
    setDueAt: Function
    style?: string
}

export default function TaskDueAt({ dueAt, setDueAt, style }: DueAtProps) {
    const settings = useSelector<any, any>((state) => state.settings)
    const dueAtRef = useRef(null)
    return (
        <div className='flex relative'>
            <input
                className={`block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite bg-lightgrey dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent ${style}`}
                type='text'
                id='due_at'
                name='due_at'
                style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                tabIndex={-1}
                value={[dueAt.getMonth() + 1, dueAt.getDate(), dueAt.getFullYear()].join('/')}
                onChange={() => { }}
            />
            <input
                className={`absolute w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] text-transparent focus:text-black focus:dark:text-offwhite bg-transparent focus:bg-white dark:focus:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent ${style}`}
                type='text'
                id='due_at'
                name='due_at'
                style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                onBlur={({ target }) => {
                    const splitDate = target.value.replaceAll(/[a-zA-Z]/g, '').match(/\d+/g)
                    if (splitDate) {
                        const dueAt = new Date(Number(splitDate[2]), Number(splitDate[0]) - 1, Number(splitDate[1]))
                        if (
                            !isNaN(dueAt.getTime()) &&
                            dueAt.getFullYear() < new Date().getFullYear() + 3 &&
                            dueAt.getFullYear() > new Date().getFullYear() - 1
                        ) {
                            target.value = [dueAt.getMonth() + 1, dueAt.getDate(), dueAt.getFullYear()].join('/')
                            return setDueAt(dueAt)
                        } else {
                            target.value = [Number(dueAt.getMonth()) + 1, dueAt.getDate(), dueAt.getFullYear()].join(
                                '/'
                            )
                            return sendToast({
                                message: `${dueAt.toLocaleDateString()} is out of the acceptable range.`,
                            })
                        }
                    }
                    target.value = [Number(dueAt.getMonth()) + 1, dueAt.getDate(), dueAt.getFullYear()].join('/')
                    sendToast({ message: `Invalid date format: ${target.value}. \n\n\n\n Try m/d/year` })
                }}
                ref={dueAtRef}
                value={[Number(dueAt.getMonth()) + 1, dueAt.getDate(), dueAt.getFullYear()].join('/')}
            />
            <div className='absolute right-[4px] top-[4px]'>
                <Calendar startDate={dueAt} setStartDate={setDueAt} endDate={dueAt} acceptsTextStartDate={true} />
            </div>
        </div>
    )
}
