import { useState } from 'react'
import { vFetch } from '../../helpers'
import { Button } from '../../custom_components/component_Basics/Button'
import { useQueryClient } from '@tanstack/react-query'

export default function CreateAnswerModal({ getAnswers, question_id }: any) {
    const [answer, setAnswer] = useState('')
    const queryClient = useQueryClient()

    const handleSubmitAnswer = () => {
        vFetch('/v1/knowledge/answers', {
            method: 'POST',
            body: JSON.stringify({ answer, question_id }),
            cb: () => {
                setAnswer('')
                getAnswers()
                return queryClient.invalidateQueries({
                    queryKey: ['knowledge', 'questions'],
                })
            },
        })
    }

    return (
        <>
            <div className='p-4 w-[100%] relative bg-white  dark:bg-darkaccent rounded border border-lightgrey dark:border-darkgrey'>
                <p className='text-lg font-bold p-2'>Answer</p>
                <textarea
                    autoFocus={true}
                    value={answer}
                    onChange={({ target }) => setAnswer(target.value)}
                    className='min-h-[40px] max-h-[90px] w-[100%] p-[8px] max-w-none border-0
                    rounded focus:outline-none  dark:bg-darkbg1
                     dark:text-offwhite  overscroll-contain pb-0'
                    placeholder='Type your answer here'
                ></textarea>
                <div></div>
                <Button variant={'outline'} className='block mx-auto' onClick={handleSubmitAnswer}>
                    Submit Answer
                </Button>
            </div>
        </>
    )
}
