import { PlugType } from '../ProductSetup.types'

export const DEFAULT_PLUGS: PlugType[] = [
    {
        name: '120v 20 Amp Standard Receptacle',
        type: '5-20R',
        image_src: 'https://cdn.shopify.com/s/files/1/1163/1976/files/120V_20A_GFCI_Outlet.png?v=1612382967',
    },
    {
        name: '120v 30 Amp Twist Lock',
        type: 'L5-30R',
        image_src: 'https://cdn.shopify.com/s/files/1/1163/1976/files/120V_30A_Twist_Lock.png?v=1612382967',
    },
    {
        name: '120/240v 30 Amp Twist Lock',
        type: 'L14-30R',
        image_src: 'https://cdn.shopify.com/s/files/1/1163/1976/files/120V_240V_30A_Twist_Lock.png?v=1612382967',
    },
    {
        name: '120v/240v 50 Amp Heavy Duty Outlet',
        type: 'L14-50R',
        image_src: 'https://cdn.shopify.com/s/files/1/1163/1976/files/120V_240V_50A_Heavy_Duty_Outlet.png?v=1612382967',
    },
    {
        name: '120v/240v 50 Amp Heavy Duty Outlet',
        type: '14-50R',
        image_src: 'https://cdn.shopify.com/s/files/1/1163/1976/files/120V_240V_50A_Heavy_Duty_Outlet.png?v=1612382967',
    },
    {
        name: '120v 30 Amp RV Ready',
        type: 'TT-30R',
        image_src: 'https://cdn.shopify.com/s/files/1/1163/1976/files/120V_TT-30R.png?v=1612813988',
    },
    {
        name: '125v/250v 20 Amp Twist Lock',
        type: 'L14-20R',
        image_src: 'https://cdn.shopify.com/s/files/1/1163/1976/files/125V_250V_20_Amp_Twist_Lock.png?v=1613276582',
    },
]
