import { vFetch } from '../../../helpers/index'

const migrationsURL = '/v1/tableData'

export const getProcessList = async () => {
    // const queryParams = [taxExemptionId ? `id=${taxExemptionId}` : null].filter((v) => v).join('&')

    return await vFetch(`${migrationsURL}/processList`, {
        reThrowErr: true,
    })
}
