export function quickDateRange(year: number, q?: number, m?: number) {
    if (q) m = (q - 1) * 3
    const start = new Date(year, m || 0, 1)
    const end = q
        ? new Date(year, m! + 3, 0, 23, 59, 59, 999)
        : m || m === 0
          ? new Date(year, Number(m) + 1, 0, 23, 59, 59, 999)
          : new Date(year, 12, 0, 23, 59, 59, 999)

    return [start, end]
}
