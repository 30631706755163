import { createColumnHelper, RowSelectionState } from '@tanstack/react-table'
import { Button } from 'custom_components/component_Basics/Button'
import { useUnfulfilledLineItemsQuery } from 'procurement/api/useQueries'
import { CompanyContext, CompanyContextType, formatDate, formatDateWithYear } from 'procurement/helpers'
import React, { useContext, useState } from 'react'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import UnfulfilledLineItemTableV2 from 'vendors/components/UnfulfilledLineItemTableV2'
import CompanyQuickModal from '../modals/CompanyQuickModal'
import UnfulfilledOrderEmail from '../modals/quickActions/UnfulfilledOrderEmail'
import { AiFillInfoCircle } from 'react-icons/ai'
import { LoadingGear } from 'helpers'
import FilterToolBar from '../FilterToolBar'

export type unfulfilledLineItemType = {
    id: number
    vendor: string
    created_at: any
    fulfillment_status: string
    order_id: number
    price: number
    quantity: number
    sku: string
    title: string
    name: string
    email_sent_at: any
}
export default function UnfulfilledOrdersList({ company, rowSelection, setRowSelection }: any) {
    const unfulfilledLineItemsQuery = useUnfulfilledLineItemsQuery(company.shopify_vendor_name, undefined)
    const { unfulfilledLineItems } = unfulfilledLineItemsQuery?.data || {}
    const [search, setSearch] = useState('')
    const [showEmailModal, setShowEmailModal] = useState(false)

    const tableData = unfulfilledLineItems?.length > 0 ? unfulfilledLineItems : []
    const tableDataMemo = React.useMemo(() => tableData ?? [], [tableData])
    const columnHelper = createColumnHelper<unfulfilledLineItemType>()

    const formatter = new Intl.NumberFormat('en-US')

    function truncate(str: string, n: number) {
        return str.length > n ? str.slice(0, n - 1) + '...' : str
    }

    const selectedLineItems = unfulfilledLineItems?.filter((lineItem: unfulfilledLineItemType) => {
        return Object.keys(rowSelection).includes(lineItem.id.toString())
    })
    const unfulfilledColumns = [
        {
            enableGlobalFilter: false,
            id: 'select-col',
            header: ({ table }: { table: any }) => (
                <div className='flex items-center justify-center ml-2'>
                    <input
                        className='h-[15px] w-[15px]'
                        type='checkbox'
                        checked={table.getIsAllRowsSelected()}
                        // indeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()} //or getToggleAllPageRowsSelectedHandler
                    />
                </div>
            ),
            cell: ({ row }: { row: any }) => (
                <div onClick={row.getToggleSelectedHandler()} className='h-full flex items-center justify-center'>
                    <input
                        className='h-[15px] w-[15px] '
                        type='checkbox'
                        checked={row.getIsSelected()}
                        disabled={!row.getCanSelect()}
                        onChange={row.getToggleSelectedHandler()}
                    />
                </div>
            ),
        },

        columnHelper.accessor('name', {
            header: 'Name',
            enableGlobalFilter: true,
            cell: (info) => {
                return (
                    <div className='flex gap-2 items-center justify-between '>
                        {typeof info.getValue() === 'number' ? info.getValue() : info.getValue()}
                        <Link
                            to={`https://fpdash.com/orders/${info.row.original.order_id}`}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <FaExternalLinkAlt />
                        </Link>
                    </div>
                )
            },
        }),
        columnHelper.accessor('order_id', {
            header: 'Order ID',
            enableGlobalFilter: false,
            cell: (info) => {
                return (
                    <div className='flex gap-2 items-center justify-between '>
                        {typeof info.getValue() === 'number' ? formatter.format(info.getValue()) : info.getValue()}
                        <Link
                            to={`https://admin.shopify.com/store/factorypure/orders/${info.getValue()}`}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <FaExternalLinkAlt />
                        </Link>
                    </div>
                )
            },
        }),
        columnHelper.accessor('price', {
            enableGlobalFilter: false,
            cell: (info) => {
                return (
                    <div className='flex flex-col gap-2 '>
                        ${typeof info.getValue() === 'number' ? formatter.format(info.getValue()) : info.getValue()}
                    </div>
                )
            },
        }),
        columnHelper.accessor('quantity', {
            enableGlobalFilter: false,
            cell: (info) => {
                return (
                    <div className='flex flex-col gap-2 '>
                        {typeof info.getValue() === 'number' ? formatter.format(info.getValue()) : info.getValue()}
                    </div>
                )
            },
        }),
        columnHelper.accessor('sku', {
            enableGlobalFilter: true,
            cell: (info) => {
                return <div className='flex flex-col gap-2 '>{truncate(info.getValue()?.toString() || '', 30)}</div>
            },
        }),
        columnHelper.accessor('title', {
            enableGlobalFilter: true,
            cell: (info) => {
                return <div className='flex flex-col gap-2 '>{truncate(info.getValue(), 55)}</div>
            },
        }),
        columnHelper.accessor('created_at', {
            enableGlobalFilter: false,
            header: 'Order Created at',

            cell: (info) => {
                return <div className='flex flex-col gap-2 '>{formatDateWithYear(new Date(info.getValue()))}</div>
            },
        }),
        columnHelper.accessor('email_sent_at', {
            enableGlobalFilter: false,
            header: () => {
                return (
                    <div className='relative flex gap-1 peer items-center'>
                        <p>Last Emailed</p>
                        <AiFillInfoCircle className='peer cursor-help' />
                        <div className='dark:bg-darkaccent opacity-0 peer-hover:opacity-100 dark:border-[1px] absolute pointer-events-none top-[24px] z-[5] bg-white shadow-small p-2 min-w-[150px] rounded-md font-normal normal-case'>
                            <p className=' text-text1 dark:text-darktext1'>
                                Emails not sent from this tool will not be accounted for here
                            </p>
                        </div>
                    </div>
                )
            },

            cell: (info) => {
                return (
                    <div className='flex gap-2'>
                        {info.getValue() ? formatDateWithYear(new Date(info.getValue())) : ''}
                    </div>
                )
            },
        }),
    ]

    const columnCount = unfulfilledColumns?.length.toString() || 10

    return (
        <div className='p-[16px] pt-0 pl-0 '>
            <>
                <div>
                    <FilterToolBar search={search} setSearch={setSearch} filterBarObject={undefined} />
                    {tableDataMemo && (
                        <div className='w-fit  text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                            <UnfulfilledLineItemTableV2
                                rowSelection={rowSelection}
                                setRowSelection={setRowSelection}
                                width={'90vw'}
                                search={search}
                                columns={unfulfilledColumns}
                                tableData={tableDataMemo}
                                columnCount={columnCount}
                            />
                        </div>
                    )}
                    {unfulfilledLineItemsQuery.isLoading && (
                        <div className='flex items-center justify-center w-full h-[85vh]'>
                            <LoadingGear />
                        </div>
                    )}
                    {Object.keys(rowSelection).length > 0 && (
                        <div className='flex fixed bottom-[8px] left-[50%] translate-x-[-50%] p-[8px] bg-white dark:bg-darkness border-[1px] border-darkgrey gap-[16px] justify-center items-center mt-[16px] rounded'>
                            <Button onClick={() => setShowEmailModal(true)} variant={'outline'} size={'default'}>
                                Email Selected
                            </Button>
                        </div>
                    )}
                </div>
            </>
            {showEmailModal && (
                <>
                    <UnfulfilledOrderEmail
                        showQuickModal={showEmailModal}
                        setShowQuickModal={setShowEmailModal}
                        unfulfilledLineItems={selectedLineItems}
                    />
                </>
            )}
        </div>
    )
}
