import { useEffect, useState } from 'react'
import { BiLinkExternal } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import { vFetch } from '../helpers'
import { useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
const moneyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })

export default function OrdersModule() {
    const ordersQuery = useQuery({
        queryKey: ['orders', 'homeScreen'],
        queryFn: () =>
            vFetch(`/v1/orders/v2/latest`, {
                cb: (res) => {
                    if (!res.success) {
                        return []
                    }
                    return res.orders
                },
                catchCb: () => [],
            }),
    })
    const orders = ordersQuery?.data || []
    return (
        <div className='p-[16px] rounded-[4px] dark:bg-darkaccent shadow-small h-[350px] flex flex-col'>
            <Link to={'/orders'} className='text-fire font-bold mb-[8px] flex gap-[4px] items-center'>
                Last 50 Orders <BiLinkExternal />
            </Link>
            <div className='h-full overflow-auto'>
                {ordersQuery.isLoading ? (
                    <p>Loading</p>
                ) : (
                    <>
                        {orders.map((o) => (
                            <Link
                                key={o.id}
                                to={`/orders/${o.id}`}
                                className='p-[8px] grid grid-cols-[1fr_2fr_1fr_1fr] gap-[8px] border-b border-lightgrey dark:border-darkgrey'
                            >
                                <p className='text-blue dark:text-accent font-bold'>{o.name}</p>
                                <p className='text-darkgrey dark:text-offwhite font-bold'>
                                    {o.customer_first_name ? `${o.customer_first_name} ` : ''}
                                    {o.customer_last_name ? o.customer_last_name : ''}
                                </p>
                                <p className='text-darkgrey dark:text-offwhite font-bold'>
                                    {o.line_item_count == 1 ? `1 item` : `${o.line_item_count} items`}
                                </p>
                                <p className='text-lime font-bold'>{moneyFormatter.format(o.total_price)}</p>
                            </Link>
                        ))}
                    </>
                )}
            </div>
        </div>
    )
}
