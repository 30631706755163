import { useEffect, useState } from 'react'
import { MonthMap, closePopout, dayMap, formatDateToLocale, monthMap, sendToast } from '../../helpers'
import {
    DraftOrderInit,
    FinancialStatusMap,
    FulfillmentStatusMap,
    LineItemInit,
    OrderInit,
    financialStatusMap,
    fulfillmentStatusMap,
} from '../../orders/orders.types'
import { TbCircle, TbCircleFilled } from 'react-icons/tb'
import { AiFillCaretDown } from 'react-icons/ai'
import { useNavigate, useParams } from 'react-router-dom'
import { FaRegCommentDots } from 'react-icons/fa'
import { FcDoNotInsert } from 'react-icons/fc'
import { IoAlertCircleOutline } from 'react-icons/io5'
import { PrivateEventType, TaskEditInit } from '../../tasks/tasks.types'
import { DayMap } from '../../helpers'
import { UserInit } from '../../users/users.types'
import PrivateEventModal from '../../tasks/components/PrivateEventModal'
import TaskEvent from '../../tasks/components/PrivateEvent'
import { useSelector } from 'react-redux'
import PrivateEvent from '../../tasks/components/PrivateEvent'

type HeaderProps = {
    userList: UserInit[]
    order: DraftOrderInit
    tasks: TaskEditInit[]
    actions: any[]
    addNewTask: Function
    draftOrderEventsPrivate: PrivateEventType[]
}

export default function DraftOrderHeader({
    order,
    tasks,
    actions,
    addNewTask,
    userList,
    draftOrderEventsPrivate,
}: HeaderProps) {
    const navigate = useNavigate()
    const user = useSelector<any, any>((state) => state.user)
    const { orderId } = useParams()
    const [showActions, setShowActions] = useState<boolean>(false)
    const [showDraftOrderEventsPrivate, setShowDraftOrderEventsPrivate] = useState(false)

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => closePopout(e, ['js-order__actions'], setShowActions)
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    function renderCreatedAt() {
        const shopifyAppNamesMap: any = {
            580111: 'FactoryPure.com',
            1775805: 'eBay.com',
            1456995: 'Walmart',
        }
        const createdAtMap: any = {
            shopify_draft_order: 'Draft Orders',
            web: shopifyAppNamesMap[order.app_id],
            1456995: 'Walmart',
        }
        const month = monthMap[formatDateToLocale(order.created_at).split(' ')[0] as keyof MonthMap]
        const dateYear = formatDateToLocale(order.created_at).split(' ').slice(1, 3).join(' ')
        const time = formatDateToLocale(order.created_at).split(' ').slice(3).join(' ')
        const from = order.source_name ? `from ${createdAtMap[order.source_name] || order.source_name}` : ''
        return [month, dateYear, 'at', time, from].join(' ').trim()
    }

    const tagStyle =
        'relative w-[auto] p-[2px] px-[8px] pr-[10px] flex gap-[4px] items-center text-darkgrey rounded-full'
    const successColor = 'bg-[rgb(190,255,200)]'
    const cancelColor = 'bg-[rgb(255,215,164)]'

    const unsubmitted = {
        line_items: order.line_items.filter((item) => item.fulfillable_quantity !== 0),
        quantity: order.line_items.reduce((acc: number, cur: LineItemInit) => acc + cur.fulfillable_quantity, 0),
    }

    const openTasks = tasks.filter((t) => t.status === 'OPEN')

    function draftOrderCreatedOn(order: any) {
        return new Date(Date.parse(order))
            .toDateString()
            .split(' ')
            .map((dateItem: string) =>
                dayMap[dateItem as keyof DayMap]
                    ? `${dayMap[dateItem as keyof DayMap]}, `
                    : monthMap[dateItem as keyof MonthMap]
                      ? `${monthMap[dateItem as keyof MonthMap]}`
                      : Number(dateItem) < 32
                        ? `${dateItem},`
                        : dateItem
            )
            .join(' ')
    }
    function draftOrderCreatedTime(order: any) {
        return `${
            new Date(order).getHours() > 12
                ? new Date(order).getHours() % 12
                : new Date(order).getHours() === 0
                  ? 12
                  : new Date(order).getHours()
        }:${new Date(order).getMinutes() < 10 ? `0${new Date(order).getMinutes()}` : new Date(order).getMinutes()} ${
            new Date(order).getHours() >= 12 ? 'PM' : 'AM'
        }`
    }

    return (
        <>
            <div className='w-[100%]'>
                {draftOrderEventsPrivate && showDraftOrderEventsPrivate && (
                    <PrivateEventModal
                        origin='draft order'
                        userList={userList}
                        setViewModal={setShowDraftOrderEventsPrivate}
                        events={draftOrderEventsPrivate}
                    />
                )}
                <div className='flex w-[100%] dark:text-offwhite justify-between items-center'>
                    <div className='flex items-center gap-[8px]'>
                        <h1 className='text-[24px]'>Draft Order - {order.name}</h1>
                        <div className='flex gap-[8px]'>
                            {order.cancel_reason && !!order.cancelled_at && (
                                <div
                                    className={`${tagStyle} ${cancelColor}`}
                                    title={`Reason: ${order.cancel_reason}\n${formatDateToLocale(order.cancelled_at)}`}
                                >
                                    <h2 className='inline-block font-semibold text-[14px] text-center'>Canceled</h2>
                                </div>
                            )}
                            <div
                                className={`${tagStyle} ${
                                    order.financial_status &&
                                    financialStatusMap[order.financial_status as keyof FinancialStatusMap]
                                        ? financialStatusMap[order.financial_status as keyof FinancialStatusMap].bg
                                        : 'bg-fire'
                                }`}
                            >
                                {order.financial_status &&
                                financialStatusMap[order.financial_status as keyof FinancialStatusMap] &&
                                financialStatusMap[order.financial_status as keyof FinancialStatusMap].symbol ? (
                                    order.financial_status.toLowerCase() === 'paid' ? (
                                        <TbCircleFilled />
                                    ) : (
                                        financialStatusMap[order.financial_status as keyof FinancialStatusMap].symbol
                                    )
                                ) : (
                                    <TbCircle />
                                )}
                                <h2 className='inline-block font-semibold text-[14px] text-center'>
                                    {order.financial_status &&
                                    financialStatusMap[order.financial_status as keyof FinancialStatusMap]
                                        ? financialStatusMap[order.financial_status as keyof FinancialStatusMap].status
                                        : 'Unpaid'}
                                </h2>
                            </div>
                            <div
                                className={`${tagStyle} ${
                                    order.fulfillment_status &&
                                    fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap]
                                        ? fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap]
                                              .bg
                                        : 'bg-fire'
                                } relative w-[auto] p-[2px] px-[8px] pr-[10px] flex gap-[4px] items-center text-darkgrey rounded-full`}
                            >
                                {order.fulfillment_status &&
                                fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap] &&
                                fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap].symbol ? (
                                    fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap].symbol
                                ) : (
                                    <TbCircle />
                                )}
                                <h2 className='inline-block font-semibold text-[14px] text-center'>
                                    {order.fulfillment_status &&
                                    fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap]
                                        ? fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap]
                                              .status
                                        : 'Unfulfilled'}
                                </h2>
                            </div>
                            {!!order.has_timeline_comment && (
                                <div className={`${tagStyle} ${successColor}`} title='This order has timeline comments'>
                                    <FaRegCommentDots />
                                    <h2 className='inline-block font-semibold text-[14px] text-center'>Submitted</h2>
                                </div>
                            )}
                            {unsubmitted.quantity > 0 && (
                                <div
                                    className={`${tagStyle} bg-fire`}
                                    title={`${unsubmitted.quantity} item${
                                        unsubmitted.quantity === 1 ? ' does' : 's do'
                                    } not have a purchase order.\n\n${unsubmitted.line_items
                                        .map((item) => `(${item.fulfillable_quantity}x) ${item.name}`)
                                        .join('\n')}`}
                                >
                                    <IoAlertCircleOutline className='' />
                                    <h2 className='inline-block font-semibold text-[14px] text-center'>
                                        {unsubmitted.quantity} Unsubmitted
                                    </h2>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='flex [&>button]:font-semibold [&>button]:rounded gap-[16px]'>
                        <button
                            className='px-2 py-1 text-sm h-fit border-[1px] border-black dark:border-darkgrey hover:bg-offwhite dark:hover:bg-darkaccent'
                            onContextMenu={() => {
                                window.open(`/tasks/new?order_id=${orderId}`)
                            }}
                            onClick={() => addNewTask()}
                        >
                            Create Task
                        </button>
                        <button
                            className='px-2 py-1 text-sm h-fit border-[1px] border-black dark:border-darkgrey hover:bg-offwhite dark:hover:bg-darkaccent'
                            onClick={() => {
                                window.open(
                                    `https://factorypure.myshopify.com/admin/draft_orders/${order.id}`,
                                    '_blank'
                                )
                            }}
                        >
                            Edit
                        </button>
                        {actions.length > 0 && (
                            <div className='js-order__actions relative'>
                                <button
                                    className='js-order__actions flex gap-[8px] items-center px-[16px] py-[8px] hover:bg-offwhite dark:hover:bg-darkaccent rounded'
                                    onClick={() => {
                                        !showActions ? setShowActions(true) : setShowActions(false)
                                    }}
                                >
                                    <span className='js-order__actions'>. . .</span>
                                    <AiFillCaretDown className='pointer-events-none' />
                                </button>
                                {showActions && (
                                    <div className='js-order__actions absolute flex flex-col top-[calc(100%+4px)] right-0 min-w-[200px] p-[8px] bg-white dark:bg-darkaccent border-[1px] dark:border-darkgrey rounded shadow-small'>
                                        {actions.map((action: any) => (
                                            <p
                                                onClick={action.action}
                                                className='p-[8px] w-[100%] cursor-pointer dark:hover:bg-darkness rounded'
                                            >
                                                {action.name}
                                            </p>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                {(user.type === 'Admin' || user.type === 'SuperAdmin') && draftOrderEventsPrivate.length > 0 && (
                    <div>
                        <PrivateEvent
                            events={draftOrderEventsPrivate}
                            setViewModal={setShowDraftOrderEventsPrivate}
                            userList={userList}
                        />
                    </div>
                )}
                <span className='dark:text-offwhite font-light text-[14px]'>{renderCreatedAt()}</span>
            </div>
            {order.order_id && (
                <div className='grid w-[100%] bg-white dark:bg-darkaccent shadow-small rounded dark:text-offwhite [&>*]:p-[16px] [&>*]:border-b-[1px] [&>*]:dark:border-darkgrey [&>:last-child]:border-0'>
                    <div className='bg-[#007a5c] dark:bg-accent text-white rounded-t dark:text-black'>Completed!</div>
                    <div>
                        <span className='dark:text-offwhite'>
                            Order Created on {draftOrderCreatedOn(order.completed_at)} at{' '}
                            {draftOrderCreatedTime(order.completed_at)}
                        </span>
                        <button
                            className='px-[16px] py-[8px]  dark:hover:bg-darkaccent text-blue dark:text-accent hover:underline '
                            onContextMenu={() => {
                                window.open(`/orders/${order.order_id}`)
                            }}
                            onClick={() => {
                                ;(window.event as MouseEvent).ctrlKey
                                    ? window.open(`/orders/${order.order_id}`)
                                    : navigate(`/orders/${order.order_id}`)
                            }}
                        >
                            View Order
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}
