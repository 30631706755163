import { useNavigate } from 'react-router-dom'
import { LuAngry } from 'react-icons/lu'

export default function NotFound() {
    const navigate = useNavigate()

    return (
        <div className='flex flex-col items-center justify-center mt-32 '>
            <div className='flex items-center dark:text-white text-[72px] mb-[-12px]'>
                4<LuAngry />4
            </div>
            <div className='text-[24px] dark:text-white'>Unfortunately, this page does not exist.</div>
            <div className='flex gap-8 mt-[22px] font-bai'>
                <button
                    className='bg-blue dark:bg-accent text-white dark:text-black px-[18px] py-[8px] rounded text-[24px] font-bold '
                    onClick={() => navigate(-1)}
                >
                    GO BACK
                </button>
                <button
                    className='bg-blue dark:bg-accent text-white dark:text-black px-[18px] py-[8px] rounded text-[24px] font-bold '
                    onClick={() => navigate('/home')}
                >
                    GO HOME
                </button>
            </div>
        </div>
    )
}
