import { Icon, Value } from '@radix-ui/react-select'
import { FaAngleDown, FaAngleUp } from 'react-icons/fa'
import Input from '../../procurement/components/Input'
import { useState } from 'react'
import { cn, vFetch } from '../../helpers'
import IconDropDownModal from './IconDropDownModal'
import { iconMap } from '../constants'
import { IoPerson } from 'react-icons/io5'

export default function CreateDepartmentModal({ setShowCreateDepartmentModal, icon, getDepartments }: any) {
    const [showIconDropdownModal, setShowIconDropdownModal] = useState(false)
    const [newDepartment, setNewDepartment] = useState('')
    const [selectedIcon, setSelectedIcon] = useState<undefined | string>()
    const isEdited = newDepartment ? true : false //
    const currentIcon = selectedIcon || icon //
    const Icon = iconMap[currentIcon as keyof typeof iconMap] || IoPerson //

    const handleClick = () => {
        setShowIconDropdownModal((prev) => !prev)
    }

    const handleCancel = () => {
        setShowCreateDepartmentModal(false)
    }

    const handleCreateDepartment = () => {
        vFetch('/v1/knowledge/departments', {
            method: 'POST',
            body: JSON.stringify({ label: newDepartment, icon: currentIcon }),
            cb: (res: any) => {
                getDepartments()
                setShowCreateDepartmentModal(false)
            },
        })
    }

    return (
        <div className='flex flex-col mt-0 justify-between items-end'>
            <div className='flex gap-1 items-stretch mt-0  mr-3'>
                <div className='flex gap-0.5 items-center bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-1 rounded-[4px]'>
                    <button onClick={handleClick} className='flex items-center gap-0.5'>
                        <Icon className='text-blue dark:text-accent text-sm' />
                        {showIconDropdownModal ? (
                            <FaAngleUp className='text-blue dark:text-offwhite w-5' />
                        ) : (
                            <FaAngleDown className='text-blue dark:text-offwhite w-5' />
                        )}
                    </button>
                </div>

                <Input
                    type='text'
                    id={'label'}
                    name='department'
                    value={newDepartment}
                    onChange={({ target }) => {
                        setNewDepartment(target.value)
                    }}
                    className='h-5'
                />
            </div>

            {showIconDropdownModal && <IconDropDownModal setSelectedIcon={setSelectedIcon} />}

            <div className='flex gap-1 mr-3'>
                <button
                    disabled={!isEdited}
                    onClick={handleCreateDepartment}
                    className={cn(
                        !isEdited && 'opacity-60', // changes of opacity of save button if no changes have been made
                        `mt-1 mb-4 text-xs bg-blue text-offwhite dark:bg-accent dark:text-darkaccent rounded px-1`
                    )}
                >
                    SAVE
                </button>
                <button
                    onClick={handleCancel}
                    className='mt-1 mb-4 text-xs text-white bg-darkgrey text:white dark:text-offwhite rounded px-1'
                >
                    CANCEL
                </button>
            </div>
        </div>
    )
}
