import { useEffect, useState } from 'react'
import { AiFillInfoCircle } from 'react-icons/ai'
import { sendToast, sortByAlphanumeric, vFetch } from '../helpers'
import LabelWithInfo from '../productSetupV2/components/LabelWithInfo'

const NO_DELETE_TEMPLATES = ['Purchase Order']
export default function ProcurementEmailTemplates() {
    const companyProps = {
        Name: '{{ company.name }}',
    }
    const blankTemplate: ProcurementEmailTemplateType = {
        name: '',
        greeting: 'Hi {{ company.name }},',
        subject: '',
        body: '',
        belongs_to: 'team',
    }
    const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(0)
    const [emailTemplates, setEmailTemplates] = useState<ProcurementEmailTemplateType[]>([])
    const [selectedTemplate, setSelectedTemplate] = useState(blankTemplate)
    const [edited, setEdited] = useState(false)
    const [newMode, setNewMode] = useState(false)
    const [needsRefresh, setNeedsRefresh] = useState('refresh')
    const templateTypeOptions = ['team', 'user']
    useEffect(() => {
        if (emailTemplates.length > 0 && !newMode) {
            setSelectedTemplate(emailTemplates[selectedTemplateIndex])
        }
    }, [selectedTemplateIndex, newMode])
    useEffect(() => {
        if (JSON.stringify(emailTemplates[selectedTemplateIndex]) !== JSON.stringify(selectedTemplate)) {
            setEdited(true)
        } else {
            setEdited(false)
        }
    }, [selectedTemplate])
    useEffect(() => {
        if (emailTemplates.length === 0) {
            setNewMode(true)
        } else {
            setNewMode(false)
        }
    }, [emailTemplates])
    useEffect(() => {
        if (needsRefresh.length > 0) {
            vFetch(`/v1/procurement/emailTemplates`, {
                cb: (res: any) => {
                    if (res.success) {
                        setEmailTemplates(
                            (res.emailTemplates as ProcurementEmailTemplateType[]).sort((a, b) =>
                                sortByAlphanumeric(a, b, 'name')
                            )
                        )
                    }
                },
            })
            setNeedsRefresh('')
        }
    }, [needsRefresh])
    const copyToClipboard = (value: string) => {
        navigator.clipboard.writeText(value).then(() => sendToast({ message: 'Copied!' }))
    }
    const handleSubmit = () => {
        if (selectedTemplate.id) {
            vFetch(`/v1/procurement/emailTemplates`, {
                method: 'PUT',
                body: JSON.stringify(selectedTemplate),
                cb: (res: any) => {
                    if (res.success) {
                        setNewMode(false)
                        setNeedsRefresh('liveResults')
                        setSelectedTemplateIndex(0)
                        setEdited(false)
                    }
                },
            })
        } else {
            vFetch(`/v1/procurement/emailTemplates`, {
                method: 'POST',
                body: JSON.stringify(selectedTemplate),
                cb: (res: any) => {
                    if (res.success) {
                        setNewMode(false)
                        setNeedsRefresh('liveResults')
                        setSelectedTemplateIndex(0)
                        setEdited(false)
                    }
                },
            })
        }
    }
    const handleDelete = () => {
        const confirmation = window.confirm('Are you sure?')
        if (confirmation) {
            vFetch(`/v1/procurement/emailTemplates`, {
                method: 'DELETE',
                body: JSON.stringify({ id: selectedTemplate.id }),
                cb: (res: any) => {
                    if (res.success) {
                        setNeedsRefresh('liveResults')
                        setSelectedTemplateIndex(0)
                    }
                },
            })
        }
    }
    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
        setSelectedTemplate({
            ...selectedTemplate,
            [target.name]: target.type === 'checkbox' ? (target as HTMLInputElement).checked : target.value,
        })
    }
    const createNewTemplate = () => {
        setSelectedTemplate(blankTemplate)
        setNewMode(true)
    }
    const handleCancel = () => {
        setSelectedTemplateIndex(0)
        setNewMode(false)
    }
    return (
        <div className='flex flex-col'>
            <h1 className='uppercase text-[24px] mb-[16px] tracking-[2px] font-[300] dark:text-offwhite'>
                {newMode ? 'Create Email Template' : 'Edit Email Templates'}
            </h1>
            <div className='flex gap-[8px] items-center mb-[8px]'>
                {!newMode && (
                    <select
                        className='bg-lightgrey focus:outline-none dark:text-white dark:bg-faintplus p-[4px] rounded-[4px]'
                        value={selectedTemplateIndex}
                        onChange={({ target }) => setSelectedTemplateIndex(parseInt(target.value))}
                    >
                        {emailTemplates.map((e, index) => (
                            <option className='dark:bg-darkness/90' key={e.name} value={index}>
                                {e.name}
                            </option>
                        ))}
                    </select>
                )}
                {newMode ? (
                    <>
                        {emailTemplates.length > 0 && (
                            <button
                                onClick={handleCancel}
                                className='py-[4px] px-[16px] rounded-[4px] bg-red text-white font-bold ml-auto'
                            >
                                CANCEL
                            </button>
                        )}
                    </>
                ) : (
                    <button onClick={createNewTemplate} className='dark:text-offwhite font-[600] ml-auto'>
                        + Create New Template
                    </button>
                )}
            </div>
            <div className='flex gap-[16px]'>
                <div className='p-[16px] w-[70%] shadow-small rounded-[4px] flex flex-col gap-[8px] dark:bg-darkaccent relative'>
                    <div className='flex gap-[8px] w-full'>
                        <div className='flex flex-col gap-[4px] w-2/3'>
                            <label
                                htmlFor='name'
                                className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                            >
                                Template Name
                            </label>
                            <input
                                name='name'
                                onChange={handleChange}
                                id='name'
                                className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                value={selectedTemplate.name}
                            />
                        </div>
                        <div className='flex flex-col gap-[4px] w-1/3'>
                            <LabelWithInfo
                                htmlFor='belongs_to'
                                info='This determines the visibility of the template. Team indiciates that anyone can use this template, and User indicates that only you can use this template.'
                                label='Belongs To'
                            />
                            <select
                                id='belongs_to'
                                className='bg-lightgrey capitalize focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                value={selectedTemplate.belongs_to || templateTypeOptions[0]}
                                onChange={({ target }) => {
                                    setSelectedTemplate({
                                        ...selectedTemplate,
                                        belongs_to: target.value as 'team' | 'user',
                                    })
                                }}
                            >
                                {templateTypeOptions.map((o) => (
                                    <option className='dark:bg-darkness/90' value={o.toLowerCase()}>
                                        {o}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    {!newMode && !NO_DELETE_TEMPLATES.includes(selectedTemplate.name) && (
                        <button
                            onClick={handleDelete}
                            className='absolute top-0 right-0 py-[2px] px-[8px] font-bold text-[12px] bg-red text-white rounded-[4px]'
                        >
                            DELETE
                        </button>
                    )}
                    <div className='flex flex-col gap-[4px]'>
                        <label
                            htmlFor='subject'
                            className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                        >
                            Subject
                        </label>
                        <input
                            name='subject'
                            onChange={handleChange}
                            id='subject'
                            className='bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                            value={selectedTemplate.subject}
                        />
                    </div>
                    <div className='flex flex-col gap-[4px]'>
                        <label
                            htmlFor='html'
                            className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                        >
                            Body
                        </label>
                        <div className='border-grey dark:border-darkgrey border rounded-[4px] p-[8px]'>
                            <div className='flex flex-col gap-[4px] mb-[8px]'>
                                <label
                                    htmlFor='greeting'
                                    className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                                >
                                    Greeting
                                </label>
                                <input
                                    name='greeting'
                                    id='greeting'
                                    value={selectedTemplate.greeting}
                                    className='bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px] w-full'
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='flex flex-col gap-[4px] mb-[8px]'>
                                <label
                                    htmlFor='html'
                                    className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                                >
                                    Main Content
                                </label>
                                <textarea
                                    name='body'
                                    onChange={handleChange}
                                    id='body'
                                    className='w-full max-w-none bg-lightgrey border-none focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px] !mb-0'
                                    value={selectedTemplate.body}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-[30%] p-[16px] rounded-[4px] shadow-small dark:bg-darkaccent'>
                    <div className='relative'>
                        <h2 className='font-bold uppercase text-[14px] dark:text-offwhite leading-[1] mb-[8px] text-darkgrey flex gap-[8px] items-center peer'>
                            Templating Helper
                            <AiFillInfoCircle className='peer cursor-help' />
                            <div className='dark:bg-darkaccent opacity-0 peer-hover:opacity-100 absolute pointer-events-none top-[24px] z-[5] bg-white shadow-small p-[8px] rounded-[4px] font-normal normal-case'>
                                <p className='mb-[8px]'>Email templates have access to the company resource.</p>
                                <p>
                                    Click these properties to copy them to your clipboard, then paste the shortcode into
                                    the editor to insert dynamic content in your email.
                                </p>
                            </div>
                        </h2>
                        <h3 className='font-bold text-darkgrey text-[12px] dark:text-offwhite uppercase border-b border-darkgrey mb-[8px]'>
                            Company Properties
                        </h3>
                        <div className='flex flex-wrap gap-[8px] mb-[8px]'>
                            {Object.entries(companyProps).map(([key, val]) => (
                                <button
                                    key={val}
                                    className='py-[2px] px-[8px] rounded-[4px] border border-grey dark:border-fire dark:text-fire font-[600] block cursor-pointer'
                                    onClick={() => copyToClipboard(val)}
                                >
                                    {key}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {edited && (
                <div className='mx-auto mt-[16px] flex gap-[16px]'>
                    <button
                        className='py-[4px] px-[16px] rounded-[4px] bg-blue text-white font-bold text-[24px]'
                        onClick={handleSubmit}
                    >
                        {!newMode ? 'UPDATE' : 'CREATE'}
                    </button>
                </div>
            )}
        </div>
    )
}

export type ProcurementEmailTemplateType = {
    id?: string | number
    name: string
    greeting: string
    subject: string
    body: string
    belongs_to: 'team' | 'user'
    user_id?: string | number
    store_id?: string | number
}
