import { useEffect, useState } from 'react'
import { BiLinkExternal } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import { vFetch } from '../helpers'
import { useQuery } from '@tanstack/react-query'

export default function ScrapeModule() {
    const underCutsQuery = useQuery({
        queryKey: ['undercuts', 'homeScreen'],
        queryFn: () =>
            vFetch(`/v1/scrape/undercuts`, {
                cb: (res) => {
                    if (!res.success) {
                        return []
                    }
                    return res.undercuts
                },
                catchCb: () => [],
            }),
    })
    const undercuts = underCutsQuery?.data || []
    return (
        <div className='p-[16px] rounded-[4px] shadow-small dark:bg-darkaccent h-[350px] flex flex-col'>
            <Link to={'/scrape?undercut=true'} className='text-fire font-bold mb-[8px] flex gap-[4px] items-center'>
                Undercuts <BiLinkExternal />
            </Link>
            <div className='h-full overflow-auto'>
                {underCutsQuery.isLoading ? (
                    <p>Loading...</p>
                ) : (
                    <>
                        {undercuts.map((u) => (
                            <div
                                key={u.lowest_price.price + u.fp_scrape_price.price}
                                className='grid grid-cols-[2fr_1fr_1fr] p-[8px] border-b border-lightgrey dark:border-darkgrey'
                            >
                                <p className='dark:text-offwhite font-bold'>{u.search_term}</p>
                                <div>
                                    <p className='dark:text-offwhite font-bold'>{u.fp_scrape_price.price}</p>
                                    <p className='uppercase text-[10px] dark:text-offwhite'>FactoryPure</p>
                                </div>
                                <div>
                                    <p className='dark:text-offwhite font-bold'>{u.lowest_price.price}</p>
                                    <p className='uppercase text-[10px] dark:text-offwhite'>{u.lowest_price.vendor}</p>
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </div>
        </div>
    )
}
