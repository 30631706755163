import { useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useShopifyGroupNamesQuery, useShopifyGroupOptionsQuery } from '../../api/useQueries'
import { formatVariantOptions } from '../../helpers'

import { FaCaretUp, FaPen } from 'react-icons/fa'
import { Button } from '../../../custom_components/component_Basics/Button'
import { FetchingSpinner } from '../../../custom_components/FetchingSpinner'
import { cn } from '../../../helpers'
import InputV2 from '../InputV2'

export default function NewShopifyProductInputs({
    setProductSetupFields,
    creationType,
    setCreationType,
    hasVariants,
    setHasVariants,
    optionValues,
    handleCreate,
    setShowRelatedVariants,
    isPending,
}: {
    setProductSetupFields: any
    creationType: any
    setCreationType: any
    hasVariants: boolean
    setHasVariants: any
    optionValues: any
    handleCreate: any
    setShowRelatedVariants: any
    isPending: boolean
}) {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm<IFormValues>({
        values: {
            title: '',
            custom_group: '',
            group_name_1: '',
            group_name_2: '',
            group_name_3: '',
            group_value_1: '',
            group_value_2: '',
            group_value_3: '',
            option_value_ids: '',
        },
    })
    const watchCustomGroup = watch('custom_group', '')

    const groupNameRef = useRef<HTMLDivElement>(null)
    const shopifyGroupNameSearchRef = useRef<HTMLInputElement>(null)
    const [shopifyGroupNameSearch, setShopifyGroupNameSearch] = useState('')
    const [showGroupNames, setShowGroupNames] = useState(false)
    const [allowNewGroup, setAllowNewGroup] = useState(false)
    const [hasOptions, setHasOptions] = useState(optionValues?.length > 0 ? true : false)

    const shopifyGroupNamesQuery = useShopifyGroupNamesQuery()
    const { groupNames: shopifyGroupNames }: { groupNames: string[] } = shopifyGroupNamesQuery?.data || {}
    const shopifyGroupOptionsQuery = useShopifyGroupOptionsQuery(watchCustomGroup)
    const { groupOptions: shopifyGroupOptions }: { groupOptions: string[] } = shopifyGroupOptionsQuery?.data || {}

    const filteredShopifyGroupNames = shopifyGroupNames
        ?.filter((groupName: any) => groupName)
        ?.filter((groupName: any) => {
            return groupName.toLowerCase().includes(shopifyGroupNameSearch?.toLowerCase())
        })

    // useEffect(() => {
    //     if (creationType === 'grouped') {
    //         ;(groupNameRef.current?.querySelector('input') as HTMLElement).focus()
    //     }
    // }, [allowNewGroup])
    // const handleToggleGroupNameEdit = (e: any) => {
    //     e.preventDefault()
    //     setShowGroupNames((toggled) => {
    //         if (toggled) {
    //             return false
    //         }

    //         ;(groupNameRef.current?.querySelector('input') as HTMLElement).focus()
    //         return true
    //     })
    // }

    const variantMapOptions = formatVariantOptions(optionValues)

    interface IFormValues {
        title: string
        custom_group: string
        group_name_1: string
        group_name_2: string
        group_name_3: string
        group_value_1: string
        group_value_2: string
        group_value_3: string
        option_value_ids: string
    }

    const onSubmit: SubmitHandler<IFormValues> = (data) => {
        const formattedData = {
            title: data.title,
            custom_group: undefined,
            // creationType === 'grouped' ? data.custom_group : undefined,
            group_names: undefined,
            // creationType === 'grouped'
            //     ? [data.group_name_1, data.group_name_2, data.group_name_3].filter((v) => v)
            //     : undefined,
            group_values: undefined,
            // creationType === 'grouped'
            //     ? [data.group_value_1, data.group_value_2, data.group_value_3].filter((v) => v)
            //     : undefined,
            option_value_ids: hasVariants || hasOptions ? data.option_value_ids : undefined,
        }
        if (!hasVariants) {
            handleCreate(formattedData)
            return
        }
        setProductSetupFields(formattedData)
        setShowRelatedVariants(true)
    }

    useEffect(() => {
        if (shopifyGroupOptions) {
            shopifyGroupOptions.map((option, index) => {
                if (index > 2) {
                    return
                }
                if (index + 1 === 1) {
                    setValue('group_name_1', option)
                }
                if (index + 1 === 2) {
                    setValue('group_name_2', option)
                }
                if (index + 1 === 3) {
                    setValue('group_name_3', option)
                }
            })
            if (shopifyGroupOptions.length === 2) {
                setValue('group_name_3', '')
            }
            if (shopifyGroupOptions.length === 1) {
                setValue('group_name_2', '')
                setValue('group_name_3', '')
            }
            if (shopifyGroupOptions.length === 0) {
                setValue('group_name_1', '')
                setValue('group_name_2', '')
                setValue('group_name_3', '')
            }
        }
    }, [shopifyGroupOptions])

    return (
        <div className='col-span-3 w-full h-full'>
            <form
                id='product_form'
                className=' gap-[16px] bg-white dark:bg-darkaccent rounded-[4px] grid grid-cols-3 p-4  '
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className=' flex flex-col gap-3'>
                    <InputV2
                        register={register}
                        label='Title'
                        id='title'
                        field='title'
                        type='text'
                        autoFocus={true}
                        required={true}
                        errors={errors}
                    />
                    {/* <div>
                        <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1] relative w-fit'>
                            Has Group
                        </label>
                        <div className='flex-col gap-2'>
                            <div className='flex gap-2'>
                                <input
                                    id='grouped'
                                    name='create_as'
                                    onChange={(event) => setCreationType(event.target.value)}
                                    value='grouped'
                                    checked={creationType === 'grouped'}
                                    type='radio'
                                ></input>
                                <label htmlFor='grouped'>Yes</label>
                            </div>
                            <div className='flex gap-2'>
                                <input
                                    id='new'
                                    name='create_as'
                                    onChange={(event) => setCreationType(event.target.value)}
                                    value='new'
                                    checked={creationType === 'new'}
                                    type='radio'
                                ></input>
                                <label htmlFor='new'>No</label>
                            </div>
                        </div>
                    </div> */}
                    <div>
                        <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1] relative w-fit'>
                            Has Variants
                        </label>
                        <div className='flex-col gap-2'>
                            <div className='flex gap-2'>
                                <input
                                    id='yes'
                                    name='has_variant'
                                    onChange={(event) => setHasVariants(event.target.value === 'yes' ? true : false)}
                                    value={'yes'}
                                    type='radio'
                                    checked={hasVariants}
                                ></input>
                                <label htmlFor='yes'>Yes</label>
                            </div>
                            <div className='flex gap-2'>
                                <input
                                    id='no'
                                    name='has_variant'
                                    onChange={(event) => setHasVariants(event.target.value === 'yes' ? true : false)}
                                    value={'no'}
                                    type='radio'
                                    checked={!hasVariants}
                                ></input>
                                <label htmlFor='no'>No</label>
                            </div>
                        </div>
                    </div>
                    {!hasVariants && (
                        <div>
                            <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1] relative w-fit'>
                                Has Options
                            </label>
                            <div className='flex-col gap-2'>
                                <div className='flex gap-2'>
                                    <input
                                        id='yes'
                                        name='has_option'
                                        onChange={(event) => setHasOptions(event.target.value === 'yes' ? true : false)}
                                        value={'yes'}
                                        type='radio'
                                        checked={hasOptions}
                                    ></input>
                                    <label htmlFor='yes'>Yes</label>
                                </div>
                                <div className='flex gap-2'>
                                    <input
                                        id='no'
                                        name='has_option'
                                        onChange={(event) => setHasOptions(event.target.value === 'yes' ? true : false)}
                                        value={'no'}
                                        type='radio'
                                        checked={!hasOptions}
                                    ></input>
                                    <label htmlFor='no'>No</label>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {/* <div
                    className={cn(
                        creationType !== 'grouped' && 'pointer-events-none opacity-60',
                        'flex flex-col gap-4'
                    )}
                >
                    <div ref={groupNameRef} className='relative'>
                        {!allowNewGroup && (
                            <Button
                                className='p-0 max-h-[20px] self-center absolute top-[-8px] hover:bg-bg1 right-0'
                                size={'sm'}
                                variant={'ghost'}
                                onClick={(e) => {
                                    e.preventDefault()

                                    setShowGroupNames(false)
                                    setAllowNewGroup(true)
                                }}
                            >
                                + add new
                            </Button>
                        )}
                        <InputV2
                            register={register}
                            id='custom_group'
                            field='custom_group'
                            label='Group Name'
                            placeholder={allowNewGroup ? 'New group name' : ''}
                            type='text'
                            disabled={!allowNewGroup}
                            required={creationType === 'grouped' ? true : false}
                            errors={errors}
                        />
                        <button
                            onClick={(e) => handleToggleGroupNameEdit(e)}
                            className='absolute top-[50%] right-[4px] z-[4]'
                        >
                            {showGroupNames ? (
                                <FaCaretUp className='text-darkgrey dark:text-offwhite' />
                            ) : (
                                <FaPen className='text-blue dark:text-accent' />
                            )}
                        </button>
                        {showGroupNames && (
                            <div className='absolute z-[30] flex flex-col top-[100%] left-0 bg-white dark:bg-darkaccent w-full shadow-small border  border-darkgrey rounded-[4px] max-h-[250px] overflow-auto'>
                                <input
                                    ref={shopifyGroupNameSearchRef}
                                    className='bg-transparent p-[8px] focus:outline-none border-b border-lightgrey dark:border-darkgrey rounded-[4px]'
                                    type='text'
                                    placeholder='Search'
                                    value={shopifyGroupNameSearch}
                                    onChange={({ target }) => setShopifyGroupNameSearch(target.value)}
                                    autoFocus={true}
                                />

                                {filteredShopifyGroupNames
                                    ?.filter((v) => v)
                                    .map((groupName) => (
                                        <button
                                            onClick={(e) => {
                                                e.preventDefault()
                                                setValue('custom_group', groupName)
                                                setShopifyGroupNameSearch('')
                                                setTimeout(() => {
                                                    setShowGroupNames(false)
                                                }, 10)
                                            }}
                                            className='text-left p-[8px] border-b border-lightgrey dark:border-darkgrey'
                                        >
                                            {groupName}
                                        </button>
                                    ))}
                            </div>
                        )}
                    </div>
                    <label htmlFor='option'>Group Option 1</label>
                    <div id='option' className='flex flex-col gap-2'>
                        <InputV2
                            register={register}
                            label={'Name'}
                            id='group_name_1'
                            field='group_name_1'
                            type='text'
                            required={creationType === 'grouped' ? true : false}
                            errors={errors}
                        />
                        <InputV2
                            register={register}
                            label={'Value'}
                            id='group_value_1'
                            field='group_value_1'
                            type='text'
                            required={creationType === 'grouped' ? true : false}
                            errors={errors}
                        />
                    </div>{' '}
                    <label htmlFor='option'>Group Option 2</label>
                    <div id='option' className='flex flex-col gap-2'>
                        <InputV2
                            register={register}
                            label={'Name'}
                            id='group_name_2'
                            field='group_name_2'
                            type='text'
                            errors={errors}
                        />
                        <InputV2
                            register={register}
                            label={'Value'}
                            id='group_value_2'
                            field='group_value_2'
                            type='text'
                            errors={errors}
                        />
                    </div>{' '}
                    <label htmlFor='option'>Group Option 2</label>
                    <div id='option' className='flex flex-col gap-2'>
                        <InputV2
                            register={register}
                            label={'Name'}
                            id='group_name_3'
                            field='group_name_3'
                            type='text'
                            errors={errors}
                        />
                        <InputV2
                            register={register}
                            label={'Value'}
                            id='group_value_3'
                            field='group_value_3'
                            type='text'
                            errors={errors}
                        />
                    </div>
                </div> */}

                <div
                    className={cn(
                        !hasOptions && !hasVariants && 'pointer-events-none opacity-60',
                        'flex flex-col gap-1'
                    )}
                >
                    <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1] relative w-fit'>
                        Variant Option
                        {(hasOptions || hasVariants) && <span className='text-danger dark:text-danger'> *</span>}
                    </label>
                    <select
                        {...register('option_value_ids', { required: hasOptions || hasVariants ? true : false })}
                        autoComplete='off'
                        className='w-full text-[16px] leading-snug bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                        id='option_value_ids'
                        name='option_value_ids'
                    >
                        <option className='dark:bg-darkness/90' value=''></option>
                        {variantMapOptions?.map((variantOption: any) => {
                            return (
                                <option
                                    disabled={variantOption.assigned_to ? true : false}
                                    className='dark:bg-darkness/90'
                                    value={variantOption.option_value_ids}
                                >
                                    {variantOption.value}
                                </option>
                            )
                        })}
                    </select>
                    {errors && errors.option_value_ids && errors.option_value_ids.type === 'required' && (
                        <span className='text-danger dark:text-lightred'>This is required</span>
                    )}
                </div>
            </form>
            <div className='border-t border-darkgrey col-span-3 mt-3 flex w-full justify-end'>
                <Button type='submit' form='product_form' className='mt-3 min-w-[100px]'>
                    {isPending ? '' : hasVariants ? 'Continue' : 'Create'}
                    {isPending && <FetchingSpinner isFetching={isPending} />}
                </Button>
            </div>
        </div>
    )
}
