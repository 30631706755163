import { buttonVariants } from 'custom_components/component_Basics/Button'
import { cn } from 'helpers'
import { useRef } from 'react'
import { Button, useDayRender } from 'react-day-picker'
import { HTMLDateInputFormatter } from 'tasksV2/helpers'

export interface DayProps {
    displayMonth: Date
    date: Date
    tasks: any
}

export function TaskCalendarDay(props: any): JSX.Element {
    const buttonRef = useRef<HTMLButtonElement>(null)
    const dayRender = useDayRender(props.date, props.displayMonth, buttonRef)
    const dueAt: Date = props.date
    dueAt.setUTCHours(8)
    const dueAtString = HTMLDateInputFormatter(dueAt)
    const currentDate = props.date
    const selectedDate = new Date(props.selectedDate?.replaceAll('-', '/') || 0)
    currentDate.setHours(0)
    selectedDate.setHours(0)
    if (dayRender.isHidden) {
        return <div role='gridcell'></div>
    }
    const isSelected = currentDate.toISOString() === selectedDate.toISOString()
    return (
        <Button
            {...dayRender.buttonProps}
            className={cn(
                dayRender.buttonProps.className,
                buttonVariants({
                    variant: 'ghost',
                    className:
                        'text-text1 dark:text-darktext1 p-[2px] hover:bg-blue h-4 hover:dark:bg-darkbg2 hover:text-white dark:hover:text-darktext1',
                }),
                'h-12 w-12 p-1 font-normal aria-selected:opacity-100',
                isSelected &&
                    'text-white dark:text-darkbg2 hover:dark:text-darkbg2 bg-accent1 dark:bg-darkaccent1 hover:bg-accent1 hover:dark:bg-darkaccent1'
            )}
            name='day'
            ref={buttonRef}
            onClick={() => props.onClick(dueAtString)}
        />
    )
}
