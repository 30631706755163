import { useSelector } from 'react-redux'
import { closePopout } from '../helpers'
import { UserInit } from '../users/users.types'
import { useEffect, useState } from 'react'

type UserButtonProps = {
    user: string
}
type UserlistDropdownProps = {
    disabled?: boolean
    style?: string
    users: UserInit[] | string[]
    selectedUsers: string[]
    setSelectedUsers: Function
}

export const UserlistDropdown = ({
    disabled = false,
    style,
    users,
    selectedUsers,
    setSelectedUsers,
}: UserlistDropdownProps) => {
    const user = useSelector<any, any>((state) => state.user)
    const [nameOrNum, setNameOrNum] = useState(Math.floor(Math.random() * Math.pow(10, 18)))
    const [show, setShow] = useState(false)

    const UserButton = ({ user }: UserButtonProps) => {
        const handleChangeAssignedTo = ({ target }: MouseEvent) => {
            ; ((target as HTMLElement).childNodes[0] as HTMLElement).classList.toggle('js-dropdown__checkbox--checked')
            const checkedBoxes = document.getElementsByClassName('js-dropdown__checkbox--checked') as any
            const checkedUsers = []
            for (let checkedBox of checkedBoxes) {
                checkedUsers.push((checkedBox?.nextElementSibling as HTMLElement).innerText)
            }
            setSelectedUsers(checkedUsers)
        }

        useEffect(() => {
            const close = (e: MouseEvent) => closePopout(e, [`user-list__${nameOrNum}`], setShow)
            window.addEventListener('click', close)
            return () => window.removeEventListener('click', close)
        }, [])

        return (
            <div
                onClick={(e: any) => handleChangeAssignedTo(e)}
                className={`user-list__${nameOrNum} js-dropdown__option flex px-[8px] py-[2px] gap-[8px] items-center cursor-pointer hover:bg-[rgb(240,240,240)] dark:hover:bg-darkaccent`}
            >
                <div
                    className={`min-w-[16px] h-[16px] border-[1px] border-[#cdcdcd] dark:border-[blueviolet] rounded js-dropdown__checkbox${selectedUsers.includes(user) ? ' js-dropdown__checkbox--checked' : ''
                        } pointer-events-none`}
                >
                    &nbsp;
                </div>
                <p className='pointer-events-none'>{user}</p>
            </div>
        )
    }

    return (
        <div className='relative'>
            <input
                style={{ opacity: disabled ? 0.75 : 1 }}
                className={`user-list__${nameOrNum} block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent rounded ${show ? 'rounded-b-none !border-b-transparent' : ''
                    } ${style}`}
                type='text'
                id={`user-list__${nameOrNum}`}
                name='user-list'
                value={`${selectedUsers}`}
                onChange={(e: any) => setSelectedUsers(e)}
                onClick={(e) => {
                    if ((e.target as HTMLElement).classList.contains(`user-list__${nameOrNum}`)) setShow(!show)
                }}
                disabled={disabled}
                readOnly
                required
            />
            {show && (
                <div
                    className={`user-list__${nameOrNum} absolute top-[calc(100%)] w-full h-[250px] overflow-x-hidden overflow-auto select-none z-index-5`}
                >
                    <div
                        className={`user-list__${nameOrNum} js-dropdown relative flex flex-col border-[1px] border-t-0 border-grey dark:border-blue bg-[white] dark:text-offwhite dark:bg-darkness pointer-events-auto select-none`}
                        onClick={() => setShow(true)}
                    >
                        {users.length > 0 &&
                            users.map((user) => <UserButton user={(user as UserInit).user_id || (user as string)} />)}
                    </div>
                </div>
            )}
        </div>
    )
}
