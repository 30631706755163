import { useEffect, useState } from 'react'
import { STATE_CODE_LIST, STATE_LIST } from '../tasks/constants'
import { useNavigate } from 'react-router-dom'
import { parseResObject, vFetch } from '../helpers'

export default function CustomerModal({
    setSelectedCustomerId = (v) => {},
    id = 'New Customer',
    returnCustomer = (v) => {},
    defaultTags = [''],
    style = '',
}) {
    const navigate = useNavigate()
    const [customer, setCustomer] = useState({
        first_name: '',
        last_name: '',
        full_name: '',
        email: '',
        phone: '',
        tags: defaultTags.join(','),
        default_address: { id: 0, country: 'United States', country_code: 'US' },
        addresses: [{ id: 0, country: 'United States', country_code: 'US' }],
    })
    const [newCustomer, setNewCustomer] = useState(customer)
    const [newTag, setNewTag] = useState('')
    const [edited, setEdited] = useState(false)

    useEffect(() => {
        if (id !== 'New Customer') {
            vFetch(`/v1/customers/${id}`, {
                cb: (res) => {
                    const resCustomer = parseResObject(res.customer)
                    if (resCustomer.tags && resCustomer.tags.split(', ').length > 1) {
                        resCustomer.tags = resCustomer.tags.split(', ').join(',')
                    }

                    setCustomer(resCustomer)
                    setNewCustomer(resCustomer)
                },
            })
        } else {
            setNewCustomer(customer)
        }
    }, [id])

    useEffect(() => {
        if (JSON.stringify(newCustomer) === JSON.stringify(customer)) {
            setEdited(false)
        } else {
            setEdited(true)
        }
    }, [newCustomer])

    function handleChange({ target }) {
        const default_address = newCustomer.default_address
        const addresses = newCustomer.addresses
        default_address.id = addresses.length
            ? addresses.find((a) => JSON.stringify(a) === JSON.stringify(default_address)).id
            : null

        if (target.name.match('default_address.')) {
            if (target.name.match('province')) {
                return setNewCustomer({
                    ...newCustomer,
                    default_address: {
                        ...default_address,
                        [target.name.replace('default_address.', '')]: target.value,
                        province_code: STATE_CODE_LIST[STATE_LIST.indexOf(target.value)],
                    },
                    addresses: addresses.map((a) => {
                        if (a.id !== default_address.id) return a
                        return {
                            ...a,
                            [target.name.replace('default_address.', '')]: target.value,
                            province_code: STATE_CODE_LIST[STATE_LIST.indexOf(target.value)],
                        }
                    }),
                })
            }
            return setNewCustomer({
                ...newCustomer,
                default_address: {
                    ...default_address,
                    [target.name.replace('default_address.', '')]: target.value,
                },
                addresses: addresses.map((a) => {
                    if (a.id !== default_address.id) return a
                    return { ...a, [target.name.replace('default_address.', '')]: target.value }
                }),
            })
        }

        setNewCustomer({ ...newCustomer, [target.name]: target.value })
    }

    function handleNewCustomer() {
        delete newCustomer.addresses[0].id
        delete newCustomer.default_address.id

        vFetch(`/v1/customers`, {
            method: 'POST',
            body: JSON.stringify({ customer: newCustomer }),
            cb: (res) => {
                if (res.success) {
                    returnCustomer(newCustomer)
                    setSelectedCustomerId()
                }
            },
        })
    }

    function handleUpdate() {
        const customerChanges = {}
        Object.keys(customer).forEach((key) => {
            if (JSON.stringify(customer[key]) !== JSON.stringify(newCustomer[key])) {
                if (key === 'default_address' && !newCustomer.addresses.length) {
                    delete newCustomer.default_address.id
                    newCustomer.default_address.country = 'United States'
                    customerChanges.addresses = [newCustomer.default_address]
                }
                customerChanges[key] = newCustomer[key]
            }
        })

        vFetch(`/v1/customers/${id}`, {
            method: 'PUT',
            body: JSON.stringify({ update: customerChanges }),
            cb: (res) => {
                if (res.success) setSelectedCustomerId()
            },
        })
    }

    function handleDelete() {
        vFetch(`/v1/customers/${id}`, {
            method: 'DELETE',
            cb: (res) => {
                if (res.success) setSelectedCustomerId()
            },
        })
    }

    return (
        <div
            className={`fixed top-[51px] left-[216px] w-[calc(100%-216px)] h-[calc(100%-51px)] bg-[rgb(0,0,0,0.5)] grid place-items-center justify-center z-[50] ${style}`}
        >
            <div className='w-[90%] h-[auto] max-h-[90%] bg-white dark:bg-darkaccent rounded-[4px] relative pt-[16px] p-[32px]'>
                <h2 className='text-[24px] font-semibold dark:text-offwhite capitalize mb-[16px]'>New Customer</h2>
                <button
                    onClick={() => setSelectedCustomerId(null)}
                    className='absolute top-0 right-0 w-[24px] h-[24px] grid place-items-center leading-[1] text-[24px] font-bold text-red'
                >
                    &times;
                </button>
                {id !== 'New Customer' && (
                    <div className='flex justify-end [&>button]:font-semibold [&>button]:rounded gap-[16px]'>
                        <button
                            className='px-[16px] py-[8px] hover:bg-offwhite dark:bg-darkness dark:hover:bg-darkness/50 dark:text-offwhite'
                            onContextMenu={() => {
                                window.open(`/tasks/new?customer_id=${id}&task_type=Sales`)
                            }}
                            onClick={() => {
                                window.event.ctrlKey
                                    ? window.open(`/tasks/new?customer_id=${id}&task_type=Sales`)
                                    : navigate(`/tasks/new?customer_id=${id}&task_type=Sales`)
                            }}
                        >
                            Create Task
                        </button>
                        <a
                            className='px-[16px] py-[8px] hover:bg-offwhite dark:bg-darkness dark:hover:bg-darkness/50 dark:text-offwhite rounded'
                            href='https://factorypure.myshopify.com/admin/draft_orders/new'
                        >
                            Create Draft Order
                        </a>
                    </div>
                )}
                <h2 className='text-[24px] font-bold dark:text-offwhite'>{customer.full_name || customer.email}</h2>
                <div className='flex gap-[32px] wrap'>
                    <div>
                        <h3 className='text-[20px] font-semibold dark:text-offwhite'>Contact Information</h3>
                        <div className='flex gap-[8px]'>
                            <div className='relative'>
                                <label className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'>
                                    First Name {id === 'New Customer' && '*'}
                                </label>
                                <input
                                    type='text'
                                    className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                    name='first_name'
                                    value={newCustomer.first_name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='relative'>
                                <label className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'>
                                    Last Name {id === 'New Customer' && '*'}
                                </label>
                                <input
                                    type='text'
                                    className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                    name='last_name'
                                    value={newCustomer.last_name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className='relative'>
                            <label className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'>
                                Email {id === 'New Customer' && '*'}
                            </label>
                            <input
                                type='text'
                                className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                name='email'
                                value={newCustomer.email ? newCustomer.email : ''}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <div className='relative'>
                                <label className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'>
                                    Phone
                                </label>
                                <input
                                    className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                    name='phone'
                                    value={newCustomer.phone}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div>
                            <form
                                className='relative'
                                onSubmit={(event) => {
                                    event.preventDefault()
                                    newTag.trim().length > 0 &&
                                        setNewCustomer({
                                            ...newCustomer,
                                            tags: newCustomer.tags ? newCustomer.tags + `,${newTag.trim()}` : newTag,
                                        })
                                    setNewTag('')
                                }}
                            >
                                <label className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'>
                                    New Tag (press enter to save)
                                </label>
                                <input
                                    className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                    name='tag'
                                    value={newTag}
                                    onChange={(event) => {
                                        setNewTag(event.target.value)
                                    }}
                                />
                            </form>
                            <div className='flex flex-wrap gap-[4px] mt-[8px] max-w-[350px]'>
                                {newCustomer.tags &&
                                    newCustomer.tags.split(',').map((tag) => (
                                        <div className='p-[8px] flex'>
                                            <p className='relative dark:text-offwhite bg-[lightgrey] dark:bg-darkness px-[8px] text-[14px] rounded'>
                                                {tag}
                                                <button
                                                    className="absolute grid bg-grey top-[-10px] right-[-7px] h-[16px] w-[16px] leading-1 content-['x'] rounded-full"
                                                    onClick={() => {
                                                        setNewCustomer({
                                                            ...newCustomer,
                                                            tags: newCustomer.tags
                                                                .split(',')
                                                                .filter((cTag) => cTag !== tag)
                                                                .join(','),
                                                        })
                                                    }}
                                                    aria-label='delete tag'
                                                >
                                                    <span className='absolute top-[-3px] right-[4px] leading-1 h-[0] font-semibold text-black'>
                                                        x
                                                    </span>
                                                </button>
                                            </p>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <h3 className='text-[20px] font-semibold dark:text-offwhite'>Default Shipping Address</h3>
                            <div className='flex gap-[8px]'>
                                <div className='relative'>
                                    <label className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'>
                                        First Name
                                    </label>
                                    <input
                                        type='text'
                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                        name='default_address.first_name'
                                        value={newCustomer.default_address.first_name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='relative'>
                                    <label className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'>
                                        Last Name
                                    </label>
                                    <input
                                        type='text'
                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                        name='default_address.last_name'
                                        value={newCustomer.default_address.last_name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className='relative'>
                                    <label className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'>
                                        Phone
                                    </label>
                                    <input
                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                        name='default_address.phone'
                                        value={newCustomer.default_address.phone}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className='relative'>
                                    <label className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'>
                                        Company
                                    </label>
                                    <input
                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                        name='default_address.company'
                                        value={newCustomer.default_address.company}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='relative'>
                                <label className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'>
                                    Street Address
                                </label>
                                <input
                                    type='tel'
                                    className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                    name='default_address.address1'
                                    value={newCustomer.default_address.address1}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='flex gap-[8px]'>
                                <div className='relative'>
                                    <label className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'>
                                        City
                                    </label>
                                    <input
                                        type='text'
                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                        name='default_address.city'
                                        value={newCustomer.default_address.city}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='relative'>
                                    <label className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'>
                                        State
                                    </label>
                                    <select
                                        type='text'
                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] py-[5px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                        name='default_address.province'
                                        value={
                                            newCustomer.default_address.province ||
                                            STATE_LIST[
                                                STATE_CODE_LIST.indexOf(newCustomer.default_address.province_code)
                                            ]
                                        }
                                        onChange={handleChange}
                                    >
                                        {STATE_LIST.map((s) => (
                                            <option key={s} value={s}>
                                                {s}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='relative'>
                                    <label className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'>
                                        Zip
                                    </label>
                                    <input
                                        type='text'
                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                        name='default_address.zip'
                                        value={newCustomer.default_address.zip}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='relative'>
                                    <label className='font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey'>
                                        Country
                                    </label>
                                    <input
                                        type='text'
                                        className='block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'
                                        name='default_address.country'
                                        disabled
                                        value={newCustomer.default_address.country}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        {newCustomer.addresses.length > 1 && (
                            <div className='dark:text-offwhite mt-[16px]'>
                                <h4 className='text-[18px] font-semibold'>Other Addresses</h4>
                                <div className='flex gap-[16px]'>
                                    {id !== 'New Customer' &&
                                        newCustomer.addresses
                                            .filter(
                                                (a) => JSON.stringify(a) !== JSON.stringify(newCustomer.default_address)
                                            )
                                            .map((a) => (
                                                <div className='block bg-[whitesmoke] dark:bg-darkness/20 p-[8px] rounded'>
                                                    <p>
                                                        {a.first_name ? a.first_name : ''}
                                                        {a.first_name ? (a.last_name ? ' ' + a.last_name : '') : ''}
                                                    </p>
                                                    {a.phone ? <p>{a.phone}</p> : ''}
                                                    <p>{a.address1}</p>
                                                    <span>{a.city}</span>
                                                    <span>
                                                        {a.city
                                                            ? a.province
                                                                ? ', ' + a.province
                                                                : a.province_code
                                                                  ? ', ' +
                                                                    STATE_LIST[STATE_CODE_LIST.indexOf(a.province_code)]
                                                                  : a.province
                                                            : STATE_LIST[STATE_CODE_LIST.indexOf(a.province_code)]}
                                                    </span>
                                                    <span> {a.zip}</span>
                                                    <p>{a.country}</p>
                                                    <div className='flex justify-center mt-[8px]'>
                                                        <button
                                                            className='p-[8px] m-auto text-[14px] text-black font-semibold bg-fire rounded'
                                                            onClick={(event) => {
                                                                event.preventDefault()
                                                                setNewCustomer({ ...newCustomer, default_address: a })
                                                            }}
                                                        >
                                                            Make Default
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {edited && (
                    <div className={`flex justify-center p-[16px] mt-[16px]`}>
                        {id !== 'New Customer' ? (
                            <button
                                className='p-[8px] bg-blue text-white font-semibold dark:bg-accent dark:text-black rounded shadow-small'
                                onClick={handleUpdate}
                            >
                                Save Changes
                            </button>
                        ) : (
                            (newCustomer.first_name || newCustomer.last_name || newCustomer.email) && (
                                <button
                                    className='p-[8px] bg-blue text-white font-semibold dark:bg-accent dark:text-black rounded shadow-small'
                                    onClick={handleNewCustomer}
                                >
                                    Add Customer
                                </button>
                            )
                        )}
                    </div>
                )}
                {customer.orders_count === 0 && (
                    <div className='flex pt-[32px] justify-end'>
                        <button className='p-[8px] bg-red text-white rounded font-semibold' onClick={handleDelete}>
                            DELETE
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}
