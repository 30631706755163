import { Button } from 'custom_components/component_Basics/Button'

export default function AreYouSureModal({
    message,
    onConfirm,
    onDeny,
}: {
    message?: string
    onConfirm: () => void
    onDeny: () => void
}) {
    return (
        <div className='fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black/50 z-50'>
            <div className='bg-white dark:bg-darkbg1 rounded shadow-md p-4'>
                <h2 className='font-bold mb-2'>{message || 'Are you sure?'}</h2>
                <div className='flex gap-2 items-center justify-center'>
                    <Button className='font-bold' onClick={onDeny}>
                        Cancel
                    </Button>
                    <Button
                        className='bg-accent1 dark:bg-darkaccent1 text-white dark:text-darkbg1 font-bold'
                        onClick={onConfirm}
                    >
                        Confirm
                    </Button>
                </div>
            </div>
        </div>
    )
}
