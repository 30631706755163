import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { vFetch } from '../../helpers'
import { FilterGroupType, FilterType, ShopifyProduct } from '../ProductSetup.types'
import { FaInfo } from 'react-icons/fa'
import LabelWithInfo from './LabelWithInfo'

export default function NewFilterModal({
    product,
    loadingModal,
    setShowModal,
    handleCreateFilterValue,
}: {
    product: ShopifyProduct
    loadingModal: boolean
    setShowModal: Dispatch<SetStateAction<boolean>>
    handleCreateFilterValue: Function
}) {
    const modalRef = useRef(null)
    const [filterGroups, setFilterGroups] = useState<FilterGroupType[]>([])
    const [showGroups, setShowGroups] = useState(false)
    const [filter, setFilter] = useState<FilterGroupType>({
        name: '',
        feature_template: '{{ value }}',
        type: 'checkbox',
        disjunctive: true,
        is_feature: false,
        match_score: 0,
    })
    const [productFitlerValue, setProductFilterValue] = useState('')
    const [isValid, setIsValid] = useState(false)

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement }) => {
        setFilter((previousState) => ({
            ...previousState,
            [target.name]: target.value,
        }))
    }
    const handleChangeCheckbox = ({ target }: { target: HTMLInputElement }) => {
        setFilter((previousState) => ({
            ...previousState,
            [target.name]: target.checked,
        }))
    }
    const handleChangeNumber = ({ target }: { target: HTMLInputElement }) => {
        setFilter((previousState) => ({
            ...previousState,
            [target.name]: parseInt(target.value),
        }))
    }

    useEffect(() => {
        vFetch(`/v2/products/groups?exclude_product=${product.id}`, {
            cb: (res: any) => {
                if (res.success) {
                    setFilterGroups(res.filterGroups || [])
                }
            },
        })
        const listenToWindow = (e: MouseEvent) => {
            if (modalRef.current === e.target) {
                setShowModal(false)
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    useEffect(() => {
        if (filter.name.length && filter.feature_template.includes('{{ value }}')) {
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    }, [filter])

    return (
        <div
            ref={modalRef}
            className='fixed top-0 left-0 z-[50] w-full h-full bg-[rgb(0,0,0,0.5)] grid place-items-center'
        >
            <div className='max-w-[90%] max-h-[90%] overflow-auto bg-white dark:bg-darkaccent p-[32px] rounded-[4px] shadow-small relative'>
                <button
                    onClick={() => setShowModal(false)}
                    className='absolute top-[0px] right-[8px] text-red dark:text-lightred font-bold text-[32px] leading-[1]'
                >
                    &times;
                </button>
                <h2 className='text-[24px] font-light tracking-[2px] dark:text-offwhite uppercase leading-[1] mb-[16px]'>
                    New Filter Value
                </h2>
                <div className='flex flex-col gap-[8px] w-[300px]'>
                    <div className='flex flex-col gap-[4px] w-full'>
                        <LabelWithInfo
                            label='Group Name'
                            htmlFor='group_name'
                            info='This name will appear on the collection page as a filter option.'
                        />
                        <div className='relative w-full'>
                            <input
                                id='name'
                                type='text'
                                value={filter.name}
                                name='name'
                                autoComplete='off'
                                className='py-[2px] px-[8px] w-full focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                onChange={handleChange}
                                onFocus={() => setShowGroups(true)}
                                onBlur={() =>
                                    setTimeout(() => {
                                        setShowGroups(false)
                                    }, 100)
                                }
                            />
                            {showGroups &&
                                filterGroups.filter(
                                    (fg) =>
                                        fg.name.toLowerCase().includes(filter.name.toLowerCase()) &&
                                        fg.name.toLowerCase() !== filter.name.toLowerCase()
                                ).length > 0 && (
                                    <div className='max-h-[100px] shadow-small z-[5] overflow-auto bg-white dark:bg-darkness p-[8px] flex flex-col gap-[4px] absolute top-[100%] w-full'>
                                        {filterGroups
                                            .filter(
                                                (fg) =>
                                                    fg.name.toLowerCase().includes(filter.name.toLowerCase()) &&
                                                    fg.name.toLowerCase() !== filter.name.toLowerCase()
                                            )
                                            .map((fg) => (
                                                <button
                                                    className='flex dark:text-white'
                                                    onClick={() => setFilter({ ...filter, name: fg.name })}
                                                >
                                                    {fg.name}
                                                </button>
                                            ))}
                                    </div>
                                )}
                        </div>
                    </div>
                    {!filterGroups.find((fg) => fg.name === filter.name) && (
                        <>
                            <div className='flex gap-[8px]'>
                                <div className='flex flex-col gap-[4px] w-full'>
                                    <LabelWithInfo
                                        label='Type'
                                        htmlFor='type'
                                        info='This is the type of selector within the filter group. Checkboxes allow multiple selections, radios allow one, and ranges allow a high and low value.'
                                    />
                                    <select
                                        id='type'
                                        value={filter.type}
                                        name='type'
                                        className='py-[2px] px-[8px] focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                        onChange={handleChange}
                                    >
                                        <option value='checkbox'>Checkbox</option>
                                        <option value='radio'>Radio</option>
                                        <option value='range'>Range</option>
                                    </select>
                                </div>
                                {filter.type === 'range' && (
                                    <div className='flex flex-col gap-[4px] w-full'>
                                        <label
                                            className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                                            htmlFor='type'
                                        >
                                            Unit
                                        </label>
                                        <input
                                            id='unit'
                                            type='text'
                                            value={filter.unit}
                                            name='unit'
                                            className='py-[2px] px-[8px] focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                            onChange={handleChange}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className='flex flex-col gap-[4px] w-full'>
                                <LabelWithInfo
                                    label='Feature Template'
                                    htmlFor='feature_template'
                                    info='This is the formatting for the features section on each product card. The value is added dynamically in place of {{ value }}. This field must include {{ value }}.'
                                />
                                <input
                                    id='feature_template'
                                    type='text'
                                    value={filter.feature_template}
                                    name='feature_template'
                                    className='py-[2px] px-[8px] focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='flex flex-col gap-[4px] w-full'>
                                <LabelWithInfo
                                    label='Match Score'
                                    htmlFor='match_score'
                                    info='This field indicates how closely products with the same filter value are related for the compare similiar tool. Higher scores means more products are more closely related.'
                                />
                                <input
                                    id='match_score'
                                    type='number'
                                    value={filter.match_score}
                                    name='match_score'
                                    className='py-[2px] px-[8px] focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                    onChange={handleChangeNumber}
                                />
                            </div>
                            <div className='flex gap-[4px] mt-[4px]'>
                                <div className='relative rounded-[4px] bg-lightgrey dark:bg-darkness flex items-center justify-between gap-[4px] w-full'>
                                    <LabelWithInfo
                                        label='Disjunctive'
                                        htmlFor='disjunctive'
                                        info='Indicates whether a product should match every selection or just one of the selected values. Disjunctive means that only one match is required.'
                                        labelClassName='cursor-pointer p-[8px] w-full h-full leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                                    />
                                    <input
                                        id='disjunctive'
                                        type='checkbox'
                                        checked={filter.disjunctive}
                                        name='disjunctive'
                                        className='absolute top-[50%] translate-y-[-50%] right-[8px] pointer-events-none focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                        onChange={handleChangeCheckbox}
                                    />
                                </div>
                                <div className='relative rounded-[4px] bg-lightgrey dark:bg-darkness flex items-center justify-between gap-[4px] w-full'>
                                    <LabelWithInfo
                                        label='Is Feature'
                                        htmlFor='is_feature'
                                        info='Indicates that the value should appear in the features section of each product card.'
                                        labelClassName='cursor-pointer p-[8px] w-full h-full leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                                    />
                                    <input
                                        id='is_feature'
                                        type='checkbox'
                                        checked={filter.is_feature}
                                        name='is_feature'
                                        className='absolute top-[50%] translate-y-[-50%] right-[8px] pointer-events-none focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                                        onChange={handleChangeCheckbox}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    <div className='flex flex-col gap-[4px] w-full'>
                        <label className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'>
                            Filter Value
                        </label>
                        <input
                            type='text'
                            value={productFitlerValue}
                            className='py-[2px] px-[8px] focus:outline-none bg-lightgrey dark:bg-faintplus dark:text-white rounded-[4px]'
                            onChange={({ target }) => setProductFilterValue(target.value)}
                        />
                    </div>
                    {isValid && (
                        <button
                            disabled={loadingModal}
                            onClick={() => handleCreateFilterValue(product.id, filter, productFitlerValue)}
                            className='block mx-auto py-[4px] px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase mt-[16px]'
                        >
                            {loadingModal ? 'Working...' : 'Create'}
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
}
