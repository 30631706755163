import { CustomerOrderType } from 'customersV2/Customers.types'
import { dateFormatter } from 'customersV2/helpers'
import { Link } from 'react-router-dom'
import { moneyFormatter } from 'shopifyCompetitorCatalogs/helpers'

export default function CustomerOrderRow({ order }: { order: CustomerOrderType }) {
    return (
        <div className='p-2 rounded bg-lightgrey dark:bg-darkbg2'>
            <div className='flex justify-between gap-4 pb-1 border-b border-white dark:border-darkbg1 mb-1'>
                <div>
                    <Link to={`/orders/${order.id}`}>
                        <h4 className='font-bold underline'>{order.name}</h4>
                    </Link>
                    <p>{dateFormatter(new Date(order.created_at))}</p>
                </div>
                <p className='font-bold'>{moneyFormatter(order.total_price)}</p>
            </div>
            <div className='flex flex-col gap-2'>
                {order.line_items.map((lineItem: any) => (
                    <div className='grid grid-cols-[6fr_1fr_2fr] gap-2'>
                        <p>{lineItem.title}</p>
                        <p className='ml-auto w-fit'>x{lineItem.quantity}</p>
                        <p className='ml-auto w-fit'>{lineItem.price}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}
