import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Chart from 'chart.js/auto'
import TasksModule from '../tasks/TasksModule'
import OrdersModule from '../orders/OrdersModule'
import ScrapeModule from '../scrape/ScrapeModule'
import { formatMoney, vFetch } from '../helpers'
import { useQuery } from '@tanstack/react-query'

export default function HomeScreen() {
    const user = useSelector((state) => state.user)
    const settings = useSelector((state) => state.settings)
    const salesQuery = useQuery({
        queryKey: ['orders', 'todaysSales'],
        queryFn: () => vFetch(`/v1/analytics/sales/today`),
    })
    const sales = salesQuery?.data?.sales || []
    const salesToday =
        sales.reduce((acc, curr) => {
            return acc + parseFloat(curr.total)
        }, 0) || 0
    const chartContainer = useRef(null)
    const chart = useRef(null)
    const lightOptions = {
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#4a4a4a',
                },
            },
            y: {
                min: 0,
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#4a4a4a',
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        elements: {
            line: {
                fill: false,
                borderColor: 'orange',
                tension: 0.5,
                pointStyle: false,
            },
        },
    }
    const darkOptions = {
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                    borderColor: 'white',
                },
                ticks: {
                    color: '#eeefea',
                },
            },
            y: {
                min: 0,
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#eeefea',
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
        elements: {
            line: {
                fill: false,
                borderColor: '#42EFD0',
                tension: 0.5,
                pointStyle: false,
            },
        },
    }

    if (chartContainer.current) {
        if (chart.current) {
            chart.current.destroy()
        }
        const salesPerHour = {}
        for (let i = 0; i < 24; i++) salesPerHour[i] = 0
        for (let item of sales) {
            const hour = new Date(item.created_at).getHours()
            salesPerHour[hour] += parseFloat(item.total)
        }
        chart.current = new Chart(chartContainer.current, {
            type: 'line',
            data: {
                labels: Object.keys(salesPerHour),
                datasets: [
                    {
                        data: Object.values(salesPerHour),
                    },
                ],
            },
            options: settings.theme == 'dark' ? darkOptions : lightOptions,
        })
    }

    //}, [settings.theme])
    return (
        <>
            {user && (
                <>
                    <h1 className='font-bold dark:font-normal text-[32px] leading-[1] mb-[16px] dark:text-offwhite font-bai'>
                        Welcome back, {user.firstName}
                    </h1>
                    {/* <div className="grid grid-cols-4 gap-[8px] p-[16px]">
                        <div className="text-white">
                            Tasks
                        </div>
                        <div className="text-white">
                            Sales
                        </div>
                        <div className="text-white">
                            Customers
                        </div>
                        <div className="text-white">
                            Products
                        </div>
                    </div> */}
                    <div className='grid grid-cols-2 gap-[16px]'>
                        <div className='dark:bg-darkaccent shadow-small rounded-[4px] p-[8px] h-[350px]'>
                            <p className='text-fire font-bold mb-[8px]'>
                                Today's Total Sales:{' '}
                                {salesQuery.isLoading ? 'Loading...' : <>{formatMoney(salesToday)}</>}
                            </p>
                            <div
                                class='chart-container'
                                style={{ position: 'relative', height: '302px', width: '100%' }}
                            >
                                <canvas ref={chartContainer}></canvas>
                            </div>
                        </div>
                        <TasksModule />
                        <OrdersModule />
                        <ScrapeModule />
                    </div>
                </>
            )}
        </>
    )
}
