import { Dispatch, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'

type ReactSetSelected = (state: any) => {}

type selectedReturnsActionsProps = {
    selected: any
    setSelected: Dispatch<SetStateAction<number[]>>
}

// export default function selectedReturnsActions (({selected, setSelected} as selectedReturnsActionsProps)) {
export default function SelectedReturnsActions({ selected, setSelected }: selectedReturnsActionsProps) {
    const navigate = useNavigate()
    return (
        <div className='fixed flex justify-center bottom-[32px] left-[216px] w-[calc(100%-216px)] z-index-5 pointer-events-none'>
            <div className='flex gap-[8px] justify-between bg-white dark:bg-darkaccent dark:border-[1px] dark:border-darkgrey rounded shadow-small dark:shadow-cool py-[8px] px-[16px] [&>*]:text-[14px] [&>*]:font-semibold [&>*]:dark:text-offwhite pointer-events-auto'>
                <button
                    onClick={() => {
                        navigate(`/returns/edit?returns=${selected}`)
                    }}
                    className='border-[1px] border-darkgrey px-[8px] py-[2px] rounded hover:bg-offwhite dark:hover:bg-accent dark:hover:text-black'
                >
                    Edit Return{selected.length > 1 && 's'}
                </button>
                {/* <button onClick={() => setShowNoteModal(true)} className="border-[1px] border-darkgrey px-[8px] py-[2px] rounded hover:bg-offwhite dark:hover:bg-accent dark:hover:text-black">Bulk Add Notes</button> */}
                {/* <button onClick={handleCreateShippingLabels} className="border-[1px] border-darkgrey px-[8px] py-[2px] rounded hover:bg-offwhite dark:hover:bg-accent dark:hover:text-black">Create Shipping Labels</button>
                <button onClick={handleMarkAsFulfilled} className="border-[1px] border-darkgrey px-[8px] py-[2px] rounded hover:bg-offwhite dark:hover:bg-accent dark:hover:text-black">Mark as Fulfilled</button>
                <button onClick={handleCapturePayments} className="border-[1px] border-darkgrey px-[8px] py-[2px] rounded hover:bg-offwhite dark:hover:bg-accent dark:hover:text-black">Capture Payments</button> */}
                <div className='relative'>
                    {/* <button onClick={handleDotDotDot} className={`js-dropdown border-[1px] border-darkgrey px-[8px] py-[2px] rounded hover:bg-offwhite ${showDotDotDot && "bg-offwhite"} dark:hover:bg-accent dark:${showDotDotDot && "bg-accent"} dark:${showDotDotDot && "text-black"} dark:hover:text-black`}>...</button>
                    {showDotDotDot &&
                    <div className="js-dropdown absolute bottom-[calc(100%+2px)] right-0 flex flex-col gap-[2px] bg-offwhite dark:bg-darkness dark:border-[1px] dark:border-darkgrey text-black dark:text-white  max-h-[300px] overflow-auto rounded shadow-small dark:shadow-cool">
                        {Object.keys(dotDotDotOptions).map(option =>
                            <div key={option} className="js-dropdown flex flex-col bg-white dark:bg-darkaccent">
                                <span className="js-dropdown py-[2px] px-[8px]">{!option.includes("title_null") && option}</span>
                                {Object.keys(dotDotDotOptions[option]).map(subOption => 
                                    <button key={subOption} onClick={dotDotDotOptions[option][subOption]} className="js-dropdown__option text-left hover:bg-offwhite dark:hover:bg-accent dark:hover:text-black py-[4px] px-[8px] font-normal whitespace-nowrap">
                                        {subOption}
                                    </button>
                                )}
                            </div>                   
                        )}
                    </div>} */}
                </div>
            </div>
        </div>
    )
}
