import {
    addDays,
    getDate,
    getMonth,
    getYear,
    startOfMonth,
    startOfQuarter,
    startOfWeek,
    subDays,
    subMonths,
    subQuarters,
    subWeeks,
} from 'date-fns'
import { StatusDataByUser } from './reports/CompanyStatusAnalyticsReport'
import { UserInit } from '../users/users.types'
import { DateRange } from 'react-day-picker'
import { LEAD_TYPE_OPTIONS, LIFECYCLE_STATUS_OPTIONS } from '../procurement/constants'

export type ChartType = {
    component: any
    analyticsObjects: {
        analyticsGetFunction?: Function
        label?: string
        queryOptions?: {
            tag: string
            value: string[]
            title: string
        }[]
        fieldOptions?: {
            tag: string
            value: string[]
            title: string
        }[][]
    }[]
    title: string
    size: 'small' | 'medium' | 'large'
    reportName?: string
}
export const colors = [
    '#CC6677',
    '#DDCC77',
    '#117733',
    '#88CCEE',
    '#44AA99',
    '#999933',
    '#AA4499',
    '#332288',
    '#882255',
]
export const compareColors = [
    '#CC667760',
    '#DDCC7760',
    '#11773360',
    '#88CCEE60',
    '#44AA9960',
    '#99993360',
    '#AA449960',
    '#33228860',
    '#88225560',
]

export const TIME_PERIOD_OPTIONS = ['DAY', 'WEEK', 'MONTH', 'TOTAL']

export const COMPARE_PICKER_OPTIONS = [
    {
        label: 'No selection',
    },
    {
        label: 'Previous period',
    },
    {
        label: 'Previous year',
    },
]

// Order will effect analytics report component
export const DATE_PICKER_OPTIONS = [
    {
        label: 'Today',
        function: subDays,
        compareFunction: subDays,
        values: ['0', '0'],
    },
    {
        label: 'Yesterday',
        function: subDays,
        compareFunction: subDays,
        values: ['1', '1'],
    },
    {
        label: 'Last 7 days',
        function: subDays,
        compareFunction: subDays,
        values: ['7', '1'],
    },
    {
        label: 'Last 30 days',
        function: subDays,
        compareFunction: subDays,
        values: ['30', '1'],
    },
    {
        label: 'Last 90 days',
        function: subDays,
        compareFunction: subDays,
        values: ['90', '1'],
    },
    {
        label: 'Last 365 days',
        function: subDays,
        compareFunction: subDays,
        values: ['365', '1'],
    },
    {
        label: 'Last month',
        function: subMonths,
        compareFunction: subMonths,
        values: ['1', '0'],
    },
    {
        label: 'Last 3 months',
        function: subMonths,
        compareFunction: subMonths,
        values: ['3', '0'],
    },
    {
        label: 'Last 6 months',
        function: subMonths,
        compareFunction: subMonths,
        values: ['6', '0'],
    },
    {
        label: 'Week to date',
        function: startOfWeek,
        compareFunction: subWeeks,
        values: ['1', '0'],
    },
    {
        label: 'Month to date',
        function: startOfMonth,
        compareFunction: subMonths,
        values: ['1', '0'],
    },
    {
        label: 'Quarter to date',
        function: startOfQuarter,
        compareFunction: subQuarters,
        values: ['1', '0'],
    },
]

const placeHolderData = [
    {
        timePeriod: '',
        createdCompany: 0,
    },
]

export const PLACEHOLDER_DATA_INSERT = {
    labels: placeHolderData.map((data) => data.timePeriod),
    datasets: [
        {
            label: '',
            data: placeHolderData.map((data) => data.createdCompany),
            backgroundColor: ['#ecf0f1'],
            borderColor: 'pink',
            borderWidth: 2,
        },
    ],
}

// OPTIONS

export const LEAD_TYPE_ANALYTICS_FIELDS = [
    ...LEAD_TYPE_OPTIONS.map((option: any) => {
        return {
            tag: option.tag,
            value: [option.value],
            title: option.label,
        }
    }),
    {
        tag: 'total',
        value: ['total'],
        title: 'Total',
    },
]
export const LIFECYCLE_STATUS_ANALYTICS_FIELDS = [
    ...LIFECYCLE_STATUS_OPTIONS.map((option: any) => {
        return {
            tag: option.tag,
            value: [option.value],
            title: option.label,
        }
    }),
    {
        tag: 'total',
        value: ['total'],
        title: 'Total',
    },
]

export const COMPANY_STATUS_QUERY_OPTIONS = [
    {
        tag: 'create',
        value: [''],
        title: 'Created',
    },
    {
        tag: 'lead_type',
        value: ['Active'],
        title: 'Active',
    },
    {
        tag: 'lifecycle_status',
        value: ['Submitted Dealer Forms'],
        title: 'Submitted Forms',
    },
    {
        tag: 'lifecycle_status',
        value: ['Listed', 'Listing'],
        title: 'Listed',
    },
]
export const COMPANY_INTERACTION_QUERY_OPTIONS = [
    {
        tag: 'all',
        value: [],
        title: 'All',
    },
    {
        tag: 'task',
        value: [],
        title: 'Tasks',
    },
    {
        tag: 'note',
        value: [],
        title: 'Notes',
    },
    {
        tag: 'edit',
        value: [],
        title: 'Edits',
    },
    {
        tag: 'view',
        value: [],
        title: 'Views',
    },
]
export const COMPANY_PRODUCT_INTERACTION_QUERY_OPTIONS = [
    {
        tag: 'all',
        value: [],
        title: 'All',
    },
    {
        tag: 'create',
        value: [],
        title: 'Created',
    },
    {
        tag: 'edit',
        value: [],
        title: 'Edits',
    },
]

function formatChartKeysDay(data: any) {
    const correctedDay = addDays(data.date, 1)
    return `${getMonth(correctedDay) + 1}/${getDate(correctedDay)}/${getYear(correctedDay).toString().slice(2)}`
}
function formatChartKeysWeek(data: any) {
    const correctedStart = addDays(data.date, 1)
    const correctedEnd = addDays(data.period_end_date, 1)
    return `${getMonth(correctedStart) + 1}/${getDate(correctedStart)}/${getYear(correctedStart)
        .toString()
        .slice(2)} - ${getMonth(correctedEnd) + 1}/${getDate(correctedEnd)}/${getYear(correctedEnd)
        .toString()
        .slice(2)}`
}
function formatChartKeysMonth(data: any) {
    const correctedStart = addDays(data.date, 1)
    const correctedEnd = addDays(data.period_end_date, 1)
    return `${getMonth(correctedStart) + 1}/${getDate(correctedStart)}/${getYear(correctedStart)
        .toString()
        .slice(2)} - ${getMonth(correctedEnd) + 1}/${getDate(correctedEnd)}/${getYear(correctedEnd)
        .toString()
        .slice(2)}`
}

export function formatTableDataByStatus(
    data: any,
    users: UserInit[],
    user_ids: any,
    fields: string[]
): StatusDataByUser[] | undefined {
    if (!data) {
        return
    }
    const tableData = user_ids.map((user_id: any) => {
        const filteredData = data.filter((data: any) => data.user_id === user_id)
        const dataArray: any = []
        fields.forEach((field: string, index: number) => {
            const data = filteredData.filter((data: any) => data.field == field && data.compare_data === 0)[0]
            const compareData = filteredData.filter((data: any) => data.field == field && data.compare_data === 1)[0]
            dataArray.push({
                [field]: (data?.analytics.toString() ?? '0') + ',' + (compareData?.analytics.toString() ?? '0'),
            })
        })
        const user_alias = users.filter((user) => user.id == user_id)[0]?.user_id ?? ''
        const formattedObject = Object.assign({}, ...[{ user_id: user_id, user_alias }, ...dataArray])
        return formattedObject
    })
    return tableData
}
export function formatChartDataByStatus(
    data: any,
    dateRangeCompare: DateRange | undefined,
    users: UserInit[],
    user_ids: any,
    fields: string[]
): any | undefined {
    if (!data || !users) {
        return
    }
    const tableData = user_ids.map((user_id: any) => {
        const filteredData = data.filter((data: any) => data.user_id === user_id)
        const dataArray: any = []
        const compareDataArray: any = []
        let total = 0
        fields.forEach((field: string) => {
            const data = filteredData.filter((data: any) => data.field == field && data.compare_data === 0)[0]
            if (field === 'Total') {
                dataArray.push({ [field]: total ?? 0 })
            } else {
                dataArray.push({ [field]: data?.analytics ?? 0 })
                total += data?.analytics || 0
            }
        })
        total = 0
        fields.forEach((field: string) => {
            const data = filteredData.filter((data: any) => data.field == field && data.compare_data === 1)[0]
            if (field === 'Total') {
                compareDataArray.push({ [field]: total ?? 0 })
            } else {
                compareDataArray.push({ [field]: data?.analytics ?? 0 })
                total += data?.analytics || 0
            }
        })
        const user_alias = users.filter((user) => user.id == user_id)[0]?.user_id ?? ''
        const formattedData = Object.assign({}, ...[...dataArray])
        const formattedCompareData = Object.assign({}, ...[...compareDataArray])
        return { user_id, user_alias, formattedData, formattedCompareData }
    })
    const datasets: any[] = []
    tableData.forEach((data: any, index: number) => {
        datasets.push({
            label: `${data.user_alias}`,
            data: data.formattedData,
            backgroundColor: colors[index],
            borderColor: colors[index],
            borderWidth: 2,
        })
        if (dateRangeCompare) {
            datasets.push({
                label: `${data.user_alias} (prev)`,
                data: data.formattedCompareData,
                backgroundColor: compareColors[index],
                borderColor: compareColors[index],
                borderWidth: 2,
            })
        }
    })
    return {
        labels: fields,
        datasets,
    }
}

export function formatChartDataByUser(data: any, dateRangeCompare: any, timePeriod: string, user_ids: any) {
    if (data?.length) {
        let datasets: any[] = []
        let orderedData: any[] = []
        const positions = new Set(data.map((data: any) => data.position))
        positions.forEach((position: any) => {
            const pair = data.filter((data: any) => data.position === position)
            orderedData.push(
                Array.from(
                    new Set(
                        pair.map((data: any) => {
                            if (timePeriod === 'DAY') {
                                return formatChartKeysDay(data)
                            } else if (timePeriod === 'WEEK') {
                                return formatChartKeysWeek(data)
                            } else {
                                return formatChartKeysMonth(data)
                            }
                        })
                    )
                )
            )
        })

        user_ids.map((user_id: any, index: any) => {
            const filteredData = data.filter((data: any) => data.user_id === user_id && data.compare_data === 0)

            datasets.push({
                label: `${filteredData[0]?.user_alias}`,
                data: filteredData.map((data: any, index: number) => {
                    return {
                        x: orderedData[index],
                        y: data.analytics,
                    }
                }),
                backgroundColor: colors[index],
                borderColor: colors[index],
                borderWidth: 2,
            })
            if (dateRangeCompare) {
                const filteredDataCompare = data.filter(
                    (data: any) => data.user_id === user_id && data.compare_data === 1
                )

                datasets.push({
                    label: `${filteredDataCompare[0]?.user_alias} (prev) `,
                    data: filteredDataCompare.map((data: any, index: number) => {
                        return {
                            x: orderedData[index],
                            y: data.analytics,
                        }
                    }),
                    backgroundColor: compareColors[index],
                    borderColor: compareColors[index],
                    borderWidth: 2,
                })
            }
        })
        return {
            labels: orderedData.reverse(),
            datasets,
        }
    }
}
export function formatChartDataByAnalyticsSet(analyticsSets: any, dateRangeCompare: any, timePeriod: string) {
    if (analyticsSets?.length) {
        let datasets: any[] = []
        let orderedData: any[] = []
        const positions = new Set(analyticsSets[0]?.data?.map((data: any) => data.position))
        positions.forEach((position: any) => {
            const pair = analyticsSets[0]?.data.filter((data: any) => data.position === position)
            orderedData.push(
                Array.from(
                    new Set(
                        pair.map((data: any) => {
                            if (timePeriod === 'DAY') {
                                return formatChartKeysDay(data)
                            } else if (timePeriod === 'WEEK') {
                                return formatChartKeysWeek(data)
                            } else {
                                return formatChartKeysMonth(data)
                            }
                        })
                    )
                )
            )
        })

        analyticsSets.map((analyticsSet: any, index: any) => {
            const filteredData = analyticsSet?.data?.filter((data: any) => data.compare_data === 0)
            datasets.push({
                label: `${analyticsSet.label}`,
                data: filteredData?.map((data: any, index: number) => {
                    return {
                        x: orderedData[index],
                        y: data.analytics,
                    }
                }),
                backgroundColor: colors[index],
                borderColor: colors[index],
                borderWidth: 2,
            })
            if (dateRangeCompare) {
                const filteredDataCompare = analyticsSet?.data?.filter((data: any) => data.compare_data === 1)

                datasets.push({
                    label: `${analyticsSet.label} (prev) `,
                    data: filteredDataCompare?.map((data: any, index: number) => {
                        return {
                            x: orderedData[index],
                            y: data.analytics,
                        }
                    }),
                    backgroundColor: compareColors[index],
                    borderColor: compareColors[index],
                    borderWidth: 2,
                })
            }
        })
        return {
            labels: orderedData.reverse(),
            datasets,
        }
    }
}
