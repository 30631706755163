type taskVariables = {
    priorityLevels: string[]
    taskTypeColors: {
        'Customer Service': string
        Sales: string
        Accounting: string
        'Order Fulfillment': string
    }
    taskTypes: string[]
}

export const taskVariables = {
    priorityLevels: ['Low', 'Medium', 'High', 'Urgent'],
    taskTypeColors: {
        'Customer Service': 'text-[rgb(0,136,204)]',
        Sales: 'text-[#41a112]',
        Accounting: 'text-purple-300',
        'Order Fulfillment': 'text-fire',
    },
    // taskTypes: ['Customer Service', 'Sales', 'Accounting', 'Order Fulfillment'],
}
