import { FaCaretLeft, FaCaretRight } from 'react-icons/fa'

export default function Pagination({ page, setPage, hasNextPage, children }: any) {
    const handleDecrement = () => {
        if (page > 1) {
            setPage(page - 1)
        }
    }
    const handleIncrement = () => {
        if (hasNextPage) {
            setPage(page + 1)
        }
    }
    return (
        <>
            {children}
            <div className='fixed bottom-4 left-[calc(50%+108px)] bg-white dark:bg-darkbg2 p-2 rounded shadow-md translate-x-[-50%] flex items-center justify-center gap-2 border border-lightgrey dark:border-darkgrey'>
                <button className='p-2 bg-white dark:bg-darkbg1 rounded' onClick={handleDecrement}>
                    <FaCaretLeft className='text-xl' />
                </button>
                <div className='p-2 font-bold'>{page}</div>
                <button className='p-2 bg-white dark:bg-darkbg1 rounded' onClick={handleIncrement}>
                    <FaCaretRight className='text-xl' />
                </button>
            </div>
        </>
    )
}
