import { useQuery } from '@tanstack/react-query'
import { vFetch } from 'helpers'

export default function Chargebacks({ order_id }: { order_id: number }) {
    const chargebacksQuery = useQuery({
        queryKey: [order_id, 'chargebacks'],
        queryFn: () => vFetch(`/v2/disputes?order_id=${order_id}`),
    })
    const chargebacks = chargebacksQuery.data?.disputes
    if (!chargebacks?.length) {
        return <></>
    }

    return (
        <>
            {chargebacks.map((chargeback: any) => (
                <div className='rounded overflow-hidden text-sm shadow-md bg-bg1 dark:bg-darkbg1'>
                    <h3 className='w-full bg-fire p-1 font-bold dark:text-darkbg2'>
                        There is a chargeback on this order
                    </h3>
                    <div className='grid grid-cols-4'>
                        <p className='p-2'>
                            <span className='font-bold'>Amount:</span> {chargeback.amount}
                        </p>
                        <p className='p-2'>
                            <span className='font-bold'>Reason:</span> {chargeback.reason?.replaceAll('_', ' ')}
                        </p>
                        <p className='p-2'>
                            <span className='font-bold'>Evidence Submitted:</span>{' '}
                            {chargeback.evidence_sent_on ? 'Yes' : 'No'}
                        </p>
                        <p className='p-2'>
                            <span className='font-bold'>Status:</span> {chargeback.status?.replaceAll('_', ' ')}
                        </p>
                    </div>
                </div>
            ))}
        </>
    )
}
