import { useEffect, useRef, useState } from 'react'
import { buildEmailFromTemplate, closePopout } from '../helpers/index'
import { Signature } from './Signature.js'
import ReactSelect from '../custom_components/ReactSelect'
import { AiFillInfoCircle } from 'react-icons/ai'
import { OrderInit } from '../orders/orders.types'
import { CustomerInit } from '../customers/customers.types'
import { EmailCustomerModalProps } from './emailModal.types'
import { MdOutlineAttachment } from 'react-icons/md'
import { Tooltip } from 'chart.js'
import { TbTooltip } from 'react-icons/tb'
import { FaTimes } from 'react-icons/fa'
import EmailAttachment from './components/EmailAttachment'
import CurrentAttachments from './components/CurrentAttachments'

export default function EmailCustomerModal({
    templates,
    selectedTemplate,
    setSelectedTemplate,
    email,
    setEmail,
    handleSendEmail,
    submitText,
    order,
    customer,
    vendorList,
    selectedVendor,
    attachments,
    setAttachments,
    setLoadingWidth,
    loadingWidth,
    loadingWidthWrapper,
    setLoadingWidthWrapper,
}: EmailCustomerModalProps) {
    useEffect(() => {
        if (selectedTemplate) {
            const { subject, body, greeting, html } = buildEmailFromTemplate(selectedTemplate, {
                order,
                customer,
                supplier: { name: vendorList[selectedVendor] || '' },
            })
            setEmail((previousState: any) => ({
                ...email,
                subject,
                body: body.replaceAll('<br>', '\n'),
                greeting: greeting || `Hi ${customer.first_name},`,
                html,
                // attachment: attachments,
            }))
        }
    }, [selectedTemplate])

    return (
        <>
            <div className='flex gap-[8px]'>
                <div className='flex flex-col w-full'>
                    <ReactSelect
                        name={'template'}
                        options={templates.map((t) => {
                            return t.name === 'Select a template'
                                ? { option: t.name, value: t }
                                : {
                                      option: t.name,
                                      value: t,
                                      symbol: <AiFillInfoCircle className='fill-grey dark:fill-white cursor-help' />,
                                      hoverText: [t.greeting, t.html].join('\n\n'),
                                  }
                        })}
                        selected={selectedTemplate}
                        setSelected={(template) => {
                            setSelectedTemplate(template)
                        }}
                    />
                </div>
                <div className='flex flex-col w-full'>
                    <label className='text-[10px] text-blue dark:text-grey font-bold uppercase' htmlFor='respond'>
                        Respond To
                    </label>
                    <select
                        value={email.respond_to}
                        onChange={({ target }) => setEmail({ ...email, respond_to: target.value })}
                        name='respond_to'
                        className='p-[4px] px-[6px] w-full border border-grey dark:border-blue dark:bg-darkness rounded-[4px] dark:text-offwhite focus:outline-none'
                    >
                        <option value='sales@factorypure.com'>sales@factorypure.com</option>
                        <option value='orders@factorypure.com'>orders@factorypure.com</option>
                        <option value='accounting@factorypure.com'>accounting@factorypure.com</option>
                    </select>
                </div>
            </div>
            <div className='flex flex-col w-full'>
                <label className='text-[10px] text-blue dark:text-grey font-bold uppercase' htmlFor='send_to'>
                    Send To
                </label>
                <input
                    value={email.send_to}
                    onChange={({ target }) => setEmail({ ...email, send_to: target.value })}
                    name='send_to'
                    className='p-[4px] px-[6px] border border-grey dark:border-blue dark:bg-darkness rounded-[4px] dark:text-offwhite focus:outline-none'
                    type='email'
                />
            </div>
            <div className='flex flex-col w-full'>
                <label className='text-[10px] text-blue dark:text-grey font-bold uppercase' htmlFor='cc'>
                    CC
                </label>
                <input
                    value={email.cc}
                    onChange={({ target }) => setEmail({ ...email, cc: target.value })}
                    name='cc'
                    className='p-[4px] px-[6px] border border-grey dark:border-blue dark:bg-darkness rounded-[4px] dark:text-offwhite focus:outline-none'
                    type='email'
                />
            </div>
            <div className='flex flex-col w-full'>
                <label className='text-[10px] text-blue dark:text-grey font-bold uppercase' htmlFor='subject'>
                    Subject
                </label>
                <input
                    value={email.subject}
                    onChange={({ target }) => setEmail({ ...email, subject: target.value })}
                    name='subject'
                    className='p-[4px] px-[6px] border border-grey dark:border-blue dark:bg-darkness rounded-[4px] dark:text-offwhite focus:outline-none'
                    type='text'
                />
            </div>
            <div className='flex flex-col w-full mb-[8px]'>
                <label className='text-[10px] text-blue dark:text-grey font-bold uppercase' htmlFor='body'>
                    Body
                </label>
                <div className='w-full rounded-[4px] p-[8px] border border-grey dark:border-blue'>
                    <input
                        className='p-[4px] w-full px-[6px] outline-none border border-grey dark:border-blue dark:bg-darkness rounded-[4px]focus:outline-none dark:text-white mb-[4px] rounded'
                        value={email.greeting}
                        placeholder='Write your greeting here'
                        onChange={({ target }) => setEmail({ ...email, greeting: target.value })}
                    />
                    <textarea
                        className='mb-[8px] min-h-[200px] p-[4px] px-[6px] border-grey dark:border-blue dark:bg-darkness rounded-[4px] dark:text-offwhite focus:outline-none'
                        placeholder='Write your message here'
                        value={email.body}
                        name='body'
                        onChange={({ target }) => setEmail({ ...email, body: target.value })}
                    />
                    <Signature />
                </div>
            </div>
            <CurrentAttachments
                attachments={attachments}
                setAttachments={setAttachments}
                setLoadingWidth={setLoadingWidth}
                loadingWidth={loadingWidth}
                loadingWidthWrapper={loadingWidthWrapper}
            />
            <div className='flex gap-6 mx-auto'>
                <button
                    onClick={() => handleSendEmail()}
                    className='ml-9 py-[4px] px-[16px] border-2 border-blue dark:border-fire text-blue dark:text-fire rounded-[4px] font-bold'
                    type='button'
                >
                    {submitText}
                </button>
                <EmailAttachment
                    attachments={attachments}
                    setAttachments={setAttachments}
                    setLoadingWidth={setLoadingWidth}
                    setLoadingWidthWrapper={setLoadingWidthWrapper}
                />
            </div>
        </>
    )
}
