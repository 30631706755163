import { useContext, useRef, useState } from 'react'
import { Path, SubmitHandler, UseFormRegister, useForm } from 'react-hook-form'
import { FetchingSpinner } from '../../../custom_components/FetchingSpinner'
import { Button } from '../../../custom_components/component_Basics/Button'
import { cn } from '../../../helpers'
import {
    useBulkCreateShopifyVariants,
    useCreateShopifyProduct,
    useOptionValuesQuery,
    useOptionsQuery,
} from '../../api/useQueries'
import { ProcurementContext, ProcurementContextType, formatToOption, formatToOptionValue } from '../../helpers'
import NewShopifyProductInputs from './NewShopifyProductInputs'
import NewShopifyVariantInputs from './NewShopifyVariantInputs'

export default function ShopifyProductCreateModal({
    product,
    allProducts,
    setShowShopifyCreate,
}: {
    product: any
    allProducts: any
    setShowShopifyCreate: any
}) {
    const modalRef = useRef(null)
    const [productSetupFields, setProductSetupFields] = useState({ title: '' })
    const { selectedCompany } = useContext<ProcurementContextType>(ProcurementContext)
    const hasVariantsTest =
        allProducts.filter((relatedProduct: any) => product.id === relatedProduct.variant_of).length > 0 ? true : false
    const [creationType, setCreationType] = useState(
        product.related_variants_group_id && !product.variant_of ? 'grouped' : 'new'
    )

    const [hasVariants, setHasVariants] = useState(hasVariantsTest)

    const [showRelatedVariants, setShowRelatedVariants] = useState(false)

    const relatedVariants = allProducts.filter((relatedProduct: any) => relatedProduct.variant_of === product.id)
    const [variantsEditableFields, setVariantsEditableFields] = useState<any>(
        relatedVariants.map((relatedVariant: any) => {
            return {}
        })
    )

    const optionValuesQuery = useOptionValuesQuery(product.variant_of || product.id)
    const { optionValues }: { optionValues: any } = optionValuesQuery?.data || {}

    const optionsQuery = useOptionsQuery(product.variant_of || product.id)
    const { options }: { options: any } = optionsQuery?.data || {}

    const createShopifyProduct = useCreateShopifyProduct()
    const createShopifyVariants = useBulkCreateShopifyVariants()

    const handleCreate = async (data: any) => {
        const selectedOptionIds = data?.option_value_ids ? data?.option_value_ids.split(',') : undefined
        const optionValueInserts = selectedOptionIds
            ? selectedOptionIds?.map((optionId: number | string) => {
                  return {
                      optionName: options.find(
                          (option: any) =>
                              option.id == optionValues.find((optionValue: any) => optionId == optionValue.id).option_id
                      ).label,
                      name: optionValues.find((optionValue: any) => optionId == optionValue.id).label,
                  }
              })
            : undefined
        createShopifyProduct.mutate(
            {
                product: {
                    ...product,
                    ...data,
                    optionValueInserts: optionValueInserts ? optionValueInserts : null,
                },
                info: {
                    optionValues: optionValues ? optionValues : null,
                    options: options ? options : null,
                    vendor: selectedCompany.shopify_vendor_name,
                },
            },
            {
                onSuccess: (data) => {
                    if (!hasVariants) {
                        setShowShopifyCreate(false)
                    }
                },
            }
        )
    }

    const handleCreateAll = (data: any) => {
        const selectedOptionIds = data?.option_value_ids ? data?.option_value_ids.split(',') : undefined
        const optionValueInserts = selectedOptionIds
            ? selectedOptionIds?.map((optionId: number | string) => {
                  return {
                      optionName: options.find(
                          (option: any) =>
                              option.id == optionValues.find((optionValue: any) => optionId == optionValue.id).option_id
                      ).label,
                      name: optionValues.find((optionValue: any) => optionId == optionValue.id).label,
                  }
              })
            : undefined
        const firstVariant = {
            ...product,
            ...data,
            optionValueInserts: optionValueInserts ? optionValueInserts : null,
        }

        const newVariants = variantsEditableFields
            .map((editableFields: any, index: number) => {
                const selectedOptionIds = editableFields?.option_value_ids
                    ? editableFields?.option_value_ids.split(',')
                    : undefined
                const optionValueInserts = selectedOptionIds
                    ? selectedOptionIds?.map((optionId: number | string) => {
                          return {
                              optionName: options.find(
                                  (option: any) =>
                                      option.id ==
                                      optionValues?.find((optionValue: any) => optionId == optionValue.id)?.option_id
                              )?.label,
                              name: optionValues?.find((optionValue: any) => optionId == optionValue.id)?.label,
                          }
                      })
                    : undefined

                if (!optionValueInserts) {
                    return
                }
                return {
                    ...relatedVariants[index],
                    ...editableFields,
                    optionValueInserts: optionValueInserts ? optionValueInserts : null,
                }
            })
            .filter((v: any) => v)

        createShopifyVariants.mutate(
            {
                products: [firstVariant, ...newVariants],
                info: {
                    vendor: selectedCompany.shopify_vendor_name,
                    optionValues: optionValues ? optionValues : null,
                    options: options ? options : null,
                },
            },
            {
                onSuccess: (data) => {
                    setShowShopifyCreate(false)
                },
            }
        )
    }

    return (
        <div
            ref={modalRef}
            className='fixed z-[41] top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] flex items-center justify-center company-modal'
        >
            <div
                className={cn(
                    'p-5 gap-[16px] bg-white dark:bg-darkaccent rounded-[4px] grid  shadow-small relative',
                    !showRelatedVariants && 'w-2/4 h-fit grid-cols-3 grid-rows-[30px_1fr]',
                    showRelatedVariants && 'w-2/4 h-fit grid-cols-3 grid-rows-[30px_1fr_60px]'
                )}
            >
                <button
                    onClick={() => {
                        setShowShopifyCreate(false)
                    }}
                    className='leading-[1] text-[24px] text-red dark:text-lightred absolute font-bold top-0 right-[8px]'
                >
                    &times;
                </button>

                <div className='border-b border-darkgrey col-span-3'>
                    {!showRelatedVariants && <p>Product Create</p>}
                    {showRelatedVariants && <p>Variant Creation</p>}
                </div>

                {!showRelatedVariants && (
                    <NewShopifyProductInputs
                        isPending={createShopifyProduct.isPending}
                        setProductSetupFields={setProductSetupFields}
                        creationType={creationType}
                        setCreationType={setCreationType}
                        hasVariants={hasVariants}
                        setHasVariants={setHasVariants}
                        optionValues={optionValues}
                        handleCreate={handleCreate}
                        setShowRelatedVariants={setShowRelatedVariants}
                    />
                )}
                {showRelatedVariants && (
                    <div className='col-span-3 w-full h-full'>
                        <div className='p-2'>
                            {relatedVariants?.map((relatedVariant: any, index: number) => {
                                return (
                                    <NewShopifyVariantInputs
                                        productSetupFields={productSetupFields}
                                        index={index}
                                        optionValues={optionValues}
                                        variantsEditableFields={variantsEditableFields}
                                        setVariantsEditableFields={setVariantsEditableFields}
                                        relatedVariant={relatedVariant}
                                    />
                                )
                            })}
                        </div>
                    </div>
                )}

                {showRelatedVariants && (
                    <div className='col-start-2 col-span-2 flex gap-3 mt-3'>
                        <p className='col-start-2'>
                            Any un-mapped variants will not be created and will be unavailable for import
                        </p>
                        <Button variant={'outline'} className='col' onClick={() => handleCreateAll(productSetupFields)}>
                            <p>{createShopifyVariants.isPending ? '' : 'create variants'}</p>
                            {createShopifyVariants.isPending && (
                                <FetchingSpinner isFetching={createShopifyVariants.isPending} />
                            )}
                        </Button>
                    </div>
                )}
                {/* </div> */}
            </div>
        </div>
    )
}
