import { FulfillmentOrderInit, LineItemInit, OrderInit } from '../../orders/orders.types'
import { useEffect, useState } from 'react'
import {
    DamageClaimCreateInit,
    DamageClaimEditInit,
    ReturnCreateInit,
    ReturnEditInit,
    defaultDamageChecklist,
    defaultItemChecklist,
} from '../returns.types'
import { itemPriceAfterDiscounts, renderDiscount } from '../../orders/orders.helpers'
import { formatMoney, vFetch } from '../../helpers'

type SelectItemProps = {
    r: ReturnCreateInit | ReturnEditInit | DamageClaimCreateInit | DamageClaimEditInit
    setR: Function
    order: OrderInit
    setSelectedItem2?: Function
}

export default function SelectReturnItem({ r, setR, order, setSelectedItem2 }: SelectItemProps) {
    const [fulfillmentOrders, setFulfillmentOrders] = useState<FulfillmentOrderInit[]>([])
    const [selectedItem, setSelectedItem] = useState<LineItemInit>()
    const [showDiscount, setShowDiscount] = useState<string>('')

    useEffect(() => {
        vFetch(`/v1/orders/${order.id}/fulfillment-orders`, {
            cb: (res: any) => setFulfillmentOrders(res.fulfillment_orders),
        })
    }, [])
    useEffect(() => {
        if (setSelectedItem2) setSelectedItem2(selectedItem)
        const fulfillmentsWithSelectedItem =
            order?.fulfillments.filter(
                (f) => f.tracking_number && f.line_items.find((item: LineItemInit) => item.sku === selectedItem?.sku)
            ) || []
        const firstTrackingNumber = fulfillmentsWithSelectedItem[0]
            ? fulfillmentsWithSelectedItem[0].tracking_number
            : ''
        const resetReturn = {
            ...r,
            manufacturer: selectedItem ? selectedItem?.vendor : '',
            model: selectedItem,
            original_tracking_number: firstTrackingNumber,
        }
        if (r.type === 'return') setR({ ...resetReturn, item_checklist: defaultItemChecklist })
        if (r.type === 'damage') setR({ ...resetReturn, damage_checklist: defaultDamageChecklist })
    }, [selectedItem])

    const pickItemStyle = 'hover:bg-offwhite dark:hover:bg-darkaccent rounded px-[8px]'
    function itemIsSelected(item: any) {
        if (selectedItem?.id) {
            if (JSON.stringify(item) === JSON.stringify(selectedItem))
                return 'bg-offwhite dark:bg-darkaccent/50 border-[2px] border-blue dark:border-accent'
            else return 'opacity-50'
        }
    }

    return (
        <div className='p-[16px] rounded shadow-small'>
            <h3 className='font-semibold text-[16px] w-[100%] border-b-[1px] border-black dark:border-grey mb-[16px]'>
                Select Item
            </h3>
            <div className='flex flex-col basis-[calc(65%)] grow-[1] min-w-[450px] gap-[16px]'>
                {/* fulfillments */}
                {/* unfulfilled items ---- fulfilled items filtered out around line 490*/}
                {fulfillmentOrders &&
                    fulfillmentOrders.length > 0 &&
                    fulfillmentOrders
                        .map((order) => order.line_items.length)
                        .reduce((acc: any, cur: any) => acc + cur, 0) !==
                        order.fulfillments
                            .map((f: any) => f.line_items.length)
                            .reduce((acc: number, cur: number) => acc + cur, 0) &&
                    fulfillmentOrders.map(
                        (f) =>
                            !f.line_items.every((item: any) => item.quantity === 0) &&
                            (f.status === 'open' || f.status === 'in_progress' || f.status === 'in progess') && (
                                <div className='grid gap-[24px] bg-white dark:bg-darkness p-[16px] shadow-small rounded dark:text-offwhite'>
                                    <div className='flex justify-between'>
                                        <div className='flex items-center gap-[8px] text-[14px]'>
                                            <svg
                                                className='fill-[rgb(205,105,0)] dark:fill-fire stroke-[rgba(255,205,165,1)] dark:stroke-[rgba(255,185,150,0.7)] border-[3px] border-[rgba(255,205,165,1)] dark:border-[rgba(255,185,150,0.7)] rounded-full w-[26px] h-[auto]'
                                                xmlns='http://www.w3.org/2000/svg'
                                                viewBox='0 0 17 17'
                                                role='img'
                                                focusable='false'
                                                aria-hidden='true'
                                            >
                                                <circle cx='8.5' cy='8.5' r='9' strokeWidth='3px' fill='none' />
                                                <path
                                                    stroke='none'
                                                    d='M10.09 16.41a.8.8 0 0 1-.18-1.58 6.46 6.46 0 0 0 2-.81L12 14a.79.79 0 0 1 1.07.35.8.8 0 0 1-.3 1.05 7.89 7.89 0 0 1-2.46 1 .55.55 0 0 1-.22.01zm-3.2 0h-.18a7.89 7.89 0 0 1-2.47-1A.8.8 0 0 1 5.09 14a6.49 6.49 0 0 0 2 .82.8.8 0 0 1 .6 1 .81.81 0 0 1-.78.62zm7.7-3.18a.8.8 0 0 1-.8-.8.79.79 0 0 1 .12-.42 6.27 6.27 0 0 0 .83-2 .8.8 0 0 1 1.56.36 7.89 7.89 0 0 1-1 2.47.77.77 0 0 1-.71.39zm-12.19 0a.78.78 0 0 1-.67-.37 8.14 8.14 0 0 1-1-2.46.8.8 0 0 1 1.53-.4 6.19 6.19 0 0 0 .82 2 .8.8 0 0 1-.68 1.23zm13.12-5.4a.81.81 0 0 1-.78-.63 6.46 6.46 0 0 0-.81-2 .81.81 0 0 1 .24-1.11.79.79 0 0 1 1.1.24 8 8 0 0 1 1 2.47.8.8 0 0 1-.6 1h-.18zm-14 0a.58.58 0 0 1-.19 0 .79.79 0 0 1-.6-1 8.22 8.22 0 0 1 1-2.47.82.82 0 0 1 1.11-.26.8.8 0 0 1 .25 1.11 6.49 6.49 0 0 0-.82 2 .78.78 0 0 1-.77.62zM12.33 3.3a.83.83 0 0 1-.43-.13 6.49 6.49 0 0 0-2-.82.79.79 0 0 1-.63-.93.8.8 0 0 1 .94-.64 8.15 8.15 0 0 1 2.48 1A.8.8 0 0 1 13 2.92a.78.78 0 0 1-.68.37zm-7.65 0A.82.82 0 0 1 4 2.93a.8.8 0 0 1 .22-1.1l.1-.06a7.93 7.93 0 0 1 2.39-1 .8.8 0 0 1 1 .61.79.79 0 0 1-.6 1 6.43 6.43 0 0 0-2 .82.82.82 0 0 1-.43.12z'
                                                />
                                            </svg>
                                            <h2 className='font-bold text-[20px] whitespace-nowrap'>
                                                {f.status === 'open' || f.status === 'in_progress'
                                                    ? 'Unfulfilled'
                                                    : f.line_items.every((item: any) => item.quantity === 0)
                                                      ? 'Removed'
                                                      : 'Please tell the developers you saw this message here'}{' '}
                                                (
                                                {
                                                    f.line_items.filter((item: any) => item.fulfillable_quantity > 0)
                                                        .length
                                                }
                                                )
                                            </h2>
                                        </div>
                                    </div>
                                    <div className='grid'>
                                        {f.line_items
                                            .filter((item: any) => item.fulfillable_quantity > 0)
                                            .map((item: any) => {
                                                const oItemIndex = order.line_items.findIndex(
                                                    (orderItem: any) => orderItem.id === item.line_item_id
                                                )
                                                if (oItemIndex < 0) return
                                                return (
                                                    <div
                                                        key={item.id}
                                                        className={`${pickItemStyle} ${itemIsSelected(
                                                            item
                                                        )} flex gap-[16px] py-[24px] w-[100%] justify-between col-span-2 text-[14px]`}
                                                        onClick={() =>
                                                            setSelectedItem(
                                                                order.line_items.find(
                                                                    (orderItem) => orderItem.id === item.line_item_id
                                                                )
                                                            )
                                                        }
                                                    >
                                                        <div className='flex gap-[16px]'>
                                                            <div className='grid w-[100px] items-center relative'>
                                                                <img
                                                                    className='object-contain min-w-[100px] min-h-[100px]'
                                                                    src={
                                                                        window.IMAGE_MAP[
                                                                            `gid://shopify/Product/${order.line_items[oItemIndex].product_id}`
                                                                        ]
                                                                    }
                                                                    alt={order.line_items[oItemIndex].name}
                                                                />
                                                            </div>
                                                            <div className='my-[16px]'>
                                                                <a
                                                                    href={`https://factorypure.com/products/${order.line_items[oItemIndex].handle}`}
                                                                    target='_blank'
                                                                    className='font-semibold hover:underline hover:text-blue dark:hover:text-accent pointer-events-auto'
                                                                >
                                                                    {order.line_items[oItemIndex].title}
                                                                </a>
                                                                {item.variant_title && (
                                                                    <p className='pt-[4px]'>
                                                                        <strong className='text-darkgrey dark:text-grey'>
                                                                            Variant:
                                                                        </strong>{' '}
                                                                        {order.line_items[oItemIndex].variant_title}
                                                                    </p>
                                                                )}
                                                                <p className='pt-[4px]'>
                                                                    <strong className='text-darkgrey dark:text-grey'>
                                                                        SKU:
                                                                    </strong>{' '}
                                                                    {order.line_items[oItemIndex].sku}
                                                                </p>
                                                                <ul className='list-disc ml-[16px] mt-[8px]'>
                                                                    {order.line_items[
                                                                        oItemIndex
                                                                    ].discount_allocations.map((discount: any) => (
                                                                        <li>
                                                                            {order.discount_applications[
                                                                                discount.discount_application_index
                                                                            ].title
                                                                                ? order.discount_applications[
                                                                                      discount
                                                                                          .discount_application_index
                                                                                  ].title
                                                                                : order.discount_applications[
                                                                                      discount
                                                                                          .discount_application_index
                                                                                  ].code}{' '}
                                                                            discount applied
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='flex gap-[32px]'>
                                                                <div className='relative my-[16px] flex gap-[32px]'>
                                                                    <div className='flex'>
                                                                        {order.line_items[oItemIndex]
                                                                            .discount_allocations.length > 0 && (
                                                                            <s
                                                                                onClick={() =>
                                                                                    setShowDiscount(
                                                                                        order.line_items[oItemIndex].sku
                                                                                    )
                                                                                }
                                                                                className='js-order__discount mr-[8px] text-grey hover:underline cursor-pointer'
                                                                            >
                                                                                {formatMoney(
                                                                                    Number(
                                                                                        order.line_items[oItemIndex]
                                                                                            .price
                                                                                    )
                                                                                )}
                                                                            </s>
                                                                        )}
                                                                        <span className='flex gap-[4px]'>
                                                                            {formatMoney(
                                                                                itemPriceAfterDiscounts(
                                                                                    order.line_items[oItemIndex]
                                                                                )
                                                                            )}
                                                                            <span className='top-0'>&times;</span>
                                                                            {order.line_items[oItemIndex].quantity}
                                                                        </span>
                                                                        {renderDiscount(
                                                                            order,
                                                                            order.line_items[oItemIndex].sku,
                                                                            order.line_items[oItemIndex],
                                                                            showDiscount
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        {order.line_items[oItemIndex]
                                                                            .discount_allocations.length > 0 && (
                                                                            <s
                                                                                onClick={() =>
                                                                                    setShowDiscount(
                                                                                        order.line_items[oItemIndex].sku
                                                                                    )
                                                                                }
                                                                                className='js-order__discount mr-[8px] text-grey hover:underline cursor-pointer'
                                                                            >
                                                                                {formatMoney(
                                                                                    Number(
                                                                                        order.line_items[oItemIndex]
                                                                                            .price
                                                                                    ) *
                                                                                        order.line_items[oItemIndex]
                                                                                            .quantity
                                                                                )}
                                                                            </s>
                                                                        )}
                                                                        <span className=''>
                                                                            {formatMoney(
                                                                                Number(
                                                                                    itemPriceAfterDiscounts(
                                                                                        order.line_items[oItemIndex]
                                                                                    )
                                                                                ) *
                                                                                    order.line_items[oItemIndex]
                                                                                        .quantity
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                    </div>
                                </div>
                            )
                    )}

                {/* fulfilled items*/}
                {order.fulfillments &&
                    order.fulfillments.length > 0 &&
                    order.fulfillments.map(
                        (f: any) =>
                            f.status !== 'cancelled' && (
                                <div
                                    key={f.id}
                                    className='grid gap-[24px] bg-white dark:bg-darkness p-[16px] shadow-small rounded dark:text-offwhite'
                                >
                                    <div className='flex justify-between'>
                                        <div className='flex items-center gap-[8px] text-[14px]'>
                                            <svg
                                                className='w-[26px] h-[auto] fill-[#16a679] dark:fill-[rgb(150,255,175)] border-[3px] border-[#a1edd0] dark:border-[rgba(100,225,150,0.3)] rounded-full'
                                                xmlns='http://www.w3.org/2000/svg'
                                                viewBox='0 0 17 17'
                                                role='img'
                                                focusable='false'
                                                aria-hidden='true'
                                            >
                                                <path d='M8.5 17a8.36 8.36 0 1 1 8.35-8.36A8.35 8.35 0 0 1 8.5 17zm0-15a6.69 6.69 0 1 0 6.68 6.68A6.68 6.68 0 0 0 8.5 2z' />
                                                <path d='M7.66 11.09a.82.82 0 0 1-.59-.24L5.4 9.18A.84.84 0 0 1 5.45 8a.82.82 0 0 1 1.13 0l1.08 1.08 2.75-2.75a.83.83 0 0 1 1.18 1.18l-3.34 3.34a.82.82 0 0 1-.59.24z' />
                                            </svg>
                                            <h2 className='font-bold text-[20px]'>Fulfilled ({f.line_items.length})</h2>
                                            <span className='text-darkgrey dark:text-grey'>
                                                {f.name.replace('.', '-F')}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='grid'>
                                        {f.line_items.map((item: any) => {
                                            return (
                                                <div
                                                    key={item.id}
                                                    className={`${pickItemStyle} ${itemIsSelected(
                                                        item
                                                    )} flex gap-[16px] py-[24px] w-[100%] justify-between col-span-2 text-[14px]`}
                                                    onClick={() => setSelectedItem(item)}
                                                >
                                                    <div className='flex gap-[16px] '>
                                                        <div className='grid w-[100px] items-center relative'>
                                                            <img
                                                                className='object-contain min-w-[100px] min-h-[100px]'
                                                                src={
                                                                    window.IMAGE_MAP[
                                                                        `gid://shopify/Product/${item.product_id}`
                                                                    ]
                                                                }
                                                                alt={item.name}
                                                            />
                                                            <span className='absolute flex top-[-10px] right-[-10px] min-w-[20px] min-h-[20px] text-[12px] leading-[1px] items-center pb-[2px] border-[5px] border-[darkgrey] bg-[darkgrey] text-white font-bold rounded-full justify-center'>
                                                                {item.quantity}
                                                            </span>
                                                        </div>
                                                        <div className='my-[16px]'>
                                                            <a
                                                                href={`https://factorypure.com/products/${order.line_items.find(
                                                                    (i: any) => item.product_id === i.product_id
                                                                )?.handle}`}
                                                                target='_blank'
                                                                className='font-semibold hover:underline hover:text-blue dark:hover:text-accent pointer-events-auto'
                                                            >
                                                                {item.title}
                                                            </a>
                                                            {item.variant_title && (
                                                                <p className='pt-[4px]'>
                                                                    <strong className='text-darkgrey dark:text-grey'>
                                                                        Variant:
                                                                    </strong>{' '}
                                                                    {item.variant_title}
                                                                </p>
                                                            )}
                                                            <p className='pt-[4px]'>
                                                                <strong className='text-darkgrey dark:text-grey'>
                                                                    SKU:
                                                                </strong>{' '}
                                                                {item.sku}
                                                            </p>
                                                            <ul className='list-disc ml-[16px] mt-[8px]'>
                                                                {item.discount_allocations.map((discount: any) => (
                                                                    <li>
                                                                        {order.discount_applications[
                                                                            discount.discount_application_index
                                                                        ].title
                                                                            ? order.discount_applications[
                                                                                  discount.discount_application_index
                                                                              ].title
                                                                            : order.discount_applications[
                                                                                  discount.discount_application_index
                                                                              ].code}{' '}
                                                                        discount applied
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='relative flex gap-[32px] my-[16px]'>
                                                            <div className='flex'>
                                                                {item.discount_allocations.length > 0 && (
                                                                    <s
                                                                        onClick={() => setShowDiscount(item.sku)}
                                                                        className='js-order__discount mr-[8px] text-grey hover:underline cursor-pointer'
                                                                    >
                                                                        {formatMoney(Number(item.price))}
                                                                    </s>
                                                                )}
                                                                <span className='flex gap-[4px]'>
                                                                    {formatMoney(itemPriceAfterDiscounts(item))}
                                                                    <span className='top-0'>&times;</span>
                                                                    {item.quantity}
                                                                </span>
                                                                {renderDiscount(order, item.sku, item, showDiscount)}
                                                            </div>
                                                            <div>
                                                                {item.discount_allocations.length > 0 && (
                                                                    <s
                                                                        onClick={() => setShowDiscount(item.sku)}
                                                                        className='js-order__discount mr-[8px] text-grey hover:underline cursor-pointer'
                                                                    >
                                                                        {formatMoney(
                                                                            Number(item.price) * item.quantity
                                                                        )}
                                                                    </s>
                                                                )}
                                                                <span className=''>
                                                                    {formatMoney(
                                                                        Number(itemPriceAfterDiscounts(item)) *
                                                                            item.quantity
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className='flex justify-end'>
                                                            <a
                                                                className='p-[8px] font-semibold text-white dark:text-black bg-[#007a5c] hover:bg-[#125443] dark:bg-fire rounded dark:hover:bg-[darkorange]'
                                                                href={`https://factorypure.myshopify.com/admin/products/${item.product_id}`}
                                                            >
                                                                View On Shopify
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                    )}

                {/* Removed Items --- Should these be commented out? */}
                {/* {fulfillmentOrders && fulfillmentOrders.length > 0 && fulfillmentOrders.map(order => order.line_items.length).reduce((acc:any, cur:any) => acc+cur, 0) !== order.fulfillments.map((f:any) => f.line_items.length).reduce((acc:any, cur:any) => acc+cur, 0) && fulfillmentOrders.map(f => f.line_items.every((item:any) => item.quantity === 0) &&
                    <div className="grid gap-[24px] bg-white dark:bg-darkness p-[16px] shadow-small rounded dark:text-offwhite opacity-[0.5]">
                        <div className="flex justify-between">
                        {(() => { 
                        console.log(f);
                        
                        return <></>})()}
                            <div className="flex items-center gap-[8px] text-[14px]">
                                <h2 className="font-bold text-[20px] whitespace-nowrap">{f.status === "open" || f.status === "in_progress" ? "Unfulfilled" : f.line_items.every((item:any) => item.quantity === 0) ? "Removed" : "Please tell the developers you saw this message"} ({f.line_items.length})</h2>
                            </div>
                        </div>
                        <div className="grid">
                            {f.line_items.map((item:any) => {
                                const oItemIndex = order.line_items.findIndex((orderItem:any) => orderItem.id === item.line_item_id);
                                if (oItemIndex < 0) return;
                                return (
                                    <div key={item.id} className={`${pickItemStyle} ${itemIsSelected(item)} flex gap-[16px] py-[24px] w-[100%] justify-between col-span-2 text-[14px]`} onClick={() => setSelectedItem(order.line_items.find(orderItem => orderItem.id === item.line_item_id))}>
                                        <div className="flex gap-[16px]">
                                            <div className="grid w-[100px] items-center relative">
                                                <img className="object-contain min-w-[100px] min-h-[100px]" src={window.IMAGE_MAP[`gid://shopify/Product/${order.line_items[oItemIndex].product_id}`]} alt={order.line_items[oItemIndex].name}/>
                                                <span className="absolute flex top-[-10px] right-[-10px] min-w-[20px] min-h-[20px] text-[12px] leading-[1px] items-center pb-[2px] border-[5px] border-[darkgrey] bg-[darkgrey] text-white font-bold rounded-full justify-center">{item.quantity}</span>
                                            </div>
                                            <div className="my-[16px]">
                                                <a href={`https://factorypure.com/products/${order.line_items[oItemIndex].handle}`} target="_blank" className="font-semibold hover:underline hover:text-blue dark:hover:text-accent pointer-events-auto">{order.line_items[oItemIndex].title}</a>
                                                {item.variant_title && <p className="pt-[4px]"><strong className="text-darkgrey dark:text-grey">Variant:</strong> {order.line_items[oItemIndex].variant_title}</p>}
                                                <p className="pt-[4px]"><strong className="text-darkgrey dark:text-grey">SKU:</strong> {order.line_items[oItemIndex].sku}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="flex gap-[32px]">
                                                <span className="my-[16px] flex gap-[4px]">{formatMoney(Number(order.line_items[oItemIndex].price))}<span>x</span>{order.line_items[oItemIndex].quantity}</span>
                                                <span className="my-[16px]">{formatMoney(Number(order.line_items[oItemIndex].price)*Number(order.line_items[oItemIndex].quantity))}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )} */}
            </div>
        </div>
    )
}
