import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export default function CallsScreen() {
    const user = useSelector((state) => state.user)
    const navigate = useNavigate()
    useEffect(() => {
        if (!user.access.includes('calls') && !user.access.includes('*')) {
            navigate('/home')
        }
    }, [])
    return <>{user && <h1>Calls</h1>}</>
}
