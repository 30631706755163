import { useState } from 'react'
import {
    useParentCompanyContactsQuery,
    useParentCompanyEmailsQuery,
    useParentCompanyIncomingEmailsQuery,
} from '../../api/useQueries'
import IncomingEmail from '../listItems/IncomingEmail'
import ParentEmail from '../listItems/ParentEmail'
import FilterToolBar from '../FilterToolBar'
import { FilterBarSelectorButton } from '../../../custom_components/component_Basics/FilterBarSelectorButton'
import { CheckCircledIcon, StopwatchIcon } from '@radix-ui/react-icons'
import { createdAtSort } from '../../helpers'

export default function ParentEmailList({
    parentCompany,
    companyNameMap,
    companyEmails = [],
}: {
    parentCompany: any
    companyNameMap: any
    companyEmails: string[]
}) {
    const [search, setSearch] = useState('')
    const [filterValues, setFilterValues] = useState<{ types: string[] }>({
        types: [],
    })

    const companyContactsQuery = useParentCompanyContactsQuery({ parentCompany, companyNameMap })
    const { contacts } = companyContactsQuery?.data || {}

    const companyIncomingEmailsQuery = useParentCompanyIncomingEmailsQuery({
        parentCompany,
        contactList:
            [
                ...(contacts || []),
                ...companyEmails?.map((email) => {
                    return { email: email }
                }),
            ] || [],
        companyNameMap,
    })
    const { emails: incomingEmails }: { emails: any[] } = companyIncomingEmailsQuery?.data || {}

    const companyEmailsQuery = useParentCompanyEmailsQuery({ parentCompany, companyNameMap })
    const { emails }: { emails: any[] } = companyEmailsQuery?.data || {}

    let allEmails: any[] = []
    if (emails) {
        allEmails.push(...emails.map((email) => ({ ...email, type: 'sent-email' }) as any as EventType))
    }
    if (incomingEmails) {
        allEmails.push(...incomingEmails.map((email) => ({ ...email, type: 'incoming-email' }) as any as EventType))
    }

    const filteredEmails = allEmails
        ?.filter((email: any) => {
            if (filterValues.types.length) {
                return (
                    email.subject?.toLowerCase().includes(search.toLowerCase()) &&
                    filterValues.types.includes(email.type)
                )
            } else {
                return email.subject?.toLowerCase().includes(search.toLowerCase())
            }
        })
        .sort(createdAtSort)

    const emailListFilterBarObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: [
                    {
                        value: 'sent-email',
                        label: 'Sent',
                        icon: StopwatchIcon,
                    },
                    {
                        value: 'incoming-email',
                        label: 'Recieved',
                        icon: CheckCircledIcon,
                    },
                ],
                title: 'Status',
                field: 'types',
                values: filterValues.types,
                searchToggle: false,
            },
        ],
        setFunction: setFilterValues,
        resetFunction: () => {
            setFilterValues({
                types: [],
            })
        },
    }

    return (
        <div className='p-[16px] pl-0 overflow-auto'>
            <div className='mb-[8px] w-full flex gap-[16px]'>
                <FilterToolBar
                    search={search}
                    setSearch={setSearch}
                    filterBarObject={emailListFilterBarObject}
                    className={'border border-blue/25 dark:border-0 dark:bg-darkbg2 rounded-[4px] px-1'}
                >
                    {emailListFilterBarObject.params.length &&
                        emailListFilterBarObject.params.map((param: any) => {
                            return (
                                <param.component
                                    key={param.field}
                                    searchToggle={param.searchToggle}
                                    title={param.title}
                                    field={param.field}
                                    options={param.options}
                                    filterValues={param.values}
                                    setFilterValues={emailListFilterBarObject.setFunction}
                                />
                            )
                        })}
                </FilterToolBar>
            </div>
            <div className='flex flex-col gap-[8px]'>
                {filteredEmails?.map((email) => {
                    if (email.type == 'incoming-email') {
                        return <IncomingEmail key={email.thread_id + email.type} email={email} />
                    }
                    return <ParentEmail key={email.id + email.type} email={email} />
                })}
            </div>
        </div>
    )
}

export type EventType = {
    id?: string | number
    type: string
    message: string
    value: string
    previous_value?: string
    raw_data?: any
    company_id?: string | number
    user_id?: string | number
    store_id?: string | number
    created_at: string
    updated_at: string
}
