import { CheckIcon, PlusCircledIcon } from '@radix-ui/react-icons'
// import { Column } from '@tanstack/react-table'
import * as React from 'react'
import { cn } from '../../helpers'
import { Badge } from './Badge'
import { Button } from './Button'
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
    CommandSeparator,
} from './Command'
import { Popover, PopoverContent, PopoverTrigger } from './Popover'
import { Separator } from './Separator'
import { useNavigate, useSearchParams } from 'react-router-dom'

interface DataTableFacetedFilterProps<TData, TValue> {
    searchToggle: boolean
    filterValues: string[]
    setFilterValues: Function
    title: string
    field: string
    options: {
        label: string
        value: string
        icon?: React.ComponentType<{ className?: string }>
    }[]
    align: 'end' | 'center' | 'start'
    selectType?: 'single' | 'multi'
    preview?: boolean
    clearFilters?: boolean
    variant: 'link' | 'default' | 'destructive' | 'outline' | 'secondary' | 'ghost'
    editSearchParams: boolean
    pageString?: string
}

export function FilterBarSelectorButton<TData, TValue>({
    searchToggle,
    filterValues,
    setFilterValues,
    title,
    field,
    options,
    align = 'end',
    selectType = 'multi',
    preview = true,
    clearFilters = true,
    variant = 'outline',
    editSearchParams = false,
    pageString,
}: DataTableFacetedFilterProps<TData, TValue>) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [open, setOpen] = React.useState(false)

    const selectedValues = new Set(filterValues as string[])

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button variant={variant} size='sm' className='h-8 border-dashed max-h-[29.5px]'>
                    {selectedValues?.size > 0 && (
                        <>
                            {!preview && (
                                <Badge
                                    variant='secondary'
                                    className='rounded-sm px-1 font-bold text-accent2 dark:text-darkaccent2'
                                >
                                    {selectedValues.size}
                                </Badge>
                            )}
                            {preview && (
                                <div className='hidden space-x-1 lg:flex'>
                                    {selectedValues.size > 2 ? (
                                        <Badge
                                            variant='secondary'
                                            className='rounded-sm px-1 font-bold text-accent2 dark:text-darkaccent2'
                                        >
                                            {selectedValues.size} selected
                                        </Badge>
                                    ) : (
                                        options
                                            ?.filter((option) => selectedValues.has(option.value))
                                            .map((option) => (
                                                <Badge
                                                    variant='secondary'
                                                    key={option.value}
                                                    className='rounded-sm px-1 font-bold text-accent2 dark:text-darkaccent2'
                                                >
                                                    {option.label}
                                                </Badge>
                                            ))
                                    )}
                                </div>
                            )}
                            <Separator orientation='vertical' className='mx-2 h-4' />
                        </>
                    )}
                    <PlusCircledIcon className='mr-2 h-4 w-4' />
                    {title}
                </Button>
            </PopoverTrigger>
            <PopoverContent className='w-[200px] p-0' align={align}>
                <Command className={'bg-bg1 dark:bg-darkbg1 text-text1 dark:text-darktext1'}>
                    {searchToggle && <CommandInput placeholder={title} />}
                    <CommandList>
                        <CommandEmpty>No results found.</CommandEmpty>
                        {selectType === 'multi' && (
                            <MultiSelectCommandGroup
                                options={options}
                                selectedValues={selectedValues}
                                setFilterValues={setFilterValues}
                                field={field}
                                editSearchParams={editSearchParams}
                                pageString={pageString || 'page'}
                            />
                        )}
                        {selectType === 'single' && (
                            <SingleSelectCommandGroup
                                options={options}
                                selectedValues={selectedValues}
                                setFilterValues={setFilterValues}
                                setOpen={setOpen}
                                field={field}
                                editSearchParams={editSearchParams}
                                pageString={pageString || 'page'}
                            />
                        )}
                        {clearFilters && selectedValues.size > 0 && (
                            <>
                                <CommandSeparator />
                                <CommandGroup>
                                    <CommandItem
                                        onSelect={() => {
                                            if (editSearchParams) {
                                                setSearchParams((prev: URLSearchParams) => {
                                                    prev.delete(field)
                                                    return prev
                                                })
                                                return
                                            }
                                            return setFilterValues((prev: any) => {
                                                return {
                                                    ...prev,
                                                    [field]: [],
                                                }
                                            })
                                        }}
                                        className='justify-center text-center'
                                    >
                                        Clear filters
                                    </CommandItem>
                                </CommandGroup>
                            </>
                        )}
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}
function MultiSelectCommandGroup({
    options,
    selectedValues,
    setFilterValues,
    field,
    editSearchParams,
    pageString,
}: {
    selectedValues: Set<string>
    setFilterValues: Function
    field: string
    options: {
        label: string
        value: string
        icon?: React.ComponentType<{ className?: string }>
    }[]
    editSearchParams: boolean
    pageString: string
}) {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    return (
        <CommandGroup>
            {options?.map((option) => {
                const isSelected = selectedValues.has(option.value)
                return (
                    <CommandItem
                        key={option.value}
                        onSelect={() => {
                            if (isSelected) {
                                selectedValues.delete(option.value)
                            } else {
                                selectedValues.add(option.value)
                            }
                            const filterValues = Array.from(selectedValues)
                            if (editSearchParams) {
                                if (filterValues.length < 1) {
                                    setSearchParams((prev: URLSearchParams) => {
                                        prev.delete(field)
                                        prev.set(pageString, '1')
                                        prev.delete('after')
                                        prev.delete('before')
                                        return prev
                                    })
                                    return
                                }

                                searchParams.set(field, encodeURIComponent(filterValues.join(',')))
                                searchParams.set(pageString, '1')
                                searchParams.delete('after')
                                searchParams.delete('before')
                                setSearchParams(searchParams)
                                return
                            }
                            setFilterValues((prev: any) => {
                                return { ...prev, [field]: filterValues.length ? filterValues : [] }
                            })
                        }}
                    >
                        <div
                            className={cn(
                                'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                                isSelected ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible'
                            )}
                        >
                            <CheckIcon className={cn('h-4 w-4')} />
                        </div>
                        {option.icon && (
                            <option.icon className='mr-2 min-h-[16px] min-w-[16px] text-muted-foreground' />
                        )}
                        <span>{option.label}</span>
                    </CommandItem>
                )
            })}
        </CommandGroup>
    )
}
function SingleSelectCommandGroup({
    options,
    selectedValues,
    setFilterValues,
    setOpen,
    field,
    editSearchParams,
    pageString,
}: {
    selectedValues: Set<string>
    setFilterValues: Function
    setOpen: Function
    field: string
    options: {
        label: string
        value: string
        icon?: React.ComponentType<{ className?: string }>
    }[]
    editSearchParams: boolean
    pageString: string
}) {
    const [searchParams, setSearchParams] = useSearchParams()
    return (
        <CommandGroup>
            {options?.map((option) => {
                const isSelected = selectedValues.has(option.value)
                return (
                    <CommandItem
                        key={option.value}
                        onSelect={() => {
                            if (isSelected) {
                                selectedValues.delete(option.value)
                            } else {
                                selectedValues.clear()
                                selectedValues.add(option.value)
                            }
                            const filterValues = Array.from(selectedValues)
                            if (editSearchParams) {
                                if (filterValues.length < 1) {
                                    setSearchParams((prev: URLSearchParams) => {
                                        prev.delete(field)
                                        prev.set(pageString, '1')
                                        prev.delete('after')
                                        prev.delete('before')
                                        return prev
                                    })
                                    return
                                }

                                searchParams.set(field, encodeURIComponent(filterValues.join(',')))
                                searchParams.set(pageString, '1')
                                searchParams.delete('after')
                                searchParams.delete('before')
                                setSearchParams(searchParams)
                                return
                            }
                            // setOpen(false)
                            setFilterValues((prev: any) => {
                                return { ...prev, [field]: filterValues.length ? filterValues : [] }
                            })
                        }}
                    >
                        <div
                            className={cn(
                                'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                                isSelected ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible'
                            )}
                        >
                            <CheckIcon className={cn('h-4 w-4')} />
                        </div>
                        {option.icon && (
                            <option.icon className='mr-2 min-h-[16px] min-w-[16px] text-muted-foreground' />
                        )}
                        <span>{option.label}</span>
                    </CommandItem>
                )
            })}
        </CommandGroup>
    )
}
