import * as React from 'react'
import { DatePickerPresets } from './DatePickerPresets'
import { DatePickerPresetsCompare } from './DatePickerPresetsCompare'
import { DateRange } from 'react-day-picker'

export function AnalyticsRangeSelector({
    date,
    setDate,
    customCompareDate,
    setCustomCompareDate,
    period,
    setPeriod,
    optionIndex,
    setOptionIndex,
}: {
    date: DateRange | undefined
    setDate: React.Dispatch<React.SetStateAction<any>>
    customCompareDate: DateRange | undefined
    setCustomCompareDate: React.Dispatch<React.SetStateAction<any>>
    period: { value: string | undefined; function: Function | undefined; rangeString: string | undefined }
    setPeriod: React.Dispatch<React.SetStateAction<any>>
    optionIndex: number
    setOptionIndex: React.Dispatch<React.SetStateAction<any>>
}) {
    return (
        <div className='flex gap-2 text-black dark:text-offwhite'>
            <DatePickerPresets
                date={date}
                setDate={setDate}
                period={period}
                setPeriod={setPeriod}
                optionIndex={optionIndex}
                setOptionIndex={setOptionIndex}
            />
            <DatePickerPresetsCompare
                date={date}
                period={period}
                optionIndex={optionIndex}
                customCompareDate={customCompareDate}
                setCustomCompareDate={setCustomCompareDate}
            />
        </div>
    )
}
