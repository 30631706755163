import { useRef, useState } from 'react'
import { MdOutlineAttachment } from 'react-icons/md'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { vFetch } from 'helpers'

// holds attachment icon and adds to attachment array.
// uploadS3FileMutation that handles adding attachments to s3 bucket is commented out
export default function EmailAttachment({
    attachments,
    setAttachments,
    setLoadingWidth,
    setLoadingWidthWrapper,
}: {
    attachments: any
    setAttachments: any
    setLoadingWidth: any
    setLoadingWidthWrapper: any
}) {
    const fileInputRef = useRef<any>()
    const queryClient = useQueryClient()

    const createFile = async ({ files }: { files: File[] | null }) => {
        if (!files) {
            return
        }
        const fileKeys: any = []
        for (let i = 0; i < Array.from(files).length; i++) {
            const file = Array.from(files)[i]
            const data = new FormData()
            data.append('file', file, file.name)
            await vFetch(`/v1/email/emailAttachments`, {
                method: 'POST',
                body: data,
                contentType: 'skip',
                reThrowErr: true,
                cb: (res: any) => {
                    if (!res.success) {
                        throw new Error(res.message)
                    }
                    fileKeys.push(res.files)
                },
            })
        }
        return fileKeys
    }
    const uploadS3FileMutation = useMutation({
        mutationFn: createFile,
        onSuccess: (data) => {
            console.log('successfully uploaded to s3 bucket', data)
        },
        onError: () => {
            console.log('Could not upload to s3 bucket')
        },
    })
    const handleFileSelection = () => {
        fileInputRef.current.click()
    }
    const handleFileChange = (e: any) => {
        if (e.target.files[0] === undefined && attachments != undefined) {
            return
        }
        const newArray = [...attachments]
        newArray.push(e.target.files[0])
        setAttachments(newArray)
        const currentFile = e.target.files[0]
        handleAttachmentAnimation()
        // uploadS3FileMutation.mutate({ files: [currentFile] })
    }

    const handleAttachmentAnimation = () => {
        setTimeout(() => {
            setLoadingWidth('w-6')
            setLoadingWidthWrapper('bg-white border border-grey dark:border-white w-24 h-4')

            setTimeout(() => {
                setLoadingWidth('w-12')

                setTimeout(() => {
                    setLoadingWidth('w-24')

                    setTimeout(() => {
                        setLoadingWidth('w-0')
                        setLoadingWidthWrapper('')
                    }, 140)
                }, 120)
            }, 120)
        }, 120)
    }

    return (
        <div className='group flex relative'>
            <button className='flex' onClick={handleFileSelection} aria-label='add attachment'>
                <MdOutlineAttachment size={'25px'} className={`m-auto rotate-[-90deg]`} />
                <main className='absolute bottom-[-1.5px] left-6 hidden group-hover:flex'>
                    <section className='container max-w-2xl p-2'>
                        <div className='flex items-center'>
                            <label
                                htmlFor='attachFileInput'
                                className='whitespace-nowrap bg-gray-700 dark:bg-accent text-white dark:text-darkaccent p-1 px-2 rounded flex-1 text-xs'
                            >
                                Add Attachment
                            </label>
                        </div>
                    </section>
                </main>
            </button>

            <input type='file' ref={fileInputRef} onChange={handleFileChange} id='attachFileInput' className='hidden' />
        </div>
    )
}
