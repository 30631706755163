import { useQuery } from '@tanstack/react-query'
import { usersQueries } from './usersQueryKeys'

export function useAllUsersQuery(select?: any): any {
    const query = useQuery({
        ...usersQueries.users.all(),
        select,
    })

    return query
}
