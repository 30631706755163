import { ChangeEvent } from 'react'
import { formatToOptionValue, formatVariantOptions } from '../../helpers'
import Input from '../Input'

export default function NewShopifyVariantInputs({
    relatedVariant,
    index,
    variantsEditableFields,
    setVariantsEditableFields,
    optionValues,
    productSetupFields,
}: {
    relatedVariant: any
    index: number
    variantsEditableFields: any
    setVariantsEditableFields: any
    optionValues: any
    productSetupFields: any
}) {
    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setVariantsEditableFields((previousState: any) => {
            const newState = [...previousState]
            newState[index] = {
                ...newState[index],
                [target.name]: target.type === 'checkbox' ? (target as HTMLInputElement).checked : target.value,
            }
            return [...newState]
        })
    }

    const variantMapOptions: { option_value_ids: string[]; value: string }[] = formatVariantOptions(optionValues)

    return (
        <div className='col-start-1 grid grid-cols-2 gap-4'>
            <Input
                label={`shopify SKU`}
                value={relatedVariant.sku}
                id='sku'
                name='sku'
                onChange={handleChange}
                type='text'
                readOnly={true}
            />
            <div className='flex flex-col gap-1'>
                <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1] relative w-fit'>
                    Variant Option
                </label>
                <select
                    autoComplete='off'
                    className='w-full text-[16px] leading-snug bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                    id='option_value_ids'
                    name='option_value_ids'
                    onChange={handleChange}
                    value={variantsEditableFields[index].option_value_ids ?? ''}
                >
                    <option className='dark:bg-darkness/90' value=''></option>
                    {variantMapOptions?.map((variantOption: any) => {
                        const optionAlreadySelected = variantsEditableFields.find((field: any) => {
                            return (
                                field.option_value_ids === variantOption.option_value_ids ||
                                productSetupFields.option_value_ids === variantOption.option_value_ids
                            )
                        })
                        return (
                            <option
                                disabled={optionAlreadySelected ? true : false}
                                className='dark:bg-darkness/90'
                                value={variantOption.option_value_ids}
                            >
                                {variantOption.value}
                            </option>
                        )
                    })}
                </select>
            </div>
        </div>
    )
}
