import { Dispatch, SetStateAction, useState } from 'react'
import { useParentCompanyContactsQuery } from '../../api/useQueries'
import ParentContact from '../listItems/ParentContact'
import FilterToolBar from '../FilterToolBar'

export default function ParentContactList({
    parentCompany,
    companyNameMap,
    setShowQuickModal,
}: {
    parentCompany: any
    companyNameMap: any
    setShowQuickModal: Dispatch<SetStateAction<string | undefined>>
}) {
    const [search, setSearch] = useState('')
    const companyContactsQuery = useParentCompanyContactsQuery({ parentCompany, companyNameMap })
    const { contacts } = companyContactsQuery.data || {}

    const filteredContacts = contacts?.filter((contact: any) => {
        return (
            contact.name?.toLowerCase().includes(search.toLowerCase()) ||
            contact.email?.toLowerCase().includes(search.toLowerCase())
        )
    })

    return (
        <div className='p-[16px] pl-0'>
            <div className='mb-[8px] w-full flex gap-[16px]'>
                <FilterToolBar
                    search={search}
                    setSearch={setSearch}
                    filterBarObject={undefined}
                    className={'border border-blue/25 dark:border-0 dark:bg-darkbg2 rounded-[4px]'}
                />
                <button
                    onClick={() => setShowQuickModal('contact')}
                    className='shrink-0 text-[14px] leading-[1] py-[2px] px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase'
                >
                    + New Contact
                </button>
            </div>
            <div className='flex flex-col overflow-auto'>
                <div className='sticky top-0 grid grid-cols-[1fr_1fr_1fr_1fr_1fr_50px] col-span-full border-b border-lightgrey bg-blue text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                    <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Company</div>
                    <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Name</div>
                    <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Email</div>
                    <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Phone</div>
                    <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Alternate</div>
                </div>
                {filteredContacts?.map((contact: any) => {
                    return <ParentContact key={contact.id} contact={contact} />
                })}
            </div>
        </div>
    )
}
