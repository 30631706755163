import { MetafieldProps, PlugType } from '../ProductSetup.types'
import PlugList from '../components/PlugList'
import { DEFAULT_PLUGS } from '../constants/plugs'

export default function Plugs({ product, setProduct, handleUpdateMetafield, edited }: MetafieldProps) {
    const availablePlugs = DEFAULT_PLUGS.filter(
        (defaultPlug) => !product.plugs?.find((plug) => plug.includes(defaultPlug.type))
    )
    const selectedPlugs = (product.plugs || [])
        .map((plug) => {
            const [quantity, type] = plug
                .replaceAll('(', '')
                .split(')')
                .map((p) => p.trim())
            return {
                ...DEFAULT_PLUGS.find((defaultPlug) => defaultPlug.type === type),
                quantity: parseInt(quantity),
            }
        })
        .filter((v) => v)
    return (
        <>
            <div className='flex gap-[16px]'>
                <div className='w-1/2 p-[16px] h-fit sticky top-[66px] rounded-[4px] shadow-small dark:bg-darkaccent overflow-hidden'>
                    <p className='mt-[-16px] ml-[-16px] mb-[16px] p-[4px] leading-[1] w-fit p-[2px] text-[12px] uppercase font-bold bg-blue dark:bg-accent text-white dark:text-darkness'>
                        Selected Plugs
                    </p>
                    <PlugList plugs={selectedPlugs as PlugType[]} setProduct={setProduct} />
                    {edited && (
                        <button
                            className='block py-[4px] px-[8px] bg-blue dark:bg-accent text-white dark:text-darkaccent rounded-[4px] mx-auto mt-[16px] font-bold uppercase'
                            onClick={() =>
                                handleUpdateMetafield([
                                    {
                                        ownerId: `gid://shopify/Product/${product.id}`,
                                        namespace: 'custom',
                                        key: 'plugs',
                                        type: 'list.single_line_text_field',
                                        value: JSON.stringify(product.plugs),
                                    },
                                ])
                            }
                        >
                            Save Changes
                        </button>
                    )}
                </div>
                <div className='w-1/2 p-[16px] h-fit sticky top-[66px] rounded-[4px] shadow-small dark:bg-darkaccent overflow-hidden'>
                    <p className='mt-[-16px] ml-[-16px] mb-[16px] p-[4px] leading-[1] w-fit p-[2px] text-[12px] uppercase font-bold bg-blue dark:bg-accent text-white dark:text-darkness'>
                        Available Plugs
                    </p>
                    <PlugList plugs={availablePlugs} setProduct={setProduct} />
                </div>
            </div>
            {selectedPlugs.length > 0 && (
                <>
                    <h2 className='dark:text-offwhite font-light uppercase text-[24px] my-[16px]'>Plugs Preview</h2>
                    <div className='bg-white description-preview w-fit !m-0 dark:opacity-[0.9] dark:text-black'>
                        {selectedPlugs.map((plug) => (
                            <div className='pdp__description__plugs__row'>
                                <div className='pdp__description__plugs__row__container'>
                                    <div className='pdp__description__plugs__row__container__image-container'>
                                        <img
                                            className='pdp__description__plugs__row__container__image-container__img'
                                            src={plug.image_src}
                                            alt='checkmark'
                                            width='auto'
                                            height='auto'
                                            loading='lazy'
                                        />
                                    </div>
                                    <p className='pdp__description__plugs__row__container__text'>
                                        &times;{plug.quantity}
                                    </p>
                                </div>
                                <div className='pdp__description__plugs__row__details'>
                                    <p className='pdp__description__plugs__row__details__name'>{plug.name}</p>
                                    <p className='pdp__description__plugs__row__details__type'>({plug.type})</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </>
    )
}
