import { Button } from 'custom_components/component_Basics/Button'
import { useDebounce } from 'helpers'
import Input from 'procurement/components/Input'
import Select from 'procurement/components/Select'
import Textarea from 'procurement/components/Textarea'
import {
    useDeleteSupplierProducts,
    useGetSupplierProducts,
    useGetSuppliers,
    useUpdateSupplier,
} from 'purchaseOrdersV2/api/useQueries'
import SupplierProductRow from 'purchaseOrdersV2/lists/SupplierProductRow'
import { useEffect, useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import AddProductsModal from './AddProductsModal'

export default function EditSupplierModal({
    selectedSupplierId,
    closeModal,
}: {
    selectedSupplierId: string
    closeModal: () => void
}) {
    const [editedFields, setEditedFields] = useState<any>({})
    const [selectedProducts, setSelectedProducts] = useState<any[]>([])
    const [productSearch, setProductSearch] = useState('')
    const [showAddProductsModal, setShowAddProductsModal] = useState(false)
    const removeProductsMutation = useDeleteSupplierProducts()
    const debouncedProductSearch = useDebounce(productSearch, 300)
    const suppliersQuery = useGetSuppliers({ id: selectedSupplierId })
    const supplierProductsQuery = useGetSupplierProducts({
        supplier_id: selectedSupplierId,
        search: debouncedProductSearch,
    })
    const supplierProducts = supplierProductsQuery.data?.products || []
    const updateSupplierMutation = useUpdateSupplier()
    const supplier = suppliersQuery.data?.suppliers.find((s: any) => s.id.toString() === selectedSupplierId) || {}
    const modalRef = useRef<HTMLDivElement>(null)
    const selectedPOType = editedFields.po_type ?? supplier.po_type
    const edited = Object.keys(editedFields).length > 0
    const allSelected = selectedProducts.length === supplierProducts.length

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
        setEditedFields((prev: any) => ({
            ...prev,
            [target.name]: target.value,
        }))
    }

    const handleUpdate = () => {
        updateSupplierMutation.mutate(
            {
                ...editedFields,
                id: supplier.id,
            },
            { onSuccess: () => setEditedFields({}) }
        )
    }

    const toggleAllSelected = () => {
        if (allSelected) {
            return setSelectedProducts([])
        }
        setSelectedProducts(structuredClone(supplierProducts))
    }

    const handleRemoveProducts = () => {
        removeProductsMutation.mutate(
            {
                product_ids: selectedProducts.map((p) => p.id),
                supplier_id: parseInt(selectedSupplierId),
            },
            {
                onSuccess: () => {
                    setSelectedProducts([])
                },
            }
        )
    }

    useEffect(() => {
        const listenToWindow = (event: MouseEvent) => {
            if (modalRef.current === event.target) {
                closeModal()
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <div
            ref={modalRef}
            className='fixed z-50 top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center'
        >
            <div className='relative p-6 max-w-[1000px] w-full max-h-[90%] bg-bg1 dark:bg-darkbg1 rounded shadow-md'>
                <button
                    onClick={() => closeModal()}
                    className='text-red dark:text-lightred font-bold text-lg absolute top-1 right-1'
                >
                    <FaTimes />
                </button>
                <h2 className='font-bold text-lg mb-2'>Edit Supplier</h2>
                <div className='grid grid-cols-[1fr_400px_1fr] gap-2'>
                    <div className='flex flex-col gap-2'>
                        <Input
                            label='Name'
                            type='text'
                            id='name'
                            name='name'
                            value={editedFields.name ?? supplier.name}
                            onChange={handleChange}
                        />
                        <Input
                            label='Contact Name'
                            type='text'
                            id='contact_name'
                            name='contact_name'
                            value={editedFields.contact_name ?? supplier.contact_name}
                            onChange={handleChange}
                        />
                        <Select
                            label='Status'
                            id='test_mode'
                            name='test_mode'
                            value={editedFields.test_mode ?? supplier.test_mode}
                            onChange={handleChange}
                        >
                            <option value=''>Select a status</option>
                            <option value='0'>Live</option>
                            <option value='1'>Testing</option>
                        </Select>
                        <Select
                            label='Country'
                            id='country'
                            name='country'
                            value={editedFields.country ?? supplier.country}
                            onChange={handleChange}
                        >
                            <option value=''>Select a country</option>
                            <option value='US'>United States</option>
                            <option value='CA'>Canada</option>
                        </Select>
                        <Select
                            label='Currency'
                            id='currency'
                            name='currency'
                            value={editedFields.currency ?? supplier.currency}
                            onChange={handleChange}
                        >
                            <option value=''>Select a currency</option>
                            <option value='USD'>USD</option>
                            <option value='CAD'>CAD</option>
                        </Select>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <Select
                            label='Purchase Order Type'
                            id='po_type'
                            name='po_type'
                            value={editedFields.po_type ?? supplier.po_type}
                            onChange={handleChange}
                        >
                            <option value=''>Select a Purchase Order type</option>
                            <option value='email'>Email</option>
                            <option value='edi'>EDI</option>
                        </Select>
                        {selectedPOType === 'email' && (
                            <>
                                <Select
                                    label='Show Cost On Purchase Order'
                                    id='show_cost'
                                    name='show_cost'
                                    value={editedFields.show_cost ?? supplier.show_cost}
                                    onChange={handleChange}
                                >
                                    <option value=''>Select an option</option>
                                    <option value='1'>Yes</option>
                                    <option value='0'>No</option>
                                </Select>
                                <Textarea
                                    label='Email Addresses (Comma Separated)'
                                    id='email_addresses'
                                    name='email_addresses'
                                    value={editedFields.email_addresses ?? supplier.email_addresses}
                                    onChange={handleChange}
                                />
                            </>
                        )}
                        {selectedPOType === 'edi' && (
                            <div className='flex gap-2'>
                                <div className='flex flex-col gap-2'>
                                    <Input
                                        label='Test EDI ISA Qualifier'
                                        type='text'
                                        id='edi_test_isa_qualifier'
                                        name='edi_test_isa_qualifier'
                                        value={editedFields.edi_test_isa_qualifier ?? supplier.edi_test_isa_qualifier}
                                        onChange={handleChange}
                                    />
                                    <Input
                                        label='Test EDI ISA Identifier'
                                        type='text'
                                        id='edi_test_isa_identifier'
                                        name='edi_test_isa_identifier'
                                        value={editedFields.edi_test_isa_identifier ?? supplier.edi_test_isa_identifier}
                                        onChange={handleChange}
                                    />
                                    <Input
                                        label='Test EDI GS Identifier'
                                        type='text'
                                        id='edi_test_gs_identifier'
                                        name='edi_test_gs_identifier'
                                        value={editedFields.edi_test_gs_identifier ?? supplier.edi_test_gs_identifier}
                                        onChange={handleChange}
                                    />
                                    <Input
                                        label='Test EDI AS2 Station'
                                        type='text'
                                        id='edi_test_as2_station'
                                        name='edi_test_as2_station'
                                        value={editedFields.edi_test_as2_station ?? supplier.edi_test_as2_station}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <Input
                                        label='Production EDI ISA Qualifier'
                                        type='text'
                                        id='edi_isa_qualifier'
                                        name='edi_isa_qualifier'
                                        value={editedFields.edi_isa_qualifier ?? supplier.edi_isa_qualifier}
                                        onChange={handleChange}
                                    />
                                    <Input
                                        label='Production EDI ISA Identifier'
                                        type='text'
                                        id='edi_isa_identifier'
                                        name='edi_isa_identifier'
                                        value={editedFields.edi_isa_identifier ?? supplier.edi_isa_identifier}
                                        onChange={handleChange}
                                    />
                                    <Input
                                        label='Production EDI GS Identifier'
                                        type='text'
                                        id='edi_gs_identifier'
                                        name='edi_gs_identifier'
                                        value={editedFields.edi_gs_identifier ?? supplier.edi_gs_identifier}
                                        onChange={handleChange}
                                    />
                                    <Input
                                        label='Production EDI AS2 Station'
                                        type='text'
                                        id='edi_as2_station'
                                        name='edi_as2_station'
                                        value={editedFields.edi_as2_station ?? supplier.edi_as2_station}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='flex flex-col gap-2'>
                        <Textarea
                            label='Purchase Order Default Note'
                            id='po_note'
                            name='po_note'
                            value={editedFields.po_note ?? supplier.po_note}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='mt-2'>
                    <h2 className='text-base font-bold mb-1'>Supplier Products</h2>
                    <div className='shadow-md rounded border border-lightgrey dark:border-darkgrey'>
                        <div className='flex'>
                            <input
                                className='w-full p-2 focus:outline-none bg-transparent'
                                placeholder='Search supplier products...'
                                type='text'
                                name='product_search'
                                id='product_search'
                                value={productSearch}
                                onChange={({ target }) => setProductSearch(target.value)}
                            />
                            <Button
                                onClick={() => setShowAddProductsModal(true)}
                                variant={'outline'}
                                className='m-1 h-fit p-1'
                            >
                                + Add Products
                            </Button>
                        </div>
                        <div className='sticky top-0 flex gap-2 items-center p-2 border-b border-lightgrey dark:border-darkgrey bg-accent1 dark:bg-darkbg2 text-white dark:text-offwhite font-bold'>
                            <button
                                onClick={toggleAllSelected}
                                className={`w-4 h-4 rounded border border-lighgrey dark:border-darkgrey ${
                                    allSelected ? 'bg-white dark:bg-offwhite' : ''
                                }`}
                            ></button>
                            <span>Product Title</span>
                            {selectedProducts.length > 0 && (
                                <Button
                                    onClick={handleRemoveProducts}
                                    className='block ml-auto h-fit px-2 py-[2px] font-bold'
                                    variant={'destructive'}
                                    size={'sm'}
                                >
                                    Remove Selected
                                </Button>
                            )}
                        </div>
                        <div className='max-h-[200px] overflow-auto'>
                            {supplierProducts.map((product: any) => (
                                <SupplierProductRow
                                    product={product}
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                {showAddProductsModal && (
                    <AddProductsModal
                        currentProducts={supplierProducts}
                        selectedSupplierId={selectedSupplierId}
                        closeModal={() => setShowAddProductsModal(false)}
                    />
                )}
                {edited && (
                    <Button onClick={handleUpdate} className='block mt-4 mx-auto' variant={'outline'}>
                        Save Changes
                    </Button>
                )}
            </div>
        </div>
    )
}
