import { useSelector } from 'react-redux'
import { FP_SIGNATURE_LINK_ROW, FP_SIGNATURE_LOGO_URL } from '../tasks/constants'

export const Signature = ({}) => {
    const user = useSelector((state) => state.user)
    return (
        <div className='dark:text-offwhite'>
            <p>
                <strong>{user.firstName} with FactoryPure</strong>
                <br />
                <strong>Sales & Customer Service: 888-999-1522 (M-F 8-5:30 PM CT)</strong>
            </p>
            <img src={FP_SIGNATURE_LOGO_URL} alt='FactoryPure Logo' width='120' height='40' />
            <div className='white-override' dangerouslySetInnerHTML={{ __html: FP_SIGNATURE_LINK_ROW }}></div>
            <style>{`
                .dark .white-override * {
                    color: white !important;
                }
            `}</style>
        </div>
    )
}
