import { useSelector } from 'react-redux'
import { Dispatch, SetStateAction } from 'react'
import TaskRow from './TaskRow'
import { useTasks } from '../api/useQueries'
import { sortTasksByType } from '../helpers'

export default function MyTasks({ setQuickModal }: { setQuickModal: Dispatch<SetStateAction<undefined | string>> }) {
    const user = useSelector((state: any) => state.user)
    const tasksQuery = useTasks()
    const { data: tasks, isPending } = tasksQuery

    const userTasks = (tasks || [])
        .filter((task: any) => task.assigned_to === user.id && task.status !== 'done')
        .sort(sortTasksByType)
    return (
        <div className='rounded shadow-lg w-full border border-lightgrey dark:border-darkaccent'>
            <div className='flex justify-between dark:bg-darkaccent rounded-t py-2 px-4'>
                <h2 className='font-bold text-[18px] leading-tight'>My Tasks</h2>
                <button
                    className='h-fit bg-blue dark:bg-accent text-white text-[14px] dark:text-darkaccent font-bold py-1 px-2 leading-tight rounded'
                    onClick={() => setQuickModal('task')}
                >
                    + New Task
                </button>
            </div>
            <div className='w-full h-[1px] bg-blue dark:bg-slate'></div>
            {isPending ? (
                <p className='p-4'>Loading...</p>
            ) : (
                <>
                    {userTasks.length === 0 ? (
                        <p className='p-4'>You have no tasks assigned to you.</p>
                    ) : (
                        userTasks.map((task: any, index: number) => (
                            <TaskRow task={task} last={index === userTasks.length - 1} />
                        ))
                    )}
                </>
            )}
        </div>
    )
}
