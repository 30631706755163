import { CustomerAddressType } from 'customersV2/Customers.types'
import CustomerAddressRow from './CustomerAddressRow'
import { useSearchParams } from 'react-router-dom'
import EditAddressModal from 'customersV2/modals/EditAddressModal'
import { Button } from 'custom_components/component_Basics/Button'
import { useState } from 'react'
import CreateAddressModal from 'customersV2/modals/CreateAddressModal'

export default function CustomerAddressesList({ addresses }: { addresses: CustomerAddressType[] }) {
    const [searchParams, setSearchParams] = useSearchParams()
    const [showCreateModal, setShowCreateModal] = useState(false)
    const addressId = searchParams.get('address_id')
    const customerId = searchParams.get('customer_id')

    return (
        <>
            <div className='flex items-center justify-between mb-2'>
                <h3 className='text-base font-bold'>Addresses</h3>
                <Button onClick={() => setShowCreateModal(true)} className='py-1 px-2 h-fit leading-none'>
                    + New
                </Button>
            </div>
            <div className='h-full overflow-auto flex flex-col gap-2 pr-2'>
                {addresses.map((address: any) => (
                    <CustomerAddressRow address={address} />
                ))}
            </div>
            {customerId && addressId && (
                <EditAddressModal
                    toggleModal={() =>
                        setSearchParams((prev) => {
                            prev.delete('address_id')
                            return prev
                        })
                    }
                    customerId={customerId}
                    addressId={addressId}
                />
            )}
            {showCreateModal && customerId && (
                <CreateAddressModal toggleModal={() => setShowCreateModal(false)} customerId={customerId} />
            )}
        </>
    )
}
