import { useMutation, useQuery } from '@tanstack/react-query'
import { CustomerAddressType, CustomerOrderType, CustomerType } from 'customersV2/Customers.types'
import CustomerContactInfo from 'customersV2/components/CustomerContactInfo'
import CustomerHeader from 'customersV2/components/CustomerHeader'
import CustomerAddressesList from 'customersV2/lists/CustomerAddressesList'
import CustomerOrdersList from 'customersV2/lists/CustomerOrdersList'
import { vFetch } from 'helpers'
import { parseIfJson } from 'purchaseOrdersV2/helpers'
import { useEffect, useRef } from 'react'
import { FaTimes } from 'react-icons/fa'

export default function CustomerModal({ customer_id, toggleModal }: { customer_id: string; toggleModal: () => void }) {
    const modalRef = useRef<HTMLDivElement>(null)
    const customerQuery = useQuery({
        queryKey: ['customers', customer_id],
        queryFn: () => vFetch(`/v2/customers?id=${customer_id}`),
    })
    const customerAddressesQuery = useQuery({
        queryKey: ['customers', customer_id, 'addresses'],
        queryFn: () => vFetch(`/v2/customers/addresses?customer_id=${customer_id}`),
    })
    const customerOrdersQuery = useQuery({
        queryKey: ['customers', customer_id, 'orders'],
        queryFn: () => vFetch(`/v2/customers/orders?customer_id=${customer_id}`),
    })
    const customerResult = customerQuery?.data?.customers ? customerQuery?.data?.customers[0] || null : null
    const customer: CustomerType | null = customerResult ? (parseIfJson(customerResult) as CustomerType) : null
    const addresses: CustomerAddressType[] = customerAddressesQuery?.data?.addresses || []
    const orders: CustomerOrderType[] = (customerOrdersQuery?.data?.orders || []).map((order: any) =>
        parseIfJson(order)
    )

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (modalRef.current === e.target) {
                toggleModal()
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <div
            ref={modalRef}
            className='fixed w-full h-full top-0 left-0 flex items-center justify-center bg-black/50 z-50'
        >
            <div className='p-6 relative rounded shadow-sm bg-white dark:bg-darkbg1 text-sm w-[90%] h-[90%] flex flex-col'>
                {customerQuery.isLoading ? (
                    <p>Loading...</p>
                ) : !customer ? (
                    <p>Customer Not Found!</p>
                ) : (
                    <>
                        <button className='absolute top-1 right-1 text-base' onClick={toggleModal}>
                            <FaTimes className='text-red dark:text-lightred' />
                        </button>
                        <CustomerHeader customer={customer} />
                        <div className='flex gap-4 min-h-0 h-full border-t border-lightgrey dark:border-darkgrey pt-2'>
                            <div className='w-1/4'>
                                <CustomerContactInfo customer={customer} />
                            </div>
                            <div className='w-1/2 flex flex-col'>
                                {customerOrdersQuery.isLoading ? (
                                    <p>Loading...</p>
                                ) : (
                                    <CustomerOrdersList orders={orders} />
                                )}
                            </div>
                            <div className='w-1/4 flex flex-col'>
                                {customerAddressesQuery.isLoading ? (
                                    <p>Loading...</p>
                                ) : (
                                    <CustomerAddressesList addresses={addresses} />
                                )}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
