import { CheckCircledIcon, Cross2Icon, CrossCircledIcon, ExternalLinkIcon } from '@radix-ui/react-icons'
import { Button } from 'custom_components/component_Basics/Button'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { cn, sendToast, useDebounce } from 'helpers'
import {
    useAddGroupProductLink,
    useGetGroupedProductIdsQuery,
    useGetProductsQuery,
} from 'productSetupV2/api/useQueries'
import { ShopifyProduct } from 'productSetupV2/ProductSetup.types'
import { useEffect, useRef, useState } from 'react'
import { FaCircleExclamation } from 'react-icons/fa6'
import { Link, useParams } from 'react-router-dom'
import { Product } from './ProductGroupDetail'

export default function ProductGroupDetailSearch({ selectedProducts }: { selectedProducts: Product[] | undefined }) {
    const [search, setSearch] = useState('')
    const { groupId } = useParams()
    const debouncedSearch = useDebounce(search.trim(), 800)
    const searchComponentRef = useRef<HTMLInputElement>(null)
    const searchInputRef = useRef<HTMLInputElement>(null)
    const [showExpandResults, setShowExpandResults] = useState(false)

    const showResults = debouncedSearch ? true : false

    const productsQuery = useGetProductsQuery({}, debouncedSearch)
    const { products } = productsQuery?.data || {}

    const groupedProductInfoQuery = useGetGroupedProductIdsQuery()
    const { groupedProductInfo }: { groupedProductInfo: { product_id: number; group_id: number }[] } =
        groupedProductInfoQuery?.data || {}

    const addGroupProductLink = useAddGroupProductLink()

    function idSort(a: any, b: any) {
        return a.id - b.id
    }
    if (products?.length > 2) {
        products.sort(idSort)
    }

    const handleClearSearch = () => {
        setSearch('')
        setShowExpandResults(false)
    }
    const handleAddProduct = (productId: string | number) => {
        if (groupId && productId) {
            addGroupProductLink.mutate(
                { groupId, productId },
                {
                    onSuccess: () => {},
                }
            )
        }
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!searchComponentRef.current?.contains(e.target as HTMLElement)) {
                setShowExpandResults(false)
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    function handleFocusInput(): void {
        if (searchInputRef.current) {
            searchInputRef.current.focus()
        }
    }

    return (
        <div
            ref={searchComponentRef}
            className='w-2/3 relative h-[280px] p-[16px] pt-[20px] rounded-[4px] border border-blue/25 dark:border-darkgrey dark:bg-darkness shadow-md'
        >
            <p className='absolute top-0 left-0 bg-blue dark:bg-accent rounded-tl-[4px] text-white dark:text-darkness leading-[1] text-[10px] uppercase font-bold p-[2px] px-1'>
                Add Products
            </p>
            <div className='flex flex-col gap-1 dark:text-offwhite border-b pb-1 mb-1 dark:border-darkgrey'>
                <div className='w-full flex relative '>
                    <input
                        ref={searchInputRef}
                        onFocus={() => setShowExpandResults(true)}
                        type='text'
                        className='w-full bg-transparent dark:text-white focus:outline-none p-1'
                        placeholder='Search'
                        value={search}
                        onChange={({ target }) => {
                            setSearch(target.value)
                        }}
                    />
                    <div className='self-center'>
                        <Button
                            disabled={search ? false : true}
                            variant='destructive'
                            onClick={handleClearSearch}
                            className={`${cn(
                                !search && 'text-darkgrey dark:text-darkgrey opacity-50 bg-bg1 dark:bg-darkbg1',
                                search && 'border border-red'
                            )} h-8 px-1 lg:px-2`}
                        >
                            Clear Search
                            <Cross2Icon className='ml-2 h-4 w-4' />
                        </Button>
                    </div>
                </div>
            </div>
            <div
                className={cn(
                    'overflow-hidden border  border-lightgrey dark:border-darkgrey rounded-[4px]',
                    showExpandResults && 'relative z-30 border-darkgrey dark:border-lightgrey'
                )}
            >
                <div
                    className={cn(
                        'flex flex-col max-h-[200px] bg-white dark:bg-darkaccent w-full  overflow-auto',
                        showExpandResults && 'z-30 w-full max-h-[350px] overflow-auto'
                    )}
                >
                    {!showResults && (
                        <div
                            onClick={handleFocusInput}
                            className={cn('text-left p-2 px-0 border-b border-lightgrey dark:border-darkgrey')}
                        >
                            <div className='flex items-center justify-center'>
                                <p>Search for products</p>
                            </div>
                        </div>
                    )}
                    {showResults && productsQuery.isFetching && (
                        <div className={cn('text-left p-2 px-0 border-b border-lightgrey dark:border-darkgrey')}>
                            <div className='flex gap-4 items-center justify-center'>
                                <p>Loading Products</p>
                                <FetchingSpinner className='mr-4' isFetching={productsQuery.isFetching} />
                            </div>
                        </div>
                    )}
                    {showResults && !productsQuery.isFetching && products.length < 1 && (
                        <div className={cn('text-left p-2 px-0 border-b border-lightgrey dark:border-darkgrey')}>
                            <div className='flex gap-4 items-center justify-center'>
                                <p>No results</p>
                                <div className='flex items-center justify-center '>{<FaCircleExclamation />}</div>
                            </div>
                        </div>
                    )}

                    {showResults &&
                        !productsQuery.isFetching &&
                        products
                            ?.filter((v: ShopifyProduct) => v)
                            .map((product: ShopifyProduct) => {
                                const relatedGroupedProductInfo = groupedProductInfo?.find(
                                    (groupedProductInfo) => groupedProductInfo.product_id == product.id
                                )

                                const isGrouped = Boolean(relatedGroupedProductInfo)
                                const isSelected = selectedProducts
                                    ?.map((selectedProduct: any) => selectedProduct.product_id)
                                    .includes(product.id)

                                return (
                                    <button
                                        key={product.id}
                                        disabled={addGroupProductLink.isPending}
                                        onClick={() => {
                                            if (isSelected) {
                                                return sendToast({ message: 'Product already in group' })
                                            }
                                            if (isGrouped) {
                                                return sendToast(
                                                    {
                                                        message: (
                                                            <Link
                                                                id='product_id'
                                                                target='_blank'
                                                                rel='noopener noreferrer'
                                                                to={`/products/product-groups/${relatedGroupedProductInfo?.group_id}`}
                                                                draggable={false}
                                                                className='flex gap-2 underline items-center w-full relative'
                                                            >
                                                                <p>Product belongs to another group</p>
                                                                <ExternalLinkIcon />
                                                            </Link>
                                                        ),
                                                    },
                                                    { autoClose: 8000 }
                                                )
                                            }
                                            if (product.id) {
                                                handleAddProduct(product.id)
                                            }
                                            return
                                        }}
                                        className={cn(
                                            'text-left p-2 px-0 border-b border-lightgrey dark:border-darkgrey',
                                            isSelected && 'cursor-default',
                                            isGrouped && 'opacity-60 cursor-default'
                                        )}
                                    >
                                        <div className='grid grid-cols-[30px_2fr_1fr_.8fr]'>
                                            <div className='flex items-center justify-center'>
                                                {isSelected && <CheckCircledIcon className='text-success' />}
                                                {isGrouped && !isSelected && <CrossCircledIcon />}
                                            </div>
                                            <p>{product.title}</p>
                                            <p>{product.sku}</p>
                                            <p>{product.id}</p>
                                        </div>
                                    </button>
                                )
                            })}
                </div>
            </div>
        </div>
    )
}
