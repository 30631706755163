import { vFetch } from '../../helpers'

const taxExemptionsURL = '/v1/taxExemptions'

export const getTaxExemptions = async (filters: any, search?: string) => {
    const queryParams = [
        filters.statuses?.length ? `statuses=${filters.statuses}` : null,
        filters.exemptions?.length ? `exemptionTypes=${filters.exemptions}` : null,
        filters.has_forms ? `has_forms=${filters.has_forms}` : null,
        filters.page ? `page=${filters.page}` : null,
        search ? `search=${encodeURIComponent(search)}` : null,
    ]
        .filter((v) => v)
        .join('&')

    return await vFetch(`${taxExemptionsURL}?${queryParams}`, {
        reThrowErr: true,
    })
}
export const getTaxExemptionAudit = async (taxExemptionId: any) => {
    const queryParams = [taxExemptionId ? `record_id=${taxExemptionId}` : null].filter((v) => v).join('&')

    return await vFetch(`${taxExemptionsURL}?${queryParams}`, {
        reThrowErr: true,
    })
}

export const getTaxExemptionDetail = async (taxExemptionId: any) => {
    const queryParams = [taxExemptionId ? `id=${taxExemptionId}` : null].filter((v) => v).join('&')

    return await vFetch(`${taxExemptionsURL}?${queryParams}`, {
        reThrowErr: true,
    })
}
export const getTaxExemptionForms = async (taxExemptionId: any) => {
    return await vFetch(`${taxExemptionsURL}/forms?tax_exemption_id=${taxExemptionId}`, {
        reThrowErr: true,
    })
}
export const deleteTaxExemption = async (taxExemptionId: any) => {
    return await vFetch(`${taxExemptionsURL}`, {
        method: 'DELETE',
        body: JSON.stringify({ id: taxExemptionId }),
        reThrowErr: true,
    })
}
export const updateTaxExemption = async (taxExemption: any) => {
    return await vFetch(`${taxExemptionsURL}`, {
        method: 'PUT',
        body: JSON.stringify(taxExemption),
        reThrowErr: true,
    })
}
