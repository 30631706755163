import { SkeletonBasic } from '../../../custom_components/component_Basics/SkeletonBasic'

export default function BasicEventSkeleton() {
    return (
        <div className='max-w-full relative p-[16px] pt-[20px] rounded-[4px] border border-blue/25 dark:border-darkgrey dark:bg-darkness shadow-md'>
            <div className='max-w-full grid grid-cols-[minmax(900px,_1fr)_100px]'>
                <SkeletonBasic className=' bg-grey w-[200px]   m-2  p-2'></SkeletonBasic>
                <SkeletonBasic className=' bg-grey  m-2  p-2'></SkeletonBasic>
            </div>
        </div>
    )
}
