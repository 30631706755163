import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { LoadingGear } from '../../helpers'

export default function RawEDIModal({
    rawEDI,
    setShowModal,
    setSelectedEDI,
}: {
    rawEDI: string
    setShowModal: Dispatch<SetStateAction<string | undefined>>
    setSelectedEDI: Dispatch<SetStateAction<string>>
}) {
    const modalRef = useRef(null)

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (e.target === modalRef.current) {
                setShowModal(undefined)
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <div
            ref={modalRef}
            className='fixed z-[50] top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] grid place-items-center'
        >
            <div className='p-[32px] gap-[32px] w-[80vw] h-[80vh] bg-white dark:bg-darkaccent rounded-[4px] shadow-small relative'>
                <button
                    onClick={() => {
                        setShowModal(undefined)
                        setSelectedEDI('')
                    }}
                    className='leading-[1] text-[24px] text-red dark:text-lightred absolute font-bold top-0 right-[8px]'
                >
                    &times;
                </button>
                <div className='relative z-[6] flex flex-col gap-[16px] h-full w-full'>
                    <h2 className='text-darkgrey dark:text-fire text-[24px] font-bold uppercase leading-[1] m-0 mb-[16px]'>
                        RAW EDI
                    </h2>
                    <pre className='block h-full w-full p-[16px] rounded-[4px] bg-lightgrey dark:bg-darkness dark:text-offwhite overflow-auto'>
                        {rawEDI
                            .split('~')
                            .map((seg) => seg.trim())
                            .join('\n')}
                    </pre>
                </div>
                <div className='flex gap-[16px]'></div>
            </div>
        </div>
    )
}
