import { cn, parseResObject } from 'helpers'
import { useUpdateTask } from 'tasksV2/api/useQueries'
import { useSelector } from 'react-redux'
import { useGetAllDepartmentConfigs } from 'tasksV2/api/useQueries'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'

export default function RecurringTaskType({
    departmentId,
    typeId,
    handleChange,
}: {
    departmentId: number
    typeId: number
    handleChange: (...args: any) => void
}) {
    const user = useSelector<any, any>((state) => state.user)
    const departmentConfigs = useGetAllDepartmentConfigs(user.id)
    const configs = departmentConfigs.data?.departments || []
    const foundConfig = parseResObject(configs.find((config: any) => config.id === departmentId) || {})
    const foundTypeOption = foundConfig.types?.find((type: any) => type.id === typeId)

    return (
        <div className='flex gap-2 items-center '>
            <select
                onClick={(e) => e.stopPropagation()}
                className={'border border-lightgrey dark:border-darkgrey rounded dark:bg-darkbg1'}
                id='type_id'
                name='type_id'
                value={foundTypeOption?.id ?? ''}
                onChange={handleChange}
            >
                {!typeId && <option value=''>No Type Selected</option>}
                {foundConfig?.types?.map((type: any) => <option value={type.id}>{type.value}</option>)}
            </select>
        </div>
    )
}
