import { Dispatch, SetStateAction } from 'react'
import { PlugType, ShopifyProduct } from '../ProductSetup.types'
import { BsFillTrash3Fill } from 'react-icons/bs'

export default function PlugList({
    plugs,
    setProduct,
}: {
    plugs: PlugType[]
    setProduct: Dispatch<SetStateAction<ShopifyProduct>>
}) {
    const handleChangeQuantity = ({ target }: { target: HTMLInputElement }, type: string, index: number) => {
        setProduct((previousState) => {
            const newState = structuredClone(previousState)
            if (newState.plugs) {
                newState.plugs.splice(index, 1, `(${target.value}) ${type}`)
            }
            return newState
        })
    }
    return (
        <>
            {plugs.length > 0 ? (
                plugs.map((plug, index) => (
                    <div
                        onClick={() =>
                            plug.quantity === undefined
                                ? setProduct((previousState) => ({
                                      ...previousState,
                                      plugs: [...(previousState.plugs || []), `(1) ${plug.type}`],
                                  }))
                                : () => {}
                        }
                        className={`${
                            plug.quantity === undefined ? 'cursor-pointer' : ''
                        } relative w-full p-[16px] flex gap-[16px] border border-grey rounded-[4px] dark:border-darkgrey mb-[8px]`}
                    >
                        <div className='w-[50px] h-[50px]'>
                            <img
                                className='w-full h-full dark:invert dark:opacity-[0.85]'
                                src={plug.image_src}
                                alt='checkmark'
                                width='auto'
                                height='auto'
                                loading='lazy'
                            />
                        </div>
                        <div className=''>
                            <p className='font-bold text-[18px] dark:text-offwhite'>{plug.name}</p>
                            <p className='dark:text-offwhite'>({plug.type})</p>
                        </div>
                        {plug.quantity !== undefined && (
                            <>
                                <div className='flex flex-col items-center gap-[4px] w-[40px] ml-auto mr-[24px]'>
                                    <label className='block text-[12px] leading-[1] uppercase font-bold dark:text-offwhite'>
                                        QTY
                                    </label>
                                    <input
                                        className='block bg-lightgrey w-full p-[4px] rounded-[4px] dark:bg-faintplus dark:text-white text-center'
                                        type='number'
                                        value={plug.quantity}
                                        onChange={(e) => handleChangeQuantity(e, plug.type, index)}
                                    />
                                </div>
                                <button
                                    className='font-bold leading-[1] text-[32px] absolute top-0 right-[8px] text-red dark:text-lightred'
                                    onClick={() =>
                                        setProduct((previousState) => {
                                            const newState = structuredClone(previousState)
                                            if (newState.plugs) {
                                                newState.plugs.splice(index, 1)
                                            }
                                            return newState
                                        })
                                    }
                                >
                                    &times;
                                </button>
                            </>
                        )}
                    </div>
                ))
            ) : (
                <p className='dark:text-offwhite'>No plugs selected!</p>
            )}
        </>
    )
}
