export function formatReadableExemption(exemption: string) {
    try {
        const stringArr = exemption.split('_')
        const [country, province] = stringArr
        if (province.length > 2) {
            return `${country} ${stringArr
                .slice(1)
                .map((word) => word[0] + word.slice(1).toLowerCase())
                .join(' ')}`
        }
        return `${country} ${province} ${stringArr
            .slice(2)
            .map((word) => word[0] + word.slice(1).toLowerCase())
            .join(' ')}`
    } catch {
        return exemption
    }
}