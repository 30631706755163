import { EmailTemplateType } from 'followUpEmail/FollowUpEmail.types'
import Input from 'procurement/components/Input'
import Textarea from 'procurement/components/Textarea'

export default function EmailEditor({
    template,
    editedFields,
    handleChange,
}: {
    template: EmailTemplateType
    editedFields: any
    handleChange: ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => void
}) {
    return (
        <>
            <h3 className='font-bold text-sm'>Email</h3>
            <Input
                id='reply_to'
                name='reply_to'
                type='text'
                label='Reply To'
                value={editedFields.reply_to ?? template.reply_to}
                onChange={handleChange}
            />
            <Input
                id='from_name'
                name='from_name'
                type='text'
                label='From Name'
                value={editedFields.from_name ?? template.from_name}
                onChange={handleChange}
            />
            <Input
                id='from_email'
                name='from_email'
                type='text'
                label='From Email'
                value={editedFields.from_email ?? template.from_email}
                onChange={handleChange}
            />
            <Input
                id='bcc'
                name='bcc'
                type='text'
                label='BCC'
                value={editedFields.bcc ?? (template.bcc || '')}
                onChange={handleChange}
            />
            <Input
                id='subject'
                name='subject'
                type='text'
                label='Subject'
                value={editedFields.subject ?? template.subject}
                onChange={handleChange}
            />
            <Textarea
                id='body'
                name='body'
                label='Body'
                value={editedFields.body ?? template.body}
                onChange={handleChange}
            />
        </>
    )
}
