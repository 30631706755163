export const STATE_LIST = [
    'Alabama',
    'Alaska',
    'American Samoa',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District of Columbia',
    'Federated States of Micronesia',
    'Florida',
    'Georgia',
    'Guam',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Marshall Islands',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Northern Mariana Islands',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Palau',
    'Pennsylvania',
    'Puerto Rico',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virgin Island',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
]
export const STATE_CODE_LIST = [
    'AL',
    'AK',
    'AS',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FM',
    'FL',
    'GA',
    'GU',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MH',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'MP',
    'OH',
    'OK',
    'OR',
    'PW',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VI',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
]
export const FP_SIGNATURE_LOGO_URL =
    'https://cdn.shopify.com/s/files/1/1163/1976/files/factory-pure-logo-transparent-bg-outline.webp?v=1692279805' //no outline  // old non-transparent BG
export const FP_SIGNATURE_LINK_ROW = `
  <u>
      <span style="font-size:10pt;color:#0563c1">
          <a style="color:#1155cc" href="mailto:sales@factorypure.com" rel="noopener" target="_blank">
              sales@factorypure.com
          </a>
      </span>
  </u>
  <span style="font-size:10pt;color:#1f497d">&nbsp;&nbsp;</span>
  <span style="font-size:10pt">|&nbsp;&nbsp;</span>
  <u>
      <span style="font-size:10pt;color:#0563c1">
          <span style="color:#0563c1">
              <a style="color:#1155cc" title="FactoryPure" href="http://www.factorypure.com/" rel="noopener" target="_blank">
                  www.factorypure.com
              </a>
          </span>
      </span>
  </u>
`
