import LabelWithInfo from '../../productSetupV2/components/LabelWithInfo'
import Input from './Input'

export default function ShippingDetails({
    product,
    editableFields,
    handleChange,
    readOnly,
}: {
    product: any
    editableFields: any
    handleChange: any
    readOnly?: boolean
}) {
    return (
        <>
            <Input
                label='Shipping Dimensions'
                value={editableFields.shipping_dimensions ?? product.shipping_dimensions}
                id='shipping_dimensions'
                name='shipping_dimensions'
                onChange={handleChange}
                type='text'
                readOnly={readOnly}
            />
            <Input
                label='Shipping Weight'
                value={editableFields.shipping_weight ?? product.shipping_weight}
                id='shipping_weight'
                name='shipping_weight'
                onChange={handleChange}
                type='text'
                readOnly={readOnly}
            />
            <div className='flex flex-col gap-[4px] w-full'>
                <LabelWithInfo
                    htmlFor='shipping_method'
                    info='Provides details on shipping methods: Ground or Freight/Freight Class'
                    label='Shipping Method'
                />
                <textarea
                    className='w-full max-h-[140px] text-[16px] leading-snug bg-lightgrey border-0 focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                    value={editableFields.shipping_method ?? product.shipping_method ?? ''}
                    name='shipping_method'
                    onChange={handleChange}
                    readOnly={readOnly}
                ></textarea>
            </div>
        </>
    )
}
