import { DraftOrderInit, FulfillmentLineItemInit, LineItemInit, OrderInit } from '../../orders.types'
import ItemPrice from './itemPrice'

type BottomItemInfoProps = {
    item: LineItemInit
    order: OrderInit | DraftOrderInit
}

export default function BottomItemInfo({ item, order }: BottomItemInfoProps) {
    const shippingAttribute = order.note_attributes.find((attr) => attr.name === 'shipping_estimate')
    const shippingEstimates = shippingAttribute?.value.split('\n\n').filter((v) => v) || []
    const shippingEstimate = (shippingEstimates.find((estimate) => estimate.split(':')[0] == item.sku) || '').split(
        ':'
    )[1]
    return (
        <div
            key={item.id}
            className='flex gap-[16px] w-[100%] justify-between items-center col-span-2 text-[14px] py-[16px]'
        >
            <div className='flex gap-[16px]'>
                <div className='grid w-[100px] items-center relative'>
                    <img
                        className='object-contain min-w-[100px] min-h-[100px]'
                        src={window.IMAGE_MAP[`gid://shopify/Product/${item.product_id}`]}
                        alt={item.name}
                    />
                    <span className='absolute flex top-[-10px] right-[-10px] min-w-[20px] min-h-[20px] text-[12px] leading-[1px] items-center pb-[2px] border-[5px] border-[darkgrey] bg-[darkgrey] text-white font-bold rounded-full justify-center'>
                        {item.quantity}
                    </span>
                </div>
            </div>
            <div className='flex flex-col gap-[16px]'>
                <div className='flex flex-col gap-[4px]'>
                    <a
                        href={`https://factorypure.com/products/${item.handle}`}
                        target='_blank'
                        className='font-semibold hover:underline hover:text-blue dark:hover:text-accent pointer-events-auto'
                    >
                        {item.title}
                    </a>
                    {item.variant_title && (
                        <p>
                            <strong className='text-darkgrey dark:text-grey'>Variant:</strong> {item.variant_title}
                        </p>
                    )}
                    <p>
                        <strong className='text-darkgrey dark:text-grey'>SKU:</strong> {item.sku}
                    </p>
                    {shippingEstimate && (
                        <p>
                            <strong className='text-darkgrey dark:text-grey'>Shipping Estimate:</strong>{' '}
                            {shippingEstimate}
                        </p>
                    )}
                    {order.name.includes('D') && item.discount_allocations && (
                        <ul className='list-disc ml-[16px] mt-[8px]'>
                            {item.discount_allocations.map((discount) => (
                                <li key={discount.title + discount.description}>
                                    {discount.title ? discount.title : discount.description} discount applied
                                </li>
                            ))}
                        </ul>
                    )}
                    {!order.name.includes('D') && item.discount_allocations.length > 0 && (
                        <ul className='list-disc ml-[16px] mt-[8px]'>
                            {item.discount_allocations.map((discount) => (
                                <li key={order.discount_applications[discount.discount_application_index].code}>
                                    {order.discount_applications[discount.discount_application_index].title
                                        ? order.discount_applications[discount.discount_application_index].title
                                        : order.discount_applications[discount.discount_application_index].code}{' '}
                                    discount applied
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <div className='flex flex-wrap-reverse items-center justify-between gap-[8px] '>
                    <div className='flex grow basis-[0]'>
                        <a
                            className='p-[8px] truncate font-semibold text-white dark:text-black bg-[#007a5c] hover:bg-[#125443] dark:bg-fire rounded dark:hover:bg-[darkorange]'
                            href={`https://factorypure.myshopify.com/admin/products/${item.product_id}`}
                        >
                            View On Shopify
                        </a>
                    </div>
                    <ItemPrice order={order} item={item} />
                </div>
            </div>
        </div>
    )
}
