import { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import CloseButton from '../custom_components/CloseButton'
import { sortByAlphanumeric, vFetch } from '../helpers'

const userTypes = ['SuperAdmin', 'Admin', 'Client']
const accesses = [
    '*',
    'product-setup',
    'processing-times',
    'cost-adjuster',
    'procurement',
    'price-cuts',
    'edi',
    'shipping',
    'sales',
    'calls',
    'users',
    'scrape',
    'admin',
]

export default function UserScreensModal({ focus, setFocus, setNeedsRefresh, roles, showDeveloperSetRoles, stores }) {
    const user = useSelector((state) => state.user)
    const outer = useRef()
    const [showModal, setShowModal] = useState(false)
    const [edited, setEdited] = useState(false)
    const [userClone, setUserClone] = useState({ access: [], roles: [] })
    const [customRoles, setCustomRoles] = useState([])
    const [showDelete, setShowDelete] = useState(false)

    const canDelete = (selectedUser) => {
        if (user.type === 'SuperAdmin' && user.email !== selectedUser.email) {
            return true
        }
        if (
            user.type === 'Admin' &&
            user.email !== selectedUser.emai &&
            selectedUser.type !== 'SuperAdmin' &&
            selectedUser.type !== 'Admin'
        ) {
            return true
        }
        return false
    }
    const canEditAccess = (selectedUser) => {
        if (user.roles.includes('developer')) return true
        if (selectedUser.type === 'SuperAdmin') return false
        if (selectedUser.type === 'Admin' && user.type === 'Admin') return false
        return true
    }
    useEffect(() => {
        if (focus.email) {
            setUserClone(structuredClone(focus))
            setShowModal(true)
        } else {
            setUserClone({})
            setShowModal(false)
        }
    }, [focus])
    useEffect(() => {
        if (JSON.stringify(focus) !== JSON.stringify(userClone)) setEdited(true)
        else setEdited(false)
    }, [userClone])
    const handleDelete = () => {
        vFetch(`/v1/users/${userClone.email}`, {
            method: 'DELETE',
            cb: (res) => {
                if (res.success) {
                    setNeedsRefresh(true)
                    setFocus({})
                    setCustomRoles([])
                }
            },
        })
    }
    const handleClose = () => {
        setFocus({})
        setCustomRoles([])
    }
    const handleUpdate = () => {
        const changes = {}
        Object.keys(focus).forEach((key) => {
            if (JSON.stringify(focus[key]) !== JSON.stringify(userClone[key])) {
                changes[key] = userClone[key]
            }
        })
        if (changes.roles)
            changes.roles = changes.roles.map((r) => r.toLowerCase()).sort((a, b) => sortByAlphanumeric(a, b))
        if (changes.access) changes.access = changes.access.sort((a, b) => sortByAlphanumeric(a, b))
        delete changes.store_ids
        vFetch(`/v1/users/adminUpdate`, {
            method: 'PUT',
            body: JSON.stringify({
                changes: changes,
                user_id: userClone.id,
                email: userClone.email,
                store_ids: userClone.store_ids,
            }),
            cb: (res) => {
                if (res.success) {
                    setNeedsRefresh(true)
                    setCustomRoles([])
                    setFocus({})
                }
            },
        })
    }
    const handleResetPassword = () => {
        vFetch(`/v1/users/password/${focus.email}/createTempPassword`, { method: 'POST' })
    }

    const inputStyle =
        'bg-lightgrey dark:bg-darkaccent p-[4px] border-[1px] dark:border-blue focus:dark:border-accent outline-0 rounded'
    const focus__inner = 'relative w-[90%] h-[90%] bg-white dark:bg-darkaccent p-[32px] overflow-y-auto pb-[112px]'
    const focus__buttons__button =
        'px-[16px] py-[8px] border-[2px] bg-blue text-white font-bold text-[20px] cursor-pointer duration-300'

    return (
        <div
            className='fixed grid top-0 left-0 z-50 w-full h-full bg-black/50 place-items-center dark:text-offwhite'
            ref={outer}
            onClick={({ target }) => (target === outer.current ? setFocus({}) : '')}
        >
            <div className={`${focus__inner} dark:bg-darkaccent`}>
                <CloseButton onClick={() => setFocus({})} />
                <div className='flex border-b-[2px] border-fire mb-[8px] justify-between pb-[4px]'>
                    <h2 className='text-[24px] font-light uppercase'>{userClone.type}</h2>
                </div>
                <div className='font-bold text-[#4a4a4a] dark:text-grey'>
                    <p className='text-[32px]'>
                        {userClone.first_name} {userClone.last_name}
                    </p>
                    <p className='text-[14px] uppercase mb-[16px] border-b-[2px] border-fire pb-[16px]'>
                        {userClone.email}
                    </p>
                </div>
                <div className='flex gap-[32px]'>
                    <div
                        style={!canEditAccess(focus) ? { pointerEvents: 'none', opacity: 0.5 } : {}}
                        className='flex flex-col gap-[16px] grow p-[16px] max-w-[300px] shadow-[inset_0_0_10px_rgba(0,0,0,0.25)]'
                    >
                        <div>
                            <p className='text-[14px] uppercase font-bold text-[#4a4a4a] dark:text-grey mb-[4px]'>
                                Type
                            </p>
                            <select
                                className={inputStyle}
                                onChange={({ target }) => setUserClone({ ...userClone, type: target.value })}
                                value={userClone.type}
                            >
                                {userTypes.slice(userTypes.indexOf(user.type)).map((o) => (
                                    <option value={o}>{o}</option>
                                ))}
                            </select>
                            {/* {userClone && <ReactSelect name="" options={userTypes.slice(userTypes.indexOf(user.type))} selected={userClone.type} setSelected={(type) => {setUserClone({...userClone, type})}} show={showUserTypes} setShow={setShowUserTypes}/>} */}
                        </div>
                        <div>
                            <p className='text-[14px] uppercase font-bold text-[#4a4a4a] dark:text-grey mb-[4px]'>
                                Shopify Rep Name
                            </p>
                            <input
                                className={inputStyle + ' dark:bg-darkness'}
                                value={userClone.shopify_rep_name}
                                onChange={({ target }) =>
                                    setUserClone({ ...userClone, shopify_rep_name: target.value })
                                }
                            />
                        </div>
                    </div>

                    <div className='grow p-[16px] max-w-[300px] shadow-[inset_0_0_10px_rgba(0,0,0,0.25)]'>
                        <p className='text-[14px] uppercase font-bold text-[#4a4a4a] dark:text-grey mb-[4px]'>Access</p>
                        <div
                            style={!canEditAccess(focus) ? { pointerEvents: 'none', opacity: 0.5 } : {}}
                            className='flex gap-[8px] w-[100%] py-[4px] border-b-[1px] border-[#cdcdcd] dark:border-darkgrey'
                        >
                            <input
                                className=''
                                id='*'
                                type='checkbox'
                                name='*'
                                checked={userClone.access.includes('*')}
                                onChange={(e) =>
                                    setUserClone({
                                        ...userClone,
                                        access: userClone.access.includes('*')
                                            ? userClone.access.filter((a) => a !== '*')
                                            : [...userClone.access, '*'],
                                    })
                                }
                            />
                            <label className='block w-[100%] cursor-pointer' for='*'>
                                All
                            </label>
                        </div>
                        {accesses
                            .filter((r) => r !== '*')
                            .map((route, index) => (
                                <div
                                    style={
                                        !canEditAccess(focus) || userClone.access.includes('*')
                                            ? { pointerEvents: 'none', opacity: 0.5 }
                                            : {}
                                    }
                                    className='flex gap-[8px] w-[100%] py-[4px] border-b-[1px] border-[#cdcdcd] dark:border-darkgrey'
                                >
                                    <input
                                        className=''
                                        id={route}
                                        type='checkbox'
                                        name={route}
                                        checked={userClone.access.some((a) => a == route || a === '*')}
                                        onChange={() =>
                                            setUserClone({
                                                ...userClone,
                                                access: userClone.access.includes(route)
                                                    ? userClone.access.filter((r) => r !== route)
                                                    : [...userClone.access, route],
                                            })
                                        }
                                    />
                                    <label className='block w-[100%] cursor-pointer' for={route}>
                                        {route
                                            .split('-')
                                            .map((val) => `${val.substring(0, 1).toUpperCase()}${val.substring(1)}`)
                                            .join(' ')}
                                    </label>
                                </div>
                            ))}
                    </div>

                    <div className='grow p-[16px] max-w-[300px] shadow-[inset_0_0_10px_rgba(0,0,0,0.25)]'>
                        <p className='text-[14px] uppercase font-bold text-[#4a4a4a] dark:text-grey mb-[4px]'>Roles</p>
                        {roles
                            .filter((role) => (showDeveloperSetRoles ? true : role[0] !== '_'))
                            .map((role, index) => (
                                <div
                                    style={
                                        !canEditAccess(focus) || userClone.access['*']
                                            ? { pointerEvents: 'none', opacity: 0.5 }
                                            : {}
                                    }
                                    key={role + index}
                                    className='flex gap-[8px] w-[100%] py-[4px] border-b-[1px] border-[#cdcdcd] dark:border-darkgrey'
                                >
                                    <input
                                        className=''
                                        id={role + 'role'}
                                        type='checkbox'
                                        name={role + 'role'}
                                        checked={userClone.roles.includes(role)}
                                        onChange={(e) =>
                                            userClone.roles.includes(role)
                                                ? setUserClone({
                                                      ...userClone,
                                                      roles: userClone.roles.filter(
                                                          (clickedRole) => clickedRole !== role
                                                      ),
                                                  })
                                                : setUserClone({ ...userClone, roles: [...userClone.roles, role] })
                                        }
                                    />
                                    <label
                                        className='block w-[calc(100%-32px)] break-words cursor-pointer'
                                        for={role + 'role'}
                                    >
                                        {role
                                            .split('-')
                                            .map((val) => `${val.substring(0, 1).toUpperCase()}${val.substring(1)}`)
                                            .join(' ')}
                                    </label>
                                </div>
                            ))}
                        {customRoles.map((role, index) => (
                            <div
                                style={
                                    !canEditAccess(focus) || userClone.access['*']
                                        ? { pointerEvents: 'none', opacity: 0.5 }
                                        : {}
                                }
                                key={'customRole' + index}
                                className='flex gap-[8px] w-[100%] py-[4px] border-b-[1px] border-[#cdcdcd] dark:border-darkgrey'
                            >
                                <input
                                    className=''
                                    id={role + '-checkbox'}
                                    type='checkbox'
                                    name={role + '-checkbox'}
                                    checked={userClone.roles.includes(role)}
                                    onChange={({ target }) => {
                                        userClone.roles.includes(role)
                                            ? setUserClone({
                                                  ...userClone,
                                                  roles: userClone.roles.filter((clickedRole) => clickedRole !== role),
                                              })
                                            : setUserClone({ ...userClone, roles: [...userClone.roles, role] })
                                        if (!target.checked)
                                            setCustomRoles(customRoles.filter((cR, cI) => cI !== index))
                                    }}
                                />
                                <input
                                    className='block w-[100%] dark:bg-darkness border-[1px] border-blue focus:border-accent outline-none rounded px-[4px]'
                                    id={role}
                                    name={role}
                                    value={role}
                                    // commenting this out, in favor of allowing the custom role to be deleted by unchecking the box, rather than relying on onBlur and onChange to handle that
                                    // onClick={({target}) => setUserClone({...userClone, roles:userClone.roles.includes(role) ? userClone.roles.filter(r => r !== role) : [...userClone.roles, role]})}
                                    onBlur={({ target }) => {
                                        setCustomRoles(customRoles.filter((cR, cI) => cR || (cI !== index && !cR)))
                                    }}
                                    onChange={({ target }) => {
                                        setUserClone({
                                            ...userClone,
                                            roles: userClone.roles
                                                .filter((uR) => uR !== role)
                                                .concat(target.value ? [target.value] : []),
                                        })
                                        setCustomRoles(customRoles.map((cR, cI) => (cI === index ? target.value : cR)))
                                    }}
                                />
                            </div>
                        ))}
                        <div
                            style={
                                !canEditAccess(focus) || userClone.access['*']
                                    ? { pointerEvents: 'none', opacity: 0.5 }
                                    : {}
                            }
                            className='flex gap-[8px] w-[100%] py-[4px] justify-center'
                        >
                            <button
                                className='rounded font-bold uppercase bg-blue dark:bg-accent text-white dark:text-black px-[8px] py-[2px]'
                                onClick={() => {
                                    setCustomRoles([...customRoles, ''])
                                }}
                            >
                                + Custom Role
                            </button>
                        </div>
                    </div>
                    <div>
                        {userClone.store_ids && (
                            <div className='basis-[50%] p-[16px] pr-[8px] shadow-[inset_0_0_10px_rgba(0,0,0,0.25)] max-h-[300px] min-w-[200px]'>
                                <div class='stores'>
                                    <p className='text-[14px] uppercase font-bold text-[#4a4a4a] dark:text-grey mb-[4px]'>
                                        Stores
                                    </p>
                                    {stores.map((store, index) => {
                                        return (
                                            <div
                                                key={store.name + index}
                                                className='flex gap-[8px] w-[100%] py-[4px] border-b-[1px] border-[#cdcdcd] dark:border-darkgrey'
                                            >
                                                <input
                                                    className=''
                                                    id={store.name + 'store'}
                                                    type='checkbox'
                                                    name={store.name + 'store'}
                                                    checked={userClone.store_ids.includes(store.id.toString())}
                                                    onChange={(e) =>
                                                        userClone.store_ids.includes(store.id.toString())
                                                            ? setUserClone({
                                                                  ...userClone,
                                                                  store_ids: userClone.store_ids.filter(
                                                                      (clickedRole) =>
                                                                          clickedRole !== store.id.toString()
                                                                  ),
                                                              })
                                                            : setUserClone({
                                                                  ...userClone,
                                                                  store_ids: [
                                                                      ...userClone.store_ids,
                                                                      store.id.toString(),
                                                                  ],
                                                              })
                                                    }
                                                />
                                                <label
                                                    className='block w-[100%] cursor-pointer capitalize'
                                                    for={store.name + 'store'}
                                                >
                                                    {store.name}
                                                </label>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='mt-[16px] flex'>
                    <button
                        className='px-[8px] py-[2px] font-bold uppercase bg-blue hover:bg-blue/80 dark:bg-accent dark:hover:bg-accent/80 text-white dark:text-black rounded'
                        onClick={handleResetPassword}
                    >
                        Reset Password
                    </button>
                </div>
            </div>
            <div className='fixed flex items-center justify-center gap-[32px] bg-white dark:bg-darkaccent h-[80px] bottom-[5%] left-[5%] w-[90%] p-[16px] shadow-small '>
                <button
                    style={!edited ? { opacity: 0, display: 'none', pointerEvents: 'none' } : {}}
                    className={`${focus__buttons__button} dark:border-blue`}
                    onClick={handleUpdate}
                >
                    SAVE
                </button>
                {canDelete(userClone) && (
                    <button
                        className={`${focus__buttons__button} bg-red hover:bg-transparent hover:text-red dark:border-red`}
                        onClick={() => setShowDelete(true)}
                    >
                        DELETE
                    </button>
                )}
                <button
                    className={`${focus__buttons__button} bg-grey hover:text-grey hover:bg-transparent dark:border-grey`}
                    onClick={handleClose}
                >
                    CLOSE
                </button>
            </div>
            {showDelete && (
                <div className='absolute top-0 left-0 bg-black/50 w-[100%] h-[100%]'>
                    <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] grid bg-white dark:bg-darkaccent p-[16px] rounded gap-[32px]'>
                        <p>
                            Are you sure you want to <strong>DELETE</strong> {focus.email}?
                        </p>
                        <div className='flex justify-around'>
                            <button className={`${focus__buttons__button} dark:border-red`} onClick={handleDelete}>
                                DELETE
                            </button>
                            <button
                                className={`${focus__buttons__button} bg-grey hover:text-grey hover:bg-transparent dark:border-grey`}
                                onClick={() => setShowDelete(false)}
                            >
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
