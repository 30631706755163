import { ChangeEvent, useState } from 'react'
import { FaPen, FaTimes } from 'react-icons/fa'
import { Button } from '../../../custom_components/component_Basics/Button'
import { sendToastWarning } from '../../../helpers'
import { useDeleteOption, useUpdateOption } from '../../api/useQueries'
import Input from '../Input'

export function VariantOption({
    isNew,
    option,
    index,
    productId,
    selectedOptionIndex,
    setSelectedOptionIndex,
    showOptionEdit,
    setShowOptionEdit,
    setShowNewOption,
    setShowNewOptionValue,
    createOption,
}: {
    isNew: boolean
    option: any
    index: number
    productId: string | number
    selectedOptionIndex: any
    setSelectedOptionIndex: any
    showOptionEdit: any
    setShowOptionEdit: any
    setShowNewOption?: any
    setShowNewOptionValue?: any
    createOption: any
}) {
    const [showOptionDelete, setShowOptionDelete] = useState<any>()
    const [showEdit, setShowEdit] = useState<boolean>(isNew ? true : false)
    const [editableFields, setEditableFields] = useState<any>({})

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setEditableFields((previousState: any) => ({
            ...structuredClone(previousState),
            [target.name]: target.type === 'checkbox' ? (target as HTMLInputElement).checked : target.value,
        }))
    }

    const updateOption = useUpdateOption()
    const deleteOption = useDeleteOption()

    const handleDeleteOption = () => {
        deleteOption.mutate(option, {
            onSuccess: () => {
                setShowOptionDelete(false)
                setSelectedOptionIndex(0)
            },
        })
    }

    function handleUpdate() {
        if (isNew) {
            if (!editableFields.label) {
                return sendToastWarning({ message: 'must include option name' })
            }
            if (editableFields.label && !editableFields.label.trim()) {
                return sendToastWarning({ message: 'must include option name' })
            }
            createOption.mutate(
                { option: { product_id: productId, ...editableFields, label: editableFields.label.trim() } },
                {
                    onSuccess: () => {
                        setEditableFields({})
                        setShowEdit(false)
                        setShowNewOption(false)
                        setSelectedOptionIndex(index)
                    },
                }
            )
        }
        if (!isNew) {
            if (!editableFields.label) {
                return sendToastWarning({ message: 'must include option name' })
            }
            if (editableFields.label && !editableFields.label.trim()) {
                return sendToastWarning({ message: 'must include option name' })
            }
            updateOption.mutate(
                {
                    options: [
                        { id: option.id, product_id: productId, ...editableFields, label: editableFields.label.trim() },
                    ],
                    product_id: productId,
                },
                {
                    onSuccess: () => {
                        setShowEdit(false)
                        setEditableFields({})
                    },
                }
            )
        }
    }

    const optionIsPending = createOption.isPending || updateOption.isPending || deleteOption.isPending

    function SaveButtons() {
        return (
            <div className='flex gap-3 items-center '>
                <button
                    onClick={handleUpdate}
                    className={`${
                        optionIsPending ? 'opacity-[0.9] pointer-events-none' : 'pointer-events-auto'
                    } shrink-0 text-[14px] leading-[1] py-1 px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase`}
                >
                    {optionIsPending ? 'SAVING...' : 'SAVE'}
                </button>
                <button
                    onClick={() => {
                        setShowEdit(false)
                        setShowOptionEdit(undefined)
                        setEditableFields({})
                        if (isNew) {
                            setShowNewOption(false)
                        }
                    }}
                    className={`${
                        optionIsPending ? 'opacity-[0.9] pointer-events-none' : 'pointer-events-auto'
                    } self-center shrink-0 text-[14px] leading-[1] py-1 px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkgrey text-black dark:text-offwhite font-bold uppercase`}
                >
                    Cancel
                </button>
            </div>
        )
    }

    return (
        <div className='grid grid-cols-[3fr_2fr] gap-3'>
            {showEdit && (
                <Input
                    id={'label'}
                    autoFocus={true}
                    name={'label'}
                    type='text'
                    placeholder={isNew ? 'New Option' : ''}
                    onChange={handleChange}
                    value={editableFields.label ?? option.label ?? ''}
                    className={` py-[4px] px-[16px] text-[14px] rounded-[4px] font-bold text-center`}
                ></Input>
            )}

            {!showEdit && (
                <Button
                    variant={'outline'}
                    onClick={() => {
                        setShowNewOptionValue(false)
                        return setSelectedOptionIndex(index)
                    }}
                    disabled={showOptionEdit ? true : false}
                    className={`${
                        index === selectedOptionIndex ? 'border-accent2 dark:border-darkaccent2' : ''
                    } py-[4px] px-[16px] rounded-[4px] font-bold`}
                >
                    <div className='flex justify-center w-full'>
                        <p className='mx-auto'>{option.label}</p>
                    </div>
                </Button>
            )}
            {showEdit && <SaveButtons />}
            {showOptionDelete && (
                <div className='flex gap-[8px] self-center items-center'>
                    <button
                        onClick={() => setShowOptionDelete(false)}
                        className={`${
                            deleteOption.isPending ? 'opacity-[0.5] pointer-events-none' : 'pointer-events-auto'
                        } text-[14px] leading-[1] py-[3px] w-fit px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkgrey font-bold uppercase`}
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleDeleteOption}
                        className={`${
                            deleteOption.isPending ? 'opacity-[0.5] pointer-events-none' : 'pointer-events-auto'
                        } shrink-0 text-[14px] leading-[1] py-[3px] w-fit px-[8px] rounded-[4px] bg-red dark:bg-lightred text-white font-bold uppercase`}
                    >
                        Delete
                    </button>
                </div>
            )}
            {!showOptionDelete && !showEdit && (
                <div className='flex gap-1 mr-[2px]'>
                    {!showOptionEdit && !showOptionDelete && (
                        <button onClick={() => setShowEdit(true)} className=''>
                            <FaPen className='text-blue dark:text-offwhite' />
                        </button>
                    )}
                    {!showOptionDelete && !showOptionEdit && (
                        <button onClick={() => setShowOptionDelete(true)}>
                            <FaTimes />
                        </button>
                    )}
                </div>
            )}
        </div>
    )
}
