import { useContext } from 'react'
import { WebSocketContext } from '../../helpers'

export default function UsersOnPathNotifier() {
    const { usersOnPath } = useContext<any>(WebSocketContext)
    return (
        <div className='flex'>
            {usersOnPath.length > 1 &&
                usersOnPath.map((v: any) => (
                    <div className='grid w-[36px] h-[36px] text-[20px] leading-[1px] rounded-full text-blue bg-[rgb(224, 239, 255)] uppercase border-[2px] border-blue place-items-center mr-[-6px]'>
                        {v.substring(0, 2)}
                    </div>
                ))}
        </div>
    )
}
