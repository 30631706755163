import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'

export const changelogs = createQueryKeys('changelogs', {
    list: (filters: any) => ({
        queryKey: [filters],
        queryFn: () => service.getChangelogs(filters),
    }),
    releases: (filters: any) => ({
        queryKey: [filters],
        queryFn: () => service.getReleases(filters),
    }),
    entries: (filters: any) => ({
        queryKey: [filters],
        queryFn: () => service.getEntries(filters),
    }),
})

export default mergeQueryKeys(changelogs)
