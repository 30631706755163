import { CustomerType } from 'customersV2/Customers.types'
import { COUNTRY_MAP } from 'customersV2/constants'
import { useSearchParams } from 'react-router-dom'

const moneyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format

export default function CustomerRow({ customer }: { customer: CustomerType }) {
    const [_searchParams, setSearchParams] = useSearchParams()

    const location = [
        [customer.city, customer.province_code].filter((v) => v).join(' '),
        COUNTRY_MAP[customer.country_code as keyof typeof COUNTRY_MAP],
    ]
        .filter((v) => v)
        .join(', ')
    return (
        <div
            onClick={() =>
                setSearchParams((prev) => {
                    prev.set('customer_id', customer.id.toString())
                    return prev
                })
            }
            className='cursor-pointer hover:bg-lightgrey dark:hover:bg-darkbg2 grid grid-cols-[2fr_1.5fr_3fr_1fr_1fr] border-b border-lightgrey dark:border-darkgrey'
        >
            <div className='p-2'>{customer.calculated_full_name || customer.email || customer.phone}</div>
            <div className='p-2'>{customer.calculated_accepts_email_marketing ? 'Subscribed' : 'Not Subscribed'}</div>
            <div className='p-2'>{location}</div>
            <div className='p-2'>{customer.orders_count || 0}</div>
            <div className='p-2'>{moneyFormatter(customer.total_spent || 0)}</div>
        </div>
    )
}
