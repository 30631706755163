import { useContext, useEffect, useRef } from 'react'
import { cn } from '../../../helpers'
import { ProcurementContext, ProcurementContextType } from '../../helpers'
import { useCompaniesQuery, useShopifyVendorsQuery } from 'procurement/api/useQueries'
import { defaultCompanyFilterParams } from 'procurement/constants'

export default function MissingVendorsModal() {
    const { toggleModal } = useContext<ProcurementContextType>(ProcurementContext)
    const modalRef = useRef(null)

    let params: any = {
        filters: {
            ...defaultCompanyFilterParams,
            lastCursor: undefined,
            assigned_to: [],
            lead_type: [],
            lifecycle_status: [],
            pageParam: 0,
            sortOption: undefined,
            limit: 10000,
        },
    }
    const companiesQuery = useCompaniesQuery({ ...params, search: undefined })
    const { companies, lastCursor } = companiesQuery?.data || {}

    const companyShopifyVendorNames = companies
        ?.map((company: any) => company.shopify_vendor_name)
        ?.filter((company: any) => company)
        ?.map((v: string) => v.toLowerCase())

    const shopifyVendorsQuery = useShopifyVendorsQuery()
    const { vendors: shopifyVendorNames }: { vendors: string[] } = shopifyVendorsQuery?.data || {}

    const missingVendors = shopifyVendorNames?.filter((vendorName: string) => {
        if (companyShopifyVendorNames?.includes(vendorName.toLowerCase())) {
            return
        }
        return vendorName
    })
    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (e.target === modalRef.current) {
                toggleModal()
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    return (
        <div
            ref={modalRef}
            className={cn('fixed z-[50] top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] grid place-items-center')}
        >
            <div
                className={cn(
                    'p-[32px] gap-[32px] bg-white dark:bg-darkaccent rounded-[4px] shadow-small relative overflow-auto max-h-[90%]'
                )}
            >
                <button
                    onClick={() => {
                        toggleModal()
                    }}
                    className='leading-[1] text-[24px] text-red dark:text-lightred absolute font-bold top-0 right-[8px]'
                >
                    &times;
                </button>
                <div className='relative z-[6] flex flex-col gap-[16px] w-1/2 whitespace-nowrap'>
                    <h2 className='text-darkgrey dark:text-fire text-[24px] font-bold uppercase leading-[1] m-0 mb-[16px]'>
                        Missing Vendors: {missingVendors && missingVendors?.length}
                    </h2>
                </div>
                {missingVendors?.length > 0 &&
                    missingVendors?.map((vendorName: string) => {
                        return <p>{vendorName}</p>
                    })}
            </div>
        </div>
    )
}
