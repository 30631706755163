import { Link } from 'react-router-dom'
import { useUpdateProject } from 'tasksV2/api/useQueries'
import ProjectStatus from 'tasksV2/projects/components/ProjectStatus'
import Assignment from 'tasksV2/tasks/components/Assignment'

const dateFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
}).format

export default function RoadmapProject({ project }: { project: any }) {
    const assignemnts = JSON.parse(project.associations || '[]').filter(
        (association: any) => association.resource_name === 'user'
    )
    const updateProjectMutation = useUpdateProject(project.id.toString())

    return (
        <div className='grid grid-cols-subgrid col-span-full gap-2 items-center border-b p-2'>
            <ProjectStatus projectId={project.id} status={project.status} mutation={updateProjectMutation} />
            <Link to={`/tasksv2/projects/${project.id}`}>{project.title}</Link>
            <div className='flex flex-wrap gap-1'>
                {assignemnts.map((assignment: any) => (
                    <Assignment association={assignment} />
                ))}
            </div>
            <p>{dateFormatter(new Date(project.start_date))}</p>
            <p>{dateFormatter(new Date(project.target_date))}</p>
            {/* <div>
                {associations.map((association: any) => (
                    <Association association={association} id={task.id} />
                ))}
            </div> */}
        </div>
    )
}
