import { useEffect, useState } from 'react'
import { vFetch } from '../../helpers'
import { Link } from 'react-router-dom'

export default function ControlTowerScreen() {
    const [leaderServerDetails, setLeaderServerDetails] = useState({
        success: false,
        leaderServers: {},
        fpdashPathMap: {},
    })

    useEffect(() => {
        getLeaderServer()

        const fetchInterval = setInterval(() => {
            getLeaderServer()
        }, 1000)

        return () => clearInterval(fetchInterval)
    }, [])

    function getLeaderServer() {
        vFetch(`/v1/controlTower/leaderServer`, {
            cb: (res: any) => {
                if (!res.success) {
                    return
                }
                setLeaderServerDetails(res)
            },
        })
    }
    return (
        <div className='dark:text-offwhite'>
            <div className='flex gap-[8px] items-center'>
                <p>Leader Server Alive:</p>
                <p>{leaderServerDetails.success ? 'TRUE' : 'FALSE'}</p>
            </div>
            <div className='flex gap-[8px] items-center'>
                <p>Leader Machines:</p>
                <div>
                    {Object.entries(leaderServerDetails.leaderServers).map(([groupName, details]: any) => (
                        <p>
                            {groupName} - Machine ID: {details.leader} (Expires{' '}
                            {new Date(details.expires).toISOString()})
                        </p>
                    ))}
                </div>
            </div>
            <div className='flex gap-[8px] items-center'>
                <p>User Paths:</p>
                <div>
                    {Object.entries(leaderServerDetails.fpdashPathMap)
                        .sort(([pathA], [pathB]) => (pathA < pathB ? -1 : 1))
                        .map(([path, userIds]: any) => (
                            <p>
                                {path} - {userIds.join(', ')}
                            </p>
                        ))}
                </div>
            </div>
            <Link
                className='underline'
                to='https://cloudwatch.amazonaws.com/dashboard.html?dashboard=FPDash&context=eyJSIjoidXMtZWFzdC0xIiwiRCI6ImN3LWRiLTQ4MjEzMTMxNzA2MCIsIlUiOiJ1cy1lYXN0LTFfdXpiM0VWcDJQIiwiQyI6IjZjbmZuN2tzNTU2cmh1aDcxOXQ3ZWJlMGEwIiwiSSI6InVzLWVhc3QtMTpkMWVkZmU1OS0zZjcxLTRmNWYtYThjNi05MjFhMGZiY2NlNTYiLCJPIjoiYXJuOmF3czppYW06OjQ4MjEzMTMxNzA2MDpyb2xlL3NlcnZpY2Utcm9sZS9DV0RCU2hhcmluZy1SZWFkT25seUFjY2Vzcy1TUlZXQTIzTyIsIk0iOiJVc3JQd1NpbmdsZSJ9&start=PT1H&end=null'
                target='_blank'
            >
                View AWS Log Dashboard
            </Link>
        </div>
    )
}
