import { projectStatusDisplayMap } from '../constants'
import { MdOutlineHexagon } from 'react-icons/md'
import ProjectRow from '../components/ProjectRow'

export default function ProjectList({ projects }: { projects: any }) {
    const projectMap: any = {
        backlog: [],
        planned: [],
        paused: [],
        'in progress': [],
        completed: [],
        canceled: [],
    }
    if (projects) {
        for (const project of projects) {
            projectMap[project.status as keyof typeof projectMap].push(project)
        }
    }
    return (
        <div className=''>
            {Object.entries(projectMap).map(([status, projects]: any) => (
                <>
                    {projects.length > 0 && (
                        <div className='mb-4 border border-lightgrey dark:border-darkaccent rounded'>
                            <TaskListHeading status={status} count={projects.length} />
                            {projects.map((project: any, index: number) => (
                                <ProjectRow project={project} last={index === projects.length - 1} />
                            ))}
                        </div>
                    )}
                </>
            ))}
        </div>
    )
}

function TaskListHeading({ status, count }: { status: string; count: number }) {
    const { icon: Icon, className } = projectStatusDisplayMap[status] || { icon: MdOutlineHexagon, class: '' }
    return (
        <div className='text-[14px] font-bold capitalize flex gap-2 items-center bg-lightgrey rounded-t dark:bg-darkaccent dark:text-offwhite py-2 px-4'>
            <Icon className={className} />
            <p>{status}</p>
            <p className='opacity-50'>{count}</p>
        </div>
    )
}
