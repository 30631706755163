import InputToggleSwitch from 'custom_components/InputToggleSwitch'
import { FilterRulesType } from 'followUpEmail/FollowUpEmail.types'
import Input from 'procurement/components/Input'
import Select from 'procurement/components/Select'
import { Dispatch, SetStateAction, useState } from 'react'

const OPERATOR_LABEL_MAP = {
    '=': 'equals',
    '!=': 'not equals',
    '>': 'more than',
    '<': 'less than',
    IN: 'included in (comma separated)',
    'NOT IN': 'not included in (comma separated)',
    LIKE: 'like',
    'NOT LIKE': 'not like',
}

export default function FilterRule({
    label,
    rules,
    setRules,
    type = 'text',
    rule = 'WHERE',
    table,
    column,
    operators = Object.keys(OPERATOR_LABEL_MAP),
}: {
    label: string
    rules: FilterRulesType
    setRules: Dispatch<SetStateAction<FilterRulesType>>
    type?: string
    rule?: 'WHERE' | 'HAVING'
    table: string
    column: string
    operators?: string[]
}) {
    const active = rules[table] && rules[table][column] && !!rules[table][column].type
    const operator = rules[table] ? (rules[table][column] ? rules[table][column].operator : '') : ''
    const value = rules[table] ? (rules[table][column] ? rules[table][column].value : '') : ''

    const toggleActivation = ({ target }: { target: HTMLInputElement }) => {
        if (target.checked) {
            setRules((prev) => {
                const newState = structuredClone(prev)
                if (!newState[table]) {
                    newState[table] = {}
                }
                if (!newState[table][column]) {
                    newState[table][column] = {
                        type: rule,
                    }
                }
                return newState
            })
        } else {
            setRules((prev) => {
                const newState = structuredClone(prev)
                if (newState[table] && newState[table][column]) {
                    delete newState[table][column]
                }
                if (newState[table] && Object.keys(newState[table]).length === 0) {
                    delete newState[table]
                }
                return newState
            })
        }
    }
    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
        setRules((prev) => {
            const newState = structuredClone(prev)
            newState[table][column][target.name as 'operator' | 'value'] = target.value
            return newState
        })
    }
    return (
        <>
            <InputToggleSwitch checked={active} onChange={toggleActivation} />
            <p className={`shrink-0 whitespace-nowrap ${!active ? 'opacity-50' : ''}`}>{label}</p>
            <Select
                className='text-sm'
                outerClassName='w-36'
                id={`${table}-${column}-operator`}
                name='operator'
                onChange={handleChange}
                datasetName='data-type'
                datasetValue={rule}
                value={operator}
                disabled={!active}
            >
                <option value=''></option>
                {operators.map((op) => (
                    <option value={op}>{OPERATOR_LABEL_MAP[op as keyof typeof OPERATOR_LABEL_MAP]}</option>
                ))}
            </Select>
            <Input
                className='text-sm leading-[1.3575] min-h-[0px]'
                id={`${table}-${column}-value`}
                name='value'
                onChange={handleChange}
                datasetName='data-type'
                datasetValue={rule}
                value={value}
                type={type}
                disabled={!active}
            />
        </>
    )
}
