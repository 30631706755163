import { Dispatch, SetStateAction, useState } from 'react'
import { useParentCompanyNotesQuery } from '../../api/useQueries'
import ParentNote from '../listItems/ParentNote'
import { ParentEventType } from '../modals/ParentCompanyModal'
import FilterToolBar from '../FilterToolBar'

export default function ParentNoteList({
    parentCompany,
    companyNameMap,
    setShowQuickModal,
}: {
    parentCompany: any
    companyNameMap: any
    setShowQuickModal: Dispatch<SetStateAction<string | undefined>>
}) {
    const [search, setSearch] = useState('')

    const companyEventsQuery = useParentCompanyNotesQuery({ parentCompany, companyNameMap })
    const { events }: { events: ParentEventType[] } = companyEventsQuery?.data || {}

    const filteredNotes = events?.filter((event: EventType) => event.type === 'note')

    const searchedNotes = filteredNotes?.filter((note) => {
        return note.message.toLowerCase().includes(search.toLowerCase())
    })

    return (
        <div className='p-[16px] pl-0 overflow-auto'>
            <div className='mb-[8px] w-full flex gap-[16px]'>
                <FilterToolBar
                    search={search}
                    setSearch={setSearch}
                    filterBarObject={undefined}
                    className={'border border-blue/25 dark:border-0 dark:bg-darkbg2 rounded-[4px]'}
                />
                <button
                    onClick={() => setShowQuickModal('note')}
                    className='shrink-0 text-[14px] leading-[1] py-[2px] px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase'
                >
                    + New Note
                </button>
            </div>
            <div className='flex flex-col gap-[8px]'>
                {searchedNotes?.map((note) => <ParentNote key={note.id} note={note} />)}
            </div>
        </div>
    )
}

export type EventType = {
    id?: string | number
    type: string
    message: string
    value: string
    previous_value?: string
    raw_data?: any
    company_id?: string | number
    user_id?: string | number
    store_id?: string | number
    created_at: string
    updated_at: string
}
