import { SkeletonBasic } from '../../../custom_components/component_Basics/SkeletonBasic'

export default function TaskListItemSkeleton() {
    return (
        <div
            className={` relative w-full p-2 pl-2 rounded-[4px] border border-blue/25 dark:border-darkgrey bg-bg1 dark:bg-darkness shadow-md`}
        >
            <div className='top-[50px] grid grid-rows-[30px_50px]  grid-cols-[36px_12px_2fr_12px_1fr_1fr_1fr_1fr] col-span-full  dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                <div className='flex items-center justify-center gap-[8px] font-bold text-[14px] row-start-1 row-end-3'>
                    <SkeletonBasic className={` cursor-pointer rounded-full p-[4px] border-2  w-[24px] h-[24px]`} />
                </div>
                <div></div>
                <SkeletonBasic className='font-bold items-start justify-start m-2 text-accent2 dark:text-darkaccent2 p-2'></SkeletonBasic>
                <div></div>
                <p className='font-bold text-[14px]'></p>
                <div></div>
                <SkeletonBasic className='col-span-2 m-2'></SkeletonBasic>

                <div className='flex gap-[8px] w-full max-w-[100%] col-start-3 col-end-10'>
                    <SkeletonBasic className='w-full  border-lightgrey dark:border-darkgrey bg-lightgrey max-h-[50px] h-auto dark:bg-darkaccent dark:text-white focus:outline-none rounded-[4px] py-[2px] px-[4px]' />
                </div>
            </div>
        </div>
    )
}
