import { CustomerInit } from '../customers/customers.types'

export type S3File = {
    name: string
    type: string
    data: string | Uint8Array | File
    location?: string
    base64?: string
}

export type ReturnFee = {
    amount: number
    name: string
    type: string
    name_alt?: string
}

type returnAndDamageSharedProps = {
    customer: number | null
    destination: string | null
    fees: ReturnFee[]
    files: S3File[]
    manufacturer: string
    model: any | null
    notes: string
    origin: string
    original_tracking_number: string
    original_shipping_paid_by: string
    original_shipping_type: string
    po_number: number | null
    quantity: number
    reason: string
    refund_to_customer?: number
    refund_from_manufacturer_actual?: number
    refund_from_manufacturer_expected?: number
    refund_type: string
    rep: string
    resolution: string
    rma_number: string | null
    shipping_type: string
    status: string
    task_id: number | null
    tracking_number: string | null
    type: 'return' | 'damage' | 'cancellation'
    submitted?: 1 | 0
}

export interface DamageClaimCreateInit extends returnAndDamageSharedProps {
    damage_checklist: {
        customer_inspected: boolean
        refused_shipment: boolean
        notated_damage: boolean
    }
}
export interface DamageClaimEditInit extends DamageClaimCreateInit {
    id: number
    created_at?: Date
    updated_at?: Date
    old_status?: string
}

export interface ReturnCreateInit extends returnAndDamageSharedProps {
    customerParsed?: CustomerInit
    item_checklist: {
        recently_delivered: boolean
        new_condition: boolean
        original_packaging: boolean
        returnable: boolean
    }
}
export interface ReturnEditInit extends ReturnCreateInit {
    id: number
    created_at?: Date
    updated_at?: Date
    old_status?: string
}

export const defaultDamageChecklist = {
    customer_inspected: false,
    refused_shipment: false,
    notated_damage: false,
}
export const defaultItemChecklist = {
    recently_delivered: false,
    new_condition: false,
    original_packaging: false,
    returnable: false,
}
export const defaultReturn: ReturnCreateInit = {
    customer: null,
    destination: null,
    fees: [],
    files: [],
    item_checklist: defaultItemChecklist,
    manufacturer: '',
    model: { unparsedModel: '' },
    notes: '',
    origin: '',
    original_tracking_number: '',
    original_shipping_paid_by: '',
    original_shipping_type: '',
    po_number: null,
    quantity: 0,
    reason: '',
    refund_to_customer: 0,
    refund_from_manufacturer_actual: 0,
    refund_from_manufacturer_expected: 0,
    refund_type: 'partial',
    rep: '',
    resolution: '',
    rma_number: null,
    shipping_type: '',
    status: 'initialized',
    task_id: null,
    tracking_number: null,
    type: 'return',
}
export const defaultDamageClaim: DamageClaimCreateInit = {
    customer: null,
    destination: null,
    damage_checklist: defaultDamageChecklist,
    fees: [],
    files: [],
    manufacturer: '',
    model: { unparsedModel: '' },
    notes: '',
    origin: '',
    original_tracking_number: '',
    original_shipping_paid_by: '',
    original_shipping_type: '',
    po_number: null,
    quantity: 0,
    reason: 'damaged in transit',
    refund_to_customer: 0,
    refund_from_manufacturer_actual: 0,
    refund_from_manufacturer_expected: 0,
    refund_type: 'partial',
    rep: '',
    resolution: '',
    rma_number: null,
    shipping_type: '',
    status: 'initialized',
    task_id: null,
    tracking_number: null,
    type: 'damage',
}
