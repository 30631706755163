import { Button } from 'custom_components/component_Basics/Button'
import { useInfoBlocksQuery } from 'procurement/api/useQueries'
import { ProcurementContext, ProcurementContextType } from 'procurement/helpers'
import { useContext, useState } from 'react'
import { BLOCK_INFO_OPTIONS } from 'vendors/constants'
import CreateInfoBlock from './CreateInfoBlock'
import VendorInfoBlock from './VendorInfoBlock'

export default function VendorInfoV2() {
    const { selectedCompany } = useContext<ProcurementContextType>(ProcurementContext)
    const [showCreate, setShowCreate] = useState(false)

    const infoBlocksQuery = useInfoBlocksQuery({ companyId: selectedCompany.id })
    const { infoBlocks } = infoBlocksQuery?.data || {}

    // always sort presets before custom header blocks
    infoBlocks?.forEach((block: any) => {
        if (BLOCK_INFO_OPTIONS.includes(block.header)) {
            block.position = BLOCK_INFO_OPTIONS.indexOf(block.header)
            return
        }
        block.position = 99999
    })
    const sortByPosition = (a: any, b: any) => {
        return a.position - b.position
    }
    infoBlocks?.sort(sortByPosition)

    return (
        <div className='flex flex-col gap-1 min-h-[300px]'>
            <Button variant={'outline'} className='justify-self-end place-self-end' onClick={() => setShowCreate(true)}>
                + Add Info
            </Button>
            {infoBlocks?.map((infoBlock: any, index: number) => {
                return <VendorInfoBlock key={infoBlock.id} infoBlock={infoBlock} index={index} />
            })}
            {showCreate && <CreateInfoBlock setShowCreate={setShowCreate} />}
        </div>
    )
}
