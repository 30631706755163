import { useTrackEvent } from '../../analytics/analytics'
import { procurementDepartment } from '../constants'

export function useCompanyEvent(department: string, type: string) {
    const event = useTrackEvent()

    return {
        completion: (variables: any, eventDetails: any) =>
            event.track({
                department,
                type,
                subtype: 'completion',
                event_details: {
                    ...eventDetails,
                },
                options: 'options',
            }),
        edit: (variables: any, eventDetails: any) =>
            event.track({
                department,
                type,
                subtype: 'edit',
                event_details: {
                    ...eventDetails,
                },
                options: 'options',
            }),
        create: (data: any, eventDetails: any) => {
            return event.track({
                department,
                type,
                subtype: 'create',
                event_details: {
                    ...data?.id,
                    ...eventDetails,
                },
                options: 'options',
            })
        },
        interaction: (data: any, variables: any, subtype: 'note' | 'email') =>
            event.track({
                department,
                type,
                subtype,
                event_details: {
                    companyId: variables?.note?.company_id,
                },
                options: 'options',
            }),
        view: (variables: any) => {
            return event.track({
                department,
                type,
                subtype: 'view',
                event_details: {
                    company_id: variables?.company?.id,
                },
                options: 'options',
            })
        },
        delete: (variables: any, eventDetails: any) => {
            return event.track({
                department,
                type,
                subtype: 'delete',
                event_details: {
                    ...eventDetails,
                },
                options: 'options',
            })
        },
    }
}
