export type StoreType = {
    id: number
    name: string
    shopify_name: string | null
    logo: string | null
    color_set: {}
    is_shopify_plus: boolean
    access_token: string | null
    ga4_property_id: string | null
    ga4_service_account_credentials: string | null
    slack_api_key: string | null
    slack_incoming_webhook_url: string | null
    google_shopping_name: string | null
}

export const defaultStore: StoreType = {
    id: 0,
    name: '',
    shopify_name: '',
    logo: '',
    color_set: {},
    is_shopify_plus: false,
    access_token: '',
    ga4_property_id: '',
    ga4_service_account_credentials: '',
    slack_api_key: '',
    slack_incoming_webhook_url: '',
    google_shopping_name: '',
}
