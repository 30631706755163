export type EmailTemplateType = {
    id?: number | string
    name: string
    reply_to: string
    from_name: string
    from_email: string
    bcc?: string
    subject: string
    body: string
    event_type: string
    delay: number
    starts_on: string
    filter_rules: FilterRulesType
    is_unique: 1 | 0
    is_marketing: 1 | 0
    status: 'active' | 'paused'
    store_id?: number | string
    created_at?: string
    updated_at?: string
}
export const defaultEmailTemplate: EmailTemplateType = {
    name: '',
    reply_to: '',
    from_name: '',
    from_email: '',
    subject: '',
    body: '',
    event_type: '',
    delay: 0,
    starts_on: `${new Date().getFullYear()}-${`0${new Date().getMonth() + 1}`.slice(-2)}-${`0${new Date().getDate()}`.slice(-2)}`,
    filter_rules: {},
    is_unique: 0,
    is_marketing: 0,
    status: 'active',
}

export type EmailTemplateEditType = {
    id: string
    name?: string
    reply_to?: string
    from_name?: string
    from_email?: string
    bcc?: string
    subject?: string
    body?: string
    event_type?: string
    delay?: number
    starts_on?: string
    filter_rules?: FilterRulesType
    is_unique?: 1 | 0
    is_marketing?: 1 | 0
    status?: 'active' | 'paused'
    store_id?: number | string
    created_at?: string
    updated_at?: string
}

export type FilterRulesType = {
    [key: string]: {
        [key: string]: FilterKeyType
    }
}

export type FilterRulesTableType = 'orders' | 'order_tags' | 'customers' | 'customer_tags' | 'products' | 'product_tags' | 'variants' | 'collections'

export type FilterRulesOperatorType = '=' | '!=' | 'IN' | 'NOT IN' | '>' | '<'

export type FilterKeyType = {
    type: 'WHERE' | 'HAVING'
    operator?: FilterRulesOperatorType
    value?: any
}

export type FilterRulesTableColumnType = {
    [key: string]: FilterKeyType
}

export type FollowUpEmailType = {
    id: string | number
    send_at: string
    sent: 1 | 0
    opened: 1 | 0
    clicked: 1 | 0
    order_id: string | number
    template_id: string | number
    customer_id: string | number
    store_id: string | number
    created_at: string
    updated_at: string
}