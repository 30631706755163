import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'

export const migrations = createQueryKeys('migrations', {
    processList: () => ({
        queryKey: ['processList'],
        queryFn: () => service.getProcessList(),
    }),
})

export const migrationQueries = mergeQueryKeys(migrations)
