import CloseButton from '../custom_components/CloseButton'
import { useState } from 'react'
import { sendToast, vFetch } from '../helpers'

type ForgotPasswordModalProps = {
    setShowForgotPasswordModal: Function
}
export default function ForgotPasswordModal({ setShowForgotPasswordModal }: ForgotPasswordModalProps) {
    const [email, setEmail] = useState('')

    function handleSubmit() {
        if (email.includes('@')) {
            vFetch(`/v1/users/password/${email}/createTempPassword`, { method: 'POST' })
            sendToast({ message: "You're lucky I like you. Check your email for a password reset link." })
            setShowForgotPasswordModal(false)
        } else sendToast({ message: 'Please enter an email address before clicking Reset Password.' })
    }

    return (
        <div className='fixed top-0 left-0 flex justify-center items-center w-[100%] h-[100%] bg-black/40'>
            <div className='relative p-[32px] bg-white dark:bg-darkaccent rounded'>
                <CloseButton onClick={() => setShowForgotPasswordModal(false)} />
                <h1 className='login__heading dark:text-offwhite'>Hmm... shame on you.</h1>
                <div className='grid justify-center items-center gap-[16px]'>
                    <input
                        className='min-w-[350px] dark:bg-darkness dark:text-offwhite outline-0 border-[1px] border-darkgrey focus:border-black dark:border-blue dark:focus:border-accent rounded-[8px] p-[16px] text-center'
                        type='email'
                        name='email'
                        id='email'
                        placeholder='Email Address'
                        value={email}
                        onChange={({ target }) => setEmail(target.value)}
                    />
                    <div className='flex justify-center items-center'>
                        <button
                            type='submit'
                            className='login__submit !w-auto !text-[16px] dark:text-black'
                            onClick={handleSubmit}
                        >
                            Reset Password
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
