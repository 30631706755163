import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Cross2Icon } from '@radix-ui/react-icons'
import { Button } from 'custom_components/component_Basics/Button'
import { cn } from 'helpers'
import Input from 'procurement/components/Input'
import { MdOutlineDragIndicator } from 'react-icons/md'
import { Option } from './ProductGroupDetail'

export default function SortableOptionName({
    allOptions,
    id,
    handleChange,
    editableOptionNameFields,
    handleRemoveName,
    handleRemoveTempName,
}: {
    allOptions: Option[]
    id: number | string
    handleChange: any
    editableOptionNameFields: any
    handleRemoveName: any
    handleRemoveTempName: any
}) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    }

    const option = allOptions.find((option: any) => option.id === id)

    return (
        <div ref={setNodeRef} style={style} {...attributes} key={id} className='flex gap-2 items-center'>
            <div className='flex gap-1 items-center w-full'>
                <div className='h-full cursor-grab'>
                    <MdOutlineDragIndicator {...listeners} className='h-full w-[20px]' />
                </div>
                {option && (
                    <Input
                        type='text'
                        id={option?.option_id.toString() || ''}
                        name={option?.option_id.toString() || ''}
                        onChange={handleChange}
                        value={editableOptionNameFields[option.option_id] ?? option.option_name}
                        className={cn('dark:bg-darkbg1')}
                    />
                )}
            </div>
            {option && (
                <Button
                    className='p-1 h-fit w-fit'
                    variant={'ghost'}
                    size={'icon'}
                    onClick={(e) => {
                        if (typeof option.option_id == 'number') {
                            handleRemoveName(e, option.option_id.toString())
                            return
                        }
                        handleRemoveTempName(e, option.option_id)
                    }}
                >
                    <Cross2Icon />
                </Button>
            )}
        </div>
    )
}
