import { useQuery } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { cn, sendToastWarning, useDebounce, vFetch } from 'helpers'
import { useEffect, useRef, useState } from 'react'
import { FaCaretLeft, FaCaretRight, FaTimes } from 'react-icons/fa'
import { FaMagnifyingGlass } from 'react-icons/fa6'
import ProductRow from '../components/ProductRow'

export default function ProductList() {
    const inputeRef = useRef<HTMLInputElement>(null)
    const [search, setSearch] = useState('')
    const [margin11, setMargin11] = useState(false)
    const [editingProduct, setEditingProduct] = useState<undefined | number>()
    const [page, setPage] = useState(1)

    const debouncedSearch = useDebounce(search, 300)
    const params = [
        debouncedSearch ? `search=${encodeURIComponent(debouncedSearch.trim())}` : null,
        margin11 ? 'margin=11' : null,
        `page=${page}`,
    ]
        .filter((v) => v)
        .join('&')
    const variantsQuery = useQuery({
        queryKey: ['costAndPrice', params],
        queryFn: () => vFetch(`/v2/costAndPrice/variants${params ? `?${params}` : ''}`),
    })
    const variants = variantsQuery.data?.variants || []
    const productMap: any = {}
    for (const variant of variants) {
        if (!productMap[variant.product_id]) {
            productMap[variant.product_id] = {
                title: variant.product_title,
                id: variant.product_id,
                variants: [],
            }
        }
        productMap[variant.product_id].variants.push({
            id: variant.variant_id,
            sku: variant.sku,
            title: variant.variant_title,
            price: variant.price,
            compare_at_price: variant.compare_at_price,
            cost: variant.unit_cost,
        })
    }
    const products = Object.values(productMap)
    const handleDecrementPage = () => {
        if (editingProduct) {
            return sendToastWarning({ message: 'Please save pending changes' })
        }
        setPage((prev) => (prev > 1 ? prev - 1 : prev))
    }
    const handleIncrementPage = () => {
        if (editingProduct) {
            return sendToastWarning({ message: 'Please save pending changes' })
        }
        setPage((prev) => (products.length > 49 ? prev + 1 : prev))
    }
    useEffect(() => {
        setEditingProduct(undefined)
    }, [debouncedSearch])
    return (
        <div className='border border-lightgrey dark:border-darkgrey rounded bg-bg1 dark:bg-darkbg1'>
            <div className='flex items-center pl-2'>
                {search ? (
                    <button
                        className='text-red dark:text-lightred'
                        onClick={() => {
                            setSearch('')
                            setPage(1)
                        }}
                    >
                        <FaTimes />
                    </button>
                ) : (
                    <button onClick={() => inputeRef.current?.focus()}>
                        <FaMagnifyingGlass />
                    </button>
                )}
                <input
                    ref={inputeRef}
                    type='text'
                    className='p-2 focus:outline-none w-full bg-transparent'
                    placeholder='Search for a product...'
                    value={search}
                    onChange={({ target }) => {
                        setSearch(target.value)
                        setPage(1)
                    }}
                />
                <Button
                    variant={'outline'}
                    className={cn('shrink-0 m-1 py-1 px-2 h-fit', margin11 && 'border-fire')}
                    onClick={() => {
                        if (editingProduct) {
                            return sendToastWarning({ message: 'Please save pending changes' })
                        }
                        setMargin11((prev) => !prev)
                        setPage(1)
                    }}
                >
                    Margin Less Than 11%
                </Button>
            </div>

            <div className='z-10 grid grid-cols-6 py-2 px-4 gap-2 mb-2 bg-accent1 dark:bg-darkbg2 dark:text-offwhite text-white font-bold text-xs sticky top-[50px]'>
                <p className=''>SKU</p>
                <p className=''>Variant Title</p>
                <p className=''>Price</p>
                <p className=''>Compare At Price</p>
                <p className=''>Cost</p>
                <p className=''>Margin</p>
            </div>
            {variantsQuery.isLoading ? (
                <p className='w-full p-2 text-center h-[90vh]'>Loading...</p>
            ) : (
                <>
                    {products.map((product: any) => (
                        <ProductRow
                            product={product}
                            editingProduct={editingProduct}
                            setEditingProduct={setEditingProduct}
                        />
                    ))}
                </>
            )}
            <div className='fixed bottom-8 left-1/2 translate-x-[-50%] flex gap-2 items-center bg-white border rounded shadow-md border-lightgrey dark:bg-darkbg1 dark:border-darkgrey p-2'>
                <button
                    disabled={page === 1}
                    className='disabled:opacity-50 rounded border border-lightgrey dark:border-darkgrey text-xl p-2'
                    onClick={handleDecrementPage}
                >
                    <FaCaretLeft />
                </button>
                <div className='font-bold text-lg'>Page {page}</div>
                <button
                    disabled={products.length < 50}
                    className='disabled:opacity-50 rounded border border-lightgrey dark:border-darkgrey text-xl p-2'
                    onClick={handleIncrementPage}
                >
                    <FaCaretRight />
                </button>
            </div>
        </div>
    )
}
