import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useAnalytics } from 'use-analytics'
import './App.css'
import { colorSettingsDark, colorSettingsLight, handleLocalStorage, useLocalStorageKeyWatcher, vFetch } from './helpers'
import Layout from './layout/Layout'
import LayoutLogin from './layout/LayoutLogin'
import { setSettings } from './redux/settings'
import { setUser } from './redux/user'
import Router from './routes/Router'
import { SUBMENUS_V2 } from './routes/submenus'

window.IMAGE_MAP = {}

function App() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [currentStoreId, setCurrentStoreId] = useState(localStorage.getItem('store_id'))
    const sessionToken = useLocalStorageKeyWatcher('session_token')
    const user = useSelector((state) => state.user)
    const app = useSelector((state) => state.app)
    const location = useLocation()
    const currentLocation = location.pathname.split('/')[1].replaceAll('-', ' ')
    const [showDevtools, setShowDevtools] = React.useState(false)

    const { track, page, identify } = useAnalytics()

    const ReactQueryDevtoolsProduction = React.lazy(() =>
        import('@tanstack/react-query-devtools/build/modern/production.js').then((d) => ({
            default: d.ReactQueryDevtools,
        }))
    )

    React.useEffect(() => {
        // @ts-ignore
        window.toggleDevtools = () => setShowDevtools((old) => !old)
    }, [])

    window.addEventListener('resize', () => {
        const textAreas = document.getElementsByTagName('textarea')

        for (let element of textAreas) {
            const minHeight = parseInt(
                window.getComputedStyle(element, null).getPropertyValue('min-height').replace('px', '')
            )

            if (element.scrollHeight > minHeight && element.scrollHeight < window.innerHeight * 0.8) {
                element.style.height = '0'
                element.style.height = `${element.scrollHeight}px`
            }
        }
    })

    useEffect(() => {
        if (
            !sessionToken &&
            location.pathname !== '/create' &&
            location.pathname !== '/login' &&
            !location.pathname.includes('/resetPassword')
        ) {
            sessionStorage.setItem(
                'stored_path',
                location.search ? location.pathname + location.search : location.pathname
            )
            dispatch(setUser(null))
            navigate('/login')
        }
    }, [sessionToken])

    useEffect(() => {
        if (sessionToken) {
            vFetch(`/v1/users/verify?version=${app.version}`, {
                cb: (res) => {
                    if (res.success) {
                        handleLocalStorage.setItem('session_token', res.token)
                        dispatch(
                            setUser({
                                firstName: res.first_name,
                                lastName: res.last_name,
                                email: res.email,
                                user_id: res.user_id,
                                id: res.id,
                                type: res.type,
                                roles: JSON.parse(res.roles),
                                store_ids: res.store_ids,
                                store_names: res.store_names,
                                access: JSON.parse(res.access),
                            })
                        )
                        identify(`${JSON.parse(res.id)}`)

                        if (!res.store_ids.includes(localStorage.getItem('store_id'))) {
                            localStorage.setItem('store_id', res.store_ids.split(',')[0])
                        }

                        const theme = localStorage.getItem('theme')
                        if (theme && theme === 'dark') {
                            document.querySelector('html').classList.add('dark')
                        }
                        if (res.refresh && location.pathname.split('/')[1] !== 'admin') return window.location.reload()
                    } else {
                        handleLocalStorage.removeItem('session_token')
                        dispatch(setUser(null))
                        navigate('/login')
                    }
                },
                catchCb: (err) => {
                    localStorage.removeItem('session_token')
                    navigate('/login')
                },
            })
        }
        vFetch(`/v2/products/productMap`, {
            cb: (res) => {
                if (res.success) res.products.forEach((p) => (window.IMAGE_MAP[p.gid] = p.img_src))
            },
        })
        vFetch(`/v1/stores/${localStorage.getItem('store_id')}`, {
            cb: (res) => {
                if (res.success) {
                    dispatch(
                        setSettings({
                            logo: res.storeInfo.logo,
                            color_set: res.storeInfo.color_set,
                        })
                    )
                    localStorage.setItem('color_set', res.storeInfo.color_set)
                    colorSettingsLight.forEach((setting) => {
                        const colors = JSON.parse(localStorage.getItem('color_set'))
                        document.documentElement.style.setProperty(`--${setting}`, `${colors[setting]}`)
                    })
                    colorSettingsDark.forEach((setting) => {
                        const colors = JSON.parse(localStorage.getItem('color_set'))
                        if (setting === 'darkBackgroundPrimary') {
                        }
                        document.documentElement.style.setProperty(`--${setting}`, `${colors[setting]}`)
                    })
                }
            },
        })
    }, [currentStoreId])

    return (
        <>
            {location.pathname === '/create' ||
            location.pathname === '/login' ||
            location.pathname.includes('resetPassword') ? (
                <LayoutLogin>
                    <Router />
                </LayoutLogin>
            ) : (
                <>
                    {user && user.store_ids.includes(localStorage.getItem('store_id')) && (
                        <Layout
                            title={currentLocation}
                            access={user.access}
                            submenu={SUBMENUS_V2[currentLocation]}
                            setCurrentStoreId={setCurrentStoreId}
                            currentStoreId={currentStoreId}
                        >
                            <Router />
                            <div id='seth-container' className='hidden-seth'>
                                <img src='/seth.png' id='seth' />
                                <div id='seth-bubble'>Quite honestly, these generators sell themselves.</div>
                            </div>
                            <style>{`
                  #seth {
                      height: 128px;
                      width: 128px;
                      object-fit: cover;
                      object-position: left;
                  }
                  #seth-container {
                      position: fixed;
                      bottom: 0;
                      left: 50px;
                  }
                  .hidden-seth {
                    display: none;
                  }
                  #seth-bubble {
                      position: absolute;
                      bottom: 0%;
                      left: 90%;
                      width: 170px !important;
                      padding: 16px;
                      border: 3px solid black;
                      border-radius: 10px;
                      font-family: monospace;
                      font-weight: 700;
                      opacity: 0;
                      transition: all 0.5s;
                      background-color: white;
                      color: black;
                  }
                  #seth-bubble::after {
                      content: '';
                      position: absolute;
                      bottom: -3px;
                      left: -3px;
                      width: 10px;
                      height: 10px;
                      background-color: white;
                      border-left: 3px solid black;
                      border-bottom: 3px solid black;
                  }
                  .seth-bubble--active {
                      bottom: 100% !important;
                      opacity: 1 !important;
                  }
              `}</style>
                        </Layout>
                    )}
                    {showDevtools && (
                        <React.Suspense fallback={null}>
                            <ReactQueryDevtoolsProduction />
                        </React.Suspense>
                    )}
                </>
            )}
            <ToastContainer stacked={true} position='bottom-right' draggable={false} />
        </>
    )
}

export default App
