export default function PDPDoc() {
    return (
        <>
            <h2>Product Page</h2>
            <ul>
                <li>
                    <p>Global Variables</p>
                    <ul>
                        <li>
                            <strong>PRODUCT_LOGO_URL</strong> - The vendor logo for each product. Used in pdp.js for
                            non-metafield style description formatting. Declared in theme.liquid.
                        </li>
                        <li>
                            <strong>initPlyr</strong> - Function to turn videos into plyrs after slide interaction.
                            Videos are lazy loaded so the must be called after an interaction.
                        </li>
                        <li>
                            <strong>pdpSplide &amp; thumbsSplide</strong> - Splide sliders, referenced in pdp.js but
                            needs to be initialized synchronously
                        </li>
                        <li>
                            <strong>globalProduct</strong> - Product details rendered serverside and made available for
                            client-side js actions
                        </li>
                        <li>
                            <strong>judgemeString</strong> - Reviews metafield, needed for non-metafield style
                            description parsing
                        </li>
                        <li>
                            <strong>config</strong> - Mutation observer config, used for sharethis button modification
                        </li>
                    </ul>
                </li>
                <li>
                    <p>Templates</p>
                    <ul>
                        <li>product.json</li>
                    </ul>
                </li>
                <li>
                    <p>Sections</p>
                    <ul>
                        <li>pdp-main.liquid</li>
                        <li>pdp-description.liquid</li>
                        <li>recently-viewed.liquid</li>
                    </ul>
                </li>
                <li>
                    <p>Snippets</p>
                    <ul>
                        <li>breadcrumb.liquid</li>
                        <li>image-magnifier.liquid</li>
                        <li>judgeme_widgets.liquid</li>
                        <li>da-restock</li>
                        <li>Various icons</li>
                    </ul>
                </li>
                <li>
                    <p>Assets</p>
                    <ul>
                        <li>pdp.js</li>
                        <li>recent.js</li>
                    </ul>
                </li>
            </ul>
        </>
    )
}
