import { CategoryScale } from 'chart.js'
import Chart from 'chart.js/auto'
import { useState } from 'react'
import { DateRange } from 'react-day-picker'
import { useSearchParams } from 'react-router-dom'
import { BarChart } from '../components/BarChart'
import { GraphContainer } from '../components/GraphContainer'
import LineChart from '../components/LineChart'
import { DATE_PICKER_OPTIONS, PLACEHOLDER_DATA_INSERT, TIME_PERIOD_OPTIONS, formatChartDataByUser } from '../helpers'

Chart.register(CategoryScale)

export default function GenericAnalyticsInterval({
    analyticsObjects,
    user_ids,
    dateRange,
    dateRangeCompare,
    size,
    chartTitle,
    reportName,
}: {
    analyticsObjects: {
        analyticsGetFunction: Function
        label?: string
        queryOptions: {
            tag: string
            value: string[]
            title: string
        }[]

        fieldOptions?: {
            tag: string
            value: string[]
            title: string
        }[][]
    }[]
    user_ids: any
    dateRange: DateRange | undefined
    dateRangeCompare: DateRange | undefined
    size: 'small' | 'medium' | 'large'
    chartTitle: string
    reportName: string
}) {
    let [searchParams, setSearchParams] = useSearchParams()
    const interval = searchParams.get('interval') || '0'
    const [timePeriodIndex, setTimePeriodIndex] = useState(parseInt(interval))
    const optionIndexParam: string = searchParams.get('option') || '2'
    const [queryOptionsIndex, setQueryOptionsIndex] = useState(0)
    const [chartType, setChartType] = useState<'bar' | 'line'>('bar')

    const { analyticsGetFunction, queryOptions } = analyticsObjects[0]

    chartTitle = chartTitle + `- ${DATE_PICKER_OPTIONS[parseInt(optionIndexParam)].label}`
    const analyticsQuery = analyticsGetFunction({
        user_ids,
        dateRange,
        dateRangeCompare,
        timePeriod: TIME_PERIOD_OPTIONS[timePeriodIndex],
        fields: queryOptions[queryOptionsIndex] || queryOptions[0],
    })
    const { analytics: analyticsData }: { analytics: any } = analyticsQuery?.data || {}

    const chartData =
        formatChartDataByUser(analyticsData, dateRangeCompare, TIME_PERIOD_OPTIONS[timePeriodIndex], user_ids) ??
        PLACEHOLDER_DATA_INSERT

    return (
        <GraphContainer
            timePeriodIndex={timePeriodIndex}
            setTimePeriodIndex={setTimePeriodIndex}
            options={queryOptions}
            setOptionsIndex={setQueryOptionsIndex}
            setChartType={setChartType}
            chartType={chartType}
            reportName={reportName}
        >
            {chartType === 'bar' && <BarChart chartTitle={chartTitle} chartData={chartData} size={size} />}
            {chartType === 'line' && <LineChart chartTitle={chartTitle} chartData={chartData} size={size} />}
        </GraphContainer>
    )
}
