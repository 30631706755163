import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { SupplierType } from '../PurchaseOrder.types'

export default function SupplierSelect({
    suppliers,
    selectedSupplier,
    setSelectedSupplier,
}: {
    suppliers: SupplierType[]
    selectedSupplier: SupplierType
    setSelectedSupplier: Dispatch<SetStateAction<SupplierType>>
}) {
    const dropdown = useRef<HTMLDivElement>(null)
    const [search, setSearch] = useState('')
    const [filteredSuppliers, setFilteredSuppliers] = useState(structuredClone(suppliers))
    const [showDropdown, setShowDropdown] = useState(false)

    const handleChangeSupplier = (newSupplier: SupplierType) => {
        setSelectedSupplier(newSupplier)
        setShowDropdown(false)
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!dropdown?.current?.contains(e.target as HTMLElement)) {
                setShowDropdown(false)
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    useEffect(() => {
        setSearch(selectedSupplier.name || '')
    }, [selectedSupplier])

    useEffect(() => {
        const filterSuppliers = (supplier: SupplierType) => {
            if (supplier.name.toLowerCase().startsWith(search.toLowerCase())) {
                return true
            }
            if (supplier.name.toLowerCase().includes(search.toLowerCase())) {
                return true
            }
            return false
        }
        const sortSuppliers = (a: SupplierType, b: SupplierType) => {
            if (
                a.name.toLowerCase().startsWith(search.toLowerCase()) &&
                !b.name.toLowerCase().startsWith(search.toLowerCase())
            ) {
                return -1
            } else if (
                !a.name.toLowerCase().startsWith(search.toLowerCase()) &&
                b.name.toLowerCase().startsWith(search.toLowerCase())
            ) {
                return 1
            }
            return 0
        }
        setFilteredSuppliers(suppliers.filter(filterSuppliers).sort(sortSuppliers))
    }, [search])

    return (
        <div className='flex flex-col gap-[4px] rounded-[4px] w-full'>
            <label className='font-bold text-[12px] dark:text-offwhite leading-[1] uppercase'>Supplier</label>
            <div ref={dropdown} className='w-full flex flex-col bg-lightgrey dark:bg-transparent relative'>
                <input
                    className={`focus:outline-none placeholder-current text-white dark:text-white font-[600] bg-darkgrey dark:bg-darkness p-[4px] ${
                        showDropdown ? 'rounded-t-[4px]' : 'rounded-[4px]'
                    }`}
                    placeholder='Search'
                    value={search}
                    onFocus={() => setShowDropdown(true)}
                    onChange={({ target }) => setSearch(target.value)}
                />
                {showDropdown && (
                    <div className='p-[8px] max-h-[150px] overflow-y-auto absolute top-[100%] rounded-b-[4px] left-0 bg-darkgrey dark:bg-darkness shadow-small w-full'>
                        {filteredSuppliers.map((supplier) => (
                            <div
                                key={supplier.name}
                                className='text-white dark:text-offwhite font-bold flex gap-[6px] items-center cursor-pointer w-full'
                                onClick={() => handleChangeSupplier(supplier)}
                            >
                                <div
                                    className={`w-[20px] h-[20px] rounded-[4px] border border-white dark:border-offwhite shrink-0 ${
                                        selectedSupplier.name === supplier.name ? 'bg-white dark:bg-offwhite' : ''
                                    }`}
                                >
                                    &nbsp;
                                </div>
                                <p className='break-all'>{supplier.name}</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}
