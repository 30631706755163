import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { NotificationSubscriptionType } from '../EDIScreens.types'
import { FaPlus } from 'react-icons/fa'

export default function NotificationToggler({
    station_id,
    event_type,
    setNotificationSubscriptions,
    label,
    notifications,
    emails,
}: {
    station_id?: string | number
    event_type: string
    setNotificationSubscriptions: Dispatch<SetStateAction<NotificationSubscriptionType[]>>
    label: string
    notifications: NotificationSubscriptionType[]
    emails: string[]
}) {
    const listRef = useRef<HTMLDivElement>(null)
    const [showSearch, setShowSearch] = useState(false)
    const [search, setSearch] = useState('')
    const [filteredEmails, setFilteredemails] = useState<string[]>(emails)

    const handleToggleNotification = (email: string) => {
        setNotificationSubscriptions((previousState) => {
            const newState = structuredClone(previousState)
            const foundIndex = previousState.findIndex(
                (notification) => notification.email === email && notification.event_type === event_type
            )
            if (foundIndex !== -1) {
                newState.splice(foundIndex, 1)
            } else {
                newState.push({
                    station_id,
                    event_type,
                    email,
                })
            }
            return newState
        })
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (listRef.current && !listRef.current.contains(e.target as HTMLElement)) {
                setShowSearch(false)
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    useEffect(() => {
        setFilteredemails(emails)
    }, [emails])

    useEffect(() => {
        setFilteredemails(
            emails.filter((email) => {
                if (email.includes(search.toLowerCase())) {
                    return true
                }
                return false
            })
        )
    }, [search])

    return (
        <div
            ref={listRef}
            className='flex flex-col gap-[4px] w-full rounded-[4px] bg-lightgrey dark:bg-darkness relative'
        >
            <div
                className='flex items-center justify-between cursor-pointer p-[8px]'
                onClick={() => {
                    setShowSearch((previousState) => !previousState)
                }}
            >
                <label className='text-darkgrey dark:text-offwhite font-bold text-[14px] leading-[1]'>
                    {label} ({notifications.length})
                </label>
                <FaPlus
                    className={`${
                        showSearch ? ' bg-grey rotate-45' : 'bg-blue dark:bg-accent text-white dark:text-darkness'
                    } transition-all w-[16px] h-[16px] p-[2px] rounded-full`}
                />
            </div>
            {showSearch && (
                <div className='absolute top-[100%] left-0 z-[5] w-full'>
                    <div className='w-full'>
                        <input
                            onChange={({ target }) => setSearch(target.value)}
                            className='w-full py-[4px] px-[8px] dark:text-offwhite focus:outline-none rounded-t-[4px] dark:bg-darkaccent border-4 border-lightgrey dark:border-darkness'
                            type='text'
                            placeholder='Search'
                        />
                    </div>
                    <div className='w-full overflow-auto max-h-[150px] p-[8px] shadow-small bg-white dark:bg-darkness'>
                        {filteredEmails.map((email) => (
                            <button
                                onClick={() => handleToggleNotification(email)}
                                className='flex items-center gap-[6px] border-b border-lightgrey dark:border-darkgrey dark:text-offwhite w-full p-[4px]'
                            >
                                <span
                                    className={`${
                                        notifications.find((notification) => email === notification.email)
                                            ? 'bg-blue dark:bg-fire'
                                            : ''
                                    } inline-block w-[16px] h-[16px] border border-blue dark:border-fire shrink-0 rounded-[4px]`}
                                >
                                    &nbsp;
                                </span>
                                {email}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
