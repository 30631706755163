import { useState } from 'react'
import { useGetSuppliers } from './api/useQueries'
import { useDebounce } from 'helpers'
import { Button } from 'custom_components/component_Basics/Button'
import SupplierRow from './lists/SupplierRow'
import { useSearchParams } from 'react-router-dom'
import EditSupplierModal from './modals/EditSupplierModal'
import CreateSupplierModal from './modals/CreateSupplierModal'

export default function SuppliersScreen() {
    const [searchParams, setSearchParams] = useSearchParams()
    const selectedSupplierId = searchParams.get('supplier_id')
    const showCreateModal = searchParams.get('showCreateModal') === 'true'
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const debouncedSearch = useDebounce(search, 300)
    const suppliersQuery = useGetSuppliers({
        page,
        search: debouncedSearch,
    })
    const suppliers = suppliersQuery.data?.suppliers || []

    const handleDecrement = () => {
        if (page === 1) {
            return
        }
        setPage((prev) => prev - 1)
    }
    const handleIncrement = () => {
        if (suppliers.length < 50) {
            return
        }
        setPage((prev) => prev + 1)
    }
    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showCreateModal')
            return setSearchParams(searchParams)
        }
        searchParams.set('showCreateModal', 'true')
        return setSearchParams(searchParams)
    }
    const toggleEditModal = (supplierId?: number) => {
        if (!supplierId) {
            searchParams.delete('supplier_id')
            return setSearchParams(searchParams)
        }
        searchParams.set('supplier_id', supplierId.toString())
        return setSearchParams(searchParams)
    }
    return (
        <div className='text-sm pb-32'>
            <div className='flex justify-between mb-4'>
                <h1 className='text-2xl font-semibold'>Suppliers</h1>
                <Button onClick={toggleCreateModal} variant={'outline'}>
                    + New Supplier
                </Button>
            </div>
            <div className='shadow-md border border-lightgrey dark:border-darkgrey'>
                <div>
                    <input
                        id='search'
                        name='search'
                        type='text'
                        value={search}
                        onChange={({ target }) => setSearch(target.value)}
                        className='p-2 bg-transparent w-full'
                        placeholder='Search for a supplier...'
                    />
                </div>
                <div className='font-bold bg-accent1 dark:bg-darkbg2 p-2 grid grid-cols-4 gap-2 text-white dark"text-offwhite'>
                    <p>Name</p>
                    <p>PO Type</p>
                    <p>Contact Name</p>
                    <p>Status</p>
                </div>
                {suppliers.map((supplier: any) => (
                    <SupplierRow supplier={supplier} />
                ))}
            </div>
            <div className='shadow-md flex items-center gap-2 fixed z-30 bottom-4 left-1/2 translate-x-[-50%] border border-lightgrey dark:border-darkgrey bg-bg1 dark:bg-darkbg1 p-2 rounded'>
                <button
                    className='border border-lightgrey dark:border-darkgrey bg-bg1 dark:bg-darkbg1 p-2 rounded'
                    onClick={handleDecrement}
                >
                    Prev
                </button>
                <div className='border border-lightgrey dark:border-darkgrey bg-bg1 dark:bg-darkbg1 p-2 rounded'>
                    {page}
                </div>
                <button
                    className='border border-lightgrey dark:border-darkgrey bg-bg1 dark:bg-darkbg1 p-2 rounded'
                    onClick={handleIncrement}
                >
                    Next
                </button>
            </div>
            {showCreateModal && <CreateSupplierModal closeModal={toggleCreateModal} />}
            {selectedSupplierId && (
                <EditSupplierModal selectedSupplierId={selectedSupplierId} closeModal={toggleEditModal} />
            )}
        </div>
    )
}
