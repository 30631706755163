import { useContext, useState } from 'react'
import { useCreateParentCompanyContact } from '../../../api/useQueries'
import { cn } from '../../../../helpers'
import { ProcurementContext, ProcurementContextType } from '../../../helpers'

export default function ParentCreateContact({
    company_ids,
    setShowQuickModal,
}: {
    company_ids: string[]
    setShowQuickModal: any
}) {
    const { selectedCompany } = useContext<ProcurementContextType>(ProcurementContext)
    const [contact, setContact] = useState({
        name: '',
        phone: '',
        email: '',
        alternate: '',
    })

    const createParentCompanyContact = useCreateParentCompanyContact()
    const { isPending } = createParentCompanyContact

    const handleSubmit = () => {
        createParentCompanyContact.mutate(
            { contact, company_ids, parent_company: selectedCompany?.parent_company },
            {
                onSuccess: () => {
                    setShowQuickModal(undefined)
                },
            }
        )
    }
    const handleChange = ({ target }: { target: HTMLInputElement | HTMLTextAreaElement }) => {
        setContact((previousState) => ({ ...previousState, [target.name]: target.value }))
    }
    return (
        <div className={cn(isPending && 'opacity-50')}>
            <div className='p-[16px] w-full bg-lightgrey dark:bg-darkness'>
                <h3 className='leading-[1] font-bold'>New Contact</h3>
            </div>
            <div className='p-[16px] flex flex-col gap-[4px]'>
                <div className='flex flex-col gap-[4px] w-full'>
                    <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                        Name
                    </label>
                    <input
                        className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                        id='name'
                        name='name'
                        onChange={handleChange}
                        value={contact.name}
                    />
                </div>
                <div className='flex gap-[8px] w-full'>
                    <div className='flex flex-col gap-[4px] w-full'>
                        <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                            Email
                        </label>
                        <input
                            className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                            id='email'
                            name='email'
                            onChange={handleChange}
                            value={contact.email}
                        />
                    </div>
                    <div className='flex flex-col gap-[4px] w-full'>
                        <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                            Phone
                        </label>
                        <input
                            className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                            id='phone'
                            name='phone'
                            onChange={handleChange}
                            value={contact.phone}
                        />
                    </div>
                </div>
                <div className='flex flex-col gap-[4px] w-full'>
                    <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                        Alternate
                    </label>
                    <textarea
                        className='h-[100px] border-none w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                        id='alternate'
                        name='alternate'
                        onChange={handleChange}
                        value={contact.alternate}
                    ></textarea>
                </div>
            </div>
            <div className='p-[16px] w-full bg-lightgrey dark:bg-darkness flex justify-center'>
                <button
                    onClick={handleSubmit}
                    className='py-[4px] px-[8px] rounded-[4px] text-[14px] bg-blue dark:bg-accent font-bold uppercase text-white dark:text-darkaccent'
                >
                    {isPending ? 'Saving..' : 'Save Contact'}
                </button>
            </div>
        </div>
    )
}
