import { useSearchParams } from 'react-router-dom'
import { useGetRoadmaps } from 'tasksV2/api/useQueries'
import CreateRoadmapModal from './modals/CreateRoadmapModal'
import RoadMapListV2 from './RoadMapListV2'

export default function RoadmapsScreen() {
    const [searchParams, setSearchParams] = useSearchParams()
    const showCreateModal = searchParams.get('showCreateModal') === 'true'
    const roadmapsQuery = useGetRoadmaps({})
    const roadmaps = roadmapsQuery.data?.roadmaps || []
    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showCreateModal')
        } else {
            searchParams.set('showCreateModal', 'true')
        }
        setSearchParams(searchParams)
    }
    return (
        <div className='text-sm'>
            <RoadMapListV2 />

            {showCreateModal && <CreateRoadmapModal closeModal={toggleCreateModal} />}
        </div>
    )
}
