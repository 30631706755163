import React, { useEffect, useRef, useState } from 'react'
import Editor from './QuillEditor'

// import { Delta } from 'quill';
// Or if you only need the core build
import { Delta } from 'quill/core'
// Or const Delta = Quill.import('delta');
import 'quill/dist/quill.core.css'
import { modules } from 'knowledge/constants'

const QuillEditorApp = ({ value, onChange, setEdited }) => {
    const [range, setRange] = useState()
    const [lastChange, setLastChange] = useState()
    const [readOnly, setReadOnly] = useState(false)

    // Use a ref to access the quill instance directly
    const quillRef = useRef()
    const delta = quillRef.current?.clipboard.convert({ html: value })

    // prevents page from displaying "unsaved changes" modal when there are none
    useEffect(() => {
        if (value && delta) {
            quillRef.current.setContents(delta)
            setTimeout(() => {
                setEdited(false)
            }, 10)
        }
    }, [quillRef.current])

    function onTextChange() {
        const HTML = quillRef.current.root.innerHTML
        onChange(HTML)
    }

    return (
        <div>
            <Editor
                ref={quillRef}
                readOnly={readOnly}
                onSelectionChange={setRange}
                onTextChange={onTextChange}
                modules={modules}
                setEdited={setEdited}
            />

            {/* {showBottomControls && (
                <div>
                    <div class='controls'>
                        <label>
                            Read Only:{' '}
                            <input type='checkbox' value={readOnly} onChange={(e) => setReadOnly(e.target.checked)} />
                        </label>
                        <button
                            className='controls-right'
                            type='button'
                            onClick={() => {
                                alert(quillRef.current?.getLength())
                            }}
                        >
                            Get Content Length
                        </button>
                    </div>
                    <div class='state'>
                        <div className='state-title'>Current Range:</div>
                        {range ? JSON.stringify(range) : 'Empty'}
                    </div>
                    <div class='state'>
                        <div className='state-title'>Last Change:</div>
                        {lastChange ? JSON.stringify(lastChange.ops) : 'Empty'}
                    </div>
                </div>
            )} */}
        </div>
    )
}

export default QuillEditorApp
