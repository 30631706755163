import { Button } from 'custom_components/component_Basics/Button'
import RefreshButton from 'custom_components/RefreshButton'
import { cn } from 'helpers'
import Input from 'procurement/components/Input'
import { productSetupQueries } from 'productSetupV2/api/productSetupQueryKeys'
import { useUpdateGroup } from 'productSetupV2/api/useQueries'
import { ChangeEvent, useState } from 'react'
import { FaPencil } from 'react-icons/fa6'
import { RiArrowGoBackLine } from 'react-icons/ri'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import DeleteGroupAreYouSure from './DeleteGroupAreYouSure'
import { ProductGroup } from './ProductGroupDetail'

export default function ProductGroupDetailHeader({ group, isFetching }: { group: ProductGroup; isFetching: boolean }) {
    const { groupId } = useParams()
    const navigate = useNavigate()
    const [showGroupNameEdit, setShowGroupNameEdit] = useState(false)
    const [editableFields, setEditableFields] = useState<any>({})
    const previousLocationPathname: string | undefined = useLocation().state?.previousLocationPathname
    const [showAreYouSure, setShowAreYouSure] = useState(false)

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setEditableFields({
            ...editableFields,
            [target.name]: target.value,
        })
    }

    const updateGroup = useUpdateGroup()

    const handleSaveGroup = () => {
        if (group?.id) {
            updateGroup.mutate(
                { groupId: group.id, name: editableFields.group_name },
                {
                    onSuccess: () => {
                        setEditableFields({})
                        setShowGroupNameEdit(false)
                    },
                }
            )
        }
    }
    const handleCancelEdit = () => {
        setEditableFields({})
        setShowGroupNameEdit(false)
    }

    return (
        <div className='flex justify-between mb-[8px]'>
            <div className='flex gap-2'>
                <span
                    onClick={() => {
                        if (previousLocationPathname?.includes('product-groups')) {
                            navigate(-1)
                            return
                        }
                        navigate('/products/product-groups')
                    }}
                    className='self-center cursor-pointer'
                >
                    <RiArrowGoBackLine />
                </span>
                <div className=' flex gap-4 items-center'>
                    <h1 className='font-semibold text-[24px]'>Group: </h1>
                    {!showGroupNameEdit && (
                        <Input
                            type='text'
                            id='group_name'
                            name='group_name'
                            onChange={handleChange}
                            value={editableFields.group_name ?? group.group_name}
                            readOnly={true}
                            autoFocus={false}
                            className={cn(
                                'text-[20px] leading-none min-w-[500px] dark:bg-darkbg1',
                                !showGroupNameEdit &&
                                    'cursor-default bg-bg1 dark:bg-darkbg2 border dark:border-darkgrey'
                            )}
                        />
                    )}
                    {showGroupNameEdit && (
                        <Input
                            type='text'
                            id='group_name'
                            name='group_name'
                            onChange={handleChange}
                            value={editableFields.group_name ?? group.group_name}
                            readOnly={false}
                            autoFocus={true}
                            className={cn(
                                'text-[20px] leading-none min-w-[500px] dark:bg-darkbg1',
                                !showGroupNameEdit &&
                                    'cursor-default bg-bg1 dark:bg-darkbg2 border dark:border-darkgrey'
                            )}
                        />
                    )}
                    {showGroupNameEdit ? (
                        <div className='flex gap-2 items-center'>
                            <button
                                onClick={handleCancelEdit}
                                className={cn(
                                    updateGroup.isPending && 'opacity-[0.6] pointer-events-none',
                                    'text-xs font-bold py-1 px-2 rounded bg-lightgrey dark:bg-darkgrey leading-none'
                                )}
                            >
                                Cancel
                            </button>

                            <button
                                onClick={handleSaveGroup}
                                className={cn(
                                    updateGroup.isPending && 'opacity-[0.6] pointer-events-none',
                                    'text-xs font-bold py-1 px-2 rounded bg-accent1 dark:bg-accent1 dark:text-darkbg2 leading-none text-white dark:text-offwhite min-w-[100px]'
                                )}
                            >
                                {updateGroup.isPending ? 'Saving...' : 'Save'}
                            </button>
                        </div>
                    ) : (
                        <div
                            className='h-9 w-9 cursor-pointer flex items-center justify-center'
                            onClick={() => setShowGroupNameEdit(true)}
                        >
                            <div className='h-[16px] w-[16px]'>
                                <FaPencil />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className='flex gap-8 items-center'>
                <Button
                    className='border border-danger text-danger dark:text-darkdanger dark:bg-darkbg2'
                    variant={'outline'}
                    size={'sm'}
                    onClick={() => setShowAreYouSure((prev) => !prev)}
                >
                    Delete Group
                </Button>
                <RefreshButton
                    queryKeys={[productSetupQueries.productGroups._def.toString()]}
                    isFetching={isFetching}
                />
            </div>
            {showAreYouSure && <DeleteGroupAreYouSure setShowAreYouSure={setShowAreYouSure} />}
        </div>
    )
}
