import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'

// queries/companies.ts

export const topMovers = createQueryKeys('top_movers', {
    topMovers: (period) => ({
        queryKey: [period],
        queryFn: ({ signal }) => service.getTopMovers(period, signal),
    }),
})

export const topMoversQueries = mergeQueryKeys(topMovers)
