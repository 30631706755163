import { useAllUsersQuery } from 'users/api/useQueries'
import { UserInit } from 'users/users.types'
const timeFormatter = new Intl.DateTimeFormat('en-US', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
}).format
export default function AdjustmentRow({ adjustment }: any) {
    const usersQuery = useAllUsersQuery()
    const users: UserInit[] = usersQuery.data?.users || []
    const foundUser = users.find((user) => user.id === adjustment.user_id)
    return (
        <div className='grid grid-cols-7 border-b border-lightgrey dark:border-darkgrey'>
            <p className='p-2'>{adjustment.sku}</p>
            <p className='p-2'>{timeFormatter(new Date(adjustment.created_at))}</p>
            <p className='p-2'>
                {adjustment.previous_cost !== adjustment.new_cost && (
                    <>
                        {adjustment.previous_cost || 'NONE'} -&gt; {adjustment.new_cost || 'NONE'}
                    </>
                )}
            </p>
            <p className='p-2'>
                {adjustment.previous_price !== adjustment.new_price && (
                    <>
                        {adjustment.previous_price} -&gt; {adjustment.new_price || 'NONE'}
                    </>
                )}
            </p>
            <p className='p-2'>
                {adjustment.previous_compare_at_price !== adjustment.new_compare_at_price && (
                    <>
                        {adjustment.previous_compare_at_price || 'NONE'} -&gt;{' '}
                        {adjustment.new_compare_at_price || 'NONE'}
                    </>
                )}
            </p>
            <p className='p-2 capitalize'>{adjustment.adjustment_platform.replaceAll('_', ' ').toLowerCase()}</p>
            {foundUser ? (
                <p className='p-2'>
                    {foundUser?.first_name} {foundUser?.last_name}
                </p>
            ) : (
                <p className='p-2'>Unknown</p>
            )}
        </div>
    )
}
