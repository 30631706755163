import { CiWarning } from 'react-icons/ci'
import { useGetResourceTasks } from 'tasksV2/api/useQueries'
import { SHOW_EXISTING_TASK_ALERT_RESOURCE_NAMES } from 'tasksV2/constants/tasks'

export default function ExistingTaskCheck({
    resource_name,
    resource_id,
    department_id,
    type_id,
}: {
    resource_name: string
    resource_id: number
    department_id: number
    type_id: number
}) {
    const tasksQuery = useGetResourceTasks(resource_name, resource_id)
    const tasks = (tasksQuery.data?.tasks || []).filter(
        (task: any) => task.department_id == department_id && task.type_id == type_id
    )

    if (!SHOW_EXISTING_TASK_ALERT_RESOURCE_NAMES.includes(resource_name)) {
        return <></>
    }

    return (
        <>
            {tasks.length > 0 && (
                <div className='relative group'>
                    <CiWarning className='text-lg text-fire' />
                    <p className='hidden group-hover:block absolute top-full left-full w-40 font-semibold z-40 bg-bg1 dark:bg-darkbg1 p-2 rounded- shadow-md'>
                        A task with this department and type already exists for this resource
                    </p>
                </div>
            )}
        </>
    )
}
