import { CaretDownIcon, CaretUpIcon } from '@radix-ui/react-icons'
import {
    GlobalFilterTableState,
    SortingState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { unfulfilledLineItemType } from 'procurement/components/lists/UnfulfilledOrdersList'
import React, { useEffect, useState } from 'react'
import { cn, useDebounce } from '../../helpers'

export default function UnfulfilledLineItemTableV2({
    tableData,
    columns,
    columnCount,
    search,
    width = '85vw',
    rowSelection,
    setRowSelection,
}: any) {
    const [sorting, setSorting] = React.useState<SortingState>([])

    const [filterState, setFilterState] = useState<GlobalFilterTableState>({
        globalFilter: '',
    })

    const debouncedSearch = useDebounce(search, 300)
    useEffect(() => {
        setFilterState({
            globalFilter: debouncedSearch,
        })
    }, [debouncedSearch])

    const table = useReactTable({
        onRowSelectionChange: setRowSelection,
        columns,
        data: tableData,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        getRowId: (row: unfulfilledLineItemType) => row.id.toString(),
        state: { sorting, rowSelection, globalFilter: filterState.globalFilter },
        getFilteredRowModel: getFilteredRowModel(),
        onGlobalFilterChange: setFilterState,
        globalFilterFn: 'includesString',
    })

    const columnInsert: string[] = []
    for (let index = 0; index < columnCount; index++) {
        columnInsert.push('1fr')
    }
    return (
        <table className={`w-[${width}] bg-blue dark:bg-darkbg2 shadow-md p-2`}>
            <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr
                        key={headerGroup.id}
                        className={cn(
                            columnInsert ? `grid-cols-[${columnInsert.join('_')}]` : 'grid-cols-10',
                            ' bg-blue dark:bg-darkbg2 px-2 '
                        )}
                    >
                        {headerGroup.headers.map((header) => (
                            <th key={header.id} colSpan={header.colSpan} className='text-start capitalize text-sm px-2'>
                                {header.isPlaceholder ? null : (
                                    <div
                                        className={
                                            header.column.getCanSort()
                                                ? 'cursor-pointer select-none flex gap-1'
                                                : 'flex gap-1'
                                        }
                                        onClick={header.column.getToggleSortingHandler()}
                                        title={
                                            header.column.getCanSort()
                                                ? header.column.getNextSortingOrder() === 'asc'
                                                    ? 'Sort ascending'
                                                    : header.column.getNextSortingOrder() === 'desc'
                                                      ? 'Sort descending'
                                                      : 'Clear sort'
                                                : undefined
                                        }
                                    >
                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                        {{
                                            asc: (
                                                <div className='self-center min-h-[18px]'>
                                                    <CaretUpIcon />
                                                </div>
                                            ),
                                            desc: (
                                                <div className='self-center min-h-[18px]'>
                                                    <CaretDownIcon />
                                                </div>
                                            ),
                                        }[header.column.getIsSorted() as string] ?? null}
                                    </div>
                                )}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody className='w-full'>
                {table.getRowModel().rows.map((row, index) => (
                    <tr
                        key={row.id}
                        className={cn(
                            `w-full bg-bg1 dark:bg-darkbg2 text-black dark:text-offwhite border-b-[2px] px-2 border-lightgrey dark:border-gray-500`,
                            index % 2 === 0 && 'bg-lightgrey dark:bg-darkbg1'
                        )}
                    >
                        {row.getVisibleCells().map((cell) => {
                            return (
                                <td key={cell.id} className='px-2'>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            )
                        })}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}
