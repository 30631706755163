export default function EmailContainer({ html, mode = '' }: { html: any; mode: string }) {
    const css = `
    * {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
            'Droid Sans', 'Helvetica Neue', sans-serif;
    }
    a {
        color: inherit;
        text-decoration: none;
    }
    div {
        color: inherit;
        text-decoration: none;
    }
    p {
        color: inherit;
        text-decoration: none;
    }
    span {
        color: inherit;
        text-decoration: none;
    }
    .wrapper {
        background-color: ${mode === 'dark' ? '#f8f0e3' : '#ffffff'};
        color: black;
    }
    `

    return (
        <div>
            <style>{css}</style>
            <div dangerouslySetInnerHTML={{ __html: JSON.parse(html) }} className='wrapper'></div>
        </div>
    )
}
