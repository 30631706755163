export default function User({ user, setFocus, showDeveloperSetRoles }) {
    return (
        <div
            className='flex gap-[32px] p-[16px] shadow-small my-[16px] mx-auto bg-white dark:bg-darkaccent'
            key={user.email}
            onClick={() => setFocus(user)}
        >
            <div className='w-[250px]'>
                <p className='text-[20px] font-extrabold uppercase text-blue dark:text-accent mb-[4px]'>
                    {user.first_name} {user.last_name}
                </p>
                <p className='text-[12px] font-bold uppercase text-[#4a4a4a] dark:text-grey'>{user.email}</p>
            </div>
            <div className='w-[250px] '>
                <p className='uppercase font-bold text-fire text-[14px]'>Type</p>
                <p className='dark:text-offwhite'>{user.type}</p>
            </div>
            <div className='w-[250px]'>
                <p className='uppercase font-bold text-fire text-[14px]'>Access</p>
                <div className='dark:text-offwhite'>
                    {user.access.map((a) => {
                        if (a === '*') {
                            return <p>All Routes</p>
                        } else {
                            const parsedRoute = a
                                .split('-')
                                .map((val) => `${val.substring(0, 1).toUpperCase()}${val.substring(1)}`)
                                .join(' ')
                            return <p>{parsedRoute}</p>
                        }
                    })}
                </div>
            </div>
            <div className='w-[250px]'>
                <p className='uppercase font-bold text-fire text-[14px]'>Roles</p>
                <div className='dark:text-offwhite capitalize'>
                    {user.roles
                        .filter((role) => (showDeveloperSetRoles ? true : role[0] !== '_'))
                        .map((role) => (
                            <p>{role}</p>
                        ))}
                </div>
            </div>
            <div className='w-[250px]'>
                <p className='uppercase font-bold text-fire text-[14px]'>Stores</p>
                <div className='dark:text-offwhite capitalize'>
                    {(user.store_name || "").split(",").map((store_name) => (
                        <p>{store_name}</p>
                    ))}
                </div>
            </div>
        </div>
    )
}
