import { useRef } from 'react'

import { Button, useDayRender } from 'react-day-picker'
import { cn } from '../../helpers'
import { buttonVariants } from './Button'

/** Represent the props used by the {@link Day} component. */
export interface DayProps {
    /** The month where the date is displayed. */
    displayMonth: Date
    /** The date to render. */
    date: Date
    tasks: any
}

/**
 * The content of a day cell – as a button or span element according to its
 * modifiers.
 */
export function CustomTaskDay(props: any): JSX.Element {
    const buttonRef = useRef<HTMLButtonElement>(null)
    const dayRender = useDayRender(props.date, props.displayMonth, buttonRef)
    if (dayRender.isHidden) {
        return <div role='gridcell'></div>
    }
    if (!dayRender.isButton) {
        return <div {...dayRender.divProps} />
    }
    return (
        <Button
            className={cn(
                buttonVariants({
                    variant: 'ghost',
                    className:
                        ' text-text1 dark:text-darktext1 p-[2px] hover:bg-blue h-4 hover:dark:bg-darkbg2 hover:text-white dark:hover:text-darktext1',
                }),
                'h-9 w-9 p-2 font-normal aria-selected:opacity-100 '
            )}
            name='day'
            ref={buttonRef}
            {...dayRender.buttonProps}
        />
    )
}
