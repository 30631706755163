import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

export default function EditListNote({
    company,
    mutationFunction,
    latestNote = '',
}: {
    company: any
    mutationFunction: any
    latestNote?: string
}) {
    const user = useSelector<any, any>((state) => state.user)
    const editRef = useRef<HTMLDivElement>(null)
    const [editableNote, setEditableNote] = useState<string | undefined>(undefined)
    const [editNote, setEditNote] = useState(false)

    const { isPending, variables } = mutationFunction

    const isEdited = editableNote !== undefined && editableNote !== latestNote

    const handleSubmit = async () => {
        mutationFunction.mutate(
            { note: editableNote, companyId: company.id, user },
            {
                onSuccess: () => {
                    setEditableNote(undefined)
                },
            }
        )
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!editRef?.current?.contains(e.target as HTMLElement)) {
                setEditNote(false)
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    useEffect(() => {
        setEditableNote(latestNote)
    }, [latestNote])

    useEffect(() => {
        if (isPending) {
            setEditNote(false)
        }
    }, [variables])

    return (
        <div className='relative p-2 dark:text-offwhite flex flex-col gap-[4px] h-full'>
            <div className='group absolute top-2 right-2'>
                {isEdited && !editNote && (
                    <div className='group relative z-[4]'>
                        <ExclamationTriangleIcon className='text-danger ' />
                        <div className='hidden group-hover:flex border absolute top-[100%] right-0 w-fit whitespace-nowrap bg-white shadow-small text-sm dark:bg-darkbg1 p-2 rounded-[4px] z-[5] '>
                            Unsaved Note
                        </div>
                    </div>
                )}
            </div>
            <div
                ref={editRef}
                className={` absolute top-0 left-0 w-full ${
                    editNote
                        ? 'z-[5] bg-white dark:bg-darkaccent shadow-small border border-lightgrey dark:border-darkgrey rounded-[4px]'
                        : 'h-full after:w-full after:h-[16px] after:bg-[linear-gradient(to_top,white,transparent)] dark:after:bg-[linear-gradient(to_top,#1D212D,transparent)] after:absolute after:bottom-0 after:left-0'
                }`}
                onClick={() => setEditNote(true)}
            >
                <textarea
                    className={` cursor-pointer w-full ${
                        editNote ? 'max-h-[150px] bg-white dark:bg-darkaccent' : '!h-full !overflow-hidden'
                    } px-[8px] text-[16px] leading-1 bg-transparent focus:outline-none dark:text-white  border-0 py-[4px] rounded-[4px]`}
                    value={editableNote ?? latestNote}
                    onChange={({ target }) => setEditableNote(target.value)}
                ></textarea>
                {editNote && (
                    <div className={` p-[8px] flex items-center justify-center`}>
                        <button
                            onClick={() => {
                                handleSubmit()
                                setEditNote(false)
                            }}
                            className='py-[4px] px-[8px] rounded-[4px] bg-blue text-white dark:bg-accent dark:text-darkaccent font-bold uppercase text-[14px]'
                        >
                            Submit New Note
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}
