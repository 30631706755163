import { vFetch } from 'helpers'
import { useSearchParams } from 'react-router-dom'

export default function XeroScreen() {
    const [searchParams] = useSearchParams()
    const code = searchParams.get('code')
    const authenticate = () => {
        vFetch('/v1/xero/consent', {
            cb: (res: any) => {
                window.open(res.consent_url)
            },
        })
    }
    if (code) {
        vFetch(`/v1/xero/callback?url=${window.location}`)
    }
    return <button onClick={authenticate}>Authenticate Xero</button>
}
