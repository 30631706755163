import { Button } from 'custom_components/component_Basics/Button'
import Input from 'procurement/components/Input'
import { useCreateGroup } from 'productSetupV2/api/useQueries'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function CreateNewGroup({ setShowCreate }: { setShowCreate: any }) {
    const navigate = useNavigate()
    const createGroup = useCreateGroup()
    const [editableFields, setEditableFields] = useState<any>({})
    const modalRef = useRef(null)

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setEditableFields({
            ...editableFields,
            [target.name]: target.value,
        })
    }

    const handleCreate = () => {
        if (editableFields.group_name) {
            createGroup.mutate(
                { group_name: editableFields.group_name },
                {
                    onSuccess: (data) => {
                        const groupId = data.groupId
                        navigate(`/products/product-groups/${groupId}`)
                    },
                }
            )
        }
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (e.target === modalRef.current) {
                setShowCreate(false)
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])
    return (
        <div
            ref={modalRef}
            className='w-full h-full fixed top-0 left-0 z-[50] bg-[rgb(0,0,0,0.5)] grid place-items-center'
        >
            <div className='flex flex-col gap-4 p-[32px] rounded-[4px] shadow-small bg-white dark:bg-darkaccent'>
                <Input
                    label='Group Name'
                    id='group_name'
                    type='text'
                    name='group_name'
                    value={editableFields.name}
                    onChange={handleChange}
                    className='min-w-[430px]'
                />
                <div className='flex gap-8 justify-center'>
                    <Button
                        size={'sm'}
                        variant={'outline'}
                        onClick={() => setShowCreate(false)}
                        className='font-bold  '
                    >
                        Cancel
                    </Button>
                    <Button size={'sm'} variant={'outline'} onClick={handleCreate} className=' font-bold'>
                        Create Group
                    </Button>
                </div>
            </div>
        </div>
    )
}
