import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { vFetch } from 'helpers'

export default function CacheScreenV2() {
    const cacheDetailsQuery = useQuery({
        queryKey: ['cacheDetails'],
        queryFn: () => vFetch(`/v2/cache`),
        refetchInterval: 1000,
    })

    const queryClient = useQueryClient()

    const clearCacheMutation = useMutation({
        mutationFn: () => vFetch(`/v2/cache/clear`),
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: ['cacheDetails'] })
        },
    })

    const clearCache = () => {
        clearCacheMutation.mutate()
    }

    const cacheDetails = cacheDetailsQuery.data?.cacheDetails?.cache || {}

    return (
        <div>
            <p>Nodes: {cacheDetails.nodeCount}</p>
            <p>size: {cacheDetails.size?.toFixed(2)}mb</p>
            <p>Requests: {cacheDetails.requestCount}</p>
            <p>Hits: {cacheDetails.cacheHits}</p>
            <p>Percentage: {(((cacheDetails.cacheHits || 0) / (cacheDetails.requestCount || 1)) * 100).toFixed(2)} %</p>
            <Button onClick={clearCache}>Clear Cache</Button>
            <div className={`${cacheDetailsQuery.isFetching ? 'text-lime' : 'opacity-50'}`}>
                <span className={`${cacheDetailsQuery.isFetching ? 'opacity-50' : 'text-red'}`}>Not</span> Fetching
            </div>
        </div>
    )
}
