import { MessageListProps, ProcessingTimesRow } from '../../ProcessingTimesScreen.types'

export default function MessageList({
    messages = [],
    setSelectedProcessingTime,
    search,
    filter = '',
    page = 1,
}: MessageListProps) {
    const searchlower = search.toLowerCase()
    const filteredMessages = filterMessages(messages, searchlower, page)
    /**
     * Returns a filtered list of messages based on the search, filteres, and page
     */
    function filterMessages(messages: ProcessingTimesRow[], search: string, page: number) {
        const startIndex = (page - 1) * 50
        const endIndex = page * 50
        return messages
            .filter((message) => {
                if (filter.length) {
                    if (filter === 'PDP' && message.type !== 'PDP') {
                        return false
                    }
                    if (filter === 'CART' && message.type !== 'CART') {
                        return false
                    }
                    if (filter === 'STARTING' && !message.starts_on) {
                        return false
                    }
                    if (filter === 'ENDING' && !message.ends_on) {
                        return false
                    }
                    if (
                        filter === 'RECENT' &&
                        new Date(message.updated_at).getTime() < Date.now() - 1000 * 60 * 60 * 24 * 7
                    ) {
                        return false
                    }
                }
                return (
                    message.name?.toLowerCase().includes(search) ||
                    message.pdp_line_1?.toLowerCase().includes(search) ||
                    message.pdp_line_2?.toLowerCase().includes(search) ||
                    message.cart_line_1?.toLowerCase().includes(search) ||
                    message.type?.toLowerCase().includes(search)
                )
            })
            .slice(startIndex, endIndex)
    }
    const dateFormatter = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        timeZone: 'UTC',
    })
    return (
        <>
            <div className='sticky top-[50px] grid grid-cols-[1fr_60px_2fr_150px_150px_100px] col-span-full border-b border-lightgrey bg-blue text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Name</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Type</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Content</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Starts On</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Ends On</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Affects</div>
            </div>
            {filteredMessages.map((message) => (
                <div
                    key={message.name}
                    onClick={() => setSelectedProcessingTime(message)}
                    className='grid grid-cols-[1fr_60px_2fr_150px_150px_100px] border-b border-lightgrey cursor-pointer dark:border-darkgrey hover:bg-lightgrey dark:hover:bg-darkness'
                >
                    <div className='p-[8px] font-bold dark:text-offwhite'>{message.name}</div>
                    <div className='p-[8px] text-fire font-bold'>{message.type}</div>
                    <div className='p-[8px]'>
                        {message.type === 'PDP' ? (
                            <>
                                <p className='flex font-bold text-darkgrey dark:text-offwhite uppercase gap-[6px]'>
                                    {message.pdp_line_1}
                                </p>
                                <p className='flex gap-[6px] dark:text-offwhite'>{message.pdp_line_2}</p>
                            </>
                        ) : (
                            <p className='font-bold text-darkgrey dark:text-offwhite uppercase'>
                                {message.cart_line_1}
                            </p>
                        )}
                    </div>
                    <div className='p-[8px] dark:text-offwhite'>
                        {message.starts_on ? dateFormatter.format(new Date(message.starts_on)) : ''}
                    </div>
                    <div className='p-[8px] dark:text-offwhite'>
                        {message.ends_on ? dateFormatter.format(new Date(message.ends_on)) : ''}
                    </div>
                    <div className='p-[8px] dark:text-offwhite'>{message.affected_resources?.split(',').length}</div>
                </div>
            ))}
        </>
    )
}
