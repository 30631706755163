import { useEffect, useRef } from 'react'
import AdjustmentList from '../lists/AdjustmentList'
import { FaTimes } from 'react-icons/fa'

export default function AdjustmentsModal({ productId, toggleModal }: { productId: string; toggleModal: () => void }) {
    const modalRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (modalRef.current === e.target) {
                toggleModal()
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <div
            ref={modalRef}
            className='fixed z-50 top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center'
        >
            <div className='p-6 rounded bg-white dark:bg-darkbg1 shadow-md max-w-[95%] max-h-[95%] overflow-auto relative'>
                <button onClick={toggleModal} className='text-red text-xl absolute top-1 right-1'>
                    <FaTimes />
                </button>
                <AdjustmentList productId={productId} />
            </div>
        </div>
    )
}
