import { vFetch } from 'helpers'

export const createSupplier = (supplier: any) => {
    return vFetch(`/v2/suppliers`, {
        method: 'POST',
        body: JSON.stringify(supplier),
    })
}

export const getSuppliers = async (filters: any) => {
    const params = Object.entries(filters)
        .filter(([_key, val]) => val === 0 || val)
        .map(([key, val]) => `${key}=${val}`)
        .join('&')
    return vFetch(`/v2/suppliers${params ? `?${params}` : ''}`)
}

export const updateSupplier = async (supplier: any) => {
    return vFetch(`/v2/suppliers`, {
        method: 'PUT',
        body: JSON.stringify(supplier),
    })
}

export const getSupplierProducts = async ({ supplier_id, search }: { supplier_id: number; search?: string }) => {
    return vFetch(`/v2/suppliers/${supplier_id}/products?limit=999999999${search ? `&search=${search}` : ''}`)
}

export const getAllProducts = async (filters: { search?: string; vendor?: string }) => {
    const params = Object.entries(filters)
        .filter(([_key, val]) => val)
        .map(([key, val]) => `${key}=${val}`)
        .join('&')
    return vFetch(`/v2/suppliers/products?limit=500${params ? `&${params}` : ''}`)
}

export const addProductsToSupplier = ({ product_ids, supplier_id }: { product_ids: number[]; supplier_id: number }) => {
    return vFetch('/v2/suppliers/products', {
        method: 'PUT',
        body: JSON.stringify({ product_ids, supplier_id }),
    })
}

export const removeProductsFromSupplier = ({
    product_ids,
    supplier_id,
}: {
    product_ids: number[]
    supplier_id: number
}) => {
    return vFetch('/v2/suppliers/products', {
        method: 'DELETE',
        body: JSON.stringify({ product_ids, supplier_id }),
    })
}
