import { ActiveModifiers, DateRange, useDayPicker } from 'react-day-picker'
import { cn } from '../../helpers'

export interface DayContentProps {
    date: Date
    displayMonth: Date
    activeModifiers: ActiveModifiers
}

export function CustomDayCellBasic({
    date,
    selectedRange,
    setRangeString,
    setSelectedCompare,
}: {
    date: Date
    selectedRange: DateRange
    displayMonth: Date
    activeModifiers: ActiveModifiers
    setRangeString?: any
    setSelectedCompare?: any
}): JSX.Element {
    const {
        locale,
        formatters: { formatDay },
    } = useDayPicker()
    const today = new Date()

    const day = new Date(date)
    day.setHours(0, 0, 0, 0)
    today.setHours(0, 0, 0, 0)

    if (selectedRange?.from) {
        if (day.toISOString() >= selectedRange.from?.toISOString()) {
            // show selected range
        }
    }
    return (
        <>
            <div
                onClick={() => {
                    setSelectedCompare()
                    // setRangeString()
                }}
                className='p-0 flex flex-col w-full h-full justify-center'
            >
                <span className={cn(today.toISOString() == day.toISOString() && 'font-bold', 'self-center leading-3')}>
                    {formatDay(date, { locale })}
                </span>
            </div>
        </>
    )
}
