import { Button } from 'custom_components/component_Basics/Button'
import { useDeleteContact, useUpdateContact } from 'procurement/api/useQueries'
import { useEffect, useRef, useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import VendorContactFieldsv2 from '../../../vendors/components/VendorContactFieldsv2'

type EditVendorContactProps = {
    contactTypes: string[]
    contact: any
    setShowDetails: any
    setVendorContactToEdit?: Function
    refreshVendorsList?: Function
}

export default function ContactDetails({ contact, setShowDetails }: EditVendorContactProps) {
    const modalRef = useRef(null)
    const [showDelete, setShowDelete] = useState(false)

    const deleteContact = useDeleteContact()
    const updateContact = useUpdateContact()

    const handleDelete = async () => {
        deleteContact.mutate({ contact })
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (e.target === modalRef.current) {
                setShowDetails(false)
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    return (
        <div className='fixed top-0 left-0 w-[100%] h-[100%] justify-center bg-[rgba(0,0,0,0.5)] z-50'>
            <div ref={modalRef} className='relative flex justify-center items-center h-[calc(100%-51px)] w-[100%]'>
                <div className='relative min-w-[600px] bg-white dark:bg-darkaccent dark:text-offwhite p-[16px] rounded'>
                    <button
                        onClick={() => {
                            setShowDetails(false)
                        }}
                        className='leading-[1] text-[24px] text-red dark:text-lightred absolute font-bold top-0 right-[8px]'
                    >
                        &times;
                    </button>
                    <h2 className='text-[24px] font-semibold dark:text-offwhite capitalize mb-[8px]'>
                        {contact.id ? 'Edit' : 'Create'} {contact.vendor ? contact.vendor : 'Vendor'} Contact
                    </h2>

                    <div className='grid gap-[8px] overflow-auto max-h-[calc(70vh-51px)]'>
                        <VendorContactFieldsv2
                            contact={contact}
                            setShowDetails={setShowDetails}
                            updateContact={updateContact}
                        />
                    </div>
                    <button
                        disabled={updateContact.isPending}
                        className='absolute bottom-[12px] right-[12px] '
                        onClick={() => setShowDelete(true)}
                    >
                        <FaTrash className='text-red dark:text-lightred' />
                    </button>
                </div>
            </div>

            {showDelete && (
                <div className='flex absolute top-0 w-[100%] h-[100%] bg-black/50 z-50'>
                    <div className='flex w-[100%] h-[100%] justify-center items-center'>
                        <div className='relative min-w-[200px] bg-white dark:bg-darkaccent dark:text-offwhite p-[16px] rounded'>
                            <h2 className='text-[24px] font-semibold dark:text-offwhite capitalize mb-[8px]'>
                                Are you sure you want to delete this contact?
                            </h2>

                            <div className='flex justify-center gap-10'>
                                <Button
                                    disabled={updateContact.isPending}
                                    variant={'destructive'}
                                    className='w-[100px] p-[8px] font-semibold hover:bg-lightgrey/50  shadow-small'
                                    onClick={handleDelete}
                                >
                                    Delete
                                </Button>
                                <Button
                                    disabled={updateContact.isPending}
                                    variant={'secondary'}
                                    className='w-[100px] p-[8px] font-semibold'
                                    onClick={() => setShowDelete(false)}
                                >
                                    No
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
