import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import queryKeys from './queryKeys'
import {
    CreateChangelogEntryOptions,
    CreateChangelogOptions,
    CreateChangelogReleaseOptions,
    UpdateChangelogEntryOptions,
    UpdateChangelogReleaseOptions,
} from 'changelog/changelogs/Changelog.types'
import * as service from './service'

export const useCreateChangelog = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (changelog: CreateChangelogOptions) => service.createChangelog(changelog),
        onSuccess: () => {
            return Promise.all([queryClient.invalidateQueries({ queryKey: queryKeys.changelogs._def })])
        },
    })
}

export const useGetChangelogs = (filters: any) => {
    return useQuery({
        ...queryKeys.changelogs.list(filters),
        placeholderData: keepPreviousData,
    })
}

export const useCreateRelease = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (release: CreateChangelogReleaseOptions) => service.createRelease(release),
        onSuccess: () => {
            return Promise.all([queryClient.invalidateQueries({ queryKey: queryKeys.changelogs._def })])
        },
    })
}

export const useGetReleases = (filters: any) => {
    return useQuery({
        ...queryKeys.changelogs.releases(filters),
        placeholderData: keepPreviousData,
    })
}

export const useUpdateRelease = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (release: UpdateChangelogReleaseOptions) => service.updateRelease(release),
        onSuccess: () => {
            return Promise.all([queryClient.invalidateQueries({ queryKey: queryKeys.changelogs._def })])
        },
    })
}

export const useCreateEntry = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (entry: CreateChangelogEntryOptions) => service.createEntry(entry),
        onSuccess: () => {
            return Promise.all([queryClient.invalidateQueries({ queryKey: queryKeys.changelogs._def })])
        },
    })
}

export const useGetEntries = (filters: any) => {
    return useQuery({
        ...queryKeys.changelogs.entries(filters),
        placeholderData: keepPreviousData,
    })
}

export const useUpdateEntry = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (entry: UpdateChangelogEntryOptions) => service.updateEntry(entry),
        onSuccess: () => {
            return Promise.all([queryClient.invalidateQueries({ queryKey: queryKeys.changelogs._def })])
        },
    })
}

export const useDeleteEntry = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (entryId: number) => service.deleteEntry(entryId),
        onSuccess: () => {
            return Promise.all([queryClient.invalidateQueries({ queryKey: queryKeys.changelogs._def })])
        },
    })
}
