import { useState } from 'react'
import { useGetCompetitorProducts } from './api/useQueries'
import CompetitorProductList from './lists/CompetitorProductList'
import Pagination from './components/Pagination'
import { useDebounce } from '../helpers'
import CompetitorProductRowSkeleton from './lists/CompetitorProductRowSkeleton'

export default function CompetitorCatalogsScreen() {
    return (
        <div>
            <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize mb-2'>Competitor Products</h1>
            <CompetitorProductList />
        </div>
    )
}
