import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { LoadingGear, parseResObject, vFetch } from '../helpers'
import NotFound from '../routes/NotFound'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { FaCaretRight, FaCircle, FaRegCircle, FaUserCircle } from 'react-icons/fa'
import Textarea from '../procurement/components/Textarea'
import { priorityMap, taskStatusDisplayMap } from './constants'
import FetchOptionSelector from './components/FetchOptionSelector'
import { useProjects, useUpdateTask } from './api/useQueries'
import { BiSolidPyramid } from 'react-icons/bi'
import { useAllUsersQuery } from '../users/api/useQueries'
import { useDeleteTask } from '../procurement/api/useQueries'
import { UserInit } from '../users/users.types'
import { monthDateShortFormatter } from './helpers'
import { getAuditHistory } from '../audit/api/service'
import { auditHistoryType } from '../procurement/components/CompanyProductEditHistory'
import { Button } from '../custom_components/component_Basics/Button'
import { FetchingSpinner } from '../custom_components/FetchingSpinner'

export default function DevelopmentTaskScreen() {
    const statusRef = useRef<HTMLDivElement>(null)
    const priorityRef = useRef<HTMLDivElement>(null)
    const estimateRef = useRef<HTMLDivElement>(null)
    const assignedToRef = useRef<HTMLDivElement>(null)
    const projectRef = useRef<HTMLDivElement>(null)
    const [popover, setPopover] = useState<undefined | string>()
    const [dismountingPopover, setDismountingPopover] = useState(false)
    const usersQuery = useAllUsersQuery()
    const projectsQuery = useProjects()
    const deleteTaskMutation = useDeleteTask()

    const { taskId } = useParams()
    const [params] = useSearchParams()
    const returnUrl = params.get('return_url') || ''
    const breadcrumbs = returnUrl
        .split('/')
        .filter((v) => v)
        .reduce((acc: any[], cur: string) => {
            const pathObject = {
                label: cur,
                path: [...acc.map((obj) => obj.path), `/${cur.replaceAll('-', ' ')}`].join(''),
            }
            return [...acc, pathObject]
        }, [])
    const taskQuery = useQuery({
        queryKey: ['development', 'tasks', taskId],
        queryFn: () =>
            vFetch(`/v1/development/tasks?id=${taskId}`, {
                cb: (res: any) => {
                    if (!res.success) {
                        return
                    }
                    return res.tasks[0]
                },
            }),
    })
    const auditQuery: any = useInfiniteQuery({
        queryKey: ['development', 'tasks', 'audit', taskId],
        queryFn: (context) =>
            getAuditHistory('development_tasks', taskId, undefined, context.pageParam, undefined, 200),
        initialPageParam: 1,
        getNextPageParam: (lastPage, pages) => lastPage.nextCursor,
        staleTime: 50000,
    })
    const { pages: auditHistoryEventPages }: { pages: any } = auditQuery?.data || {}

    const [editedFields, setEditedFields] = useState<any>({})
    const updateTaskMutation = useUpdateTask()
    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (
                !statusRef.current?.contains(e.target as HTMLElement) &&
                !priorityRef.current?.contains(e.target as HTMLElement) &&
                !estimateRef.current?.contains(e.target as HTMLElement) &&
                !assignedToRef.current?.contains(e.target as HTMLElement) &&
                !projectRef.current?.contains(e.target as HTMLElement)
            ) {
                setDismountingPopover(true)
                setTimeout(() => {
                    setPopover(undefined)
                    setDismountingPopover(false)
                }, 200)
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    const task = taskQuery.data
    if (!task) {
        return <p className='p-4'>Loading...</p>
    }
    const loading = taskQuery.isPending
    const users: UserInit[] = usersQuery?.data?.users || []
    const projects = projectsQuery?.data || []
    const foundUser = users.find((user) => user.id === task?.assigned_to)
    const foundProject = projects.find((project: any) => project.id === task?.project_id)
    const { icon: Priority, className: priorityClassName } = priorityMap[task.priority as keyof typeof priorityMap]
    const { icon: Status, className: statusClassName } = taskStatusDisplayMap[task.status] || {
        icon: FaRegCircle,
        class: '',
    }

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setEditedFields((previousState: any) => ({
            ...previousState,
            [target.name]: target.value,
        }))
    }

    const handleUpdateTask = (taskUpdate: any) => {
        if (JSON.stringify(task) === JSON.stringify({ ...task, ...taskUpdate })) {
            return
        }
        updateTaskMutation.mutate(taskUpdate, {
            onSuccess: () => {
                setPopover(undefined)
                setEditedFields({})
            },
        })
    }

    const handleTogglePopover = (target: string) => {
        if (target !== popover) {
            return setPopover(target)
        }
        setDismountingPopover(true)
        setTimeout(() => {
            setPopover(undefined)
            setDismountingPopover(false)
        }, 200)
    }

    return (
        <div className='pr-[332px]'>
            <div className='flex justify-between'>
                <div className='flex gap-1 items-center mb-4'>
                    <span className='block rounded w-fit leading-none font-bold text-xs capitalize'>&lt;-</span>
                    {breadcrumbs.map((bc, index) => (
                        <>
                            <Link
                                className='block rounded w-fit leading-none font-bold text-xs capitalize'
                                to={bc.path}
                            >
                                {bc.label}
                            </Link>
                            <FaCaretRight className='text-xs' />
                        </>
                    ))}
                    <span className='block rounded w-fit leading-none font-bold text-xs capitalize'>Task {taskId}</span>
                </div>
                {Object.keys(editedFields).length > 0 && (
                    <button
                        className='h-fit py-1 px-2 rounded bg-blue text-white dark:bg-accent dark:text-darkaccent uppercase font-bold text-sm leading-none'
                        onClick={() =>
                            handleUpdateTask({
                                id: task.id,
                                ...editedFields,
                            })
                        }
                    >
                        Save
                    </button>
                )}
            </div>
            {!task ? (
                <NotFound />
            ) : (
                <div className='flex justify-between'>
                    <div className='w-full'>
                        <Textarea
                            id='title'
                            className='h-fit p-0 bg-transparent focus:outline-none text-3xl border-0 font-semibold w-full mb-4'
                            name='title'
                            value={editedFields.title ?? task.title}
                            onChange={handleChange}
                        />
                        <Textarea
                            id='description'
                            placeholder='Add a description...'
                            className='bg-transparent focus:outline-none border-0 p-0 h-fit'
                            name='description'
                            value={editedFields.description ?? task.description}
                            onChange={handleChange}
                        />
                        <div className='text-sm font-bold mt-4 pt-4 border-t border-lightgrey dark:border-darkgrey w-full'>
                            <h3 className='text-base mb-4'>Activity</h3>
                            {auditHistoryEventPages?.map((page: any) => {
                                return page?.auditHistory?.map((audit: auditHistoryType, index: number) => {
                                    const auditUser = users.find((user) => user.id === audit.created_by)
                                    if (audit.edited_fields === 'created') {
                                        return (
                                            <div
                                                className={`py-2 flex items-center gap-2 relative after:w-[2px] after:h-full after:bg-slate after:absolute after:top-0 after:left-[5px] after:z-[-1] ${
                                                    index === 0 ? 'pt-0' : ''
                                                } ${index === auditQuery.data.length - 1 ? 'pb-0' : ''}`}
                                            >
                                                <FaCircle className='text-xs border-[3px] border-white dark:border-darkaccent rounded-full' />
                                                <p className='font-semibold'>
                                                    {auditUser?.first_name} {auditUser?.last_name} created this task on{' '}
                                                    {monthDateShortFormatter.format(new Date(audit.created_at))}
                                                </p>
                                            </div>
                                        )
                                    }
                                    const editedFields = audit.edited_fields.split(',')
                                    const fieldString =
                                        editedFields.length === 1
                                            ? `changed ${editedFields[0]} to ${audit.new_value[editedFields[0]]}`
                                            : editedFields.length === 2
                                              ? `edited ${editedFields.join(' and ')}`
                                              : `edited ${editedFields
                                                    .map((field: string, index: number) =>
                                                        index === editedFields.length - 1 ? `and ${field}` : field
                                                    )
                                                    .join(', ')}`
                                    return (
                                        <div
                                            className={`py-2 flex items-center gap-2 relative after:w-[2px] after:h-full after:bg-slate after:absolute after:top-0 after:left-[5px] after:z-[-1] ${
                                                index === 0 ? 'pt-0' : ''
                                            } ${index === auditQuery.data.length - 1 ? 'pb-0' : ''}`}
                                        >
                                            <FaCircle className='text-xs border-[3px] border-white dark:border-darkaccent rounded-full' />
                                            <p className='font-semibold'>
                                                {auditUser?.first_name} {auditUser?.last_name} {fieldString} on{' '}
                                                {monthDateShortFormatter.format(new Date(audit.created_at))}
                                            </p>
                                        </div>
                                    )
                                })
                            })}
                            <div className='flex justify-center col-span-3 mt-2'>
                                <Button
                                    className='flex gap-1'
                                    size={'default'}
                                    variant={'default'}
                                    onClick={() => auditQuery.fetchNextPage()}
                                    disabled={!auditQuery.hasNextPage}
                                >
                                    load more
                                    {auditQuery.isFetching && <FetchingSpinner isFetching={auditQuery.isFetching} />}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className='fixed right-0 h-[calc(100%-50px)] top-[50px] bg-white shadow-small dark:bg-darkaccent p-4 min-w-[300px]'>
                        <p className='text-xs font-semibold opacity-50 mb-4 w-full'>Task properties</p>
                        <div ref={statusRef} className='relative mb-2' title='Change the task status'>
                            <label className='text-xs font-bold leading-none'>Status</label>
                            <button
                                onClick={() => handleTogglePopover('status')}
                                className='flex gap-2 items-center rounded p-2 hover:bg-lightgrey dark:hover:bg-darkness transition-all'
                            >
                                <Status className={`${statusClassName}`} />
                                <p className='capitalize font-bold text-sm'>{task.status}</p>
                            </button>
                            {popover === 'status' && (
                                <FetchOptionSelector
                                    options={Object.keys(taskStatusDisplayMap)}
                                    selectedOption={task.status}
                                    onClick={(option: string) => handleUpdateTask({ id: task.id, status: option })}
                                    isPending={updateTaskMutation.isPending}
                                    className='left-[unset] right-[calc(100%+16px)] top-0 translate-x-0'
                                    searchText='Update status...'
                                    dismountingPopover={dismountingPopover}
                                />
                            )}
                        </div>
                        <div ref={priorityRef} className='relative cursor-pointer mb-2' title='Adjust the priority'>
                            <label className='text-xs font-bold leading-none'>Priority</label>
                            <button
                                onClick={() => handleTogglePopover('priority')}
                                className='flex gap-2 items-center rounded p-2 hover:bg-lightgrey dark:hover:bg-darkness transition-all'
                            >
                                <Priority
                                    onClick={() => handleTogglePopover('priority')}
                                    className={`${priorityClassName}`}
                                />
                                <p className='capitalize font-bold text-sm'>{task.priority}</p>
                            </button>
                            {popover === 'priority' && (
                                <FetchOptionSelector
                                    options={['low', 'medium', 'high', 'urgent']}
                                    selectedOption={task.priority}
                                    onClick={(option: string) => handleUpdateTask({ id: task.id, priority: option })}
                                    isPending={updateTaskMutation.isPending}
                                    searchText='Set priority...'
                                    className='left-[unset] right-[calc(100%+16px)] top-0 translate-x-0'
                                    dismountingPopover={dismountingPopover}
                                />
                            )}
                        </div>
                        <div
                            ref={estimateRef}
                            className='relative group shrink-0 text-[12px] font-bold font-robotomono cursor-pointer mb-2'
                            title='Adjust the time estimate'
                        >
                            <label className='text-xs font-bold leading-none'>Estimate</label>
                            <button
                                className='flex gap-2 items-center rounded p-2 hover:bg-lightgrey dark:hover:bg-darkness transition-all'
                                onClick={() => handleTogglePopover('estimate')}
                            >
                                <BiSolidPyramid className='text-base' />
                                <p className='capitalize font-bold text-sm'>{task.estimate} hours</p>
                            </button>
                            {popover === 'estimate' && (
                                <FetchOptionSelector
                                    options={['0', '1', '2', '3', '5', '8']}
                                    selectedOption={task.estimate.toString()}
                                    onClick={(option: string) => handleUpdateTask({ id: task.id, estimate: option })}
                                    isPending={updateTaskMutation.isPending}
                                    searchText='Change estimate...'
                                    className='left-[unset] right-[calc(100%+16px)] top-0 translate-x-0'
                                    dismountingPopover={dismountingPopover}
                                />
                            )}
                        </div>
                        <div
                            ref={assignedToRef}
                            className='relative w-4 shrink-0 cursor-pointer'
                            title='Assign to a developer'
                        >
                            <label className='text-xs font-bold leading-none whitespace-nowrap'>Assigned to</label>
                            <button
                                className='flex gap-2 items-center rounded p-2 hover:bg-lightgrey dark:hover:bg-darkness transition-all'
                                onClick={() => handleTogglePopover('assigned_to')}
                            >
                                {foundUser ? (
                                    <div className='w-4 h-4 text-[9px] flex justify-center items-center bg-blue text-white dark:bg-accent rounded-full dark:text-darkaccent font-bold'>
                                        {foundUser.first_name[0]}
                                        {foundUser.last_name[0]}
                                    </div>
                                ) : (
                                    <FaUserCircle className='w-4 h-4 opacity-50' />
                                )}
                                <p className='capitalize font-bold text-sm whitespace-nowrap'>
                                    {foundUser?.first_name} {foundUser?.last_name}
                                </p>
                            </button>
                            {popover === 'assigned_to' && (
                                <FetchOptionSelector
                                    options={[
                                        { label: 'Unassigned', value: null },
                                        ...users
                                            .filter((user) => user.roles.includes('developer'))
                                            .map((user) => ({
                                                label: `${user.first_name} ${user.last_name}`,
                                                value: user.id.toString(),
                                            })),
                                    ]}
                                    selectedOption={task.assigned_to?.toString() || null}
                                    onClick={(option: string) => handleUpdateTask({ id: task.id, assigned_to: option })}
                                    isPending={updateTaskMutation.isPending}
                                    searchText='Assign to...'
                                    className='left-[unset] right-[calc(100%+16px)] top-0 translate-x-0'
                                    dismountingPopover={dismountingPopover}
                                />
                            )}
                        </div>
                        <div
                            ref={projectRef}
                            className='relative shrink-0 cursor-pointer'
                            title='Add task to a project'
                        >
                            <label className='text-xs font-bold leading-none whitespace-nowrap'>Project</label>
                            <div
                                onClick={() => handleTogglePopover('project')}
                                className='w-fit mt-2 font-bold text-[12px] py-1 px-2 leading-tight rounded-[99vw] border border-darkgrey hover:bg-lightgrey/50 dark:hover:bg-darkaccent/50 transition-all'
                            >
                                {foundProject ? foundProject.title : 'No Project'}
                            </div>
                            {popover === 'project' && (
                                <FetchOptionSelector
                                    options={[
                                        { label: 'No Project', value: null },
                                        ...projects.map((project: any) => ({
                                            label: project.title,
                                            value: project.id.toString(),
                                        })),
                                    ]}
                                    selectedOption={task.project_id?.toString() || null}
                                    onClick={(option: string) => handleUpdateTask({ id: task.id, project_id: option })}
                                    isPending={updateTaskMutation.isPending}
                                    searchText='Add to project...'
                                    className='left-[unset] right-[calc(100%+16px)] top-0 translate-x-0'
                                    dismountingPopover={dismountingPopover}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
