import { useSearchParams } from 'react-router-dom'

export default function ResourceViewButton({
    resourceView,
    setResourceView,
    viewName,
}: {
    resourceView: string
    setResourceView: Function
    viewName: string
}) {
    const [searchParams, setSearchParams] = useSearchParams()
    return (
        <button
            onClick={() => {
                setSearchParams((prev: any) => {
                    prev.set('view', viewName)
                    prev.delete('group_id')
                    return prev
                })
                setResourceView(viewName)
            }}
            className={`${
                resourceView === viewName
                    ? 'border-x border-t border-white bg-blue text-white dark:text-offwhite dark:bg-darkaccent dark:border-x dark:border-t mb-[-1px] dark:border-darkgrey font-bold'
                    : 'border-x border-t border-white bg-lightgrey dark:bg-darkness dark:border-darkaccent'
            } py-[4px] px-[8px] rounded-t-[4px] self-start`}
        >
            <span className='capitalize'>{viewName}</span>
        </button>
    )
}
