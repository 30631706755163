import { useParams, useSearchParams } from 'react-router-dom'
import TaxExemptionList from './lists/TaxExemptionList'
import EditTaxExemptionModal from './modals/EditTaxExemptionModal'

export default function TaxExemptionsScreen() {
    const params = useParams()
    const taxExemptionId = params.taxExemptionId
    return (
        <div>
            <h1 className='text-2xl font-semibold mb-4'>Tax Exemptions</h1>
            <TaxExemptionList />
            {taxExemptionId && <EditTaxExemptionModal taxExemptionId={taxExemptionId} />}
        </div>
    )
}
