import { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { useCreateParentCompanyNote } from '../../../api/useQueries'
import { cn } from '../../../../helpers'
import { ProcurementContext, ProcurementContextType } from '../../../helpers'

export default function ParentCreateNote({
    company_ids,
    setShowQuickModal,
}: {
    company_ids: string[]
    setShowQuickModal: any
}) {
    const { selectedCompany } = useContext<ProcurementContextType>(ProcurementContext)
    const user = useSelector<any, any>((state) => state.user)
    const [note, setNote] = useState('')

    const createParentCompanyNote = useCreateParentCompanyNote()
    const { isPending } = createParentCompanyNote

    const handleSubmit = () => {
        createParentCompanyNote.mutate(
            { user, note, company_ids, parent_company: selectedCompany?.parent_company },
            {
                onSuccess: () => {
                    setShowQuickModal(undefined)
                },
            }
        )
    }
    return (
        <div className={cn(isPending && 'opacity-50')}>
            <div className={cn(isPending && 'opacity-50', 'p-[16px] w-full bg-lightgrey dark:bg-darkness')}>
                <h3 className='leading-[1] font-bold'>New Note</h3>
            </div>
            <textarea
                className='w-full p-[16px] dark:bg-darkaccent border-none focus:outline-none h-[150px]'
                placeholder='Type your note here...'
                value={note}
                onChange={({ target }) => setNote(target.value)}
            ></textarea>
            <div className='p-[16px] w-full bg-lightgrey dark:bg-darkness flex flex-col gap-[8px]'>
                <button
                    onClick={handleSubmit}
                    className='py-[4px] px-[8px] self-center rounded-[4px] text-[14px] bg-blue dark:bg-accent font-bold uppercase text-white dark:text-darkaccent'
                >
                    {isPending ? 'Saving..' : 'Save Note'}
                </button>
            </div>
        </div>
    )
}
