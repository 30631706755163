import { BsClockHistory } from 'react-icons/bs'
import { EDIMessageType } from '../EDIScreens.types'
import { IoMdCheckmarkCircle } from 'react-icons/io'
import { MdOutlineCancel } from 'react-icons/md'
import { Dispatch, SetStateAction } from 'react'

export default function Messages({
    messages,
    setShowModal,
    setSelectedEDI,
}: {
    messages: EDIMessageType[]
    setShowModal: Dispatch<SetStateAction<string | undefined>>
    setSelectedEDI: Dispatch<SetStateAction<string>>
}) {
    const transactionTypeMap: TransactionTypeMapType = {
        810: 'Invoice',
        846: 'Inventory',
        850: 'Purchase Order',
        855: 'Purchase Order Acknowledgement',
        856: 'Advanced Ship Notice',
    }
    return (
        <div>
            <div className='sticky top-[50px] grid grid-cols-[150px_100px_100px_100px_1fr_1fr_1fr_1fr_80px] col-span-full border-b border-lightgrey bg-blue text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness'>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Transaction Type</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>ISA #</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>GS #</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>ST #</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Order Name</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Sender</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Receiver</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Received At</div>
                <div className='font-bold dark:text-offwhite p-[8px] uppercase text-[12px]'>Accepted</div>
            </div>
            {messages.map((message: any) => {
                return (
                    <div
                        key={message.name}
                        onClick={() => {
                            setSelectedEDI(message.raw_edi)
                            setShowModal('rawEDI')
                        }}
                        className='grid grid-cols-[150px_100px_100px_100px_1fr_1fr_1fr_1fr_80px] border-b border-lightgrey cursor-pointer dark:border-darkgrey hover:bg-lightgrey dark:hover:bg-darkness'
                    >
                        <div className='p-[8px] font-bold dark:text-offwhite'>
                            {transactionTypeMap[message.transaction_type as keyof TransactionTypeMapType]}
                        </div>
                        <div className='p-[8px] font-bold dark:text-offwhite'>{message.isa_control_number}</div>
                        <div className='p-[8px] font-bold dark:text-offwhite'>{message.gs_control_number}</div>
                        <div className='p-[8px] font-bold dark:text-offwhite'>{message.st_control_number}</div>
                        {message.order_name ? (
                            <div className='p-[8px] font-bold dark:text-offwhite'>#{message.order_name}</div>
                        ) : (
                            <div></div>
                        )}
                        <div className='p-[8px] font-bold dark:text-offwhite'>{message.sender_id}</div>
                        <div className='p-[8px] font-bold dark:text-offwhite'>{message.receiver_id}</div>
                        <div className='p-[8px] font-bold dark:text-offwhite'>
                            {new Date(message.created_at).toDateString()}
                        </div>
                        <div className='p-[8px] font-bold dark:text-offwhite'>
                            {message.transaction_set_accepted === null ? (
                                <BsClockHistory className='mx-auto text-fire w-[24px] h-[24px]' />
                            ) : message.transaction_set_accepted == 1 ? (
                                <IoMdCheckmarkCircle className='mx-auto text-lime w-[24px] h-[24px]' />
                            ) : (
                                <MdOutlineCancel className='mx-auto text-lightred w-[24px] h-[24px]' />
                            )}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

type TransactionTypeMapType = {
    810: 'Invoice'
    846: 'Inventory'
    850: 'Purchase Order'
    855: 'Purchase Order Acknowledgement'
    856: 'Advanced Ship Notice'
}
