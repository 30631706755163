import { useContext, useEffect, useState } from 'react'
import { FaTrash } from 'react-icons/fa'
import { vFetch } from '../../../helpers'
import { CategoryType, ProcurementContext, ProcurementContextType } from '../../helpers'
import { useSelector } from 'react-redux'

export default function Category({ category }: { category: CategoryType }) {
    const { users, getCategories } = useContext<ProcurementContextType>(ProcurementContext)
    const user = useSelector((state: any) => state.user)
    const [editableCategory, setEditableCategory] = useState(structuredClone(category))
    const [edited, setEdited] = useState(false)

    const handleDelete = async (id: number | string) => {
        vFetch('/v1/procurement/categories', {
            method: 'DELETE',
            body: JSON.stringify({ id }),
        }).then(() => getCategories())
    }

    const handleUpdate = async () => {
        vFetch('/v1/procurement/categories', {
            method: 'PUT',
            body: JSON.stringify(editableCategory),
        }).then(() => getCategories())
    }

    useEffect(() => {
        if (category.name !== editableCategory.name) {
            setEdited(true)
        } else {
            setEdited(false)
        }
    }, [category, editableCategory])

    return (
        <div className='flex gap-[8px] items-center border-b border-lightgrey dark:border-darkgrey pr-[8px]'>
            <input
                className='w-full p-[8px] bg-transparent'
                name='name'
                value={editableCategory.name}
                onChange={({ target }) =>
                    setEditableCategory((previousState) => ({ ...previousState, name: target.value }))
                }
            />
            {(user.type === 'Admin' || user.type === 'SuperAdmin') && (
                <p className='p-[8px] leading-[1] text-[14px] shrink-0'>
                    Belongs to {users.find((u) => u.id === category.user_id)?.user_id}
                </p>
            )}
            {edited ? (
                <button
                    onClick={handleUpdate}
                    className='flex shrink-0 items-center justify-center font-bold bg-blue dark:bg-accent text-white dark:text-darkaccent py-[2px] px-[4px] rounded-[4px] self-center uppercase'
                >
                    Save Category
                </button>
            ) : (
                <button onClick={() => handleDelete(category.id!)} className='flex items-center justify-center'>
                    <FaTrash className='text-red dark:text-lightred' />
                </button>
            )}
        </div>
    )
}
