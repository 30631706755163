'use client'

import { CalendarIcon } from '@radix-ui/react-icons'
import { format } from 'date-fns'

import { Popover, PopoverContent, PopoverTrigger } from '@radix-ui/react-popover'
import { Button } from 'custom_components/component_Basics/Button'
import { cn } from 'helpers'
import { getDateToday } from 'procurement/constants'
import { CalendarBase } from 'custom_components/component_Basics/CalendarBase'
import { CalendarBasev2 } from 'custom_components/component_Basics/CalendarBasev2'

export function V2TaskCalendar<TData, TValue>({
    date,
    setDate,
    openFlag,
}: {
    date: Date | undefined
    setDate: any
    openFlag?: boolean
}) {
    return (
        <Popover open={openFlag || undefined}>
            <PopoverTrigger asChild>
                <Button
                    variant={'outline'}
                    size={'sm'}
                    className={cn(
                        date !== getDateToday() && '',
                        'w-full justify-center text-left font-bold max-h-[29.5px]',
                        !date && 'text-muted-foreground'
                    )}
                >
                    <CalendarIcon className='mr-2 h-4 w-4' />
                    {date ? format(date, 'PPP') : <span>Pick a date</span>}
                </Button>
            </PopoverTrigger>
            <PopoverContent className='w-auto p-0 z-50 ' align='start'>
                <CalendarBasev2
                    className='border border-primary rounded bg-bg1 dark:bg-darkbg1'
                    mode='single'
                    selected={date}
                    onSelect={(e: any) => {
                        if (e === undefined) {
                            setDate(getDateToday())
                        } else {
                            setDate(e)
                        }
                    }}
                    initialFocus
                />
            </PopoverContent>
        </Popover>
    )
}
