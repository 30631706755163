import { useQuery } from '@tanstack/react-query'
import ProjectList from './lists/ProjectList'
import { vFetch } from '../helpers'
import { useState } from 'react'
import CreateProject from './quickModals/CreateProject'
import { useProjects } from './api/useQueries'

export default function DevelopmentProjectsScreen() {
    const [quickModal, setQuickModal] = useState<undefined | string>()

    const projectsQuery = useProjects()
    const { data: projects, isPending } = projectsQuery
    return (
        <div>
            <div className='flex justify-between mb-4'>
                <h1 className='text-[24px] leading-tight font-semibold dark:text-offwhite capitalize'>
                    Development Projects
                </h1>
                <button
                    className='h-fit bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold py-1 px-2 leading-tight rounded'
                    onClick={() => setQuickModal('project')}
                >
                    + New Project
                </button>
            </div>
            {isPending ? <div>Loading...</div> : <ProjectList projects={projects} />}
            {quickModal === 'project' && <CreateProject setQuickModal={setQuickModal} />}
        </div>
    )
}
