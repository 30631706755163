import { ChangeEvent, Dispatch, MouseEventHandler, SetStateAction, Suspense, useEffect, useState } from 'react'
import { ResourceType } from '../../processingTimes/ProcessingTimesScreen.types'
import { BsFillTrash3Fill } from 'react-icons/bs'

type ResourceProps = {
    resource: ResourceType | null
    deletable: boolean
    setPushList?: Dispatch<SetStateAction<ResourceType[]>>
    handleRemove?: Function
    handleAdd?: Function
    amount?: number
    amount_unit?: 'PERCENT' | 'FIXED'
}

export default function Resource({
    resource,
    setPushList,
    deletable,
    handleRemove,
    handleAdd,
    amount,
    amount_unit,
}: ResourceProps) {
    const [type, setType] = useState<ShopifyResourceType>('Product')
    const [override, setOverride] = useState(false)
    const amountOff: number =
        resource?.amount_override && resource?.amount_unit_override ? resource?.amount_override : amount || 0
    const amountOffUnit =
        resource?.amount_override && resource?.amount_unit_override ? resource?.amount_unit_override : amount_unit
    const costOff: number = resource?.cost && resource?.cost_unit ? resource?.cost : 0
    const costOffUnit = resource?.cost && resource?.cost_unit ? resource?.cost_unit : undefined
    const originalCost: number = resource?.original_cost || resource?.unit_cost || 0
    const compareAtPrice: number = resource?.variants
        ? resource?.variants[0].compare_at_price || resource?.variants[0].price
        : resource?.compare_at_price || resource?.price
    const newPrice: number = calculateNewPriceOrCost(amountOff, amountOffUnit, compareAtPrice)
    const newCost: number = calculateNewPriceOrCost(costOff, costOffUnit, originalCost)
    function calculateNewPriceOrCost(amount: number, amount_unit?: 'FIXED' | 'PERCENT', originalValue?: number) {
        if (!originalValue) {
            return 0
        }
        if (!amount_unit) {
            return originalValue
        }
        if (amount_unit === 'FIXED') {
            return originalValue - amount
        } else if (amount_unit === 'PERCENT') {
            return originalValue * ((100 - amount) / 100)
        }
        return originalValue
    }

    useEffect(() => {
        if (resource) {
            let tempType = resource.gid.split('/')[3]
            if (tempType === 'Collection' && resource.is_vendor) {
                tempType = 'Brand'
            }
            setType(tempType as ShopifyResourceType)
        }
    }, [resource])

    const handleChange = (
        { target }: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
        resource: ResourceType | null
    ) => {
        if (setPushList) {
            setPushList((previousState) => {
                const newState = structuredClone(previousState)
                const foundResourceIndex = newState.findIndex((r) => r.gid === resource?.gid)
                newState[foundResourceIndex] = {
                    ...newState[foundResourceIndex],
                    [target.name]: target.value,
                }
                return newState
            })
        }
    }
    return (
        <>
            {handleAdd ? (
                <button
                    onClick={handleAdd ? () => handleAdd(resource) : () => {}}
                    className={`
                            text-black text-left font-bold p-[4px] px-[8px] rounded-[4px] dark:opacity-[0.8]
                            ${
                                type === 'Product'
                                    ? 'bg-[lightblue]'
                                    : type === 'Brand'
                                      ? 'bg-fire'
                                      : type === 'Collection'
                                        ? 'bg-[tan]'
                                        : type === 'ProductVariant'
                                          ? 'bg-[darkseagreen]'
                                          : ''
                            }
                    `}
                >
                    {/* <h3 className="text-[12px] uppercase leading-[1] mt-[-2px] ml-[-4px]">{type}</h3> */}
                    <div className='flex gap-[8px] justify-between'>
                        <p className='text-[16px] whitespace-nowrap overflow-hidden text-ellipsis'>
                            {resource?.sku ? `${resource.sku} - ` : ''}
                            {resource?.product_title || resource?.title}
                        </p>
                    </div>
                    <div className='flex gap-[8px] text-[14px] text-darkgrey'>
                        <p>
                            Price: <span> ${(compareAtPrice || 0).toFixed(2)}</span>
                        </p>
                        <p>|</p>
                        <p>
                            Cost: <span> ${(originalCost || 0).toFixed(2)}</span>
                        </p>
                    </div>
                    {(resource?.variants?.length || 0) > 1 && (
                        <p className='text-[14px] text-darkgrey'>
                            SKUS:{' '}
                            {resource?.variants?.map((variant: any, index: number) => (
                                <>
                                    {variant.sku}
                                    {index === (resource?.variants?.length || 0) - 1 ? '' : ', '}
                                </>
                            ))}
                        </p>
                    )}
                </button>
            ) : (
                <div
                    className={`
                        text-black text-left font-bold p-[4px] px-[8px] rounded-[4px] dark:opacity-[0.8]
                            ${
                                type === 'Product'
                                    ? 'bg-[lightblue]'
                                    : type === 'Brand'
                                      ? 'bg-fire'
                                      : type === 'Collection'
                                        ? 'bg-[tan]'
                                        : type === 'ProductVariant'
                                          ? 'bg-[darkseagreen]'
                                          : ''
                            }
                    `}
                >
                    <h3 className='text-[12px] uppercase leading-[1] mt-[-2px] ml-[-4px]'>{type}</h3>
                    <div className='flex gap-[8px] justify-between'>
                        <p className='text-[16px] whitespace-nowrap overflow-hidden text-ellipsis'>
                            {resource?.sku ? `${resource.sku} - ` : ''}
                            {resource?.product_title || resource?.title}
                        </p>
                        {deletable && handleRemove && (
                            <BsFillTrash3Fill
                                onClick={() => handleRemove(resource)}
                                className='w-[20px] h-[20px] shrink-0 text-darkgrey cursor-pointer'
                            />
                        )}
                    </div>
                    <div className='flex flex-wrap gap-[8px] text-[14px] text-darkgrey'>
                        <p className='flex gap-[4px] items-center'>
                            Price:
                            {compareAtPrice !== newPrice && (
                                <span className='line-through text-[12px] text-lightred leading-[1] relative top-[-2px]'>
                                    ${(compareAtPrice || 0).toFixed(2)}
                                </span>
                            )}
                            <span className='text-black'>${(newPrice || 0).toFixed(2)}</span>
                        </p>
                        <p>|</p>
                        <p className='flex gap-[4px] items-center'>
                            Cost:
                            {originalCost !== newCost && (
                                <span className='line-through text-[12px] text-lightred leading-[1] relative top-[-2px]'>
                                    ${(originalCost || 0).toFixed(2)}
                                </span>
                            )}
                            <span className='text-black'>${(newCost || 0).toFixed(2)}</span>
                        </p>
                    </div>
                    {(resource?.variants?.length || 0) > 1 && (
                        <p className='text-[14px] text-darkgrey'>
                            SKUS:{' '}
                            {resource?.variants?.map((variant: any, index: number) => (
                                <>
                                    {variant.sku}
                                    {index === (resource?.variants?.length || 0) - 1 ? '' : ', '}
                                </>
                            ))}
                        </p>
                    )}
                    <button
                        className='text-[14px] flex gap-[4px] items-center'
                        onClick={() => setOverride((prevState) => !prevState)}
                    >
                        Advanced{' '}
                        <div
                            className={`border-b-2 border-r-2 border-black w-[6px] h-[6px] transition-all ${
                                override ? 'rotate-[-135deg] ' : 'rotate-45'
                            }`}
                        ></div>
                    </button>
                    {override && (
                        <div className='flex flex-col gap-[4px]'>
                            <div className='flex gap-[8px] w-full'>
                                <div className='flex flex-col gap-[4px] w-3/4'>
                                    <label className='uppercase text-[12px] font-bold leading-[1]'>
                                        Override Amount Reduction
                                    </label>
                                    <input
                                        className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-darkgrey dark:bg-white p-[4px] rounded-[4px]'
                                        name='amount_override'
                                        type='number'
                                        value={resource?.amount_override}
                                        onChange={(e) => handleChange(e, resource)}
                                    />
                                </div>
                                <div className='flex flex-col gap-[4px] w-1/4'>
                                    <label className='uppercase text-[12px] font-bold leading-[1]'>Unit</label>
                                    <select
                                        className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-darkgrey dark:bg-white p-[4px] rounded-[4px]'
                                        name='amount_unit_override'
                                        value={resource?.amount_unit_override}
                                        onChange={(e) => handleChange(e, resource)}
                                    >
                                        <option
                                            value=''
                                            className={`${!resource?.amount_unit_override ? '' : 'hidden'}`}
                                        >
                                            Select An Option
                                        </option>
                                        <option value='FIXED'>Fixed</option>
                                        <option value='PERCENT'>Percent</option>
                                    </select>
                                </div>
                            </div>
                            {originalCost !== 0 && (
                                <div className='flex gap-[8px] w-full mb-[4px]'>
                                    <div className='flex flex-col gap-[4px] w-3/4'>
                                        <label className='uppercase text-[12px] font-bold leading-[1]'>
                                            Cost Reduction
                                        </label>
                                        <input
                                            className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-darkgrey dark:bg-white p-[4px] rounded-[4px]'
                                            name='cost'
                                            value={resource?.cost}
                                            onChange={(e) => handleChange(e, resource)}
                                            type='number'
                                        />
                                    </div>
                                    <div className='flex flex-col gap-[4px] w-1/4'>
                                        <label className='uppercase text-[12px] font-bold leading-[1]'>Unit</label>
                                        <select
                                            className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-darkgrey dark:bg-white p-[4px] rounded-[4px]'
                                            name='cost_unit'
                                            value={resource?.cost_unit}
                                            onChange={(e) => handleChange(e, resource)}
                                        >
                                            <option value='' className={`${!resource?.cost_unit ? '' : 'hidden'}`}>
                                                Select An Option
                                            </option>
                                            <option value='FIXED'>Fixed</option>
                                            <option value='PERCENT'>Percent</option>
                                        </select>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

type ShopifyResourceType = 'Product' | 'Collection' | 'Brand' | 'ProductVariant'
