import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectModalDiv } from '../redux/appFunctions'
import './ModalDiv.css'
import { sendToast, vFetch } from '../helpers'

export function renderModalDiv(event = {}) {
    const overlay = document.getElementById('layout__modal-div')

    overlay.style.zIndex = 10000
    setTimeout(() => {
        overlay.style.opacity = 1
    }, 100)
}

export function closeModalDiv(event, force) {
    const overlay = document.getElementById('layout__modal-div')

    if (force) {
        overlay.style.zIndex = -10
        overlay.style.opacity = 0
        return
    } else if (event.target === overlay || event.target.name === 'NO' || event.target.name === 'CLOSE') {
        overlay.style.zIndex = -10
        overlay.style.opacity = 0
    }
}

export default function ModalDiv() {
    const modalOptions = useSelector(selectModalDiv)
    const user = useSelector((state) => state.user)
    const navigate = useNavigate()
    const fileUpload = useRef()
    const [files, setFiles] = useState([])
    const [base64Files, setBase64Files] = useState([])
    const fileUploadRef = useRef()
    const { func, args, name, purpose, heading, subheading } = modalOptions
    const userRequestOptions = []

    const requestTopicMap = [
        'Product Setup',
        'Shipping',
        'Tasks',
        'Sales',
        'Calls',
        'Users',
        'Admin',
        'Scrape',
        'Knowledge',
        'General / Other',
    ]

    if (!user.access.includes('*')) {
        requestTopicMap.forEach((topic) => {
            if (user.access.includes(topic.toLowerCase())) {
                userRequestOptions.push(topic)
            }
        })
        userRequestOptions.push('General / Other')
    }

    const requestTypeMap = {
        Feature: {},
        'Bug Fix': {},
    }
    const defaultHelpInput = {
        request_topic: requestTopicMap[0],
        request_type: Object.keys(requestTypeMap)[0],
        description: '',
    }
    const [helpInput, setHelpInput] = useState(defaultHelpInput)

    const functionMap = {
        deleteTask: async () => {
            await vFetch(`/v1/tasks`, {
                method: 'DELETE',
                body: JSON.stringify({
                    id: args['task'].id,
                    order_id: args['task'].order_id,
                    draft_order_id: args['task'].draft_order_id,
                    note: args['task'].order_notes,
                    task_type: args['task'].task_type,
                }),
                cb: (res) => {
                    if (res.success) navigate('/tasks/manager')
                    else window.alert('Something went wrong.')
                },
            })
        },
        markTaskCompleted: async () => {
            await vFetch(`/v1/tasks/${args['task'].id}`, {
                method: 'POST',
                cb: (res) => {
                    if (res.success) navigate('/tasks/manager')
                    else window.alert('Something went wrong. ')
                },
            })
        },
    }

    function handleAreYouSure() {
        closeModalDiv(undefined, true)
        functionMap[func]()
    }

    useEffect(() => {
        const textAreas = document.getElementsByClassName(
            'min-h-[175px] max-w-[1440px] overflow-hidden dark:bg-darkness dark:text-offwhite dark:outline-grey dark:focus:outline'
        )

        for (let element of textAreas) {
            const minHeight = parseInt(
                window.getComputedStyle(element, null).getPropertyValue('min-height').replace('px', '')
            )

            if (element.scrollHeight > minHeight && element.scrollHeight < window.innerHeight * 0.8) {
                element.style.height = '0'
                element.style.height = `${element.scrollHeight}px`
            }
        }
    }, [helpInput])

    useEffect(() => {
        const getData = async () => {
            async function readFileAsDataURL(file) {
                let result_base64 = await new Promise((resolve) => {
                    let fileReader = new FileReader()
                    fileReader.onload = (e) => resolve(fileReader.result)
                    fileReader.readAsDataURL(file)
                })
                return result_base64
            }
            let tempFiles = []
            for (let file of Array.from(files)) {
                tempFiles.push(await readFileAsDataURL(file))
            }
            setBase64Files([...base64Files, ...tempFiles])
        }
        getData()
    }, [files])

    return (
        <>
            <div
                id='layout__modal-div'
                className='flex w-[100%] h-[100%] fixed left-0 top-0 justify-center justify-items-center items-center content-center bg-[rgba(0,0,0,0.3)]'
            >
                {purpose === 'REDIRECT' && (
                    <div
                        id='layout__modal-div__inner-div'
                        className='flex flex-wrap relative max-w-[1440px] w-[80%] justify-center bg-white dark:bg-darkaccent gap-y-[16px] gap-x-[64px] p-[32px]'
                    >
                        <button
                            className='absolute right-0 top-0 h-[24px] w-[24px] text-white bg-blue border-0 dark:text-black dark:bg-fire dark:shadow-hot outline-none cursor-pointer'
                            name='CLOSE'
                            onClick={closeModalDiv}
                        >
                            X
                        </button>
                        <h2 className='w-[100%] text-center dark:text-offwhite'>Are you sure you want to {name}?</h2>
                        <button
                            className='text-white bg-blue dark:text-black dark:bg-accent rounded text-[12px] font-semibold py-[5px] px-[16px] my-[16px] min-w-[75px] cursor-pointer'
                            onClick={handleAreYouSure}
                        >
                            {name}
                        </button>
                        <button
                            className='text-white bg-blue dark:text-black dark:bg-accent rounded text-[12px] font-semibold py-[5px] px-[16px] my-[16px] min-w-[75px] cursor-pointer'
                            name='NO'
                            onClick={closeModalDiv}
                        >
                            NO
                        </button>
                    </div>
                )}
                {purpose === 'DANGER' && (
                    <div
                        id='layout__modal-div__inner-div'
                        className='flex flex-wrap relative max-w-[1440px] w-[80%] justify-center bg-white dark:bg-darkaccent gap-y-[16px] gap-x-[64px] p-[32px]'
                    >
                        <button
                            className='absolute right-0 top-0 h-[24px] w-[24px] text-white bg-blue border-0 dark:text-black dark:bg-fire dark:shadow-hot outline-none cursor-pointer'
                            name='CLOSE'
                            onClick={closeModalDiv}
                        >
                            X
                        </button>
                        <h2 className='w-[100%] text-center dark:text-offwhite'>
                            Are you sure you want to <strong>{name}</strong>?
                        </h2>
                        <button
                            className='semibold py-[5px] px-[16px] min-w-[75px] text-black text-[12px] font-extrabold p-[5px] bg-[rgb(255,200,200)] border-[2px] border-[rgb(255,0,0)] rounded my-[16px] cursor-pointer'
                            onClick={handleAreYouSure}
                        >
                            {name}
                        </button>
                        <button
                            className='text-white bg-blue dark:text-black dark:bg-accent rounded text-[12px] font-extrabold py-[5px] px-[16px] my-[16px] min-w-[75px] cursor-pointer'
                            name='NO'
                            onClick={closeModalDiv}
                        >
                            NO
                        </button>
                    </div>
                )}
            </div>
        </>
    )
}
