import { FaTrash } from 'react-icons/fa'
import Input from './Input'
import React, { useState } from 'react'
import { useCreateScoreSetupOption, useDeleteScoreSetupOption, useUpdateScoreSetupOption } from '../api/useQueries'
import { toast } from 'react-toastify'
import { sendToast } from '../../helpers'

export default function ScoreOption({ option, last, isNew, setShowNew }: any) {
    const [editableFields, setEditableFields] = useState<any>({})
    const [showDelete, setShowDelete] = useState(false)
    const toastId = React.useRef<any>(null)

    const isEdited = Boolean(Object.values(editableFields).length)

    const updateScoreOption = useUpdateScoreSetupOption()
    const deleteScoreOption = useDeleteScoreSetupOption()
    const createScoreOption = useCreateScoreSetupOption()

    function handleDelete(): void {
        deleteScoreOption.mutate(
            { option },
            {
                onSuccess: () => {
                    setEditableFields({})
                    setShowDelete(false)
                },
            }
        )
    }

    function handleUpdate(): void {
        const editedOption = { ...option, ...editableFields }
        if (!editedOption.name || !editedOption.value) {
            toast.dismiss(toastId.current)
            return (toastId.current = sendToast({ message: 'Please Complete All Fields' }))
        }
        updateScoreOption.mutate(
            { option: editedOption },
            {
                onSuccess: () => {
                    setEditableFields({})
                },
            }
        )
    }
    function handleCreate() {
        const editedOption = { ...option, ...editableFields }
        if (!editedOption.name || !editedOption.value) {
            toast.dismiss(toastId.current)
            return (toastId.current = sendToast({ message: 'Please Complete All Fields' }))
        }
        createScoreOption.mutate(
            {
                ...option,
                ...editableFields,
            },
            {
                onSuccess: () => {
                    setShowNew(false)
                    setEditableFields({})
                },
            }
        )
    }

    return (
        <div
            className={`grid grid-cols-[2fr_2fr_1fr] items-center gap-2 ${
                last ? '' : 'border-b border-lightgrey dark:border-darkgrey pb-2 mb-2'
            }`}
        >
            <Input
                id={option.id}
                name='name'
                value={editableFields.name ?? option.name}
                type='text'
                placeholder='Name'
                onChange={({ target }) => {
                    setShowDelete(false)
                    return setEditableFields((previousState: any) => ({
                        ...previousState,
                        [target.name]: target.value,
                    }))
                }}
            />
            <Input
                id={option.id}
                name='value'
                value={editableFields.value ?? option.value}
                placeholder='Score Value'
                type='number'
                onChange={({ target }) => {
                    setShowDelete(false)
                    setEditableFields((previousState: any) => ({
                        ...previousState,
                        [target.name]: target.value,
                    }))
                }}
            />
            {isNew && (
                <div className='flex gap-[8px] justify-end'>
                    <button
                        onClick={handleCreate}
                        className={`${
                            createScoreOption.isPending ? 'opacity-[0.5] pointer-events-none' : 'pointer-events-auto'
                        }  shrink-0 text-[14px] leading-[1] py-1 px-2 rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase`}
                    >
                        {createScoreOption.isPending ? 'CREATING...' : 'CREATE'}
                    </button>
                    <button
                        onClick={() => {
                            setShowNew(false)
                            setEditableFields({})
                        }}
                        className={`${
                            updateScoreOption.isPending ? 'opacity-[0.5] pointer-events-none' : 'pointer-events-auto'
                        } shrink-0 text-[14px] leading-[1] py-1 px-2 rounded-[4px] bg-lightgrey dark:bg-darkgrey text-black dark:text-offwhite font-bold uppercase`}
                    >
                        Cancel
                    </button>
                </div>
            )}
            {isEdited && !isNew && (
                <div className='flex gap-[8px] justify-end'>
                    <button
                        onClick={handleUpdate}
                        className={`${
                            updateScoreOption.isPending ? 'opacity-[0.5] pointer-events-none' : 'pointer-events-auto'
                        }  shrink-0 text-[14px] leading-[1] py-1 px-2 rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase`}
                    >
                        {updateScoreOption.isPending ? 'SAVING...' : 'SAVE'}
                    </button>
                    <button
                        onClick={() => setEditableFields({})}
                        className={`${
                            updateScoreOption.isPending ? 'opacity-[0.5] pointer-events-none' : 'pointer-events-auto'
                        } shrink-0 text-[14px] leading-[1] py-1 px-2 rounded-[4px] bg-lightgrey dark:bg-darkgrey text-black dark:text-offwhite font-bold uppercase`}
                    >
                        Cancel
                    </button>
                </div>
            )}
            {showDelete && (
                <div className='flex gap-[8px] self-center justify-self-end'>
                    <button
                        onClick={handleDelete}
                        className={`${
                            deleteScoreOption.isPending ? 'opacity-[0.5] pointer-events-none' : 'pointer-events-auto'
                        } shrink-0 text-[14px] leading-[1] py-[4px] w-fit px-[8px] rounded-[4px] bg-red dark:bg-lightred text-white font-bold uppercase`}
                    >
                        Delete
                    </button>
                    <button
                        onClick={() => setShowDelete(false)}
                        className={`${
                            deleteScoreOption.isPending ? 'opacity-[0.5] pointer-events-none' : 'pointer-events-auto'
                        } text-[14px] leading-[1] py-[4px] w-fit px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkgrey font-bold uppercase`}
                    >
                        Cancel
                    </button>
                </div>
            )}
            {!isEdited && !showDelete && !isNew && (
                <button onClick={() => setShowDelete(true)} className='mb-[9px] justify-self-end'>
                    <FaTrash className='text-red dark:text-lightred w-4 h-4' />
                </button>
            )}
        </div>
    )
}
