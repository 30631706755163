import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ReturnEditInit } from './returns.types'
import { closePopout, formatMoney, parseResObject, sendToast, vFetch } from '../helpers'
import EditReturnModule from './components/EditReturnModule'
import { OrderInit } from '../orders/orders.types'
import { TaskEditInit } from '../tasks/tasks.types'
import { CustomerInit } from '../customers/customers.types'
import { returnVariables } from './returnsVariables'
import SelectReturnItem from './components/SelectItem'
import { itemPriceAfterDiscounts, renderDiscount } from '../orders/orders.helpers'
import { getTaxTotal } from './returns.helpers'
import ReturnInfoSection from './components/ReturnInfoSection'

export default function EditReturnScreen() {
    const [params, setParams] = useSearchParams()
    const [selectedReturn, setSelectedReturn] = useState<ReturnEditInit | null>(null)
    const [returnOrder, setReturnOrder] = useState<OrderInit | null>(null)
    const [returnTask, setReturnTask] = useState<TaskEditInit | null>(null)
    const [returnCustomer, setReturnCustomer] = useState<CustomerInit | null>(null)
    const { statuses, reasons, refundTypes } = returnVariables

    function refresh() {
        vFetch(`/v1/returns?returnIds=${params.get('returns')}`, {
            cb: (res: any) => {
                if (res.success) {
                    const resReturn: any = parseResObject(res.returns[0])
                    setSelectedReturn(resReturn)
                    if (!returnOrder) {
                        vFetch(`/v1/orders/all?filters=order_number=${resReturn.po_number}`, {
                            cb: (res: any) => {
                                if (res.success && res.orders?.length > 0) {
                                    setReturnOrder(parseResObject(res.orders[0]))
                                } else sendToast({ message: 'Order cannot be found. Please contact the developers.' })
                            },
                        })
                    }
                    if (!returnCustomer && typeof resReturn.customer === 'number') {
                        vFetch(`/v1/customers/multipleById?ids=${resReturn.customer}`, {
                            cb: (res: any) => {
                                if (res.success && res.customers.length > 0) {
                                    setReturnCustomer(parseResObject(res.customers[0]))
                                } else sendToast({ message: 'Customer not found.' })
                            },
                        })
                    }
                }
            },
        })
    }

    useEffect(() => {
        if (!selectedReturn) {
            refresh()
        }
    }, [])

    // everything between here and the return() is for the fallback/v1 editor
    const navigate = useNavigate()
    const ids = params.get('returns')
    const [returns, setReturns] = useState<ReturnEditInit[]>([])
    const [returnsMap, setReturnsMap] = useState<any>({})
    const [oldReturns, setOldReturns] = useState<ReturnEditInit[]>([])
    const [foundTasks, setFoundTasks] = useState<{}>({})
    const [orders, setOrders] = useState<[]>([])
    const [customers, setCustomers] = useState<[]>([])
    const [edited, setEdited] = useState(false)

    useEffect(() => {
        if (ids) {
            vFetch(
                `/v1/returns/all?filters=${ids
                    ?.split(',')
                    .map((id) => `id = ${id}`)
                    .join(' OR ')}`,
                {
                    cb: (res: any) => {
                        const resReturns = res.returns.map((r: ReturnEditInit, i: number) => {
                            const resReturn: any = parseResObject(r)
                            if (typeof resReturn.quantity !== 'number') resReturn.quantity = 1
                            return resReturn
                        })
                        const resReturnsMap: any = {}
                        resReturns.forEach((r: ReturnEditInit) => (resReturnsMap[r.id!] = { return: r }))
                        setReturns(resReturns)
                        setOldReturns(resReturns)
                        setReturnsMap(resReturnsMap)
                    },
                }
            )
        }
    }, [])

    useEffect(() => {
        if (oldReturns.length > 0) {
            vFetch(`/v1/tasks?order_name=${oldReturns[0].po_number}`, {
                cb: (res: any) => {
                    if (res.success) {
                        res.tasks.forEach((task: any) => {
                            const resTask: any = parseResObject(task)
                            if (resTask.task_type === 'Customer Service' || resTask.topic === 'return')
                                setReturnTask(resTask)
                        })
                    }
                },
            })

            vFetch(`/v1/orders/all?filters=${oldReturns.map((r) => `order_number=${r.po_number}`).join(' OR ')}`, {
                cb: (res: any) => {
                    if (res.success) {
                        const newReturns: ReturnEditInit[] = [...oldReturns]
                        const newReturnsMap: any = { ...returnsMap }
                        const itemGIDs: any = {}
                        const resOrders = res.orders.map((o: any) => {
                            const resOrder: any = parseResObject(o)
                            const returnId = oldReturns.find(
                                (r: ReturnEditInit) => r.po_number === resOrder.order_number
                            )!.id
                            newReturnsMap[returnId].order = resOrder
                            const item = newReturnsMap[returnId].order.line_items.find(
                                (i: any) =>
                                    i.sku ===
                                    newReturns.find((r: ReturnEditInit) => r.id === returnId)!.model.unparsedModel
                            )

                            if (item) {
                                newReturnsMap[returnId].return.model = item
                                itemGIDs[item.product_id] = `gid://shopify/Product/${item.product_id}`
                            }
                            return resOrder
                        })
                        resOrders.forEach((o: any) =>
                            o.line_items.forEach((i: any) => (itemGIDs[i.id] = `gid://shopify/Product/${i.product_id}`))
                        )
                        // const itemGIDs:string[] = [] = [].concat.apply([], resOrders.map((o:any) =>
                        //     o.line_items.map((i:any) => `gid://shopify/Product/${i.product_id}`)
                        // ))

                        vFetch(`/v1/products/limited/handles?gid=${Object.values(itemGIDs)}`, {
                            cb: (pRes: any) => {
                                if (pRes.success) {
                                    Object.keys(newReturnsMap).forEach((r: any) => {
                                        const item = newReturnsMap[r].return.model
                                        if (item.sku)
                                            item.handle = pRes.products.find(
                                                (p: any) => p.gid === `gid://shopify/Product/${item.product_id}`
                                            ).handle
                                    })
                                }
                            },
                        })

                        setReturnsMap(newReturnsMap)
                        setOrders(resOrders)
                        handleChangeReturn('customer', resOrders[0].customer.id, returns[0], 0)
                        setReturnCustomer(resOrders[0].customer.id)
                    } else sendToast({ message: 'Order cannot be found. Please contact the developers.' })
                },
            })
        }
    }, [oldReturns])

    useEffect(() => {
        if (orders.length > 0) {
            const rWithoutTasks = oldReturns.filter((r) => !r.task_id)
            vFetch(`/v1/tasks/search?order_name=${rWithoutTasks.map((r) => r.po_number)}`, {
                cb: (res: any) => {
                    const resFoundTasks: any = {}
                    returns.forEach((r: ReturnEditInit) => {
                        resFoundTasks[r.id] = []
                        res.tasks.forEach((t: any) => {
                            if (JSON.stringify(r.po_number) === t.order_name.replaceAll('D', '').replaceAll('#', '')) {
                                resFoundTasks[r.id].push(parseResObject(t))
                            }
                        })
                    })
                    setFoundTasks(resFoundTasks)
                },
            })

            vFetch(`/v1/customers/multipleById?ids=${orders.map((o: any) => o.customer.id)}`, {
                cb: (res: any) =>
                    setCustomers(
                        res.customers.map((c: any) => {
                            const newReturnsMap: any = { ...returnsMap }
                            const resCustomer = parseResObject(c)
                            newReturnsMap[
                                oldReturns.find(
                                    (r: ReturnEditInit) =>
                                        r.po_number ===
                                        (orders.find((o: any) => o.customer.id === resCustomer.id) as any)!.order_number
                                )!.id
                            ].customer = resCustomer
                            return resCustomer
                        })
                    ),
            })
        }
    }, [orders])
    useEffect(() => {
        if (JSON.stringify(returns) !== JSON.stringify(oldReturns)) setEdited(true)
        else setEdited(false)
    }, [returns])

    function handleNavigate(e: MouseEvent, poNumber: number | undefined, returnId?: number) {
        const target = e.target as HTMLElement
        if (
            closePopout(
                e,
                ['js-returns-checkbox', 'js-items-dropdown', 'js-tags-dropdown', 'js-dont-navigate'],
                () => {}
            )
        ) {
            if (poNumber !== undefined && !returnId) {
                vFetch(`/v1/orders/all?filters=order_number=${poNumber}`, {
                    cb: (res: any) => {
                        if (res.success && res.orders?.length > 0) {
                            if (e.ctrlKey) window.open(`/orders/${res.orders[0].id}`)
                            else if (e.type === 'contextmenu') window.open(`/orders/${res.orders[0].id}`)
                            else navigate(`/orders/${res.orders[0].id}`)
                        } else sendToast({ message: 'Order page cannot be found. Please contact the developers.' })
                    },
                })
            }
            if (returnId !== undefined && closePopout(e, ['js-return-po-number', 'js-return-checkbox'], () => {})) {
                if (e.ctrlKey) window.open(`/returns/edit?returns=${returnId}`)
                else if (e.type === 'contextmenu') window.open(`/returns/edit?returns=${returnId}`)
                else navigate(`/returns/edit?returns=${returnId}`)
            }
        }
    }

    function handleChangeShippingAddress(target: HTMLInputElement, r: ReturnEditInit, i: number) {
        const newReturn = {
            ...r,
            customer: {
                ...returnsMap[r.id].customer,
                default_address: {
                    ...returnsMap[r.id].customer.default_address,
                    ...r.customerParsed?.default_address,
                    [target.name]: target.value,
                },
            },
        }
        delete newReturn.customer.customer_id
        const newReturns = [...returns]
        newReturns.splice(i, 1, newReturn)
        setReturns(newReturns)
        setReturnsMap({
            ...returnsMap,
            [r.id]: {
                ...returnsMap[r.id],
                return: newReturn,
            },
        })
    }
    function handleChangeRefundType(target: HTMLInputElement, pricePlusTax: number, r: ReturnEditInit, i: number) {
        const newReturns = [...returns]
        const newReturn = { ...r, refund_type: target.value }
        newReturn.refund_to_customer = target.value === 'full' ? Number(pricePlusTax.toFixed(2)) : 0
        newReturns.splice(i, 1, newReturn)
        setReturns(newReturns)
    }
    function handleChangeRefundQuantity(target: HTMLInputElement, pricePlusTax: number, r: ReturnEditInit, i: number) {
        const newReturns = [...returns]
        const newReturn = { ...r, quantity: Number(target.value) }
        newReturn.refund_to_customer =
            newReturn.refund_type === 'full' ? Number((newReturn.quantity * pricePlusTax).toFixed(2)) : 0
        newReturns.splice(i, 1, newReturn)
        setReturns(newReturns)
    }
    function handleChangeRefundAmount(value: string, r: ReturnEditInit, i: number) {
        const newReturns = [...returns]
        const newReturn = { ...r, refund: Number(value) }
        if (newReturn.refund_type === 'full') newReturn.refund_type = 'partial'
        newReturns.splice(i, 1, newReturn)
        setReturns(newReturns)
    }
    function handleChangeReturn(name: string, value: any, r: ReturnEditInit, i: number) {
        const newReturns = [...returns]
        const newReturn = { ...r, [name]: value || '' }
        newReturns.splice(i, 1, newReturn)
        setReturns(newReturns)
    }
    function setR(r: ReturnEditInit, i: number) {
        const newReturns = [...returns]
        newReturns.splice(i, 1, { ...r })
        setReturns(newReturns)
    }

    function renderQuantities(item: any) {
        let q: number = 0
        const quantities: any[] = []
        while (q <= item.quantity) {
            quantities.push(<option value={q}>{q}</option>)
            q++
        }
        return quantities
    }

    const buttonStyle =
        'p-[8px] bg-blue hover:bg-blue/90 text-white font-semibold dark:bg-accent dark:hover:bg-accent/90 dark:text-black rounded shadow-small'
    const labelStyle = 'block dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
    const fieldContainerStyle = 'flex flex-col gap-[4px]'
    const inputStyle =
        'bg-lightgrey dark:bg-darkaccent p-[4px] border-[1px] dark:border-blue focus:dark:border-accent outline-0 rounded'
    const [showDiscount, setShowDiscount] = useState<string>('')
    const [showAreYouSureYouWantToDeleteReturnModal, setShowAreYouSureYouWantToDeleteReturnModal] =
        useState<boolean>(false)

    return (
        <div>
            <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Edit Returns</h1>
            {selectedReturn && returnOrder && returnCustomer && returns[0] && returns[0]?.model?.id ? (
                <EditReturnModule
                    selectedReturn={returns[0]}
                    returnOrder={returnOrder}
                    returnTask={returnTask}
                    returnCustomer={returnCustomer}
                    refreshReturns={refresh}
                />
            ) : (
                // Fallback screen for returns parsed from Google Sheets, this can be removed once we either get all the returns reformatted / figure out a better parsing method.
                <div className='flex justify-center'>
                    <div className='grid gap-[16px]'>
                        {returns.map((r: ReturnEditInit, i) => {
                            const order = returnsMap[r.id].order
                            // if the customer is unedited, the customer info will be pulled from the database, otherwise it will be set to whatever the edit made was.
                            const customer = order ? order.customer : r.customer
                            const task = returnsMap[r.id].task

                            return (
                                <div>
                                    {edited && (
                                        <button className='fixed top-[50px] right-0 py-[4px] px-[16px] font-bold bg-blue text-white dark:text-darkness dark:bg-accent border-0 z-[5] uppercase shadow-small dark:shadow-cool'>
                                            Save Return
                                        </button>
                                    )}
                                    {order ? (
                                        <div className='flex flex-col gap-[16px] bg-white dark:bg-darkaccent rounded p-[16px] grow max-w-[1100px]'>
                                            <div className='flex'>
                                                <h3
                                                    className='font-semibold text-[16px] hover:underline dark:hover:text-accent cursor-pointer'
                                                    onClick={(event: any) => handleNavigate(event, r.po_number!)}
                                                >
                                                    Order {order.order_number}
                                                </h3>
                                            </div>
                                            <div className='flex gap-[16px]'>
                                                {r.model?.id ? (
                                                    <div className='grid gap-[16px] grow'>
                                                        <div className=''>
                                                            <h2 className='text-[20px] font-bold'>Return {r.id}</h2>
                                                        </div>
                                                        <div className='grow bg-white dark:bg-darkness py-[8px] p-[16px] rounded shadow-small'>
                                                            <h3 className='font-semibold text-[16px] w-[100%] border-b-[1px] border-black dark:border-grey'>
                                                                Item from{' '}
                                                                <span
                                                                    className='hover:underline dark:hover:text-accent cursor-pointer'
                                                                    onClick={(event: any) =>
                                                                        handleNavigate(event, r.po_number || undefined)
                                                                    }
                                                                    onContextMenu={(event: any) =>
                                                                        handleNavigate(event, r.po_number || undefined)
                                                                    }
                                                                >
                                                                    Order {order?.order_number}
                                                                </span>
                                                            </h3>
                                                            <div className='flex gap-[16px]'>
                                                                {r.model.id ? (
                                                                    <div
                                                                        key={r.model.id}
                                                                        className='flex gap-[16px] py-[24px] w-[100%] justify-between col-span-2 text-[14px]'
                                                                    >
                                                                        <div className='flex gap-[16px]'>
                                                                            <div className='grid w-[100px] items-center relative'>
                                                                                <img
                                                                                    className='object-contain min-w-[100px] min-h-[100px] rounded dark:bg-darkness'
                                                                                    src={
                                                                                        window.IMAGE_MAP[
                                                                                            `gid://shopify/Product/${r.model.product_id}`
                                                                                        ]
                                                                                    }
                                                                                    alt={r.model.name}
                                                                                />
                                                                            </div>
                                                                            <div className=''>
                                                                                <a
                                                                                    href={`https://factorypure.com/search?q=${r.model.sku}`}
                                                                                    target='_blank'
                                                                                    className='font-semibold hover:underline hover:text-blue dark:hover:text-accent pointer-events-auto'
                                                                                >
                                                                                    {r.model.title}
                                                                                </a>
                                                                                {r.model.variant_title && (
                                                                                    <p className='pt-[4px]'>
                                                                                        <strong className='text-darkgrey dark:text-grey'>
                                                                                            Variant:
                                                                                        </strong>{' '}
                                                                                        {r.model.variant_title}
                                                                                    </p>
                                                                                )}
                                                                                <p className='pt-[4px]'>
                                                                                    <strong className='text-darkgrey dark:text-grey'>
                                                                                        SKU:
                                                                                    </strong>{' '}
                                                                                    {r.model.sku}
                                                                                </p>
                                                                                <ul className='list-disc ml-[16px] mt-[8px]'>
                                                                                    {/* {r.model.discount_allocations.map((discount:any) => 
                                                                                <li>{order.discount_applications[discount.discount_application_index].title ? order.discount_applications[discount.discount_application_index].title : order.discount_applications[discount.discount_application_index].code} discount applied</li>
                                                                                )} */}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex gap-[32px]'>
                                                                            <div>
                                                                                <label
                                                                                    className={
                                                                                        labelStyle + ' text-right'
                                                                                    }
                                                                                >
                                                                                    QTY
                                                                                </label>
                                                                                <span className='flex gap-[4px]'>
                                                                                    {r.model.quantity}
                                                                                </span>
                                                                            </div>
                                                                            <div>
                                                                                <label
                                                                                    className={
                                                                                        labelStyle + ' text-right'
                                                                                    }
                                                                                >
                                                                                    price
                                                                                </label>
                                                                                <div className='relative grid text-right'>
                                                                                    <span>
                                                                                        {formatMoney(
                                                                                            itemPriceAfterDiscounts(
                                                                                                r.model
                                                                                            )
                                                                                        )}
                                                                                    </span>
                                                                                    {r.model.discount_allocations
                                                                                        .length > 0 && (
                                                                                        <s
                                                                                            onClick={() =>
                                                                                                setShowDiscount(
                                                                                                    r.model.sku
                                                                                                )
                                                                                            }
                                                                                            className='js-order__discount text-grey hover:underline cursor-pointer'
                                                                                        >
                                                                                            {formatMoney(
                                                                                                Number(r.model.price)
                                                                                            )}
                                                                                        </s>
                                                                                    )}
                                                                                    {renderDiscount(
                                                                                        order!,
                                                                                        r.model.sku,
                                                                                        r.model,
                                                                                        showDiscount
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <label
                                                                                    className={
                                                                                        labelStyle + ' text-right'
                                                                                    }
                                                                                >
                                                                                    Tax
                                                                                </label>
                                                                                <span className=''>
                                                                                    {formatMoney(
                                                                                        getTaxTotal(r.model.tax_lines)
                                                                                    )}
                                                                                </span>
                                                                            </div>
                                                                            <div>
                                                                                <label
                                                                                    className={
                                                                                        labelStyle + ' text-right'
                                                                                    }
                                                                                >
                                                                                    Total
                                                                                </label>
                                                                                <div className='grid text-right'>
                                                                                    <span className=''>
                                                                                        {formatMoney(
                                                                                            Number(
                                                                                                itemPriceAfterDiscounts(
                                                                                                    r.model
                                                                                                )
                                                                                            ) *
                                                                                                r.model.quantity +
                                                                                                getTaxTotal(
                                                                                                    r.model.tax_lines
                                                                                                )
                                                                                        )}
                                                                                    </span>
                                                                                    {r.model.discount_allocations
                                                                                        .length > 0 && (
                                                                                        <s
                                                                                            onClick={() =>
                                                                                                setShowDiscount(
                                                                                                    r.model.sku
                                                                                                )
                                                                                            }
                                                                                            className='js-order__discount text-grey hover:underline cursor-pointer'
                                                                                        >
                                                                                            {formatMoney(
                                                                                                getTaxTotal(
                                                                                                    r.model.tax_lines
                                                                                                ) +
                                                                                                    Number(
                                                                                                        r.model.price
                                                                                                    ) *
                                                                                                        r.model.quantity
                                                                                            )}
                                                                                        </s>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        {!returnTask && !returnOrder && (
                                                                            <div className='flex gap-[16px] my-[16px]'>
                                                                                <div>
                                                                                    <label className={labelStyle}>
                                                                                        Manufacturer
                                                                                    </label>
                                                                                    <input
                                                                                        value={r.manufacturer}
                                                                                        className={inputStyle}
                                                                                        onChange={({ target }) =>
                                                                                            handleChangeReturn(
                                                                                                'manufacturer',
                                                                                                target.value,
                                                                                                r,
                                                                                                i
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div>
                                                                                    <label className={labelStyle}>
                                                                                        Model
                                                                                    </label>
                                                                                    <input
                                                                                        value={r.model.unparsedModel}
                                                                                        className={inputStyle}
                                                                                        // onChange={({target}) => {
                                                                                        //     const newReturns = [...returns];
                                                                                        //     newReturns.splice(i, 1, {...r, model: {unparsedModel: target.value}})
                                                                                        //     setReturns(newReturns);
                                                                                        // }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className='flex gap-[16px] bg-white dark:bg-darkness/50 rounded'>
                                                            {returnCustomer && (
                                                                <div className='flex flex-col'>
                                                                    <div className='w-[500px] dark:bg-darkness p-[16px] rounded shadow-small sticky top-[64px]'>
                                                                        <h3 className='font-semibold text-[16px] w-[100%] border-b-[1px] border-black dark:border-grey mb-[16px]'>
                                                                            Customer
                                                                        </h3>
                                                                        <div className='flex flex-col justify-between gap-[8px] dark:bg-darkness rounded-[4px]'>
                                                                            <div className={fieldContainerStyle}>
                                                                                <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                                                    Company
                                                                                </label>
                                                                                <input
                                                                                    className='bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                                                    type='text'
                                                                                    name='company'
                                                                                    value={
                                                                                        customer?.default_address
                                                                                            ?.company
                                                                                    }
                                                                                    onChange={({ target }) =>
                                                                                        handleChangeShippingAddress(
                                                                                            target,
                                                                                            r,
                                                                                            i
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div className='flex gap-[8px] grow'>
                                                                                <div className='flex flex-col gap-[4px] w-full'>
                                                                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                                                        First Name
                                                                                    </label>
                                                                                    <input
                                                                                        className='bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                                                        type='text'
                                                                                        name='first_name'
                                                                                        value={
                                                                                            customer?.default_address
                                                                                                ?.first_name
                                                                                        }
                                                                                        onChange={({ target }) =>
                                                                                            handleChangeShippingAddress(
                                                                                                target,
                                                                                                r,
                                                                                                i
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className='flex flex-col gap-[4px] w-full'>
                                                                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                                                        Last Name
                                                                                    </label>
                                                                                    <input
                                                                                        className='bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                                                        type='text'
                                                                                        name='last_name'
                                                                                        value={
                                                                                            customer?.default_address
                                                                                                ?.last_name
                                                                                        }
                                                                                        onChange={({ target }) =>
                                                                                            handleChangeShippingAddress(
                                                                                                target,
                                                                                                r,
                                                                                                i
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className={fieldContainerStyle}>
                                                                                <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                                                    Address Line 1
                                                                                </label>
                                                                                <input
                                                                                    className='bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                                                    type='text'
                                                                                    name='address1'
                                                                                    value={
                                                                                        customer?.default_address
                                                                                            ?.address1
                                                                                    }
                                                                                    onChange={({ target }) =>
                                                                                        handleChangeShippingAddress(
                                                                                            target,
                                                                                            r,
                                                                                            i
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div className={fieldContainerStyle}>
                                                                                <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                                                    Address Line 2
                                                                                </label>
                                                                                <input
                                                                                    className='bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                                                    type='text'
                                                                                    name='address2'
                                                                                    value={
                                                                                        customer?.default_address
                                                                                            ?.address2
                                                                                    }
                                                                                    onChange={({ target }) =>
                                                                                        handleChangeShippingAddress(
                                                                                            target,
                                                                                            r,
                                                                                            i
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div className='flex gap-[8px] w-full'>
                                                                                <div className='flex flex-col gap-[4px] w-1/3'>
                                                                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                                                        City
                                                                                    </label>
                                                                                    <input
                                                                                        className='w-full bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                                                        type='text'
                                                                                        name='city'
                                                                                        value={
                                                                                            customer?.default_address
                                                                                                ?.city
                                                                                        }
                                                                                        onChange={({ target }) =>
                                                                                            handleChangeShippingAddress(
                                                                                                target,
                                                                                                r,
                                                                                                i
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className='flex flex-col gap-[4px] w-1/3'>
                                                                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                                                        State
                                                                                    </label>
                                                                                    <input
                                                                                        className='w-full bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                                                        type='text'
                                                                                        name='province_code'
                                                                                        value={
                                                                                            customer?.default_address
                                                                                                ?.province_code
                                                                                        }
                                                                                        onChange={({ target }) =>
                                                                                            handleChangeShippingAddress(
                                                                                                target,
                                                                                                r,
                                                                                                i
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className='flex flex-col gap-[4px] w-1/3'>
                                                                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                                                        Zip
                                                                                    </label>
                                                                                    <input
                                                                                        className='w-full bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                                                        type='text'
                                                                                        name='zip'
                                                                                        value={
                                                                                            customer?.default_address
                                                                                                ?.zip
                                                                                        }
                                                                                        onChange={({ target }) =>
                                                                                            handleChangeShippingAddress(
                                                                                                target,
                                                                                                r,
                                                                                                i
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className={fieldContainerStyle}>
                                                                                <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                                                    Country
                                                                                </label>
                                                                                <input
                                                                                    className='bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                                                    type='text'
                                                                                    name='country'
                                                                                    value={
                                                                                        customer?.default_address
                                                                                            ?.country
                                                                                    }
                                                                                    onChange={({ target }) =>
                                                                                        handleChangeShippingAddress(
                                                                                            target,
                                                                                            r,
                                                                                            i
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div className={fieldContainerStyle}>
                                                                                <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                                                                    Phone
                                                                                </label>
                                                                                <input
                                                                                    className='bg-lightgrey focus:outline-none dark:text-white font-[600] dark:bg-faintplus p-[4px] rounded-[4px]'
                                                                                    type='text'
                                                                                    name='phone'
                                                                                    value={
                                                                                        customer?.default_address?.phone
                                                                                    }
                                                                                    onChange={({ target }) =>
                                                                                        handleChangeShippingAddress(
                                                                                            target,
                                                                                            r,
                                                                                            i
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {r.model && (
                                                                <ReturnInfoSection
                                                                    buttonStyle={buttonStyle}
                                                                    inputStyle={inputStyle}
                                                                    fieldContainerStyle={fieldContainerStyle}
                                                                    labelStyle={labelStyle}
                                                                    order={order}
                                                                    r={r}
                                                                    setR={(r: ReturnEditInit) => setR(r, i)}
                                                                    handleChangeReturn={(name: string, value: any) =>
                                                                        handleChangeReturn(name, value, r, i)
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                        <div className='flex justify-end'>
                                                            <button
                                                                className={
                                                                    buttonStyle + ' !font-bold !text-white !bg-red'
                                                                }
                                                                onClick={() => {
                                                                    setShowAreYouSureYouWantToDeleteReturnModal(true)
                                                                }}
                                                            >
                                                                DELETE RETURN
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <>
                                                        {order && (
                                                            <div className='flex flex-col gap-[8px]'>
                                                                <h2 className='font-bold text-[20px] text-red'>
                                                                    Sku "{r.model?.unparsedModel}" could not be found.
                                                                    Please select the correct model from this list.
                                                                </h2>
                                                                <SelectReturnItem
                                                                    r={r}
                                                                    setR={(r: ReturnEditInit) => setR(r, i)}
                                                                    order={order}
                                                                />
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <span>Order could not be found.</span>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
        </div>
    )
}
