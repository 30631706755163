import { useEffect, useState } from 'react'
import Acceptance from './components/Acceptance'
import { read855 } from './helpers/read'
import { EDIMessageType } from './EDIScreens.types'
import { useDebounce, vFetch } from '../helpers'
import { vendorMap } from './helpers/EDICodeMaps'

export default function EDIOrdersScreen() {
    const [search, setSearch] = useState('')
    const [orders, setOrders] = useState<EDIOrderType[]>([])
    const debouncedSearch = useDebounce(search, 300)
    const [filter, setFilter] = useState('')

    useEffect(() => {
        const query = [
            debouncedSearch ? `search=${search}` : null,
            filter === 'non-acknowledged' ? 'nonAcknowledged=true' : null,
        ]
            .filter((v) => v)
            .join('&')
        vFetch(`/v1/edi/orders${query.length ? `?${query}` : ''}`, {
            cb: (res: any) => {
                if (!res.success) return
                setOrders(res.orders || [])
            },
        })
    }, [debouncedSearch, filter])

    return (
        <div>
            <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>EDI Orders</h1>
            <div className='rounded-[4px] shadow-small dark:bg-darkaccent min-h-[85vh]'>
                <div className='flex justify-between border-b-4 border-lightgrey dark:border-darkness items-center pr-[8px] rounded-t-[4px] rounded-tl-[0px] dark:bg-darkaccent'>
                    <div className='w-full p-[8px] flex'>
                        <input
                            type='text'
                            className='w-full bg-transparent dark:text-white focus:outline-none'
                            placeholder='Search'
                            value={search}
                            onChange={({ target }) => {
                                setSearch(target.value)
                            }}
                        />
                    </div>
                    <div className='flex gap-[8px] items-center text-[14px] text-darkgrey'>
                        {(filter !== '' || search.length > 0) && (
                            <button
                                onClick={() => {
                                    setFilter('')
                                    setSearch('')
                                }}
                                className={`rounded-[4px] font-bold py-[4px] px-[8px] bg-red dark:bg-lightred text-white`}
                            >
                                CLEAR
                            </button>
                        )}
                        <button
                            onClick={() => setFilter('non-acknowledged')}
                            className={`rounded-[99vw] font-bold py-[4px] px-[8px] whitespace-nowrap ${
                                filter === 'non-acknowledged'
                                    ? 'bg-blue dark:bg-accent text-white dark:text-darkness'
                                    : 'bg-lightgrey dark:bg-darkness dark:text-offwhite'
                            }`}
                        >
                            MISSING ACKNOWLEDGEMENT
                        </button>
                    </div>
                </div>
                {orders.map((order) => (
                    <div className='p-[8px] border-b-4 border-lightgrey dark:border-darkness'>
                        <p className='font-bold dark:text-offwhite text-[18px]'>
                            #{order[850].order_name} -{' '}
                            {vendorMap[order[850].receiver_id.trim() as '2625221452' | 'GENERAC']} -{' '}
                            {new Date(order[850].created_at).toDateString()}
                        </p>
                        <div className='flex gap-[8px] my-[8px] p-[8px] border border-lightgrey dark:border-darkgrey rounded-[4px]'>
                            <p className='font-bold dark:text-offwhite'>PO:</p>
                            <Acceptance
                                GSAccepted={order[850].functional_group_accepted}
                                STAccepted={order[850].transaction_set_accepted}
                            />
                        </div>
                        {vendorMap[order[850].receiver_id.trim() as '2625221452' | 'GENERAC'] === 'Generac' && (
                            <div className='my-[8px] p-[8px] border border-lightgrey dark:border-darkgrey rounded-[4px]'>
                                <div className='flex gap-[8px]'>
                                    <div className='w-full flex gap-[8px]'>
                                        <p className='font-bold dark:text-offwhite'>PO Acknowledged:</p>
                                        {order[855].length > 0 ? (
                                            <p className='text-lime font-bold'>Yes</p>
                                        ) : (
                                            <p className='text-fire font-bold'>Pending</p>
                                        )}
                                    </div>
                                    {order[855].length > 0 && (
                                        <div className='w-full'>
                                            {order[855].map((order: EDIMessageType) => {
                                                const parsedGroup = read855(order.raw_edi)
                                                const parsed = parsedGroup[0] as unknown as POAckType
                                                return (
                                                    <div className='dark:text-offwhite'>
                                                        <p>Purchase order status: {parsed.acceptanceStatus}.</p>
                                                        <p>Line item statuses:</p>
                                                        {parsed.lineItems.map((li: any) => (
                                                            <p>
                                                                {li.sku || li.upc || li.modelNumber} -{' '}
                                                                {li.acceptanceStatus} ({li.acceptedQuantity}/
                                                                {li.quantity})
                                                            </p>
                                                        ))}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className='flex gap-[8px] my-[8px] p-[8px] border border-lightgrey dark:border-darkgrey rounded-[4px]'>
                            <p className='font-bold dark:text-offwhite'>Shipped:</p>
                            {order[856].length > 0 ? (
                                <p className='text-lime font-bold'>Yes</p>
                            ) : (
                                <p className='text-fire font-bold'>Pending</p>
                            )}
                        </div>
                        <div className='flex gap-[8px] my-[8px] p-[8px] border border-lightgrey dark:border-darkgrey rounded-[4px]'>
                            <p className='font-bold dark:text-offwhite'>Invoiced:</p>
                            {order[810].length > 0 ? (
                                <p className='text-lime font-bold'>Yes</p>
                            ) : (
                                <p className='text-fire font-bold'>Pending</p>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

type EDIOrderType = {
    810: any
    850: any
    855: any
    856: any
}
type POAckType = {
    acceptanceStatus: string
    lineItems: any
}
