import ReturnForm from './ReturnForm'

const formMap = {
    'Select Type': {},
    Sales: {},
    'Customer Service': {
        Return: (props) => (
            <ReturnForm
                product={props.product}
                selectedProducts={props.selectedProducts}
                setSelectedProducts={props.setSelectedProducts}
            />
        ),
    },
    Accounting: {},
    'Order Fulfillment': {},
}

export default formMap
