import { useEffect, useState } from 'react'
import { OrderInit } from '../orders.types'
import EmailModal from '../../emailModal/EmailModal'
import { sendToast, vFetch } from '../../helpers'
import { MdContentCopy } from 'react-icons/md'

type CustomerProps = {
    order: OrderInit
    eventSource: any
    onEmailSubmit: Function
    refetchAttachments: boolean
    setRefetchAttachments: any
}

export default function Customer({
    order,
    eventSource,
    onEmailSubmit,
    refetchAttachments,
    setRefetchAttachments,
}: CustomerProps) {
    const [emailMode, setEmailMode] = useState<string | null>(null)
    const [orderCount, setOrderCount] = useState(0)

    useEffect(() => {
        vFetch(`/v1/orders/customerCount?customer_id=${order?.customer?.id}`, {
            cb: (res: any) => {
                if (res.success) {
                    setOrderCount(res.order_count)
                }
            },
        })
    }, [])
    return (
        <div className='grid bg-white dark:bg-darkaccent shadow-small rounded dark:text-offwhite [&>*]:p-[16px] [&>*]:border-b-[1px] [&>*]:dark:border-darkgrey [&>:last-child]:border-0'>
            <div>
                <div className='flex justify-between items-center'>
                    <h2 className='text-[20px] font-bold'>Customer</h2>
                    {/* <button className='relative w-[35px] h-[35px] text-grey hover:text-darkgrey dark:hover:text-offwhite'>
                        <span
                            className='js-order__dot-button absolute text-[20px] font-black bottom-[8px] left-[11px] right-[11px] tracking-wider'
                            onClick={() => {
                                window.alert(
                                    "This doesn't work yet, but I'm sure the developers are working hard to make it do something."
                                )
                            }}
                        >
                            ...
                        </span>
                    </button> */}
                </div>
                <div className='flex justify-between'>
                    <p
                        onClick={() => {
                            window.open(
                                `https://factorypure.myshopify.com/admin/customers/${order?.customer?.id}`,
                                '_blank'
                            )
                        }}
                        className='text-blue dark:text-accent hover:underline cursor-pointer'
                    >
                        {order?.customer?.first_name} {order?.customer?.last_name}
                    </p>
                    {orderCount > 1 && (
                        <p
                            onClick={() => {
                                window.open(
                                    `https://factorypure.myshopify.com/admin/orders?customer_id=${order?.customer?.id}&inContextTimeframe=today`,
                                    '_blank'
                                )
                            }}
                            className='text-blue dark:text-accent hover:underline cursor-pointer'
                        >
                            See All Orders ({orderCount})
                        </p>
                    )}
                </div>
            </div>

            <div className='flex flex-col'>
                <h2 className='text-[20px] font-bold'>Contact Information</h2>
                <div className='grid gap-[16px]'>
                    {order?.customer?.email && (
                        <a
                            href={`mailto:${order.customer.email}`}
                            className='text-blue dark:text-accent hover:underline cursor-pointer my-[8px]'
                        >
                            {order.customer.email}
                        </a>
                    )}
                    <div className='flex gap-[16px]'>
                        {order?.customer?.email && (
                            <button
                                type='button'
                                onClick={() => setEmailMode('customer')}
                                className='py-[4px] w-full uppercase px-[16px] border-2 border-blue dark:border-fire text-blue dark:text-fire rounded-[4px] font-bold'
                            >
                                Email Customer
                            </button>
                        )}
                        {order.name && !order.name.includes('D') && (
                            <button
                                type='button'
                                onClick={() => setEmailMode('manufacturer')}
                                className='py-[4px] w-full uppercase px-[16px] border-2 border-blue dark:border-fire text-blue dark:text-fire rounded-[4px] font-bold'
                            >
                                Email Manu
                            </button>
                        )}
                    </div>
                    <EmailModal
                        eventSource={eventSource}
                        mode={emailMode}
                        setMode={setEmailMode}
                        handleSubmitEmailEvent={onEmailSubmit}
                        customer={order.customer}
                        order={order}
                        refetchAttachments={refetchAttachments}
                        setRefetchAttachments={setRefetchAttachments}
                    />
                </div>
            </div>

            <div>
                <h2 className='text-[20px] font-bold mb-[4px]'>Shipping Address</h2>

                {order.shipping_address && (
                    <div className='flex justify-between'>
                        <div className='flex flex-col'>
                            {order.shipping_address?.name && <p key={1}>{order.shipping_address?.name}</p>}
                            {order.shipping_address?.address1 && <p key={2}>{order.shipping_address?.address1}</p>}
                            {order.shipping_address?.address2 && <p key={2}>{order.shipping_address?.address2}</p>}{' '}
                            {(order.shipping_address?.city ||
                                order.shipping_address?.province_code ||
                                order.shipping_address?.zip) && (
                                <p key={3}>
                                    {[
                                        [order.shipping_address?.city, order.shipping_address?.province_code]
                                            .filter((v) => v)
                                            .join(', '),
                                        order.shipping_address?.zip,
                                    ]
                                        .filter((v) => v)
                                        .join(' ')}
                                </p>
                            )}
                            {order.shipping_address?.country && <p key={4}>{order.shipping_address?.country}</p>}
                            {order.shipping_address?.phone && <p key={5}>{order.shipping_address?.phone}</p>}
                        </div>
                        <MdContentCopy
                            title={`Copy address and phone`}
                            className='cursor-pointer opacity-50 hover:opacity-100 duration-300'
                            onClick={() => {
                                navigator.clipboard.writeText(
                                    [
                                        order.shipping_address?.name,
                                        order.shipping_address?.address1,
                                        [
                                            [order.shipping_address?.city, order.shipping_address?.province_code]
                                                .filter((v) => v)
                                                .join(', '),
                                            order.shipping_address?.zip,
                                        ]
                                            .filter((v) => v)
                                            .join(' '),
                                        order.shipping_address?.country,
                                        order.shipping_address?.phone,
                                    ]
                                        .filter((v) => v)
                                        .join(', ')
                                )
                                sendToast({ message: `Copied shipping address.` })
                            }}
                        />
                    </div>
                )}
            </div>
            <div>
                <h2 className='text-[20px] font-bold mb-[4px]'>Billing Address</h2>
                {order.billing_address && (
                    <>
                        {order.shipping_address?.name === order.billing_address?.name &&
                        order.shipping_address?.address1 === order.billing_address?.address1 &&
                        order.shipping_address?.city === order.billing_address?.city &&
                        order.shipping_address?.province_code === order.billing_address?.province_code &&
                        order.shipping_address?.zip === order.billing_address?.zip &&
                        order.shipping_address?.country === order.billing_address?.country ? (
                            <p>Same as shipping address</p>
                        ) : (
                            <div className='flex justify-between'>
                                <div className='flex flex-col'>
                                    <p key={1}>{order.billing_address?.name}</p>
                                    <p key={2}>{order.billing_address?.address1}</p>
                                    {order.billing_address?.address2 && (
                                        <p key={2}>{order.billing_address?.address2}</p>
                                    )}
                                    <p key={3}>
                                        {order.billing_address?.city}, {order.billing_address?.province_code}{' '}
                                        {order.billing_address?.zip}
                                    </p>
                                    <p key={4}>{order.billing_address?.country}</p>
                                    <p key={5}>{order.billing_address?.phone}</p>
                                </div>
                                <MdContentCopy
                                    title={`Copy address and phone`}
                                    className='cursor-pointer opacity-50 hover:opacity-100 duration-300'
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            [
                                                order.billing_address?.name,
                                                order.billing_address?.address1,
                                                order.billing_address?.address2,
                                                [
                                                    [order.billing_address?.city, order.billing_address?.province_code]
                                                        .filter((v) => v)
                                                        .join(', '),
                                                    order.billing_address?.zip,
                                                ]
                                                    .filter((v) => v)
                                                    .join(' '),
                                                order.billing_address?.country,
                                                order.billing_address?.phone,
                                            ]
                                                .filter((v) => v)
                                                .join(', ')
                                        )
                                        sendToast({ message: `Copied shipping address.` })
                                    }}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}
