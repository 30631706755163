import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { useQueryClient } from '@tanstack/react-query'
import { Button } from 'custom_components/component_Basics/Button'
import { cn } from 'helpers'
import { procurementQueries } from 'procurement/api/procurementQueryKeys'
import { useCreateNote, useUpdateCompany } from 'procurement/api/useQueries'
import EditListNote from 'procurement/components/EditListNote'
import ScoreView from 'procurement/components/ScoreView'
import { defaultCompanyLogo } from 'procurement/constants'
import { ProcurementContext, ProcurementContextType } from 'procurement/helpers'
import { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

export default function VendorV2({ company, setShowListQuickModal }: any) {
    const additionalOptionsRef = useRef<HTMLButtonElement>(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const { toggleModal, setSelectedCompany, setSelectedCompanyId, users } =
        useContext<ProcurementContextType>(ProcurementContext)
    const [showAdditionalOptions, setShowAdditionalOptions] = useState(false)
    const queryClient = useQueryClient()

    const updateCompany = useUpdateCompany()
    const { isPending, submittedAt, variables, mutate, isError } = updateCompany

    const createEvents = useCreateNote()
    const eventIsPending = createEvents.isPending

    const openCompanyModal = (view: string) => {
        queryClient.prefetchQuery(procurementQueries.companies.detail(company.id)._ctx.contacts())
        queryClient.prefetchQuery(procurementQueries.companies.detail(company.id)._ctx.events(users))
        setShowListQuickModal(undefined)
        setSearchParams((prev: any) => {
            prev.set('company_id', company.id)
            prev.set('view', view)
            return prev
        })
        toggleModal('company', company)
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!additionalOptionsRef.current?.contains(e.target as Element)) {
                setTimeout(() => {
                    setShowAdditionalOptions(false)
                }, 100)
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    return (
        <>
            <div
                key={company.name + company.id}
                className={`${cn(
                    isPending && 'opacity-50',
                    eventIsPending && 'opacity-50'
                )} text-[14px] grid grid-cols-[56px_300px_200px_74px_1fr_1fr_3fr] items-center border-b border-lightgrey dark:text-darktext1 dark:border-darkgrey hover:bg-lightgrey dark:hover:bg-darkness`}
            >
                <div className='p-[2px] justify-self-center'>
                    <img
                        className='p-[4px] bg-lightgrey dark:bg-darkness w-[36px] h-[36px] object-contain rounded-full'
                        src={company.logo || defaultCompanyLogo}
                    />
                </div>
                <button
                    onClick={() => openCompanyModal('contacts')}
                    className='font-bold  text-left relative p-[8px] w-full'
                >
                    {company.name}
                </button>
                <button
                    onClick={() => openCompanyModal('contacts')}
                    className='font-semibold  text-left relative p-[8px] w-full'
                >
                    {company.phone}
                </button>

                <ScoreView company={company} />
                <div className='flex gap-1 items-center pl-2'>
                    {company.unfulfilled_line_items_count > 0 && (
                        <Button
                            onClick={() => openCompanyModal('unfulfilled orders')}
                            variant={'outline'}
                            className='place-self-center w-fit px-2 flex gap-2 dark:border-gray-500 min-w-[75px]'
                        >
                            {company.unfulfilled_line_items_count > 0 && (
                                <ExclamationTriangleIcon className='text-danger shrink-0' />
                            )}
                            <p>{company.unfulfilled_line_items_count > 0 && company.unfulfilled_line_items_count}</p>
                        </Button>
                    )}
                </div>
                {/* <div className='w-full h-full font-bold  flex flex-col gap-[4px]'>
                    <input
                        value={company.lead_type}
                        readOnly={true}
                        name='lead_type'
                        onChange={({ target }) => {
                            const newValue = target.value
                            if (newValue.includes('Follow Up')) {
                                setSelectedCompany(company)
                                setShowListQuickModal('task')
                            }
                            updateCompany.mutateAsync({
                                id: company.id,
                                editableFields: { lead_type: target.value },
                            })
                        }}
                        className=' p-[8px] w-full h-full block text-[14px] leading-1 focus:outline-none py-[4px] bg-transparent'
                    ></input>
                </div> */}
                <div className='w-full h-full font-bold  flex flex-col gap-[4px]'>
                    <input
                        value={company.lifecycle_status}
                        readOnly={true}
                        name='lifecycle_status'
                        onChange={({ target }) => {
                            const newValue = target.value
                            if (newValue === 'Listing' || newValue === 'Listed') {
                                setSelectedCompany(company)
                                setSelectedCompanyId(company.id)
                                setShowListQuickModal('listing')
                                return
                            }
                            return updateCompany.mutate({
                                id: company.id,
                                editableFields: { lifecycle_status: target.value },
                            })
                        }}
                        className=' p-[8px] w-full h-full block text-[14px] leading-1 focus:outline-none py-[4px] bg-transparent'
                    ></input>
                </div>
                <EditListNote
                    company={company}
                    mutationFunction={createEvents}
                    latestNote={company.latest_note || undefined}
                />
                {/* <div className='p-[8px] text-sm font-semibold '>
                    {company.task_id && (
                        <button
                            onClick={() => {
                                navigate(`/procurement?task_id=${company.task_id}`)
                            }}
                            className='flex flex-col text-left'
                        >
                            <span>Task: {company.task_id}</span>
                            <span>{formatDateSimple(new Date(company.task_due_at))}</span>
                        </button>
                    )}
                </div>
                <div className='p-2 font-semibold '>{formatDateDayMonthYear(new Date(company.created_at))}</div> */}
                <div className='flex gap-[8px] justify-center items-center'>
                    {/* <button
                        onClick={() => {
                            setSelectedCompany(company)
                            return showListQuickModal === 'email'
                                ? setShowListQuickModal(undefined)
                                : setShowListQuickModal('email')
                        }}
                        className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                    >
                        <MdEmail className='text-darkgrey dark:text-darktext1  p-[6px] w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite' />
                        <p className='font-medium leading-[1] uppercase text-[12px]'>Email</p>
                    </button> */}
                    {/* <button
                        onClick={() => {
                            setSelectedCompany(company)
                            return showListQuickModal === 'task'
                                ? setShowListQuickModal(undefined)
                                : setShowListQuickModal('task')
                        }}
                        className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
                    >
                        <FaTasks className='text-darkgrey dark:text-darktext1  p-[6px] w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite' />
                        <p className='font-medium leading-[1] uppercase text-[12px]'>Task</p>
                    </button> */}
                </div>
            </div>
        </>
    )
}
