import { useEffect, useState } from 'react'
import { sendToast, vFetch } from '../helpers'
import MoreActions from '../processingTimes/components/MoreActions'
import Refresh from '../processingTimes/components/Refresh'
import { LoadingGear } from '../helpers'
import PriceCutsModal from './components/priceCuts/PriceCutsModal'
import PriceCutsList from './components/priceCuts/PriceCutsList'

export default function PriceCutsScreen() {
    const sessionToken = localStorage.getItem('session_token')
    const [priceCuts, setPriceCuts] = useState([])
    const [needsRefresh, setNeedsRefresh] = useState(true)
    const [loading, setLoading] = useState(true)
    const [view, setView] = useState('priceCuts')
    const [selectedPriceCut, setSelectedPriceCut] = useState<any>(null)
    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState('')
    const [page, setPage] = useState(1)
    useEffect(() => {
        if (needsRefresh) {
            setLoading(true)
            getPriceCuts().then((res) => {
                setPriceCuts(res)
                setNeedsRefresh(false)
                setLoading(false)
            })
        }
    }, [needsRefresh])

    async function getPriceCuts() {
        return vFetch('/v1/priceCuts', {
            cb: (res: any) => {
                if (!res.success) return []
                return res.priceCuts
            },
        })
    }
    return (
        <div>
            <div className='flex justify-between mb-[8px]'>
                <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Price Cuts</h1>
                <div className='flex gap-[8px]'>
                    <MoreActions options={[]} />
                    <Refresh setNeedsRefresh={setNeedsRefresh} />
                </div>
            </div>
            <div className='flex gap-[4px] mb-[8px]'>
                <button
                    onClick={() => setView('priceCuts')}
                    className={`mb-[-8px] py-[4px] px-[16px] rounded-t-[4px] font-bold ${
                        view === 'priceCuts'
                            ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent'
                            : 'bg-lightgrey dark:bg-darkness dark:text-lightgrey dark:border dark:border-darkgrey dark:border-b-0'
                    }`}
                >
                    Price Cuts
                </button>
                {/* <button 
                onClick={() => setView("products")}
                className={`mb-[-8px] py-[4px] px-[16px] rounded-t-[4px] font-bold ${view === "products" ? 'bg-blue text-white dark:bg-darkaccent dark:text-accent' : 'bg-lightgrey dark:bg-darkness dark:text-offwhite dark:border dark:border-darkgrey dark:border-b-0'}`}
            >
                Products
            </button> */}
                <button
                    onClick={() => setSelectedPriceCut({ status: 'SCHEDULED', amount: 0, amount_unit: 'FIXED' })}
                    className='ml-auto py-[4px] px-[16px] bg-blue text-white rounded-[4px] dark:bg-accent dark:text-darkness font-bold uppercase'
                >
                    + New Price Cut
                </button>
            </div>
            <div className='rounded-[4px] shadow-small dark:bg-darkaccent min-h-[85vh]'>
                <div className='flex justify-between border-b border-lightgrey dark:border-darkgrey items-center pr-[8px] rounded-t-[4px] rounded-tl-[0px] dark:bg-darkaccent'>
                    <div className='w-full p-[8px] flex'>
                        <input
                            type='text'
                            className='w-full bg-transparent dark:text-white focus:outline-none'
                            placeholder='Search'
                            value={search}
                            onChange={({ target }) => {
                                setSearch(target.value)
                            }}
                        />
                    </div>
                    {view === 'priceCuts' && (
                        <div className='flex gap-[8px] items-center text-[14px] text-darkgrey'>
                            {(filter !== '' || search.length > 0) && (
                                <button
                                    onClick={() => {
                                        setFilter('')
                                        setSearch('')
                                    }}
                                    className={`rounded-[4px] font-bold py-[4px] px-[8px] bg-red dark:bg-lightred text-white`}
                                >
                                    CLEAR
                                </button>
                            )}
                            <button
                                onClick={() => setFilter('STARTING')}
                                className={`rounded-[99vw] font-bold py-[4px] px-[8px] ${
                                    filter === 'STARTING'
                                        ? 'bg-blue dark:bg-accent text-white dark:text-darkness'
                                        : 'bg-lightgrey dark:bg-darkness dark:text-offwhite'
                                }`}
                            >
                                STARTING
                            </button>
                            <button
                                onClick={() => setFilter('ENDING')}
                                className={`rounded-[99vw] font-bold py-[4px] px-[8px] ${
                                    filter === 'ENDING'
                                        ? 'bg-blue dark:bg-accent text-white dark:text-darkness'
                                        : 'bg-lightgrey dark:bg-darkness dark:text-offwhite'
                                }`}
                            >
                                ENDING
                            </button>
                            <button
                                onClick={() => setFilter('RECENT')}
                                className={`rounded-[99vw] font-bold py-[4px] px-[8px] whitespace-nowrap ${
                                    filter === 'RECENT'
                                        ? 'bg-blue dark:bg-accent text-white dark:text-darkness'
                                        : 'bg-lightgrey dark:bg-darkness dark:text-offwhite'
                                }`}
                            >
                                RECENTLY EDITED
                            </button>
                        </div>
                    )}
                </div>
                {loading ? (
                    <div className='w-full h-[40vw] grid place-items-center shadow-small bg-white dark:bg-darkaccent'>
                        <LoadingGear />
                    </div>
                ) : (
                    <>
                        {view === 'priceCuts' && (
                            <PriceCutsList
                                priceCuts={priceCuts}
                                setSelectedPriceCut={setSelectedPriceCut}
                                search={search}
                                filter={filter}
                                page={page}
                            />
                        )}
                    </>
                )}
                <PriceCutsModal
                    selectedPriceCut={selectedPriceCut}
                    setSelectedPriceCut={setSelectedPriceCut}
                    setNeedsRefresh={setNeedsRefresh}
                />
            </div>
        </div>
    )
}
