import { useQuery } from '@tanstack/react-query'
import { vFetch } from 'helpers'
import AdjustmentRow from '../components/AdjustmentRow'
import { useState } from 'react'
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa'

export default function AdjustmentList({ productId }: { productId: string }) {
    const [page, setPage] = useState(1)
    const adjustmentsQuery = useQuery({
        queryKey: ['costAndPrice', 'adjustments', productId, page],
        queryFn: () => vFetch(`/v2/costAndPrice?product_id=${productId}&page=${page}`),
    })
    const adjustments = adjustmentsQuery.data?.adjustments || []
    const handleDecrementPage = () => {
        setPage((prev) => (prev > 1 ? prev - 1 : prev))
    }
    const handleIncrementPage = () => {
        setPage((prev) => (adjustments.length > 49 ? prev + 1 : prev))
    }
    return (
        <div className='relative pb-16'>
            <h2 className='text-lg font-semibold mb-4 leading-none'>Historical Adjustments</h2>
            <div className='border border-lightgrey dark:border-darkgrey rounded'>
                <div className='grid grid-cols-7 border-b border-lightgrey dark:border-darkgrey bg-accent1 text-white dark:bg-darkbg2 dark:text-offwhite text-xs font-bold'>
                    <p className='p-2'>SKU</p>
                    <p className='p-2'>Created At</p>
                    <p className='p-2'>Cost Change</p>
                    <p className='p-2'>Price Change</p>
                    <p className='p-2'>Compare At Price Change</p>
                    <p className='p-2'>Platform</p>
                    <p className='p-2'>User</p>
                </div>
                {adjustmentsQuery.isLoading ? (
                    <p className='p-2 w-full text-center'>Loading...</p>
                ) : adjustments.length === 0 ? (
                    <p className='p-2 w-full text-center'>This product has no tracked adjustments</p>
                ) : (
                    <>
                        {adjustments.map((adjustment: any) => (
                            <AdjustmentRow adjustment={adjustment} />
                        ))}
                    </>
                )}
            </div>
            <div className='absolute bottom-0 left-1/2 translate-x-[-50%] flex gap-2 items-center bg-white border rounded shadow-md border-lightgrey dark:bg-darkbg1 dark:border-darkgrey p-2'>
                <button
                    disabled={page === 1}
                    className='disabled:opacity-50 rounded border border-lightgrey dark:border-darkgrey text-xl p-2'
                    onClick={handleDecrementPage}
                >
                    <FaCaretLeft />
                </button>
                <div className='font-bold text-lg'>Page {page}</div>
                <button
                    disabled={adjustments.length < 50}
                    className='disabled:opacity-50 rounded border border-lightgrey dark:border-darkgrey text-xl p-2'
                    onClick={handleIncrementPage}
                >
                    <FaCaretRight />
                </button>
            </div>
        </div>
    )
}
