import { Button } from 'custom_components/component_Basics/Button'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

export default function FilterButton({
    selectedValues = [],
    filterOptions,
    setFilters,
}: {
    selectedValues: string[]
    filterOptions: any
    setFilters: Dispatch<SetStateAction<any>>
}) {
    const modalRef = useRef<HTMLDivElement>(null)
    const [showOptions, setShowOptions] = useState(false)
    const [search, setSearch] = useState('')
    const filteredValues = filterOptions.values.filter((value: string) =>
        value.toLowerCase().includes(search.toLowerCase())
    )
    const selectionLabel = selectedValues.length > 2 ? `${selectedValues.length} Selected` : selectedValues.join(', ')
    const handleToggleValue = (value: string) => {
        if (selectedValues.includes(value)) {
            setFilters((prev: any) => {
                const newState = structuredClone(prev)
                newState[filterOptions.key] = newState[filterOptions.key].filter((val: string) => val !== value)
                return newState
            })
        } else {
            setFilters((prev: any) => {
                const newState = structuredClone(prev)
                newState[filterOptions.key].push(value)
                return newState
            })
        }
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!modalRef.current?.contains(e.target as HTMLElement)) {
                setShowOptions(false)
                setSearch('')
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    })
    return (
        <div ref={modalRef} className='relative'>
            <Button className='flex gap-1 items-center' onClick={() => setShowOptions((prev) => !prev)}>
                {selectionLabel && <span className='pr-2 border-r border-darkgrey mr-1'>{selectionLabel}</span>}
                <filterOptions.icon />
                <span>{filterOptions.label}</span>
            </Button>
            {showOptions && (
                <div className='absolute top-full right-0 z-10 p-2 border border-lightgrey dark:border-darkgrey rounded bg-white dark:bg-darkbg1 shadow-lg font-medium text-sm'>
                    <input
                        className='p-1 mb-2 bg-transparent focus:outline-none'
                        autoFocus={true}
                        placeholder='Search...'
                        value={search}
                        onChange={({ target }) => setSearch(target.value)}
                    />
                    <div className='max-h-[300px] overflow-auto flex flex-col gap-1'>
                        {filteredValues.map((val: string) => (
                            <button
                                className='flex items-center gap-1 whitespace-nowrap'
                                onClick={() => handleToggleValue(val)}
                            >
                                <span
                                    className={`rounded-full w-4 h-4 ${
                                        selectedValues.includes(val)
                                            ? 'border-4 border-blue dark:border-darkaccent1'
                                            : 'border border-grey/50 dark:darkgrey'
                                    }`}
                                >
                                    &nbsp;
                                </span>
                                <span>{val}</span>
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
