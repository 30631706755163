import { Dispatch, SetStateAction } from 'react'
import { FaPerson, FaRegNoteSticky } from 'react-icons/fa6'

export default function ParentCompanyQuickActions({
    setShowQuickModal,
}: {
    setShowQuickModal: Dispatch<SetStateAction<string | undefined>>
}) {
    return (
        <div className='sticky top-0 bg-white dark:bg-darkaccent z-[6] flex items-center gap-[8px] p-[8px] rounded-[4px] border-b border-lightgrey dark:border-darkgrey'>
            <button
                onClick={() => setShowQuickModal('note')}
                className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
            >
                <FaRegNoteSticky className='text-darkgrey dark:text-offwhite p-[6px] w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite' />
                <p className='font-medium leading-[1] uppercase text-[12px]'>Note</p>
            </button>
            <button
                onClick={() => setShowQuickModal('contact')}
                className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'
            >
                <FaPerson className='text-darkgrey dark:text-offwhite p-[6px] w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite' />
                <p className='font-medium leading-[1] uppercase text-[12px]'>Contact</p>
            </button>
        </div>
    )
}
