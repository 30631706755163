import { useState } from 'react'
import { ReturnEditInit } from '../returns.types'
import { vFetch } from '../../helpers'
import { useNavigate } from 'react-router-dom'

type AreYouSureYouWantToDeleteReturnModalProps = {
    r: ReturnEditInit
    setShowReturnsModal: any
    setShowAreYouSureYouWantToDeleteReturnModal: any
    refreshReturns: any
}

export default function AreYouSureYouWantToDeleteReturnModal({
    r,
    setShowAreYouSureYouWantToDeleteReturnModal,
    setShowReturnsModal,
    refreshReturns,
}: AreYouSureYouWantToDeleteReturnModalProps) {
    const navigate = useNavigate()
    const buttonStyle =
        'p-[8px] bg-blue hover:bg-blue/90 text-white font-semibold dark:bg-accent dark:hover:bg-accent/90 dark:text-black rounded shadow-small'

    function handleDelete() {
        vFetch(`/v1/returns`, {
            method: 'DELETE',
            body: JSON.stringify({ id: r.id }),
            cb: (res: any) => {
                if (res.success) {
                    if (window.location.pathname === '/returns/edit') return navigate('/returns')
                    if (setShowReturnsModal) setShowReturnsModal(null)
                    setShowAreYouSureYouWantToDeleteReturnModal(false)
                    refreshReturns()
                }
            },
        })
    }

    return (
        <div className='absolute grid gap-[16px] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] bg-white dark:bg-darkness text-black dark:text-offwhite p-[16px] border-black dark:border-grey border-[1px] rounded w-full max-w-[800px]  z-50'>
            <div className='relative grid gap-[16px] max-h-[calc(100%)]'>
                <h2 className='text-[20px] font-bold'>Are you sure you want to DELETE this return?</h2>
                {/* <div className='grid gap-[16px]  max-h-[80%] overflow-auto'> */}
                <ul className='list-disc max-h-[500px] overflow-auto'>
                    {Object.keys(r).map((k) => {
                        const value = r[k as keyof ReturnEditInit]
                        const stringifiedValue = JSON.stringify(value)
                        const abbrValue = !stringifiedValue
                            ? 'NULL'
                            : stringifiedValue.length > 100
                              ? `${stringifiedValue.slice(0, 50)}...`
                              : stringifiedValue
                        return (
                            <li className='ml-[16px]'>
                                {k}: {abbrValue}
                            </li>
                        )
                    })}
                </ul>
                {/* </div> */}
                <div className='flex justify-between py-[16px] w-full'>
                    <button className={buttonStyle + ' font-bold !bg-red !text-white'} onClick={handleDelete}>
                        DELETE
                    </button>
                    <button className={buttonStyle} onClick={() => setShowAreYouSureYouWantToDeleteReturnModal(false)}>
                        No, I changed my mind
                    </button>
                </div>
            </div>
        </div>
    )
}
