import { CheckIcon, PlusCircledIcon } from '@radix-ui/react-icons'
import * as React from 'react'
import { GrRefresh } from 'react-icons/gr'
import { cn } from '../../helpers'
import { Button } from './Button'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from './Command'
import { Popover, PopoverContent, PopoverTrigger } from './Popover'

interface DataTableFacetedFilterProps<TData, TValue> {
    disabled?: Boolean
    searchToggle: boolean
    filterValues: string[]
    onSelect: any
    pending?: any
    mutationTarget?: any
    title: string
    width?: string
    options: {
        label: string
        value: string
        icon?: React.ComponentType<{ className?: string }>
    }[]
    align: 'end' | 'center' | 'start'
}

export function SelectorButton<TData, TValue>({
    disabled,
    searchToggle,
    filterValues,
    onSelect,
    pending,
    mutationTarget,
    title,
    options,
    width,
    align = 'end',
}: DataTableFacetedFilterProps<TData, TValue>) {
    const selectedValues = new Set(filterValues as string[])

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button variant='outline' size='sm' className='h-8 border-dashed'>
                    <PlusCircledIcon className='mr-2 h-4 w-4' />
                    {title}
                </Button>
            </PopoverTrigger>
            <PopoverContent className={cn('w-[200px] p-0', width && width)} align={align}>
                <Command className={'bg-bg1 dark:bg-darkbg1 text-text1 dark:text-darktext1'}>
                    {searchToggle && <CommandInput placeholder={title} />}
                    <CommandList>
                        <CommandEmpty>No results found.</CommandEmpty>
                        <SelectCommandGroup
                            mutationTarget={mutationTarget}
                            pending={pending}
                            options={options}
                            selectedValues={selectedValues}
                            onSelect={onSelect}
                        />
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}
function SelectCommandGroup({
    pending,
    mutationTarget,
    options,
    selectedValues,
    onSelect,
}: {
    pending?: any
    mutationTarget?: any
    onSelect: any
    selectedValues: Set<string>
    options: {
        label: string
        value: string
        icon?: React.ComponentType<{ className?: string }>
    }[]
}) {
    return (
        <CommandGroup>
            {options?.map((option) => {
                const isSelected = selectedValues.has(option.value)
                const isMutationTarget = mutationTarget == option.value

                return (
                    <CommandItem
                        disabled={pending}
                        className={cn(pending && isMutationTarget && 'opacity-50')}
                        key={option.value}
                        onSelect={(target) => onSelect(target)}
                    >
                        <div
                            className={cn(
                                'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                                isSelected ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible'
                            )}
                        >
                            <CheckIcon className={cn('h-4 w-4')} />
                        </div>
                        {option.icon && (
                            <option.icon className='mr-2 min-h-[16px] min-w-[16px] text-muted-foreground' />
                        )}

                        <span>{option.label}</span>
                        {/* split on this comma and pull the value off in the passed onSelect */}
                        <span className='hidden'>,{option.value}</span>
                        {isMutationTarget && pending && (
                            <GrRefresh
                                className={cn(
                                    'self-center ml-auto',
                                    'animate-spin opacity-100',
                                    '[&>*]:stroke-darkgrey [&>*]:dark:stroke-offwhite '
                                )}
                            />
                        )}
                    </CommandItem>
                )
            })}
        </CommandGroup>
    )
}
