import TaskRow from '../components/TaskRow'
import { FaCaretDown, FaCaretUp, FaRegCircle } from 'react-icons/fa'
import { taskStatusDisplayMap } from '../constants'
import { Dispatch, SetStateAction, useState } from 'react'

export default function TaskList({ tasks }: { tasks: any }) {
    const [expandedSections, setExpandedSections] = useState<{ [key: string]: boolean }>({
        'in review': true,
        blocked: true,
        'in progress': true,
        todo: true,
        backlog: false,
        done: false,
        canceled: false,
    })
    const taskMap: any = {
        'in review': [],
        blocked: [],
        'in progress': [],
        todo: [],
        backlog: [],
        done: [],
        canceled: [],
    }
    if (tasks) {
        for (const task of tasks) {
            taskMap[task.status as keyof typeof taskMap].push(task)
        }
    }
    const taskCategories = Object.entries(taskMap).filter(([status, tasks]: any) => tasks.length > 0)

    return (
        <div className=''>
            {taskCategories.map(([status, tasks]: any, typeIndex: number) => (
                <div
                    className={`${
                        typeIndex === taskCategories.length - 1 ? '' : 'mb-4'
                    } border border-lightgrey dark:border-darkaccent rounded`}
                >
                    <TaskListHeading
                        status={status}
                        count={tasks.length}
                        expanded={expandedSections[status]}
                        setExpandedSections={setExpandedSections}
                    />
                    {expandedSections[status as keyof typeof expandedSections] && (
                        <>
                            {tasks.map((task: any, index: number) => (
                                <TaskRow task={task} last={index === tasks.length - 1} />
                            ))}
                        </>
                    )}
                </div>
            ))}
        </div>
    )
}

function TaskListHeading({
    status,
    count,
    expanded,
    setExpandedSections,
}: {
    status: string
    count: number
    expanded: boolean
    setExpandedSections: Dispatch<SetStateAction<{ [key: string]: boolean }>>
}) {
    const { icon: Icon, className } = taskStatusDisplayMap[status] || { icon: FaRegCircle, class: '' }
    return (
        <div className='text-[14px] font-bold capitalize flex gap-2 items-center bg-lightgrey rounded-t dark:bg-darkaccent dark:text-offwhite py-2 px-4'>
            <Icon className={className} />
            <p>{status}</p>
            <p className='opacity-50'>{count}</p>
            <button
                onClick={() =>
                    setExpandedSections((previousState) => ({ ...previousState, [status]: !previousState[status] }))
                }
            >
                {expanded ? <FaCaretUp /> : <FaCaretDown />}
            </button>
        </div>
    )
}
