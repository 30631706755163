import { SkeletonBasic } from 'custom_components/component_Basics/SkeletonBasic'

export default function CustomerSkeletonRow() {
    return (
        <div className='grid grid-cols-[2fr_1.5fr_3fr_1fr_1fr] border-b border-lightgrey dark:border-darkgrey'>
            <div className='p-2 opacity-50'>
                <SkeletonBasic className='w-full h-[20px]' />
            </div>
            <div className='p-2 opacity-50'>
                <SkeletonBasic className='w-full h-[20px]' />
            </div>
            <div className='p-2 opacity-50'>
                <SkeletonBasic className='w-full h-[20px]' />
            </div>
            <div className='p-2 opacity-50'>
                <SkeletonBasic className='w-full h-[20px]' />
            </div>
            <div className='p-2 opacity-50'>
                <SkeletonBasic className='w-full h-[20px]' />
            </div>
        </div>
    )
}
