import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import { createColumnHelper } from '@tanstack/react-table'
import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { useDebounce } from 'helpers'
import { useShopifyVendorsQuery } from 'procurement/api/useQueries'
import FilterToolBar from 'procurement/components/FilterToolBar'
import { useGetProductsQuery } from 'productSetupV2/api/useQueries'
import ProductsTable from 'productSetupV2/ProductsTable'
import React, { useState } from 'react'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export default function LeadTimeProductSearch({ setSelectedProduct }: { setSelectedProduct: any }) {
    const [search, setSearch] = useState('')
    const debouncedSearch = useDebounce(search, 500)
    const [filters, setFilters] = useState({
        vendors: [],
        after: '',
        before: '',
    })

    const productsQuery = useGetProductsQuery({ ...filters, limit: 10 }, debouncedSearch, true, true)
    const { products } = productsQuery?.data || {}

    const shopifyVendorsQuery = useShopifyVendorsQuery()
    const { vendors: shopifyVendorNames }: { vendors: string[] } = shopifyVendorsQuery?.data || {}

    function idSort(a: any, b: any) {
        return a.id - b.id
    }
    if (products?.length > 2) {
        products.sort(idSort)
    }

    const companyFilterBarObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: shopifyVendorNames?.map((vendorName: string) => {
                    return {
                        value: vendorName,
                        label: vendorName,
                        icon: undefined,
                    }
                }),
                title: 'Vendors',
                field: 'vendors',
                values: filters.vendors,
                searchToggle: true,
                editSearchParams: false,
            },
        ],
        setFunction: setFilters,
        resetFunction: () => {
            return setFilters({
                vendors: [],
                after: '',
                before: '',
            })
        },
    }

    type ProductInfo = {
        id: string | number
        company_id?: number | string
        vendor?: string
        title: string
        status?: string
        sku?: string
        product_type?: string
        has_identifying_info?: boolean
        featured_image?: string
    }

    const tableData = products?.length > 0 ? products : []
    const tableDataMemo = React.useMemo(() => tableData ?? [], [tableData])
    const columnHelper = createColumnHelper<ProductInfo>()

    const productsColumns = [
        columnHelper.accessor('title', {
            minSize: 600,
            header: 'Title',
            cell: (info) => {
                return (
                    <div
                        onClick={() => setSelectedProduct(info.row.original)}
                        className='flex flex-col gap-2 cursor-pointer '
                    >
                        {typeof info.getValue() === 'number' ? info.getValue() : info.getValue()}
                    </div>
                )
            },
        }),

        columnHelper.accessor('has_identifying_info', {
            header: 'Info',
            cell: (info) => {
                return (
                    <div className='flex flex-col gap-2 '>
                        {!info.getValue() && (
                            <div className='group relative '>
                                <ExclamationTriangleIcon className='text-danger z-[4] ' />
                                <div className='hidden group-hover:flex border absolute top-[100%] right-0 w-fit whitespace-nowrap bg-white shadow-small text-sm dark:bg-darkbg1 p-2 rounded-[4px] z-[5] '>
                                    Product has no UPC, model number or supplier sku
                                </div>
                            </div>
                        )}
                    </div>
                )
            },
            size: 30,
        }),
        columnHelper.accessor('sku', {
            header: 'Sku',
            cell: (info) => {
                return (
                    <div
                        onClick={() => setSelectedProduct(info.row.original)}
                        className='flex flex-col gap-2 cursor-pointer'
                    >
                        {typeof info.getValue() === 'number' ? info.getValue() : info.getValue()}
                    </div>
                )
            },
        }),
        columnHelper.accessor('status', {
            cell: (info) => {
                return (
                    <div
                        onClick={() => setSelectedProduct(info.row.original)}
                        className='flex flex-col gap-2 cursor-pointer'
                    >
                        {typeof info.getValue() === 'number' ? info.getValue() : info.getValue()}
                    </div>
                )
            },
            maxSize: 60,
        }),
        columnHelper.accessor('product_type', {
            header: 'Product Type',
            cell: (info) => {
                return (
                    <div
                        onClick={() => setSelectedProduct(info.row.original)}
                        className='flex flex-col gap-2 cursor-pointer'
                    >
                        {typeof info.getValue() === 'number' ? info.getValue() : info.getValue()}
                    </div>
                )
            },
        }),
        columnHelper.accessor('vendor', {
            cell: (info) => {
                return (
                    <div
                        onClick={() => setSelectedProduct(info.row.original)}
                        className='flex justify-between items-center gap-2 cursor-pointer'
                    >
                        <span className='font-semibold'>{info.getValue()}</span>
                        <Link
                            to={`https://admin.shopify.com/store/factorypure/products/${info.row.original.id}`}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            <FaExternalLinkAlt />
                        </Link>
                    </div>
                )
            },
        }),
    ]

    const columnCount = productsColumns?.length.toString() || 10

    return (
        <>
            <div>
                <FilterToolBar
                    search={search}
                    setSearch={setSearch}
                    handleClearSearch={() => {
                        setSearch('')
                        setFilters((prev: any) => {
                            return { vendors: prev.vendors, after: '', before: '' }
                        })
                    }}
                    filterBarObject={companyFilterBarObject}
                >
                    <FetchingSpinner isFetching={productsQuery.isFetching} />

                    {companyFilterBarObject.params.length &&
                        companyFilterBarObject.params.map((param: any) => {
                            return (
                                <param.component
                                    key={param.title + param.field}
                                    searchToggle={param.searchToggle}
                                    editSearchParams={param.editSearchParams}
                                    title={param.title}
                                    field={param.field}
                                    options={param.options}
                                    filterValues={param.values}
                                    setFilterValues={companyFilterBarObject.setFunction}
                                />
                            )
                        })}
                </FilterToolBar>
                <>
                    <div className='w-full'>
                        <div className='w-full  text-white dark:text-offwhite dark:border-darkgrey dark:bg-darkness max-h-[325px] overflow-scroll'>
                            <ProductsTable
                                columns={productsColumns}
                                tableData={tableDataMemo}
                                columnCount={columnCount}
                            />
                        </div>
                    </div>
                </>
            </div>
        </>
    )
}
