import RoadmapRow from '../components/RoadmapRow'

export default function RoadmapList({ roadmaps }: { roadmaps: any }) {
    return (
        <div className=''>
            {roadmaps?.map((roadmap: any, index: number) => (
                <RoadmapRow roadmap={roadmap} last={index === roadmaps.length - 1} />
            ))}
        </div>
    )
}
