import { moneyFormatter } from '../helpers'

export default function CompetitorProductRow({ product }: any) {
    return (
        <div className='grid grid-cols-[64px_2fr_1fr_1fr_1fr_1fr_1fr] items-center border-b border-lightgrey dark:border-darkgrey'>
            <div className='p-2'>
                <img
                    className='w-12 h-12 rounded border border-lightgrey dark:border-darkgrey'
                    src={product.featured_image}
                />
            </div>
            <p className='font-bold text-sm p-2'>{product.title}</p>
            <p className='font-bold text-sm p-2'>{product.sku}</p>
            <p className='font-bold text-sm p-2'>{product.competitor_name}</p>
            <p className='font-bold text-sm p-2'>{moneyFormatter(product.price)}</p>
            <p className='font-bold text-sm p-2'>
                {product.compare_at_price && moneyFormatter(product.compare_at_price)}
            </p>
            <p className='font-bold text-sm p-2'>
                {product.our_price && (
                    <>
                        {moneyFormatter(product.our_price)}{' '}
                        {product.our_compare_at_price && `(${moneyFormatter(product.our_compare_at_price)})`}
                    </>
                )}
            </p>
        </div>
    )
}
