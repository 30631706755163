import { Cross2Icon } from '@radix-ui/react-icons'
import { Button } from '../../custom_components/component_Basics/Button'
import { cn } from '../../helpers'

export default function FilterToolBar({
    search,
    setSearch,
    handleClearSearch = () => setSearch(''),
    filterBarObject,
    children,
    className,
    placeholder = 'Search',
    showSearch = true,
}: any) {
    let isFiltered
    if (filterBarObject) {
        filterBarObject.params.map((param: any) => {
            if (param.values?.length) {
                isFiltered = true
            }
        })
    }
    return (
        <div
            className={cn(
                'w-full grid grid-cols-[2fr_3fr] gap-4  dark:border-darkgrey items-center pr-[8px] rounded-t-[4px] rounded-tl-[0px] dark:bg-darkaccent',
                className
            )}
        >
            {showSearch && (
                <div className='w-full flex relative '>
                    <input
                        type='text'
                        className='w-full bg-transparent dark:text-white focus:outline-none p-2'
                        placeholder={placeholder}
                        value={search}
                        onChange={({ target }) => {
                            setSearch(target.value)
                        }}
                    />
                    <div className='self-center'>
                        <Button
                            disabled={search ? false : true}
                            variant='destructive'
                            onClick={handleClearSearch}
                            className={`${cn(
                                !search &&
                                    'text-darkgrey dark:text-darkgrey opacity-50 bg-bg1 dark:bg-darkbg1 border-none',
                                search && 'border border-red'
                            )} h-8 px-1 lg:px-2`}
                        >
                            Clear Search
                            <Cross2Icon className='ml-2 h-4 w-4' />
                        </Button>
                    </div>
                </div>
            )}
            <div className='relative shrink-0 flex gap-[8px] justify-end items-center col-start-2 mb-1'>
                {children}
                {children && (
                    <Button
                        disabled={isFiltered ? false : true}
                        variant='destructive'
                        onClick={() => {
                            filterBarObject.resetFunction()
                        }}
                        className={`${cn(
                            !isFiltered &&
                                'text-darkgrey dark:text-darkgrey opacity-50 bg-1 dark:bg-darkbg1 border-none',
                            isFiltered && 'border border-red'
                        )} h-8 px-1 lg:px-2`}
                    >
                        Reset
                        <Cross2Icon className='ml-2 h-4 w-4' />
                    </Button>
                )}
            </div>
        </div>
    )
}
