import { useEffect, useState } from 'react'
import { closePopout } from '../helpers'
import { BsFillTrash3Fill } from 'react-icons/bs'
import { BiChevronDown } from 'react-icons/bi'
import { ReactCheckbox } from './ReactCheckbox'

type ReactCheckboxSelectProps = {
    name?: string
    options: string[]
    icon?: any
    iconSide?: 'left' | 'right'
    selected: string[]
    setSelected: Function
    style?: string
    listStyle?: string
    optionStyle?: string
}
export const ReactCheckboxSelect = ({
    name,
    options,
    selected,
    setSelected,
    icon = (
        <BiChevronDown className='absolute fill-black dark:fill-offwhite h-[20px] w-[20px] right-0 pointer-events-none' />
    ),
    iconSide = 'right',
    style,
    listStyle,
    optionStyle = 'hover:bg-[rgb(240,240,240)] dark:hover:bg-darkaccent',
}: ReactCheckboxSelectProps) => {
    const [show, setShow] = useState(false)
    const [nameOrNum, setNameOrNum] = useState(name || `${Math.floor(Math.random() * Math.pow(10, 18))}`)

    useEffect(() => {
        const listenToWindow = (e: any) =>
            closePopout(e, [`js-checkbox-select__${nameOrNum}`, `js-checkbox-select__${nameOrNum}__option`], setShow)
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <div key={nameOrNum}>
            {name && (
                <label className='flex items-center gap-x-[8px] uppercase text-[10px] font-bai font-bold text-blue dark:text-grey'>
                    {name}
                    {selected.length > 0 && (
                        <BsFillTrash3Fill
                            className='dark:fill-accent relative top-[-2px] cursor-pointer h-[13px] w-[13px]'
                            onClick={() => setSelected([])}
                        />
                    )}
                </label>
            )}
            <div
                className={`js-checkbox-select__${nameOrNum} relative flex gap-[4px] justify-between items-center w-full py-[3px] px-[6px] outline-0 border-[1px] border-grey dark:border-blue focus:border-[1px] dark:bg-darkness dark:text-white dark:focus:border-blue z-2 rounded caret-transparent ${
                    show ? 'rounded-t rounded-b-none' : ''
                } ${style}`}
                onClick={(e) => {
                    if ((e.target as HTMLElement).classList.contains(`js-checkbox-select__${nameOrNum}`)) setShow(!show)
                }}
                tabIndex={0}
            >
                {iconSide === 'left' && (
                    <div className='flex relative justify-center items-center w-[20px] h-[20px]'>{icon}</div>
                )}
                <input
                    className={`bg-transparent pointer-events-none w-full ${style}`}
                    type='text'
                    value={Array.isArray(selected) ? selected.join(', ') : selected}
                    tabIndex={-1}
                />
                {iconSide === 'right' && (
                    <div className='flex relative justify-center items-center w-[20px] h-[20px] pointer-events-none'>
                        {icon}
                    </div>
                )}
                {show && (
                    <div
                        className={`js-checkbox-select__${nameOrNum} absolute top-[calc(100%)] w-[calc(100%+2px)] left-[-1px] h-[250px] overflow-x-hidden overflow-auto select-none z-index-5`}
                    >
                        <div
                            className={`js-checkbox-select__${nameOrNum} js-dropdown relative flex flex-col border-[1px] border-t-0 border-grey dark:border-blue bg-[white] dark:text-offwhite dark:bg-darkness pointer-events-auto select-none ${listStyle}`}
                            onClick={() => setShow(true)}
                        >
                            {options &&
                                options.map((option) => (
                                    <ReactCheckbox
                                        option={option}
                                        name={nameOrNum}
                                        key={option}
                                        selected={selected}
                                        setSelected={setSelected}
                                        styleFromList={optionStyle}
                                    />
                                ))}
                        </div>
                    </div>
                )}

                {/* {show && <div className={`absolute top-[calc(100%)] left-[-1px] min-w-[calc(100%-6px)] h-[250px] overflow-x-hidden overflow-auto pointer-events-none select-none`}>
                    <div className={`js-dropdown relative flex flex-col gap-[8px] w-full border-[1px] border-[#cdcdcd] dark:border-darkgrey bg-[white] dark:text-offwhite dark:bg-darkness pointer-events-auto select-none`} onClick={() => {filter.setShow(true)}}>
                        {filter.options.map(option => <FilterByButton key={option} option={option} quantity={filter.quantities ? filter.quantities[filter.options.indexOf(option)] : undefined} propFilter={filter} filterClassName={`filter__${ex?.namePrepend ? `${ex.namePrepend}__${filter.name.toLowerCase().split(" ").join("-")}` : filter.name.toLowerCase().split(" ").join("-")}`} />)}
                    </div>
                </div>} */}
            </div>
        </div>
    )
}
