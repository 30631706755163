import TaskList from './lists/TaskList'
import CreateTask from './quickModals/CreateTask'
import { useState } from 'react'
import { useTasks } from './api/useQueries'

export default function DevelopmentTasksScreen() {
    const [quickModal, setQuickModal] = useState<undefined | string>()

    const tasksQuery = useTasks()
    const { data: tasks, isPending } = tasksQuery

    return (
        <div>
            <div className='flex justify-between mb-4'>
                <h1 className='text-[24px] leading-tight font-semibold dark:text-offwhite capitalize'>
                    Development Tasks
                </h1>
                <button
                    className='h-fit bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold py-1 px-2 leading-tight rounded'
                    onClick={() => setQuickModal('task')}
                >
                    + New Task
                </button>
            </div>
            {isPending ? <div>Loading...</div> : <TaskList tasks={tasks} />}
            {quickModal === 'task' && <CreateTask setQuickModal={setQuickModal} />}
        </div>
    )
}
