import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'

export const productSetup = createQueryKeys('productSetup', {
    inventory: ({
        emailIdParam,
        filters,
        search,
    }: {
        emailIdParam?: string | undefined
        filters?: string[] | undefined
        search?: string | undefined
    }) => ({
        queryKey: [emailIdParam, filters, search],
        queryFn: (context) =>
            service.getInventoryEmails({
                emailIdParam,
                filters,
                search,
            }),
    }),
    files: (files: any) => ({
        queryKey: [files],
        queryFn: () => service.getFiles(files),
    }),
    products: (filters: any, search: string) => ({
        queryKey: [filters, search],
        queryFn: () => service.getProducts(filters, search),
    }),
    productSetupEvents: (productId: string | undefined) => ({
        queryKey: [productId],
        queryFn: () => service.getProductSetupEvents(productId),
    }),

    variants: (product_id: any) => ({
        queryKey: [product_id],
        queryFn: () => service.getVariants(product_id),
    }),
    privateEvents: () => ({
        queryKey: ['privateEvents'],
        queryFn: () => service.getProductSetupPrivateEvents(),
    }),
})

export const productGroups = createQueryKeys('productGroups', {
    list: (filters: any) => ({
        queryKey: [filters],
        queryFn: () => service.getProductGroups(filters),
    }),
    groupedProductIdsQuery: () => ({
        queryKey: ['groupedProductIdsQuery'],
        queryFn: () => service.groupedProductIdsQuery(),
    }),
    detail: ({ groupId, productId }: { groupId?: any; productId?: any }) => ({
        queryKey: [groupId, productId],
        queryFn: () => service.getProductGroupDetail({ groupId, productId }),
        contextQueries: {
            options: {
                queryKey: null,
                queryFn: (context) => service.getOptions({ groupId }),
            },
            // productOptions: {
            //     queryKey: null,
            //     queryFn: (context) => service.getProductOptions({ groupId }),
            // },
        },
    }),
})
export const leadTimes = createQueryKeys('leadTimes', {
    list: (filters: any) => ({
        queryKey: [filters],
        queryFn: () => service.getLeadTimes(filters),
    }),
    detail: (leadTimeId: string | undefined) => ({
        queryKey: [leadTimeId],
        queryFn: () => service.getLeadTimeDetail(leadTimeId),
    }),
})

export const productSetupQueries = mergeQueryKeys(productSetup, productGroups, leadTimes)
