import { FilterBarSelectorButton } from 'custom_components/component_Basics/FilterBarSelectorButton'
import { useDebounce } from 'helpers'
import { useShopifyVendorsQuery } from 'procurement/api/useQueries'
import FilterToolBar from 'procurement/components/FilterToolBar'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export default function InventoryEmailsSearchBarContainer({ setDebouncedSearch }: { setDebouncedSearch: any }) {
    const [searchParams, setSearchParams] = useSearchParams()
    const vendorsString = searchParams.get('vendors') || ''
    const statusesString = searchParams.get('status') || ''

    const searchParam = searchParams.get('search') || ''
    const [search, setSearch] = useState(searchParam)

    const debouncedSearch = useDebounce(search.trim(), 400)
    useEffect(() => {
        setDebouncedSearch(debouncedSearch)
    }, [debouncedSearch])

    const vendors = decodeURIComponent(vendorsString)
        .split(',')
        .filter((v) => v)
    const statuses = decodeURIComponent(statusesString)
        .split(',')
        .filter((v) => v)

    const shopifyVendorsQuery = useShopifyVendorsQuery()
    const { vendors: shopifyVendorNames }: { vendors: string[] } = shopifyVendorsQuery?.data || {}

    const companyFilterBarObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: shopifyVendorNames?.map((vendorName: string) => {
                    return {
                        value: vendorName,
                        label: vendorName,
                        icon: undefined,
                    }
                }),
                title: 'Vendors',
                field: 'vendors',
                values: vendors,
                searchToggle: true,
                editSearchParams: true,
            },
            {
                component: FilterBarSelectorButton,
                options: [
                    {
                        value: 'success',
                        label: 'Success',
                        icon: undefined,
                    },
                    {
                        value: 'failure',
                        label: 'Failure',
                        icon: undefined,
                    },
                ],
                title: 'Status',
                field: 'status',
                values: statuses,
                searchToggle: false,
                editSearchParams: true,
            },
        ],
        setFunction: () => {},
        resetFunction: () =>
            setSearchParams((prev: URLSearchParams) => {
                prev.delete('vendors')
                prev.set('page', '1')
                prev.delete('after')
                prev.delete('status')
                prev.delete('before')
                return prev
            }),
    }

    const handleClearSearch = () => {
        setSearchParams((prev: URLSearchParams) => {
            prev.delete('after')
            prev.delete('search')
            prev.delete('before')
            prev.set('page', '1')
            return prev
        })
        setSearch('')
    }

    // const selectorButtonObject = {
    //     params: [
    //         {
    //             component: FilterBarSelectorButton,
    //             options: shopifyVendorNames?.map((vendorName: string) => {
    //                 return {
    //                     value: vendorName,
    //                     label: vendorName,
    //                     icon: undefined,
    //                 }
    //             }),
    //             title: 'Vendors',
    //             field: 'vendors',
    //             values: filters.vendors,
    //             searchToggle: true,
    //             editSearchParams: true,
    //         },
    //     ],
    //     setFunction: setFilters,
    //     resetFunction: () => {
    //         setFilters({
    //             vendors: [],
    //         })
    //     },
    // }
    return (
        <div className='flex justify-between border-b border-lightgrey dark:border-darkgrey items-center pr-[8px] rounded-t-[4px] rounded-tl-[0px] dark:bg-darkaccent'>
            <FilterToolBar
                handleClearSearch={handleClearSearch}
                search={search}
                setSearch={setSearch}
                filterBarObject={companyFilterBarObject}
            >
                {/* {isFetching && <FetchingSpinner isFetching={isFetching} />} */}
                {/* {selectorButtonObject.params.length &&
                    selectorButtonObject.params.map((param: any) => {
                        return (
                            <param.component
                                key={param.title + param.field}
                                searchToggle={param.searchToggle}
                                title={param.title}
                                field={param.field}
                                options={param.options}
                                filterValues={param.values}
                                setFilterValues={selectorButtonObject.setFunction}
                            />
                        )
                    })} */}
                {companyFilterBarObject.params.length &&
                    companyFilterBarObject.params.map((param: any) => {
                        return (
                            <param.component
                                key={param.title + param.field}
                                searchToggle={param.searchToggle}
                                editSearchParams={param.editSearchParams}
                                title={param.title}
                                field={param.field}
                                options={param.options}
                                filterValues={param.values}
                                setFilterValues={companyFilterBarObject.setFunction}
                            />
                        )
                    })}
            </FilterToolBar>
        </div>
    )
}
