'use client'

import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons'
import * as React from 'react'
import { DayPicker } from 'react-day-picker'

import { cn } from '../../helpers'
import { buttonVariants } from './Button'
import { CustomDayContentTaskCounts } from './CustomDayContentTaskCounts'
import { CustomTaskDay } from './CustomTaskDay'
import { TaskListCaptionLabel } from './TaskListCaptionLabel'

export type CalendarProps = React.ComponentProps<typeof DayPicker>

function CalendarBasev2({ className, classNames, showOutsideDays = true, showTasks = true, ...props }: any) {
    return (
        <DayPicker
            showOutsideDays={showOutsideDays}
            className={cn('p-3', className)}
            classNames={{
                months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
                month: 'space-y-4',
                caption: 'flex justify-center pt-1 relative items-center text-text dark:text-darktext1',
                caption_label: 'text-sm font-medium',
                nav: 'space-x-1 flex items-center ',
                nav_button: cn(
                    buttonVariants({ variant: 'outline' }),
                    'text-text dark:text-darktext1 h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100'
                ),
                nav_button_previous: 'absolute left-1',
                nav_button_next: 'absolute right-1',
                table: 'w-full border-collapse',
                head_row: 'flex justify-between',
                head_cell:
                    ' text-muted-foreground rounded-md w-12 font-normal text-text1 dark:text-darktext1 text-[0.8rem]',
                row: 'flex w-full gap-[2px]',
                cell: cn(
                    'relative h-12 w-12 p-0 mt-[2px] text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-bg1  dark:[&:has([aria-selected])]:bg-darkbg1 [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected].day-range-end)]:rounded-r-md',
                    props.mode === 'range'
                        ? '[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md'
                        : '[&:has([aria-selected])]:rounded-md'
                ),
                day: cn(
                    buttonVariants({
                        variant: 'ghost',
                        className:
                            'text-text1 dark:text-darktext1 border-[1px] p-[2px] border-darkgrey hover:bg-gray-300 h-4 hover:dark:bg-darkbg2 hover:text-accent1 dark:hover:text-darktext1',
                    }),
                    'h-12 w-12  font-normal aria-selected:opacity-100 '
                ),
                day_range_start: 'day-range-start',
                day_range_end: 'day-range-end',
                day_today: 'bg-gray-300 dark:bg-darkbg2 text-text1 dark:text-text2 text-accent-foreground',
                day_selected:
                    'bg-sky-900 dark:bg-sky-800 dark:bg-darkaccent1 text-text2 dark:text-darktext1 text-primary-foreground hover:bg-sky-800 hover:text-text2 focus:bg-primary focus:text-primary-foreground',
                day_outside:
                    'day-outside text-muted-foreground opacity-50  aria-selected:bg-accent/50 dark:aria-selected:bg-darkbg2/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
                day_disabled: 'text-muted-foreground opacity-50',
                day_range_middle: 'aria-selected:bg-accent aria-selected:text-accent-foreground',
                day_hidden: 'invisible',
                ...classNames,
            }}
            components={{
                Day: ({ ...props }) => <CustomTaskDay displayMonth={props.displayMonth} date={props.date} />,
                DayContent: ({ ...props }) => (
                    <CustomDayContentTaskCounts
                        showTasks={showTasks}
                        activeModifiers={props.activeModifiers}
                        date={props.date}
                        displayMonth={props.displayMonth}
                    />
                ),
                CaptionLabel: ({ ...props }) => <TaskListCaptionLabel {...props} />,
                Months: ({ ...props }) => <div>{props.children}</div>,
                IconLeft: ({ ...props }) => <ChevronLeftIcon className='h-4 w-4' />,
                IconRight: ({ ...props }) => <ChevronRightIcon className='h-4 w-4' />,
            }}
            {...props}
        />
    )
}
CalendarBasev2.displayName = 'Calendar'

export { CalendarBasev2 }
