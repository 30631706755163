import { Button } from 'custom_components/component_Basics/Button'
import Input from 'procurement/components/Input'
import Select from 'procurement/components/Select'
import Textarea from 'procurement/components/Textarea'
import { useEffect, useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { useCreateRoadmap } from 'tasksV2/api/useQueries'
import { TASK_STATUSES } from 'tasksV2/constants/tasks'
import RoadmapAssignmentsModal from './RoadmapAssignmentsModal'
import { useSelector } from 'react-redux'

export default function CreateRoadmapModal({ closeModal }: { closeModal: () => void }) {
    const settings = useSelector((state: any) => state.settings)
    const [roadmap, setRoadmap] = useState<any>({
        associations: [],
    })
    const createRoadmapMutation = useCreateRoadmap()
    const modalRef = useRef<HTMLDivElement>(null)
    const [showAssignments, setShowAssignments] = useState(false)
    const handleCreate = () => {
        createRoadmapMutation.mutate(roadmap)
    }

    const toggleAssociations = (association: { resource_name: string; readable_name: string; resource_id: number }) => {
        setRoadmap((prev: any) => {
            const newState = structuredClone(prev)
            if (!newState.associations) {
                newState.associations = []
            }
            if (newState.associations?.find((resource: any) => resource.resource_id === association.resource_id)) {
                newState.associations = newState.associations.filter(
                    (resource: any) => resource.resource_id !== association.resource_id
                )
                return newState
            }
            newState.associations.push(association)
            return newState
        })
    }

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
        setRoadmap((prev: any) => {
            const newState = structuredClone(prev)
            newState[target.name] =
                target.type === 'checkbox' ? ((target as HTMLInputElement).checked ? 1 : 0) : target.value

            return newState
        })
    }

    useEffect(() => {
        const listenToWindow = (event: MouseEvent) => {
            if (event.target === modalRef.current) {
                closeModal()
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    return (
        <div
            ref={modalRef}
            className='fixed z-50 top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center text-sm'
        >
            <div className='bg-bg1 dark:bg-darkbg1 p-6 relative rounded shadow-md w-full max-w-[600px]'>
                <button onClick={closeModal} className='absolute top-1 right-1'>
                    <FaTimes className='text-red dark:text-lightred text-lg' />
                </button>
                <h2 className='text-lg font-bold mb-4'>Create New Roadmap</h2>
                <div className='flex flex-col gap-1'>
                    <div className='flex gap-1'>
                        <Input
                            className='text-sm'
                            label='Title'
                            type='text'
                            id='title'
                            name='title'
                            value={roadmap.title}
                            onChange={handleChange}
                        />
                        <div className='flex flex-col gap-1 items-center'>
                            <label htmlFor='pinned' className='text-xs font-bold leading-none'>
                                Pinned
                            </label>
                            <input
                                style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                                className='mt-[5px]'
                                type='checkbox'
                                id='pinned'
                                name='pinned'
                                checked={roadmap.pinned === 1}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='flex flex-col gap-1 items-center'>
                            <label htmlFor='private' className='text-xs font-bold leading-none'>
                                Private
                            </label>
                            <input
                                style={settings.theme === 'dark' ? { colorScheme: 'dark' } : {}}
                                className='mt-[5px]'
                                type='checkbox'
                                id='private'
                                name='private'
                                checked={roadmap.private === 1}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className='flex gap-1'>
                        <Select
                            className='text-sm py-[5.5px]'
                            label='Status'
                            id='status'
                            name='status'
                            value={roadmap.status}
                            onChange={handleChange}
                        >
                            <option value=''>Select a status</option>
                            {TASK_STATUSES.map((status) => (
                                <option value={status}>{status}</option>
                            ))}
                        </Select>
                        <Input
                            className='text-sm'
                            label='Start Date'
                            type='date'
                            id='start_date'
                            name='start_date'
                            value={roadmap.start_date}
                            onChange={handleChange}
                        />
                        <Input
                            className='text-sm'
                            label='Target Date'
                            type='date'
                            id='target_date'
                            name='target_date'
                            value={roadmap.target_date}
                            onChange={handleChange}
                        />
                    </div>
                    <Textarea
                        className='text-sm w-full max-h-[100px] leading-1 bg-lightgrey border-0 focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                        label='Description'
                        id='description'
                        name='description'
                        value={roadmap.description}
                        onChange={handleChange}
                    />
                    <label className='text-xs font-bold uppercase leading-none text-darkgrey dark:text-offwhite'>
                        Roadmap Members
                    </label>
                    <div className='flex gap-1 flex-wrap'>
                        {roadmap.associations.map((association: any) => (
                            <div className='flex items-center gap-2 p-1 rounded border border-lightgrey dark:border-darkgrey'>
                                <span>{association.readable_name}</span>
                                <button onClick={() => toggleAssociations(association)}>
                                    <FaTimes />
                                </button>
                            </div>
                        ))}
                    </div>
                    <Button
                        className='w-fit block text-sm h-fit p-1 dark:border-darkgrey'
                        variant='outline'
                        onClick={() => setShowAssignments(true)}
                    >
                        + Add / Edit Members
                    </Button>
                </div>
                <Button
                    className='mx-auto block mt-4 text-sm dark:border-darkgrey'
                    variant={'outline'}
                    onClick={handleCreate}
                >
                    Create Roadmap
                </Button>
            </div>
            {showAssignments && (
                <RoadmapAssignmentsModal
                    closeModal={() => setShowAssignments(false)}
                    associations={roadmap.associations}
                    toggleAssociation={toggleAssociations}
                />
            )}
        </div>
    )
}
