import { useEffect, useState } from 'react'
import { sortByAlphanumeric } from '../../helpers'
import { renderOrderTimelineEvent } from '../../orderHubScreen/orderHubScreen.helpers'
import { TaskCreateInit, TaskEditInit, TaskTimelineEventV2 } from '../tasks.types'
import { UserInit } from '../../users/users.types'
import { OrderInit, OrderNoteInit, OrderTimelineEvent } from '../../orders/orders.types'
import NewNoteField from '../../orders/components/NewNoteField'
import { EmailInit } from '../../emails/email.types'
import Email from '../../emails/Email'
import TaskPublicTimelineEvent from './TaskPublicTimelineEvent'

type EventsProps = {
    events: (OrderTimelineEvent | OrderNoteInit | TaskTimelineEventV2 | EmailInit)[]
    userList: UserInit[]
    task: any
}

export function TaskScreenEvents({ events, userList, task }: EventsProps) {
    const savedActivityFilter: any =
        localStorage?.getItem('taskScreenEventsFilter') !== null
            ? localStorage?.getItem('taskScreenEventsFilter')?.split(',')
            : ['task', 'email', 'task [all]']
    const [activity, setActivity] = useState<string[]>(savedActivityFilter)
    const [sortedEvents, setSortedEvents] = useState([])
    const [filteredEvents, setFilteredEvents] = useState([])

    const typeChecker: any = {
        email: {
            check: (e: EmailInit) => isEmail(e),
        },
        task: {
            check: (e: TaskTimelineEventV2) => isTaskTimelineEvent(e) && !e.message.includes('edited this task'),
        },
        'task [all]': {
            check: (e: TaskTimelineEventV2) => isTaskTimelineEvent(e),
        },
    }

    const normalizedProperties = ['normalized_created_at', 'normalized_event_index']
    const normalize = (e: any, created_at: Date) => {
        return { ...e, normalized_created_at: created_at }
    }

    const isTaskTimelineEvent = (e: any) => e.normalized_event_type === 'Task Events Public'
    const isEmail = (e: any) => e.normalized_event_type === 'Email'
    useEffect(() => {
        const taskEvents = events.filter((e) => isTaskTimelineEvent(e)) as TaskTimelineEventV2[]
        const emails = events.filter((e) => isEmail(e)) as EmailInit[]
        const normalizedEvents: any = [
            ...taskEvents.map((e) => normalize(e, new Date(e.created_at + 'Z'))),
            ...emails.map((e) => normalize(e, e.created_at)),
        ]
        normalizedEvents.sort((a: any, b: any) =>
            sortByAlphanumeric(
                a.normalized_created_at?.getTime() || Infinity,
                b.normalized_created_at?.getTime() || Infinity
            )
        )
        normalizedEvents.forEach((e: any, i: number) => (e.normalized_event_index = i + 1))
        setSortedEvents(normalizedEvents)
    }, [events])

    useEffect(() => {
        if (activity.includes('all')) setActivity(Object.keys(typeChecker))
    }, [activity])
    useEffect(() => {
        let filteredEvents = sortedEvents.filter((e) => activity.some((type: any) => typeChecker[type].check(e)))
        setFilteredEvents(filteredEvents)
    }, [sortedEvents, activity])

    function FilterOptionBubble({ option, filter, setFilter }: any) {
        return (
            <div
                className={`relative whitespace-nowrap text-black bg-grey dark:bg-darkgrey ${
                    filter.includes(option) && '!bg-blue text-offwhite'
                } p-[4px] rounded cursor-pointer`}
                onClick={() => {
                    localStorage.setItem('taskScreenEventsFilter', option)
                    setFilter([option])
                }}
            >
                <button className='capitalize'>{option}</button>
            </div>
        )
    }
    const timeline_eventsList: any = []
    filteredEvents.map((event) => {
        if (isTaskTimelineEvent(event)) {
            timeline_eventsList.push(event)
        }
    })
    const indexArr = timeline_eventsList.reverse().map((e: any) => e.id)

    return (
        <div className='grid gap-[8px] dark:text-offwhite'>
            <div className='grid gap-[8px] bg-white dark:bg-darkaccent p-[16px] rounded shadow-small'>
                <div className='flex gap-[8px] text-[14px]'>
                    <h3 className='text-[16px] font-semibold'>Activity:</h3>
                    <div className='flex flex-col gap-[4px]'>
                        <div className='flex gap-[4px] flex-wrap'>
                            <button
                                className='text-fire font-semibold border-[2px] border-fire rounded py-[2px] px-[8px]'
                                onClick={() => {
                                    localStorage.setItem('taskScreenEventsFilter', Object.keys(typeChecker).join(','))
                                    setActivity(Object.keys(typeChecker))
                                }}
                            >
                                All
                            </button>
                            {Object.keys(typeChecker).map((o, i) => (
                                <FilterOptionBubble
                                    key={`order__filter-option${i}`}
                                    option={o}
                                    filter={activity}
                                    setFilter={setActivity}
                                />
                            ))}
                        </div>
                    </div>
                </div>

                {/* NEW TASK COMMENT */}

                <div className='grid gap-[8px] border-l-[1px] border-grey dark:border-darkgrey'>
                    {filteredEvents
                        .map((e: any, i) => (
                            <div key={`order-event__${i}`} className='flex gap-[0] items-center'>
                                <div className='relative left-[-14px] flex items-center justify-center text-[12px] min-w-[28px] min-h-[28px] border-[4px] border-white dark:border-darkaccent bg-white dark:bg-darkaccent basis-[1]'>
                                    <div className='absolute min-w-[20px] min-h-[20px] bg-white dark:bg-darkaccent z-index-1'>
                                        <span className='block text-center font-semibold min-w-[20px] min-h-[20px] bg-white dark:bg-darkaccent border-[1px] border-grey dark:border-accent rounded-full px-[2px]'>
                                            {e.normalized_event_index}
                                        </span>
                                    </div>
                                    <div className='absolute left-[20px] bg-grey dark:bg-accent h-[1px] w-[12px]' />
                                </div>

                                {isTaskTimelineEvent(e) && (
                                    <TaskPublicTimelineEvent
                                        task_type={task.task_type}
                                        tasks={[task]}
                                        event={e}
                                        events={timeline_eventsList}
                                        indexArr={indexArr}
                                        user={userList.find((u) => u.id === e.user_id)}
                                    />
                                )}
                                {isEmail(e) && <Email email={e} attachments={[]} refetchAttachments={''} />}
                            </div>
                        ))
                        .reverse()}
                </div>
            </div>
        </div>
    )
}
