import { Dispatch, SetStateAction, useState } from 'react'
import Input from '../../procurement/components/Input'
import { FaTimes } from 'react-icons/fa'
import Textarea from '../../procurement/components/Textarea'
import Select from '../../procurement/components/Select'
import { useAllUsersQuery } from '../../users/api/useQueries'
import { UserInit } from '../../users/users.types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { vFetch } from '../../helpers'
import { useProjects } from '../api/useQueries'

export default function CreateRoadmap({
    setQuickModal,
}: {
    setQuickModal: Dispatch<SetStateAction<undefined | string>>
}) {
    const defaultRoadmap = {
        title: '',
        description: '',
        pinned: '',
    }
    const [roadmap, setRoadmap] = useState({ ...defaultRoadmap })
    const allUsersQuery = useAllUsersQuery()
    const users: UserInit[] = allUsersQuery?.data?.users || []
    const projectsQuery = useProjects()
    const projects = projectsQuery?.data || []

    const queryClient = useQueryClient()
    const createRoadmapMutation = useMutation({
        mutationFn: () =>
            vFetch('/v1/development/roadmaps', {
                method: 'POST',
                body: JSON.stringify({
                    ...roadmap,
                    projects: [],
                }),
            }),
        onSuccess: () => {
            setQuickModal(undefined)
            setRoadmap({ ...defaultRoadmap })
            return queryClient.invalidateQueries({ queryKey: ['development', 'roadmaps'] })
        },
    })

    const handleCreateRoadmap = async () => {
        createRoadmapMutation.mutate()
    }

    return (
        <div className='fixed min-w-[500px] pb-4 bg-white dark:bg-darkaccent z-50 bottom-0 left-[50%] translate-x-[-50%] rounded border border-darkgrey border-b-0 rounded-b-none'>
            <div className='p-2 rounded flex justify-between items-center bg-lightgrey dark:bg-darkness'>
                <h3 className='font-bold text-[14px] leading-tight'>New Roadmap</h3>
                <button onClick={() => setQuickModal(undefined)} className=''>
                    <FaTimes className='text-red dark:text-lightred' />
                </button>
            </div>
            <div className='flex flex-col gap-2 p-4'>
                <Input
                    label='Title'
                    id='title'
                    name='title'
                    onChange={({ target }) => setRoadmap({ ...roadmap, [target.name]: target.value })}
                    type='text'
                    autoFocus={true}
                    value={roadmap.title}
                />
                <Textarea
                    label='Description'
                    id='description'
                    name='description'
                    onChange={({ target }) => setRoadmap({ ...roadmap, [target.name]: target.value })}
                    value={roadmap.description}
                />
                <div className='flex gap-2'>
                    <Select
                        label='Projects'
                        id='projects'
                        name='projects'
                        onChange={({ target }) => setRoadmap({ ...roadmap, [target.name]: target.value })}
                        value={''}
                    >
                        <option value=''>No projects</option>
                        {projects.map((project: any) => (
                            <option value={project.id}>{project.title}</option>
                        ))}
                    </Select>
                    <Input
                        label='Pinned'
                        id='pinned'
                        name='pinned'
                        onChange={({ target }) =>
                            setRoadmap({ ...roadmap, [target.name]: (target as HTMLInputElement).checked })
                        }
                        type='checkbox'
                        className="appearance-none font-bold text-[14px] flex items-center justify-center relative w-full h-8 after:content-['Unpinned'] checked:after:content-['Pinned'] checked:!bg-blue checked:!text-white dark:checked:!bg-accent dark:checked:!text-darkaccent"
                        value={roadmap.pinned}
                    />
                </div>
            </div>
            <button
                onClick={handleCreateRoadmap}
                className='block h-fit mx-auto bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold py-1 px-2 leading-tight rounded'
            >
                Create Roadmap
            </button>
        </div>
    )
}
