import { CiEdit } from 'react-icons/ci'
import { FaTrashCan } from 'react-icons/fa6'
import { vFetch } from '../../helpers'
import { MdEdit } from 'react-icons/md'
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export default function EditOptions({
    setShowEditButton,
    setView,
    setEditQuestion,
    selectedQuestion,
    getQuestions,
    // handleDeleteQuestion,
}: {
    setShowEditButton: any
    setView: any
    setEditQuestion: any
    selectedQuestion: any
    getQuestions: any
    // handleDeleteQuestion: any
}) {
    const [confirmCancel, setConfirmCancel] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    const handleDeleteAttempt = () => {
        setConfirmCancel(true)
    }
    function handleCancel() {
        setShowEditButton(true)
        setConfirmCancel(false)
    }
    function useDeleteQuestion() {
        const queryClient = useQueryClient()
        return useMutation({
            mutationFn: ({ id }: { id: any }) => {
                return vFetch('/v1/knowledge/questions', {
                    method: 'DELETE',
                    body: JSON.stringify({ id }),
                })
            },

            onSuccess: () => {
                return queryClient.invalidateQueries({
                    queryKey: ['knowledge', 'questions'],
                })
            },
            onSettled: () => {},
        })
    }
    const deleteQuestion = useDeleteQuestion()

    const handleDeleteQuestion = (id: any) => {
        deleteQuestion.mutate(
            { id },
            {
                onSuccess: () => {
                    setSearchParams((prev: any) => {
                        prev.delete('questionID', `${id}`)
                        return prev
                    })
                    setView('qna')
                },
            }
        )
    }

    return (
        <>
            {!confirmCancel && (
                <div
                    className='absolute top-[110%] right-2 flex flex-col shrink-0 border shadow-md text-sm
         dark:border-darkgrey  whitespace-nowrap text-darkgrey dark:text-white bg-white dark:bg-darkbg1 rounded '
                >
                    <button
                        onClick={() => setEditQuestion(true)}
                        value='Edit'
                        className='flex gap-2 items-center dark:text-offwhite text-darkaccent m-2'
                    >
                        <MdEdit className='w-[20px]' />
                        {/* <CiEdit className='w-[20px] h-[20px]' /> */}
                        Edit Question
                    </button>
                    <button
                        value='Delete'
                        onClick={handleDeleteAttempt}
                        className='flex gap-2 items-center mb-2 mx-2 text-red/75 dark:text-offwhite'
                    >
                        <FaTrashCan className='w-[20px] text-red/75 dark:text-offwhite text-sm' />
                        Delete Question
                    </button>
                </div>
            )}
            {confirmCancel && (
                <div className='absolute z-10 items-center right-2 top-7 flex flex-col shrink-0 border shadow-md text-sm dark:border-darkgrey  whitespace-nowrap text-darkgrey dark:text-white bg-white dark:bg-darkbg1 rounded '>
                    <p className='flex gap-2 items-center dark:text-offwhite text-darkaccent m-2'>Are you sure?</p>
                    <button
                        value='Yes - Delete'
                        onClick={() => handleDeleteQuestion(selectedQuestion.id)}
                        className='p-1 px-[22px] rounded flex gap-2 items-center mb-1.5 mx-2 text-white dark:text-offwhite bg-red/75 dark:bg-lightred'
                    >
                        Yes
                    </button>
                    <button
                        value='No - Cancel'
                        onClick={handleCancel}
                        className='p-1 px-6 rounded flex gap-2 items-center mb-2 mx-2 text-white dark:text-offwhite bg-grey'
                    >
                        No
                    </button>
                </div>
            )}
        </>
    )
}
