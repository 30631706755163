import { addDays, differenceInMinutes } from 'date-fns'
import { TimesheetEntryType } from 'timesheets/Timesheets.types'

export const buildWeekArrayFromEntries = (
    weekStartDate: Date,
    entries: TimesheetEntryType[] = []
): [string, TimesheetEntryType[], Date][] => {
    const map: { [key: number]: TimesheetEntryType[] } = {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
    }

    for (const entry of entries) {
        const day = new Date(entry.starts_on).getDay()
        map[day].push(entry)
    }

    return [
        ['Monday', map[1], weekStartDate],
        ['Tuesday', map[2], addDays(weekStartDate, 1)],
        ['Wednesday', map[3], addDays(weekStartDate, 2)],
        ['Thursday', map[4], addDays(weekStartDate, 3)],
        ['Friday', map[5], addDays(weekStartDate, 4)],
        ['Saturday', map[6], addDays(weekStartDate, 5)],
        ['Sunday', map[0], addDays(weekStartDate, 6)],
    ]
}

export const getTotalsFromEntries = (entries: TimesheetEntryType[]) => {
    const totals = {
        normal: 0,
        overtime: 0,
    }
    for (const entry of entries) {
        totals[entry.type as keyof typeof totals] += differenceInMinutes(entry.ends_on, entry.starts_on)
    }

    return totals
}
