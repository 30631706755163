import { useEffect, useRef, useState } from 'react'
import ReactDatalist from '../custom_components/ReactDatalist'
import { sendToast, vFetch } from '../helpers'
import {
    VendorContactEditInit,
    VendorContactsMap,
    VendorInit,
    defaultVendor,
    defaultVendorContact,
} from './vendors.types'
import VendorContactFields from './components/VendorContactFields'
import CloseButton from '../custom_components/CloseButton'
import MoreActions from '../processingTimes/components/MoreActions'
import { FaBars, FaSyncAlt } from 'react-icons/fa'

type EditVendorProps = {
    contactTypes: string[]
    vendorsInfo: VendorContactsMap
    vendor: VendorInit
    setVendorToEdit: Function
    refreshVendorsList: Function
}
export default function EditVendor({
    contactTypes,
    vendorsInfo,
    vendor,
    setVendorToEdit,
    refreshVendorsList,
}: EditVendorProps) {
    const outer: any = useRef()
    const deleteOuter: any = useRef()

    const [newVendor, setNewVendor] = useState(vendor)
    const [oldVendor, setOldVendor] = useState(newVendor)
    const [edited, setEdited] = useState(false)
    const [deleteVendor, setDeleteVendor] = useState(false)
    const [contactsArray, setContactsArray] = useState(
        JSON.stringify(vendor) === JSON.stringify(defaultVendor) ? [defaultVendorContact] : []
    )

    useEffect(() => {
        if (JSON.stringify(newVendor) !== JSON.stringify(oldVendor)) setEdited(true)
        else setEdited(false)
    }, [newVendor])

    function refreshAndClose() {
        refreshVendorsList()
        setVendorToEdit(null)
    }

    function handleDuplicateVendor() {
        vFetch(`/v1/vendors/duplicate`, {
            method: 'POST',
            body: JSON.stringify({ vendor }),
            cb: (res: any) => {
                if (res.success) {
                    refreshAndClose()
                }
            },
        })
    }

    function handleSubmit() {
        vFetch(`/v1/vendors`, {
            method: JSON.stringify(vendor) === JSON.stringify(defaultVendor) ? 'POST' : 'PUT',
            body: JSON.stringify({ vendor: newVendor, oldVendor: vendor }),
            cb: (res: any) => {
                if (res.success && contactsArray.length > 0) {
                    const contactsToSubmit = contactsArray.map((c) => {
                        return { ...c, vendor: newVendor.name }
                    })
                    vFetch(`/v1/vendors/contacts`, {
                        method: 'POST',
                        body: JSON.stringify({ contacts: contactsToSubmit }),
                        cb: (contactRes: any) => {
                            if (contactRes.success) return refreshAndClose()
                        },
                    })
                } else if (res.success) return refreshAndClose()
            },
        })
        setVendorToEdit(null)
    }
    function handleDelete() {
        vFetch(`/v1/vendors`, {
            method: 'DELETE',
            body: JSON.stringify({ vendor: vendor }),
            cb: (res: any) => {
                if (res.success) return refreshAndClose()
            },
        })
    }
    function handleVendorNameChange(clicked: string, event: any) {
        if (vendorsInfo[clicked] && !event && JSON.stringify(vendor) === JSON.stringify(defaultVendor)) {
            setVendorToEdit(vendorsInfo[clicked])
            setNewVendor(vendorsInfo[clicked])
            setOldVendor(vendorsInfo[clicked])
            setContactsArray([])
        } else {
            setNewVendor({ ...newVendor, name: clicked })
        }
    }
    function canSubmit() {
        if (
            JSON.stringify(vendor) === JSON.stringify(defaultVendor) &&
            contactsArray.every((c) => JSON.stringify(c) !== JSON.stringify(defaultVendorContact))
        )
            return true
        return JSON.stringify(vendor) !== JSON.stringify(defaultVendor)
    }
    const options = [
        {
            displayText: `Duplicate Vendor`,
            icon: FaSyncAlt,
            action: handleDuplicateVendor,
        },
    ]

    const labelStyle = 'font-bai text-[12px] font-bold text-[#4a4a4a] uppercase dark:text-offwhite'
    const inputStyle =
        'block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'

    return (
        <div
            className='fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[rgba(0,0,0,0.5)] z-50'
            ref={outer}
            onMouseDown={({ target }) => {
                if (outer.current === target) setVendorToEdit(null)
            }}
        >
            <div className='relative flex flex-col bg-white dark:bg-darkaccent dark:text-offwhite 0 rounded p-[16px] max-w-[1200px] h-[90%] w-[90%]'>
                <div className='absolute top-1 right-14 z-10'>
                    <MoreActions options={options} displayContent={{ icon: FaBars }} />
                </div>
                <CloseButton onClick={() => setVendorToEdit(null)} />
                <div className='flex w-full h-[calc(100%-64px)]'>
                    <div className='relative w-full flex flex-col h-full'>
                        <div className='p-[16px] py-[8px] bg-white dark:bg-darkaccent dark:text-offwhite rounded'>
                            <h2 className='text-[24px] font-semibold dark:text-offwhite capitalize'>
                                {JSON.stringify(vendor) !== JSON.stringify(defaultVendor)
                                    ? `Edit ${vendor.name || ''}`
                                    : 'Create Vendor'}
                            </h2>
                        </div>
                        <div className='px-[16px] pb-[8px] h-full overflow-auto'>
                            {newVendor.name !== oldVendor.name &&
                                JSON.stringify(vendor) !== JSON.stringify(defaultVendor) && (
                                    <p className='w-[100%] text-red dark:text-fire'>
                                        Changing the vendor name will also change {vendor.name} contacts' associated
                                        vendor name.
                                    </p>
                                )}
                            {newVendor.name !== oldVendor.name &&
                                JSON.stringify(vendor) !== JSON.stringify(defaultVendor) &&
                                vendorsInfo[newVendor.name as keyof VendorContactsMap] && (
                                    <p className='w-[100%] text-red dark:text-fire'>
                                        Changing the name to an existing vendor's name will combine any existing
                                        contacts between the two vendors.
                                    </p>
                                )}
                            <div className='grid gap-[8px]'>
                                <div>
                                    <label className={labelStyle}>Vendor (searchable)</label>
                                    <ReactDatalist
                                        value={newVendor.name}
                                        options={Object.keys(vendorsInfo)}
                                        setSelected={(clicked: any, e: any) => handleVendorNameChange(clicked, e)}
                                        onBlurHandler={({ target }: any) => {
                                            if (
                                                JSON.stringify(vendor) === JSON.stringify(defaultVendor) &&
                                                vendorsInfo[target.value]
                                            )
                                                sendToast({
                                                    message:
                                                        'This name is already in use by another vendor. If you want to edit the existing vendor, please click it in the dropdown. Otherwise, please enter a different name.',
                                                })
                                        }}
                                    />
                                </div>
                                <div>
                                    <label className={labelStyle}>Order Submission Contact / URL</label>
                                    <input
                                        value={newVendor.order_submission_url}
                                        className={inputStyle}
                                        onChange={({ target }) =>
                                            setNewVendor({ ...newVendor, order_submission_url: target.value })
                                        }
                                    />
                                </div>
                                <div>
                                    <label className={labelStyle}>Order Submission Instructions</label>
                                    <textarea
                                        value={newVendor.order_submission_info}
                                        className={`${inputStyle} h-[120px] !max-w-none`}
                                        onChange={({ target }) =>
                                            setNewVendor({ ...newVendor, order_submission_info: target.value })
                                        }
                                    />
                                </div>
                                <div>
                                    <label className={labelStyle}>Payment Information</label>
                                    <textarea
                                        value={newVendor.payment_info}
                                        className={`${inputStyle} h-[120px] !max-w-none`}
                                        onChange={({ target }) =>
                                            setNewVendor({ ...newVendor, payment_info: target.value })
                                        }
                                    />
                                </div>
                                <div>
                                    <label className={labelStyle}>Return Instructions</label>
                                    <textarea
                                        value={newVendor.return_instructions}
                                        className={`${inputStyle} h-[120px] !max-w-none`}
                                        onChange={({ target }) =>
                                            setNewVendor({
                                                ...newVendor,
                                                return_instructions: target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    <div>
                                        <h3 className='text-[20px] tracking-[2px] font-[300] dark:text-offwhite uppercase'>
                                            Return Policy
                                        </h3>
                                        <div>
                                            <label className={labelStyle}>Buyer's Remorse</label>
                                            <textarea
                                                value={newVendor.return_policy_buyers_remorse}
                                                className={`${inputStyle} h-[120px] !max-w-none`}
                                                onChange={({ target }) =>
                                                    setNewVendor({
                                                        ...newVendor,
                                                        return_policy_buyers_remorse: target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div>
                                            <label className={labelStyle}>Defective on Arrival</label>
                                            <textarea
                                                value={newVendor.return_policy_doa}
                                                className={`${inputStyle} h-[120px] !max-w-none`}
                                                onChange={({ target }) =>
                                                    setNewVendor({
                                                        ...newVendor,
                                                        return_policy_doa: target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div>
                                            <label className={labelStyle}>Warranty</label>
                                            <textarea
                                                value={newVendor.return_policy_warranty_repair}
                                                className={`${inputStyle} h-[120px] !max-w-none`}
                                                onChange={({ target }) =>
                                                    setNewVendor({
                                                        ...newVendor,
                                                        return_policy_warranty_repair: target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div>
                                            <label className={labelStyle}>Additional Info</label>
                                            <textarea
                                                value={newVendor.additional_info}
                                                className={`${inputStyle} h-[120px] !max-w-none`}
                                                onChange={({ target }) =>
                                                    setNewVendor({
                                                        ...newVendor,
                                                        additional_info: target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {JSON.stringify(vendor) === JSON.stringify(defaultVendor) && (
                        <div className='w-[40%] shrink-0 flex flex-col h-full'>
                            <div className='p-[16px] py-[8px] bg-white dark:bg-darkaccent dark:text-offwhite z-5'>
                                <h2 className='text-[24px] font-semibold dark:text-offwhite capitalize'>
                                    Vendor Contacts
                                </h2>
                            </div>
                            <div className='px-[16px] h-full overflow-auto'>
                                <VendorContactFields
                                    contactTypes={contactTypes}
                                    contact={defaultVendorContact}
                                    contactsArray={contactsArray}
                                    setContactsArray={setContactsArray}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className='shrink-0 h-fit relative flex justify-center py-[16px]'>
                    <button
                        className={`${
                            edited && canSubmit() ? '' : 'opacity-[0.2] !bg-grey cursor-auto'
                        } duration-200 p-[8px] bg-blue text-white font-semibold dark:bg-accent dark:text-black rounded shadow-small`}
                        onClick={() => {
                            edited && canSubmit()
                                ? handleSubmit()
                                : sendToast({
                                      message:
                                          'Please fill out both the vendor and vendor contact form before clicking submit.',
                                  })
                        }}
                    >
                        Submit Vendor
                    </button>
                    {JSON.stringify(vendor) !== JSON.stringify(defaultVendor) && (
                        <button
                            className='absolute right-[16px] w-[100px] p-[8px] text-white font-semibold bg-red hover:bg-red/75 rounded shadow-small'
                            onClick={() => setDeleteVendor(true)}
                        >
                            Delete
                        </button>
                    )}
                </div>
            </div>
            {deleteVendor && (
                <div className='flex absolute top-0 w-[100%] h-[100%] bg-black/50 z-50'>
                    <div
                        className='flex w-[100%] h-[100%] justify-center items-center'
                        ref={deleteOuter}
                        onMouseDown={({ target }) => {
                            if (deleteOuter.current === target) setDeleteVendor(false)
                        }}
                    >
                        <div className='relative min-w-[500px] bg-white dark:bg-darkaccent dark:text-offwhite p-[16px] rounded'>
                            <h2 className='text-[24px] font-semibold dark:text-offwhite capitalize mb-[8px]'>
                                Are you sure you want to delete this contact?
                            </h2>
                            <div className='p-[16px] max-h-[500px] overflow-y-auto'>
                                {Object.keys(vendor).map((key) => (
                                    <p>
                                        {key.replaceAll('_', ' ')}:{' '}
                                        <strong>
                                            {Array.isArray(vendor[key as keyof VendorInit])
                                                ? (vendor[key as keyof VendorInit] as VendorContactEditInit[]).map(
                                                      (c) => (
                                                          <ul>
                                                              {c.name}
                                                              {': {'}
                                                              {Object.keys(c).map((k) => (
                                                                  <li className='ml-[8px]'>{`${k}: ${
                                                                      c[k as keyof VendorContactEditInit]
                                                                  }`}</li>
                                                              ))}
                                                              {'},'}
                                                          </ul>
                                                      )
                                                  )
                                                : (vendor[key as keyof VendorInit] as string | number)}
                                        </strong>
                                    </p>
                                ))}
                            </div>
                            <div className='flex justify-around'>
                                <button
                                    className='w-[100px] p-[8px] text-white font-semibold bg-red hover:bg-red/75 rounded shadow-small'
                                    onClick={handleDelete}
                                >
                                    Delete
                                </button>
                                <button
                                    className='w-[100px] p-[8px] text-white font-semibold bg-blue hover:bg-blue/75 dark:bg-accent dark:hover:bg-accent/75 dark:text-black rounded shadow-small'
                                    onClick={() => setDeleteVendor(false)}
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
