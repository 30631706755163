import { useState } from 'react'
import MyTasks from './components/MyTasks'
import PinnedProjects from './components/PinnedProjects'
import PinnedRoadmaps from './components/PinnedRoadmaps'
import CreateTask from './quickModals/CreateTask'
import CreateProject from './quickModals/CreateProject'
import CreateRoadmap from './quickModals/CreateRoadmap'
import { useSelector } from 'react-redux'
import Tickets from './components/Tickets'
import CreateTicket from './quickModals/CreateTicket'

export default function DevelopmentScreen() {
    const user = useSelector((state: any) => state.user)
    const [quickModal, setQuickModal] = useState<undefined | string>()

    return (
        <div className='pb-32'>
            <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize w-[100%] mb-4'>Development</h1>
            {user.roles?.includes('developer') && (
                <div className='mb-4'>
                    <MyTasks setQuickModal={setQuickModal} />
                </div>
            )}
            <div className='flex gap-4 mb-4'>
                <Tickets triage={true} setQuickModal={setQuickModal} />
                <Tickets setQuickModal={setQuickModal} />
            </div>
            <div className='flex flex-col gap-4 mb-4'>
                <PinnedRoadmaps setQuickModal={setQuickModal} />
                <PinnedProjects setQuickModal={setQuickModal} />
            </div>
            {quickModal === 'task' && <CreateTask setQuickModal={setQuickModal} />}
            {quickModal === 'project' && <CreateProject setQuickModal={setQuickModal} />}
            {quickModal === 'roadmap' && <CreateRoadmap setQuickModal={setQuickModal} />}
            {quickModal === 'ticket' && <CreateTicket setQuickModal={setQuickModal} />}
        </div>
    )
}
