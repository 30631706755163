import { SkeletonBasic } from '../../../custom_components/component_Basics/SkeletonBasic'

export default function CompanyV2Skeleton() {
    return (
        <>
            <div className='text-[14px] grid grid-cols-[64px_200px_74px_1fr_1fr_1fr_1fr_100px_100px] items-center border-b border-lightgrey dark:text-darktext1 dark:border-darkgrey hover:bg-lightgrey dark:hover:bg-darkness'>
                <div className='p-[8px]'>
                    <SkeletonBasic className='p-[4px]  w-[48px] h-[48px] object-contain rounded-full' />
                </div>
                <SkeletonBasic className='p-[8px] w-2/3'>
                    <SkeletonBasic className='font-bold  text-left w-full'></SkeletonBasic>
                </SkeletonBasic>
                <div></div>
                <SkeletonBasic className='p-[8px] w-2/3'>
                    <SkeletonBasic className='font-bold  text-left w-full'></SkeletonBasic>
                </SkeletonBasic>
                <SkeletonBasic className='p-[8px] w-2/3'>
                    <SkeletonBasic className='font-bold  text-left w-full'></SkeletonBasic>
                </SkeletonBasic>
                <SkeletonBasic className='p-[8px] w-2/3'>
                    <SkeletonBasic className='font-bold  text-left w-full'></SkeletonBasic>
                </SkeletonBasic>
                <div></div>
                <div></div>
                <div className='flex gap-[8px] justify-center items-center'>
                    <button className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'>
                        <SkeletonBasic className='text-darkgrey dark:text-darktext1  p-[6px] w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite' />
                    </button>
                    <button className='w-[40px] flex flex-col items-center gap-[4px] relative hover:scale-[1.05] transition-all'>
                        <SkeletonBasic className='text-darkgrey dark:text-darktext1  p-[6px] w-[28px] h-[28px] rounded-full border border-darkgrey dark:border-offwhite' />
                    </button>
                </div>
            </div>
        </>
    )
}
