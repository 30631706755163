import { vFetch } from "../../helpers"

export const createTask = async (task: { [key: string]: any }) => {
    return await vFetch('/v1/development/tasks', {
        method: 'POST',
        body: JSON.stringify(task),
    })
}

export const getTasks = async (tickets: boolean) => {
    return await vFetch(`/v1/development/tasks${tickets ? '?is_ticket=true' : ''}`, {
        cb: (res: any) => {
            if (!res.success) {
                return []
            }
            return res.tasks
        },
        catchCb: () => [],
    })
}

export const updateTask = async (taskUpdate: { [key: string]: any }) => {
    return await vFetch('/v1/development/tasks', {
        method: 'PUT',
        body: JSON.stringify(taskUpdate),
    })
}

export const deleteTask = async (taskId: string) => {
    return await vFetch('/v1/development/tasks', {
        method: 'DELETE',
        body: JSON.stringify({id: taskId}),
    })
}

export const getProjects = async () => {
    return await vFetch(`/v1/development/projects`, {
        cb: (res: any) => {
            if (!res.success) {
                return []
            }
            return res.projects
        },
        catchCb: () => [],
    })
}

export const updateProject = async (projectUpdate: { [key: string]: any }) => {
    return await vFetch('/v1/development/projects', {
        method: 'PUT',
        body: JSON.stringify(projectUpdate),
    })
}

export const deleteProject = async (projectId: string) => {
    return await vFetch('/v1/development/projects', {
        method: 'DELETE',
        body: JSON.stringify({id: projectId}),
    })
}

export const getRoadmaps = async () => {
    return await vFetch(`/v1/development/roadmaps`, {
        cb: (res: any) => {
            if (!res.success) {
                return []
            }
            return res.roadmaps
        },
        catchCb: () => [],
    })
}

export const updateRoadmap = async (roadmapUpdate: { [key: string]: any }) => {
    return await vFetch('/v1/development/roadmaps', {
        method: 'PUT',
        body: JSON.stringify(roadmapUpdate),
    })
}

export const deleteRoadmap = async (roadmapId: string) => {
    return await vFetch('/v1/development/roadmaps', {
        method: 'DELETE',
        body: JSON.stringify({id: roadmapId}),
    })
}