import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { vFetch } from '../helpers'
import { OrderInit } from '../orders/orders.types'
import { TaskEditInit } from '../tasks/tasks.types'
import CreateReturnModule from './components/CreateReturnModule'

export default function CreateReturnScreen() {
    // this screen is not used at the moment.
    const { taskId, orderId } = useParams()

    const [task, setTask] = useState<null | TaskEditInit>(null)
    const [order, setOrder] = useState<null | OrderInit>(null)

    return (
        <div className='dark:text-offwhite text-[16px] font-normal'>
            <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>New Return</h1>
            <CreateReturnModule returnTask={task} returnOrder={order} type='return' />
        </div>
    )
}
