import { ExclamationTriangleIcon } from '@radix-ui/react-icons'
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react'
import { BiBarcodeReader } from 'react-icons/bi'
import {
    FaBars,
    FaCaretLeft,
    FaCaretUp,
    FaCrown,
    FaExternalLinkAlt,
    FaLink,
    FaPen,
    FaSyncAlt,
    FaTrash,
} from 'react-icons/fa'
import { MdCancel } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { Button } from '../../custom_components/component_Basics/Button'
import { cn, sendToast } from '../../helpers'
import MoreActions from '../../processingTimes/components/MoreActions'
import LabelWithInfo from '../../productSetupV2/components/LabelWithInfo'
import { setProductPin } from '../../redux/productPin'
import { useAllUsersQuery } from '../../users/api/useQueries'
import { UserInit } from '../../users/users.types'
import {
    useAddComment,
    useCompanyProductTypesQuery,
    useDeleteProduct,
    useDuplicateProduct,
    useSetPrimaryVariant,
    useUpdateProductPricingValues,
    useUpsertProduct,
} from '../api/useQueries'
import { ProcurementContext, ProcurementContextType, ProductWebsocketContext } from '../helpers'
import CompanyProductEditHistory from './CompanyProductEditHistory'
import CompanyProductOptionSetup from './CompanyProductOptionSetup'
import Input from './Input'
import ProductViewers from './ProductViewers'
import Select from './Select'
import Textarea from './Textarea'
import ProductCommentsModal from './modals/ProductCommentsModal'
import { SelectorButton } from 'custom_components/component_Basics/SelectorButton'
import FakeInput from './FakeInput'
import ShippingDetails from './ShippingDetails'

export default function CompanyProduct({
    product: originalProduct,
    setSearch,
    setShowShopifyCreate,
    setSelectedCreateProduct,
    groupByVariants,
    setGroupByVariants,
    allProducts,
}: {
    product: any
    setSearch: any
    setShowShopifyCreate: any
    setSelectedCreateProduct: any
    groupByVariants: boolean
    setGroupByVariants: any
    allProducts: any
}) {
    const weblinksRef = useRef<HTMLDivElement>(null)
    const location = useLocation()
    const dispatch = useDispatch()
    const productTypesRef = useRef<HTMLDivElement>(null)
    const searchRef = useRef<HTMLInputElement>(null)
    const identifiersRef = useRef<HTMLDivElement>(null)
    const shippingDetailsRef = useRef<HTMLDivElement>(null)
    const { selectedCompany } = useContext<ProcurementContextType>(ProcurementContext)
    const user = useSelector((state: any) => state.user)

    const { appState, selectedProductId, setSelectedProductId, pendingEdits, setPendingEdits } =
        useContext<any>(ProductWebsocketContext)
    const [editableFields, setEditableFields] = useState<any>({})
    const [editablePricingValues, setEditablePricingValues] = useState<any>({})

    const [noteExpand, setNoteExpand] = useState(false)
    const [showIdentifiers, setShowIdentifiers] = useState(false)
    const [showShippingDetails, setShowShippingDetails] = useState(false)
    const [showEditHistory, setShowEditHistory] = useState(false)
    const [showOptionSetup, setShowOptionSetup] = useState(false)
    const [showWeblinks, setShowWeblinks] = useState(false)
    const [editWeblinks, setEditWeblinks] = useState(false)
    const [showProductTypes, setShowProductTypes] = useState(false)
    const [productTypeSearch, setProductTypeSearch] = useState('')
    const [allowCustomType, setAllowCustomType] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [showVariantOfSelection, setShowVariantOfSelection] = useState(false)
    const variantGroupingId = searchParams.get('group_id') || null

    const [showCommentModal, setShowCommentModal] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const { mutate: deleteProduct, isPending: deletePending } = useDeleteProduct(selectedCompany.id)

    const { current } = React.useRef<any>({ timer: null })

    const productTypesQuery = useCompanyProductTypesQuery()
    const { productTypes } = productTypesQuery?.data || {}

    const allUsersQuery = useAllUsersQuery()
    const { users }: { users: UserInit[] } = allUsersQuery?.data || {}

    const isEdited = Boolean(Object.keys(editableFields).length)
    const isPricingEdited = Boolean(Object.keys(editablePricingValues).length)

    const upsertProducts = useUpsertProduct(selectedCompany.id, setEditableFields)
    const updateProductPricingValues = useUpdateProductPricingValues(selectedCompany.id)
    const setPrimaryVariant = useSetPrimaryVariant(selectedCompany.id)

    const addComment = useAddComment(selectedCompany?.id)
    const duplicateProduct = useDuplicateProduct(selectedCompany?.id)

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setEditableFields({
            ...editableFields,
            [target.name]: target.value,
        })
    }
    function handleChangeVariantOf(target: any) {
        const [sku, productId] = target.split(',')

        if (productId == product.variant_of) {
            setEditableFields({
                ...editableFields,
                variant_of: 0,
            })
            return
        }

        setEditableFields({
            ...editableFields,
            variant_of: productId,
        })
    }
    const handlePricingValueChange = ({
        target,
    }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setEditablePricingValues({
            ...editablePricingValues,
            [target.name]: target.value,
        })
    }

    const product = upsertProducts.isPending
        ? {
              ...originalProduct,
              ...upsertProducts.variables.products[0],
              cost: originalProduct.cost,
              shipping_fee: originalProduct.shipping_fee,
              list_price: originalProduct.list_price,
          }
        : originalProduct

    function checkForDuplicateSku(): boolean {
        if (!editableFields.sku) {
            return false
        }
        const skuMatch = allProducts
            ?.map((product: any) => product.sku)
            .filter((sku: any) => sku !== product.sku)
            .filter((sku: any) => sku.toLowerCase() === editableFields.sku.toLowerCase())

        return skuMatch.length > 0 ? true : false
    }

    const duplicateSkuFound: boolean = checkForDuplicateSku()

    useEffect(() => {
        if (!Object.keys(editableFields).length) {
            return
        }
        if (editableFields?.sku?.trim() === '') {
            clearTimeout(current.timer)
            return
        }
        if (duplicateSkuFound) {
            clearTimeout(current.timer)
            return
        }
        if (upsertProducts.isPending) {
            clearTimeout(current.timer)
        }
        if (current.timer) clearTimeout(current.timer)
        if (Object.keys(editableFields).includes('lowest_competitor_price')) {
            current.timer = setTimeout(() => {
                current.timer = null
                handleSave()
            }, 2500)
            return
        }
        if (Object.keys(editableFields).includes('notes')) {
            current.timer = setTimeout(() => {
                current.timer = null
                handleSave()
            }, 4000)
            return
        }
        current.timer = setTimeout(() => {
            current.timer = null
            handleSave()
        }, 1000)
    }, [editableFields])

    const options = [
        {
            displayText: `Duplicate Product`,
            icon: FaSyncAlt,
            action: handleDuplicateProduct,
        },
    ]

    function handleDuplicateProduct() {
        duplicateProduct.mutate(
            { product },
            {
                onSuccess: () => {},
            }
        )
    }

    const handleChangeWeblink = (
        { target }: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
        weblinkIndex: number
    ) => {
        let weblinks: any[] = []
        if (!editableFields.weblinks) {
            weblinks = product.weblinks
            setEditableFields((previousState: any) => {
                const newState = structuredClone(previousState)
                newState.weblinks = weblinks
                if (newState.weblinks && (newState.weblinks as any[])[weblinkIndex]) {
                    ;(newState.weblinks as any[])[weblinkIndex] = {
                        ...(newState.weblinks as any[])[weblinkIndex],
                        [target.name]: target.value,
                    }
                }
                return newState
            })
        } else {
            setEditableFields((previousState: any) => {
                const newState = structuredClone(previousState)
                if (newState.weblinks && (newState.weblinks as any[])[weblinkIndex]) {
                    ;(newState.weblinks as any[])[weblinkIndex] = {
                        ...(newState.weblinks as any[])[weblinkIndex],
                        [target.name]: target.value,
                    }
                }
                return newState
            })
        }
    }

    const handleAddWeblink = () => {
        setEditWeblinks(true)
        setEditableFields((previousState: any) => {
            const newState = structuredClone(previousState)
            if (!newState.weblinks) {
                newState.weblinks = product.weblinks
            }
            newState.weblinks?.push({ title: '', link: '' })
            return newState
        })
    }

    const handleRemoveWeblink = (weblinkIndex: number) => {
        let weblinks: any[] = []
        if (!editableFields.weblinks) {
            weblinks = product.weblinks
            setEditableFields((previousState: any) => {
                const newState = structuredClone(previousState)
                newState.weblinks = weblinks
                newState.weblinks?.splice(weblinkIndex, 1)
                return newState
            })
        } else {
            setEditableFields((previousState: any) => {
                const newState = structuredClone(previousState)
                newState.weblinks?.splice(weblinkIndex, 1)
                return newState
            })
        }
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!weblinksRef.current?.contains(e.target as Element)) {
                setShowWeblinks(false)
            }
            if (!identifiersRef.current?.contains(e.target as Element)) {
                setShowIdentifiers(false)
            }
            if (!shippingDetailsRef.current?.contains(e.target as Element)) {
                setShowShippingDetails(false)
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    })

    const handleSave = () => {
        setEditableFields({})
        upsertProducts.mutate(
            {
                products: [
                    {
                        id: product.id,
                        ...editableFields,
                    },
                ],
                company_id: selectedCompany.id,
            },
            {
                onSuccess: () => {
                    setShowDelete(false)
                },
            }
        )
    }
    const handlePricingValueSave = () => {
        if (product.id) {
            addComment.mutate(
                {
                    value: `Cost - $${
                        editablePricingValues?.cost
                            ? parseFloat(editablePricingValues?.cost).toFixed(2)
                            : product?.cost.toFixed(2)
                    } | Shipping Fee - $${
                        editablePricingValues?.shipping_fee
                            ? parseFloat(editablePricingValues?.shipping_fee).toFixed(2)
                            : product?.shipping_fee.toFixed(2)
                    } | List Price - $${
                        editablePricingValues?.list_price
                            ? parseFloat(editablePricingValues?.list_price).toFixed(2)
                            : product?.list_price.toFixed(2)
                    }`,
                    product_id: product.id,
                    company_id: selectedCompany.id,
                },
                {
                    onSuccess: () => {},
                }
            )
        }
        updateProductPricingValues.mutate(
            {
                products: [
                    {
                        id: product.id,
                        ...editablePricingValues,
                    },
                ],
                company_id: selectedCompany.id,
            },
            {
                onSuccess: () => {
                    setShowDelete(false)
                    setEditablePricingValues({})
                    setEditWeblinks(false)
                },
            }
        )
    }

    useEffect(() => {
        if (isEdited || isPricingEdited) {
            setPendingEdits(true)
        } else {
            setPendingEdits(false)
        }
    }, [editableFields, editablePricingValues])

    const toastId = React.useRef<any>(null)

    const filteredProductTypes = productTypes
        ?.filter((pt: any) => pt)
        ?.filter((pt: any) => {
            return pt.toLowerCase().includes(productTypeSearch?.toLowerCase())
        })

    function handleSetPrimaryVariant(event: any): void {
        if (upsertProducts.isPending || isEdited) {
            return
        }
        setPrimaryVariant.mutate(
            {
                products: [
                    {
                        ...product,
                        is_primary_variant: 1,
                    },
                ],
                company_id: selectedCompany.id,
            },
            {
                onSuccess: () => {
                    setShowDelete(false)
                },
            }
        )
    }

    const weblinks = editableFields.weblinks ?? product.weblinks
    const profit = (product.list_price - product.cost - product.shipping_fee).toFixed(2)
    const margin = (((product.list_price - product.cost - product.shipping_fee) / product.list_price) * 100).toFixed(2)
    const profitMarginInsert = `$${profit} (${margin}%)`
    const editableProfit = (
        (editablePricingValues.list_price ?? product.list_price) -
        (editablePricingValues.cost ?? product.cost) -
        (editablePricingValues.shipping_fee ?? product.shipping_fee)
    ).toFixed(2)
    const editableMargin = (
        (((editablePricingValues.list_price ?? product.list_price) -
            (editablePricingValues.cost ?? product.cost) -
            (editablePricingValues.shipping_fee ?? product.shipping_fee)) /
            (editablePricingValues.list_price ?? product.list_price)) *
        100
    ).toFixed(2)
    const editableProfitMarginInsert = `$${editableProfit} (${editableMargin}%)`

    const handlePin = () => {
        dispatch(setProductPin({ id: product.id, sku: product.sku, open: true }))
    }

    const relatedProduct = allProducts.find((relatedProduct: any) => relatedProduct.id === product.variant_of)

    return (
        <div
            onClick={() => {
                if (pendingEdits && selectedProductId !== product.id) {
                    return (toastId.current = sendToast({ message: 'Please Save Pending Changes' }))
                }
                if (
                    appState[product.id] &&
                    appState[product.id].find((userId: string) => userId !== user.id.toString())
                ) {
                    return
                }
                setSelectedProductId(product.id)
            }}
            className={cn(
                pendingEdits && selectedProductId !== product.id && 'opacity-75',
                appState[product.id] &&
                    appState[product.id].find((userId: string) => userId !== user.id.toString()) &&
                    'opacity-60',
                `border rounded-[4px] shadow-small grid grid-cols-[1.5fr_3.25fr_0.75fr_1.5fr_32px] text-[14px] relative overflow-visible
            ${
                selectedProductId === product.id
                    ? 'border-fire/50 dark:border-accent/50'
                    : 'border-lightgrey dark:border-darkgrey'
            } ${deletePending ? 'opacity-[0.5] pointer-events-none' : ''}, ${
                !product.supplier_sku && !product.upc && !product.model_number && 'border-danger dark:border-darkdanger'
            }
            `
            )}
        >
            <div className='absolute top-9 right-[2px]'>
                <ProductViewers product_id={product.id} />
            </div>
            <div
                className={cn(
                    pendingEdits && selectedProductId !== product.id && 'pointer-events-none',
                    appState[product.id] &&
                        appState[product.id].find((userId: string) => userId !== user.id.toString()) &&
                        'pointer-events-none',
                    'flex flex-col gap-[8px] p-[8px]'
                )}
            >
                <div className='flex gap-[8px] items-end relative'>
                    {selectedProductId !== product.id && (
                        <FakeInput value={editableFields.sku ?? product.sku} label='Shopify SKU ' />
                    )}
                    {selectedProductId === product.id && (
                        <Input
                            label={`shopify SKU`}
                            value={editableFields.sku ?? product.sku}
                            id='sku'
                            name='sku'
                            onChange={handleChange}
                            type='text'
                        />
                    )}
                    <button
                        onClick={() => {
                            setShowWeblinks((previousState) => !previousState)
                            setShowIdentifiers(false)
                        }}
                        title='Weblinks'
                        className='mb-[8px]'
                    >
                        {showWeblinks ? (
                            <FaCaretLeft className='w-[20px] h-[20px] text-red dark:text-lightred' />
                        ) : (
                            <FaLink className='w-[16px] h-[16px]' />
                        )}
                    </button>
                    {showWeblinks && (
                        <div
                            ref={weblinksRef}
                            className='p-[8px] border border-lightgrey dark:border-darkgrey rounded-[4px] flex flex-col gap-[8px] absolute top-0 left-[calc(100%-24px)] bg-white dark:bg-darkaccent z-[5] shadow-small w-[300px]'
                        >
                            <div className='dark:text-offwhite uppercase border-b py-2'>
                                <LabelWithInfo
                                    label='Weblinks'
                                    info='Where we are pulling product information'
                                    htmlFor=''
                                />
                            </div>
                            <ul
                                className={`${!editWeblinks ? `ml-[12px] pl-[12px]` : ''} ${
                                    product.weblinks?.length > 0 ? 'my-[8px]' : ''
                                }`}
                            >
                                {weblinks?.map((weblink: any, weblinkIndex: number) => {
                                    if (editWeblinks) {
                                        return (
                                            <li key={`Weblink-${weblinkIndex}`} className='flex gap-[8px] items-end'>
                                                <Input
                                                    label='Title'
                                                    value={weblink.title}
                                                    id='weblink-title'
                                                    name='title'
                                                    onChange={(e) => handleChangeWeblink(e, weblinkIndex)}
                                                    type='text'
                                                />
                                                <Input
                                                    label='Link'
                                                    value={weblink.link}
                                                    id='weblink-link'
                                                    name='link'
                                                    onChange={(e) => handleChangeWeblink(e, weblinkIndex)}
                                                    type='text'
                                                />

                                                <button
                                                    className='block mb-[8px]'
                                                    onClick={() => handleRemoveWeblink(weblinkIndex)}
                                                >
                                                    <FaTrash className='text-[16px] text-red dark:text-lightred' />
                                                </button>
                                            </li>
                                        )
                                    }
                                    return (
                                        <li className='list-disc'>
                                            <a
                                                target='_blank'
                                                key={`Weblink-${weblinkIndex}`}
                                                className='font-bold border-b w-fit flex items-center gap-[8px]'
                                                href={weblink.link}
                                            >
                                                {weblink.title}
                                                <FaExternalLinkAlt />
                                            </a>
                                        </li>
                                    )
                                })}
                            </ul>

                            <div className='flex items-center justify-center gap-[8px]'>
                                <button
                                    onClick={handleAddWeblink}
                                    className='shrink-0 text-[14px] leading-[1] py-[4px] w-fit px-[8px] rounded-[4px] bg-blue dark:bg-fire text-white dark:text-darkaccent font-bold uppercase'
                                >
                                    + Weblink
                                </button>
                                {product.weblinks?.length > 0 && !editWeblinks && (
                                    <button
                                        onClick={() => {
                                            return setEditWeblinks(true)
                                        }}
                                        className='shrink-0 text-[14px] leading-[1] py-[4px] w-fit px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkgrey text-text1 dark:text-darktext1 font-bold uppercase'
                                    >
                                        Edit Weblinks
                                    </button>
                                )}
                                {editWeblinks && (
                                    <button
                                        onClick={() => setEditWeblinks(false)}
                                        className='shrink-0 text-[14px] leading-[1] py-[4px] w-fit px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase'
                                    >
                                        View Weblinks
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                    <div className='flex flex-col gap-1 items-center'>
                        {!product.supplier_sku && !product.upc && !product.model_number && (
                            <div className='group relative z-[4]'>
                                <ExclamationTriangleIcon className='text-danger dark:text-darkdanger self-center' />
                                <div className='hidden group-hover:flex border absolute top-[100%] left-0 w-fit whitespace-nowrap bg-white shadow-small text-sm dark:bg-darkbg1 p-2 rounded-[4px] z-[5] '>
                                    Each product must have supplier sku, upc or model number
                                </div>
                            </div>
                        )}
                        <button
                            onClick={() => {
                                setShowIdentifiers((previousState) => !previousState)
                                setShowWeblinks(false)
                            }}
                            title='Alternate Identifiers'
                            className='mb-[8px]'
                        >
                            {showIdentifiers ? (
                                <FaCaretLeft className='w-[20px] h-[20px] text-red dark:text-lightred' />
                            ) : (
                                <BiBarcodeReader className='w-[20px] h-[20px]' />
                            )}
                        </button>
                    </div>
                    {showIdentifiers && (
                        <div
                            ref={identifiersRef}
                            className='p-[8px] border border-lightgrey dark:border-darkgrey rounded-[4px] flex flex-col gap-[8px] absolute top-0 left-[100%] bg-white dark:bg-darkaccent z-[5] shadow-small w-[400px]'
                        >
                            <Input
                                label='supplier sku'
                                value={editableFields.supplier_sku ?? product.supplier_sku}
                                id='supplier_sku'
                                name='supplier_sku'
                                onChange={handleChange}
                                type='text'
                            />
                            <Input
                                label='upc'
                                value={editableFields.upc ?? product.upc}
                                id='upc'
                                name='upc'
                                onChange={handleChange}
                                type='text'
                            />

                            <Input
                                label='Model Number'
                                value={editableFields.model_number ?? product.model_number}
                                id='model_number'
                                name='model_number'
                                onChange={handleChange}
                                type='text'
                            />
                        </div>
                    )}
                </div>
                <div ref={productTypesRef} className='relative'>
                    {selectedProductId !== product.id && (
                        <FakeInput value={editableFields.product_type ?? product.product_type} label='Product Type' />
                    )}
                    {selectedProductId === product.id && (
                        <Input
                            id='product_type'
                            name='product_type'
                            label='Product Type'
                            value={editableFields.product_type ?? product.product_type}
                            onChange={handleChange}
                            type='text'
                            onclick={() => {
                                setShowProductTypes((previousState) => !previousState)
                                setTimeout(() => {
                                    searchRef.current?.focus()
                                }, 10)
                            }}
                        />
                    )}
                    <button
                        onClick={() => {
                            setShowProductTypes((previousState) => !previousState)
                            setTimeout(() => {
                                searchRef.current?.focus()
                            }, 10)
                        }}
                        className='absolute top-[50%] right-[4px] z-[4]'
                    >
                        {showProductTypes ? (
                            <FaCaretUp className='text-darkgrey dark:text-offwhite' />
                        ) : (
                            <FaPen className='text-blue dark:text-accent' />
                        )}
                    </button>
                    {showProductTypes && (
                        <div className='absolute z-[5] flex flex-col top-[100%] left-0 bg-white dark:bg-darkaccent w-full shadow-small border  border-darkgrey rounded-[4px] max-h-[250px] overflow-auto'>
                            <input
                                ref={searchRef}
                                className='bg-transparent p-[8px] focus:outline-none border-b border-lightgrey dark:border-darkgrey rounded-[4px]'
                                type='text'
                                placeholder='Search'
                                value={productTypeSearch}
                                onChange={({ target }) => setProductTypeSearch(target.value)}
                            />
                            <button
                                onClick={() => {
                                    setAllowCustomType(true)
                                    setShowProductTypes(false)
                                    setEditableFields((previousState: any) => ({
                                        ...previousState,
                                        product_type: productTypeSearch,
                                    }))
                                    setProductTypeSearch('')
                                    setTimeout(() => {
                                        productTypesRef.current?.querySelector('input')?.focus()
                                    }, 10)
                                }}
                                className='text-left p-[8px] border-b border-lightgrey dark:border-darkgrey'
                            >
                                + Add New Type
                            </button>
                            {filteredProductTypes
                                ?.filter((v: any) => v)
                                .map((pt: any) => (
                                    <button
                                        onClick={() => {
                                            setEditableFields((previousState: any) => ({
                                                ...previousState,
                                                product_type: pt,
                                            }))
                                            setProductTypeSearch('')
                                            setTimeout(() => {
                                                setShowProductTypes(false)
                                            }, 10)
                                        }}
                                        className='text-left p-[8px] border-b border-lightgrey dark:border-darkgrey'
                                    >
                                        {pt}
                                    </button>
                                ))}
                        </div>
                    )}
                </div>
                <div className='flex flex-col gap-1'>
                    <Select
                        className={cn(
                            selectedProductId !== product.id &&
                                'bg-bg1 dark:bg-darkbg1 border h-[29.5px] dark:border-darkgrey'
                        )}
                        label='Listing Status'
                        id='status'
                        name='status'
                        onChange={handleChange}
                        value={editableFields.status ?? product.status}
                    >
                        <option value='calculating-margins'>Calculating Margins</option>
                        <option value='ready'>Ready to list</option>
                        <option value='needs-review'>Needs Review * </option>
                        <option value='unlistable'>Unlistable</option>
                        <option value='listed'>Listed</option>
                    </Select>
                    <div className='grid grid-cols-2 gap-2'>
                        {product?.related_variants_group_id && (
                            <Button
                                onClick={() => {
                                    setSearch('')
                                    return setSearchParams((prev: any) => {
                                        prev.set('group_id', product.related_variants_group_id)
                                        return prev
                                    })
                                }}
                                variant={'outline'}
                                size={'sm'}
                                className='text-darkdanger font-semibold p-0 max-h-[28px] self-start px-2  border-lightgrey dark:border-darkgrey'
                            >
                                VIEW RELATED PRODUCTS *
                            </Button>
                        )}

                        {!product?.related_variants_group_id && product?.variant_of > 0 && (
                            <Button
                                onClick={() => {
                                    setGroupByVariants(groupByVariants === true ? false : true)
                                }}
                                variant={'outline'}
                                size={'sm'}
                                className=' font-semibold p-0 max-h-[28px] self-start px-2  border-lightgrey dark:border-darkgrey'
                            >
                                <p>GROUP BY VARIANTS *</p>
                            </Button>
                        )}
                        {product.variant_of === 0 && (
                            <div className=' justify-end items-start flex flex-col min-w-[110px] col-start-2'>
                                <Button
                                    onClick={() => setShowOptionSetup(showOptionSetup ? false : true)}
                                    variant={'outline'}
                                    size={'sm'}
                                    className={cn(
                                        'text-text dark:text-darktext1 uppercase font-semibold p-0 max-h-[28px] self-start px-4  border-lightgrey dark:border-darkgrey',
                                        showOptionSetup && 'pr-[2px] pl-[14px] flex gap-1'
                                    )}
                                >
                                    variant option setup
                                    {showOptionSetup && <MdCancel className='' />}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div>
                <div
                    className={cn(
                        pendingEdits && selectedProductId !== product.id && 'pointer-events-none',
                        appState[product.id] &&
                            appState[product.id].find((userId: string) => userId !== user.id.toString()) &&
                            'pointer-events-none',
                        'relative grid grid-rows-[24px_30px_34px] grid-cols-[0.75fr_0.75fr_0.75fr_.80fr] py-[8px] gap-x-2 gap-y-5 mr-3'
                    )}
                >
                    <div className='relative grid grid-cols-[0.75fr_0.75fr_0.75fr_.80fr] px-2  gap-x-2 col-span-4 items-center'>
                        <div className=' text-base font-semibold'>Cost</div>
                        <div className=' flex gap-1 items-center'>
                            <p className='text-base font-semibold'>Shipping</p>
                            <div className='relative'>
                                <button
                                    onClick={() => {
                                        setShowShippingDetails((previousState) => !previousState)
                                    }}
                                    title='Shipping Details'
                                    className=' '
                                >
                                    {showShippingDetails ? (
                                        <div className='underline text-xs'>close</div>
                                    ) : (
                                        <p className='underline text-xs'>details</p>
                                    )}
                                </button>
                                {showShippingDetails && (
                                    <div
                                        ref={shippingDetailsRef}
                                        className='p-[8px] border border-lightgrey dark:border-darkgrey rounded-[4px] flex flex-col gap-[8px] absolute top-0 left-[120%] bg-white dark:bg-darkaccent z-[5] shadow-small w-[400px]'
                                    >
                                        <ShippingDetails
                                            product={product}
                                            editableFields={editableFields}
                                            handleChange={handleChange}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className=' text-base font-semibold'>List Price</div>
                        <div className=' text-base font-semibold'>Profit (Margin)</div>
                    </div>
                    <div className='relative grid grid-cols-[0.75fr_0.75fr_0.75fr_.80fr] px-2  gap-x-2 col-span-4 border rounded-md  border-lightgrey dark:border-darkgrey items-center'>
                        <div className='absolute -top-5 left-2'>original</div>
                        <div className=' flex'>
                            <div className={cn(editablePricingValues.cost && 'line-through', 'self-center mt-[-1px]')}>
                                $
                            </div>
                            <FakeInput
                                value={product.cost.toFixed(2)}
                                className={cn(
                                    editablePricingValues.cost && 'line-through',
                                    'border-none h-[28px] bg-bg1 dark:bg-darkbg1 p-0'
                                )}
                            />
                        </div>
                        <div className=' flex relative'>
                            <div
                                className={cn(
                                    editablePricingValues.shipping_fee && 'line-through',
                                    'self-center mt-[-1px]'
                                )}
                            >
                                $
                            </div>
                            <FakeInput
                                value={product.shipping_fee.toFixed(2)}
                                className={cn(
                                    editablePricingValues.shipping_fee && 'line-through',
                                    'border-none  h-[28px] bg-bg1 dark:bg-darkbg1 p-0'
                                )}
                            />
                        </div>
                        <div className=' flex relative'>
                            <div
                                className={cn(
                                    editablePricingValues.list_price && 'line-through',
                                    'self-center mt-[-1px]'
                                )}
                            >
                                $
                            </div>
                            <FakeInput
                                value={product.list_price.toFixed(2)}
                                className={cn(
                                    editablePricingValues.list_price && 'line-through',
                                    'border-none h-[28px] bg-bg1 dark:bg-darkbg1 p-0'
                                )}
                            />
                        </div>
                        <div
                            className={cn(
                                profit.length + margin.length > 16 && 'text-[12px]',
                                ' font-bold flex flex-col gap-2 w-fit'
                            )}
                        >
                            {profitMarginInsert}
                        </div>
                    </div>
                    <div
                        className={cn(
                            updateProductPricingValues.isPending && 'pointer-events-none',
                            'relative grid grid-cols-[0.75fr_0.75fr_0.75fr_.80fr] px-2 gap-x-2 col-span-4 '
                        )}
                    >
                        <div className='absolute -top-5 left-2'>current</div>
                        {selectedProductId !== product.id && (
                            <>
                                <div className=''>
                                    <FakeInput value={editablePricingValues?.cost ?? product.cost.toFixed(2)} />
                                </div>
                                <div className=''>
                                    <FakeInput
                                        value={editablePricingValues?.shipping_fee ?? product.shipping_fee.toFixed(2)}
                                    />
                                </div>
                                <div className=''>
                                    <FakeInput
                                        value={editablePricingValues?.list_price ?? product.list_price.toFixed(2)}
                                    />
                                </div>
                            </>
                        )}
                        {selectedProductId === product.id && (
                            <>
                                <div className=''>
                                    <Input
                                        value={editablePricingValues?.cost ?? product.cost.toFixed(2)}
                                        id='cost'
                                        name='cost'
                                        onChange={handlePricingValueChange}
                                        type='number'
                                    />
                                </div>
                                <div className=''>
                                    <Input
                                        value={editablePricingValues?.shipping_fee ?? product.shipping_fee.toFixed(2)}
                                        id='shipping_fee'
                                        name='shipping_fee'
                                        onChange={handlePricingValueChange}
                                        type='number'
                                    />
                                </div>
                                <div className=''>
                                    <Input
                                        value={editablePricingValues?.list_price ?? product.list_price.toFixed(2)}
                                        id='list_price'
                                        name='list_price'
                                        onChange={handlePricingValueChange}
                                        type='number'
                                    />
                                </div>
                            </>
                        )}
                        <div
                            className={cn(
                                editableProfit.length + editableMargin.length > 16 && 'text-[12px]',
                                ' mt-1 font-bold flex flex-col gap-2'
                            )}
                        >
                            {editableProfitMarginInsert}
                        </div>
                    </div>
                </div>
                <div
                    className={cn(
                        pendingEdits && selectedProductId !== product.id && 'pointer-events-none',
                        appState[product.id] &&
                            appState[product.id].find((userId: string) => userId !== user.id.toString()) &&
                            'pointer-events-none',
                        'relative grid grid-cols-[0.75fr_0.75fr_0.75fr_.80fr] pb-[8px] mr-3'
                    )}
                >
                    <div className='col-span-2 flex justify-between px-2 gap-1'>
                        {(user.id === 4 || user.id === 9 || user.id === 33 || user.id === 28 || user.id === 31) && (
                            <div className='px-2 justify-end items-start flex flex-col'>
                                <button onClick={handlePin} className={cn('underline flex gap-1 items-center')}>
                                    pin product
                                </button>
                            </div>
                        )}
                        {(product.product_setup_id || product.sku_product_setup_id) && (
                            <div className='px-2 justify-end items-start flex flex-col'>
                                <Link
                                    onClick={() => {
                                        return localStorage.setItem('lastViewedCompany', window.location.search)
                                    }}
                                    to={`/products/${product.product_setup_id ?? product.sku_product_setup_id}`}
                                    state={{ previousLocationPathname: location.pathname }}
                                    className={cn('underline flex gap-1 items-center')}
                                >
                                    view in product setup
                                </Link>
                            </div>
                        )}
                        {product.variant_of === 0 && !product.product_setup_id && !product.sku_product_setup_id && (
                            <Button
                                className='self-end'
                                variant={'outline'}
                                size={'sm'}
                                onClick={() => {
                                    setSelectedCreateProduct(originalProduct)
                                    return setShowShopifyCreate(true)
                                }}
                            >
                                Create Shopify Product
                            </Button>
                        )}
                    </div>
                    <div className='flex col-start-3 col-span-1 gap-3'>
                        <div className='flex flex-col gap-1 w-full'>
                            <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1] relative flex gap-2 w-fit'>
                                <div className='flex gap-2'>
                                    Variant Of
                                    {product.has_variants == 1 && <FaCrown className='self-center' />}
                                </div>
                                {product.has_variants < 1 && product.related_variants_group_id < 1 && (
                                    <p
                                        onClick={() => setShowVariantOfSelection((prev) => (prev ? false : true))}
                                        className='cursor-pointer font-normal underline lowercase'
                                    >
                                        {showVariantOfSelection ? 'cancel' : 'edit'}
                                    </p>
                                )}
                            </label>
                            <div className='flex gap-1'>
                                {product.has_variants > 0 && <p>Primary</p>}
                                {product.related_variants_group_id > 0 && <p>Grouped</p>}
                            </div>
                            {product.variant_of > 0 && relatedProduct && !showVariantOfSelection && (
                                <p className='bg-lightgrey p-1 rounded-md dark:text-darktext1 dark:bg-darkbg2'>
                                    {relatedProduct?.sku}
                                </p>
                            )}
                            {!product.has_variants &&
                                !product.related_variants_group_id &&
                                !showVariantOfSelection &&
                                product.variant_of === 0 && (
                                    <Button
                                        onClick={() => setShowVariantOfSelection(true)}
                                        variant={'outline'}
                                        size={'sm'}
                                    >
                                        Set Variant of
                                    </Button>
                                )}
                            {showVariantOfSelection && (
                                <SelectorButton
                                    mutationTarget={product?.id}
                                    pending={upsertProducts.isPending}
                                    align={'start'}
                                    width='w-fit'
                                    key={'products'}
                                    searchToggle={true}
                                    title={'Select product'}
                                    options={allProducts
                                        ?.filter(
                                            (relatedProduct: any) =>
                                                !relatedProduct?.variant_of && relatedProduct.id !== product.id
                                        )
                                        .map((relatedProduct: any) => {
                                            return {
                                                label: relatedProduct.sku,
                                                value: relatedProduct.id,
                                            }
                                        })}
                                    filterValues={[product.variant_of]}
                                    onSelect={handleChangeVariantOf}
                                ></SelectorButton>
                            )}
                        </div>
                    </div>
                    <div className='flex flex-col col-start-4 items-center justify-center'>
                        {isPricingEdited && (
                            <div className='flex gap-3 justify-end mb-2'>
                                <button
                                    onMouseDown={(event) => {
                                        event.preventDefault()
                                        handlePricingValueSave()
                                    }}
                                    className={`${
                                        updateProductPricingValues.isPending
                                            ? 'opacity-[0.9] pointer-events-none'
                                            : 'pointer-events-auto'
                                    } shrink-0 text-[14px] leading-[1] py-1 px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase`}
                                >
                                    {updateProductPricingValues.isPending ? 'SAVING...' : 'SAVE'}
                                </button>
                                <button
                                    onClick={() => {
                                        setShowDelete(false)
                                        setEditWeblinks(false)
                                        setEditablePricingValues({})
                                    }}
                                    className={`${
                                        updateProductPricingValues.isPending
                                            ? 'opacity-[0.9] pointer-events-none'
                                            : 'pointer-events-auto'
                                    } self-center shrink-0 text-[14px] leading-[1] py-1 px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkgrey text-black dark:text-offwhite font-bold uppercase`}
                                >
                                    Cancel
                                </button>
                            </div>
                        )}
                        {(product.latest_comment || product.latest_comment == '') && (
                            <button
                                onClick={() => setShowCommentModal(true)}
                                className=' shrink-0 underline min-w-[80px] '
                            >
                                pricing history
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <div
                className={cn(
                    pendingEdits && selectedProductId !== product.id && 'pointer-events-none',
                    appState[product.id] &&
                        appState[product.id].find((userId: string) => userId !== user.id.toString()) &&
                        'pointer-events-none',
                    'py-[8px] flex flex-col gap-[8px]'
                )}
            >
                <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1] relative w-fit'>
                    Lowest Price
                </label>
                <div className='flex gap-[8px] items-center justify-between border border-lightgrey rounded-[4px] dark:border-darkgrey px-[4px]'>
                    <a
                        target='_blank'
                        className='px-[4px] py-[4px] flex gap-[4px] items-center font-bold'
                        href={product.lowest_competitor_link}
                    >
                        ${(Number(product.lowest_competitor_price) || 0).toFixed(2)} <FaLink />
                    </a>
                </div>
                {selectedProductId !== product.id && (
                    <>
                        <FakeInput
                            value={
                                editableFields.lowest_competitor_price ??
                                parseFloat(product?.lowest_competitor_price)?.toFixed(2)
                            }
                            label='Price'
                        />
                        <Input
                            className={cn(
                                selectedProductId !== product.id &&
                                    'bg-bg1 dark:bg-darkbg1 border ml-[-1px] dark:border-darkgrey'
                            )}
                            label='link'
                            value={editableFields.lowest_competitor_link ?? product.lowest_competitor_link}
                            id='lowest_competitor_link'
                            name='lowest_competitor_link'
                            onChange={handleChange}
                            type='text'
                        />
                    </>
                )}
                {selectedProductId === product.id && (
                    <>
                        <Input
                            label='Price'
                            value={
                                editableFields.lowest_competitor_price ??
                                parseFloat(product?.lowest_competitor_price)?.toFixed(2)
                            }
                            id='lowest_competitor_price'
                            name='lowest_competitor_price'
                            onChange={handleChange}
                            type='number'
                        />
                        <Input
                            label='link'
                            value={editableFields.lowest_competitor_link ?? product.lowest_competitor_link}
                            id='lowest_competitor_link'
                            name='lowest_competitor_link'
                            onChange={handleChange}
                            type='text'
                        />
                    </>
                )}
                <div className=' justify-end items-start flex flex-col min-w-[110px]'>
                    <button
                        className='underline flex gap-1 items-center'
                        onClick={() => setShowEditHistory(showEditHistory ? false : true)}
                    >
                        edit history
                        {showEditHistory && <MdCancel />}
                    </button>
                </div>
            </div>

            <div
                className={cn(
                    pendingEdits && selectedProductId !== product.id && 'pointer-events-none',
                    appState[product.id] &&
                        appState[product.id].find((userId: string) => userId !== user.id.toString()) &&
                        'pointer-events-none',
                    'py-2 px-1 relative'
                )}
            >
                <button
                    className='absolute top-[2px] left-16 underline'
                    onClick={() => setNoteExpand(noteExpand ? false : true)}
                >
                    {noteExpand ? 'collapse' : 'expand'}
                </button>

                <Textarea
                    label='Notes'
                    outerClassName={cn(
                        noteExpand && 'h-full min-h-[159px]',
                        !noteExpand && 'h-full max-h-[170px] overflow-auto',
                        'flex flex-col gap-[4px] w-full '
                    )}
                    className={cn(
                        pendingEdits && selectedProductId !== product.id && 'pointer-events-none',
                        appState[product.id] &&
                            appState[product.id].find((userId: string) => userId !== user.id.toString()) &&
                            'pointer-events-none',
                        'w-full h-full !max-w-none p-[8px] bg-transparent border-lightgrey dark:border-0 dark:border-darkgrey dark:bg-darkbg2 rounded-[4px] focus:outline-none !max-h-none',
                        selectedProductId !== product.id && 'bg-bg1 dark:bg-darkbg1 dark:border-1'
                    )}
                    id='notes'
                    name='notes'
                    onChange={handleChange}
                    value={editableFields.notes ?? product.notes ?? ''}
                />
            </div>
            <div className='absolute top-1 right-1 z-[6]'>
                <MoreActions options={options} displayContent={{ icon: FaBars }} />
            </div>
            <div
                className={cn(
                    pendingEdits && selectedProductId !== product.id && 'pointer-events-none',
                    appState[product.id] &&
                        appState[product.id].find((userId: string) => userId !== user.id.toString()) &&
                        'pointer-events-none',
                    'absolute top-[2px] right-10 '
                )}
            >
                {variantGroupingId && !product.is_primary_variant && product.variant_of === 0 && (
                    <div
                        className={cn(
                            upsertProducts.isPending || isEdited ? 'cursor-default' : 'cursor-pointer underline'
                        )}
                        onClick={handleSetPrimaryVariant}
                    >
                        set group primary
                    </div>
                )}
                {product.is_primary_variant && (
                    <span className='flex gap-2'>
                        <FaCrown className='self-center' />
                        Group Primary
                    </span>
                )}
            </div>

            <div
                className={cn(
                    pendingEdits && selectedProductId !== product.id && 'pointer-events-none',
                    appState[product.id] &&
                        appState[product.id].find((userId: string) => userId !== user.id.toString()) &&
                        'pointer-events-none',
                    'absolute bottom-0 left-0'
                )}
            >
                {duplicateSkuFound && (
                    <div className='flex gap-4 p-2 justify-end'>
                        <Button
                            variant={'destructive'}
                            onClick={handleSave}
                            className={`pointer-events-none shrink-0 text-[14px] leading-none py-0 px-1 text-center rounded-[4px] border border-darkgrey dark:border-darkgrey font-bold uppercase`}
                        >
                            <ExclamationTriangleIcon className='text-danger dark:text-darkdanger self-center mr-2' />
                            {'Duplicate shopify sku, cannot save'}
                        </Button>
                    </div>
                )}
                {editableFields?.sku?.trim() === '' && (
                    <div className='flex gap-4 p-2 justify-end'>
                        <Button
                            variant={'destructive'}
                            onClick={handleSave}
                            className={`pointer-events-none shrink-0 text-[14px] leading-none py-0 px-1 text-center rounded-[4px] border border-darkgrey dark:border-darkgrey font-bold uppercase`}
                        >
                            <ExclamationTriangleIcon className='text-danger dark:text-darkdanger self-center mr-2' />
                            {'Product must have Shopify SKU, cannot save'}
                        </Button>
                    </div>
                )}
            </div>
            <div className='flex flex-col justify-between relative'>
                {/* <button onClick={() => {}} className='p-[8px] h-fit'> */}
                {/* <BsThreeDotsVertical className='text-[20px]' /> */}
                {/* </button> */}

                <div
                    className={cn(
                        pendingEdits && selectedProductId !== product.id && 'pointer-events-none',
                        appState[product.id] &&
                            appState[product.id].find((userId: string) => userId !== user.id.toString()) &&
                            'pointer-events-none',
                        'absolute bottom-0 right-0'
                    )}
                >
                    {(upsertProducts.isPending || setPrimaryVariant.isPending) && (
                        <div className='flex gap-4 p-2 justify-end'>
                            <button
                                onClick={handleSave}
                                className={`${
                                    upsertProducts.isPending
                                        ? 'opacity-[0.9] pointer-events-none'
                                        : 'pointer-events-auto'
                                } shrink-0 text-[14px] leading-[1] py-1 px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase`}
                            >
                                {upsertProducts.isPending || setPrimaryVariant.isPending ? 'SAVING...' : 'SAVE'}
                            </button>
                        </div>
                    )}
                    {!isEdited && !isPricingEdited && showDelete && (
                        <div className='flex gap-[8px] p-[8px]'>
                            <button
                                onClick={() =>
                                    deleteProduct(product, {
                                        onSuccess: () => {
                                            setPendingEdits(false)
                                        },
                                    })
                                }
                                className='shrink-0 text-[14px] leading-[1] py-[4px] w-fit px-[8px] rounded-[4px] bg-red dark:bg-lightred text-white font-bold uppercase'
                            >
                                Delete
                            </button>
                            <button
                                onClick={() => setShowDelete(false)}
                                className='text-[14px] leading-[1] py-[4px] w-fit px-[8px] rounded-[4px] bg-lightgrey dark:bg-darkgrey font-bold uppercase'
                            >
                                Cancel
                            </button>
                        </div>
                    )}
                    {!isEdited &&
                        !isPricingEdited &&
                        !showDelete &&
                        !upsertProducts.isPending &&
                        !setPrimaryVariant.isPending && (
                            <button
                                onClick={() => {
                                    if (product.is_primary_variant) {
                                        return (toastId.current = sendToast({
                                            message: 'Cannot delete while primary of group',
                                        }))
                                    }
                                    if (product.has_variants) {
                                        return (toastId.current = sendToast({
                                            message: 'Cannot delete with related variants',
                                        }))
                                    }
                                    return setShowDelete(true)
                                }}
                                className='p-[8px] h-fit text-red dark:text-lightred'
                            >
                                <FaTrash className='text-[20px]' />
                            </button>
                        )}
                </div>
            </div>
            {showEditHistory && <CompanyProductEditHistory record_id={originalProduct.id} users={users} />}
            {!showEditHistory && !product.is_variant && showOptionSetup && (
                <CompanyProductOptionSetup product_id={originalProduct.id} />
            )}
            {showCommentModal && <ProductCommentsModal product_id={product.id} setShowModal={setShowCommentModal} />}
        </div>
    )
}

export type CompanyProductType = {
    id?: string | number
    upc: string
    sku: string
    model_number: string
    part_number: string
    product_type: string
    status: string
    weblinks: { title: string; link: string }[]
    cost: number
    shipping_fee: number
    list_price: number
    new_cost: number
    new_shipping_fee: number
    new_list_price: number
    lowest_competitor_price: number
    lowest_competitor_link: string
    shopify_product_id?: string | number
    shopify_variant_id?: string | number
    is_variant?: boolean
    variant_of?: string | number
    listed_by?: string | number
    notes: string
    working_comment: string
    latest_comment: string
    company_id?: string | number
    store_id?: string | number
    related_variants_group_id?: string | number | null
    is_primary_variant?: number | null
    shipping_dimensions?: string
    shipping_weight?: string
}

export const defaultCompanyProduct: CompanyProductType = {
    product_type: '',
    status: '',
    upc: '',
    sku: '',
    model_number: '',
    part_number: '',
    weblinks: [],
    cost: 0,
    shipping_fee: 0,
    list_price: 0,
    new_cost: 0,
    new_shipping_fee: 0,
    new_list_price: 0,
    lowest_competitor_price: 0,
    lowest_competitor_link: '',
    notes: '',
    working_comment: '',
    latest_comment: '',
    shipping_dimensions: '',
    shipping_weight: '',
}
