'use client'

import { CalendarIcon } from '@radix-ui/react-icons'
import { format } from 'date-fns'

import { PopoverContent, PopoverTrigger } from '@radix-ui/react-popover'
import { cn } from '../helpers/index'
import { Button } from './component_Basics/Button'
import { CalendarBase } from './component_Basics/CalendarBase'
import { Popover } from './component_Basics/Popover'
import { DATE_TODAY, getDateToday } from '../procurement/constants'

export function CalendarProcurementTasks<TData, TValue>({
    date,
    setDate,
    openFlag,
}: {
    date: Date | undefined
    setDate: any
    openFlag?: boolean
}) {
    return (
        <Popover open={openFlag || undefined}>
            <PopoverTrigger asChild>
                <Button
                    variant={'outline'}
                    size={'sm'}
                    className={cn(
                        date !== DATE_TODAY && 'text-accent2 dark:text-darkaccent2',
                        'w-fit justify-start text-left font-bold border-dashed',
                        !date && 'text-muted-foreground'
                    )}
                >
                    <CalendarIcon className='mr-2 h-4 w-4' />
                    {date ? format(date, 'PPP') : <span>Pick a date</span>}
                </Button>
            </PopoverTrigger>
            <PopoverContent className='w-auto p-0 z-50 ' align='start'>
                <CalendarBase
                    className='border border-primary rounded bg-bg1 dark:bg-darkbg1'
                    mode='single'
                    selected={date}
                    onSelect={(e: any) => {
                        if (e === undefined) {
                            setDate(getDateToday())
                        } else {
                            setDate(e)
                        }
                    }}
                    initialFocus
                />
            </PopoverContent>
        </Popover>
    )
}
