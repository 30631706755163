import { Button } from 'custom_components/component_Basics/Button'
import { useDebounce } from 'helpers'
import { useAddSupplierProducts, useGetAllProducts } from 'purchaseOrdersV2/api/useQueries'
import SupplierProductRow from 'purchaseOrdersV2/lists/SupplierProductRow'
import { useEffect, useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'

export default function AddProductsModal({
    currentProducts,
    selectedSupplierId,
    closeModal,
}: {
    currentProducts: any[]
    selectedSupplierId: string
    closeModal: () => void
}) {
    const [page, setPage] = useState(1)
    const [selectedProducts, setSelectedProducts] = useState<any[]>(currentProducts)
    const [productSearch, setProductSearch] = useState('')
    const debouncedProductSearch = useDebounce(productSearch, 300)
    const allProductsQuery = useGetAllProducts({ page, search: debouncedProductSearch })
    const allProducts: any[] = allProductsQuery.data?.products || []
    const modalRef = useRef<HTMLDivElement>(null)
    const addProductsMutation = useAddSupplierProducts()
    const allSelected = !allProducts.find((p) => !selectedProducts.find((pro) => p.id === pro.id))
    const toggleAllSelected = () => {
        const newProducts = selectedProducts.filter((p) => !allProducts.find((pro) => pro.id === p.id))
        if (allSelected) {
            return setSelectedProducts(newProducts)
        }
        newProducts.push(...allProducts)
        setSelectedProducts(newProducts)
    }
    const handleDecrement = () => {
        if (page === 1) {
            return
        }
        setPage((prev) => prev - 1)
    }
    const handleIncrement = () => {
        if (allProducts.length < 500) {
            return
        }
        setPage((prev) => prev + 1)
    }

    const handleSave = () => {
        addProductsMutation.mutate(
            {
                product_ids: selectedProducts.map((p) => p.id),
                supplier_id: parseInt(selectedSupplierId),
            },
            {
                onSuccess: () => {
                    setSelectedProducts([])
                    closeModal()
                },
            }
        )
    }

    useEffect(() => {
        const listenToWindow = (event: MouseEvent) => {
            if (modalRef.current === event.target) {
                closeModal()
            }
        }

        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <div
            ref={modalRef}
            className='fixed z-50 top-0 left-0 w-full h-full bg-black/50 flex items-center justify-center'
        >
            <div className='relative p-6 max-w-[800px] w-full max-h-[90%] bg-bg1 dark:bg-darkbg1 rounded shadow-md'>
                <button
                    onClick={() => closeModal()}
                    className='text-red dark:text-lightred font-bold text-lg absolute top-1 right-1'
                >
                    <FaTimes />
                </button>
                <h2 className='font-bold text-lg mb-2'>Add Products</h2>
                <div className='shadow-md rounded border border-lightgrey dark:border-darkgrey'>
                    <input
                        className='w-full p-2 focus:outline-none bg-transparent'
                        placeholder='Search products...'
                        type='text'
                        name='product_search'
                        id='product_search'
                        value={productSearch}
                        onChange={({ target }) => setProductSearch(target.value)}
                    />
                    <div className='sticky top-0 flex gap-2 items-center p-2 border-b border-lightgrey dark:border-darkgrey bg-accent1 dark:bg-darkbg2 text-white dark:text-offwhite font-bold'>
                        <button
                            onClick={toggleAllSelected}
                            className={`w-4 h-4 rounded border border-lighgrey dark:border-darkgrey ${
                                allSelected ? 'bg-white dark:bg-offwhite' : ''
                            }`}
                        ></button>
                        <span>Product Title</span>
                    </div>
                    <div className='max-h-[250px] overflow-auto'>
                        {allProducts.map((product: any) => (
                            <SupplierProductRow
                                product={product}
                                selectedProducts={selectedProducts}
                                setSelectedProducts={setSelectedProducts}
                            />
                        ))}
                    </div>
                    <div className='my-2 shadow-md flex items-center gap-2 z-30 mx-auto w-fit border border-lightgrey dark:border-darkgrey bg-bg1 dark:bg-darkbg1 p-1 rounded'>
                        <button
                            className='border border-lightgrey dark:border-darkgrey bg-bg1 dark:bg-darkbg1 p-2 rounded'
                            onClick={handleDecrement}
                        >
                            Prev
                        </button>
                        <div className='border border-lightgrey dark:border-darkgrey bg-bg1 dark:bg-darkbg1 p-2 rounded'>
                            {page}
                        </div>
                        <button
                            className='border border-lightgrey dark:border-darkgrey bg-bg1 dark:bg-darkbg1 p-2 rounded'
                            onClick={handleIncrement}
                        >
                            Next
                        </button>
                    </div>
                </div>

                {selectedProducts.length > 0 && (
                    <Button onClick={handleSave} variant={'outline'} className='block mx-auto mt-2'>
                        Add Products
                    </Button>
                )}
            </div>
        </div>
    )
}
