import { Dispatch, SetStateAction } from 'react'
import { GrRefresh } from 'react-icons/gr'

export default function Refresh({ setNeedsRefresh }: { setNeedsRefresh: Dispatch<SetStateAction<boolean>> }) {
    return (
        <button
            onClick={() => setNeedsRefresh(true)}
            className='p-[5px] h-fit bg-lightgrey dark:bg-darkaccent rounded-[4px] hover:bg-fire transition'
        >
            <GrRefresh className='[&>*]:stroke-darkgrey [&>*]:dark:stroke-offwhite' />
        </button>
    )
}
