import { CheckIcon } from '@radix-ui/react-icons'
import { Button } from 'custom_components/component_Basics/Button'
import RefreshButton from 'custom_components/RefreshButton'
import { cn } from 'helpers'
import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { tasks } from 'tasksV2/api/queryKeys'
import { useGetTasks } from '../api/useQueries'
import CreateTaskModal from './modals/CreateTaskModal'
import TasksScreenGroupedV2 from './TasksScreenGroupedV2'
import TasksScreenSplitV2 from './TasksScreenSplitV2'
import { TasksScreenV2SearchContainer } from './TasksScreenV2SearchContainer'

export default function TasksScreenV2() {
    const [searchParams, setSearchParams] = useSearchParams()
    const showCreateModal = searchParams.get('showCreateModal') === 'true'
    const tasksListQuery = useGetTasks({})
    const stringFilterValues = localStorage.getItem('tasksFilterValues')
    const [initRender, setInitRender] = useState(true)
    const [filterValues, setFilterValues] = useState<any>(stringFilterValues ? JSON.parse(stringFilterValues) : {})
    const [groupTables, setGroupTables] = useState(stringFilterValues ? JSON.parse(stringFilterValues).grouped : false)

    const searchRef: any = useRef()

    const toggleCreateModal = () => {
        if (showCreateModal) {
            searchParams.delete('showCreateModal')
        } else {
            searchParams.set('showCreateModal', 'true')
        }
        setSearchParams(searchParams)
    }

    const handleFilterValuesEdit = (newValue: any) => {
        if (Object.keys(newValue)?.length) {
            searchParams.set('page', '1')
            setSearchParams(searchParams)
            localStorage.setItem('tasksFilterValues', JSON.stringify(newValue))
        }
    }

    useEffect(() => {
        if (initRender) {
            setInitRender(false)
        }
    }, [])

    return (
        <div className='text-sm mt-[-12px]'>
            <>
                <div className='flex justify-between mb-2'>
                    <div className='flex gap-6 items-center w-1/3'>
                        <h1 className='text-2xl font-semibold'>Tasks</h1>
                        <TasksScreenV2SearchContainer ref={searchRef} />
                    </div>
                    <div className='flex gap-4 items-center'>
                        <div
                            onClick={() => {
                                setGroupTables(!groupTables)
                                setFilterValues((prev: any) => {
                                    const newValue = {
                                        ...prev,
                                    }
                                    newValue['grouped'] = !newValue['grouped']
                                    handleFilterValuesEdit(newValue)
                                    return newValue
                                })
                            }}
                            className='flex gap-1 cursor-pointer items-center'
                        >
                            <div
                                className={cn(
                                    ' flex h-4 w-4 items-center justify-center rounded-sm border border-darkgrey dark:bg-darkbg2 dark:border-lightgrey cursor-pointer',
                                    groupTables ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible'
                                )}
                            >
                                <CheckIcon className={cn('h-4 w-4')} />
                            </div>
                            <p>Group Departments </p>
                        </div>
                        <RefreshButton
                            queryKeys={[tasks.list._def.toString()]}
                            isFetching={tasksListQuery.isFetching}
                        />
                        <Button
                            variant={'outline'}
                            size={'sm'}
                            onClick={() => toggleCreateModal()}
                            className='flex gap-1 items-center'
                        >
                            <p className='mb-[2px]'>+</p>
                            <p> New Task</p>
                        </Button>
                    </div>
                </div>

                {!groupTables && <TasksScreenSplitV2 />}
                {groupTables && <TasksScreenGroupedV2 />}
            </>
            {showCreateModal && <CreateTaskModal closeModal={toggleCreateModal} />}
        </div>
    )
}
