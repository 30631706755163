import { useState } from 'react'
import { formatMoney } from '../../helpers'
import { DraftOrderInit } from '../../orders/orders.types'
import { draftOrderFindDiscounts, getSubtotal } from '../helpers/draftOrders.helpers'

type PaymentStatusProps = {
    order: DraftOrderInit
}

export default function DraftOrderPaymentStatus({ order }: PaymentStatusProps) {
    const [showTaxRates, setShowTaxRates] = useState<boolean>(false)

    return (
        <div className='grid gap-[24px] bg-white dark:bg-darkaccent p-[16px] pb-0 shadow-small rounded dark:text-offwhite [&>*:last-child]:pb-[16px]'>
            <div className='flex items-center gap-[8px] text-[14px]'>
                <h2 className='font-bold text-[20px]'>Invoice</h2>
            </div>
            <div className='grid gap-[16px]'>
                <div className='flex flex-col gap-[8px]'>
                    <div className='flex justify-between'>
                        <div className='flex gap-[16px] text-darkgrey dark:text-offwhite'>
                            <span className='w-[140px]'>Subtotal</span>
                            <span>
                                {order.line_items.map((item: any) => item.quantity).reduce((acc, cur) => acc + cur, 0)}{' '}
                                items
                            </span>
                        </div>
                        <span>{formatMoney(getSubtotal(order))}</span>
                    </div>
                    {draftOrderFindDiscounts(order).map((discount: any) => {
                        const discountTitleFormat: string[] = []
                        if (discount) {
                            if (discount.title) {
                                discountTitleFormat.push(`${discount.title}`)
                            } else if (discount.description) {
                                discountTitleFormat.push(`${discount.description}`)
                            }
                            if (discount.value_type === 'percentage') {
                                discountTitleFormat.push(`(-${Number(discount.value)}%)`)
                            } else {
                                if (discount.quantity) {
                                    discountTitleFormat.push(` ${formatMoney(discount.value)} x ${discount.quantity}  `)
                                }

                                discountTitleFormat.push(`(-$${discount.amount})`)
                            }
                        }
                        return (
                            <div className='flex justify-between'>
                                <div className='flex gap-[16px] text-darkgrey dark:text-offwhite'>
                                    <span className='w-[140px]'>Discount</span>
                                    <span>{discountTitleFormat}</span>
                                </div>
                                <span>- ${discount.amount}</span>
                            </div>
                        )
                    })}

                    <div className='flex justify-between'>
                        <div className='flex gap-[16px] text-darkgrey dark:text-offwhite'>
                            <span className='w-[140px]'>Shipping</span>
                            {order.shipping_line && order.shipping_line.title}
                        </div>
                        <span>{order.shipping_line && formatMoney(Number(order.shipping_line.price))}</span>
                    </div>
                    {order.tax_lines && order.tax_lines.length > 0 && (
                        <div className='flex justify-between'>
                            <div className='flex gap-[16px] text-darkgrey dark:text-offwhite'>
                                <span className='w-[140px]'>Tax</span>
                                {order.tax_lines && order.tax_lines.length === 1 ? (
                                    <span>
                                        {order.tax_lines[0].title} {(Number(order.tax_lines[0].rate) * 100).toFixed(4)}%
                                    </span>
                                ) : (
                                    <>
                                        <span
                                            className='font-semibold hover:underline hover:text-blue dark:hover:text-accent cursor-pointer'
                                            onClick={() => {
                                                setShowTaxRates(true)
                                            }}
                                        >
                                            Show Tax Rates
                                        </span>
                                        {showTaxRates && (
                                            <div className='js-show-tax-rates-close fixed flex p-[32px] top-[50px] left-[216px] bg-[rgba(0,0,0,0.3)] w-[calc(100%-216px)] h-[calc(100%-50px)] z-50'>
                                                <div className='grid self-center ml-[64px] w-[calc(65%-128px)] min-w-[250px] bg-white dark:bg-darkaccent rounded-lg'>
                                                    <div className='flex justify-between items-center border-b-[1px] dark:border-b-darkgrey'>
                                                        <h2 className='p-[16px] text-[20px] font-bold'>Tax Rates</h2>
                                                        <button
                                                            onClick={() => {
                                                                setShowTaxRates(false)
                                                            }}
                                                            className='font-bold mr-[16px] hover:bg-offwhite dark:hover:bg-darkness h-[32px] w-[32px] rounded-lg'
                                                        >
                                                            <span>X</span>
                                                        </button>
                                                    </div>
                                                    <div className='p-[16px] flex flex-col gap-[16px] border-b-[1px] dark:border-b-darkgrey'>
                                                        {order.tax_lines &&
                                                            order.tax_lines.map((line) => (
                                                                <div key={line.title} className='flex justify-between'>
                                                                    <span>
                                                                        {line.title} (
                                                                        {(Number(line.rate) * 100).toFixed(4)}%)
                                                                    </span>{' '}
                                                                    <span>{formatMoney(Number(line?.price))}</span>
                                                                </div>
                                                            ))}
                                                        <div className='flex justify-between'>
                                                            <span className='font-semibold'>Total Tax</span>
                                                            <span>{formatMoney(Number(order.total_tax))}</span>
                                                        </div>
                                                    </div>
                                                    <button
                                                        className='ml-[auto] mr-[16px] my-[16px] py-[4px] px-[16px] border-[1px] rounded hover:bg-offwhite dark:hover:bg-darkness'
                                                        onClick={() => {
                                                            setShowTaxRates(false)
                                                        }}
                                                    >
                                                        Close
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                            <span>{formatMoney(Number(order.total_tax))}</span>
                        </div>
                    )}

                    <div className='flex justify-between'>
                        <span className='w-[140px] font-semibold'>Total</span>
                        <span>{formatMoney(Number(order.total_price))}</span>
                    </div>
                </div>
            </div>
            {order.financial_status === 'authorized' && (
                <div className='flex p-[16px] w-[calc(100%+32px)] relative left-[-16px] border-t-[1px] dark:border-darkgrey'>
                    <button
                        onClick={() => {
                            window.open(`https://factorypure.myshopify.com/admin/orders/${order.id}`, '_blank')
                        }}
                        className='ml-auto bg-[#007a5c] hover:bg-[#125443] dark:bg-fire dark:hover:bg-[darkorange] rounded py-[8px] px-[16px] items-center justify-center text-[14px] font-semibold text-white dark:text-black !border-[1px] border-darkgrey'
                    >
                        Capture Payment
                    </button>
                </div>
            )}
        </div>
    )
}
