import { useEffect, useRef } from 'react'
import { PrivateEventType } from '../../tasks/tasks.types'
import { cn, formatDateToLocale } from '../../helpers'
import { Link, useLocation } from 'react-router-dom'

export default function ProductsAllPrivateEventModalV2({
    origin,
    events,
    setViewModal,
    userList,
}: {
    origin: string
    events: PrivateEventType[]
    setViewModal: any
    userList: any
}) {
    const modalRef = useRef(null)
    const location = useLocation()

    const listenToWindow = (e: any) => {
        if (e.target === modalRef.current) setViewModal(false)
    }
    useEffect(() => {
        // if (!user.roles.includes("developer")) {
        //     navigate("/")
        // }
        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <>
            <div
                ref={modalRef}
                className={`grid fixed top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] z-[50] place-items-center`}
            >
                <div className='max-w-[750px] max-h-[90%] overflow-auto bg-bg1 rounded-[4px] shadow-small dark:bg-darkbg1 p-[10px] relative flex flex-col gap-[4px]'>
                    <div className='max-w-[100%] dark:bg-darkbg1 relative mb-[16px] p-[8px] pt-[8px] rounded '>
                        <div>
                            <h3 className='pl-[4px] font-light text-[24px] dark:text-darktext1 w-[fit-content] uppercase  items-center'>
                                Full {origin} Event History
                            </h3>
                            <button
                                onClick={() => setViewModal(false)}
                                className='leading-[30px] text-[34px] text-danger dark:text-darkdanger absolute font-bold top-0 right-[8px]'
                            >
                                &times;
                            </button>
                        </div>
                        <ul className='flex relative flex-col max-w-[100%] pt-[8px] list-none z-[0]'>
                            {events.map((e: PrivateEventType) => {
                                const dateToLocale = formatDateToLocale(e.created_at)
                                const day = new Date(Date.parse(e.created_at)).toDateString().split(' ')[0]
                                const date = dateToLocale.split(' ').slice(0, 2).join(' ').slice(0, -1)
                                const time = dateToLocale.split(' ').slice(3).join(' ')
                                const user =
                                    userList.find((u: any) => parseInt(e.user_id) === parseInt(u.id)) ||
                                    userList.find((u: any) => e.user_id === u.user_id)
                                if (!user) {
                                    return
                                }
                                return (
                                    <li className='flex gap-[10px] self-start  border-b-[1px] border-text1 dark:border-darktext1 py-1 px-1'>
                                        <div
                                            className={`relative top-0 bg-accent1 dark:bg-darkaccent1 border-[5px] border-bg1 dark:border-darkbg1 rounded-full shrink-0 h-[18px]  w-[18px] z-index-2 self-center`}
                                        ></div>
                                        <p className={`leading-[17.5px] text-[14px] font-normal flex gap-3 `}>
                                            {user.first_name} {user.last_name} viewed product{' '}
                                            <Link
                                                draggable={false}
                                                to={`/products/${e.product_id}`}
                                                state={{ previousLocationPathname: location.pathname }}
                                                className={cn('underline flex gap-1 items-center')}
                                            >
                                                {e.product_id}
                                            </Link>
                                            at {time} on {day} {date}
                                            <span
                                                className={`${user.type === 'Client' ? 'text-text1' : 'text-accent2'} ${
                                                    user.type === 'Client'
                                                        ? 'dark:text-darktext1'
                                                        : 'dark:text-darkaccent2'
                                                }`}
                                            >
                                                {' '}
                                                ({user.type})
                                            </span>{' '}
                                        </p>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
