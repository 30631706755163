import { useQuery } from '@tanstack/react-query'
import { vFetch } from 'helpers'

export default function TopIPAddresses() {
    const sessionsQuery = useQuery({
        queryKey: ['sessions', 'top_ips'],
        queryFn: () => vFetch('/v1/analytics/sessions/topIPs?days=1'),
    })
    const sessions = sessionsQuery?.data?.sessions || []
    return (
        <div className='w-full'>
            <h2 className='text-xl font-bold'>Top IP Addresses For The Last 24 Hours</h2>
            <div className='flex flex-col gap-2'>
                <div className='grid grid-cols-4 gap-2 text-sm font-bold p-2 bg-lightgrey dark:bg-darkbg2 rounded shadow-md break-all sticky top-[50px] z-10'>
                    {/* <p>id: {session.id}</p> */}
                    {/* <p>Domain</p> */}
                    {/* <p>Path</p> */}
                    {/* <p>search: {session.search}</p> */}
                    {/* <p>hash: {session.hash}</p> */}
                    {/* <p>href: {session.href}</p> */}
                    {/* <p>ISP</p> */}
                    <p>IP Address</p>
                    <p>City</p>
                    <p>State</p>
                    {/* <p>region_name: {session.region_name}</p> */}
                    {/* <p>zip: {session.zip}</p> */}
                    {/* <p>country_code: {session.country_code}</p> */}
                    {/* <p>country: {session.country}</p> */}
                    {/* <p>timezone: {session.timezone}</p> */}
                    <p>Count</p>
                </div>
                {sessions.map((session: any) => (
                    <div className='grid grid-cols-4 gap-2 text-sm font-bold p-2 dark:bg-darkbg1 rounded shadow-md break-all'>
                        {/* <p>id: {session.id}</p> */}
                        {/* <p>{session.domain}</p> */}
                        {/* <p>{session.path}</p> */}
                        {/* <p>search: {session.search}</p> */}
                        {/* <p>hash: {session.hash}</p> */}
                        {/* <p>href: {session.href}</p> */}
                        {/* <p>{session.isp}</p> */}
                        <p>{session.ip_address}</p>
                        <p>{session.city}</p>
                        <p>{session.region}</p>
                        {/* <p>region_name: {session.region_name}</p> */}
                        {/* <p>zip: {session.zip}</p> */}
                        {/* <p>country_code: {session.country_code}</p> */}
                        {/* <p>country: {session.country}</p> */}
                        {/* <p>timezone: {session.timezone}</p> */}
                        {/* <p>{timeFormatter(new Date(session.created_at))}</p> */}
                        <p>{session.count}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}
