import Analytics from 'analytics'
import { useAnalytics } from 'use-analytics'
import { vFetch } from '../helpers'

type EventTrackType = {
    department: string
    type: string
    subtype: string
    event_details: {}
    options: {}
}

export function useTrackEvent() {
    return useTrack()
}

function useTrack() {
    const { track, page } = useAnalytics()
    return {
        track: ({ department, type, subtype, event_details, options }: EventTrackType) => {
            return track(
                'interaction',
                {
                    department,
                    type,
                    subtype,
                    event_details,
                },
                { options }
            )
        },
        pageView: () => {
            return page()
        },
    }
}

const analytics = Analytics({
    app: 'fp-dash',
    debug: false,
    plugins: [
        {
            name: 'custom-plugin',
            page: ({ payload }: any) => {
                vFetch(`/v1/analytics/interactions/pageView`, {
                    skipToast: true,
                    method: 'POST',
                    body: JSON.stringify({
                        payload,
                    }),
                })
            },
            identify: ({ payload }: any) => {},
            track: ({ payload }: any) => {
                vFetch(`/v1/analytics/interactions`, {
                    skipToast: true,
                    method: 'POST',
                    body: JSON.stringify({
                        payload,
                    }),
                })
            },
        },
    ],
})

export default analytics
