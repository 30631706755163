import { useQuery } from '@tanstack/react-query'
import { useDebounce, vFetch } from 'helpers'
import CustomerRow from './CustomerRow'
import { CustomerType } from 'customersV2/Customers.types'
import { useRef, useState } from 'react'
import CustomerSkeletonRow from './CustomerRowSkeleton'
import CustomerFilters from 'customersV2/components/CustomerFilters'
import CustomerSort from 'customersV2/components/CustomerSort'
import { FaCaretLeft, FaCaretRight, FaTimes } from 'react-icons/fa'
import { FaMagnifyingGlass } from 'react-icons/fa6'

export default function CustomerList() {
    const inputeRef = useRef<HTMLInputElement>(null)
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [filters, setFilters] = useState<{ [key: string]: string[] }>({
        state: [],
        country: [],
        tags: [],
    })
    const [sortKey, setSortKey] = useState('id')
    const [sortDirection, setSortDirection] = useState('desc')
    const sort = sortKey ? `${sortKey}-${sortDirection}` : ''
    const debouncedSearch = useDebounce(search, 300)
    const queryParams = Object.entries({
        search: [debouncedSearch].filter((v) => v),
        ...filters,
    })
        .filter(([_key, val]) => val.length)
        .map(([key, val]) => `${key}=${encodeURIComponent(val.join(','))}`)
        .join('&')
    const customersQuery = useQuery({
        queryKey: ['customers', queryParams, page, sort],
        queryFn: () => vFetch(`/v2/customers?limit=50&page=${page}&sort=${sort}${queryParams && `&${queryParams}`}`),
    })
    const customers: CustomerType[] = customersQuery?.data?.customers || []

    const handleDecrementPage = () => {
        setPage((prev) => (prev > 1 ? prev - 1 : prev))
    }
    const handleIncrementPage = () => {
        setPage((prev) => (customers.length > 49 ? prev + 1 : prev))
    }
    return (
        <div className='text-sm rounded shadow-lg border border-lightgrey dark:border-darkgrey bg-white dark:bg-darkbg1 mb-16'>
            <div className='flex justify-between items-center pl-2'>
                {search ? (
                    <button className='text-red dark:text-lightred' onClick={() => setSearch('')}>
                        <FaTimes />
                    </button>
                ) : (
                    <button onClick={() => inputeRef.current?.focus()}>
                        <FaMagnifyingGlass />
                    </button>
                )}
                <input
                    ref={inputeRef}
                    type='text'
                    className='p-2 focus:outline-none w-full bg-transparent'
                    placeholder='Search for a customer...'
                    value={search}
                    onChange={({ target }) => {
                        setSearch(target.value)
                        setPage(1)
                    }}
                />
                <div className='flex gap-2 items-center self-center p-1'>
                    <CustomerFilters
                        filters={filters}
                        setFilters={(filters) => {
                            setFilters(filters)
                            setPage(1)
                        }}
                    />
                    <CustomerSort
                        sortKey={sortKey}
                        setSortKey={(key) => {
                            setSortKey(key)
                            setPage(1)
                        }}
                        sortDirection={sortDirection}
                        setSortDirection={(direction) => {
                            setSortDirection(direction)
                            setPage(1)
                        }}
                    />
                </div>
            </div>
            <div className='grid grid-cols-[2fr_1.5fr_3fr_1fr_1fr] text-white dark:text-offwhite font-bold text-xs bg-blue dark:bg-darkbg2 border-b border-lightgrey dark:border-darkgrey sticky top-[50px]'>
                <div className='p-2'>Name</div>
                <div className='p-2'>Email Subscription</div>
                <div className='p-2'>Location</div>
                <div className='p-2'>Orders Count</div>
                <div className='p-2'>Total Spent</div>
            </div>
            {customersQuery.isLoading ? (
                <>
                    {new Array(50).fill({}).map(() => (
                        <CustomerSkeletonRow />
                    ))}
                </>
            ) : (
                <>
                    {customers.map((customer) => (
                        <CustomerRow customer={customer} />
                    ))}
                </>
            )}
            <div className='fixed bottom-8 left-1/2 translate-x-[-50%] flex gap-2 items-center bg-white border rounded shadow-md border-lightgrey dark:bg-darkbg1 dark:border-darkgrey p-2'>
                <button
                    disabled={page === 1}
                    className='disabled:opacity-50 rounded border border-lightgrey dark:border-darkgrey text-xl p-2'
                    onClick={handleDecrementPage}
                >
                    <FaCaretLeft />
                </button>
                <div className='font-bold text-lg'>Page {page}</div>
                <button
                    disabled={customers.length < 50}
                    className='disabled:opacity-50 rounded border border-lightgrey dark:border-darkgrey text-xl p-2'
                    onClick={handleIncrementPage}
                >
                    <FaCaretRight />
                </button>
            </div>
        </div>
    )
}
