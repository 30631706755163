import { useQuery, useQueryClient } from '@tanstack/react-query'
import { MetafieldProps } from '../ProductSetup.types'
import ManualList from '../components/ManualList'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useDebounce, vFetch } from '../../helpers'
import { FaExternalLinkSquareAlt, FaTimes } from 'react-icons/fa'
import { IoMdRefresh } from 'react-icons/io'

export default function Manuals({ product, setProduct, handleUpdateMetafield, edited }: MetafieldProps) {
    const inputContainerRef = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const [search, setSearch] = useState('')
    const [showResults, setShowResults] = useState(false)
    const debouncedSearch = useDebounce(search, 500)
    const filesQuery = useQuery({
        queryKey: ['product', 'files', debouncedSearch],
        staleTime: 1,
        queryFn: () =>
            vFetch(`/v2/products/files?search=${debouncedSearch}`, {
                cb: (res: any) => {
                    if (!res.success) {
                        return []
                    }
                    return res.files
                },
                catchCb: () => [],
            }),
    })
    const files = filesQuery.data || []
    const parsedManuals = (product.manuals || []).map((manual) => {
        const name = manual.substring(0, manual.indexOf(':'))
        const href = manual.substring(manual.indexOf(':') + 1)
        return {
            name,
            href,
        }
    })
    const queryClient = useQueryClient()
    const refreshResults = () => {
        queryClient.invalidateQueries({ queryKey: ['product', 'files'] })
        setTimeout(() => {
            inputRef.current?.focus()
        }, 100)
    }
    const handleCloseResults = () => {
        setSearch('')
        setShowResults(false)
    }
    const handleUploadFile = async ({ target }: ChangeEvent<HTMLInputElement>) => {
        if (target.files) {
            const file = (target.files || [])[0]
            const data = new FormData()
            data.append('file', file, file.name)
            await vFetch(`/v2/products/files`, {
                method: 'POST',
                body: data,
                contentType: 'skip',
            })
            target.files = null
        }
    }
    const handleAddManual = (url?: string) => {
        setProduct((previousState) => {
            const newState = structuredClone(previousState)
            if (!newState.manuals) {
                newState.manuals = []
            }
            newState.manuals.push(`:${url || ''}`)
            return newState
        })
        setSearch('')
        setShowResults(false)
    }
    useEffect(() => {
        const listenToWindow = (e: any) => {
            if (!inputContainerRef.current?.contains(e.target)) {
                setSearch('')
                setShowResults(false)
            }
        }
        window.addEventListener('click', listenToWindow)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    return (
        <>
            <div className='w-full dark:bg-darkaccent p-[16px] rounded-[4px] shadow-small'>
                <p className='mt-[-16px] ml-[-16px] mb-[16px] p-[4px] leading-[1] w-fit p-[2px] text-[12px] uppercase font-bold bg-blue dark:bg-accent text-white dark:text-darkness'>
                    Manuals &amp; Documentation
                </p>
                {parsedManuals.length === 0 && (
                    <p className='dark:text-offwhite font-bold mb-[16px] dark:font-normal'>
                        Add links to any product manuals or documentation
                    </p>
                )}
                <div ref={inputContainerRef} className='relative w-full mb-2'>
                    <div className='flex gap-2 items-center border-b border-lightgrey dark:border-darkgrey'>
                        <input
                            ref={inputRef}
                            onFocus={() => setShowResults(true)}
                            className='bg-transparent border-0 focus:outline-none w-full py-2'
                            type='text'
                            value={search}
                            onChange={({ target }) => setSearch(target.value)}
                            placeholder='Search recent files...'
                        />
                        {(showResults || search.length > 0) && (
                            <button
                                className='py-1 px-2 border border-lightgrey dark:border-darkgrey rounded flex gap-1 items-center whitespace-nowrap shrink-0'
                                onClick={refreshResults}
                            >
                                Refresh Results
                                <IoMdRefresh className='text-xl' />
                            </button>
                        )}
                        {(showResults || search.length > 0) && (
                            <button
                                className='py-1 px-2 border border-lightgrey dark:border-darkgrey rounded flex gap-1 items-center whitespace-nowrap shrink-0'
                                onClick={handleCloseResults}
                            >
                                Close Results
                                <FaTimes className='text-xl text-red dark:text-lightred' />
                            </button>
                        )}
                        <div className='shrink-0 w-fit'>
                            <label
                                className='whitespace-nowwrap bg-blue dark:bg-darkgrey cursor-pointer text-white dark:text-offwhite font-bold py-[6px] px-2 rounded'
                                htmlFor='file'
                            >
                                Upload File
                            </label>
                            <input
                                className='appearance-none w-full h-full hidden'
                                id='file'
                                onChange={handleUploadFile}
                                type='file'
                            />
                        </div>
                    </div>
                    {(showResults || search.length > 0) && (
                        <div className='flex flex-col gap-2 shadow-md border border-t-0 border-lightgrey dark:border-darkgrey rounded-t-none rounded absolute top-full left-0 max-h-64 bg-white dark:bg-darkbg1 p-2 z-10 overflow-auto w-full'>
                            {filesQuery.isFetching || filesQuery.isLoading || filesQuery.isPending ? (
                                <p>Loading...</p>
                            ) : (
                                <>
                                    {files.map((file: any) => (
                                        <div className='flex gap-2 items-center'>
                                            <button onClick={() => handleAddManual(file.url)}>
                                                <FaExternalLinkSquareAlt className='rotate-[270deg]' />
                                            </button>
                                            <p className='font-bold'>
                                                {file.url?.split('/')[file.url.split('/').length - 1]}
                                                <span className='font-normal'> ({file.mimeType?.split('/')[1]})</span>
                                            </p>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    )}
                </div>

                <ManualList manuals={parsedManuals} setProduct={setProduct} />
                <button
                    className='text-blue border-blue uppercase font-bold dark:text-fire border-2 dark:border-fire py-[2px] px-[8px] rounded-[4px]'
                    onClick={() => handleAddManual()}
                >
                    + Manual
                </button>
                {edited && (
                    <button
                        className='block py-[4px] px-[8px] bg-blue dark:bg-accent text-white dark:text-darkaccent rounded-[4px] mx-auto mt-[16px] font-bold uppercase'
                        onClick={() =>
                            handleUpdateMetafield([
                                {
                                    ownerId: `gid://shopify/Product/${product.id}`,
                                    namespace: 'custom',
                                    key: 'manuals',
                                    type: 'list.single_line_text_field',
                                    value: JSON.stringify(product.manuals),
                                },
                            ])
                        }
                    >
                        Save Changes
                    </button>
                )}
            </div>
            {parsedManuals.length > 0 && (
                <>
                    <h2 className='dark:text-offwhite font-light uppercase text-[24px] my-[16px]'>Manuals Preview</h2>
                    <div className='bg-white description-preview description-preview--full w-fit !m-0 dark:opacity-[0.9] dark:text-black'>
                        <div className='pdp__manuals__grid'>
                            {parsedManuals.map((m) => (
                                <a
                                    className='pdp__manuals__grid__manual'
                                    href={m.href}
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    <div className='pdp__manuals__grid__manual__image-container'>
                                        <img
                                            className='pdp__manuals__grid__manual__image-container__img'
                                            src='https://cdn.shopify.com/s/files/1/1163/1976/files/pdf-icon.png?v=1611684687'
                                            alt='manual icon'
                                            width='auto'
                                            height='auto'
                                            loading='lazy'
                                        />
                                    </div>
                                    <p className='pdp__manuals__grid__manual__text'>{m.name}</p>
                                </a>
                            ))}
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
