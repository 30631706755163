import { Button } from 'custom_components/component_Basics/Button'
import Input from 'procurement/components/Input'
import { useEffect, useRef, useState } from 'react'
import { FaTimes, FaTrash } from 'react-icons/fa'
import { ChangelogEntry } from '../Changelog.types'
import Textarea from 'procurement/components/Textarea'
import Select from 'procurement/components/Select'
import { useDeleteEntry, useGetEntries, useUpdateEntry } from 'changelog/api/useQueries'

export default function EditEntryModal({ entry_id, closeModal }: { entry_id: number; closeModal: () => void }) {
    const modalRef = useRef<HTMLDivElement>(null)
    const entryQuery = useGetEntries({
        id: entry_id,
    })
    const entry = entryQuery.data?.entries[0] || ({} as ChangelogEntry)
    const [editedFields, setEditedFields] = useState<any>({})
    const [showDelete, setShowDelete] = useState(false)
    const updateEntryMutation = useUpdateEntry()
    const deleteEntryMutation = useDeleteEntry()

    const handleChange = ({ target }: { target: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement }) => {
        setEditedFields((prev: any) => ({
            ...prev,
            [target.name]: target.value,
        }))
    }

    const handleUpdate = () => {
        updateEntryMutation.mutate({
            ...editedFields,
            id: entry_id,
        })
    }

    const handleDelete = () => {
        deleteEntryMutation.mutate(entry_id)
    }

    useEffect(() => {
        const listenToWindow = (event: MouseEvent) => {
            if (event.target === modalRef.current) {
                closeModal()
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])
    return (
        <div
            ref={modalRef}
            className='fixed top-0 left-0 w-full h-full bg-black/50 z-50 flex items-center justify-center'
        >
            <div className='bg-bg1 dark:bg-darkbg1 p-6 rounded shadow-md relative max-w-[90%] max-h-[90%] min-w-[400px]'>
                <button onClick={closeModal} className='text-lg text-red dark:text-lightred absolute top-1 right-1'>
                    <FaTimes />
                </button>
                <h2 className='text-lg font-bold mb-4'>Edit Changelog Entry</h2>
                <div className='flex flex-col gap-1'>
                    <div className='flex gap-1'>
                        <Select
                            disabled={!!entry.release_id}
                            id='type'
                            name='type'
                            value={editedFields.type ?? entry.type}
                            onChange={handleChange}
                            label='Type'
                        >
                            <option value=''>Select a type</option>
                            <option value='patch'>Patch</option>
                            <option value='minor'>Feature</option>
                            <option value='major'>Major / Breaking Change</option>
                        </Select>
                        <Select
                            disabled={!!entry.release_id}
                            id='status'
                            name='status'
                            value={editedFields.status ?? entry.status}
                            onChange={handleChange}
                            label='Status'
                        >
                            <option value=''>Select a status</option>
                            <option value='pending'>Pending Review</option>
                            <option value='published'>Published</option>
                        </Select>
                    </div>
                    <Input
                        id='title'
                        name='title'
                        type='text'
                        value={editedFields.title ?? entry.title}
                        onChange={handleChange}
                        label='Title'
                    />
                    <Textarea
                        id='description'
                        name='description'
                        value={editedFields.description ?? entry.description}
                        onChange={handleChange}
                        label='Description'
                    />
                </div>
                <div className='flex justify-between items-end'>
                    <Button className='block mt-4' onClick={handleUpdate}>
                        Save Changes
                    </Button>
                    {!entry.release_id && (
                        <div className='flex gap-2'>
                            {showDelete ? (
                                <>
                                    <Button className='h-fit p-1' variant='destructive' onClick={handleDelete}>
                                        Delete
                                    </Button>
                                    <Button
                                        className='h-fit p-1'
                                        variant='outline'
                                        onClick={() => setShowDelete(false)}
                                    >
                                        Cancel
                                    </Button>
                                </>
                            ) : (
                                <button className='text-red dark:text-lightred' onClick={() => setShowDelete(true)}>
                                    <FaTrash />
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
