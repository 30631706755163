import { useEffect, useRef, useState } from 'react'
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'
import { BiLeftArrowAlt, BiRightArrowAlt, BiSolidArrowToLeft } from 'react-icons/bi'
import { BsListTask } from 'react-icons/bs'
import { FaRegCommentDots } from 'react-icons/fa'
import { TailSpin } from 'react-loading-icons'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import FiltersMenu from '../custom_components/FiltersMenu'
import { DayMap, MonthMap, dayMap, formatMoney, monthMap, parseResObject, vFetch } from '../helpers'

import { TbCircle } from 'react-icons/tb'
import { Filter } from '../custom_components/FiltersMenu'
import { DraftOrderInit } from '../orders/orders.types'

function renderIconMessage(iconMessage: string | React.ReactElement) {
    let unparsedMessage = iconMessage
    let parsedMessage = []

    if (typeof unparsedMessage === 'string') {
        while (unparsedMessage.match('\n')) {
            parsedMessage.push(unparsedMessage.slice(0, unparsedMessage.indexOf('\n')))
            parsedMessage.push(<br></br>)
            unparsedMessage = unparsedMessage.replace(unparsedMessage.slice(0, unparsedMessage.indexOf('\n') + 1), '')
        }
    }
    parsedMessage.push(unparsedMessage)
    return parsedMessage
}

type IconMessage = [string, string | Element | HTMLElement | React.ReactElement] | false

export default function DraftOrdersListScreen() {
    const navigate = useNavigate()
    const [params] = useSearchParams()
    const [initRender, setInitRender] = useState<boolean>(true)
    const [startDate, setStartDate] = useState(
        params.get('startDate')
            ? new Date(params.get('startDate') || '')
            : new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59, 999)
    )
    const [orders, setOrders] = useState<DraftOrderInit[]>([])
    const [filteredOrders, setFilteredOrders] = useState<DraftOrderInit[]>([])
    const [firstOrderID, setFirstOrderID] = useState<string>('')
    const [selected, setSelected] = useState<string[]>([])
    const [iconMessage, setIconMessage] = useState<IconMessage>(false)
    const [showLineItems, setShowLineItems] = useState<string>('')
    const [startID, setStartID] = useState<string | number | null | undefined>(params.get('startID') || '')
    const [endID, setEndID] = useState<string | number | null | undefined>(params.get('endID') || '')
    const [loading, setLoading] = useState<boolean>(true)
    const [search, setSearch] = useState('')

    const [filterByPStatus, setFilterByPStatus] = useState(
        params
            .get('pStatus')
            ?.split(',')
            .filter((v) => v) || []
    )
    const [filterByPStatusOptions, setFilterByPStatusOptions] = useState([
        'open',
        'completed',
        'invoice_sent',
        'submitted_for_review',
    ])
    const [showFilterByPStatusOptions, setShowFilterByPStatusOptions] = useState(false)

    const pStatusFilter = new Filter(
        'Filter by Status',
        filterByPStatus,
        setFilterByPStatus,
        filterByPStatusOptions,
        showFilterByPStatusOptions,
        setShowFilterByPStatusOptions,
        useRef(null)
    )
    const filters = [pStatusFilter]
    filters.forEach((filter: Filter, index: number) => (filter.zIndex = filters.length - index))
    const filterProps = {
        startDate: startDate,
        setStartDate: setStartDate,
        endDate: startDate,
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!(e.target as HTMLElement).classList.contains('js-items-dropdown')) {
                setShowLineItems('')
            }
        }
        window.addEventListener('click', listenToWindow)

        setInitRender(false)

        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    function refresh(direction: any) {
        setLoading(true)
        const resPStatusOptions = [...filterByPStatusOptions]
        vFetch(
            `/v1/draftOrders/limited?firstOrderID=${firstOrderID}&startID=${startID}&endID=${endID}&direction=${direction}&pStatus=${filterByPStatus}` +
                `&startDate=${startDate.toISOString()}&endDate=${new Date(
                    new Date(startDate).getFullYear(),
                    new Date(startDate).getMonth(),
                    new Date(startDate).getDate(),
                    23,
                    59,
                    59,
                    999
                ).toISOString()}`,
            {
                cb: (res: any) => {
                    if (res.success) {
                        const resOrders = res.draft_orders.map((order: any) => parseResObject(order))
                        if (res.first_order_id) setFirstOrderID(res.first_order_id)
                        setOrders(resOrders)
                        setFilteredOrders(resOrders)
                        setFilterByPStatusOptions(
                            resPStatusOptions.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                        )
                    }
                    setLoading(false)
                },
                catchCb: () => setLoading(false),
            }
        )
    }
    useEffect(() => {
        refresh('')
    }, [])

    useEffect(() => {
        if (orders.length) {
            setStartID(orders[0].id)
            setEndID(orders[orders.length - 1]?.id)
            window.history.pushState(
                undefined,
                '',
                `draftOrders?startID=${startID}&endID=${endID}&pStatus=${filterByPStatus}&startDate=${startDate.toISOString()}&endDate=${startDate.toISOString()}`
            )
        }
    }, [orders])

    useEffect(() => {
        if (initRender === false) {
            window.history.pushState(
                undefined,
                '',
                `draftOrders?startID=${startID}&endID=${endID}&pStatus=${filterByPStatus}&startDate=${startDate.toISOString()}&endDate=${startDate.toISOString()}`
            )
            refresh('')
            setStartID(undefined)
            setEndID(undefined)
        }
    }, [startDate])

    useEffect(() => {
        refresh('')
        window.history.pushState(
            undefined,
            '',
            `draftOrders?startID=${startID}&endID=${endID}&pStatus=${filterByPStatus}&startDate=${startDate.toISOString()}&endDate=${startDate.toISOString()}`
        )
    }, [filterByPStatus])

    useEffect(() => {
        if (initRender === false) {
            setStartID('')
            setEndID('')
        }
    }, [filterByPStatus])

    function handleCheck(event: MouseEvent) {
        let target = event.target as HTMLInputElement
        if (target.name === 'all' && target.checked) {
            return setSelected(orders.map((order) => String(order.id)))
        } else if (target.name === 'all' && !target.checked) {
            return setSelected([])
        }

        if (target.id) {
            const checkbox = target.childNodes[0] as HTMLInputElement
            if (checkbox.name === 'all' && !checkbox.checked) {
                checkbox.checked = true
                return setSelected(orders.map((order) => String(order.id)))
            } else if (checkbox.name === 'all' && checkbox.checked) {
                checkbox.checked = false
                return setSelected([])
            } else if (checkbox.checked) {
                return setSelected(selected.filter((selected) => selected !== checkbox.name))
            } else {
                return setSelected([...selected, checkbox.name])
            }
        }

        if (target.checked) {
            setSelected([...selected, target.name])
        } else {
            setSelected(selected.filter((selected) => selected !== target.name))
        }
    }

    function handleNavigate(e: MouseEvent, orderId: number | undefined) {
        if (e.ctrlKey) return
        e.preventDefault()
        if (
            !(e.target as HTMLElement).classList.contains('js-items-dropdown') &&
            !(e.target as HTMLElement).classList.contains('js-tags-dropdown') &&
            !(e.target as HTMLElement).classList.contains('js-dont-navigate')
        ) {
            window.history.pushState(
                undefined,
                '',
                `draftOrders?startID=${startID}&endID=${endID}&pStatus=${filterByPStatus}&startDate=${startDate.toISOString()}&endDate=${startDate.toISOString()}`
            )
            navigate(`/draftOrders/${orderId}`)
        }
    }

    function filterResults(order: any) {
        if (!order.line_items) {
            return
        }
        let matches = [...order.line_items].filter(
            (line_item) => line_item.sku?.toLowerCase().includes(search.toLowerCase())
        )
        if (matches.length) {
            return order
        }
    }
    useEffect(() => {
        let filteredResults = [...orders].filter((order) => filterResults(order))
        if (filteredResults.length) {
            setFilteredOrders(filteredResults)
        }
        if (search.length === 0) {
            setFilteredOrders(orders)
        }
    }, [search])

    return (
        <div className='relative'>
            {loading && (
                <div className='grid fixed top-[50px] left-[216px] w-[calc(100%-216px)] h-[100%] justify-center items-center bg-[rgba(0,0,0,0.2)] z-50'>
                    <TailSpin stroke={'#42EFD0'} />
                </div>
            )}
            <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Draft Orders</h1>
            <div className='flex items-center justify-between h-[50px] gap-x-[8px] font-bai dark:text-offwhite'>
                <div className='flex gap-[16px]'>
                    <>
                        <h2 className='font-bold text-[24px]'>{getDate()}</h2>
                        {resetDateButton()}
                    </>
                </div>
            </div>

            <FiltersMenu filters={filters} ex={filterProps} />
            <div className='flex col-span-full p-[8px] mb-4 bg-[#f5f5f5] text-black dark:bg-darkaccent border-grey dark:border-darkgrey dark:text-offwhite'>
                <input
                    className='focus:outline-none w-full bg-transparent'
                    type='text'
                    placeholder='Search by SKU'
                    value={search}
                    onChange={({ target }) => setSearch(target.value)}
                />
            </div>

            <div className='pb-[32px]'>
                <div className='grid grid-cols-[30px_70px_minmax(100px,120px)_75px_minmax(90px,150px)_105px_164px_175px_50px] relative justify-between bg-blue rounded-t dark:bg-darkaccent text-white text-[12px] font-bai font-extrabold border-[1px] border-darkgrey cursor-pointer'>
                    <div
                        className='js-dont-navigate grid items-center justify-center cursor-default'
                        onClick={(e: any) => handleCheck(e)}
                        id='all'
                    >
                        <input type='checkbox' name='all' onChange={(e: any) => handleCheck(e)} />
                    </div>
                    <div className='p-[8px]'>Order #</div>
                    {/* for spacing */}
                    <div></div>
                    <div className='p-[8px]'>Date</div>
                    <div className='p-[8px]'>Customer</div>
                    <div className='p-[8px]'>Total</div>
                    <div className='p-[8px]'>Status</div>
                    <div className='p-[8px]'>Rep</div>
                    <div className='p-[8px]'>Items</div>
                </div>

                <div className='border-[1px] border-darkgrey'>
                    {filteredOrders.map((order) => (
                        <NavLink
                            key={order.id}
                            onClick={(e: any) => handleNavigate(e, order.id)}
                            className={`grid grid-cols-[30px_70px_minmax(100px,120px)_75px_minmax(90px,150px)_105px_164px_175px_50px] items-center justify-between text-[14px] font-bai  cursor-pointer hover:bg-[rgb(199,202,209)] [&:nth-child(even)]:bg-[rgb(224,228,235)] [&:nth-child(even)]:hover:bg-[rgb(199,202,209)] dark:[&:nth-child(even)]:bg-darkaccent dark:[&:nth-child(even)]:hover:bg-blue dark:bg-darkness dark:text-offwhite dark:hover:bg-blue`}
                            to={`/draftOrders/${order.id}`}
                        >
                            <div
                                className='js-dont-navigate grid items-center justify-center cursor-default h-[100%]'
                                onClick={(e: any) => handleCheck(e)}
                                id={String(order.id)}
                            >
                                <input
                                    className='js-dont-navigate'
                                    type='checkbox'
                                    onChange={(e: any) => handleCheck(e)}
                                    checked={selected.includes(String(order.id))}
                                    name={String(order.id)}
                                />
                            </div>
                            <div className='p-[8px]'>{order.name}</div>
                            <div className='js-orders-icon-list relative py-[8px] flex gap-[8px] items-center h-[100%] w-[100%]'>
                                <div className='flex gap-[8px] items-center'>
                                    {order.note && (
                                        <div
                                            className='js-orders-icon'
                                            onMouseOver={() => {
                                                setIconMessage([order.name, `${order.note}`])
                                            }}
                                        >
                                            <svg
                                                className='stroke-black dark:stroke-accent pointer-events-none'
                                                stroke='currentColor'
                                                fill='currentColor'
                                                strokeWidth='0'
                                                viewBox='0 0 24 24'
                                                height='1em'
                                                width='1em'
                                                xmlns='http://www.w3.org/2000/svg'
                                            >
                                                <path
                                                    className='pointer-events-none'
                                                    fill='none'
                                                    strokeWidth='2'
                                                    d='M3,1 L3,23 L16,23 L21,18 L21,1 L3,1 Z M6,17 L11,17 M6,13 L18,13 M6,9 L16,9 M3,5 L21,5 M21,17 L15,17 L15,23'
                                                ></path>
                                            </svg>
                                        </div>
                                    )}
                                    {order.has_timeline_comment ? (
                                        <FaRegCommentDots
                                            onMouseOver={() => {
                                                setIconMessage([order.name, 'This order has timeline comments.'])
                                            }}
                                            className='dark:fill-accent h-[14px] w-[14px]'
                                        />
                                    ) : undefined}
                                    {order.tasks && (
                                        <BsListTask
                                            className='h-[16px] w-[16px] dark:fill-accent'
                                            onMouseOver={() => {
                                                setIconMessage([
                                                    order.name,
                                                    <span>
                                                        This order has{' '}
                                                        {order?.tasks && order?.tasks?.length > 1
                                                            ? `${order.tasks?.length} tasks`
                                                            : 'a task'}
                                                        :{' '}
                                                        {order?.tasks &&
                                                            order?.tasks.map((task) => (
                                                                <span
                                                                    key={`${order.id}-${task}`}
                                                                    className='js-dont-navigate text-blue dark:text-accent hover:underline cursor-pointer font-semibold mr-[4px]'
                                                                    onContextMenu={() => {
                                                                        window.open(`/tasks/${task}`)
                                                                    }}
                                                                    onClick={() => {
                                                                        ;(window.event as MouseEvent).ctrlKey
                                                                            ? window.open(`/tasks/${task}`)
                                                                            : navigate(`/tasks/${task}`)
                                                                    }}
                                                                >
                                                                    {task}
                                                                </span>
                                                            ))}
                                                    </span>,
                                                ])
                                            }}
                                        />
                                    )}
                                </div>
                                <div
                                    className='flex grow h-[100%] shrink-0'
                                    onMouseOver={() => {
                                        setIconMessage(false)
                                    }}
                                />
                                {iconMessage[0 as keyof IconMessage] === order.name && (
                                    <div className='hidden js-orders-icon-message absolute hover:flex p-[16px] min-w-[350px] top-[calc(95%-16px)] left-[-36px] cursor-auto js-dont-navigate'>
                                        <div className='left-[0px] top-[0px] js-orders-icon p-[8px] min-w-[300px] bg-white dark:bg-darkaccent border-darkgrey border-[1px] text-black dark:text-offwhite z-index-4 shadow-small js-dont-navigate'>
                                            {renderIconMessage(iconMessage[1 as keyof IconMessage])}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='p-[8px]'>
                                {[
                                    order.created_at.getMonth() + 1,
                                    order.created_at.getDate(),
                                    order.created_at.getFullYear(),
                                ].join('/')}{' '}
                                {[order.created_at.getHours() % 12, order.created_at.getMinutes()].join(':')}{' '}
                                {order.created_at.getHours() >= 12 ? 'PM' : 'AM'}
                            </div>
                            <div className='p-[8px]'>
                                {order?.customer &&
                                    `${order.customer.first_name ? order.customer.first_name : ''} ${
                                        order.customer.last_name ? order.customer.last_name : ''
                                    }`}
                            </div>
                            <div className='p-[8px]'>{formatMoney(Number(order.subtotal_price))}</div>

                            <div className='p-[8px]'>
                                {order.status[0].toUpperCase() + order.status.replace('_', ' ').slice(1)}
                            </div>
                            <div className='p-[8px]'>{order.representative_name}</div>

                            <div className='flex relative p-[8px] h-[100%] w-[100%] items-center justify-center'>
                                {order.line_items
                                    .map((item) => {
                                        return Number(item.quantity)
                                    })
                                    .reduce((acc: number, cur: number) => acc + cur, 0)}
                                <div
                                    className='js-items-dropdown absolute flex w-[100%] h-[100%] hover:opacity-[1] opacity-0 bg-[rgba(0,0,0,0.1)] items-center justify-end'
                                    onClick={() => {
                                        showLineItems !== order.name
                                            ? setShowLineItems(order.name)
                                            : setShowLineItems('')
                                    }}
                                >
                                    {showLineItems === order.name ? (
                                        <AiFillCaretUp className='pointer-events-none' />
                                    ) : (
                                        <AiFillCaretDown className='pointer-events-none' />
                                    )}
                                </div>
                                {showLineItems === order.name && (
                                    <div className='[&>*]:pointer-events-none js-items-dropdown grid absolute top-[calc(100%-5px)] min-w-[300px] w-[25vw] right-[0] p-[8px] text-black bg-white shadow-small dark:text-offwhite dark:bg-darkaccent dark:border-[1px] dark:border-darkgrey z-index-2 cursor-auto'>
                                        <span className='js-items-dropdown inline font-bold text-[16px] w-[auto] text-end'>
                                            Items
                                        </span>
                                        {order.line_items.filter(
                                            (item) =>
                                                !item.fulfillment_status || item.fulfillment_status === 'unfulfilled'
                                        ).length > 0 && (
                                            <>
                                                <div className='js-items-dropdown w-[40px] flex gap-[4px] items-center bg-fire text-darkgrey px-[4px] rounded-full mb-[16px]'>
                                                    <TbCircle className='js-items-dropdown' />
                                                    <span className='js-items-dropdown inline-block w-[15px] font-bold text-center'>
                                                        U
                                                    </span>
                                                </div>
                                                <div className='js-items-dropdown grid gap-[16px]'>
                                                    {order.line_items.map((item) => {
                                                        if (
                                                            item.fulfillment_status === 'scheduled' ||
                                                            item.fulfillment_status === 'unfulfilled' ||
                                                            (!item.fulfillment_status && item.requires_shipping)
                                                        ) {
                                                            return (
                                                                <div
                                                                    key={`${order.id}-${item.id}`}
                                                                    className='js-items-dropdown flex gap-[16px]'
                                                                >
                                                                    <div className='js-items-dropdown relative flex items-center'>
                                                                        <img
                                                                            className='js-items-dropdown object-contain max-h-[75px] max-w-[75px]'
                                                                            src={
                                                                                window.IMAGE_MAP[
                                                                                    `gid://shopify/Product/${item.product_id}`
                                                                                ]
                                                                            }
                                                                            alt={item.name}
                                                                            height='100px'
                                                                            width='100px'
                                                                        />
                                                                        <span className='js-items-dropdown absolute flex top-[-10px] right-[-10px] min-w-[20px] min-h-[20px] text-[12px] leading-[1px] items-center pb-[2px] border-[5px] border-[darkgrey] bg-[darkgrey] text-white font-bold rounded-full justify-center'>
                                                                            {item.quantity}
                                                                        </span>
                                                                    </div>
                                                                    <div className='js-items-dropdown my-[12px]'>
                                                                        <a
                                                                            href={`https://factorypure.myshopify.com/admin/products/${item.product_id}`}
                                                                            target='_blank'
                                                                            className='js-dont-navigate js-items-dropdown font-semibold hover:underline hover:text-blue dark:hover:text-accent pointer-events-auto'
                                                                        >
                                                                            {item.name}
                                                                        </a>
                                                                        <p className='js-items-dropdown pt-[4px]'>
                                                                            <strong className='js-items-dropdown'>
                                                                                SKU:
                                                                            </strong>{' '}
                                                                            {item.sku}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        </NavLink>
                    ))}
                </div>

                <div className='flex fixed bottom-[8px] left-[calc(50%+216px)] translate-x-[calc(-50%-108px)] p-[8px] bg-white dark:bg-darkness border-[1px] border-darkgrey gap-[16px] justify-center items-center mt-[16px] rounded'>
                    {orders[0] && (orders[0].id ? Number(firstOrderID) > orders[0].id : false) && (
                        <>
                            <div
                                className='grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                                onClick={() => {
                                    refresh('home')
                                }}
                            >
                                <BiSolidArrowToLeft className='fill-darkgrey dark:fill-accent' />
                            </div>
                            <div
                                className='grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                                onClick={() => {
                                    refresh('backward')
                                }}
                            >
                                <BiLeftArrowAlt className='fill-darkgrey dark:fill-accent' />
                            </div>
                        </>
                    )}
                    {orders.length > 0 && (
                        <div className='font-bold dark:text-offwhite'>
                            {orders[0].name} - {orders[orders.length - 1].name}
                        </div>
                    )}
                    {(orders.length >= 50 || (orders[0] && orders[0].id == Number(firstOrderID))) && (
                        <div
                            className='grid border-[1px] border-darkgrey dark:border-accent w-[30px] h-[30px] rounded items-center justify-center cursor-pointer'
                            onClick={() => {
                                refresh('forward')
                            }}
                        >
                            <BiRightArrowAlt className='fill-darkgrey dark:fill-accent' />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )

    function resetDateButton() {
        return (
            startDate.toISOString() !==
                new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate(),
                    23,
                    59,
                    59,
                    999
                ).toISOString() && (
                <button
                    className='dark:text-accent font-semibold py-[8px] px-[16px] rounded hover:bg-offwhite dark:hover:bg-darkaccent'
                    onClick={() =>
                        setStartDate(
                            new Date(
                                new Date().getFullYear(),
                                new Date().getMonth(),
                                new Date().getDate(),
                                23,
                                59,
                                59,
                                999
                            )
                        )
                    }
                >
                    Reset Date
                </button>
            )
        )
    }

    function getDate() {
        return startDate
            .toDateString()
            .split(' ')
            .map((dateItem) =>
                dayMap[dateItem as keyof DayMap]
                    ? `${dayMap[dateItem as keyof DayMap]}, `
                    : monthMap[dateItem as keyof MonthMap]
                      ? `${monthMap[dateItem as keyof MonthMap]}`
                      : Number(dateItem) < 32
                        ? `${Number(dateItem)},`
                        : dateItem
            )
            .join(' ')
    }
}
