export default function RenderProductSetupValue({
    value,
    state,
}: {
    value: string | number | string[] | { key: [string] }[]
    state: string
}) {
    if (Array.isArray(value)) {
        let HTML = ''
        value.forEach((item) => {
            if (item && typeof item == 'object') {
                Object.entries(item).map(([key, value]) => {
                    HTML += `${key} - ${value}, `
                })
                HTML += `  `
            } else {
                HTML += `${item}${' '}`
            }
        })

        return (
            <div>
                {state}
                <>
                    <p>{HTML}</p>
                </>
            </div>
        )
    } else {
        return (
            <p>
                {state} {value}
            </p>
        )
    }
}
