import { CategoryScale } from 'chart.js'
import Chart from 'chart.js/auto'
import { useState } from 'react'
import { DateRange } from 'react-day-picker'
import { useSearchParams } from 'react-router-dom'
import { useAllUsersQuery } from '../../users/api/useQueries'
import { UserInit } from '../../users/users.types'
import { BarChart } from '../components/BarChart'
import { GraphContainer } from '../components/GraphContainer'
import LineChart from '../components/LineChart'
import { DATE_PICKER_OPTIONS, PLACEHOLDER_DATA_INSERT, formatChartDataByStatus } from '../helpers'
import Table from '../reports/Table'

Chart.register(CategoryScale)

export default function GenericAnalyticsTotals({
    analyticsObjects,
    user_ids,
    dateRange,
    dateRangeCompare,
    size,
    chartTitle,
}: {
    analyticsObjects: {
        analyticsGetFunction: Function
        label?: string
        queryOptions: {
            tag: string
            value: string[]
            title: string
        }[]

        fieldOptions?: {
            tag: string
            value: string[]
            title: string
        }[][]
    }[]
    user_ids: any
    dateRange: DateRange | undefined
    dateRangeCompare: DateRange | undefined
    size: 'small' | 'medium' | 'large'
    chartTitle: string
}) {
    let [searchParams, setSearchParams] = useSearchParams()
    const interval = searchParams.get('interval') || '0'
    const [timePeriodIndex, setTimePeriodIndex] = useState(parseInt(interval))
    const optionIndexParam: string = searchParams.get('option') || '2'
    const [queryOptionsIndex, setQueryOptionsIndex] = useState(0)
    const [chartType, setChartType] = useState<'bar' | 'line' | 'table'>('bar')

    const allUsersQuery = useAllUsersQuery()
    const { users }: { users: UserInit[] } = allUsersQuery?.data || {}

    const { analyticsGetFunction, queryOptions, fieldOptions } = analyticsObjects[0]

    chartTitle = chartTitle + `- ${DATE_PICKER_OPTIONS[parseInt(optionIndexParam)].label}`
    const analyticsQuery = analyticsGetFunction({
        user_ids,
        dateRange,
        dateRangeCompare,
        fields: fieldOptions ? fieldOptions[queryOptionsIndex] : '',
    })
    const { analytics: analyticsData }: { analytics: any } = analyticsQuery?.data || {}

    const chartData =
        formatChartDataByStatus(
            analyticsData,
            dateRangeCompare,
            users,
            user_ids,
            fieldOptions ? fieldOptions[queryOptionsIndex].map((option: any) => option.title) : []
        ) ?? PLACEHOLDER_DATA_INSERT

    return (
        <GraphContainer
            timePeriodIndex={timePeriodIndex}
            setTimePeriodIndex={setTimePeriodIndex}
            options={queryOptions}
            setOptionsIndex={setQueryOptionsIndex}
            setChartType={setChartType}
            chartType={chartType}
        >
            {chartType === 'bar' && <BarChart chartTitle={chartTitle} chartData={chartData} size={size} />}
            {chartType === 'line' && <LineChart chartTitle={chartTitle} chartData={chartData} size={size} />}
        </GraphContainer>
    )
}
