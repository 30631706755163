import { useState } from 'react'
import { createPortal } from 'react-dom'

const CustomIframe = ({ children, expand, setExpand, ...props }: any) => {
    const [contentRef, setContentRef] = useState<any>(null)

    const mountNode = contentRef?.contentWindow?.document?.body

    return (
        <div className='relative'>
            <div
                className={` absolute top-0 left-0 w-full ${
                    expand
                        ? 'z-[5] bg-bg1 dark:bg-darkbg2 shadow-small  rounded-[4px]'
                        : 'h-full after:w-full after:h-[16px] after:bg-[linear-gradient(to_top,white,transparent)] dark:after:bg-[linear-gradient(to_top,#1D212D,transparent)] after:absolute after:bottom-0 after:left-0'
                }`}
                onClick={() => setExpand(true)}
            ></div>
            <iframe
                title='email-container'
                className={` cursor-pointer w-full ${
                    expand
                        ? 'h-[450px] max-h-fit bg-bg1 dark:bg-[#f8f0e3] overflow-visible border border-accent1 dark:border-darkaccent2'
                        : '!h-full !overflow-hidden'
                } px-[8px] text-[16px] leading-1 bg-transparent focus:outline-none dark:text-white  py-[4px] rounded-[4px]`}
                {...props}
                ref={setContentRef}
            >
                {mountNode && createPortal(children, mountNode)}
            </iframe>
        </div>
    )
}

export default CustomIframe
