import { useState } from 'react'
import { useParentCompanyDetailTasksQuery } from '../../api/useQueries'
import TaskListItem from '../listItems/TaskListItem'
import { CompanyTaskType } from '../modals/quickActions/CreateTask'
import FilterToolBar from '../FilterToolBar'

export default function ParentTaskList({ parentCompany, companyNameMap }: { parentCompany: any; companyNameMap: any }) {
    const [search, setSearch] = useState('')

    const companyTasksQuery = useParentCompanyDetailTasksQuery({ parentCompany, companyNameMap })
    const { isLoading } = companyTasksQuery
    const { tasks }: { tasks: CompanyTaskType[] } = companyTasksQuery?.data || {}

    const filteredTasks = tasks?.filter((task) => {
        return task.title.toLowerCase().includes(search.toLowerCase())
    })
    return (
        <div className='p-[16px] pl-0 overflow-auto'>
            <div className='mb-[8px] w-full flex gap-[16px]'>
                <FilterToolBar
                    search={search}
                    setSearch={setSearch}
                    filterBarObject={undefined}
                    className={'border border-blue/25 dark:border-0 dark:bg-darkbg2 rounded-[4px]'}
                />
            </div>
            <div className='flex flex-col gap-[8px]'>
                {!isLoading &&
                    filteredTasks?.map((task) => <TaskListItem key={task.id} task={task} urlParamTaskId={undefined} />)}
            </div>
        </div>
    )
}
