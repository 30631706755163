import { Button } from 'custom_components/component_Basics/Button'
import InputV2 from 'procurement/components/InputV2'
import SelectV2 from 'procurement/components/SelectV2'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useContactTypesQuery, useUpdateContact } from '../../procurement/api/useQueries'
import { FetchingSpinner } from 'custom_components/FetchingSpinner'
import { cn } from 'helpers'

type VendorContactFieldsProps = {
    contact: any
    setShowDetails: any
    updateContact: any
}

export default function VendorContactFieldsv2({ contact, setShowDetails, updateContact }: VendorContactFieldsProps) {
    const contactTypesQuery = useContactTypesQuery()
    const { types: contactTypes } = contactTypesQuery?.data || {}

    interface IFormValues {
        contact_type: string
        name: string
        phone: string
        email: string
        alternate: string
        company_id: string | number
    }

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<IFormValues>({
        values: {
            contact_type: contact.contact_type,
            name: contact?.name?.trim(),
            phone: contact?.phone?.trim(),
            email: contact?.email?.trim(),
            alternate: contact?.alternate ? contact?.alternate?.trim() : '',
            company_id: contact?.company_id,
        },
    })

    const onSubmit: SubmitHandler<IFormValues> = (data) => {
        updateContact.mutate(
            { contact: { id: contact.id, store_id: contact.store_id, ...data } },
            {
                onSettled: () => {
                    return setShowDetails(false)
                },
            }
        )
    }

    const handleReset = () => {
        setValue('contact_type', contact?.contact_type)
        setValue('name', contact?.name?.trim())
        setValue('phone', contact?.phone?.trim())
        setValue('email', contact?.email?.trim())
        setValue('alternate', contact?.alternate?.trim())
    }

    const labelStyle = 'font-bai text-[12px] font-bold text-[#4a4a4a] uppercase dark:text-offwhite'
    const inputStyle =
        'block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent'

    return (
        <div className='grid gap-[16px] [&>*]:border-t-[1px] [&>*]:border-darkgrey [&>*]:dark:border-fire [&>*:first-child]:border-[0px] [&>*:last-child]:border-[0px]'>
            <form
                id='contact_form'
                className={cn(
                    'grid p-1 gap-2 bg-white dark:bg-darkaccent',
                    updateContact.isPending && 'pointer-events-none'
                )}
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className='flex gap-[8px]'>
                    <div className='grow'>
                        <SelectV2
                            register={register}
                            field='contact_type'
                            label='Contact Type'
                            id='contact_type'
                            name='contact_type'
                            errors={errors}
                        >
                            <option value={'General'}>General</option>
                            {contactTypes?.map((type: string) => {
                                return <option value={type}>{type}</option>
                            })}
                        </SelectV2>
                    </div>
                </div>
                <div className='flex gap-[8px]'>
                    <div className='grow'>
                        <InputV2
                            register={register}
                            label='Name'
                            id='name'
                            field='name'
                            type='text'
                            required={false}
                            errors={errors}
                        />
                    </div>
                    <div className='grow'>
                        <InputV2
                            register={register}
                            label='Email'
                            id='email'
                            field='email'
                            type='text'
                            required={false}
                            errors={errors}
                        />
                    </div>
                </div>
                <div className='grow'>
                    <InputV2
                        register={register}
                        label='Phone'
                        id='phone'
                        field='phone'
                        type='text'
                        required={false}
                        errors={errors}
                    />
                </div>

                <div className='flex gap-[8px]'>
                    <div className='grow'>
                        <label className={labelStyle}>Alternate</label>
                        <textarea {...register('alternate')} className={`${inputStyle} h-[120px]`} />
                    </div>
                </div>
            </form>
            <div className='relative flex justify-center mt-[16px] gap-4 h-[40px]'>
                <Button variant={'outline'} className={cn('col relative')} type='submit' form='contact_form'>
                    <p className={cn(updateContact.isPending && 'opacity-0')}>Submit</p>
                    {updateContact.isPending && (
                        <div className='absolute '>
                            <FetchingSpinner isFetching={updateContact.isPending} />
                        </div>
                    )}
                </Button>
                {/* <Button type='submit' form='contact_form' variant={'outline'} className={``} onClick={() => {}}>
                    Submit
                </Button> */}
                <Button
                    disabled={updateContact.isPending}
                    variant={'outline'}
                    className={`text-danger`}
                    onClick={handleReset}
                >
                    Reset
                </Button>
            </div>
        </div>
    )
}
