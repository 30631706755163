import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react'
import { FaCaretUp, FaPen } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { LoadingGear, cn, sendToast, vFetch } from '../../../helpers'
import { defaultCompany } from '../../Procurements.types'
import { useCreateCompany, useGetScoreSetupGroups, useGetScoreSetupOptions } from '../../api/useQueries'
import { LEAD_TYPE_OPTIONS, LIFECYCLE_STATUS_OPTIONS } from '../../constants'
import { ProcurementContext, ProcurementContextType } from '../../helpers'
import Input from '../Input'
import TimezoneDropdown from '../TimezoneDropdown'

const requiredFields = ['name', 'domain', 'product_type', 'phone']

export default function CreateCompanyModalV2() {
    const productTypesRef = useRef<HTMLDivElement>(null)
    const searchRef = useRef<HTMLInputElement>(null)
    const { toggleModal, productTypes, setSelectedCompany, setSelectedCompanyId } =
        useContext<ProcurementContextType>(ProcurementContext)
    const modalRef = useRef(null)
    const [loading, setLoading] = useState(false)
    const [fetchedInsights, setFetchedInsights] = useState(false)
    const [alreadyExists, setAlreadyExists] = useState(false)
    const [existingCompany, setExistingCompany] = useState<any>(undefined)
    const [showProductTypes, setShowProductTypes] = useState(false)
    const [allowCustomType, setAllowCustomType] = useState(false)
    const [productTypeSearch, setProductTypeSearch] = useState('')
    const [editableCompany, setEditableCompany] = useState({
        ...defaultCompany,
    })
    const [scoreOptionSelections, setScoreOptionSelections] = useState<{ groupId: number; optionId: number }[]>([])

    const getScoreSetupGroupsQuery = useGetScoreSetupGroups()
    const { groups } = getScoreSetupGroupsQuery?.data || {}
    const getScoreSetupOptionsQuery = useGetScoreSetupOptions()

    const { options } = getScoreSetupOptionsQuery?.data || {}

    let positionMap = undefined
    if (groups) {
        positionMap = groups[0]?.position_map
    }
    const requiredScoreSelectionGroupIds =
        groups
            ?.map((group: any) => {
                if (group.is_required) {
                    return group.id
                } else {
                    return null
                }
            })
            .filter((v: any) => v) || []

    const createCompany = useCreateCompany()
    const { isPending } = createCompany

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setEditableCompany((previousState: any) => ({
            ...structuredClone(previousState),
            [target.name]: target.type === 'checkbox' ? (target as HTMLInputElement).checked : target.value,
        }))
    }
    const handleScoreSelection = ({
        target,
    }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const optionSelection = {
            groupId: parseInt(target.value.split(',')[0]),
            optionId: parseInt(target.value.split(',')[1]),
        }
        const selectedGroupIdsSet = new Set(scoreOptionSelections.map((selection: any) => selection.groupId))
        if (selectedGroupIdsSet.has(optionSelection.groupId)) {
            const index = Array.from(selectedGroupIdsSet).indexOf(optionSelection.groupId)
            const newArray = [...scoreOptionSelections]
            newArray.splice(index, 1)
            if (optionSelection.optionId) {
                setScoreOptionSelections([...newArray, optionSelection])
            } else {
                setScoreOptionSelections([...newArray])
            }
        } else {
            setScoreOptionSelections((prev: any) => {
                return [...prev, optionSelection]
            })
        }
    }
    const toastId = React.useRef<any>(null)

    const handleCreate = () => {
        for (const field of requiredFields) {
            if (!editableCompany[field as keyof typeof editableCompany]) {
                toast.dismiss(toastId.current)
                return (toastId.current = sendToast({ message: `Must provide ${field.replaceAll('_', ' ')}!` }))
            }
        }
        const selectedGroupIdsSet = new Set(scoreOptionSelections.map((selection: any) => selection.groupId))
        for (const requiredGroupId of requiredScoreSelectionGroupIds) {
            if (!selectedGroupIdsSet.has(requiredGroupId)) {
                toast.dismiss(toastId.current)
                return (toastId.current = sendToast({ message: `Must provide all score selections` }))
            }
        }
        createCompany.mutate(
            {
                editableCompany: { ...editableCompany, shopify_vendor_name: editableCompany.name },
                scoreOptionSelections,
            },
            {
                onSuccess: (data) => {
                    if (data && !data.foundCompany) {
                        toggleModal()
                    }
                },
            }
        )
    }

    const fetchInsights = () => {
        setFetchedInsights(true)
        setLoading(true)
        vFetch(
            `/v1/procurement/companies/insights?domain=${encodeURIComponent(
                editableCompany.domain
                    .replaceAll(/https:\/\/|http:\/\//g, '')
                    .replaceAll('www.', '')
                    .split('/')[0]
            )}`,
            {
                cb: (res: any) => {
                    if (res.already_exists) {
                        setAlreadyExists(true)
                        setExistingCompany(res.company)
                    }
                    if (res.success && res.insights && res.insights.organization) {
                        const {
                            name,
                            phone,
                            sanitized_phone,
                            industry,
                            city,
                            state,
                            postal_code: zip,
                            logo_url: logo,
                            short_description: description,
                            linkedin_url: linkedin,
                            facebook_url: facebook,
                        } = res.insights.organization
                        setEditableCompany({
                            ...editableCompany,
                            name,
                            phone: sanitized_phone || phone,
                            industry,
                            city,
                            state,
                            zip,
                            logo,
                            description,
                            linkedin,
                            facebook,
                        })
                    }
                },
            }
        ).then(() => setLoading(false))
    }
    const removeHttps = () => {
        setEditableCompany({
            ...editableCompany,
            domain: editableCompany.domain
                .replaceAll(/https:\/\/|http:\/\//g, '')
                .replaceAll('www.', '')
                .split('/')[0],
        })
    }

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (e.target === modalRef.current) {
                toggleModal()
            }
            if (!productTypesRef.current?.contains(e.target as Element)) {
                setShowProductTypes(false)
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    }, [])

    const filteredProductTypes = productTypes
        ?.filter((pt: any) => pt)
        ?.filter((pt: any) => {
            return pt.toLowerCase().includes(productTypeSearch?.toLowerCase())
        })

    return (
        <div
            ref={modalRef}
            className={cn(
                isPending && 'pointer-events-none',
                'fixed z-[50] top-0 left-0 w-full h-full bg-[rgb(0,0,0,0.5)] grid place-items-center'
            )}
        >
            <div
                className={cn(
                    'p-[32px] gap-[32px] bg-white dark:bg-darkaccent rounded-[4px] shadow-small relative overflow-auto max-h-[90%]'
                )}
            >
                {loading && (
                    <div className='absolute top-0 left-0 w-full h-full grid place-items-center bg-white dark:bg-darkaccent z-[5]'>
                        <LoadingGear />
                    </div>
                )}
                <button
                    onClick={() => {
                        toggleModal()
                    }}
                    className='leading-[1] text-[24px] text-red dark:text-lightred absolute font-bold top-0 right-[8px]'
                >
                    &times;
                </button>
                <div className='relative z-[6] flex flex-col gap-[16px] w-1/2 whitespace-nowrap'>
                    <h2 className='text-darkgrey dark:text-fire text-[24px] font-bold uppercase leading-[1] m-0 mb-[16px]'>
                        Create Company
                    </h2>
                </div>
                <div className={cn(isPending && 'opacity-50', 'flex gap-[16px]')}>
                    <div className='min-w-[600px] flex flex-col gap-[4px]'>
                        {alreadyExists && (
                            <div className='flex gap-[8px] items-center mb-[8px]'>
                                <p className='text-red dark:text-lightred font-bold'>
                                    This company already exists in our system!
                                </p>
                                <button
                                    className='py-[2px] px-[4px] text-[14px] uppercase rounded-[4px] bg-blue text-white dark:text-darkaccent dark:bg-accent font-bold'
                                    onClick={() => toggleModal('company', existingCompany)}
                                >
                                    View Company
                                </button>
                            </div>
                        )}
                        <div className='flex gap-[8px]'>
                            <div className='flex gap-[8px] w-full items-end'>
                                <div className='flex flex-col gap-[4px] w-full'>
                                    <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1] relative w-fit'>
                                        Domain Name
                                        <span className='absolute top-0 right-[-10px] text-[14px] text-red dark:text-lightred'>
                                            *
                                        </span>
                                    </label>
                                    <input
                                        autoComplete='off'
                                        className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                        id='domain'
                                        name='domain'
                                        onBlur={removeHttps}
                                        onChange={(e) => {
                                            handleChange(e)
                                            setFetchedInsights(false)
                                            setAlreadyExists(false)
                                        }}
                                        value={editableCompany.domain}
                                        required={true}
                                    />
                                </div>
                                {editableCompany.domain && !fetchedInsights && (
                                    <button
                                        onClick={fetchInsights}
                                        className='py-[4px] px-[8px] font-bold shrink-0 bg-blue text-white dark:bg-accent dark:text-darkaccent rounded-[4px]'
                                    >
                                        Get Details
                                    </button>
                                )}
                                <Input
                                    id='name'
                                    label='Name'
                                    type='text'
                                    name='name'
                                    onChange={handleChange}
                                    value={editableCompany.name}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div ref={productTypesRef} className='relative'>
                            <Input
                                id='product_type'
                                name='product_type'
                                label='Product Type'
                                value={editableCompany.product_type}
                                onChange={handleChange}
                                type='text'
                                required={true}
                                // disabled={!allowCustomType}
                                onclick={() => {
                                    setShowProductTypes((previousState) => !previousState)
                                    setTimeout(() => {
                                        searchRef.current?.focus()
                                    }, 10)
                                }}
                            />
                            <button
                                onClick={() => {
                                    setShowProductTypes((previousState) => !previousState)
                                    setTimeout(() => {
                                        searchRef.current?.focus()
                                    }, 10)
                                }}
                                className='absolute top-[50%] right-[4px] z-[4]'
                            >
                                {showProductTypes ? (
                                    <FaCaretUp className='text-darkgrey dark:text-offwhite' />
                                ) : (
                                    <FaPen className='text-blue dark:text-accent' />
                                )}
                            </button>
                            {showProductTypes && (
                                <div className='absolute z-[5] flex flex-col top-[100%] left-0 bg-white dark:bg-darkaccent w-full shadow-small border  border-darkgrey rounded-[4px] max-h-[250px] overflow-auto'>
                                    <input
                                        ref={searchRef}
                                        className='bg-transparent p-[8px] focus:outline-none border-b border-lightgrey dark:border-darkgrey rounded-[4px]'
                                        type='text'
                                        placeholder='Search'
                                        value={productTypeSearch}
                                        onChange={({ target }) => setProductTypeSearch(target.value)}
                                    />
                                    <button
                                        onClick={() => {
                                            setAllowCustomType(true)
                                            setShowProductTypes(false)
                                            setEditableCompany((previousState: any) => ({
                                                ...previousState,
                                                product_type: productTypeSearch,
                                            }))
                                            setProductTypeSearch('')
                                            setTimeout(() => {
                                                productTypesRef.current?.querySelector('input')?.focus()
                                            }, 10)
                                        }}
                                        className='text-left p-[8px] border-b border-lightgrey dark:border-darkgrey'
                                    >
                                        + Add New Type
                                    </button>
                                    {filteredProductTypes
                                        ?.filter((v) => v)
                                        .map((pt) => (
                                            <button
                                                onClick={() => {
                                                    setEditableCompany((previousState: any) => ({
                                                        ...previousState,
                                                        product_type: pt,
                                                    }))
                                                    setProductTypeSearch('')
                                                    setTimeout(() => {
                                                        setShowProductTypes(false)
                                                    }, 10)
                                                }}
                                                className='text-left p-[8px] border-b border-lightgrey dark:border-darkgrey'
                                            >
                                                {pt}
                                            </button>
                                        ))}
                                </div>
                            )}
                        </div>

                        <div className='flex gap-[8px]'>
                            <Input
                                id='phone'
                                label='Phone'
                                type='text'
                                name='phone'
                                onChange={handleChange}
                                value={editableCompany.phone}
                                required={true}
                            />
                            <Input
                                id='email'
                                label='Email'
                                type='text'
                                name='email'
                                onChange={handleChange}
                                value={editableCompany.email}
                            />
                        </div>

                        <div className='flex flex-col gap-[4px] w-full'>
                            <label
                                htmlFor='description'
                                className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                            >
                                Description
                            </label>
                            <textarea
                                className='w-full max-h-[100px] text-[16px] leading-1 bg-lightgrey border-0 focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                value={editableCompany.description}
                                name='description'
                                onChange={handleChange}
                            ></textarea>
                        </div>
                        <Input
                            id='city'
                            label='City'
                            type='text'
                            name='city'
                            onChange={handleChange}
                            value={editableCompany.city}
                        />
                        <Input
                            id='state'
                            label='State'
                            type='text'
                            name='state'
                            onChange={handleChange}
                            value={editableCompany.state}
                        />
                        <Input
                            id='zip'
                            label='Zip'
                            type='text'
                            name='zip'
                            onChange={handleChange}
                            value={editableCompany.zip}
                        />
                        <div className='flex flex-col gap-[4px] w-full'>
                            <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                Timezone
                            </label>
                            <TimezoneDropdown
                                className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                id='timezone'
                                name='timezone'
                                handleChange={handleChange}
                                value={editableCompany.timezone}
                            />
                        </div>
                        <Input
                            id='linkedin'
                            name='linkedin'
                            label='LinkedIn Profile'
                            value={editableCompany.linkedin}
                            onChange={handleChange}
                            type='text'
                        />
                        <Input
                            id='facebook'
                            name='facebook'
                            label='Facebook Profile'
                            value={editableCompany.facebook}
                            onChange={handleChange}
                            type='text'
                        />
                    </div>
                    <div className='flex flex-col gap-[4px] min-w-[400px]'>
                        <div className='flex gap-[8px]'>
                            <div className='flex flex-col gap-[4px] w-full'>
                                <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    Lead Type
                                </label>
                                <select
                                    autoComplete='off'
                                    className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                    id='lead_type'
                                    name='lead_type'
                                    onChange={handleChange}
                                    value={editableCompany.lead_type}
                                >
                                    {LEAD_TYPE_OPTIONS.map((leadType) => (
                                        <option className='dark:bg-darkness/90' value={leadType.value}>
                                            {leadType.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className='flex flex-col gap-[4px] w-full'>
                                <label className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    Lifecycle stage
                                </label>
                                <select
                                    autoComplete='off'
                                    className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                    id='lifecycle_status'
                                    name='lifecycle_status'
                                    onChange={handleChange}
                                    value={editableCompany.lifecycle_status}
                                >
                                    {LIFECYCLE_STATUS_OPTIONS.map((stage) => (
                                        <option className='dark:bg-darkness/90' value={stage.value}>
                                            {stage.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <Input
                            id='parent_company'
                            label='Parent Company'
                            type='text'
                            name='parent_company'
                            onChange={handleChange}
                            value={editableCompany.parent_company}
                        />
                        <div className='border-t border-lightgrey dark:border-darkgrey pt-[8px] flex flex-col gap-[4px]'>
                            {positionMap?.map((id: any, index: number) => {
                                const relatedGroup = groups.filter((group: any) => {
                                    if (group.id === id) {
                                        return group
                                    }
                                    return
                                })[0]
                                const relatedOptions = options?.filter(
                                    (option: any) => option.procurement_score_group_id === relatedGroup.id
                                )
                                return (
                                    <div className={`flex flex-col gap-[4px] w-full `}>
                                        <label className='relative text-darkgrey dark:text-offwhite font-bold text-[12px] w-fit uppercase leading-[1]'>
                                            {relatedGroup?.name}
                                            <span className='absolute top-0 right-[-10px] text-[14px] text-red dark:text-lightred'>
                                                {relatedGroup.is_required ? '*' : ''}
                                            </span>
                                        </label>
                                        <select
                                            autoComplete='off'
                                            className='w-full text-[16px] leading-1 bg-lightgrey focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                            onChange={handleScoreSelection}
                                        >
                                            <option className='dark:bg-darkness/90' value={`${relatedGroup.id}, ''`}>
                                                No Option Selected
                                            </option>
                                            {relatedOptions?.map((option: any) => (
                                                <option
                                                    className='dark:bg-darkness/90'
                                                    value={`${relatedGroup.id}, ${option.id}`}
                                                    selected={option.is_selected === 1}
                                                >
                                                    {option.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )
                            })}
                        </div>

                        <div className='flex flex-col gap-[4px] w-full'>
                            <label
                                htmlFor='description'
                                className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'
                            >
                                Additional Details
                            </label>
                            <textarea
                                className='w-full max-h-[100px] text-[16px] leading-1 bg-lightgrey border-0 focus:outline-none dark:text-white dark:bg-darkness p-[4px] rounded-[4px]'
                                value={editableCompany.notes}
                                name='notes'
                                onChange={handleChange}
                            ></textarea>
                        </div>
                    </div>
                </div>
                <div className='flex gap-[16px] justify-center items-center mt-[16px]'>
                    <button
                        disabled={isPending}
                        onClick={handleCreate}
                        className='block py-[8px] px-[24px] rounded-[4px] font-bold text-white bg-blue dark:text-darkness dark:bg-accent uppercase'
                    >
                        {isPending ? 'Creating..' : 'Create Company'}
                    </button>
                </div>
            </div>
        </div>
    )
}
