import { useState } from 'react'
import { MdDoubleArrow } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { cn } from '../helpers'
import TaskPopout from '../procurement/components/modals/quickActions/TaskPopout'
import ProductPopout from '../procurement/components/modals/quickActions/ProductPopout'
import { Button } from '../custom_components/component_Basics/Button'
import { setProductPin } from '../redux/productPin'

export default function Popout() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const [showQuickModal, setShowQuickModal] = useState<string | undefined>(undefined)

    const showPopout = useSelector<any, any>((state) => state.productPin.open)

    const user = useSelector((state: any) => state.user)
    const settings = useSelector((state: any) => state.settings)

    return (
        <>
            {(user.id === 9 || user.id === 33 || user.id === 28 || user.id === 31) && (
                <div className='text-text1 dark:text-darktext1'>
                    <div
                        className={cn(
                            'w-[1202px] ',
                            'fixed h-fit bottom-[-1px] left-[50%] translate-x-[-50%] z-[49] bg-white shadow-small rounded-t-[4px] dark:bg-darkaccent flex flex-col border border-darkgrey dark:border-grey border-b-0 overflow-visible'
                        )}
                    >
                        <Button
                            variant={'outline'}
                            className={cn('z-50 absolute bottom-[10px] left-[70%] ', showPopout && 'bottom-[254px]')}
                            // onClick={() => setShowQuickModal(showQuickModal ? undefined : 'not undefined')}
                            onClick={() => dispatch(setProductPin({ open: showPopout ? false : true }))}
                        >
                            <div className={`w-fit h-fit transition-all duration-300 ${showQuickModal ? '' : ''}`}>
                                Product Viewer
                            </div>
                        </Button>
                        <button
                            onClick={() => dispatch(setProductPin({ open: false }))}
                            className='leading-[1] text-[24px] text-red dark:text-lightred absolute font-bold top-0 right-[8px]'
                        >
                            &times;
                        </button>
                        {/* {showQuickModal && <TaskPopout setShowQuickModal={setShowQuickModal} />} */}
                        <div className={cn(!showPopout && 'hidden')}>
                            <ProductPopout showQuickModal={showPopout} />
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
