import { Dispatch, SetStateAction } from 'react'

export default function AreYouSure({
    savedStep,
    setSavedStep,
    handleStepChange,
}: {
    savedStep: string
    setSavedStep: Dispatch<SetStateAction<string>>
    handleStepChange: Function
}) {
    return (
        <div className='w-full h-full fixed top-0 left-0 z-[50] bg-[rgb(0,0,0,0.5)] grid place-items-center'>
            <div className='p-[32px] rounded-[4px] shadow-small bg-white dark:bg-darkaccent'>
                <p className='text-[20px] font-bold dark:text-offwhite leading-[1] mb-[16px]'>Are you sure?</p>
                <p className='dark:text-offwhite leading-[1] mb-[24px]'>
                    You have unsaved changes. If you continue without saving these will be lost.
                </p>
                <div className='flex gap-[16px]'>
                    <button
                        onClick={() => setSavedStep('')}
                        className='py-[4px] px-[8px] rounded-[4px] bg-grey font-bold uppercase dark:text-darkaccent'
                    >
                        Go Back
                    </button>
                    <button
                        onClick={() => handleStepChange(savedStep, true)}
                        className='py-[4px] px-[8px] rounded-[4px] bg-red dark:bg-lightred text-white dark:text-offwhite font-bold uppercase'
                    >
                        Continue Without Saving
                    </button>
                </div>
            </div>
        </div>
    )
}
