import { SkeletonBasic } from '../../custom_components/component_Basics/SkeletonBasic'

export default function CompetitorProductRowSkeleton() {
    return (
        <div className='grid grid-cols-[64px_2fr_1fr_1fr_1fr_1fr] items-center border-b border-lightgrey dark:border-darkgrey'>
            <div className='p-2 opacity-50'>
                <SkeletonBasic className='w-full h-12' />
            </div>
            <div className='font-bold text-sm p-2 opacity-50'>
                <SkeletonBasic className='w-full h-12' />
            </div>
            <div className='font-bold text-sm p-2 opacity-50'>
                <SkeletonBasic className='w-full h-12' />
            </div>
            <div className='font-bold text-sm p-2 opacity-50'>
                <SkeletonBasic className='w-full h-12' />
            </div>
            <div className='font-bold text-sm p-2 opacity-50'>
                <SkeletonBasic className='w-full h-12' />
            </div>
            <div className='font-bold text-sm p-2 opacity-50'>
                <SkeletonBasic className='w-full h-12' />
            </div>
        </div>
    )
}
