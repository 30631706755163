import { MonthMap, formatDateToLocale, monthMap } from '../../helpers'
import {
    FinancialStatusMap,
    FulfillmentStatusMap,
    LineItemInit,
    OrderInit,
    financialStatusMap,
    fulfillmentStatusMap,
} from '../orders.types'
import { TbCircle, TbCircleFilled } from 'react-icons/tb'
import { FaRegCommentDots } from 'react-icons/fa'
import { IoAlertCircleOutline } from 'react-icons/io5'
import { PrivateEventType, TaskEditInit } from '../../tasks/tasks.types'
import OrderActions from './OrderActions'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { UserInit } from '../../users/users.types'
import PrivateEventModal from '../../tasks/components/PrivateEventModal'
import PrivateEvent from '../../tasks/components/PrivateEvent'

type HeaderProps = {
    userList: UserInit[]
    order: OrderInit
    tasks: TaskEditInit[]
    actions: any[]
    addNewTask: Function
    orderEventsPrivate: PrivateEventType[]
}

export default function OrderHeader({ actions, addNewTask, order, tasks, orderEventsPrivate, userList }: HeaderProps) {
    const user = useSelector<any, any>((state) => state.user)
    const [showOrderEventsPrivate, setShowOrderEventsPrivate] = useState(false)

    function renderCreatedAt() {
        const shopifyAppNamesMap: any = {
            580111: 'FactoryPure.com',
            1775805: 'eBay.com',
            1456995: 'Walmart',
        }
        const createdAtMap: any = {
            shopify_draft_order: 'Draft Orders',
            web: shopifyAppNamesMap[order.app_id],
            1456995: 'Walmart',
        }
        const month = monthMap[formatDateToLocale(order.created_at).split(' ')[0] as keyof MonthMap]
        const dateYear = formatDateToLocale(order.created_at).split(' ').slice(1, 3).join(' ')
        const time = formatDateToLocale(order.created_at).split(' ').slice(3).join(' ')
        const from = order.source_name ? `from ${createdAtMap[order.source_name] || order.source_name}` : ''
        return [month, dateYear, 'at', time, from].join(' ').trim()
    }

    const tagStyle =
        'relative w-[auto] p-[2px] px-[8px] pr-[10px] flex gap-[4px] items-center text-darkgrey rounded-full'
    const successColor = 'bg-[rgb(190,255,200)]'

    function CanceledLabel() {
        return (
            <div
                className={`${tagStyle} bg-[rgb(255,180,160)]`}
                title={`Reason: ${order.cancel_reason}\n${formatDateToLocale(order.cancelled_at as Date)}`}
            >
                <h2 className='inline-block font-semibold text-[14px] text-center'>Canceled</h2>
            </div>
        )
    }
    function FinancialStatusLabel() {
        return (
            <div
                className={`${tagStyle} ${
                    order.financial_status && financialStatusMap[order.financial_status as keyof FinancialStatusMap]
                        ? financialStatusMap[order.financial_status as keyof FinancialStatusMap].bg
                        : 'bg-fire'
                }`}
            >
                {order.financial_status &&
                financialStatusMap[order.financial_status as keyof FinancialStatusMap] &&
                financialStatusMap[order.financial_status as keyof FinancialStatusMap].symbol ? (
                    order.financial_status.toLowerCase() === 'paid' ? (
                        <TbCircleFilled />
                    ) : (
                        financialStatusMap[order.financial_status as keyof FinancialStatusMap].symbol
                    )
                ) : (
                    <TbCircle />
                )}
                <h2 className='inline-block font-semibold text-[14px] text-center'>
                    {order.financial_status && financialStatusMap[order.financial_status as keyof FinancialStatusMap]
                        ? financialStatusMap[order.financial_status as keyof FinancialStatusMap].status
                        : 'Unpaid'}
                </h2>
            </div>
        )
    }
    function FulfillmentStatusLabel() {
        return (
            <div
                className={`${tagStyle} ${
                    order.fulfillment_status &&
                    fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap]
                        ? fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap].bg
                        : 'bg-fire'
                } relative w-[auto] p-[2px] px-[8px] pr-[10px] flex gap-[4px] items-center text-darkgrey rounded-full`}
            >
                {order.fulfillment_status &&
                fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap] &&
                fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap].symbol ? (
                    fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap].symbol
                ) : (
                    <TbCircle />
                )}
                <h2 className='inline-block font-semibold text-[14px] text-center'>
                    {order.fulfillment_status &&
                    fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap]
                        ? fulfillmentStatusMap[order.fulfillment_status as keyof FulfillmentStatusMap].status
                        : 'Unfulfilled'}
                </h2>
            </div>
        )
    }
    function SubmittedLabel() {
        return (
            <div className={`${tagStyle} ${successColor}`} title='This order has timeline comments'>
                <FaRegCommentDots />
                <h2 className='inline-block font-semibold text-[14px] text-center truncate'>Submitted</h2>
            </div>
        )
    }

    const unsubmittedQuantity =
        !order.has_timeline_comment &&
        order.line_items.reduce((acc: number, cur: LineItemInit) => acc + cur.fulfillable_quantity, 0)

    function UnsubmittedItemsLabel() {
        const unsubmittedLineItems = order.line_items.filter((item) => item.fulfillable_quantity !== 0)
        const [showMessage, setShowMessage] = useState(false)

        return (
            <div className={`relative ${tagStyle} bg-fire`} onMouseOver={() => setShowMessage(true)}>
                <IoAlertCircleOutline className='' />
                <h2 className='inline-block font-semibold text-[14px] text-center truncate'>
                    {unsubmittedQuantity} Unsubmitted
                </h2>
                {showMessage && (
                    <div
                        className='absolute left-0 top-[0] p-[16px] text-black dark:text-offwhite z-index-2 opacity-0 hover:opacity-100 duration-300'
                        onMouseOut={() => setShowMessage(false)}
                    >
                        <div className='flex flex-col gap-[8px] p-[8px] bg-white dark:bg-darkaccent border-[1px] border-darkgrey shadow-small '>
                            <p className='whitespace-nowrap'>
                                {unsubmittedQuantity} item{unsubmittedQuantity === 1 ? ' does' : 's do'} not have a
                                purchase order.
                            </p>
                            <div>
                                {unsubmittedLineItems.map((item) => (
                                    <p className='whitespace-nowrap'>
                                        ({item.fulfillable_quantity}) x {item.name}
                                    </p>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }

    return (
        <div className='w-[100%]'>
            {orderEventsPrivate && showOrderEventsPrivate && (
                <PrivateEventModal
                    origin='order'
                    userList={userList}
                    setViewModal={setShowOrderEventsPrivate}
                    events={orderEventsPrivate}
                />
            )}
            <div className='flex w-[100%] text-text1 dark:text-darktext1 justify-between items-center'>
                <div className='flex items-center gap-[8px]'>
                    <h1 className='text-[24px]'>{order.name}</h1>

                    <div className='flex gap-[8px] items-center'>
                        {order.cancel_reason && !!order.cancelled_at && <CanceledLabel />}
                        <FinancialStatusLabel />
                        <FulfillmentStatusLabel />
                        {!!order.has_timeline_comment && <SubmittedLabel />}
                        {!!unsubmittedQuantity && <UnsubmittedItemsLabel />}
                    </div>
                </div>
                <OrderActions actions={actions} addNewTask={addNewTask} order={order} />
            </div>
            {(user.type === 'Admin' || user.type === 'SuperAdmin') && orderEventsPrivate.length > 0 && (
                <div>
                    <PrivateEvent
                        events={orderEventsPrivate}
                        setViewModal={setShowOrderEventsPrivate}
                        userList={userList}
                    />
                </div>
            )}
            <span className='font-light text-[14px]'>{renderCreatedAt()}</span>
        </div>
    )
}
