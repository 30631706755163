export default function Acceptance({ GSAccepted, STAccepted }: { GSAccepted: null | 0 | 1; STAccepted: null | 0 | 1 }) {
    let GSMessage = ''
    let GSClasses = ['font-bold']
    let STMessage = ''
    let STClasses = ['font-bold']
    if (GSAccepted === null) {
        GSMessage += 'GS Pending, '
        GSClasses.push('text-fire')
    }
    if (GSAccepted === 0) {
        GSMessage += 'GS Rejected, '
        GSClasses.push('text-lightred')
    }
    if (GSAccepted === 1) {
        GSMessage += 'GS Accepted, '
        GSClasses.push('text-lime')
    }
    if (STAccepted === null) {
        STMessage += 'ST Pending'
        STClasses.push('text-fire')
    }
    if (STAccepted === 0) {
        STMessage += 'ST Rejected'
        STClasses.push('text-lightred')
    }
    if (STAccepted === 1) {
        STMessage += 'ST Accepted'
        STClasses.push('text-lime')
    }

    return (
        <p>
            <span className={GSClasses.join(' ')}>{GSMessage}</span>
            <span className={STClasses.join(' ')}>{STMessage}</span>
        </p>
    )
}
