import { Link } from 'react-router-dom'
import { useUpdateTask } from 'tasksV2/api/useQueries'
import Assignment from 'tasksV2/tasks/components/Assignment'
// import Association from 'tasksV2/tasks/components/Association'
import TaskStatus from 'tasksV2/tasks/components/TaskStatus'
import TaskType from 'tasksV2/tasks/components/TaskType'

const dateFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
}).format

export default function ProjectTask({ task }: { task: any }) {
    const assignemnts = JSON.parse(task.associations).filter((association: any) => association.resource_name === 'user')
    const updateTaskMutation = useUpdateTask(task.id.toString())
    // const associations = JSON.parse(task.associations).filter(
    //     (association: any) => association.resource_name !== 'user'
    // )

    return (
        <div className='grid grid-cols-subgrid col-span-full gap-2 items-center border-b p-2'>
            <TaskStatus taskId={task.id} status={task.status} mutation={updateTaskMutation} />
            <Link to={`/tasksv2/${task.id}`}>{task.title}</Link>
            <TaskType departmentId={task.department_id} taskId={task.id} typeId={task.type_id} />
            <div className='flex gap-1 flex-wrap'>
                {assignemnts.map((assignment: any) => (
                    <Assignment association={assignment} />
                ))}
            </div>
            <p>{dateFormatter(new Date(task.due_at))}</p>
            {/* <div>
                {associations.map((association: any) => (
                    <Association association={association} id={task.id} />
                ))}
            </div> */}
        </div>
    )
}
