import { useState } from 'react'
import { LineItemInit, OrderInit } from '../../orders/orders.types'
import { MdContentCopy } from 'react-icons/md'

type TrackingInformationProps = {
    order: OrderInit
}
export default function TrackingInformationBlock({ order }: TrackingInformationProps) {
    const [showTracking, setShowTracking] = useState<boolean>(false)

    return (
        <div className={`relative p-[8px] pt-[18px] bg-white dark:bg-darkaccent rounded shadow-small`}>
            <h3
                className='font-bai absolute flex font-extrabold text-[12px] cursor-pointer top-0 left-0 uppercase bg-[rgba(0,60,90,0.5)] rounded-tl text-[rgb(74,74,74)] dark:text-offwhite py-[2px] px-[4px] mb-[8px] w-[fit-content] items-center justify-between'
                onClick={() => setShowTracking(!showTracking)}
                style={
                    showTracking
                        ? { color: 'white' }
                        : {
                              width: '100%',
                              background: 'none',
                              color: '',
                              marginBottom: '12px',
                              fontSize: '16px',
                              position: 'static',
                          }
                }
            >
                Tracking Information
                {!showTracking && <span className='inline-block ml-[auto] text-[24px] leading-[1]'>+</span>}
            </h3>

            <div className={`overflow-hidden ${showTracking ? 'max-h-none' : 'max-h-[0px]'}`}>
                <div className='js-items-dropdown grid gap-[32px] p-[16px]  text-black bg-white dark:text-offwhite dark:bg-darkaccent [&>div]:border-b-[1px] dark:[&>div]:border-darkgrey [&>:last-child]:border-0'>
                    {order.fulfillments.map((f) => {
                        return (
                            f.tracking_url && (
                                <div className='grid'>
                                    <div className='flex gap-[16px] mb-[32px] items-center'>
                                        <span
                                            className='hover:underline cursor-pointer font-semibold text-[16px] text-blue dark:text-accent'
                                            onClick={() => {
                                                if (
                                                    !f.tracking_url.includes('ups.com') &&
                                                    !f.tracking_url.includes(f.tracking_number)
                                                ) {
                                                    navigator.clipboard.writeText(f.tracking_number)
                                                    setTimeout(() => {
                                                        alert(
                                                            'Tracking number copied: ' +
                                                                f.tracking_number +
                                                                '\n\nYou can right-click and paste the tracking number into the tracking website.\n\nClick OK to continue to the tracking website.'
                                                        )
                                                        window.open(f.tracking_url, '_blank')
                                                    }, 1)
                                                } else {
                                                    window.open(f.tracking_url, '_blank')
                                                }
                                            }}
                                        >
                                            {f.tracking_company === 'Other' &&
                                            f.tracking_url.match(/www.([a-zA-Z0-9]+)./)
                                                ? f.tracking_url
                                                      .match(/www.([a-zA-Z0-9]+)./)![1]
                                                      .slice(0, 1)
                                                      .toUpperCase() +
                                                  f.tracking_url.match(/www.([a-zA-Z0-9]+)./)![1].slice(1)
                                                : f.tracking_url.match(/([a-zA-Z0-9]+).com\//)
                                                  ? f.tracking_url.match(/([a-zA-Z0-9]+).com\//)![1].toUpperCase()
                                                  : f.tracking_company}{' '}
                                            - {f.tracking_number}
                                        </span>
                                        <MdContentCopy
                                            className='cursor-pointer'
                                            onClick={() => {
                                                navigator.clipboard.writeText(f.tracking_number)
                                                setTimeout(() => {
                                                    window.alert('Tracking number copied!')
                                                }, 1)
                                            }}
                                        />
                                    </div>

                                    {f.line_items.map((item: LineItemInit) => {
                                        return (
                                            <div className='js-items-dropdown flex gap-[16px]'>
                                                <div className='js-items-dropdown relative flex items-center'>
                                                    <img
                                                        className='js-items-dropdown object-contain max-h-[75px] max-w-[75px]'
                                                        src={
                                                            window.IMAGE_MAP[`gid://shopify/Product/${item.product_id}`]
                                                        }
                                                        alt={item.name}
                                                        height='100px'
                                                        width='100px'
                                                    />
                                                    <span className='js-items-dropdown absolute flex top-[-10px] right-[-10px] min-w-[20px] min-h-[20px] text-[12px] leading-[1px] items-center pb-[2px] border-[5px] border-[darkgrey] bg-[darkgrey] text-white font-bold rounded-full justify-center'>
                                                        {item.quantity}
                                                    </span>
                                                </div>
                                                <div className='js-items-dropdown my-[12px]'>
                                                    <a
                                                        href={`https://factorypure.com/search?q=${item.sku}`}
                                                        target='_blank'
                                                        className='js-dont-navigate js-items-dropdown font-semibold hover:underline hover:text-blue dark:hover:text-accent pointer-events-auto'
                                                    >
                                                        {item.name}
                                                    </a>
                                                    <p className='js-items-dropdown pt-[4px]'>
                                                        <strong className='js-items-dropdown'>SKU:</strong>{' '}
                                                        <a
                                                            className='hover:underline hover:text-blue dark:hover:text-accent pointer-events-auto'
                                                            href={`https://factorypure.myshopify.com/admin/products/${item.product_id}`}
                                                            target='_blank'
                                                        >
                                                            {item.sku}
                                                        </a>
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        )
                    })}
                </div>
                {/* <div className={`overflow-hidden ${showTracking ? "max-h-none":"max-h-[0px]"}`}>
                    <div className="flex gap-[8px] w-[100%]">
                        <div className="relative w-[100%]">
                            <label 
                                className="font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey"
                                htmlFor="customer_company"
                            >
                                Address
                            </label>
                            <input
                                type="text"
                                className="block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent"
                                id="customer_address"
                                name="address1"
                                defaultValue={task.customer.address1}
                                onChange={(e:any) => handleChangeCustomer(e)}
                            />
                        </div>
                    </div>
                    <div className="flex gap-[8px] w-[100%]">
                        <div className="relative w-[100%]">
                            <label 
                                className="font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey"
                                htmlFor="customer_company"
                            >
                                City
                            </label>
                            <input
                                type="text"
                                className="block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent"
                                id="customer_city"
                                name="city"
                                defaultValue={task.customer.city}
                                onChange={(e:any) => handleChangeCustomer(e)}
                            />
                        </div>
                        <div className="relative w-[100%]">
                            <label 
                                className="font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey"
                                htmlFor="customer_company"
                            >
                                State
                            </label>
                            <select 
                                className="block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent" 
                                value={task.customer.province_code}
                                id="province_code"
                                name="province_code"
                                onChange={(e:any) => handleChangeCustomer(e)}
                            >
                                {STATE_CODE_LIST.map(stateCode => <option value={stateCode} >{stateCode}</option>)}
                            </select>
                        </div>
                        <div className="relative w-[100%]">
                            <label 
                                className="font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey"
                                htmlFor="customer_company"
                            >
                                Zip
                            </label>
                            <input
                                type="text"
                                className="block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent"
                                id="customer_zip"
                                name="zip"
                                defaultValue={task.customer.zip}
                                onChange={(e:any) => handleChangeCustomer(e)}
                            />
                        </div>
                    </div>
                    <div className="flex gap-[8px] w-[100%]">
                        <div className="relative w-[100%]">
                            <label 
                                className="font-bai text-[10px] font-bold text-[#4a4a4a] uppercase dark:text-grey"
                                htmlFor="customer_company"
                            >
                                Phone
                            </label>
                            <input
                                type="text"
                                className="block w-[100%] border-[1px] border-[#cdcdcd] dark:border-blue rounded p-[4px] dark:text-offwhite dark:bg-darkness focus:outline focus:outline-[1px] dark:focus:outline-accent"
                                id="customer_phone"
                                name="phone"
                                defaultValue={task.customer.phone}
                                onChange={(e:any) => handleChangeCustomer(e)}
                            />
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}
