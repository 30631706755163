import { findImage } from '../../../helpers'
import formMap from '../productForms/formMap'

export default function SelectedProduct({
    product,
    selectedType,
    selectedTopic,
    selectedProducts,
    setSelectedProducts,
}) {
    const handleRemove = () => {
        setSelectedProducts(selectedProducts.filter((p) => p.title !== product.title))
    }
    const SelectedForm = formMap[selectedType][selectedTopic]
    return (
        <>
            <div className='new-task__products__dropdown__product flex items-center gap-[8px] bg-white dark:bg-darkness p-[16px]'>
                <div className='new-task__products__image-container w-[56px] h-[56px] p-[3px] overflow-hidden shrink-0 border-[1px] border-[#cdcdcd] dark:border-darkaccent rounded'>
                    <img
                        src={findImage(product.gid ? product.gid.split('/')[4] : product.product_id)}
                        alt={product.title}
                        width='50'
                        height='50'
                        className='new-task__products__img w-[100%] h-[100%] object-contain'
                    />
                </div>
                <p>{product.title}</p>
                <div className='new-task__products__qty w-[40px]'>
                    <label className='new-task__products__qty__label block w-[100%] text-[12px] text-[#4a4a4a] dark:text-offwhite text-center font-bold mb-[2px]'>
                        QTY
                    </label>
                    <input
                        className='new-task__products__qty__input w-[100%] text-center border-[1px] border-[#cdcdcd] dark:border-darkaccent rounded'
                        type='number'
                        value={product.quantity}
                    />
                </div>
                <div
                    onClick={handleRemove}
                    className='new-task__products__remove text-[24px] font-bold leading-[1] self-center p-[8px] cursor-pointer'
                >
                    &times;
                </div>
            </div>
            {SelectedForm && (
                <SelectedForm
                    product={product}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                />
            )}
        </>
    )
}
