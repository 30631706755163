import { iconMap } from '../constants'

export default function IconDropDownModal({ setSelectedIcon }: any) {
    return (
        <>
            <div
                className='flex flex-row flex-wrap gap-2 text-[18px] leading-1 p-[4px] rounded-[4px]
        bg-lightgrey text-blue focus:outline-none dark:text-accent dark:bg-darkness'
            >
                {Object.entries(iconMap)?.map(([key, Value]) => (
                    <button onClick={() => setSelectedIcon(key)}>
                        <Value />
                    </button>
                ))}
            </div>
        </>
    )
}
