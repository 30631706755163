import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import NoteModal from './NoteModal'

export default function SelectedOrdersActions({ style = '', selected, setSelectedOrders }) {
    const navigate = useNavigate()
    const [showDotDotDot, setShowDotDotDot] = useState(false)
    const [showNoteModal, setShowNoteModal] = useState(false)

    const dotDotDotOptions = {
        title_null0: {
            'Request fulfillment': () => {
                window.alert('functionality needs to be added.')
            },
            'Cancel fullment requests': () => {
                window.alert('functionality needs to be added.')
            },
            'Mark as unfulfilled': () => {
                window.alert('functionality needs to be added.')
            },
            'Change fulfillment locations': () => {
                window.alert('functionality needs to be added.')
            },
        },
        title_null1: {
            'Print packing slips': () => {
                window.alert('functionality needs to be added.')
            },
            'Archive orders': () => {
                window.alert('functionality needs to be added.')
            },
            'Unarchive orders': () => {
                window.alert('functionality needs to be added.')
            },
            'Cancel orders': () => {
                window.alert('functionality needs to be added.')
            },
        },
        title_null2: {
            'Add tags': () => {
                window.alert('functionality needs to be added.')
            },
            'Remove tags': () => {
                window.alert('functionality needs to be added.')
            },
        },
        Apps: {
            'Add new paid orders to a spreadsheet': () => {
                window.alert('functionality needs to be added.')
            },
            'Run Flow Animation': () => {
                window.alert('functionality needs to be added.')
            },
            'Export with Matrixify': () => {
                window.alert('functionality needs to be added.')
            },
        },
    }

    const handleCreateShippingLabels = () => {
        window.alert('Tell the developers that they need to add functionality to this button.')
    }

    const handleMarkAsFulfilled = () => {
        window.alert('Tell the developers that they need to add functionality to this button.')
    }

    const handleCapturePayments = () => {
        window.alert('Tell the developers that they need to add functionality to this button.')
    }

    const handleDotDotDot = () => {
        setShowDotDotDot(showDotDotDot ? false : true)
    }
    const showNoteEditor = () => {}

    useEffect(() => {
        const listenToWindow = (e) => {
            if (!e.target.classList.contains('js-dropdown') && !e.target.classList.contains('js-dropdown__option')) {
                setShowDotDotDot(false)
            }
        }
        window.addEventListener('click', listenToWindow)
        return () => window.removeEventListener('click', listenToWindow)
    }, [])

    return (
        <>
            {selected.length > 0 && (
                <div
                    className={`fixed flex justify-center bottom-[32px] left-[216px] w-[calc(100%-216px)] z-index-5 ${style}`}
                >
                    <div className='flex gap-[8px] justify-between bg-white dark:bg-darkaccent dark:border-[1px] dark:border-darkgrey rounded shadow-small py-[8px] px-[16px] [&>*]:text-[14px] [&>*]:font-semibold [&>*]:dark:text-offwhite'>
                        <button
                            onClick={() => {
                                navigate(`/orders/purchase-orders/new?orders=${selected}`)
                            }}
                            className='border-[1px] border-darkgrey px-[8px] py-[2px] rounded hover:bg-offwhite dark:hover:bg-accent dark:hover:text-black'
                        >
                            Create Purchase Order{selected.length > 0 && 's'}
                        </button>
                        <button
                            onClick={() => setShowNoteModal(true)}
                            className='border-[1px] border-darkgrey px-[8px] py-[2px] rounded hover:bg-offwhite dark:hover:bg-accent dark:hover:text-black'
                        >
                            Bulk Add Notes
                        </button>
                        <button
                            onClick={handleCreateShippingLabels}
                            className='border-[1px] border-darkgrey px-[8px] py-[2px] rounded hover:bg-offwhite dark:hover:bg-accent dark:hover:text-black'
                        >
                            Create Shipping Labels
                        </button>
                        <button
                            onClick={handleMarkAsFulfilled}
                            className='border-[1px] border-darkgrey px-[8px] py-[2px] rounded hover:bg-offwhite dark:hover:bg-accent dark:hover:text-black'
                        >
                            Mark as Fulfilled
                        </button>
                        <button
                            onClick={handleCapturePayments}
                            className='border-[1px] border-darkgrey px-[8px] py-[2px] rounded hover:bg-offwhite dark:hover:bg-accent dark:hover:text-black'
                        >
                            Capture Payments
                        </button>
                        <div className='relative'>
                            <button
                                onClick={handleDotDotDot}
                                className={`js-dropdown border-[1px] border-darkgrey px-[8px] py-[2px] rounded hover:bg-offwhite ${
                                    showDotDotDot && 'bg-offwhite'
                                } dark:hover:bg-accent dark:${showDotDotDot && 'bg-accent'} dark:${
                                    showDotDotDot && 'text-black'
                                } dark:hover:text-black`}
                            >
                                ...
                            </button>
                            {showDotDotDot && (
                                <div className='js-dropdown absolute bottom-[calc(100%+2px)] right-0 flex flex-col gap-[2px] bg-offwhite dark:bg-darkness dark:border-[1px] dark:border-darkgrey text-black dark:text-white  max-h-[300px] overflow-auto rounded shadow-small dark:shadow-cool'>
                                    {Object.keys(dotDotDotOptions).map((option) => (
                                        <div
                                            key={option}
                                            className='js-dropdown flex flex-col bg-white dark:bg-darkaccent'
                                        >
                                            <span className='js-dropdown py-[2px] px-[8px]'>
                                                {!option.includes('title_null') && option}
                                            </span>
                                            {Object.keys(dotDotDotOptions[option]).map((subOption) => (
                                                <button
                                                    key={subOption}
                                                    onClick={dotDotDotOptions[option][subOption]}
                                                    className='js-dropdown__option text-left hover:bg-offwhite dark:hover:bg-accent dark:hover:text-black py-[4px] px-[8px] font-normal whitespace-nowrap'
                                                >
                                                    {subOption}
                                                </button>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {showNoteModal && (
                <NoteModal
                    selectedOrders={selected}
                    setShowModal={setShowNoteModal}
                    setSelectedOrders={setSelectedOrders}
                />
            )}
        </>
    )
}
