import { useContext, useState } from 'react'
import { useCompanyContactsQuery, useCompanyEmailsQuery, useCompanyIncomingEmailsQuery } from '../../api/useQueries'
import { CompanyContext, ProcurementContext, ProcurementContextType, createdAtSort } from '../../helpers'
import CompanyEmail from '../listItems/CompanyEmail'
import IncomingEmail from '../listItems/IncomingEmail'
import FilterToolBar from '../FilterToolBar'
import { FilterBarSelectorButton } from '../../../custom_components/component_Basics/FilterBarSelectorButton'
import { CheckCircledIcon, StopwatchIcon } from '@radix-ui/react-icons'
import { useDebounce } from 'helpers'

export default function EmailListV2({ company }: any) {
    const { selectedCompany, users } = useContext<ProcurementContextType>(ProcurementContext)
    const { setShowQuickModal } = useContext<any>(CompanyContext)
    const [search, setSearch] = useState('')
    const debouncedSearch = useDebounce(search.trim(), 500)
    const [filterValues, setFilterValues] = useState<{ types: string[] }>({
        types: [],
    })

    const companyContactsQuery = useCompanyContactsQuery({ companyId: selectedCompany.id })
    const { contacts } = companyContactsQuery.data || {}

    const companyEmailsQuery = useCompanyEmailsQuery({ companyId: selectedCompany.id, search: debouncedSearch })

    const { emails }: { emails: any[] } = companyEmailsQuery?.data || {}

    const companyIncomingEmailsQuery = useCompanyIncomingEmailsQuery({
        companyId: company.id,
        companyEmail: company.email,
        contactList: contacts || [],
        search: debouncedSearch,
    })

    const { emails: incomingEmails }: { emails: any[] } = companyIncomingEmailsQuery?.data || {}
    let allEmails: any[] = []
    if (emails) {
        allEmails.push(...emails.map((email) => ({ ...email, type: 'sent-email' }) as any as EventType))
    }
    if (incomingEmails) {
        allEmails.push(...incomingEmails.map((email) => ({ ...email, type: 'incoming-email' }) as any as EventType))
    }

    const filteredEmails = allEmails.sort(createdAtSort)

    const emailListFilterBarObject = {
        params: [
            {
                component: FilterBarSelectorButton,
                options: [
                    {
                        value: 'sent-email',
                        label: 'Sent',
                        icon: StopwatchIcon,
                    },
                    {
                        value: 'incoming-email',
                        label: 'Recieved',
                        icon: CheckCircledIcon,
                    },
                ],
                title: 'Status',
                field: 'types',
                values: filterValues.types,
                searchToggle: false,
            },
        ],
        setFunction: setFilterValues,
        resetFunction: () => {
            setFilterValues({
                types: [],
            })
        },
    }

    return (
        <div className='p-[2px] pl-0'>
            <div className='mb-[8px] w-full flex gap-[16px] sticky top-[32px] z-40 bg-bg1 dark:bg-darkbg1'>
                <FilterToolBar search={search} setSearch={setSearch} filterBarObject={emailListFilterBarObject}>
                    {/* {emailListFilterBarObject.params.length &&
                        emailListFilterBarObject.params.map((param: any) => {
                            return (
                                <param.component
                                    key={param.field}
                                    searchToggle={param.searchToggle}
                                    title={param.title}
                                    field={param.field}
                                    options={param.options}
                                    filterValues={param.values}
                                    setFilterValues={emailListFilterBarObject.setFunction}
                                />
                            )
                        })} */}
                </FilterToolBar>
                <button
                    onClick={() => setShowQuickModal('email')}
                    className='min-h-[32px] self-center shrink-0 text-[14px] leading-[1] py-[2px] px-[8px] rounded-[4px] bg-blue dark:bg-accent text-white dark:text-darkaccent font-bold uppercase'
                >
                    + New Email
                </button>
            </div>
            <div className='flex flex-col gap-[8px] min-h-[300px]'>
                {filteredEmails?.map((email) => {
                    if (email.type == 'incoming-email') {
                        return <IncomingEmail key={email.thread_id} email={email} />
                    }
                    return <CompanyEmail key={email.id} email={email} />
                })}
            </div>
        </div>
    )
}

export type EventType = {
    id?: string | number
    type: string
    message: string
    value: string
    previous_value?: string
    raw_data?: any
    company_id?: string | number
    user_id?: string | number
    store_id?: string | number
    created_at: string
    updated_at: string
}
