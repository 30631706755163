import { FilterRulesType } from 'followUpEmail/FollowUpEmail.types'
import { Dispatch, SetStateAction } from 'react'
import FilterRule from './FilterRule'

export default function OrderTagsEditor({
    event_type,
    rules,
    setRules,
}: {
    event_type?: string
    rules: FilterRulesType
    setRules: Dispatch<SetStateAction<FilterRulesType>>
}) {
    return (
        <div className='flex flex-col gap-1 text-sm'>
            <div className='grid grid-cols-[auto_auto_auto_auto] gap-1 items-center'>
                {event_type?.includes('order') && (
                    <FilterRule label='Order tags' table='order_tags' column='tag' rules={rules} setRules={setRules} />
                )}
                {event_type?.includes('order') && (
                    <FilterRule
                        label='Order total price'
                        table='orders'
                        column='total_price'
                        type='number'
                        rules={rules}
                        setRules={setRules}
                    />
                )}
                {event_type && (
                    <FilterRule
                        label='Line item count'
                        table={event_type.includes('order') ? 'orders' : 'abandoned_checkouts'}
                        column='line_item_count'
                        type='number'
                        rule='HAVING'
                        rules={rules}
                        setRules={setRules}
                    />
                )}
                <FilterRule
                    label='Customer tags'
                    table='customer_tags'
                    column='tag'
                    rules={rules}
                    setRules={setRules}
                />
                <FilterRule
                    label='Customer total spent'
                    table='customers'
                    column='total_spent'
                    type='number'
                    rules={rules}
                    setRules={setRules}
                />
                <FilterRule
                    label='Customer country code'
                    table='customers'
                    column='country_code'
                    rules={rules}
                    setRules={setRules}
                />
                <FilterRule label='Product tags' table='product_tags' column='tag' rules={rules} setRules={setRules} />
                <FilterRule label='Product vendor' table='products' column='vendor' rules={rules} setRules={setRules} />
                <FilterRule label='Product title' table='products' column='title' rules={rules} setRules={setRules} />
                <FilterRule
                    label='Product type'
                    table='products'
                    column='product_type'
                    rules={rules}
                    setRules={setRules}
                />
                <FilterRule label='Product SKU' table='variants' column='sku' rules={rules} setRules={setRules} />
                <FilterRule
                    label='Product price'
                    table='variants'
                    column='price'
                    type='number'
                    rules={rules}
                    setRules={setRules}
                />
                <FilterRule label='Collection ID' table='collections' column='id' rules={rules} setRules={setRules} />
            </div>
        </div>
    )
}
