import { addBusinessDays, addMonths, addWeeks } from 'date-fns'
import Input from 'procurement/components/Input'
import Select from 'procurement/components/Select'
import { HTMLDateInputFormatter } from 'tasksV2/helpers'

export default function DueAtSelector({
    selectedDate,
    setSelectedDate,
    selectedTime,
    setSelectedTime,
}: {
    selectedDate: string
    setSelectedDate: (HTMLDateString: string) => void
    selectedTime: string
    setSelectedTime: (HTMLTimeString: string) => void
}) {
    const thisMorning = new Date()
    thisMorning.setHours(0, 0, 0, 0)
    const formattedDate = HTMLDateInputFormatter(new Date(selectedDate?.replaceAll('-', '/') || 0))
    const today = HTMLDateInputFormatter(thisMorning)
    const tomorrow = HTMLDateInputFormatter(addBusinessDays(thisMorning, 1))
    const twoDays = HTMLDateInputFormatter(addBusinessDays(thisMorning, 2))
    const threeDays = HTMLDateInputFormatter(addBusinessDays(thisMorning, 3))
    const oneWeek = HTMLDateInputFormatter(addWeeks(thisMorning, 1))
    const twoWeeks = HTMLDateInputFormatter(addWeeks(thisMorning, 2))
    const oneMonth = HTMLDateInputFormatter(addMonths(thisMorning, 1))
    const threeMonths = HTMLDateInputFormatter(addMonths(thisMorning, 3))
    const sixMonths = HTMLDateInputFormatter(addMonths(thisMorning, 6))

    const isCustomDate = ![
        today,
        tomorrow,
        twoDays,
        threeDays,
        oneWeek,
        twoWeeks,
        oneMonth,
        threeMonths,
        sixMonths,
    ].includes(formattedDate)

    const handleChange = ({ target }: { target: HTMLSelectElement }) => {
        if (target.value !== 'custom') {
            setSelectedDate(target.value)
        } else {
            setSelectedDate('')
        }
    }

    return (
        <div className='flex flex-col gap-1'>
            <label className='text-darkgrey dark:text-offwhite uppercase text-xs font-bold leading-none'>Due At</label>
            <div className='flex gap-1'>
                <Select id='htmlDate' name='htmlDate' value={formattedDate} onChange={handleChange}>
                    <option value={today}>Today</option>
                    <option value={tomorrow}>Next business day</option>
                    <option value={twoDays}>In 2 business days</option>
                    <option value={threeDays}>In 3 business days</option>
                    <option value={oneWeek}>In 1 week</option>
                    <option value={twoWeeks}>In 2 weeks</option>
                    <option value={oneMonth}>In 1 month</option>
                    <option value={threeMonths}>In 3 months</option>
                    <option value={sixMonths}>In 6 months</option>
                    <option value={isCustomDate ? formattedDate : ''}>Custom</option>
                </Select>
                {isCustomDate && (
                    <Input
                        id='htmlDate'
                        name='htmlDate'
                        type='date'
                        value={selectedDate}
                        onChange={({ target }) => setSelectedDate(target.value)}
                    />
                )}
                <Input
                    id='htmlTime'
                    name='htmlTime'
                    type='time'
                    value={selectedTime}
                    onChange={({ target }) => setSelectedTime(target.value)}
                />
            </div>
        </div>
    )
}
