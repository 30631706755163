export default function TimezoneDropdown({
    className,
    id,
    name,
    handleChange,
    value,
}: {
    className: string | undefined
    id: string
    name: string
    handleChange: (...params: any) => void
    value: string
}) {
    return (
        <select className={className} id={id} name={name} value={value} onChange={handleChange}>
            <option className='dark:bg-darkness/90' value='Pacific/Midway'>
                Midway (-11:00)
            </option>
            <option className='dark:bg-darkness/90' value='Pacific/Honolulu'>
                Honolulu (-10:00)
            </option>
            <option className='dark:bg-darkness/90' value='America/Anchorage'>
                Anchorage (-09:00)
            </option>
            <option className='dark:bg-darkness/90' value='America/Los_Angeles'>
                Los Angeles (-08:00)
            </option>
            <option className='dark:bg-darkness/90' value='America/Denver'>
                Denver (-07:00)
            </option>
            <option className='dark:bg-darkness/90' value='America/Chicago'>
                Chicago (-06:00)
            </option>
            <option className='dark:bg-darkness/90' value='America/New_York'>
                New York (-05:00)
            </option>
            <option className='dark:bg-darkness/90' value='America/Santiago'>
                Santiago (-04:00)
            </option>
            <option className='dark:bg-darkness/90' value='America/Buenos_Aires'>
                Buenos Aires (-03:00)
            </option>
            <option className='dark:bg-darkness/90' value='Etc/GMT-2'>
                Mid-Atlantic (-02:00)
            </option>
            <option className='dark:bg-darkness/90' value='Atlantic/Azores'>
                Azores (-01:00)
            </option>
            <option className='dark:bg-darkness/90' value='Europe/London'>
                London (+00:00)
            </option>
            <option className='dark:bg-darkness/90' value='Europe/Paris'>
                Paris / Berlin (+01:00)
            </option>
            <option className='dark:bg-darkness/90' value='Africa/Cairo'>
                Cairo (+02:00)
            </option>
            <option className='dark:bg-darkness/90' value='Asia/Riyadh'>
                Riyadh (+03:00)
            </option>
            <option className='dark:bg-darkness/90' value='Asia/Dubai'>
                Dubai (+04:00)
            </option>
            <option className='dark:bg-darkness/90' value='Asia/Karachi'>
                Karachi (+05:00)
            </option>
            <option className='dark:bg-darkness/90' value='Asia/Dhaka'>
                Dhaka (+06:00)
            </option>
            <option className='dark:bg-darkness/90' value='Asia/Bangkok'>
                Bangkok (+07:00)
            </option>
            <option className='dark:bg-darkness/90' value='Asia/Shanghai'>
                Shanghai (+08:00)
            </option>
            <option className='dark:bg-darkness/90' value='Asia/Tokyo'>
                Tokyo (+09:00)
            </option>
            <option className='dark:bg-darkness/90' value='Australia/Brisbane'>
                Brisbane (+10:00)
            </option>
            <option className='dark:bg-darkness/90' value='Asia/Vladivostok'>
                Vladivostok (+11:00)
            </option>
            <option className='dark:bg-darkness/90' value='Pacific/Fiji'>
                Fiji (+12:00)
            </option>
            <option className='dark:bg-darkness/90' value='Pacific/Samoa'>
                Samoa (+13:00)
            </option>
        </select>
    )
}
