import { useState } from 'react'
import Pagination from '../components/Pagination'
import CompetitorProductRow from './CompetitorProductRow'
import CompetitorProductRowSkeleton from './CompetitorProductRowSkeleton'
import { useDebounce } from '../../helpers'
import { useGetCompetitorProducts } from '../api/useQueries'

export default function CompetitorProductList() {
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const debouncedSearch = useDebounce(search, 300)
    const filters = {
        search: debouncedSearch,
        page,
    }
    const getCompanyProductsQuery = useGetCompetitorProducts({
        ...filters,
        search: debouncedSearch,
    })
    const competitorProducts = getCompanyProductsQuery.data || []

    return (
        <Pagination page={filters.page} setPage={setPage} hasNextPage={competitorProducts.length === 50}>
            <div className='bg-white dark:bg-darkbg1 rounded shadow-md border border-lightgrey dark:border-darkgrey'>
                <div className='flex border-b border-lightgrey dark:border-darkgrey'>
                    <input
                        className='p-2 bg-transparent focus:outline-none w-full'
                        value={search}
                        onChange={({ target }) => {
                            setPage(1)
                            setSearch(target.value)
                        }}
                        placeholder='Search for products...'
                    />
                </div>
                <div className='bg-blue text-white dark:bg-darkbg2 z-10 dark:text-offwhite sticky top-[50px] grid grid-cols-[64px_2fr_1fr_1fr_1fr_1fr_1fr] items-center border-b border-lightgrey dark:border-darkgrey'>
                    <h2 className='font-bold uppercase text-xs p-2'>Image</h2>
                    <h2 className='font-bold uppercase text-xs p-2'>Title</h2>
                    <h2 className='font-bold uppercase text-xs p-2'>SKU</h2>
                    <h2 className='font-bold uppercase text-xs p-2'>Competitor</h2>
                    <h2 className='font-bold uppercase text-xs p-2'>Price</h2>
                    <h2 className='font-bold uppercase text-xs p-2'>Compare At Price</h2>
                    <h2 className='font-bold uppercase text-xs p-2'>Our Price</h2>
                </div>
                {getCompanyProductsQuery.isLoading ? (
                    <>
                        {Array(50)
                            .fill(<CompetitorProductRowSkeleton />)
                            .map((v) => v)}
                    </>
                ) : (
                    <>
                        {competitorProducts.map((product: any, index: number) => (
                            <CompetitorProductRow key={`competitor-product-${product.id}-${index}`} product={product} />
                        ))}
                    </>
                )}
            </div>
        </Pagination>
    )
}
