import { ExternalLinkIcon } from '@radix-ui/react-icons'
import { Button } from 'custom_components/component_Basics/Button'
import { cn, LoadingGear, sendToastWarning } from 'helpers'
import Input from 'procurement/components/Input'
import {
    useDeleteGroupProductLinks,
    useGetProductGroupDetailQuery,
    useUpdateOptions,
    useUpdateOptionValues,
} from 'productSetupV2/api/useQueries'
import { ChangeEvent, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import ProductGroupDetailHeader from './ProductGroupDetailHeader'
import ProductGroupDetailOptions from './ProductGroupDetailOptions'
import ProductGroupDetailSearch from './ProductGroupDetailSearch'
import ProductGroupFallback from './ProductGroupFallback'

export default function ProductGroupDetail({}: {}) {
    const { groupId } = useParams()
    const [editableOptionValueFields, setEditableOptionValueFields] = useState<any>({})
    const [removedOptionValues, setRemovedOptionValues] = useState<any>([])
    const [removedProductIds, setRemovedProductIds] = useState<any>([])

    const valuesEdited = Object.keys(editableOptionValueFields).length > 0 || removedProductIds.length > 0

    const updateOptions = useUpdateOptions()
    const updateOptionValues = useUpdateOptionValues()
    const deleteGroupProductLinks = useDeleteGroupProductLinks()

    const productGroupQuery = useGetProductGroupDetailQuery({ groupId })

    const { group: productGroups }: { group: ProductGroup[] } = productGroupQuery?.data || {}
    const productGroup = productGroups?.length > 0 ? productGroups[0] : {}
    const { products, options, product_option_values } = productGroup

    const handleNestedChange = (
        { target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
        product_link_id: number
    ) => {
        const relatedProductOptionValue = product_option_values?.find((option_value) => {
            return option_value.product_link_id == product_link_id && option_value.option_id.toString() == target.name
        })
        // if input empty and no row exists, remove from editableFields
        if (target.value === '' && !relatedProductOptionValue) {
            const newEditableFields = structuredClone(editableOptionValueFields)
            delete newEditableFields[product_link_id][target.name]
            setEditableOptionValueFields(newEditableFields)
        }
        // if input empty and row exists, add to removedOptionValue array
        if (target.value === '' && relatedProductOptionValue) {
            const newRemovedOptionValues = structuredClone(removedOptionValues)
            newRemovedOptionValues.push({ option_id: target.name, product_link_id })
            setRemovedOptionValues(newRemovedOptionValues)
            const newEditableFields = structuredClone(editableOptionValueFields)
            newEditableFields[product_link_id] = {
                ...newEditableFields[product_link_id],
                [target.name]: target.value,
            }
            setEditableOptionValueFields(newEditableFields)
        }
        // otherwise add to editableFields and remove from removedOptionValue array
        if (target.value !== '') {
            const newRemovedOptionValues = structuredClone(removedOptionValues)
                .filter((optionValue: OptionValue) => {
                    if (
                        optionValue.option_id?.toString() != target.name ||
                        optionValue.product_link_id != product_link_id
                    ) {
                        return optionValue
                    }
                })
                .filter((v: OptionValue) => v)

            setRemovedOptionValues(newRemovedOptionValues)
            const newEditableFields = structuredClone(editableOptionValueFields)
            newEditableFields[product_link_id] = {
                ...newEditableFields[product_link_id],
                [target.name]: target.value,
            }
            setEditableOptionValueFields(newEditableFields)
        }
    }

    function handleOptionValuesCancel() {
        setEditableOptionValueFields({})
        setRemovedOptionValues([])
        setRemovedProductIds([])
    }

    const handleSaveOptionValues = async () => {
        const updates = Object.entries(editableOptionValueFields)
            .map(([key, values]: any) => {
                const editArray = Object.entries(values).map(([key2, value2]: any) => {
                    return { product_link_id: key, option_id: key2, option_value: value2 }
                })
                return editArray
            })
            .flat()

        //filter to ignore empty string
        // const newValues = updates.map((update) => update.option_value).filter((v) => v)
        // let foundDuplicate = false
        // updates.forEach((update) => {
        // does not check if other values are changing at the same time. product1 green => blue, product2 blue => green will fail for duplicate values
        // const otherOptionValues = product_option_values
        //     ?.filter(
        //         (value) =>
        //             value.product_link_id != update.product_link_id ||
        //             (value.option_id != update.option_id && value.product_link_id == update.product_link_id)
        //     )
        //     .map((value) => value.option_value.toLowerCase().trim())
        // if (otherOptionValues?.includes(update.option_value.toLowerCase().trim())) {
        //     return (foundDuplicate = true)
        // }
        // })
        // if (Array.from(new Set(newValues)).length != newValues.length) {
        //     foundDuplicate = true
        // }

        // if (foundDuplicate) {
        //     return sendToastWarning({
        //         message: <p>Cannot contain duplicate values. </p>,
        //     })
        // }

        const deletions = removedOptionValues
        if (productGroup.id) {
            const mutation = await updateOptionValues.mutateAsync(
                { groupId: productGroup.id, updates, deletions },
                {
                    onSuccess: (data, variables) => {
                        if (removedProductIds.length < 1) {
                            handleOptionValuesCancel()
                        }
                    },
                }
            )
            // wait for above mutation
            if (mutation.success && removedProductIds.length > 0) {
                return deleteGroupProductLinks.mutate(
                    { groupId: productGroup.id, productIds: removedProductIds },
                    {
                        onSuccess: () => {
                            handleOptionValuesCancel()
                        },
                    }
                )
            }
        }
    }

    function handleRemoveProduct(product_id: number) {
        const newRemovedProducts = structuredClone(removedProductIds)
        newRemovedProducts.push(product_id)
        setRemovedProductIds(newRemovedProducts)

        const newEditableFields = structuredClone(editableOptionValueFields)
        delete newEditableFields[product_id]
        setEditableOptionValueFields(newEditableFields)
    }

    function positionSort(a: Option, b: Option) {
        return a.position - b.position
    }
    // drag and drop library needs object to possess explicit id key
    options?.forEach((option) => (option.id = option.option_id))
    if (productGroupQuery.error) {
        return <ProductGroupFallback />
    }

    return (
        <div>
            {productGroup?.id && (
                <ProductGroupDetailHeader group={productGroup} isFetching={productGroupQuery.isFetching} />
            )}
            {productGroupQuery.isLoading && (
                <div className='w-full h-[55vw] grid place-items-center shadow-small bg-white dark:bg-darkaccent'>
                    <LoadingGear />
                </div>
            )}

            <div className='rounded-[4px] shadow-small dark:bg-darkaccent min-h-[105vh]'>
                <div className='flex justify-between border-b border-lightgrey dark:border-darkgrey items-center pr-[8px] rounded-t-[4px] rounded-tl-[0px] dark:bg-darkaccent'></div>

                {!productGroupQuery.isLoading && (
                    <>
                        <div className='w-full'>
                            <div>
                                <div className='p-5 relative flex flex-col gap-[16px] h-[100%] w-full'>
                                    <>
                                        <div className='flex gap-2 '>
                                            {productGroup?.id && (
                                                <ProductGroupDetailOptions
                                                    options={options ?? []}
                                                    groupId={productGroup.id}
                                                    updateOptions={updateOptions}
                                                    handleOptionValuesCancel={handleOptionValuesCancel}
                                                    idPositions={
                                                        options?.length
                                                            ? options?.sort(positionSort).map((option) => option.id)
                                                            : []
                                                    }
                                                />
                                            )}
                                            <ProductGroupDetailSearch selectedProducts={products} />
                                        </div>
                                        <div className='w-full  relative p-[16px] pt-0 pb-0 rounded-[4px] border border-blue/25 dark:border-darkgrey dark:bg-darkness shadow-md'>
                                            <p className='absolute top-0 left-0 bg-blue dark:bg-accent rounded-tl-[4px] text-white dark:text-darkness leading-[1] text-[10px] uppercase font-bold p-[2px] px-1'>
                                                Products
                                            </p>
                                            {products && (
                                                <div
                                                    className={cn(
                                                        'flex gap-2 items-end justify-end w-full',
                                                        !valuesEdited && 'pointer-events-none opacity-40'
                                                    )}
                                                >
                                                    <div className=' p-2 rounded flex gap-2 items-center'>
                                                        <button
                                                            onClick={handleSaveOptionValues}
                                                            className={cn(
                                                                (updateOptionValues.isPending ||
                                                                    updateOptions.isPending ||
                                                                    deleteGroupProductLinks.isPending) &&
                                                                    'opacity-[0.8] pointer-events-none',
                                                                'text-xs font-bold py-1 px-2 rounded bg-accent1 dark:bg-accent1 dark:text-darkbg2 leading-none text-white dark:text-offwhite min-w-[110px]'
                                                            )}
                                                        >
                                                            {updateOptionValues.isPending ||
                                                            deleteGroupProductLinks.isPending
                                                                ? 'SAVING...'
                                                                : 'SAVE CHANGES'}
                                                        </button>
                                                        <button
                                                            onClick={handleOptionValuesCancel}
                                                            className={cn(
                                                                (updateOptionValues.isPending ||
                                                                    updateOptions.isPending ||
                                                                    deleteGroupProductLinks.isPending) &&
                                                                    'opacity-[0.6] pointer-events-none',
                                                                'text-xs font-bold py-1 px-2 rounded bg-lightgrey dark:bg-darkgrey leading-none'
                                                            )}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                            <div className='flex flex-col gap-3 rounded-[4px] dark:text-offwhite'>
                                                {products
                                                    ?.filter(
                                                        (product) => !removedProductIds.includes(product.product_id)
                                                    )
                                                    .map((product) => {
                                                        const { product_id, title, product_link_id } = product
                                                        const dashboardURL = `/products/${product_id}`
                                                        const shopifyURL = `https://admin.shopify.com/store/factorypure/products/${product_id}`

                                                        return (
                                                            <div
                                                                key={product_id}
                                                                className='relative p-2 rounded gap-3 grid grid-cols-[2fr_1fr_2fr_1fr] border dark:border-darkgrey'
                                                            >
                                                                <div className='flex flex-col gap-[4px] w-full relative'>
                                                                    <label
                                                                        htmlFor={'product_id'}
                                                                        className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1] relative w-fit min-h-[12px]'
                                                                    >
                                                                        Title
                                                                    </label>
                                                                    <p>{title}</p>
                                                                </div>
                                                                <div className='flex flex-col gap-[4px] w-full relative'>
                                                                    <label
                                                                        htmlFor={'product_id'}
                                                                        className='text-darkgrey dark:text-offwhite font-bold text-[12px] uppercase leading-[1] relative w-fit min-h-[12px]'
                                                                    >
                                                                        Product Id
                                                                    </label>
                                                                    <div
                                                                        className={cn('flex items-center gap-2 w-full')}
                                                                    >
                                                                        <Link
                                                                            id='product_id'
                                                                            target='_blank'
                                                                            rel='noopener noreferrer'
                                                                            to={dashboardURL}
                                                                            draggable={false}
                                                                            className='flex gap-2 underline items-center relative'
                                                                        >
                                                                            {product_id}
                                                                        </Link>
                                                                        <Link
                                                                            id='product_id'
                                                                            target='_blank'
                                                                            rel='noopener noreferrer'
                                                                            to={shopifyURL}
                                                                            draggable={false}
                                                                            className=''
                                                                        >
                                                                            <ExternalLinkIcon />
                                                                        </Link>
                                                                    </div>
                                                                </div>

                                                                <div className='flex flex-col gap-3'>
                                                                    {options?.map((option) => {
                                                                        const relatedProductOptionInfo =
                                                                            product_option_values?.find(
                                                                                (option_value) => {
                                                                                    return (
                                                                                        option_value.product_link_id ==
                                                                                            product_link_id &&
                                                                                        option_value.option_id ==
                                                                                            option.option_id
                                                                                    )
                                                                                }
                                                                            )

                                                                        // find nested value or return null
                                                                        const value = editableOptionValueFields[
                                                                            product_link_id
                                                                        ]
                                                                            ? typeof editableOptionValueFields[
                                                                                  product_link_id
                                                                              ][option.option_id] === 'string'
                                                                                ? editableOptionValueFields[
                                                                                      product_link_id
                                                                                  ][option.option_id]
                                                                                : null
                                                                            : null

                                                                        return (
                                                                            <Input
                                                                                key={option.option_id}
                                                                                label={option?.option_name}
                                                                                value={
                                                                                    value ??
                                                                                    (relatedProductOptionInfo?.option_value ||
                                                                                        '')
                                                                                }
                                                                                type='text'
                                                                                id={option?.option_id.toString()}
                                                                                name={option?.option_id.toString()}
                                                                                onChange={(e) =>
                                                                                    handleNestedChange(
                                                                                        e,
                                                                                        product_link_id
                                                                                    )
                                                                                }
                                                                                className={cn('dark:bg-darkbg1')}
                                                                            />
                                                                        )
                                                                    })}
                                                                </div>
                                                                <Button
                                                                    className='p-1 h-fit w-fit justify-self-end place-self-center '
                                                                    variant={'outline'}
                                                                    size={'sm'}
                                                                    onClick={(e) => handleRemoveProduct(product_id)}
                                                                >
                                                                    <p>Remove Product</p>
                                                                </Button>
                                                            </div>
                                                        )
                                                    })}
                                            </div>
                                            {!products && (
                                                <div className='flex justify-center items-center py-8'>
                                                    <p>Add products to edit group values</p>
                                                </div>
                                            )}
                                            {products && (
                                                <div
                                                    className={cn(
                                                        'flex gap-2 items-end justify-end w-full',
                                                        !valuesEdited && 'pointer-events-none opacity-40'
                                                    )}
                                                >
                                                    <div className=' p-2 rounded flex gap-2 items-center'>
                                                        <button
                                                            onClick={handleSaveOptionValues}
                                                            className={cn(
                                                                (updateOptionValues.isPending ||
                                                                    updateOptions.isPending ||
                                                                    deleteGroupProductLinks.isPending) &&
                                                                    'opacity-[0.8] pointer-events-none',
                                                                'text-xs font-bold py-1 px-2 rounded bg-accent1 dark:bg-accent1 dark:text-darkbg2 leading-none text-white dark:text-offwhite min-w-[110px]'
                                                            )}
                                                        >
                                                            {updateOptionValues.isPending ||
                                                            deleteGroupProductLinks.isPending
                                                                ? 'SAVING...'
                                                                : 'SAVE CHANGES'}
                                                        </button>
                                                        <button
                                                            onClick={handleOptionValuesCancel}
                                                            className={cn(
                                                                (updateOptionValues.isPending ||
                                                                    updateOptions.isPending ||
                                                                    deleteGroupProductLinks.isPending) &&
                                                                    'opacity-[0.6] pointer-events-none',
                                                                'text-xs font-bold py-1 px-2 rounded bg-lightgrey dark:bg-darkgrey leading-none'
                                                            )}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </>

                                    {/* push bottom of page down so lower save isnt always covered by toast */}
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export type ProductGroup = {
    id?: number | string
    group_name?: string
    options?: {
        id: number
        option_id: number
        option_name: string
        position: number
    }[]
    products?: {
        product_id: number
        product_link_id: number
        title: string
    }[]
    product_option_values?: {
        option_id: number
        option_value: string
        product_link_id: number
    }[]
}

export type Product = {
    product_id: number
    product_link_id: number
    title: string
}

export type Option = {
    id: number
    option_id: number
    option_name: string
    position: number
}

export type OptionValue = {
    option_id: number
    option_value: string
    product_link_id: number
}
