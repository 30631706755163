import React from 'react'
import { useGetScoreSetupGroups, useGetScoreSetupOptions, useGetScores } from '../api/useQueries'

export default function ScoreView({ company }: { company: any }) {
    const getScoreSetupGroupsQuery = useGetScoreSetupGroups()
    const { groups } = getScoreSetupGroupsQuery?.data || {}

    const getScoreSetupOptionsQuery = useGetScoreSetupOptions()
    const { options } = getScoreSetupOptionsQuery?.data || {}
    let positionMap = undefined
    if (groups) {
        positionMap = groups[0]?.position_map
    }
    const selectedOptionIdArray: { groupId: number; optionId: number }[] = []
    const groupIdArray = company?.active_score_groups?.split(',')
    const optionIdArray = company?.active_score_options?.split(',')
    groupIdArray?.map((groupId: number, index: number) => {
        selectedOptionIdArray.push({
            groupId,
            optionId: optionIdArray[index],
        })
    })

    // if no options selected push dummy object to create proper display.
    if (!groupIdArray) {
        selectedOptionIdArray.push({ groupId: 0, optionId: 0 })
    }

    return (
        <div className='text-[14px] font-bold p-[8px] relative group cursor-help'>
            <p>{company.overall_score_v2}</p>
            <div className='hidden group-hover:grid absolute border top-[100%] left-0 w-[400px] bg-white shadow-small dark:bg-darkaccent p-[8px] rounded-[4px] z-[5] grid-cols-[auto_30px_auto]'>
                {Object.entries(selectedOptionIdArray).length &&
                    positionMap?.map((groupId: any, index: number) => {
                        const relatedGroup = groups?.filter((group: any) => group.id === groupId)[0]
                        const relatedOptions = options?.filter(
                            (option: any) => option.procurement_score_group_id === relatedGroup?.id
                        )
                        {
                            const selectedOptionId = selectedOptionIdArray?.filter(
                                (selection: any) => selection.groupId == relatedGroup?.id
                            )[0]?.optionId
                            const selectedOption = relatedOptions?.filter(
                                (option: any) => option.id == selectedOptionId
                            )[0]
                            return (
                                <React.Fragment key={`${groupId}${index}`}>
                                    <p>{relatedGroup?.name}:</p>
                                    <p className='text-fire'>{selectedOption?.value || 0}</p>
                                    <p className='font-normal'>{selectedOption?.name || 'No Selection'}</p>
                                </React.Fragment>
                            )
                        }
                    })}
            </div>
        </div>
    )
}
