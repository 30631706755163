import { LuCircleDashed } from 'react-icons/lu'
import { FaCheckCircle, FaExclamationCircle, FaRegCircle } from 'react-icons/fa'
import { FaCircleHalfStroke } from 'react-icons/fa6'
import { BiSolidXCircle } from 'react-icons/bi'
import { BsHexagonHalf } from 'react-icons/bs'
import { TbAntennaBars1, TbAntennaBars3, TbAntennaBars4, TbAntennaBars5, TbHexagonMinus, TbHexagonOff } from 'react-icons/tb'
import { MdHexagon, MdOutlineHexagon } from 'react-icons/md'
import { IoEllipsisHorizontalCircle } from 'react-icons/io5'

export const taskStatusDisplayMap: any = {
    backlog: {
        icon: LuCircleDashed,
        className: '',
        sortValue: 0
    },
    todo: {
        icon: FaRegCircle,
        className: '',
        sortValue: 1
    },
    'in progress': {
        icon: FaCircleHalfStroke,
        className: 'text-fire',
        sortValue: 2
    },
    'in review': {
        icon: FaExclamationCircle,
        className: 'text-lime',
        sortValue: 3
    },
    blocked: {
        icon: BiSolidXCircle,
        className: 'text-red dark:text-lightred',
        sortValue: 4
    },
    done: {
        icon: FaCheckCircle,
        className: 'text-slate',
        sortValue: 5
    },
    canceled: {
        icon: BiSolidXCircle,
        className: 'text-grey',
        sortValue: 6
    },
}

export const ticketStatusDisplayMap: any = {
    triage: {
        icon: IoEllipsisHorizontalCircle,
        className: 'text-fire'
    },
    todo: {
        icon: FaRegCircle,
        className: '',
    },
    'in progress': {
        icon: FaCircleHalfStroke,
        className: 'text-fire',
    },
    'in review': {
        icon: FaExclamationCircle,
        className: 'text-lime',
    },
    blocked: {
        icon: BiSolidXCircle,
        className: 'text-red dark:text-lightred',
    },
    done: {
        icon: FaCheckCircle,
        className: 'text-slate',
    },
    canceled: {
        icon: BiSolidXCircle,
        className: 'text-grey',
    },
}

export const projectStatusDisplayMap: any = {
    backlog: {
        icon: TbHexagonMinus,
        className: '',
    },
    planned: {
        icon: MdOutlineHexagon,
        className: '',
    },
    paused: {
        icon: MdOutlineHexagon,
        className: 'text-fire',
    },
    'in progress': {
        icon: BsHexagonHalf,
        className: '',
    },
    completed: {
        icon: MdHexagon,
        className: 'text-blue',
    },
    canceled: {
        icon: TbHexagonOff,
        className: 'text-grey',
    },
}

export const priorityMap = {
    low: {
        icon: TbAntennaBars1,
        className: ''
    },
    medium: {
        icon:TbAntennaBars3, 
        className:'',
    },
    high: {
        icon: TbAntennaBars4, 
        className: '',
    },
    urgent: {
        icon: TbAntennaBars5, 
        className: 'text-red dark:text-lightred',
    },
}