import { SkeletonBasic } from '../../custom_components/component_Basics/SkeletonBasic'
import { cn } from '../../helpers'

export default function CompanyProductSkeleton() {
    return (
        <div
            className={cn(
                `border rounded-[4px] shadow-small grid grid-cols-[1.5fr_3.25fr_0.75fr_1.5fr_32px] text-[14px] relative`
            )}
        >
            <div className={cn('flex flex-col gap-5 p-[8px] justify-center')}>
                <SkeletonBasic className='h-[28px] w-[80%]'></SkeletonBasic>
                <SkeletonBasic className='h-[28px] w-[80%]'></SkeletonBasic>
                <SkeletonBasic className='h-[28px] w-[80%]'></SkeletonBasic>
            </div>
            <div
                className={cn(
                    'relative grid grid-rows-[24px_30px_34px_34px] grid-cols-[0.75fr_0.75fr_0.75fr_.80fr] py-[8px] gap-x-2 gap-y-5 mr-3'
                )}
            >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div className='relative grid grid-cols-[0.75fr_0.75fr_0.75fr_.80fr] px-2 py-1  gap-x-2 col-span-4  rounded-md  items-center'>
                    <div className=' flex'>
                        <SkeletonBasic className={cn('h-[28px] w-[160px] p-0')} />
                    </div>
                    <div className=' flex relative'>
                        <SkeletonBasic className={cn('h-[28px] w-[160px] p-0')} />
                    </div>
                    <div className=' flex relative'>
                        <SkeletonBasic className={cn('h-[28px] w-[160px] p-0')} />
                    </div>
                    <div className={cn(' font-bold flex flex-col gap-2 w-fit')}></div>
                </div>

                <div className={cn('relative grid grid-cols-[0.75fr_0.75fr_0.75fr_.80fr] px-2 gap-x-2 col-span-4 ')}>
                    <div className=''>
                        <SkeletonBasic className={cn('h-[28px] w-[160px] p-0')} />
                    </div>
                    <div className=''>
                        <SkeletonBasic className={cn('h-[28px] w-[160px] p-0')} />
                    </div>
                    <div className=''>
                        <SkeletonBasic className={cn('h-[28px] w-[160px] p-0')} />
                    </div>
                    <div className={cn(' mt-1 font-bold flex flex-col gap-2')}></div>
                </div>

                <div className='flex flex-col col-start-4 items-start justify-end'></div>
            </div>
            <div className={cn('py-[8px] flex flex-col gap-4 justify-center')}>
                <SkeletonBasic className={cn('h-[28px] w-[160px] p-0')} />
                <SkeletonBasic className={cn('h-[28px] w-[160px] p-0')} />
                <SkeletonBasic className={cn('h-[28px] w-[160px] p-0')} />
            </div>

            <div className='p-5'>
                <SkeletonBasic className={cn('h-[160px] w-[240px] p-5')} />
            </div>
        </div>
    )
}
