import { createQueryKeys, mergeQueryKeys } from '@lukemorales/query-key-factory'
import * as service from './service'

export const customers = createQueryKeys('customers', {
    list: (filters: any) => ({
        queryKey: [filters],
        queryFn: () => service.getCustomers(filters),
    }),
})

export default mergeQueryKeys(customers)
