import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { ShopifyProduct } from '../ProductSetup.types'
import { LoadingGear, useDebounce, vFetch } from '../../helpers'
import { GiKeyboard } from 'react-icons/gi'
import { LuAngry } from 'react-icons/lu'
import { useLocation, useNavigate } from 'react-router-dom'

export default function ProductSelect({
    product,
    status,
    setStatus,
    handleClearProduct,
    handleFetchProduct,
}: {
    product: ShopifyProduct
    setProduct: Dispatch<SetStateAction<ShopifyProduct>>
    status: string
    setStatus: Dispatch<SetStateAction<string>>
    handleClearProduct: Function
    handleFetchProduct: Function
}) {
    const [search, setSearch] = useState(product.title || '')
    const [products, setProducts] = useState<any>([])
    const debouncedSearch = useDebounce(search, 300)
    const abortController = new AbortController()
    const navigate = useNavigate()

    const previousLocationPathname: string | undefined = useLocation().state?.previousLocationPathname

    useEffect(() => {
        if (!search.length) {
            setStatus('pending')
        } else {
            setStatus('loading')
        }
    }, [search])

    useEffect(() => {
        if (debouncedSearch.length) {
            vFetch(`/v2/products?search=${encodeURIComponent(debouncedSearch)}&fields=title`, {
                cb: (res: any) => {
                    if (!res.success) {
                        return setStatus('failed')
                    }
                    setStatus('loaded')
                    return setProducts(res.products)
                },
                catchCb: (err: any) => {
                    setStatus('failed')
                },
                abortController,
            })
        }

        return () => abortController.abort()
    }, [debouncedSearch])

    return (
        <>
            <div
                className={`${
                    product.id ? 'shadow-small rounded-b-[4px] mb-[16px]' : ''
                } flex justify-between items-center pr-[8px] dark:bg-darkaccent`}
            >
                <div className='w-full p-[8px] flex'>
                    <input
                        type='text'
                        className='w-full bg-transparent dark:text-white focus:outline-none overflow-hidden whitespace-nowrap text-ellipsis pr-[16px]'
                        placeholder='Search'
                        value={product.title || search}
                        disabled={!!product.id}
                        onChange={({ target }) => {
                            setSearch(target.value)
                        }}
                    />
                    {product.id && (
                        <button
                            onClick={() => {
                                if (previousLocationPathname?.includes('products')) {
                                    navigate(-1)
                                    return
                                }
                                navigate('/products')
                            }}
                            className='py-[4px] px-[8px] rounded-[4px] bg-darkgrey text-white dark:text-offwhite shrink-0 font-bold'
                        >
                            SELECT DIFFERENT PRODUCT
                        </button>
                    )}
                </div>
            </div>
            {!product.id && (
                <div className='rounded-b-[4px] dark:bg-darkaccent'>
                    {status === 'pending' ? (
                        <div className='flex items-center ml-[8px] h-[90px]'>
                            <GiKeyboard className='text-blue dark:text-darkgrey w-[100px] h-[100px] relative top-[10px]' />
                            <p className='p-[16px] dark:text-grey text-[18px]'>Start typing to find products</p>
                        </div>
                    ) : status === 'loading' ? (
                        <div className='flex items-center ml-[8px] h-[90px] relative'>
                            <LoadingGear color={'#4a4a4a'} width={'100px'} height={'50px'} />
                            <p className='p-[16px] dark:text-grey text-[18px]'>Finding products...</p>
                        </div>
                    ) : status === 'loading-product' ? (
                        <div className='flex items-center ml-[8px] h-[90px] relative'>
                            <LoadingGear color={'#4a4a4a'} width={'100px'} height={'50px'} />
                            <p className='p-[16px] dark:text-grey text-[18px]'>Tracking down your product...</p>
                        </div>
                    ) : status === 'loaded' && products.length > 0 ? (
                        products.map((p: any) => (
                            <button
                                onClick={() => handleFetchProduct(p.id)}
                                className='flex items-center w-full p-[8px] border-b border-lightgrey dark:border-darkgrey gap-[8px]'
                            >
                                <img
                                    className='p-[4px] w-[48px] border border-lightgrey dark:border-darkgrey h-[48px] rounded-[4px] object-contain bg-lightgrey dark:bg-darkness'
                                    src={p.featured_image}
                                />
                                <p className='dark:text-white font-bold'>{p.title}</p>
                            </button>
                        ))
                    ) : status === 'loaded' ? (
                        <div className='flex items-center ml-[8px] h-[90px] relative'>
                            <LuAngry className='text-red dark:text-lightred w-[100px] h-[50px]' />
                            <p className='p-[16px] dark:text-offwhite text-[18px]'>No products matched this search!</p>
                        </div>
                    ) : (
                        <div className='flex items-center ml-[8px] h-[90px] relative]'>
                            <LuAngry className='text-red dark:text-lightred w-[100px] h-[50px]' />
                            <p className='p-[16px] dark:text-offwhite text-[18px]'>Failed to fetch products!</p>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}
