import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import SupplierSelect from './SupplierSelect'
import { SCAC_CODE_MAP, SHIPPING_DESTINATION_TYPES, STOCKING_ADDRESS } from '../constants'
import PurchaseOrderLineItems from './PurchaseOrderLineItems'
import { AddressType, PurchaseOrderType, SupplierType, defaultSupplier } from '../PurchaseOrder.types'
import { sendToast, vFetch } from '../../helpers'
import pdfMake from 'pdfmake/build/pdfmake.js'
import { vfs } from 'helpers/fonts'
pdfMake.vfs = vfs

export default function PurchaseOrder({
    order,
    orderIndex,
    orders,
    setOrders,
    suppliers,
    products,
    po_id,
}: {
    order: PurchaseOrderType
    orderIndex: number
    orders: PurchaseOrderType[]
    setOrders: Dispatch<SetStateAction<PurchaseOrderType[]>>
    suppliers: SupplierType[]
    products: any
    po_id?: string
}) {
    const [selectedSupplier, setSelectedSupplier] = useState(defaultSupplier)
    const [dropship, setDropship] = useState(false)
    const [stocking, setStocking] = useState(false)

    const handleRemove = () => {
        const confirmation = window.confirm('Remove PO from editor?')
        if (confirmation) {
            setOrders((previousState) => {
                const newState = structuredClone(previousState)
                newState.splice(orderIndex, 1)
                return newState
            })
        }
    }
    const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setOrders((previousState) => {
            const newState = structuredClone(previousState)
            ;(newState[orderIndex][target.name as keyof PurchaseOrderType] as any) =
                target.type === 'checkbox' ? ((target as HTMLInputElement).checked ? 1 : 0) : target.value
            return newState
        })
    }

    const handleChangeShippingAddress = ({ target }: any) => {
        setOrders((previousState) => {
            const newState = structuredClone(previousState)
            newState[orderIndex].shipping_address[target.name as keyof AddressType] = target.value
            return newState
        })
    }
    const handleChangeShipFromAddress = ({ target }: any) => {
        setOrders((previousState) => {
            const newState = structuredClone(previousState)
            newState[orderIndex].ship_from_address[target.name.replace('ship_from_', '') as keyof AddressType] =
                target.value
            return newState
        })
    }
    const handleChangeCarrier = ({ target }: any) => {
        setOrders((previousState) => {
            const newState = structuredClone(previousState)
            const carrier_details = SCAC_CODE_MAP[target.value] || {
                carrier_scac: '',
                carrier_name: '',
                fp_carrier_account_number: '',
            }
            newState[orderIndex].carrier_scac = target.value
            newState[orderIndex].carrier_name = carrier_details.carrier_name
            newState[orderIndex].fp_carrier_account_number = carrier_details.fp_carrier_account_number
            return newState
        })
    }
    const createAndOpenPdf = async () => {
        try {
            await vFetch(`/v1/orders/purchase-orders/pdf`, {
                method: 'POST',
                body: JSON.stringify({
                    purchaseOrder: {
                        ...order,
                        total: order.line_items
                            .map((item) => item.quantity * (item.item_cost || 0))
                            .reduce((acc, cur) => acc + cur, 0),
                    },
                }),
                cb: (res: any) => {
                    if (res.success) {
                        const pdfDoc = pdfMake.createPdf(JSON.parse(JSON.stringify(res.sheet)))
                        pdfDoc.open()
                    }
                },
            })
        } catch (err) {
            sendToast(err)
        }
    }
    const openPdf = (sheet: any) => {
        const pdfDoc = pdfMake.createPdf(JSON.parse(JSON.stringify(sheet)))
        pdfDoc.open()
    }

    useEffect(() => {
        const foundSupplier = suppliers.find((supplier) => {
            return supplier.name == order.supplier || supplier.id === order.supplier_id
        })
        if (foundSupplier) {
            setSelectedSupplier(foundSupplier)
        }
    }, [orders.length])

    useEffect(() => {
        setOrders((previousState) => {
            const newState = structuredClone(previousState)
            newState[orderIndex] = {
                ...newState[orderIndex],
                supplier: selectedSupplier.name,
                supplier_id: selectedSupplier.id,
                supplier_email: selectedSupplier.email_addresses?.split(',') || [],
                supplier_edi_info:
                    selectedSupplier.po_type === 'edi'
                        ? {
                              test_edi_id: `${selectedSupplier.edi_test_isa_qualifier}/${selectedSupplier.edi_test_isa_identifier}`,
                              production_edi_id: `${selectedSupplier.edi_isa_qualifier}/${selectedSupplier.edi_isa_identifier}`,
                              test_as2_station_id: selectedSupplier.edi_test_as2_station || '',
                              production_as2_station_id: selectedSupplier.edi_as2_station || '',
                          }
                        : undefined,
                show_cost: selectedSupplier.show_cost,
                line_items: newState[orderIndex].line_items,
                accepts_email: selectedSupplier.po_type === 'email' ? 1 : 0,
                accepts_edi: selectedSupplier.po_type === 'edi' ? 1 : 0,
            }
            return newState
        })
        if (
            selectedSupplier.name === 'Generac' ||
            selectedSupplier.name === 'Duromax' ||
            selectedSupplier.name === 'Great Circle US' ||
            selectedSupplier.name === 'Firman'
        ) {
            setDropship(true)
        }
        if (selectedSupplier.po_note && !order.notes) {
            setOrders((previousState) => {
                const newState = structuredClone(previousState)
                newState[orderIndex].notes = selectedSupplier.po_note || ''
                return newState
            })
        }
    }, [selectedSupplier])

    useEffect(() => {
        setOrders((previousState) => {
            const newState = structuredClone(previousState)
            newState[orderIndex].dropship = dropship
            newState[orderIndex].destination_code = dropship ? 'H' : ''
            newState[orderIndex].requires_liftgate = dropship ? 1 : 0
            return newState
        })
    }, [dropship])

    useEffect(() => {
        setOrders((previousState) => {
            const newState = structuredClone(previousState)
            newState[orderIndex].stocking = stocking
            if (stocking) {
                newState[orderIndex].shipping_address = STOCKING_ADDRESS
            }
            return newState
        })
    }, [stocking])

    return (
        <>
            <div className='p-[8px] border border-grey dark:border-darkgrey rounded-[4px] my-[16px] flex flex-col gap-[8px] relative'>
                {po_id && (
                    <h2 className='dark:text-offwhite font-bold'>
                        Purchase Order {po_id} (SHOPIFY {order.order_name}) - Sent{' '}
                        {new Date(order.created_at.split('T')[0].replaceAll('-', '/')).toDateString()}
                    </h2>
                )}
                {!selectedSupplier.id && (
                    <div className='text-[rgb(255,0,0)] z-[2] text-[18px] font-bold'>
                        THIS SUPPLIER DOES NOT ACCEPT EMAILED PURCHASE ORDERS
                    </div>
                )}
                {orders.length > 1 && (
                    <button
                        onClick={handleRemove}
                        className='absolute top-0 right-[0px] text-[32px] text-white cursor-pointer dark:text-offwhite font-medium leading-[0.5] bg-darkgrey pb-[4px] pr-[2px] pl-[2px] rounded-bl-[4px] shadow-small'
                    >
                        &times;
                    </button>
                )}
                <div className='flex gap-[8px]'>
                    <div className='flex flex-col gap-[4px] w-[20%] p-[16px] rounded-[4px] dark:bg-darkaccent shadow-small h-fit'>
                        <div className='flex w-full'>
                            <div className='flex flex-col gap-[4px] w-full mb-[4px]'>
                                <label className='font-bold text-[12px] dark:text-offwhite leading-[1] uppercase'>
                                    PO Number
                                </label>
                                <input
                                    type='text'
                                    className='bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                    value={order.order_name}
                                    name='order_name'
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        {suppliers && (
                            <SupplierSelect
                                suppliers={suppliers}
                                selectedSupplier={selectedSupplier}
                                setSelectedSupplier={setSelectedSupplier}
                            />
                        )}
                    </div>
                    <PurchaseOrderLineItems
                        order={order}
                        orderIndex={orderIndex}
                        setOrders={setOrders}
                        products={products}
                        selectedSupplier={selectedSupplier}
                    />
                </div>
                <div className='flex gap-[8px] w-auto'>
                    <div className='flex flex-col gap-[8px] dark:bg-darkaccent p-[16px] rounded-[4px] shadow-small w-1/2'>
                        <div className='w-fit flex gap-[8px] w-full items-center justify-between dark:text-offwhite font-bold text-[12px] uppercase leading-[1] border-b border-offwhite mb-[8px]'>
                            <p>{selectedSupplier.po_type === 'edi' ? 'Ship To' : 'Shipping Address'}</p>
                            {selectedSupplier.po_type === 'edi' && (
                                <div className='flex gap-[4px]'>
                                    <input
                                        className='w-fit bg-lightgrey focus:outline-none dark:text-offwhite p-[4px] rounded-[4px]'
                                        type='checkbox'
                                        name='stocking'
                                        checked={stocking}
                                        onChange={({ target }) => setStocking(target.checked)}
                                    />
                                    <label className='dark:text-offwhite font-normal text-[12px] uppercase leading-[1]'>
                                        Stocking Order
                                    </label>
                                </div>
                            )}
                        </div>
                        <div className='flex flex-col gap-[4px]'>
                            <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                Company
                            </label>
                            <input
                                className='bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                type='text'
                                name='company'
                                value={order.shipping_address && order.shipping_address.company}
                                onChange={handleChangeShippingAddress}
                            />
                        </div>
                        <div className='flex gap-[8px]'>
                            <div className='flex flex-col gap-[4px] w-full'>
                                <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    First Name
                                </label>
                                <input
                                    className='bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                    type='text'
                                    name='first_name'
                                    value={order.shipping_address && order.shipping_address.first_name}
                                    onChange={handleChangeShippingAddress}
                                />
                            </div>
                            <div className='flex flex-col gap-[4px] w-full'>
                                <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    Last Name
                                </label>
                                <input
                                    className='bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                    type='text'
                                    name='last_name'
                                    value={order.shipping_address && order.shipping_address.last_name}
                                    onChange={handleChangeShippingAddress}
                                />
                            </div>
                        </div>
                        <div className='flex flex-col gap-[4px]'>
                            <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                Address Line 1
                            </label>
                            <input
                                className='bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                type='text'
                                name='address1'
                                value={order.shipping_address && order.shipping_address.address1}
                                onChange={handleChangeShippingAddress}
                            />
                        </div>
                        <div className='flex flex-col gap-[4px]'>
                            <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                Address Line 2
                            </label>
                            <input
                                className='bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                type='text'
                                name='address2'
                                value={order.shipping_address && order.shipping_address.address2}
                                onChange={handleChangeShippingAddress}
                            />
                        </div>
                        <div className='flex gap-[8px] w-full'>
                            <div className='flex flex-col gap-[4px] w-1/3'>
                                <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    City
                                </label>
                                <input
                                    className='w-full bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                    type='text'
                                    name='city'
                                    value={order.shipping_address && order.shipping_address.city}
                                    onChange={handleChangeShippingAddress}
                                />
                            </div>
                            <div className='flex flex-col gap-[4px] w-1/3'>
                                <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    State
                                </label>
                                <input
                                    className='w-full bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                    type='text'
                                    name='province_code'
                                    value={order.shipping_address && order.shipping_address.province_code}
                                    onChange={handleChangeShippingAddress}
                                />
                            </div>
                            <div className='flex flex-col gap-[4px] w-1/3'>
                                <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    Zip
                                </label>
                                <input
                                    className='w-full bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                    type='text'
                                    name='zip'
                                    value={order.shipping_address && order.shipping_address.zip}
                                    onChange={handleChangeShippingAddress}
                                />
                            </div>
                        </div>
                        <div className='flex flex-col gap-[4px]'>
                            <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                Country Code
                            </label>
                            <input
                                className='bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                type='text'
                                name='country_code'
                                value={order.shipping_address && order.shipping_address.country_code}
                                onChange={handleChangeShippingAddress}
                            />
                        </div>
                        <div className='flex flex-col gap-[4px]'>
                            <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                Phone
                            </label>
                            <input
                                className='bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                type='text'
                                name='phone'
                                value={order.shipping_address && order.shipping_address.phone}
                                onChange={handleChangeShippingAddress}
                            />
                        </div>
                    </div>
                    {selectedSupplier.po_type === 'edi' ? (
                        <div className='flex flex-col gap-[8px] dark:bg-darkaccent p-[16px] rounded-[4px] shadow-small w-1/2'>
                            <div className='w-fit flex gap-[8px] w-full items-center justify-between dark:text-offwhite font-bold text-[12px] uppercase leading-[1] border-b border-offwhite mb-[8px]'>
                                <p>Ship From</p>
                                <div className='flex gap-[4px]'>
                                    <input
                                        className='w-fit bg-lightgrey focus:outline-none dark:text-offwhite p-[4px] rounded-[4px]'
                                        type='checkbox'
                                        name='dropship'
                                        checked={dropship}
                                        onChange={({ target }) => setDropship(target.checked)}
                                    />
                                    <label className='dark:text-offwhite font-normal text-[12px] uppercase leading-[1]'>
                                        Let Supplier Choose
                                    </label>
                                </div>
                            </div>
                            <div className='flex flex-col gap-[4px]'>
                                <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    Company
                                </label>
                                <input
                                    disabled={dropship}
                                    className='disabled:opacity-[0.5] bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                    type='text'
                                    name='ship_from_company'
                                    value={order.ship_from_address && order.ship_from_address.company}
                                    onChange={handleChangeShipFromAddress}
                                />
                            </div>
                            <div className='flex gap-[8px]'>
                                <div className='flex flex-col gap-[4px] w-full'>
                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                        First Name
                                    </label>
                                    <input
                                        disabled={dropship}
                                        className='disabled:opacity-[0.5] bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                        type='text'
                                        name='ship_from_first_name'
                                        value={order.ship_from_address && order.ship_from_address.first_name}
                                        onChange={handleChangeShipFromAddress}
                                    />
                                </div>
                                <div className='flex flex-col gap-[4px] w-full'>
                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                        Last Name
                                    </label>
                                    <input
                                        disabled={dropship}
                                        className='disabled:opacity-[0.5] bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                        type='text'
                                        name='ship_from_last_name'
                                        value={order.ship_from_address && order.ship_from_address.last_name}
                                        onChange={handleChangeShipFromAddress}
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col gap-[4px]'>
                                <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    Address Line 1
                                </label>
                                <input
                                    disabled={dropship}
                                    className='disabled:opacity-[0.5] bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                    type='text'
                                    name='ship_from_address1'
                                    value={order.ship_from_address && order.ship_from_address.address1}
                                    onChange={handleChangeShipFromAddress}
                                />
                            </div>
                            <div className='flex flex-col gap-[4px]'>
                                <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    Address Line 2
                                </label>
                                <input
                                    disabled={dropship}
                                    className='disabled:opacity-[0.5] bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                    type='text'
                                    name='ship_from_address2'
                                    value={order.ship_from_address && order.ship_from_address.address2}
                                    onChange={handleChangeShipFromAddress}
                                />
                            </div>
                            <div className='flex gap-[8px] w-full'>
                                <div className='flex flex-col gap-[4px] w-1/3'>
                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                        City
                                    </label>
                                    <input
                                        disabled={dropship}
                                        className='disabled:opacity-[0.5] w-full bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                        type='text'
                                        name='ship_from_city'
                                        value={order.ship_from_address && order.ship_from_address.city}
                                        onChange={handleChangeShipFromAddress}
                                    />
                                </div>
                                <div className='flex flex-col gap-[4px] w-1/3'>
                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                        State
                                    </label>
                                    <input
                                        disabled={dropship}
                                        className='disabled:opacity-[0.5] w-full bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                        type='text'
                                        name='ship_from_province_code'
                                        value={order.ship_from_address && order.ship_from_address.province_code}
                                        onChange={handleChangeShipFromAddress}
                                    />
                                </div>
                                <div className='flex flex-col gap-[4px] w-1/3'>
                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                        Zip
                                    </label>
                                    <input
                                        disabled={dropship}
                                        className='disabled:opacity-[0.5] w-full bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                        type='text'
                                        name='ship_from_zip'
                                        value={order.ship_from_address && order.ship_from_address.zip}
                                        onChange={handleChangeShipFromAddress}
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col gap-[4px]'>
                                <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    Country Code
                                </label>
                                <input
                                    disabled={dropship}
                                    className='disabled:opacity-[0.5] bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                    type='text'
                                    name='ship_from_country_code'
                                    value={order.ship_from_address && order.ship_from_address.country_code}
                                    onChange={handleChangeShipFromAddress}
                                />
                            </div>
                            <div className='flex flex-col gap-[4px]'>
                                <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    Phone
                                </label>
                                <input
                                    disabled={dropship}
                                    className='disabled:opacity-[0.5] bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                    type='text'
                                    name='ship_from_phone'
                                    value={order.ship_from_address && order.ship_from_address.phone}
                                    onChange={handleChangeShipFromAddress}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className='flex flex-col gap-[4px] p-[16px] w-1/2 dark:bg-darkaccent shadow-small rounded-[4px] h-fit'>
                            <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                Notes
                            </label>
                            <textarea
                                className='bg-lightgrey dark:bg-darkness dark:text-offwhite border-none rounded-[4px] focus:outline-none'
                                value={order.notes}
                                name='notes'
                                onChange={handleChange}
                            ></textarea>
                        </div>
                    )}
                </div>
                {selectedSupplier.po_type === 'edi' && (
                    <div className='flex gap-[8px]'>
                        <div className='flex flex-col gap-[8px] dark:bg-darkaccent p-[16px] rounded-[4px] shadow-small w-1/2'>
                            <div className='flex flex-col gap-[4px]'>
                                <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                    Shipping Carrier
                                </label>
                                <select
                                    disabled={dropship}
                                    className='disabled:opacity-[0.5] bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                    name='carrier_details'
                                    onChange={handleChangeCarrier}
                                >
                                    <option value={''}>Choose a carrier</option>
                                    {Object.entries(SCAC_CODE_MAP)
                                        .sort((a, b) => (a[1].carrier_name > b[1].carrier_name ? 1 : -1))
                                        .map(([key, val]) => (
                                            <option key={key} value={key}>
                                                {val.carrier_name}
                                            </option>
                                        ))}
                                    <option value={''}>Other</option>
                                </select>
                            </div>
                            <div className='flex gap-[8px] w-full'>
                                <div className='flex flex-col gap-[4px] w-1/3'>
                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                        SCAC Code
                                    </label>
                                    <input
                                        disabled={dropship}
                                        className='disabled:opacity-[0.5] w-full bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                        type='text'
                                        name='carrier_scac'
                                        value={order.carrier_scac}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='flex flex-col gap-[4px] w-1/3'>
                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                        Carrier Name
                                    </label>
                                    <input
                                        disabled={dropship}
                                        className='disabled:opacity-[0.5] w-full bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                        type='text'
                                        name='carrier_name'
                                        value={order.carrier_name}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='flex flex-col gap-[4px] w-1/3'>
                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                        FP Account Number
                                    </label>
                                    <input
                                        disabled={dropship}
                                        className='disabled:opacity-[0.5] w-full bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                        type='text'
                                        name='fp_carrier_account_number'
                                        value={order.fp_carrier_account_number}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='flex gap-[8px]'>
                                <div className='flex flex-col gap-[4px] w-2/3'>
                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                        Shipping Destination Type
                                    </label>
                                    <select
                                        className='bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                        name='destination_code'
                                        value={order.destination_code}
                                        onChange={handleChange}
                                    >
                                        <option value=''>Choose Destination Type</option>
                                        {Object.entries(SHIPPING_DESTINATION_TYPES).map(([key, val]) => (
                                            <option key={key} value={key}>
                                                {val}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='flex flex-col gap-[4px] w-1/3'>
                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                        Requires Liftgate
                                    </label>
                                    <input
                                        className='w-fit h-[32px] bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                        type='checkbox'
                                        name='requires_liftgate'
                                        checked={order.requires_liftgate === 1}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='flex gap-[8px] w-full'>
                                <div className='flex flex-col gap-[4px] w-full'>
                                    <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                        Insurance Cost
                                    </label>
                                    <input
                                        className='w-full bg-lightgrey focus:outline-none dark:text-offwhite font-[600] dark:bg-darkness p-[4px] rounded-[4px]'
                                        type='number'
                                        name='insurance_cost'
                                        defaultValue='0'
                                        value={order.insurance_cost}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-[4px] p-[16px] w-1/2 dark:bg-darkaccent shadow-small rounded-[4px] h-fit'>
                            <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                Notes
                            </label>
                            <textarea
                                className='bg-lightgrey dark:bg-darkness dark:text-offwhite border-none rounded-[4px] focus:outline-none !p-[8px]'
                                value={order.notes}
                                name='notes'
                                onChange={handleChange}
                            ></textarea>
                        </div>
                    </div>
                )}
                <button
                    className='dark:text-offwhite py-[4px] px-[16px] border w-fit mt-[8px] mx-auto border-grey roudned-[4px]'
                    onClick={createAndOpenPdf}
                >
                    Download PDF
                </button>
            </div>
            {po_id && (
                <div className='p-[32px] border border-grey dark:bg-darkaccent rounded-[4px] my-[16px] flex flex-col gap-[16px] relative'>
                    <h2 className='dark:text-offwhite font-bold uppercase ml-[-16px] mt-[-16px]'>Email Events</h2>
                    <div className='flex flex-col gap-[16px]'>
                        {order.email_timeline &&
                            order.email_timeline.map((event: any) => (
                                <div
                                    key={event.time_sent}
                                    className='grid grid-cols-2 gap-[8px] border border-grey rounded-[4px] p-[8px]'
                                >
                                    <p className='dark:text-offwhite col-span-full'>
                                        {new Date(event.time_sent).toLocaleString()}
                                    </p>
                                    <div className='flex flex-col gap-[4px] bg-lightgrey dark:bg-darkness rounded-[4px] p-[8px]'>
                                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                            Subject
                                        </label>
                                        <p className='dark:text-offwhite'>{event.subject}</p>
                                    </div>
                                    <div className='flex flex-col gap-[4px] bg-lightgrey dark:bg-darkness rounded-[4px] p-[8px]'>
                                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                            Recipient
                                        </label>
                                        <p className='dark:text-offwhite'>
                                            {Array.isArray(event.recipient)
                                                ? event.recipient.map((r: any, index: any) => (
                                                      <>
                                                          {r}
                                                          {index !== event.recipient.length - 1 && ', '}
                                                      </>
                                                  ))
                                                : event.recipient}
                                        </p>
                                    </div>
                                    <div className='flex flex-col gap-[4px] bg-lightgrey dark:bg-darkness rounded-[4px] p-[8px]'>
                                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                            Status
                                        </label>
                                        <p className='dark:text-offwhite'>
                                            {event.delivery_success ? 'Delivered' : 'Failed'}
                                        </p>
                                    </div>
                                    <div className='flex flex-col gap-[4px] bg-lightgrey dark:bg-darkness rounded-[4px] p-[8px]'>
                                        <label className='dark:text-offwhite font-bold text-[12px] uppercase leading-[1]'>
                                            PDF
                                        </label>
                                        <button
                                            className='dark:text-offwhite w-fit '
                                            onClick={() => openPdf(event.pdf)}
                                        >
                                            View PDF
                                        </button>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            )}
        </>
    )
}
