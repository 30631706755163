import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { queryKeys } from './queryKeys'
import * as service from './service'

export const useCreateSupplier = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (supplier: any) => service.createSupplier(supplier),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKeys.suppliers._def }),
    })
}

export const useGetSuppliers = (filters: any) => {
    return useQuery({
        ...queryKeys.suppliers.list(filters),
    })
}

export const useUpdateSupplier = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (supplier: any) => service.updateSupplier(supplier),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKeys.suppliers._def }),
    })
}

export const useGetSupplierProducts = (filters: any) => {
    return useQuery({
        ...queryKeys.suppliers.products(filters),
    })
}

export const useGetAllProducts = (filters: any) => {
    return useQuery({
        ...queryKeys.suppliers.allProducts(filters),
    })
}

export const useAddSupplierProducts = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (variables: { product_ids: number[]; supplier_id: number }) =>
            service.addProductsToSupplier(variables),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKeys.suppliers._def }),
    })
}

export const useDeleteSupplierProducts = () => {
    const queryClient = useQueryClient()
    return useMutation({
        mutationFn: (variables: { product_ids: number[]; supplier_id: number }) =>
            service.removeProductsFromSupplier(variables),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: queryKeys.suppliers._def }),
    })
}
