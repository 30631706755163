import { useEffect, useState } from 'react'
import { LoadingGear, vFetch } from '../helpers'
import { useSearchParams } from 'react-router-dom'
const moneyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })

export default function MoversScreen() {
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true,
        timeZone: 'America/Chicago',
    }
    const timeFormatter = new Intl.DateTimeFormat('en-US', options)
    const [params] = useSearchParams()
    const [results, setResults] = useState([])
    const [undercutsMode, setUndercutsMode] = useState(params.get('undercut') === 'true' ? true : false)
    const [page, setPage] = useState(0)
    const [currentSlice, setCurrentSlice] = useState([])
    const LIMIT = 50
    const [search, setSearch] = useState('')
    const [sortBy, setSortBy] = useState('product-desc')
    const [loading, setLoading] = useState(true)
    const [selectedRows, setSelectedRows] = useState([])
    const [needRefresh, setNeedRefresh] = useState(true)
    const [needScrape, setNeedScrape] = useState(false)
    const abortController = new AbortController()
    useEffect(() => {
        setPage(0)
        if (needRefresh) {
            setLoading(true)
            vFetch(`/v1/scrape`, {
                abortController,
                cb: (res) => {
                    if (res.success) {
                        setResults(res.scrapeResults)
                        setLoading(false)
                        setNeedRefresh(false)
                        setNeedScrape(false)
                    }
                },
            })
        }
        return () => abortController.abort()
    }, [needRefresh])

    function filterResults(item) {
        if (!item.title || !item.sku) {
            return
        } else if (
            item.title.toLowerCase().includes(search.toLowerCase()) ||
            item.sku.toLowerCase().includes(search.toLowerCase())
        ) {
            return item
        }
    }
    useEffect(() => {
        let filteredResults = [...results].filter((item) => filterResults(item))

        if (undercutsMode) {
            filteredResults = filteredResults.filter(
                (item) =>
                    item.fp_was_lowest == 1 &&
                    item.fp_is_lowest == 0 &&
                    item.scrape_result.length > 0 &&
                    item.scrape_result.find((sr) => sr.vendor === 'FactoryPure')
            )
        }
        if (sortBy.includes('product')) {
            if (sortBy.includes('desc')) {
                setCurrentSlice(
                    [...filteredResults]
                        .sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1))
                        .slice(LIMIT * page, LIMIT * (page + 1))
                )
            } else {
                setCurrentSlice(
                    [...filteredResults]
                        .sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? -1 : 1))
                        .slice(LIMIT * page, LIMIT * (page + 1))
                )
            }
        }
        if (sortBy.includes('sku')) {
            if (sortBy.includes('desc')) {
                setCurrentSlice(
                    [...filteredResults]
                        .sort((a, b) => (a.sku.toLowerCase() < b.sku.toLowerCase() ? -1 : 1))
                        .slice(LIMIT * page, LIMIT * (page + 1))
                )
            } else {
                setCurrentSlice(
                    [...filteredResults]
                        .sort((a, b) => (a.sku.toLowerCase() > b.sku.toLowerCase() ? -1 : 1))
                        .slice(LIMIT * page, LIMIT * (page + 1))
                )
            }
        }
        if (sortBy.includes('results')) {
            if (sortBy.includes('desc')) {
                setCurrentSlice(
                    [...filteredResults]
                        .sort(
                            (a, b) =>
                                (b.scrape_result ? b.scrape_result.length : 0) -
                                (a.scrape_result ? a.scrape_result.length : 0)
                        )
                        .slice(LIMIT * page, LIMIT * (page + 1))
                )
            } else {
                setCurrentSlice(
                    [...filteredResults]
                        .sort(
                            (a, b) =>
                                (a.scrape_result ? a.scrape_result.length : 0) -
                                (b.scrape_result ? b.scrape_result.length : 0)
                        )
                        .slice(LIMIT * page, LIMIT * (page + 1))
                )
            }
        }
        if (sortBy.includes('last-scrape')) {
            if (sortBy.includes('desc')) {
                setCurrentSlice(
                    [...filteredResults]
                        .sort((a, b) => new Date(b.last_scrape || 0).getTime() - new Date(a.last_scrape || 0).getTime())
                        .slice(LIMIT * page, LIMIT * (page + 1))
                )
            } else {
                setCurrentSlice(
                    [...filteredResults]
                        .sort((a, b) => new Date(a.last_scrape || 0).getTime() - new Date(b.last_scrape || 0).getTime())
                        .slice(LIMIT * page, LIMIT * (page + 1))
                )
            }
        }
        if (sortBy.includes('was-lowest')) {
            if (sortBy.includes('desc')) {
                setCurrentSlice(
                    [...filteredResults]
                        .sort((a, b) =>
                            b.fp_was_lowest !== null && a.fp_was_lowest === null
                                ? 1
                                : b.fp_was_lowest > a.fp_was_lowest
                                  ? 1
                                  : -1
                        )
                        .slice(LIMIT * page, LIMIT * (page + 1))
                )
            } else {
                setCurrentSlice(
                    [...filteredResults]
                        .sort((a, b) =>
                            b.fp_was_lowest !== null && a.fp_was_lowest === null
                                ? 1
                                : b.fp_was_lowest < a.fp_was_lowest
                                  ? 1
                                  : -1
                        )
                        .slice(LIMIT * page, LIMIT * (page + 1))
                )
            }
        }
        if (sortBy.includes('is-lowest')) {
            if (sortBy.includes('desc')) {
                setCurrentSlice(
                    [...filteredResults]
                        .sort((a, b) =>
                            b.fp_is_lowest !== null && a.fp_is_lowest === null
                                ? 1
                                : b.fp_is_lowest > a.fp_is_lowest
                                  ? 1
                                  : -1
                        )
                        .slice(LIMIT * page, LIMIT * (page + 1))
                )
            } else {
                setCurrentSlice(
                    [...filteredResults]
                        .sort((a, b) =>
                            b.fp_is_lowest !== null && a.fp_is_lowest === null
                                ? 1
                                : b.fp_is_lowest < a.fp_is_lowest
                                  ? 1
                                  : -1
                        )
                        .slice(LIMIT * page, LIMIT * (page + 1))
                )
            }
        }
    }, [results, sortBy, page, search, undercutsMode])
    const handleSetSortBy = (type) => {
        setPage(0)
        if (type === 'product') {
            setSortBy((prev) => (prev === 'product-desc' ? 'product-asc' : 'product-desc'))
        }
        if (type === 'sku') {
            setSortBy((prev) => (prev === 'sku-desc' ? 'sku-asc' : 'sku-desc'))
        }
        if (type === 'results') {
            setSortBy((prev) => (prev === 'results-desc' ? 'results-asc' : 'results-desc'))
        }
        if (type === 'last-scrape') {
            setSortBy((prev) => (prev === 'last-scrape-desc' ? 'last-scrape-asc' : 'last-scrape-desc'))
        }
        if (type === 'was-lowest') {
            setSortBy((prev) => {
                if (prev === 'was-lowest-asc') {
                    return 'was-lowest-desc'
                } else {
                    return 'was-lowest-asc'
                }
            })
        }
        if (type === 'is-lowest') {
            setSortBy((prev) => {
                if (prev === 'is-lowest-asc') {
                    return 'is-lowest-desc'
                } else {
                    return 'is-lowest-asc'
                }
            })
        }
    }
    const handleAddRemoveRow = ({ target }, row) => {
        if (selectedRows.find((item) => item.gid === row.gid)) {
            setSelectedRows((prev) => [...prev].filter((item) => item.gid !== row.gid))
        } else {
            setSelectedRows((prev) => [
                ...prev,
                {
                    gid: row.gid,
                    price: row.price,
                    search_term: row.search_term,
                    match_values: row.match_values,
                },
            ])
        }
        if (selectedRows.length === 0) {
            setNeedScrape(false)
        }
    }
    const handleSetScrape = ({ target }, index) => {
        if (target.name === 'match') {
            setSelectedRows((rows) => {
                const newRows = [...rows]
                if (newRows[index].match_values.join(', ').length > target.value.length) {
                    newRows[index].match_values = target.value
                        .split(/[ ,]+/)
                        .map((v) => v.trim())
                        .filter((v) => v.length)
                } else {
                    newRows[index].match_values = target.value.split(/[ ,]+/).map((v) => v.trim())
                }
                return newRows
            })
        } else {
            setSelectedRows((rows) => {
                const newRows = [...rows]
                newRows[index].search_term = target.value
                setNeedScrape(true)
                return newRows
            })
        }
    }
    const scrapeSelected = () => {
        vFetch(`/v1/scrape`, {
            method: 'POST',
            body: JSON.stringify({
                scrape_items: selectedRows,
            }),
            cb: (res) => {
                setSelectedRows([])
                setNeedScrape(false)
            },
        })
    }

    const newMatchValues = (selectedRows) => {
        for (let i = 0; i < selectedRows.length; i++) {
            if (selectedRows[i].match_values.includes('')) {
                selectedRows[i].match_values.pop()
            }
        }
        vFetch(`/v1/scrape/updateMatchValues`, {
            method: 'POST',
            body: JSON.stringify({
                scrape_items: selectedRows,
            }),
            cb: (res) => {
                setSelectedRows([])
                setNeedRefresh(true)
            },
        })
    }

    return (
        <>
            {selectedRows.length > 0 && (
                <div className='flex fixed bottom-[28px] left-[calc(50%+216px)] translate-x-[calc(-50%-108px)] p-[8px] bg-white dark:bg-darkness border-[1px] border-darkgrey gap-[16px] justify-center items-center mt-[16px] rounded z-[10]'>
                    {needScrape && (
                        <button
                            className='bg-blue dark:bg-accent dark:text-darkness dark:shadow-cool font-bold text-white py-[4px] px-[16px]'
                            onClick={scrapeSelected}
                        >
                            Scrape
                        </button>
                    )}
                    <button
                        className='bg-blue dark:bg-accent dark:text-darkness dark:shadow-cool font-bold text-white py-[4px] px-[16px]'
                        onClick={() => newMatchValues(selectedRows)}
                    >
                        Update Match Values
                    </button>
                </div>
            )}
            <h1 className='text-[24px] font-semibold dark:text-offwhite capitalize'>Price Scraper</h1>
            <div className='grid grid-cols-[32px_1fr_1fr_120px_120px_80px_120px_120px_150px] shadow-small rounded-[4px] my-[8px] dark:bg-darkaccent'>
                <div className='flex col-span-full p-[8px] border-b border-grey dark:border-darkgrey dark:text-offwhite'>
                    <input
                        className='focus:outline-none w-full bg-transparent'
                        type='text'
                        placeholder='Search'
                        value={search}
                        onChange={({ target }) => setSearch(target.value)}
                    />
                    <input
                        id='undercuts'
                        type='checkbox'
                        checked={undercutsMode}
                        onChange={({ target }) => setUndercutsMode(target.checked)}
                        className='cursor-pointer'
                    />
                    <label htmlFor='undercuts' className='ml-[4px] shrink-0 cursor-pointer font-bold'>
                        Show undercuts?
                    </label>
                </div>
                {loading ? (
                    <div className='w-full h-[80vh] grid place-items-center col-span-full'>
                        <LoadingGear />
                    </div>
                ) : (
                    <>
                        <div className='sticky top-[52px] z-[5] items-end col-span-full grid grid-cols-[32px_1fr_1fr_120px_120px_80px_120px_120px_150px] border-b border-lightgrey dark:bg-darkness dark:border-darkgrey bg-blue'>
                            <p className='font-[100]'></p>
                            <div className='p-[8px] grid grid-cols-2 col-span-2'>
                                <p className='font-bold text-[13px] text-accent uppercase col-span-2 border-b border-lightgrey dark:border-grey'>
                                    Product Details
                                </p>
                                <p
                                    className={`text-[13px] uppercase select-none cursor-pointer ${
                                        sortBy.includes('product') ? 'text-fire font-black' : 'text-white font-bold'
                                    }`}
                                    onClick={() => handleSetSortBy('product')}
                                >
                                    Product
                                    {sortBy.includes('product') && (
                                        <span className='text-[16px] leading-[1px] ml-[4px] inline-block'>
                                            ({sortBy.includes('asc') ? '↑' : '↓'})
                                        </span>
                                    )}
                                </p>
                                <p
                                    className={`text-[13px] uppercase select-none cursor-pointer ${
                                        sortBy.includes('sku') ? 'text-fire font-black' : 'text-white font-bold'
                                    }`}
                                    onClick={() => handleSetSortBy('sku')}
                                >
                                    SKU
                                    {sortBy.includes('sku') && (
                                        <span className='text-[16px] leading-[1px] ml-[4px] inline-block'>
                                            ({sortBy.includes('asc') ? '↑' : '↓'})
                                        </span>
                                    )}
                                </p>
                            </div>
                            <div className='p-[8px] col-span-3 grid grid-cols-[112px_112px_80px]'>
                                <p className='font-bold text-[13px] text-accent uppercase col-span-3 border-b border-lightgrey dark:border-grey'>
                                    Scrape Data
                                </p>
                                <p className={`text-[13px] uppercase select-none text-white font-bold`}>FP PRICE</p>
                                <p className={`text-[13px] uppercase select-none text-white font-bold`}>LOWEST PRICE</p>
                                <p
                                    className={`text-[13px] uppercase select-none cursor-pointer ${
                                        sortBy.includes('results') ? 'text-fire font-black' : 'text-white font-bold'
                                    }`}
                                    onClick={() => handleSetSortBy('results')}
                                >
                                    RESULTS
                                    {sortBy.includes('results') && (
                                        <span className='text-[16px] leading-[1px] ml-[4px] inline-block'>
                                            ({sortBy.includes('asc') ? '↑' : '↓'})
                                        </span>
                                    )}
                                </p>
                            </div>
                            <div className='p-[8px] grid grid-cols-2 col-span-2'>
                                <p className='font-bold text-[13px] text-accent uppercase col-span-2 border-b border-lightgrey dark:border-grey'>
                                    Price History
                                </p>
                                <p
                                    className={`text-[13px] uppercase select-none cursor-pointer ${
                                        sortBy.includes('was-lowest') ? 'text-fire font-black' : 'text-white font-bold'
                                    }`}
                                    onClick={() => handleSetSortBy('was-lowest')}
                                >
                                    WAS LOWEST
                                    {sortBy.includes('was-lowest') && (
                                        <span className='text-[16px] leading-[1px] ml-[4px] inline-block'>
                                            ({sortBy.includes('asc') ? '↑' : '↓'})
                                        </span>
                                    )}
                                </p>
                                <p
                                    className={`text-[13px] uppercase select-none cursor-pointer ${
                                        sortBy.includes('is-lowest') ? 'text-fire font-black' : 'text-white font-bold'
                                    }`}
                                    onClick={() => handleSetSortBy('is-lowest')}
                                >
                                    IS LOWEST
                                    {sortBy.includes('is-lowest') && (
                                        <span className='text-[16px] leading-[1px] ml-[4px] inline-block'>
                                            ({sortBy.includes('asc') ? '↑' : '↓'})
                                        </span>
                                    )}
                                </p>
                            </div>
                            <div className='p-[8px] grid grid-cols-1'>
                                <p className='font-bold text-[13px] text-accent uppercase col-span-2 border-b border-lightgrey dark:border-grey'>
                                    Events
                                </p>
                                <p
                                    className={`text-[13px] uppercase select-none cursor-pointer ${
                                        sortBy.includes('last-scrape') ? 'text-fire font-black' : 'text-white font-bold'
                                    }`}
                                    onClick={() => handleSetSortBy('last-scrape')}
                                >
                                    LAST SCRAPE
                                    {sortBy.includes('last-scrape') && (
                                        <span className='text-[16px] leading-[1px] ml-[4px] inline-block'>
                                            ({sortBy.includes('asc') ? '↑' : '↓'})
                                        </span>
                                    )}
                                </p>
                            </div>
                        </div>
                        {currentSlice.map((p) => (
                            <div className='col-span-full grid grid-cols-[32px_1fr_1fr_120px_120px_80px_120px_120px_150px] border-b border-lightgrey hover:bg-lightgrey dark:hover:bg-faintplus dark:border-darkgrey'>
                                <input
                                    className='w-[16px] h-[16px] p-[8px] m-auto block cursor-pointer'
                                    type='checkbox'
                                    checked={selectedRows.find((item) => item.gid === p.gid)}
                                    onChange={(e) => handleAddRemoveRow(e, p)}
                                />
                                {!selectedRows.find((item) => item.gid === p.gid) ? (
                                    <>
                                        <p
                                            className={`p-[8px] dark:text-offwhite ${
                                                sortBy.includes('product') ? `bg-lightgrey dark:bg-faintplus` : ''
                                            }`}
                                        >
                                            {p.title}
                                        </p>
                                        <p
                                            className={`p-[8px] dark:text-offwhite break-all ${
                                                sortBy.includes('sku') ? `bg-lightgreydark:bg-faintplus` : ''
                                            }`}
                                        >
                                            {p.sku}
                                        </p>
                                        <div className={`h-full col-span-2 relative grid grid-cols-[120px_120px]`}>
                                            <p
                                                className={`${
                                                    p.fp_scrape_price.price &&
                                                    p.lowest_price.price &&
                                                    Number(
                                                        p.fp_scrape_price?.price?.toString().replace(/[^0-9.-]+/g, '')
                                                    ) <=
                                                        Number(
                                                            p.lowest_price?.price?.toString().replace(/[^0-9.-]+/g, '')
                                                        )
                                                        ? 'text-[green] dark:text-lime'
                                                        : 'text-red dark:text-lightred'
                                                } p-[8px] font-bold border-none peer`}
                                            >
                                                {moneyFormatter.format(p.price)}
                                            </p>
                                            <p
                                                className={`${
                                                    p.fp_scrape_price.price &&
                                                    p.lowest_price.price &&
                                                    Number(
                                                        p.fp_scrape_price?.price?.toString().replace(/[^0-9.-]+/g, '')
                                                    ) <=
                                                        Number(
                                                            p.lowest_price?.price?.toString().replace(/[^0-9.-]+/g, '')
                                                        )
                                                        ? 'text-[green] dark:text-lime'
                                                        : 'text-red dark:text-lightred'
                                                } p-[8px] font-bold border-none peer`}
                                            >
                                                {moneyFormatter.format(p.lowest_price.price || 0)}
                                            </p>
                                            <div className='max-h-[400px] overflow-y-auto absolute p-[8px] top-[32px] transition-all delay-150 w-fit left-0 invisible hover:visible peer-hover:visible z-[5] dark:bg-darkaccent dark:text-offwhite bg-white shadow-small rounded-[4px]'>
                                                {p.scrape_result.map((row) => (
                                                    <p className='text-[13px] mb-[8px]'>
                                                        <span className='font-bold'>{row.price}</span> -{' '}
                                                        <span className='font-bold'>{row.vendor}</span> - {row.title}
                                                    </p>
                                                ))}
                                            </div>
                                        </div>
                                        <p
                                            className={`p-[8px] font-bold dark:text-offwhite ${
                                                sortBy.includes('results') ? `bg-lightgrey dark:bg-faintplus` : ''
                                            }`}
                                        >
                                            {p.scrape_result.length}
                                        </p>
                                        <div className={`h-full col-span-2 relative grid grid-cols-[100px_120px]`}>
                                            <p className={`p-[8px] font-bold border-none peer dark:text-offwhite`}>
                                                {p.fp_was_lowest !== null
                                                    ? p.fp_was_lowest == 1
                                                        ? 'True'
                                                        : 'False'
                                                    : ''}
                                            </p>
                                            <p className={`p-[8px] font-bold peer dark:text-offwhite`}>
                                                {p.fp_is_lowest !== null
                                                    ? p.fp_is_lowest == 1
                                                        ? 'True'
                                                        : 'False'
                                                    : ''}
                                            </p>
                                        </div>
                                        <div className={`h-full relative`}>
                                            <p
                                                className={`p-[8px] font-bold uppercase border-none peer dark:text-offwhite`}
                                            >
                                                {timeFormatter.format(new Date(p.last_scrape)).split(', ')[0]}
                                                <br />
                                                {timeFormatter
                                                    .format(new Date(p.last_scrape))
                                                    .split(', ')[1]
                                                    .slice(0, -6)}{' '}
                                                {timeFormatter.format(new Date(p.last_scrape)).slice(-2)}
                                            </p>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className='col-span-8 grid grid-cols-[1fr_1fr_120px_120px_80px_120px_120px_150px]'>
                                            <p className={`p-[8px] dark:text-offwhite`}>{p.title}</p>
                                            <p className={`p-[8px] break-all dark:text-offwhite`}>{p.sku}</p>
                                            <div className='flex flex-col p-[8px] col-span-3'>
                                                <label className='font-bold uppercase text-[12px] dark:text-offwhite'>
                                                    Search Term
                                                </label>
                                                <input
                                                    className='py-[2px] px-[4px] border border-lightgrey rounded-[4px] dark:bg-faintplus dark:border-none dark:text-white'
                                                    type='text'
                                                    name='term'
                                                    value={selectedRows.find((r) => r.gid === p.gid).search_term}
                                                    onChange={(e) =>
                                                        handleSetScrape(
                                                            e,
                                                            selectedRows.findIndex((r) => r.gid === p.gid)
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className='flex flex-col p-[8px] col-span-3'>
                                                <label className='font-bold uppercase text-[12px] dark:text-offwhite'>
                                                    Match Values
                                                </label>
                                                <input
                                                    className='py-[2px] px-[4px] border border-lightgrey rounded-[4px] dark:bg-faintplus dark:border-none dark:text-white'
                                                    type='text'
                                                    name='match'
                                                    value={selectedRows
                                                        .find((r) => r.gid === p.gid)
                                                        .match_values.join(', ')}
                                                    onChange={(e) =>
                                                        handleSetScrape(
                                                            e,
                                                            selectedRows.findIndex((r) => r.gid === p.gid)
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        ))}
                    </>
                )}
            </div>
            <div className='flex gap-[16px] w-fit mx-auto mt-[16px]'>
                <button
                    className='disabled:opacity-25 py-[2px] px-[8px] rounded-[4px] border border-grey bg-lightgrey dark:bg-darkaccent dark:text-offwhite'
                    disabled={page === 0}
                    onClick={() => setPage((prev) => prev - 1)}
                >
                    PREV
                </button>
                <p className='py-[2px] px-[8px] rounded-[4px] dark:text-offwhite'>{page + 1}</p>
                <button
                    className='disabled:opacity-25 py-[2px] px-[8px] rounded-[4px] border border-grey bg-lightgrey dark:bg-darkaccent dark:text-offwhite'
                    disabled={page * LIMIT + currentSlice.length >= results.length || currentSlice.length < 50}
                    onClick={() => setPage((prev) => prev + 1)}
                >
                    NEXT
                </button>
            </div>
        </>
    )
}
