import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useGetPathname } from 'productSetupV2/api/useQueries'
import { taxExemptionQueries } from './taxExemptionQueryKeys'
import * as service from './service'

export function useGetTaxExemptions({ filters, search }: { filters: any; search?: string }) {
    const query = useQuery({
        ...taxExemptionQueries.taxExemptions.list(filters, search),
        meta: {
            clientErrMessage: 'Failed to load tax exemptions',
            pathname: useGetPathname(),
            functionName: 'useGetTaxExemptions',
        },
    })
    return query
}

export function useGetTaxExemptionAuditQuery({
    table,
    taxExemptionId,
    parent_id,
}: {
    table: string
    taxExemptionId: any
    parent_id: number
}) {
    const query = useQuery({
        ...taxExemptionQueries.taxExemptions.audit(table, taxExemptionId, parent_id),
        meta: {
            clientErrMessage: 'Failed to load tax exemption audit',
            pathname: useGetPathname(),
            functionName: 'useGetTaxExemptionAuditQuery',
        },
    })
    return query
}

export function useGetTaxExemptionDetail({ taxExemptionId }: { taxExemptionId: any }) {
    const query = useQuery({
        ...taxExemptionQueries.taxExemptions.detail(taxExemptionId),
        meta: {
            clientErrMessage: 'Failed to load tax exemptions',
            pathname: useGetPathname(),
            functionName: 'useGetTaxExemptions',
        },
    })
    return query
}
export function useGetTaxForms({ taxExemptionId }: { taxExemptionId: any }) {
    const query = useQuery({
        ...taxExemptionQueries.taxExemptions.forms(taxExemptionId),
        meta: {
            clientErrMessage: 'Failed to load tax forms',
            pathname: useGetPathname(),
            functionName: 'useGetTaxForms',
        },
    })
    return query
}

// Mutations
export function useDeleteTaxExemption() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ taxExemptionId }: { taxExemptionId: string | number }) => {
            return service.deleteTaxExemption(taxExemptionId)
        },
        meta: {
            clientErrMessage: 'Failed to delete exemption',
            pathname: useGetPathname(),
            functionName: 'useDeleteTaxExemption',
        },
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: taxExemptionQueries.taxExemptions._def })
        },
    })
}

export function useUpdateTaxExemption() {
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: ({ taxExemption }: { taxExemption: any }) => {
            return service.updateTaxExemption(taxExemption)
        },
        meta: {
            clientErrMessage: 'Failed to update exemption',
            pathname: useGetPathname(),
            functionName: 'useUpdateTaxExemption',
        },
        onSuccess: () => {
            return queryClient.invalidateQueries({ queryKey: taxExemptionQueries.taxExemptions._def })
        },
    })
}
