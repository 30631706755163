import { ChangeEvent, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { Button } from '../../custom_components/component_Basics/Button'
import { useCreateOption, useOptionValuesQuery, useOptionsQuery } from '../api/useQueries'
import Input from './Input'
import { VariantOption } from './lists/VariantOption'
import { VariantOptionValue } from './lists/VariantOptionValue'

export default function CompanyProductOptionSetup({ product_id }: { product_id: number | string }) {
    const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(0)
    const [showNewOption, setShowNewOption] = useState(false)
    const [showOptionEdit, setShowOptionEdit] = useState<any>()

    const [editableOption, setEditableOption] = useState<any>({})

    const [showNewOptionValue, setShowNewOptionValue] = useState(false)

    const optionsQuery = useOptionsQuery(product_id)
    const { options }: { options: any } = optionsQuery?.data || {}

    const optionValuesQuery = useOptionValuesQuery(product_id)
    const { optionValues }: { optionValues: any } = optionValuesQuery?.data || {}

    const createOption = useCreateOption()

    const handleOptionChange = ({
        target,
    }: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setEditableOption({
            ...editableOption,
            [target.name]: target.value,
        })
    }

    return (
        <>
            <div className='w-full dark:bg-darkaccent p-[16px] rounded-[4px] flex flex-col  col-span-5'>
                <>
                    <div className='flex gap-[16px]'>
                        <div className='w-1/3 flex flex-col gap-[8px]'>
                            <div className='grid grid-cols-[3fr_2fr]'>
                                <div className='flex justify-between'>
                                    <label
                                        className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                                        htmlFor='custom_supplier_sku'
                                    >
                                        Option
                                    </label>
                                    {!showNewOption &&
                                        !showOptionEdit &&
                                        options?.length < 3 &&
                                        !createOption.isPending && (
                                            <Button
                                                size={'default'}
                                                variant={'ghost'}
                                                onClick={() => {
                                                    setShowNewOption(true)
                                                }}
                                                disabled={createOption.isPending}
                                                className='h-4 w-4 hover:bg-bg1 dark:hover:bg-darkbg1 mr-4'
                                            >
                                                <p className='flex gap-2'>
                                                    New Option
                                                    <FaPlus className='self-center' />
                                                </p>
                                            </Button>
                                        )}
                                </div>
                            </div>
                            {options?.map((option: any, index: number) => {
                                if (showOptionEdit === option.id) {
                                    return (
                                        <Input
                                            key={option.id}
                                            id={'label'}
                                            autoFocus={true}
                                            name={'label'}
                                            type='text'
                                            onChange={handleOptionChange}
                                            value={editableOption.label ?? option.label}
                                            className={` py-[4px] px-[16px] text-[14px] rounded-[4px] font-bold text-center`}
                                        ></Input>
                                    )
                                }
                                if (!(showOptionEdit === option.id)) {
                                    return (
                                        <VariantOption
                                            key={option.id}
                                            isNew={false}
                                            option={option}
                                            index={index}
                                            productId={product_id}
                                            selectedOptionIndex={selectedOptionIndex}
                                            setSelectedOptionIndex={setSelectedOptionIndex}
                                            showOptionEdit={showOptionEdit}
                                            setShowOptionEdit={setShowOptionEdit}
                                            setShowNewOptionValue={setShowNewOptionValue}
                                            createOption={createOption}
                                        />
                                    )
                                }
                            })}
                            {showNewOption && options.length < 3 && (
                                <VariantOption
                                    isNew={true}
                                    option={{}}
                                    index={options.length}
                                    productId={product_id}
                                    selectedOptionIndex={selectedOptionIndex}
                                    setSelectedOptionIndex={setSelectedOptionIndex}
                                    showOptionEdit={showOptionEdit}
                                    setShowOptionEdit={setShowOptionEdit}
                                    setShowNewOption={setShowNewOption}
                                    createOption={createOption}
                                />
                            )}
                        </div>
                        <div className='w-1/3 flex flex-col gap-2'>
                            {options && Object.keys(options).length > 0 && (
                                <div className='grid grid-cols-[3fr_2fr]'>
                                    <div className='flex justify-between'>
                                        <label
                                            className='leading-[1] text-[12px] uppercase font-bold text-darkgrey dark:text-offwhite'
                                            htmlFor='custom_supplier_sku'
                                        >
                                            Option Values
                                        </label>
                                        {!showNewOptionValue && (
                                            <Button
                                                size={'icon'}
                                                variant={'ghost'}
                                                onClick={() => {
                                                    setShowNewOptionValue(true)
                                                }}
                                                className='h-4 w-4 hover:bg-bg1 dark:hover:bg-darkbg1 mr-5'
                                            >
                                                <p className='flex gap-2'>
                                                    New Value
                                                    <FaPlus className='self-center' />
                                                </p>
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            )}
                            {options &&
                                optionValues &&
                                Object.keys(optionValues).length > 0 &&
                                Object.keys(options).length > 0 &&
                                optionValues
                                    ?.filter(
                                        (optionValue: any) =>
                                            options.findIndex((option: any) => option.id === optionValue.option_id) ===
                                            selectedOptionIndex
                                    )
                                    ?.map((optionValue: any, index: number) => {
                                        const hasSingleOptionValue =
                                            optionValues?.filter(
                                                (optionValue: any) =>
                                                    options.findIndex(
                                                        (option: any) => option.id === optionValue.option_id
                                                    ) === selectedOptionIndex
                                            ).length > 1
                                                ? true
                                                : false
                                        return (
                                            <VariantOptionValue
                                                key={optionValue.id}
                                                canDelete={hasSingleOptionValue}
                                                isNew={false}
                                                optionValue={optionValue}
                                                productId={product_id}
                                                optionId={options[selectedOptionIndex].id}
                                                setShowNewOptionValue={setShowNewOptionValue}
                                            />
                                        )
                                    })}
                            {options && showNewOptionValue && Object.keys(options).length > 0 && (
                                <VariantOptionValue
                                    canDelete={false}
                                    isNew={true}
                                    optionValue={{}}
                                    productId={product_id}
                                    optionId={options[selectedOptionIndex].id}
                                    setShowNewOptionValue={setShowNewOptionValue}
                                />
                            )}
                        </div>
                    </div>
                </>
            </div>
        </>
    )
}
