import { useQuery } from '@tanstack/react-query'
import { vFetch } from 'helpers'
import Select from 'procurement/components/Select'
import { useState } from 'react'

export default function AnalyticsHeader({ templateId }: { templateId?: string }) {
    const [days, setDays] = useState('7')
    const analyticsQuery = useQuery({
        queryKey: ['marketing', 'email', templateId, 'analytics', days],
        queryFn: () =>
            vFetch(
                `/v1/marketing/followUpEmail/analytics?days=${days}${templateId ? `&template_id=${templateId}` : ''}`
            ),
    })
    const analytics = analyticsQuery.data?.analytics || {}

    return (
        <div className='mb-4'>
            <h2 className='font-semibold mb-2 text-lg leading-none'>Statistics</h2>
            <div className='grid grid-cols-6 text-sm border border-lightgrey dark:border-darkgrey rounded bg-bg1 dark:bg-darkbg1'>
                <Select
                    className='p-4 text-sm bg-transparent'
                    id='days'
                    name='days'
                    value={days}
                    onChange={({ target }) => setDays(target.value)}
                >
                    <option value='1'>In The Last 24 Hours</option>
                    <option value='7'>In The Last 7 Days</option>
                    <option value='30'>In The Last 30 Days</option>
                </Select>
                <p className='p-4 font-bold border-r border-lightgrey dark:border-darkgrey'>
                    {analyticsQuery.isLoading ? <p>Loading...</p> : <>{analytics.scheduled} Emails Scheduled</>}
                </p>
                <p className='p-4 font-bold border-r border-lightgrey dark:border-darkgrey'>
                    {analyticsQuery.isLoading ? <p>Loading...</p> : <>{analytics.sent} Emails Sent</>}
                </p>
                <p className='p-4 font-bold border-r border-lightgrey dark:border-darkgrey'>
                    {analyticsQuery.isLoading ? <p>Loading...</p> : <>{analytics.opens} Emails Opened</>}
                </p>
                <p className='p-4 font-bold border-r border-lightgrey dark:border-darkgrey'>
                    {analyticsQuery.isLoading ? <p>Loading...</p> : <>{analytics.clicks} Links Clicked</>}
                </p>
                <p className='p-4 font-bold'>
                    {analyticsQuery.isLoading ? <p>Loading...</p> : <>{analytics.unsubscribed} Unsubscribed</>}
                </p>
            </div>
        </div>
    )
}
