import { vFetch } from 'helpers'
import {
    CreateTimesheetEntryType,
    UpdateTimesheetEntryType,
    TimesheetEntryType,
    TimesheetType,
    UpdateTimesheetType,
} from 'timesheets/Timesheets.types'

export const getTimesheets = async (filters: any): Promise<{ success: boolean; timesheets?: TimesheetType[] }> => {
    const params = Object.entries(filters)
        .filter(([_key, val]) => val)
        .map(([key, val]) => `${key}=${val}`)
        .join('&')
    return vFetch(`/v2/timesheets${params ? `?${params}` : ''}`)
}

export const updateTimesheet = async (timesheet: UpdateTimesheetType) => {
    return vFetch('/v2/timesheets', {
        method: 'PUT',
        body: JSON.stringify(timesheet),
    })
}

export const createEntry = async (entry: CreateTimesheetEntryType) => {
    return vFetch('/v2/timesheets/entries', {
        method: 'POST',
        body: JSON.stringify(entry),
    })
}

export const getTimesheetEntries = async (
    filters: any
): Promise<{ success: boolean; timesheetEntries?: TimesheetEntryType[] }> => {
    const params = Object.entries(filters)
        .filter(([_key, val]) => val)
        .map(([key, val]) => `${key}=${val}`)
        .join('&')
    return vFetch(`/v2/timesheets/entries${params ? `?${params}` : ''}`)
}

export const updateEntry = async (entry: UpdateTimesheetEntryType) => {
    return vFetch('/v2/timesheets/entries', {
        method: 'PUT',
        body: JSON.stringify(entry),
    })
}

export const deleteEntry = async (entryId: number) => {
    return vFetch('/v2/timesheets/entries', {
        method: 'DELETE',
        body: JSON.stringify({ id: entryId }),
    })
}
