import { Button } from 'custom_components/component_Basics/Button'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { LuArrowUpDown } from 'react-icons/lu'

const SORT_KEYS = {
    id: 'Created At',
    name: 'Customer Name',
    orders_count: 'Total Orders',
    total_spent: 'Amount Spent',
}

const SORT_DIRECTIONS = {
    asc: 'Ascending',
    desc: 'Descending',
}

export default function CustomerSort({
    sortKey,
    setSortKey,
    sortDirection,
    setSortDirection,
}: {
    sortKey: string
    setSortKey: Dispatch<SetStateAction<string>>
    sortDirection: string
    setSortDirection: Dispatch<SetStateAction<string>>
}) {
    const modalRef = useRef<HTMLDivElement>(null)

    const [showSorters, setShowSorters] = useState(false)

    useEffect(() => {
        const listenToWindow = (e: MouseEvent) => {
            if (!modalRef.current?.contains(e.target as HTMLElement)) {
                setShowSorters(false)
            }
        }

        window.addEventListener('mousedown', listenToWindow)

        return () => window.removeEventListener('mousedown', listenToWindow)
    })
    return (
        <div ref={modalRef} className='relative'>
            <Button className='px-2' variant={'outline'} onClick={() => setShowSorters((prev) => !prev)}>
                <LuArrowUpDown className='text-xl' />
            </Button>
            {showSorters && (
                <div className='absolute top-full right-0 z-10 p-2 border border-lightgrey dark:border-darkgrey rounded bg-white dark:bg-darkbg1 shadow-lg font-medium text-sm'>
                    <div className='flex flex-col gap-1 mb-4'>
                        {Object.entries(SORT_KEYS).map(([key, val]) => (
                            <button
                                className='shrink-0 whitespace-nowrap flex gap-2 items-center'
                                onClick={() => setSortKey(key)}
                            >
                                <span
                                    className={`rounded-full w-4 h-4 ${
                                        key === sortKey
                                            ? 'border-4 border-blue dark:border-darkaccent1'
                                            : 'border border-grey/50 dark:darkgrey'
                                    }`}
                                >
                                    &nbsp;
                                </span>
                                <span>{val}</span>
                            </button>
                        ))}
                    </div>
                    <div className='flex flex-col gap-1'>
                        {Object.entries(SORT_DIRECTIONS).map(([key, val]) => (
                            <button
                                className='shrink-0 whitespace-nowrap flex gap-2 items-center'
                                onClick={() => setSortDirection(key)}
                            >
                                <span
                                    className={`rounded-full w-4 h-4 ${
                                        key === sortDirection
                                            ? 'border-4 border-blue dark:border-darkaccent1'
                                            : 'border border-grey/50 dark:darkgrey'
                                    }`}
                                >
                                    &nbsp;
                                </span>
                                <span>{val}</span>
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
